define("recruit/components/candidate-helpers/bulk-import/on-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['padding-sm-bottom bg-white']
  });

  _exports.default = _default;
});