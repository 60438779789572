define("recruit/components/events/event-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    initProperties: function initProperties() {
      this.set('newStatus', null);
      this.set('modalMessage', null);
      this.set('isNegativeLoading', false);

      if (this.get('showStatus')) {
        this.toggleProperty('showStatus');
      }
    },
    canChangeEventStatus: Ember.computed('selectedStatus.id', 'canChangeStatus', function () {
      return this.get('selectedStatus.id') != 3 && this.get('selectedStatus.id') != 6 && this.get('canChangeStatus');
    }),
    actions: {
      toggleModal: function toggleModal(property) {
        var resetProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (this.get('canChangeEventStatus')) {
          this.toggleProperty(property);

          if (resetProperties) {
            this.initProperties();
          }
        }
      },
      changeStatus: function changeStatus(newStatus) {
        var _this = this;

        var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('eventModel');
        this.set('newStatus', newStatus);

        if (this.get('selectedStatus.id') == 1) {
          Ember.RSVP.resolve(model.checkDraftStatusValidity(newStatus)).then(function (response) {
            if (response.get('isValid')) {
              return _this.send('getModalMessage', newStatus, model);
            }

            _this.initProperties();

            _this.set('modalMessage', response.get('message'));

            _this.set('modalTitle', response.get('title'));

            _this.set('hideNegativeButton', true);

            return _this.send('toggleModal', 'showHeadsup', false);
          });
        } else {
          return this.send('getModalMessage', newStatus, model);
        }
      },
      getModalMessage: function getModalMessage(newStatus, model) {
        var _this2 = this;

        Ember.RSVP.resolve(model.getHeadsUpMessage(newStatus)).then(function (headsUp) {
          if (headsUp.error.length) {
            _this2.set('modalMessage', headsUp.error);

            _this2.set('modalTitle', "There was a problem");

            _this2.set('hideNegativeButton', true);

            return _this2.send('toggleModal', 'showHeadsup');
          }

          if (headsUp.message.length) {
            _this2.set('modalMessage', headsUp.message);

            _this2.set('modalTitle', "Heads Up!");

            _this2.set('hideNegativeButton', false);

            return _this2.send('toggleModal', 'showHeadsup');
          }

          return _this2.send('setStatus', newStatus, model);
        });
      },
      setStatus: function setStatus() {
        var _this3 = this;

        var newStatus = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('newStatus');
        var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('eventModel');
        model.set('status', newStatus.get('id'));
        this.set('isNegativeLoading', true);
        model.save().then(function () {
          _this3.get('toast').success('Status updated');
        }).catch(function () {
          _this3.get('toast').error('error');
        }).finally(function () {
          _this3.toggleProperty('showStatus');

          _this3.toggleProperty('showHeadsup');

          _this3.initProperties();
        });
      }
    }
  });

  _exports.default = _default;
});