define("recruit/components/reports/advanced/conversion-report-list", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filteredList: Ember.computed('filterValue', function () {
      var filterValue = _npmLodash.default.toLower(this.get('filterValue'));

      if (!filterValue) {
        return this.get('columns');
      }

      return this.get('columns').filter(function (col) {
        return _npmLodash.default.toLower(col.get('label')).includes(filterValue);
      });
    }),
    actions: {
      redirect: function redirect(obj) {
        this.get('router').transitionTo('recruiter.reports.advanced.conversion.conversion-index', obj);
      }
    }
  });

  _exports.default = _default;
});