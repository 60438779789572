define("recruit/components/assessment/tabs/remove-form-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      removeForm: function removeForm(sectionModel) {
        if (sectionModel.get('evaluationForm.id')) {
          this.set('isLoading', true);
          this.set('sectionModel.evaluationForm', null);
          this.send('saveModel', sectionModel.get('content') ? sectionModel.get('content') : sectionModel);
        }
      },
      toggleModal: function toggleModal() {
        this.set('sectionModel.isForm', true);
        this.sendAction('toggleModal');
      },
      saveModel: function saveModel(section) {
        var _this = this;

        section.save().then(function () {
          _this.get('toast').success('Form Deleted', 'Success');

          _this.set('sectionModel.isForm', false);

          _this.sendAction('toggleModal');
        }).catch(function () {
          _this.get('toast').error('Form not deleted', 'Something went wrong');
        }).finally(function () {
          _this.set('isLoading', true);
        });
      }
    }
  });

  _exports.default = _default;
});