define("recruit/components/answer-views/live-webrtc-videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    wait: false,
    selectedVideo: Ember.computed(function () {
      return this.get('videos.firstObject');
    }),
    actions: {
      selectVideo: function selectVideo(video) {
        var _this = this;

        if (this.get('selectedVideo') != video) {
          this.set('wait', true);
          this.set('selectedVideo', video);
          setTimeout(function () {
            _this.set('wait', false);
          }, 0);
        }
      }
    }
  });

  _exports.default = _default;
});