define("recruit/templates/components/recruiter-view/questions/bulk-create/bulk-import-review-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bGKc0FPN",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segment margin-bottom-no margin-top-hundred text-black text-weight-medium border-left-none border-right-none box-shadow-none border-bottom-left-radius-none border-bottom-right-radius-none\"],[7],[0,\"\\n  BULK IMPORT QUESTIONS SUCCESSFULL\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ui grid margin-no padding-bg bg-white\"],[9,\"style\",\"height: 35vh;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"sixteen wide column text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"display-inline-block\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"/images/tick.svg\"],[9,\"alt\",\"upload file\"],[9,\"class\",\"padding-bg padding-sm-bottom\"],[9,\"style\",\"height: 130px !important;\"],[7],[8],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"margin-bottom-five margin-top-fifteen text-black\"],[7],[6,\"span\"],[9,\"class\",\"text-blue\"],[7],[1,[18,\"totalArray\"],false],[8],[0,\" Questions were imported from file \"],[6,\"span\"],[9,\"class\",\"text-blue\"],[7],[1,[18,\"documentName\"],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ui segment text-center margin-no border-top-left-radius-none border-top-right-radius-none border-left-none border-right-none full-width\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"sixteen wide column text-center\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui basic button blue\"],[3,\"action\",[[19,0,[]],\"back\"]],[7],[0,\"Close\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/bulk-create/bulk-import-review-success.hbs"
    }
  });

  _exports.default = _default;
});