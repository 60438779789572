define("recruit/templates/components/live-session/join-flash-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+so2jHMs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"iframe\"],[10,\"src\",[26,[[20,[\"sessionURL\",\"flashJoinUrl\"]]]]],[9,\"style\",\"width: 100%; height: calc(100vh - 151px);\"],[7],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-session/join-flash-view.hbs"
    }
  });

  _exports.default = _default;
});