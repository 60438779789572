define("recruit/templates/components/google-maps-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qESsDoHK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui left icon input full-width\"],[7],[0,\"\\n  \"],[1,[25,\"fa-icon\",[\"map-marker-alt\"],[[\"class\"],[\"text-grey input-icon-fix left\"]]],false],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"value\"],[\"location\",\"Add location\",[20,[\"searchText\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/google-maps-location.hbs"
    }
  });

  _exports.default = _default;
});