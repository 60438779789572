define("recruit/templates/components/helpers/table-profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f2i7BH3v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[3,\"action\",[[19,0,[]],\"linkToPage\"]],[7],[0,\"\\n  \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"computedValue\",\"profilePic\"]],\"ui circular image profile-picture\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/table-profile-picture.hbs"
    }
  });

  _exports.default = _default;
});