define("recruit/models/settings-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    settings_id: _emberData.default.attr('string'),
    settings_allowed_value_id: _emberData.default.attr('string')
  });

  _exports.default = _default;
});