define("recruit/templates/components/recruiter-view/questions/helpers/code-input-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IDwsQQmd",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"field padding-vs-bottom\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"text-black text-weight-medium\"],[9,\"style\",\"font-size: 14px;\"],[7],[0,\"Test Case 1\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui input focus\"],[7],[0,\"\\n\\t  \"],[6,\"input\"],[9,\"type\",\"text\"],[9,\"placeholder\",\"Test Case 1\"],[7],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"field padding-vs-bottom\"],[7],[0,\"\\n  \"],[6,\"label\"],[9,\"class\",\"text-black text-weight-medium\"],[9,\"style\",\"font-size: 14px;\"],[7],[0,\"Result\"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui input focus\"],[7],[0,\"\\n\\t  \"],[6,\"input\"],[9,\"type\",\"text\"],[9,\"placeholder\",\"Result\"],[7],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/code-input-helper.hbs"
    }
  });

  _exports.default = _default;
});