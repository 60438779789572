define("recruit/components/recruiter-view/actions/candidate-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    property: Ember.computed('model.relationType', 'listValues.@each.candidate', function () {
      switch (this.get('model.relationType')) {
        case 'assessmentCandidates':
          {
            return 'candidate';
          }

        case 'panels':
          {
            return 'user';
          }

        case 'liveSession':
          {
            return 'candidate';
          }

        default:
          {
            return null;
          }
      }
    })
  });

  _exports.default = _default;
});