define("recruit/routes/recruiter/events/index", ["exports", "npm:lodash", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _npmLodash, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      searchText: {
        replace: true
      }
    },
    user: Ember.inject.service('current-user'),
    performance: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('performance').start('Recruiter:Events:list');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.get('user.me.permissions').then(function (permissions) {
        controller.set('isEventsEnabled', _npmLodash.default.includes(permissions.map(function (permission) {
          return permission.get('item_name');
        }), 'event.create'));
        controller.set('isLoaded', true);
      });
    }
  });

  _exports.default = _default;
});