define("recruit/controllers/recruiter/assessment/details/assessment-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['currentSlide', 'sectionId', 'updateJd'],
    currentSlide: 0,
    sectionId: null,
    updateJd: null
  });

  _exports.default = _default;
});