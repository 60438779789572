define("recruit/templates/components/live-schedule/location-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FyrDPMSK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"field margin-no\"],[9,\"style\",\"margin-top:0 !important\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui left icon input full-width\"],[7],[0,\"\\n    \"],[1,[25,\"fa-icon\",[\"map-marker-alt\"],[[\"class\"],[\"text-grey input-icon-fix left\"]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"value\"],[\"location\",\"Add location\",[20,[\"searchText\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"field\"],[7],[0,\"\\n  \"],[1,[25,\"textarea\",null,[[\"value\",\"rows\",\"class\",\"placeholder\"],[[20,[\"liveSession\",\"location\",\"description\"]],\"4\",\"margin-top-ten full-width padding-sm\",\"Add description\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-schedule/location-details.hbs"
    }
  });

  _exports.default = _default;
});