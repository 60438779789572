define("recruit/routes/recruiter/live-schedule/form", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment", "recruit/validations/time-validator", "npm:lodash"], function (_exports, _authenticatedRouteMixin, _moment, _timeValidator, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    user: Ember.inject.service('current-user'),
    selectedTimeZone: null,
    queryParams: {
      cid: {
        refreshModel: true
      },
      aid: {
        refreshModel: true
      },
      acid: {
        refreshModel: true
      },
      redirectTo: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.set('params', params);
      return Ember.RSVP.hash({
        liveSession: this.get('store').createRecord('live-schedule-occurrence'),
        timeZoneList: this.get('store').query('settingsAllowed', {
          settings_id: 1
        }),
        timeZone: this.get('store').query('settingsData', {
          settings_id: 1
        }),
        organization: this.get('user.me.organization')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('params', this.get('params'));
      controller.set('selectedPanels', Ember.A([]));
      controller.set('isAdhocLive', model.organization.get('isAdhocLive'));
      controller.set('candidateRecord', null);
      controller.set('timeZoneList', model.timeZoneList);
      controller.set('timeZone', model.timeZone);
      controller.set('scheduleType', 'now');
      controller.set('isAskPanel', false);
      controller.set('isNotify', true);
      controller.set('isPanelLoading', false);
      controller.set('askPanel', false);
      controller.set('selectedAssessmentSection', null);
      controller.set('currentDate', (0, _moment.default)().add(3, 'hours').toDate());
      controller.set('minimumDate', (0, _moment.default)().subtract(1, 'days').startOf('day').toDate());
      controller.set('panelList', Ember.A([]));
      controller.set('openModal', false);
      controller.set('colorIndex', Ember.A([0, 1, 2, 3, 4, 5, 6, 7, 8]));
      controller.set('index', 0);
      controller.set('isIntegration', _npmLodash.default.includes(this.get('params.redirectTo'), 'integration'));
      controller.set('userTimeZone', controller.get('timeZone') ? this.get('store').peekRecord('settings-allowed', controller.get('timeZone.firstObject.settings_allowed_value_id')) : null);
    },
    afterModel: function afterModel() {
      Ember.run.next(function () {
        Ember.$('.ui.dropdown').dropdown();
      });
    },
    deactivate: function deactivate() {
      this.controller.set('openModal', false);
    },
    actions: {
      addPanel: function addPanel(user) {
        if (!this.controller.get('selectedPanels').findBy('id', user.get('id'))) {
          if (this.get('user.me.id') == user.get('id')) {
            user.set('colorIndex', "attendees-accent-organiser");
          } else if (this.controller.get('colorIndex.length')) {
            user.set('colorIndex', "attendees-accent-color".concat(this.controller.get('colorIndex').shiftObject()));
          } else {
            user.set('colorIndex', "attendees-accent-color".concat(this.controller.incrementProperty('index')));
          }

          this.controller.get('selectedPanels').pushObject(user);

          if (!this.controller.get('panelList').findBy('id', user.get('id'))) {
            this.controller.get('panelList').pushObject(user);
          }
        }
      },
      removePanel: function removePanel(user) {
        this.controller.get('selectedPanels').removeObject(user);

        if (this.controller.get('panelList').findBy('id', user.get('id'))) {
          this.controller.get('panelList').removeObject(user);
        }

        if (this.get('user.me.id') != user.get('id')) {
          this.controller.get('colorIndex').pushObject(+user.get('colorIndex').slice(-1));

          if (this.controller.get('colorIndex.length') > 9) {
            this.controller.set('colorIndex', this.controller.get('colorIndex').uniq());
          }
        }
      },
      toggleModal: function toggleModal(property) {
        this.controller.toggleProperty(property);
      },
      save: function save(liveSession) {
        var _this = this;

        var selectedPanels = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        var candidate = arguments.length > 2 ? arguments[2] : undefined;
        var model, candidateName;

        if (!this.controller.get('candidateRecord') && !this.get('params.cid')) {
          return this.get('toast').error('', 'Please select a candidate');
        }

        if (!_npmLodash.default.isNil(candidate.get('fullName')) && candidate.get('fullName').length) {
          candidateName = candidate.get('fullName');
        } else {
          candidateName = candidate.get('email');
        }

        if (!this.controller.get('isAdhocLive')) {
          if (!this.controller.get('selectedAssessmentSection')) {
            return this.get('toast').error('', "Please select an assessment and section");
          }
        }

        if (!selectedPanels.length) {
          return this.get('toast').error('', 'Please select a panel');
        }

        if (!liveSession.get('from') && !this.controller.get('askPanel')) {
          return this.get('toast').error('', 'Please select a start time');
        } // if(!this.controller.get('newStartDate')) {
        //   return this.get('toast').error('', 'Please select a date')
        // }


        if (this.controller.get('scheduleType') == 'now' && !this.controller.get('askPanel')) {
          if (!liveSession.get('from')) {
            return this.get('toast').error('', 'Please select a start time');
          }

          if (!this.controller.get('userTimeZone')) {
            return this.get('toast').error('', "Please select the timezone");
          }
        }

        var startDateTime = !liveSession.get('from') ? this.controller.get('currentDate') : liveSession.get('from');
        var selectedTime = liveSession.get('durationPreSelected').value;
        var endDateTime = !liveSession.get('to') ? (0, _moment.default)(startDateTime).add(selectedTime, "minutes") : liveSession.get('to');
        var userTimeZone = this.controller.get('userTimeZone'); //Validating timezone specific time

        var isValidDate = (0, _timeValidator.default)(startDateTime, userTimeZone.get('item_value'));

        if (!isValidDate) {
          return this.get('toast').error('', 'Cannot scheduled in past');
        }

        this.controller.set('isLoading', true);
        model = this.get('store').createRecord('actions/live-schedule', {
          startDate: this.controller.get('askPanel') ? null : startDateTime,
          endDate: this.controller.get('askPanel') ? null : endDateTime,
          event: 'Live',
          panel: selectedPanels.length ? selectedPanels.map(function (panel) {
            return {
              id: panel.get('id')
            };
          }) : null,
          assessmentCandidateID: this.get('params.acid'),
          candidateID: candidate.get('id'),
          email: candidate.get('email'),
          candidateName: candidateName.trim(),
          currentOffset: (0, _moment.default)().local().utcOffset() * 60,
          assessmentSectionID: this.controller.get('selectedAssessmentSection.id'),
          type: 1,
          isNotify: this.controller.get('isNotify'),
          timezone: this.controller.get('askPanel') ? null : userTimeZone.get('item_value'),
          askPanel: this.controller.get('askPanel'),
          sync: true,
          isOffline: liveSession.get('isOffline'),
          location: liveSession.get('location')
        });
        model.save().then(function (response) {
          if (response.get('status') == 99) {
            _this.get('toast').error(response.get('response'));
          } else {
            return response.get('liveSession').then(function (ls) {
              return ls.get('liveAttendees').then(function () {
                if (_this.get('params.redirectTo')) {
                  _this.get('router').transitionTo(_this.get('params.redirectTo'));
                } else {
                  _this.get('router').transitionTo('recruiter.live-session.view', ls);
                }

                return _this.get('toast').success('Live session scheduled successfully');
              });
            });
          }
        }).catch(function (err) {
          if (err.errors) {
            err.errors.forEach(function (error) {
              Raven.captureException(error.detail);

              _this.get('toast').error(error.detail, 'Error!');
            });
          }
        }).finally(function () {
          _this.controller.set('isLoading', false);
        });
      },
      back: function back() {
        history.back();
      },
      askPanel: function askPanel(liveSession, selectedPanels, candidate) {
        this.controller.set('askPanel', true);
        this.send('save', liveSession, selectedPanels, candidate);
      }
    }
  });

  _exports.default = _default;
});