define("recruit/routes/recruiter/live-session/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/data/live-list", "recruit/data/filters/r-ls"], function (_exports, _authenticatedRouteMixin, _liveList, _rLs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      searchText: {
        replace: true
      },
      filters: {
        replace: true
      }
    },
    performance: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('store').push({
        data: _liveList.default
      });
      this.get('store').push({
        data: _rLs.default
      });
      this.get('performance').start('Recruiter:Live-Session:list');
    },
    model: function model() {
      return Ember.RSVP.hash({
        masterFilters: this.store.peekAll('filter/master') // appliedFilters: this.store.query('filter/applied', {objectType: 'r-ls'}),
        // columnMaster: this.store.query('column/master', {table_type: 'r-ls'}),
        // columnApplied: this.store.query('column/applied', {tableType: 'r-ls'})

      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('appliedFilters', Ember.A([]));
      controller.set('masterFilters', model.masterFilters.filterBy('objectType', 'r-ls')); // let appliedFilters = A([]);
      // // model.appliedFilters.forEach(filter => appliedFilters.pushObject(filter));
      // controller.set('appliedFilters', appliedFilters);
      // let columns = A([
      //   {
      //     component : "helpers/table-checkboxs",
      //     cellComponent : "helpers/row-checkbox",
      //     sortable : false,
      //     width : '40px',
      //     classNames : ['table-head padding-no table-head-checkbox-th'],
      //     cellClassNames : ['child padding-no'],
      //   }
      // ]);
      // model.columnMaster.filterBy('tableType', 'r-ls').forEach(column => {
      //   if(column.get('isColumnSelected')) {
      //     let modifiedColumn = column.toJSON();
      //     // modifiedColumn.width="200px";
      //     columns.pushObject(modifiedColumn);
      //   }
      // });
      // controller.set('currentUser', this.get('currentUser.me'));
      // return controller.set('columns', columns.sortBy('sortWeight'));
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controller.set('filters', null);
        var navRoutes = ["recruiter.pending-candidates", "recruiter.candidate.index", "recruiter.assessment.index", "recruiter.reports.dashboard", "dashboard.index", "user.settings.account"];
        navRoutes.forEach(function (routeName) {
          if (transition.targetName == routeName) {
            return;
          }
        });
      }
    }
  });

  _exports.default = _default;
});