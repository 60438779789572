define("recruit/templates/components/recruiter-view/assessment/helpers/notification-table-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DQgqXLZV",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[20,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[6,\"td\"],[9,\"style\",\"width: 100vw; border-bottom: 1px solid #eee\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui centered grid margin-no\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"fifteen wide column loader-header\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"fifteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"fifteen wide column loader-header\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"fifteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"fifteen wide column loader-header\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/helpers/notification-table-placeholder.hbs"
    }
  });

  _exports.default = _default;
});