define("recruit/templates/components/candidate-auth/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QZ35h7wh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"text-center\"],[9,\"style\",\"\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui icon circular yellow button auth-audio-button\"],[3,\"action\",[[19,0,[]],\"togglePlay\"]],[7],[0,\"\\n    \"],[6,\"i\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"playing\"]],\"pause\",\"play\"],null],\" icon\"]]],[7],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"playing\"]]],null,{\"statements\":[[0,\"      \"],[1,[18,\"auth-soundwave\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"audio\"],[9,\"id\",\"tv-audio-player\"],[10,\"src\",[20,[\"file\",\"resourceUrl\"]],null],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-auth/audio-player.hbs"
    }
  });

  _exports.default = _default;
});