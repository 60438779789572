define("recruit/components/reports/helpers/graph-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    graphType: Ember.computed(function () {
      var routeName = this.get('router.currentRouteName').split('.')[2];

      switch (routeName) {
        case 'assessments':
          return 'reports/helpers/assessment-graph-header';

        case 'recruiter':
          return 'reports/helpers/user-graph-header';

        case 'panel':
          return 'reports/helpers/user-graph-header';

        case 'time-range':
          return 'reports/helpers/time-range-graph-header';

        case 'advanced':
          return 'reports/helpers/advance-graph-header';
      }
    })
  });

  _exports.default = _default;
});