define("recruit/components/panel-evaluation/section-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.next('afterRender', function () {
        Ember.$('.proviewLog').popup({
          position: 'bottom right'
        });
      });
    },
    hideExtraColumn: Ember.computed('selectedAssc.section.isMcq', 'selectedAssc.section.isExternal', function () {
      return this.get('selectedAssc.section.isMcq') || this.get('selectedAssc.section.isExternal') || this.get('selectedAssc.section.isPearson');
    }),
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('.behavioral-rating').popup({
          position: 'bottom center'
        });
      });
    },
    actions: {
      changeAnswer: function changeAnswer(answer) {
        this.sendAction('changeAnswer', answer);
      },
      toggleModal: function toggleModal(property) {
        if (property) {
          return this.toggleProperty(property);
        }

        this.sendAction('toggleModal');
      },
      viewProctorLogs: function viewProctorLogs() {
        this.toggleProperty('openProctorLogs');
      }
    }
  });

  _exports.default = _default;
});