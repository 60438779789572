define("recruit/routes/recruiter/candidate/create", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        candidate: this.get('store').createRecord('candidate'),
        fieldMaster: this.get('store').query('custom-field-master', {
          'type': 2
        })
      });
    },
    afterModel: function afterModel() {// this.get('router').transitionTo('recruiter.candidate.create.basic-details');
    }
  });

  _exports.default = _default;
});