define("recruit/components/recruiter-view/questions/create/index", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isBrowserIE: Ember.computed(function () {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');

      if (msie != -1) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');

      if (trident != -1) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      } // other browser


      return false;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('allowedQuestionTypes', Ember.A([]));
      Ember.$('.ui.dropdown').dropdown({
        onChange: function onChange(value) {
          var type, qtype;
          qtype = _this.get('allowedQuestionTypes').findBy('label', value);
          value = _npmLodash.default.toLower(value).trim();

          switch (value) {
            case 'mca':
              type = 'multiple choice multiple answer';
              break;

            case 'mcq':
              type = 'multiple choice';
              break;

            case 'automated':
              type = 'interview';
              break;

            default:
              type = value;
          }

          Ember.set(_this, "selectedQuestionType", qtype);
          Ember.set(_this, "creationType", type);
          Ember.set(_this.get('question'), 'type', type);
        }
      });
      this.set('selectedSkills', Ember.A([]));
      this.set('preSelectedSkills', Ember.A([]));
      this.set('files', Ember.A([]));
      this.get('availableSections').forEach(function (sections) {
        if (sections.id == "4" || sections.id == "5" || sections.id == "6" || sections.id == "10") {
          _this.get('allowedQuestionTypes').pushObject(sections);

          if (sections.id == "6") {
            _this.get('allowedQuestionTypes').pushObject(Ember.Object.create({
              name: 'Multiple Correct Answer',
              icon: 'tasks',
              label: 'MCA',
              description: "Multiple choice questions with multiple correct answer."
            }));
          }
        }
      });
      this.set('cannotCreate', false);
      this.set('moreErrors', true);
      this.set('creationType', 'multiple choice');
    },
    actions: {
      removeSkill: function removeSkill(skill, index) {
        this.get('selectedSkills').removeAt(index);

        if (skill.get('isDeleteable')) {
          this.get('preSelectedSkills').removeAt(index);
        }

        skill.rollbackAttributes();
      },
      selectSkills: function selectSkills(skill) {
        this.get('selectedSkills').pushObject(skill);

        if (!this.get('preSelectedSkills').findBy('id', skill.get('id'))) {
          skill.set('isDeleteable', true);
          this.get('preSelectedSkills').pushObject(skill);
        }
      },
      validate: function validate(changeset, skills, type) {
        var _this2 = this;

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            _this2.set('cannotCreate', true);
          } else {
            _this2.send('createQuestion', changeset, skills, type);
          }
        });
      },
      createQuestion: function createQuestion(questionObject, skills, files) {
        var _this3 = this;

        this.set('isLoading', true);
        questionObject.save().then(function (createdQuestion) {
          if (Ember.get(skills, 'length') || Ember.get(files, 'length')) {
            var allFiles = files.map(function (file) {
              return _this3.get('store').createRecord('question-file', {
                file: file,
                question: createdQuestion
              }).save();
            });
            var allSkills = skills.map(function (skill) {
              return _this3.get('store').createRecord('question-skill', {
                skill: skill,
                question: createdQuestion
              }).save();
            });

            var promiseArray = _npmLodash.default.concat(allSkills, allFiles);

            Ember.RSVP.Promise.all(promiseArray).then(function () {
              _this3.get('toast').success('Question created successfully', 'Success');

              Ember.run.next(function () {
                window.history.back();
              });
            });
          } else {
            _this3.get('toast').success('Question created successfully', 'Success');

            Ember.run.next(function () {
              window.history.back();
            });
          }
        }).catch(function (error) {
          if (error.errors) {
            error.errors.forEach(function (e) {
              _this3.get('toast').error(e.detail, 'Error');
            });
          } else {
            _this3.get('toast').error('Please try after sometime', 'Something went wrong!');
          }
        }).finally(function () {
          _this3.set('isLoading', false);
        });
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('cannotCreate');
      },
      closeErrors: function closeErrors() {
        this.set('cannotCreate', false);
      },
      showErrors: function showErrors(property) {
        this.toggleProperty(property);
      },
      cancel: function cancel() {
        window.history.back();
      },
      saveChanges: function saveChanges(image, changeset, property) {
        changeset.set("".concat(property), image);
      },
      saveChoice: function saveChoice(image, changeset, property) {
        changeset.set("".concat(property), image);
      }
    }
  });

  _exports.default = _default;
});