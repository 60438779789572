define("recruit/templates/components/helpers/render-custom-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yzx15OxN",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"each\",[[20,[\"customField\"]]],null,{\"statements\":[[4,\"if\",[[25,\"and\",[[19,1,[\"is_object\"]],[19,1,[\"value\",\"length\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui c-profile-field\"],[9,\"style\",\"overflow-x: scroll; padding: 0px;\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"divider margin-bottom-five\"],[7],[1,[19,1,[\"label\"]],false],[8],[0,\"\\n      \"],[1,[25,\"candidate-helpers/nested-custom-field\",null,[[\"field\"],[[19,1,[]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui column c-profile-field\"],[7],[0,\"\\n      \"],[6,\"div\"],[7],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"divider word-break-all\"],[7],[1,[19,1,[\"label\"]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[1,[25,\"is-valid-field\",[[19,1,[\"value\"]]],null],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/render-custom-field.hbs"
    }
  });

  _exports.default = _default;
});