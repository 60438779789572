define("recruit/templates/components/helpers/resume-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F+N1u5+J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"isFileSupported\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"id\",\"tv-resume-viewer\"],[9,\"style\",\"height: calc(100vh - 125px); overflow: auto;\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui middle aligned center aligned grid\"],[9,\"style\",\"height: calc(100vh - 125px)\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column\"],[7],[0,\"\\n        \"],[6,\"img\"],[9,\"src\",\"/images/empty_states/file_warning.png\"],[9,\"alt\",\"\"],[9,\"class\",\"ui tiny centered image\"],[9,\"style\",\"opacity:0.8;\"],[7],[8],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"text-black margin-bottom-five padding-sm-top\"],[7],[0,\"Resume preview not available.\"],[8],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"text-black\"],[7],[0,\"\\n          \"],[6,\"a\"],[10,\"download\",[20,[\"file\",\"name\"]],null],[10,\"href\",[20,[\"file\",\"resourceUrl\"]],null],[10,\"name\",[20,[\"file\",\"originalName\"]],null],[9,\"class\",\"text-blue margin-top-five cursor-pointer\"],[9,\"target\",\"_blank\"],[7],[0,\"\\n            Download resume\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/resume-viewer.hbs"
    }
  });

  _exports.default = _default;
});