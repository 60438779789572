define("recruit/templates/components/live-session/join-web-rtc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2keRt68N",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"id\",\"meet_talview\"],[9,\"style\",\"height: calc(100vh - 151px); width: 100%;\"],[10,\"class\",[26,[[25,\"unless\",[[20,[\"sessionJoined\"]],\"display-none\"],null]]]],[7],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"sessionJoined\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui active centered inline tiny loader display-inline-block margin-right-ten\"],[7],[8],[0,\"Connecting...\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-session/join-web-rtc.hbs"
    }
  });

  _exports.default = _default;
});