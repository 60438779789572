define("recruit/models/reports/custom-field-time", ["exports", "recruit/models/reports/assessment-time"], function (_exports, _assessmentTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _assessmentTime.default.extend({});

  _exports.default = _default;
});