define("recruit/controllers/recruiter/candidate/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchText: null,
    filters: null,
    isPendingInvite: null,
    isUnassigned: null,
    isPendingEval: null,
    isRecentEval: null
  });

  _exports.default = _default;
});