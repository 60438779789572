define("recruit/models/evaluation", ["exports", "ember-data", "npm:lodash"], function (_exports, _emberData, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    evaluated_at: _emberData.default.attr('pgdate'),
    recommendation: _emberData.default.attr('string'),
    assessment_section_id: _emberData.default.attr('number'),
    assessmentSection: _emberData.default.belongsTo('assessment-section'),
    assessment_candidate_id: _emberData.default.attr('number'),
    assessment_section_candidate_id: _emberData.default.attr('number'),
    evaluationComments: _emberData.default.hasMany('evaluationComment'),
    evaluationRatings: _emberData.default.hasMany('evaluationRating'),
    formInstance: _emberData.default.belongsTo('formInstance', {
      inverse: null
    }),
    panel: _emberData.default.belongsTo('panel'),
    evaluationsStatus: Ember.computed(function () {
      switch (this.get('recommendation')) {
        case 'select':
          return 'selected';

        case 'reject':
          return 'rejected';

        case 'hold':
          return 'hold';

        default:
          return 'pending';
      }
    }),
    averageRating: Ember.computed('evaluationRatings.@each', 'evaluationRatings.isFulfilled', 'assessmentSection.parameters.[]', function () {
      var _this = this;

      this.set('parameters', Ember.A([]));
      this.get('assessmentSection.parameters').then(function (params) {
        if (params.get('length')) {
          params.forEach(function (param) {
            if (_this.get('evaluationRatings.length')) {
              _this.get('parameters').pushObject(_this.get('evaluationRatings').findBy('parameter.id', param.get('id')));
            }
          });

          if (_this.get('parameters.length')) {
            _this.set('rating', _npmLodash.default.round(_this.get('parameters').map(function (param) {
              return +param.get('rating');
            }).reduce(function (a, b) {
              return parseInt(a) + parseInt(b);
            }, 0) / _this.get('parameters.length'), 2));
          }
        }
      });
      return this.get('rating') ? this.get('rating') : '--';
    }),
    isRelationsLoaded: Ember.computed('formInstance.@each', 'evaluationRatings.isFulfilled', function () {
      var _this2 = this;

      this.get('panel').then(function () {
        return _this2.get('evaluationRatings');
      }).then(function () {
        return _this2.get('formInstance');
      }).then(function () {
        return _this2.set('isRelationsLoaded', true);
      });
    })
  });

  _exports.default = _default;
});