define("recruit/components/modal-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'modal'],
    classNameBindings: ['isFullScreen:fullscreen', 'modalSize'],
    didUpdateAttrs: function didUpdateAttrs() {
      var that = this;
      this.$().modal({
        onHide: function onHide() {
          that.toggleProperty('openModal');
        }
      });

      if (this.get('openModal')) {
        this.$().modal('setting', {
          autofocus: false,
          closable: this.get('closable')
        }).modal('show');
      } else {
        this.$().modal({
          closable: true
        }).modal('hide');
      } //TODO: FIX (Extend semantic UI calendar to implement life cycle)
      // Ember.$('.ui.calendar').calendar({type: 'datetime', minDate: moment().toDate(), disableMinute: true});

    }
  });

  _exports.default = _default;
});