define("recruit/components/answer-views/code", ["exports", "recruit/config/environment", "npm:highlight.js"], function (_exports, _environment, _npmHighlight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    didInsertElement: function didInsertElement() {
      Ember.$('pre code').each(function (i, block) {
        _npmHighlight.default.highlightBlock(block);
      });
    }
  });

  _exports.default = _default;
});