define("recruit/components/reports/helpers/column-with-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: Ember.computed(function () {
      return this.get('table.columns.lastObject.value') ? this.get('table.columns.lastObject.value') : '--';
    }),
    actions: {
      resizeTable: function resizeTable(property) {
        if (property) {
          this.set('hideGraph', false);
        } else {
          this.set('hideGraph', true);
        }
      }
    }
  });

  _exports.default = _default;
});