define("recruit/models/position", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    external_id: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    designation: _emberData.default.attr('string'),
    job_requirements: _emberData.default.attr('string'),
    job_responsibilities: _emberData.default.attr('string')
  });

  _exports.default = _default;
});