define("recruit/components/reports/filters/advance-filters", ["exports", "npm:lodash", "recruit/components/filters/advance-filters"], function (_exports, _npmLodash, _advanceFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _advanceFilters.default.extend({
    showWarning: Ember.computed('selectedFilter', function () {
      if (this.get('selectedAdvanceFilters.length')) {
        var arr = this.get('selectedAdvanceFilters').map(function (filterObj) {
          return filterObj.get('filter.id');
        });
        return _npmLodash.default.includes(arr, this.get('selectedFilter.id'));
      }
    }),
    listValue: Ember.computed('selectedFilter', function () {
      if (this.get('selectedFilter.type') == 'list' && this.get('doPeekModel')) {
        return this.get('store').peekAll(this.get('selectedFilter.model'));
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});