define("recruit/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userRole: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model) {
        switch (model.errors.get('firstObject.status')) {
          case "403":
            controller.set('errorComponent', 'error-states/error-404');
            break;

          case "500":
            controller.set('errorComponent', 'error-states/error-500');
            break;

          case "502":
            controller.set('errorComponent', 'error-states/error-502');
            break;

          case "504":
            controller.set('errorComponent', 'error-states/error-504');
            break;

          case "404":
            controller.set('errorComponent', 'error-states/error-404');
            break;

          default:
            controller.set('errorComponent', 'error-states/fallback-error');
        }
      }
    },
    actions: {
      back: function back() {
        if (this.get('userRole.isRecruiter')) {
          this.get('router').transitionTo('recruiter.dashboard');
        } else {
          this.get('router').transitionTo('panel.dashboard');
        }
      },
      refresh: function refresh() {
        window.location.reload();
      }
    }
  });

  _exports.default = _default;
});