define("recruit/components/input-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui inverted range'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        var model = _this.get('model');

        _this.$().range({
          min: _this.get('min') ? _this.get('min') : 0,
          max: _this.get('maxValue'),
          start: model.get('currentValue'),
          step: 1,
          onChange: function onChange(value) {
            model.set('currentValue', value);
          }
        });
      });
    }
  });

  _exports.default = _default;
});