define("recruit/components/recruiter-view/actions/add-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedPanels: Ember.A([]),
    actions: {
      addPanel: function addPanel(panel) {
        this.get('selectedPanels').pushObject(panel);
      },
      remove: function remove(panel, index) {
        this.get('selectedPanels').removeAt(index);
      }
    }
  });

  _exports.default = _default;
});