define("recruit/adapters/settings-allowed", ["exports", "recruit/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import UrlTemplates from "ember-data-url-templates";
  var _default = _application.default.extend({
    namespace: 'models',
    pathForType: function pathForType() {
      return 'settings-allowed-value';
    }
  });

  _exports.default = _default;
});