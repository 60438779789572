define("recruit/components/panel/evaluation-strip", ["exports", "recruit/config/environment", "npm:lodash"], function (_exports, _environment, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    openModal: false,
    model: null,
    // evaluationType: null,
    ratingsArray: Ember.A([]),
    session: Ember.inject.service(),
    userRole: Ember.inject.service(),
    showTextIcon: Ember.computed(function () {
      return !this.get('currentUser.me.organization.isIconEvaluation');
    }),
    isComment: Ember.computed('evaluationType', function () {
      return this.get('evaluationType') === 'comment';
    }),
    previousEvaluations: Ember.computed('selectedAssc.status.label', 'isSessionEvaluated', function () {
      var _this = this;

      if (this.get('selectedAssc.status.label') && this.get('isSessionEvaluated')) {
        return this.get('selectedAssc.status.label');
      } else {
        this.get('selectedAssc.status').then(function (status) {
          Ember.RSVP.resolve(status);

          _this.set('previousEvaluations', status.get('label'));
        });
      }
    }),
    setButtonColor: Ember.computed('previousEvaluations', function () {
      if (this.get('previousEvaluations')) {
        return 'is' + this.get('previousEvaluations');
      }
    }),
    assessmentParameters: Ember.computed('selectedAssc', 'selectedAssc.section.parameters', function () {
      if (this.get('selectedAssc.section.parameters.length')) {
        this.get('selectedAssc.section.parameters').forEach(function (p) {
          p.set('currentValue', 0);
          p.set('lineWidth', p.get('recommended_value') / p.get('max_value') * 100);
        });
      }

      return this.get('selectedAssc.section.parameters');
    }),
    totalValue: Ember.computed('assessmentParameters.@each.currentValue', function () {
      if (this.get('assessmentParameters.isFulfilled')) {
        return this.get('assessmentParameters').map(function (as) {
          return as.get('currentValue');
        }).reduce(function (a, b) {
          return parseInt(a) + parseInt(b);
        }, 0);
      }

      return 0;
    }),
    total: Ember.computed('assessmentParameters.isFulfilled', 'assessmentParameters.@each.max_value', function () {
      if (this.get('assessmentParameters.isFulfilled')) {
        return this.get('assessmentParameters').map(function (p) {
          return p.get('max_value');
        }).reduce(function (a, b) {
          return parseInt(a) + parseInt(b);
        }, 0);
      } else {
        return 0;
      }
    }),
    average: Ember.computed('assessmentParameters.@each.currentValue', function () {
      if (this.get('assessmentParameters.isFulfilled')) {
        return _npmLodash.default.floor(this.get('assessmentParameters').map(function (obj) {
          return obj.get('currentValue');
        }).reduce(function (a, b) {
          return parseInt(a) + parseInt(b);
        }, 0) / this.get('assessmentParameters.length'), 2);
      }

      return 0;
    }),
    percentage: Ember.computed('totalValue', 'total', function () {
      return Number.parseFloat(this.get('totalValue') / this.get('total') * 100).toFixed(2);
    }),
    actions: {
      evaluateCandidate: function evaluateCandidate(status, evaluationType) {
        var _this2 = this;

        if (!this.get('isForm')) {
          if (!evaluationType && this.get('assessmentParameters.length')) {
            return this.send('formEvaluation', 'ratings');
          } // if(this.get('isComment') && ! this.get('commentText')) {
          //   return this.get('toast').error('Invalid Comment', "Comment can't be blank");
          // }

        }

        this.get('assessmentParameters').forEach(function (parameter) {
          _this2.get('ratingsArray').pushObject({
            "parameter_id": parameter.get('id'),
            "rating": parameter.get('currentValue')
          });
        });
        var evaluateAction = this.get('store').createRecord('actions/evaluate', {
          assessmentSectionCandidateID: this.get('selectedAssc.id'),
          rating: this.get('ratingsArray'),
          evaluationComment: this.get('commentText'),
          recommendation: status
        });
        this.set('disableClick', true);
        evaluateAction.save().then(function (response) {
          if (response.get('status') == 99) {
            return _this2.get('toast').error(response.get('response'));
          } else {
            return response.get('evaluation').then(function (evalResponse) {
              _this2.set('currentSessionEvaluated', true);

              if (_this2.get('panelCandidate.evaluations.length')) {
                _this2.get('panelCandidate.evaluations').clear();

                _this2.get('panelCandidate.evaluations').pushObject(evalResponse);
              }

              _this2.get('toast').success('Status: ' + evalResponse.get('evaluationsStatus'), 'Evaluation saved successfully!', {
                timeOut: 5000
              });
            });
          }
        }).catch(function (e) {
          if (e.errors) {
            e.errors.forEach(function (error) {
              _this2.get('toast').error(error.detail, 'Error!');
            });
          } else {
            _this2.get('toast').error('Please try after sometimer', 'Some thing went wrong');
          }
        }).finally(function () {
          _this2.set('disableClick', false);

          evaluationType ? _this2.send('formEvaluation') : null;

          _this2.set('ratingsArray', Ember.A([]));

          if (_this2.get('redirect')) {
            if (_this2.get('userRole.isRecruiter')) {
              _this2.get('router').transitionTo('recruiter.live-session.view', _this2.get('liveSession'));
            } else {
              _this2.get('router').transitionTo('panel.live-session.view', _this2.get('liveSession'));
            }
          } else {
            if (_this2.get('isCandidateSidebar')) {
              _this2.send('toggleEvaluationSidebar', 'toggleEvaluationSidebar');
            }
          }
        });
      },
      toggleSlider: function toggleSlider(type) {
        if (this.get('isCandidateSidebar')) {
          this.send('toggleEvaluationSidebar', 'toggleEvaluationSidebar');
        } else {
          this.set('evaluationType', type);
          Ember.$('.ui.sidebar').sidebar({
            context: Ember.$('#' + this.elementId)
          }).sidebar('setting', 'transition', 'overlay').sidebar('toggle');
          this.$().toggleClass('pushable');
        }
      },
      formEvaluation: function formEvaluation(type) {
        if (this.get('section.evaluationFormUrl')) {
          if (this.get('isPanel')) {
            this.get('router').transitionTo('panel.candidate.evaluate.form', this.get('panelCandidate.id'));
          } else if (this.get('isLiveEvaluation')) {
            this.send('toggleSlider', type);
          } else {
            this.get('router').transitionTo('recruiter.candidate.details.assessment-section.evaluate', this.get('selectedAssc.ac.candidate.id'), this.get('selectedAssc'));
          }
        } else {
          this.send('toggleSlider', type);
        }
      },
      toggleEvaluations: function toggleEvaluations() {
        this.sendAction('toggleEvaluations');
      },
      toggleEvaluationSidebar: function toggleEvaluationSidebar(property) {
        this.sendAction('toggleEvaluationSidebar', property);
      }
    }
  });

  _exports.default = _default;
});