define("recruit/components/recruiter-view/assessment/assessment-overview/overview-header", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var activeHeaderRoutes = ['recruiter.assessment.details.overview', 'recruiter.assessment.details.candidate', 'recruiter.assessment.details.assessment-section', 'recruiter.assessment.details.notification'];
  var activeInviteRoutes = ['recruiter.assessment.details.candidate', 'recruiter.assessment.details.assessment-section'];

  var _default = Ember.Component.extend({
    isTrue: Ember.computed('router.currentRouteName', function () {
      return _npmLodash.default.includes(activeInviteRoutes, this.get('router.currentRouteName'));
    }),
    sectionAvailable: Ember.computed('router.currentRouteName', function () {
      if (_npmLodash.default.includes(activeHeaderRoutes, this.get('router.currentRouteName'))) {
        return this.get('model.assessmentSections').then(function (res) {
          return res.get('length');
        });
      } else {
        return false;
      }
    })
    /* Uncomment the below code to show assessment status tooltip for assessment workflow freeze */
    // didRender() {
    //   Ember.run.later('afterRender', () => {
    //     Ember.$('.assessmentStatus').popup({
    //       inline: false,
    //       hoverable: true,
    //       html: this.get('model').getStatusTooltipContent(),
    //       position: 'bottom center'
    //     })
    //   })
    // }

  });

  _exports.default = _default;
});