define("recruit/data/assessment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    type: "status/assessment",
    attributes: {
      "label": "Draft",
      "value": 1,
      "sort_key": 1
    }
  }, {
    id: 2,
    type: "status/assessment",
    attributes: {
      "label": "Closed",
      "value": 2,
      "sort_key": 2
    }
  }, {
    id: 3,
    type: "status/assessment",
    attributes: {
      "label": "Open",
      "value": 3,
      "sort_key": 3
    }
  }, {
    id: 4,
    type: "status/assessment",
    attributes: {
      "label": "Filled",
      "sort_key": 4
    }
  }, {
    id: 5,
    type: "status/assessment",
    attributes: {
      "label": "Cancelled",
      "sort_key": 5
    }
  }, {
    id: 6,
    type: "status/assessment",
    attributes: {
      "label": "On Hold",
      "sort_key": 6
    }
  }, {
    id: 7,
    type: "status/assessment",
    attributes: {
      "label": "Sourcing",
      "sort_key": 7
    }
  }, {
    id: 8,
    type: "status/assessment",
    attributes: {
      "label": "Approved",
      "sort_key": 8
    }
  }];
  _exports.default = _default;
});