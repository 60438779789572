define("recruit/components/live-schedule/location-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global google*/
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.setObject();
    },
    setObject: function setObject() {
      this.set('liveSession.location', Ember.Object.create({
        formattedAddress: null,
        formattedPhoneNumber: null,
        place_id: null,
        coordinate: null,
        url: null,
        description: null
      }));
    },
    resetProperty: function resetProperty() {
      var description = this.get('liveSession.location.description');
      this.setObject();
      this.set('liveSession.location.description', description);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.fetchAddress(); //Scrolling down to bottom

      if (this.get('liveSession.isOffline')) {
        Ember.$('.live-schedule-form').animate({
          scrollTop: Ember.$('.live-schedule-form')[0].scrollHeight
        }, 700);
      }
    },
    searchTextObserver: Ember.observer('searchText', function () {
      var _this = this;

      if (this.get('searchText')) {
        var service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions({
          input: this.get('searchText')
        }, function (results, status) {
          Ember.run.next('afterRender', function () {
            if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              _this.resetProperty();

              _this.set('liveSession.location.formattedAddress', _this.get('searchText'));
            }
          });
        });
      } else {
        this.resetProperty();
      }
    }),
    fetchAddress: function fetchAddress() {
      var _this2 = this;

      var autocomplete;
      autocomplete = new google.maps.places.Autocomplete(document.getElementById('location'));
      autocomplete.addListener('place_changed', function () {
        var location = autocomplete.getPlace();

        if (location && location.geometry) {
          _this2.get('liveSession.location').setProperties({
            "formattedAddress": location.formatted_address,
            "formattedPhoneNumber": location.formatted_phone_number,
            "place_id": location.place_id,
            "coordinate": location.geometry.location,
            "url": location.url
          });
        }
      });
    }
  });

  _exports.default = _default;
});