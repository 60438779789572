define("recruit/components/recruiter-view/assessment-section-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    outerClass: Ember.computed('assessmentSection.isReject', 'assessmentSection.isFirst', function () {
      if (this.get('assessmentSection.isReject')) {
        return 'basic';
      } else {
        if (this.get('assessmentSection.isFirst')) {
          return 'color-selected';
        } else {
          return 'basic blue';
        }
      }
    }),
    innerClass: Ember.computed('assessmentSection.isReject', 'assessmentSection.isFirst', function () {
      if (this.get('assessmentSection.isReject')) {
        return 'color-grey';
      } else {
        if (this.get('assessmentSection.isFirst')) {
          return 'white';
        } else {
          return 'blue';
        }
      }
    }),
    lineClass: Ember.computed('assessmentSection.isReject', 'assessmentSection.isFirst', function () {
      return this.get('assessmentSection.isReject') ? 'grey' : 'blue';
    }),
    actions: {
      selectAssessmentSection: function selectAssessmentSection(newAssessmentSection) {
        this.sendAction('selectAssessmentSection', newAssessmentSection);
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.$('[data-content]').popup({
        position: 'bottom center',
        inline: false,
        variation: 'inverted'
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('[data-content]').popup('destroy');
    }
  });

  _exports.default = _default;
});