define("recruit/router", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');

        var title = _this.getWithDefault('currentRouteName', 'unknown');

        Ember.get(_this, 'metrics').trackPage({
          page: page,
          title: title
        });
      });
    }
  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('login');
    this.route('user', function () {
      this.route('change-role');
      this.route('settings', function () {
        this.route('account');
        this.route('security');
        this.route('notifications');
        this.route('password');
        this.route('picture');
        this.route('capture');
      });
      this.route('details', {
        path: '/:user_id'
      }, function () {
        this.route('profile');
        this.route('stats');
        this.route('timeline');
      });
    });
    this.route('recruiter', function () {
      this.route('dashboard');
      this.route('live-schedule', function () {
        this.route('form');
      }); // Candidate routes with ac and assc details
      //Assessment candidate list

      this.route('candidate', {
        path: 'invites'
      }, function () {
        //Candidate detailed view
        this.route('details', {
          path: '/:id'
        }, function () {
          // Candidate section details of an assessment
          this.route('assessment', {
            path: 'assessment/:ac_id'
          }); //Candidates answers for one section in an assessment

          this.route('assessment-section', {
            path: 'assessment-section/:assessment_section_candidate_id'
          }, function () {
            this.route('answers', function () {});
            this.route('evaluations');
            this.route('form');
            this.route('evaluate');
          }); //List of assessments for a candidate

          this.route('assessments'); //Profile view of candidate

          this.route('profile');
          this.route('timeline');
          this.route('files');
          this.route('profile-placeholder');
          this.route('authentication'); // this.route('authentication', {path: '/authentication/:auth_id'});

          this.route('resume');
        });
        this.route('create', function () {
          this.route('basic-details', {
            path: 'basic'
          });
          this.route('additional');
          this.route('advance');
          this.route('bulk-create', {
            path: 'bulk'
          });
        });
        this.route('bulk-create');
      });
      this.route('positions', function () {});
      this.route('reports', function () {
        this.route('assessments', function () {
          this.route('conversion');
          this.route('time-to-hire', {
            path: 'time-to-process'
          });
        });
        this.route('time-range', function () {
          this.route('time-per-month');
          this.route('conversion');
        });
        this.route('dashboard');
        this.route('recruiter', function () {
          this.route('conversion');
          this.route('activities');
          this.route('time-to-hire', {
            path: 'time-to-process'
          });
        });
        this.route('advanced', function () {
          this.route('conversion', function () {
            this.route('conversion-index', {
              path: '/:custom_field_master_id'
            });
          });
          this.route('time', {
            path: 'time-to-process'
          }, function () {
            this.route('time-to-process', {
              path: ':custom_field_master_id'
            });
          });
        });
        this.route('report-filters');
        this.route('panel', function () {
          this.route('conversion');
        });
      });
      this.route('pending-candidates', {
        path: 'candidates'
      });
      this.route('live-schedule-confirm');
      this.route('assessment', function () {
        this.route('details', {
          path: '/:id'
        }, function () {
          this.route('overview');
          this.route('candidate', {
            path: '/invites'
          });
          this.route('section');
          this.route('import');
          this.route('job-description');
          this.route('assessment-section', {
            path: '/assessment-section/:assessment_section_id'
          });
          this.route('assessment-update', {
            path: '/edit'
          });
          this.route('notification');
          this.route('edit-template');
        });
        this.route('create', function () {});
        this.route('create-new-assessment', {
          path: 'new/:uuid'
        });
      });
      this.route('live-session', function () {
        this.route('view', {
          path: '/:live_session_id'
        });
        this.route('join', {
          path: 'join/:live_attendee_id'
        });
        this.route('videos', {
          path: '/:live_session_id/videos'
        });
      });
      this.route('vm-schedule', function () {
        this.route('form');
      });
      this.route('recruiter-actions', {
        path: ''
      }, function () {
        this.route('actions', {
          path: 'actions/:id'
        });
      });
      this.route('questions', function () {
        this.route('create');
        this.route('edit', {
          path: '/:id/edit'
        });
        this.route('bulk-create');
      });
      this.route('events', {
        path: 'event'
      }, function () {
        this.route('details', {
          path: '/:event_id'
        }, function () {
          this.route('overview');
          this.route('candidate', {
            path: '/invites'
          });
        });
      });
    });
    this.route('panel', function () {
      this.route('dashboard');
      this.route('calendar');
      this.route('candidate', function () {
        this.route('evaluate', function () {
          this.route('index', {
            path: '/:id'
          });
          this.route('form', {
            path: '/:id/form'
          });
        });
      });
      this.route('live-session', function () {
        this.route('view', {
          path: '/:live_session_id'
        });
        this.route('join', {
          path: 'join/:live_attendee_id'
        });
        this.route('videos', {
          path: '/:live_session_id/videos'
        });
      });
      this.route('event', function () {
        this.route('details', {
          path: ':event_id'
        }, function () {
          this.route('response');
          this.route('register');
        });
        this.route('invitation');
      });
    });
    this.route('ticket', function () {
      this.route('new');
    });
    this.route('help');
    this.route('logout');
    this.route('forgot');
    this.route('reset-password');
    this.route('ask-activation');
    this.route('alert');
    this.route('game-load');
    this.route('design', function () {
      this.route('automated');
      this.route('change-last-date');
      this.route('other-status');
      this.route('assign-panel');
      this.route('live-prompt');
      this.route('temp');
      this.route('choose-account');
    });
    this.route('dashboard', function () {
      this.route('redirect');
    });
    this.route('setup', function () {
      this.route('user');
      this.route('security');
    });
    this.route('calendar-card');
    this.route('404', {
      path: '/*path'
    });
    this.route('auth', function () {
      this.route('callback');
      this.route('aad');
      this.route('token');
    });
    this.route('candidate', function () {});
    this.route('signed-out');
    this.route('custom-login');
    this.route('activation', {
      path: '/activate'
    });
    this.route('filters');
    this.route('integration', function () {
      this.route('invite');
      this.route('live-schedule');
      this.route('success');
    });
  });
  var _default = Router;
  _exports.default = _default;
});