define("recruit/models/user-metrics", ["exports", "ember-data", "npm:lodash", "moment"], function (_exports, _emberData, _npmLodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tat: _emberData.default.attr('number'),
    panelSelectCount: _emberData.default.attr('number'),
    panelRejectCount: _emberData.default.attr('number'),
    panelHoldCount: _emberData.default.attr('number'),
    avgTat: Ember.computed(function () {
      if (this.get('tat')) {
        var time = _moment.default.duration(this.get('tat'), 'seconds');

        if (time.asHours() < 1) {
          return "".concat(_npmLodash.default.floor(time.asMinutes()), " Minutes");
        } else if (time.asDays() < 1) {
          return "".concat(_npmLodash.default.floor(time.asHours()), " Hours ");
        } else {
          return "".concat(_npmLodash.default.floor(time.asDays()), " Days ").concat(time.hours() ? "".concat(time.hours(), " Hours") : '');
        }
      } else {
        return this.get('tat');
      }
    }),
    recommendationRatio: Ember.computed(function () {
      var hold, rejected, selected;

      if (this.get('panelHoldCount') && this.get('panelRejectCount') && this.get('panelSelectCount')) {
        var total = this.get('panelHoldCount') + this.get('panelRejectCount') + this.get('panelSelectCount');
        selected = _npmLodash.default.round(this.get('panelSelectCount') / total, 2);
        rejected = _npmLodash.default.round(this.get('panelRejectCount') / total, 2);
        hold = _npmLodash.default.round(this.get('panelHoldCount') / total, 2);
        return "".concat(selected, " : ").concat(rejected, " : ").concat(hold);
      } else {
        return "--:--:--";
      }
    })
  });

  _exports.default = _default;
});