define("recruit/templates/components/user-helpers/user-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IdD3mS8E",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"value\"]],[20,[\"value\",\"profilePic\"]],[20,[\"value\",\"name\"]]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui centered two column grid margin-no\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"column padding-no reports-text\"],[9,\"style\",\"max-width: 60px;\"],[7],[0,\"\\n      \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"value\",\"profilePic\"]],\"ui circular centered image questions user-profile\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"middle aligned column reports-text padding-no text-left text-size-fourteen\"],[7],[0,\"\\n      \"],[6,\"p\"],[10,\"data-tooltip\",[26,[[20,[\"value\",\"name\"]]]]],[9,\"data-position\",\"bottom left\"],[9,\"class\",\"text-black text-weight-normal margin-no\"],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[7],[1,[20,[\"value\",\"name\"]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui centered two column grid margin-no\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"column padding-no reports-text\"],[9,\"style\",\"max-width: 60px;\"],[7],[0,\"\\n      \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[\"/images/user_light.svg\",\"ui circular centered image questions user-profile\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"middle aligned column reports-text padding-no text-left text-size-fourteen\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"text-grey text-weight-normal margin-no\"],[7],[0,\"\\n        \"],[6,\"span\"],[7],[0,\"Anonymous\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/user-helpers/user-details-cell-component.hbs"
    }
  });

  _exports.default = _default;
});