define("recruit/templates/recruiter/questions/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gfLliDN9",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"recruiter-view/questions/create/index\",null,[[\"selectedQuestionType\",\"question\",\"availableSections\"],[[20,[\"selectedQuestionType\"]],[20,[\"model\",\"question\"]],[20,[\"availableSections\"]]]]],false],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/questions/create.hbs"
    }
  });

  _exports.default = _default;
});