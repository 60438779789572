define("recruit/components/recruiter-view/questions/list-view-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedQuestions: Ember.A([]),
    store: Ember.inject.service(),
    isLoadingList: false,
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.dropdown').dropdown();
      this.set('userList', this.get('users'));
      this.set('skillList', this.get('skillModel'));
    },
    typeList: Ember.computed('availableSections', function () {
      var sectionArray = Ember.A([]);
      this.get('availableSections').forEach(function (section) {
        if (section.id === "6" || section.id === "5" || section.id === "4" || section.id === "10" || section.id === "23" || section.id === "17") {
          if (section.get('name') === "Objective") {
            section.set('name', "Multiple Choice"); // section.name = "Multiple Choice"

            sectionArray.pushObject(section);
            sectionArray.pushObject(Ember.Object.create({
              name: 'Multiple Choice Multiple Answer',
              label: 'MCA'
            }));
          } else {
            sectionArray.pushObject(section);
          }
        }
      });
      return sectionArray;
    }),
    showAppliedFilter: Ember.computed('customListFilters.[]', 'appliedFilters.[]', function () {
      return this.get('customListFilters.length') || this.get('appliedFilters.length');
    }),
    actions: {
      toggleFilter: function toggleFilter(property) {
        this.toggleProperty(property);
      },
      removeAddedField: function removeAddedField(array, index, customListFilters) {
        this.sendAction('removeAddedField', array, index, customListFilters);
      },
      applyFilter: function applyFilter() {
        this.sendAction('applyFilter');
      },
      resetAppliedFilters: function resetAppliedFilters() {
        this.sendAction('resetAppliedFilters');
      },
      resetAddedFilters: function resetAddedFilters() {
        this.sendAction('resetAddedFilters');
      },
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
      },
      getSelectedFilters: function getSelectedFilters(viewList, model, addedFilters) {
        var _this = this;

        if (addedFilters.get('length')) {
          addedFilters.forEach(function (obj) {
            _this.get("".concat(viewList)).unshift(model.findBy('id', obj.get('id')));
          });
          this.set("".concat(viewList), this.get("".concat(viewList)).uniqBy('id'));
        }

        this.set('isLoadingList', false);
      },
      getList: function getList(searchList, modelName) {
        var _this2 = this;

        switch (modelName) {
          case 'user':
            {
              if (searchList.get('length')) {
                searchList.forEach(function (user) {
                  if (!_this2.get('users').findBy('id', user.get('id'))) {
                    _this2.get('users').pushObject(user);
                  }
                });
              }

              this.set('userList', searchList);
              this.send('getSelectedFilters', 'userList', this.get('users'), this.get('addedFilters.created_by'));
              break;
            }

          case 'skill':
            {
              if (searchList.get('length')) {
                searchList.forEach(function (skill) {
                  if (!_this2.get('skillModel').findBy('id', skill.get('id'))) {
                    _this2.get('skillModel').pushObject(skill);
                  }
                });
              }

              this.set('skillList', searchList);
              this.send('getSelectedFilters', 'skillList', this.get('skillModel'), this.get('addedFilters.skills'));
              break;
            }
        }
      }
    }
  });

  _exports.default = _default;
});