define("recruit/templates/panel/candidate/evaluate/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j/IHMIpY",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui middle aligned grid margin-top-hundred\"],[9,\"style\",\"min-height:calc(100vh - 100px);\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"sixteen wide column padding-no\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"assc\",\"as\",\"isFormLoaded\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"forms/evaluation-forms\",null,[[\"selectedAssc\",\"model\"],[[20,[\"model\",\"assc\"]],[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[9,\"style\",\"height: 80vh;\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"style\",\"margin-top: 35vh;\"],[9,\"class\",\"six wide centered column\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[9,\"style\",\"width: 50%;\"],[7],[8],[0,\"\\n            \"],[6,\"p\"],[9,\"class\",\"text-center\"],[7],[0,\"Loading...\"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/panel/candidate/evaluate/form.hbs"
    }
  });

  _exports.default = _default;
});