define("recruit/components/recruiter-view/assessment/helpers/average-rating-view", ["exports", "recruit/components/recruiter-view/assessment/helpers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    averageRating: Ember.computed('selectedEvaluation', 'value.@each.averageRating', 'value.@each.evaluationRatings.isFulfilled', 'pc', function () {
      var selectedEvaluation = this.get('selectedEvaluation');

      if (selectedEvaluation) {
        return selectedEvaluation.get('averageRating');
      } else {
        return '--';
      }
    })
  });

  _exports.default = _default;
});