define("recruit/components/assessment/question/question-card-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('selectedAS.isExtraAs')) {
        if (this.get('questionModel.question.id')) {
          this.set('isSkill', false);
          this.get('questionModel.question').then(function (question) {
            _this.setQuestionProperties(question);
          });
        }

        if (this.get('questionModel.skill.id')) {
          this.set('isSkill', true);
          this.set('qCount', this.get('questionModel').get('q_count') ? this.get('questionModel').get('q_count') : '--');
          this.get('questionModel.skill').then(function (skill) {
            _this.setQuestionProperties(skill);
          });
        }
      }
    },
    setQuestionProperties: function setQuestionProperties(model) {
      this.setProperties({
        'title': model.get('title') ? model.get('title') : model.get('name'),
        'contentRaw': model.get('contentRaw') ? model.get('contentRaw') : null,
        'content': this.clipContent(model.get('plainTxtContent'))
      });
    },
    clipContent: function clipContent() {
      var content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (content) {
        if (content.length > 110) {
          this.set('showMore', true);
          return content.slice(0, 110) + '....';
        } else {
          this.set('showMore', false);
          return content;
        }
      } else {
        return '';
      }
    },
    actions: {
      removeQuestion: function removeQuestion(index, question) {
        this.sendAction('removeQuestion', index, question);
      },
      toggleText: function toggleText(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});