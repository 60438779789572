define("recruit/models/extended-candidate", ["exports", "recruit/models/candidate"], function (_exports, _candidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _candidate.default.extend({});

  _exports.default = _default;
});