define("recruit/routes/panel", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/config/environment", "npm:lodash"], function (_exports, _authenticatedRouteMixin, _environment, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    userRole: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (_npmLodash.default.includes(transition.targetName, 'panel.live-session.join')) {
        this.set('isLiveJoin', true);
      }

      return this._super.apply(this, arguments);
    },
    model: function model() {
      if (this.get('currentUser.me')) {
        return this.get('currentUser.me');
      } else {
        return this.get('currentUser').load();
      }
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      if (this.get('isLiveJoin')) {
        this.get('userRole').update('Evaluator');
        return controller.set('errorInRole', false);
      }

      if (_npmLodash.default.includes(transition.targetName, 'panel.event')) {
        if (_npmLodash.default.includes(transition.targetName, 'panel.event.details')) {
          controller.set('redirectURL', transition.intent.url);
        } else {
          controller.set('redirectPath', transition.targetName);
          controller.set('redirectParams', JSON.stringify(transition.queryParams));
        }
      }

      if (_environment.default.isDevelopment) {
        return controller.set('errorInRole', false);
      }

      if (!this.get('userRole.isPanel')) {
        return controller.set('errorInRole', true);
      } else {
        return controller.set('errorInRole', false);
      }
    }
  });

  _exports.default = _default;
});