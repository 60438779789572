define("recruit/services/user-permission", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    user: Ember.inject.service('current-user'),
    load: function load() {
      var _this = this;

      this.get('user.me.permissions').then(function (permissions) {
        permissions.forEach(function (permission) {
          if (permission.get('item_name')) {
            // converts event.create to hasEventCreate
            var item = _lodash.default.chain('has.' + permission.get('item_name')).split('.').map(_lodash.default.upperFirst).join().camelCase().value();

            _this.set(item, true);
          }
        });
      });
    }
  });

  _exports.default = _default;
});