define("recruit/templates/recruiter/questions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "crFdzAGe",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"recruiter-view/questions/edit-question\",null,[[\"QuestionValidations\",\"model\",\"skills\"],[[20,[\"QuestionValidations\"]],[20,[\"model\"]],[20,[\"skills\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/questions/edit.hbs"
    }
  });

  _exports.default = _default;
});