define("recruit/components/assessment-helpers/rating-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changeRatingType: function changeRatingType(type, value) {
        if (this.get('disabled')) {
          if (!this.get('toggleNag')) {
            this.toggleProperty('toggleNag');
          }

          return;
        }

        this.set('value', !value);

        if (type == 'Form' && !this.get('value')) {
          if (this.get('sectionModel.evaluationForm.id')) {
            this.sendAction('toggleModal');
          }
        }
      }
    }
  });

  _exports.default = _default;
});