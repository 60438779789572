define("recruit/components/recruiter-view/assessment/share-assessment", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.initialize();
    },
    initialize: function initialize() {
      this.setProperties({
        'showEndDatePicker': false,
        'showStartDatePicker': false
      });
    },
    becomeFocused: Ember.on('didRender', function () {
      this.$('input[type=text]').focus();
    }),
    currentDate: Ember.computed('model.start_date', function () {
      return this.get('model.start_date') ? this.get('model.start_date') : (0, _moment.default)().subtract(1, 'minutes'); //The attend link takes time to become public, there's a backend validation for start time (it will accept start time only upto 5 minutes less than the current time), hence reducing the start time by 1 minute.
    }),
    curentEndDate: Ember.computed('model.end_date', 'model.start_date', function () {
      return this.get('model.end_date') ? this.get('model.end_date') : this.get('model.start_date') ? (0, _moment.default)(this.get('model.start_date')).add(10, 'days') : (0, _moment.default)().add(10, 'days');
    }),
    changeAssessmentWindow: function changeAssessmentWindow() {
      var _this = this;

      var assessmentStart = this.get('newStartDate') ? this.get('newStartDate') : this.get('model.start_date');
      var assessmentEnd = this.get('newEndDate') ? this.get('newEndDate') : this.get('model.end_date');

      if (this.get('model.start_date') == assessmentStart || this.get('model.end_date') == assessmentEnd) {
        this.set('model.start_date', assessmentStart);
        this.set('model.end_date', assessmentEnd);
        this.get('model').save().then(function () {
          _this.get('toast').success("Assessment Window updated Successfully!");

          _this.get('toast').success("Assessment Published");
        }).catch(function (error) {
          _this.send('catchErrors', error);
        });
      }
    },
    actions: {
      catchErrors: function catchErrors(error) {
        this.sendAction('catchErorrs', error);
      },
      publish: function publish() {
        var _this2 = this;

        this.set('isLoading', true);
        !this.get('model.start_date') ? this.set('model.start_date', this.get('currentDate')) : this.get('model.start_date');
        !this.get('model.end_date') ? this.set('model.end_date', this.get('curentEndDate')) : this.get('model.end_date');
        this.set('model.publicSection.firstObject.is_public', true);
        this.get('model.publicSection.firstObject').save().then(function (as) {
          _this2.set('model.firstAssessmentSection', as);

          _this2.changeAssessmentWindow();
        }).catch(function (error) {
          _this2.sendAction('catchErorrs', error);
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      download: function download() {
        this.sendAction('download', this.get('model.firstAssessmentSection.QRCodeUrl'));
      },
      copyLink: function copyLink(text) {
        this.get('toast').success(text.text, 'Copied to clipboard');
      },
      showDatePicker: function showDatePicker(property) {
        this.toggleProperty(property);
      },
      unpublish: function unpublish(property1, property2) {
        this.sendAction('unpublish', property1, property2);
      },
      onHideStartDate: function onHideStartDate() {
        if (this.get('newStartDate')) {
          this.get('model.start_date') ? this.set('model.start_date', this.get('newStartDate')) : this.set('currentDate', this.get('newStartDate'));
        }

        this.set('showStartDatePicker', false);
      },
      onHideEndDate: function onHideEndDate() {
        if (this.get('newEndDate')) {
          this.get('model.end_date') ? this.set('model.end_date', this.get('newEndDate')) : this.set('curentEndDate', this.get('newEndDate'));
        }

        this.set('showEndDatePicker', false);
      },
      confirm: function confirm() {
        if (this.get('newEndDate') || this.get('newStartDate')) {
          this.changeAssessmentWindow();
        }

        this.send('toggleModal', 'shareAssessment');
      },
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      }
    }
  });

  _exports.default = _default;
});