define("recruit/components/live-schedule/panel-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.later(function () {
        Ember.$('.contentTooltip').popup();
      });
    },
    actions: {
      remove: function remove(model, index) {
        this.sendAction('remove', model, index);
      }
    }
  });

  _exports.default = _default;
});