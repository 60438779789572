define("recruit/mixins/profile-pic-initials", ["exports", "npm:lodash", "recruit/config/environment"], function (_exports, _npmLodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    profilePic: Ember.computed('profilePicSource', 'fullName', 'email', function () {
      if (this.get('profilePicSource')) {
        return this.get('profilePicSource');
      } else if (this.get('fullName') == this.get('email')) {
        return this.buildInitials(this.get('email'), false);
      } else if (this.get('fullName')) {
        return this.buildInitials(this.get('fullName'), true);
      } else {
        return this.buildInitials(this.get('email'), false);
      }
    }),
    buildInitials: function buildInitials(name, nameFound) {
      if (name) {
        if (nameFound) {
          var initial = _npmLodash.default.chain(name).words().map(function (n) {
            return n.substring(0, 1);
          }).join('').value().substring(0, 2);

          var x = 35;

          if (initial.length == 1) {
            x = 65;
          }

          return "".concat(_environment.default.APP.API_ROOT, "/image/placeholder?text=").concat(initial.toUpperCase(), "&bgColor=").concat(this.getBGColor(), "&x=").concat(x, "&y=140&fillColor=fff&fSize=100");
        } else {
          return '/images/user-icon-cuton.jpg'; // let initials = _.upperCase(name.substring(0,2));
          // return `https://apiv4.talview.com/image/placeholder?text=${initials}&bgColor=${this.getBGColor()}&x=35&y=140&fillColor=fff`
        }
      } else {
        return '/images/user_light.svg';
      }
    },
    colors: ['29bb9c', '4ba2f2', '1a75b7', '553b80', '28334d', 'f2bf35', 'f1793e', 'cf4945', 'dc3d76', '56514c'],
    getBGColor: function getBGColor() {
      return this.get('colors')[this.get('currentId') % 10];
    }
  });

  _exports.default = _default;
});