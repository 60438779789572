define("recruit/routes/setup/security", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/validations/update-password"], function (_exports, _authenticatedRouteMixin, _updatePassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    PasswordValidations: _updatePassword.default,
    model: function model() {
      return Ember.RSVP.hash({
        user: this.get('session.user'),
        qrCode: this.store.queryRecord('generateQr', {
          get: true
        }).then(function (generatedQr) {
          return generatedQr;
        }),
        updatePassword: this.get('store').createRecord('reset-password')
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);

      controller.set('password', models.updatePassword);
      controller.set('user', models.user);
      controller.set('qrCode', models.qrCode);
      controller.set('canScan', true);
    },
    actions: {
      toggleThis: function toggleThis(property) {
        this.controller.toggleProperty(property);
      },
      next: function next(changeset) {
        var _this = this;

        if (changeset.get('isValid')) {
          var passwordResetToken = localStorage.getItem('tv_password_reset_token');

          if (passwordResetToken) {
            changeset.set('token', passwordResetToken);
            changeset.save().then(function () {
              var user = _this.controller.get('user');

              user.set('status', 25);
              user.save();
              localStorage.removeItem('tv_password_reset_token');

              _this.get('router').transitionTo('dashboard');

              _this.get('toast').success('Your account has been setup', 'Successful');
            }).catch(function (e) {
              e.errors.forEach(function (error) {
                _this.get('toast').error(error.detail, 'Please try again');
              });
            });
          } else {
            this.get('toast').error('Something went wrong', 'Please try after some time.');
          }
        } else {
          /*if changeset is invalid*/
          this.get('toast').error('Something went wrong', 'Please try after some time.');
        }
      }
    }
  });

  _exports.default = _default;
});