define("recruit/components/panel-evaluation/section-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canSeeAnswers: Ember.computed('selectedAssc.section', 'answers.length', function () {
      return this.get('selectedAssc.section.shouldHaveAnswers') ? this.get('answers.length') : true;
    }),
    canEvaluate: Ember.computed('selectedAssc', 'selectedAssc.section', function () {
      if (this.get('isPanel')) {
        return this.get('selectedAssc.id') == this.get('panelCandidate.assc.id') && this.get('selectedAssc.section.canEvaluateAnswers');
      } else {
        return this.get('selectedAssc.section.canEvaluateAnswers');
      }
    }),
    actions: {
      gotoNextAnswer: function gotoNextAnswer(answer) {
        this.sendAction('gotoNextAnswer', answer);
      },
      toggleEvaluations: function toggleEvaluations() {
        this.sendAction('toggleEvaluations');
      }
    }
  });

  _exports.default = _default;
});