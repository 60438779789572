define("recruit/templates/components/live-schedule/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vY9atT2/",
    "block": "{\"symbols\":[\"occurrence\",\"timetable\",\"calendar\",\"&default\"],\"statements\":[[4,\"as-calendar\",null,[[\"title\",\"occurrences\",\"showHeader\",\"defaultTimeZoneQuery\",\"startFromDate\",\"dayStartingTime\",\"dayEndingTime\",\"defaultOccurrenceTitle\",\"timeSlotDuration\",\"timeSlotHeight\",\"onAddOccurrence\",\"onNavigateWeek\"],[\"Schedule Live\",[20,[\"occurrences\"]],false,\"New York|London|Dubai|Hong Kong\",true,\"00:00\",\"23:59\",\"Schedule\",\"0:30\",15,[25,\"action\",[[19,0,[]],\"calendarAddOccurrence\"],null],[25,\"action\",[[19,0,[]],\"calendarNavigateWeek\"],null]]],{\"statements\":[[0,\"  \"],[1,[25,\"live-schedule/calendar-occurrence\",null,[[\"class\",\"tempLiveSession\",\"timeZone\",\"timeSlotHeight\",\"timetable\",\"timeSlotDuration\",\"isResizable\",\"isDraggable\",\"revertModel\"],[[19,1,[\"content\",\"bgClass\"]],[19,1,[]],[19,3,[\"timeZone\"]],[19,3,[\"timeSlotHeight\"]],[19,2,[]],[19,3,[\"timeSlotDuration\"]],false,false,\"revertModel\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2,3]},null],[11,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-schedule/calendar.hbs"
    }
  });

  _exports.default = _default;
});