define("recruit/templates/components/reports/dashboard/dashboard-list-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cJ9UOZ7H",
    "block": "{\"symbols\":[\"listItem\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui three column grid margin-no\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"tile\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"column\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui center aligned grid margin-no\"],[9,\"style\",\"background-color: #FFF; cursor: pointer; box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.18); border-radius: 5px;\"],[3,\"action\",[[19,0,[]],\"redirect\",[19,1,[\"redirectUrl\"]],[19,1,[\"parameter\"]]]],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column\"],[7],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"text-size-normal text-black text-weight-medium margin-no\"],[7],[1,[19,1,[\"title\"]],false],[8],[0,\"\\n\"],[0,\"      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"fourteen wide column\"],[10,\"style\",[26,[\"background-image:url('/images/mis_report/\",[19,1,[\"graphName\"]],\"-graph.png'); height: 120px; background-position: center; background-size: contain; background-repeat: no-repeat; margin-bottom: 10px;\"]]],[7],[0,\"\\n\"],[0,\"      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column padding-no\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"padding-md text-black full-width text-left\"],[9,\"style\",\"background-color: #d9e7f1; min-height: 70px;\"],[7],[0,\"\\n          \"],[1,[19,1,[\"description\"]],true],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/dashboard/dashboard-list-tab.hbs"
    }
  });

  _exports.default = _default;
});