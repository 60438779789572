define("recruit/components/filters/section-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    statusList: Ember.computed(function () {
      return this.get('store').query('status', {
        type: 10
      });
    })
  });

  _exports.default = _default;
});