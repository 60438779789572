define("recruit/components/reports/helpers/assessment-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('searchText', null);
    },
    searchTextObserver: Ember.observer('searchText', function () {
      var _this = this;

      if (this.get('searchText')) {
        Ember.run.debounce(this, function () {
          _this.sendAction('tableActions.searchTable', _this.get('searchText'));
        }, 1000);
      } else {
        this.send('clearSearchText');
      }
    }),
    actions: {
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
        this.sendAction('tableActions.searchTable', this.get('searchText'));
      }
    }
  });

  _exports.default = _default;
});