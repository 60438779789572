define("recruit/components/recruiter-view/questions/create/code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.set('testCasesArray', Ember.A([]));
      var testCasesArray = [{
        test_case: this.get('changeset.test_case_1'),
        result: this.get('changeset.test_result_1')
      }, {
        test_case: this.get('changeset.test_case_2'),
        result: this.get('changeset.test_result_2')
      }, {
        test_case: this.get('changeset.test_case_3'),
        result: this.get('changeset.test_result_3')
      }, {
        test_case: this.get('changeset.test_case_4'),
        result: this.get('changeset.test_result_4')
      }, {
        test_case: this.get('changeset.test_case_5'),
        result: this.get('changeset.test_result_5')
      }];
      this.set('testCasesArray', testCasesArray);
    }
  });

  _exports.default = _default;
});