define("recruit/validations/user-account", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = {
    name: [(0, _validators.validatePresence)(true)],
    email: [(0, _validators.validatePresence)(true)],
    phone: [(0, _validators.validateNumber)({
      allowBlank: true,
      integer: true
    })]
  };
  _exports.default = _default;
});