define("recruit/components/range-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.initializeDatePicker();
    },
    initializeDatePicker: function initializeDatePicker() {
      var today = new Date();
      Ember.$("#".concat(this.get('startDateID'))).calendar({
        type: 'datetime',
        maxDate: new Date(this.get('eventEndDate')),
        minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
        endCalendar: Ember.$("#".concat(this.get('endDateID'))),
        popupOptions: {
          position: 'right center'
        }
      }).calendar('focus');
      Ember.$("#".concat(this.get('endDateID'))).calendar({
        type: 'datetime',
        maxDate: new Date(this.get('eventEndDate')),
        startCalendar: Ember.$("#".concat(this.get('startDateID'))),
        popupOptions: {
          position: 'right center'
        }
      });
    }
  });

  _exports.default = _default;
});