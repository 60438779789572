define("recruit/routes/user/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel() {// this.get('router').transitionTo('user.details.profile');
    }
  });

  _exports.default = _default;
});