define("recruit/components/reports/filters/report-filter-dropdown", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setFilter: function setFilter(val, obj) {
        this.sendAction('filterAction', val, obj);
      },
      removeFilter: function removeFilter(x, itemId) {
        var ids = this.get('selectedOptions').map(function (a) {
          return a.get('id');
        });

        if (_npmLodash.default.includes(ids, itemId)) {
          this.get('selectedOptions').removeAt(ids.indexOf(itemId));
        }

        this.sendAction('removeFilter', x, itemId);
      }
    }
  });

  _exports.default = _default;
});