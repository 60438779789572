define("recruit/components/candidate-helpers/bulk-import/success-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    actions: {
      toggleModal: function toggleModal(property, setTitle) {
        if (setTitle) {
          this.set('displayTitle', setTitle);
        }

        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});