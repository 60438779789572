define("recruit/components/model-table/panel/candidate", ["exports", "recruit/mixins/table-common"], function (_exports, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    modelType: 'panel-candidate',
    hasSingleSelectection: Ember.computed.equal('table.selectedRows.length', 1),
    // columns: Ember.computed(function() {
    //   return [
    //     {
    //       valuePath: 'assessmentCandidate.candidate',
    //       cellComponent: 'helpers/table-profile-picture',
    //       sortable: false,
    //       width: '60px',
    //       classNames: ['table-head']
    //     },
    //     {
    //       label: 'Candidate',
    //       valuePath: 'assessmentCandidate.candidate',
    //       cellComponent: 'candidate-helpers/candidate-details',
    //       classNames: ['table-head']
    //     },
    //     {
    //       label : 'Assessment / Batch',
    //       valuePath: 'assessmentCandidate.assessment',
    //       cellComponent: 'assessment-helpers.assessment-details',
    //       cellClassNames: 'text-left text-weight-light ellipsis padding-md-top',
    //       classNames: ['table-head text-left']
    //     },
    //     {
    //       label: 'Section Status',
    //       cellComponent: 'candidate-helpers.section-status',
    //       valuePath: 'assessmentCandidate',
    //       sortable: false,
    //       cellClassNames: 'status-scroll',
    //       classNames: ['table-head']
    //     },
    //     {
    //       label: 'Evaluation Status',
    //       cellComponent: 'status-helpers.evaluation',
    //       valuePath: 'evaluationStatus',
    //       sortable: false,
    //       cellClassNames: 'text-center',
    //       width: '100px',
    //       classNames: ['table-head']
    //     },
    //     {
    //       label: 'Pending Since',
    //       valuePath: 'pending_since',
    //       cellComponent: 'helpers.date-view',
    //       showTimeAgo: true,
    //       width: '140px',
    //       classNames: ['table-head']
    //     }
    //   ];
    // }),
    actions: {
      onRowClick: function onRowClick(row) {
        this.get('router').transitionTo('panel.candidate.evaluate', row.get('id'));
      },
      rowChecked: function rowChecked(row) {
        this.get('table.selectedRows').pushObject(row);
      },
      rowUnChecked: function rowUnChecked(row) {
        this.get('table.selectedRows').removeObject(row);
      }
    }
  });

  _exports.default = _default;
});