define("recruit/templates/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0JhSUsP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-wrapper\",null,[[\"showSupport\"],[[20,[\"showSupport\"]]]],{\"statements\":[[6,\"div\"],[9,\"class\",\"two wide column\"],[7],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"five wide column\"],[7],[0,\"\\n  \"],[6,\"img\"],[9,\"src\",\"/images/cuton_thumbsup.png\"],[9,\"alt\",\"cuton\"],[9,\"class\",\"ui image cuton-updown\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"nine wide middle aligned left aligned column\"],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"ui center aligned grey text\"],[7],[0,\"\\n  You've successfully logged out.\\n  \"],[8],[0,\"\\n  \"],[4,\"link-to\",[\"login\"],null,{\"statements\":[[6,\"button\"],[9,\"class\",\"ui blue button margin-top-thirty\"],[7],[1,[25,\"fa-icon\",[\"arrow-left\"],[[\"class\"],[\"text-grey\"]]],false],[0,\"Back to Log in page\"],[8]],\"parameters\":[]},null],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/logout.hbs"
    }
  });

  _exports.default = _default;
});