define("recruit/templates/components/recruiter-view/questions/helpers/question-table-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uecPSG0e",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui blue segment table-footer-background-grey padding-bg-left padding-bg-right\"],[7],[0,\"\\n  \"],[6,\"p\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"rightText\"]],\"float-right\",\"text-right\"],null],\" text-weight-medium text-black\"]]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"selectedRows\",\"length\"]]],null,{\"statements\":[[0,\"      Selected: \"],[1,[20,[\"selectedRows\",\"length\"]],false],[0,\" |\\n\"]],\"parameters\":[]},null],[0,\"      Total:\\n\"],[4,\"if\",[[20,[\"isCountLoading\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"fa-icon\",[\"spinner\"],[[\"pulse\",\"class\"],[\"true\",\"text-grey\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[18,\"totalCount\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/question-table-footer.hbs"
    }
  });

  _exports.default = _default;
});