define("recruit/models/actions/event-candidate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    isNotify: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    event: _emberData.default.belongsTo('event'),
    candidates: _emberData.default.attr(),
    assessmentSection: _emberData.default.belongsTo('assessment-section')
  });

  _exports.default = _default;
});