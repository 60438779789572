define("recruit/controllers/user/settings/account", ["exports", "recruit/controllers/master"], function (_exports, _master) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _master.default.extend({
    timeZoneSelected: Ember.computed(function () {
      if (this.get('timeZone')) {
        return this.get('store').peekRecord('settings-allowed', this.get('timeZone.firstObject.settings_allowed_value_id'));
      }
    }) // actions: {
    //   setTimeZone(key) {
    //     let timeZone = this.get('settingsData');
    //     timeZone.set('settings_id', 1);
    //     timeZone.set('settings_allowed_value_id', key)
    //   }
    // }

  });

  _exports.default = _default;
});