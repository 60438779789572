define("recruit/components/panel/events/accept-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      askDecline: function askDecline() {
        this.sendAction("askDecline");
      }
    }
  });

  _exports.default = _default;
});