define("recruit/data/filters/p-ls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 5889,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Timezone",
      "model": "settingsAllowed",
      "isRemote": true,
      "modelQuery": {
        "settings_id": 1
      },
      "objectType": "p-ls",
      "queryTemplate": "(timezone:{{value}})"
    }
  }, {
    "id": 5890,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Scheduled by",
      "model": "user",
      "isRemote": true,
      "objectType": "p-ls",
      "queryTemplate": "(hosted_by: {{value}})"
    }
  }, {
    "id": 5891,
    "type": "filter/master",
    "attributes": {
      "list": [{
        "key": "15 minutes",
        "value": "900"
      }, {
        "key": "30 minutes",
        "value": "1800"
      }, {
        "key": "45 minutes",
        "value": "2700"
      }, {
        "key": "1 hour",
        "value": "3600"
      }, {
        "key": "1 hour 30 minutes",
        "value": "4800"
      }, {
        "key": "2 hours",
        "value": "7200"
      }],
      "type": "list",
      "field": "Duration",
      "isRemote": false,
      "objectType": "p-ls",
      "queryTemplate": "(duration: {{value}})"
    }
  }, {
    "id": 5892,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Assessment Name",
      "model": "assessment",
      "isRemote": true,
      "objectType": "p-ls",
      "queryTemplate": "(assessment.id: {{value}})"
    }
  }, {
    "id": 5888,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Starts At",
      "objectType": "p-ls",
      "queryTemplate": "(starts_at:[{{start}} TO {{end}} ])"
    }
  }, {
    "id": 5894,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Assessment External ID",
      "objectType": "p-ls",
      "queryTemplate": "(assessment.external_id{{exact}}{{value}})"
    }
  }, {
    "id": 5895,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate #(id)",
      "objectType": "p-ls",
      "queryTemplate": "({{condition}} candidate.external_id{{exact}}{{value}})"
    }
  }, {
    "id": 5896,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate First Name",
      "objectType": "p-ls",
      "queryTemplate": "({{condition}} candidate.first_name{{exact}}{{value}})"
    }
  }, {
    "id": 5897,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate Last Name",
      "objectType": "p-ls",
      "queryTemplate": "({{condition}} candidate.last_name{{exact}}{{value}})"
    }
  }, {
    "id": 5898,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate Email",
      "objectType": "p-ls",
      "queryTemplate": "({{condition}} candidate.email{{exact}}{{value}})"
    }
  }, {
    "id": 5899,
    "type": "filter/master",
    "attributes": {
      "list": [{
        "key": "Scheduled",
        "value": 1
      }, {
        "key": "In Progress",
        "value": 2
      }, {
        "key": "Completed",
        "value": 3
      }, {
        "key": "Cancelled",
        "value": 10
      } // {
      //   "key": "Panel No Show",
      //   "value": 11
      // },
      // {
      //   "key": "Candidate No Show",
      //   "value": 12
      // },
      // {
      //   "key": "Both No Show",
      //   "value": 13
      // }
      ],
      "type": "list",
      "field": "Live Status",
      "isRemote": false,
      "objectType": "p-ls",
      "queryTemplate": "(status: {{value}})"
    }
  }, {
    "id": 5900,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Panel",
      "model": "user",
      "isRemote": true,
      "modelQuery": {
        "roles": "Evaluator"
      },
      "objectType": "p-ls",
      "queryTemplate": "(liveAttendees.user_id:{{value}})"
    }
  }, {
    "id": 5901,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Panel Email",
      "objectType": "p-ls",
      "queryTemplate": "({{condition}} liveAttendees.email{{exact}}{{value}}) AND (liveAttendees.type: 2)"
    }
  }];
  _exports.default = _default;
});