define("recruit/components/recruiter-view/assessment/details/assessment-candidate", ["exports", "npm:lodash", "recruit/config/environment"], function (_exports, _npmLodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    userPermission: Ember.inject.service('user-permission'),
    isDevelopment: _environment.default.isDevelopment,
    panelSearchText: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('defaultPanels', Ember.A([]));
      this.set('sectionFilters', Ember.Object.create({
        type: 'section',
        value: '',
        subFilters: Ember.A([]),
        appliedFilters: Ember.A([])
      }));
      this.set('addedFilters', Ember.Object.create({
        panel: Ember.A([]),
        finalStatus: Ember.A([]),
        proviewRatings: Ember.A([]),
        events: Ember.A([])
      }));
      this.set('dateFilters', Ember.Object.create({
        type: 'date',
        values: Ember.A([])
      })); // this.get('store').push({
      //   data: ProviewFilters
      // });
    },
    eventList: Ember.computed('model.events.[]', function () {
      return this.get('model.events');
    }),
    hideFilter: false,
    masterFilters: Ember.computed(function () {
      return this.get('store').peekAll('filter/master', {
        filter: {
          objectType: 'as'
        }
      });
    }),
    showAppliedFilter: Ember.computed('customListFilters.[]', 'appliedFilters.[]', function () {
      return this.get('customListFilters.length') || this.get('appliedFilters.length');
    }),
    status: Ember.computed('reset', function () {
      return Ember.A([Ember.Object.create({
        sort_key: 70,
        id: 6,
        label: 'Selected',
        type: 10
      }), Ember.Object.create({
        sort_key: 90,
        id: 8,
        label: 'Rejected',
        type: 10
      }), Ember.Object.create({
        sort_key: 80,
        id: 7,
        label: 'Hold',
        type: 10
      }), Ember.Object.create({
        sort_key: 30,
        id: 3,
        label: 'In Progress',
        type: 10
      }), Ember.Object.create({
        sort_key: 50,
        id: 4,
        label: 'Completed',
        type: 10
      }), Ember.Object.create({
        sort_key: 60,
        id: 5,
        label: 'Evaluated',
        type: 10
      }), Ember.Object.create({
        sort_key: 20,
        id: 2,
        label: 'Invited',
        type: 10
      })]);
    }),
    coLWidth: function coLWidth(length) {
      var columns = this.set('masterColumns', this.get('store').peekAll('column/master').filterBy('tableType', 'a-i-c')).sortBy('sort');
      var fixedWidth = 50;
      columns.filter(function (item) {
        if (item.get('isDefault')) {
          fixedWidth = fixedWidth + (item.get('width') ? parseInt(item.get('width').replace('px', '')) : 0);
        }
      });
      var remainingWidth = Ember.$('#assessmentCandidateTable').innerWidth() - 2 - fixedWidth;
      return remainingWidth / length < 150 ? '150px' : "".concat(remainingWidth / length, "px");
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // this.set('proviewFilters', this.get('store').peekRecord('filter/master', 6000));


      this.set('sectionDataFilters', this.get('masterFilters').filter(function (item) {
        return item.get('objectType') == 'as' && item.get('type') != 'date';
      }));
      this.set('dateDataFilters', this.get('masterFilters').filter(function (item) {
        return item.get('objectType') == 'as' && item.get('type') == 'date';
      }));

      if (!this.get('defaultPanels.length')) {
        this.get('store').query('panel', {
          assessment_id: this.get('model.id')
        }).then(function (res) {
          _this.set('defaultPanels', res.map(function (panel) {
            return panel.get('user');
          }).uniqBy('id'));

          _this.set('panelList', _this.get('defaultPanels'));
        });
      }

      Ember.run.next('afterRender', function () {
        Ember.$('.ui.dropdown').dropdown({
          onShow: function onShow() {
            if (_this.get('showFilters')) {
              _this.send('toggleFilter', 'showFilters');
            }
          }
        });
      });
      this.constructSectionColumns();
    },
    constructSectionColumns: function constructSectionColumns() {
      var _this2 = this;

      var width = 0;
      this.get('model.assessmentSections').then(function (assessmentSections) {
        return Ember.RSVP.resolve(_this2.getAdditionalColumn(assessmentSections)).then(function () {
          width = _this2.coLWidth(assessmentSections.length);
          return assessmentSections;
        });
      }).then(function (assessmentSections) {
        return Ember.RSVP.all(assessmentSections.map(function (assessmentSection, index) {
          return assessmentSection.get('section').then(function (section) {
            _this2.set('hasVm', section.get('isVm'));

            _this2.get('store').push({
              data: {
                "id": index + 12,
                "type": "column/master",
                "attributes": {
                  label: section.get('title'),
                  classNames: ['ember-table-head text-center'],
                  cellClassNames: ['child padding-no text-center asst-section-candidate-status padding-no-left padding-no-right'],
                  extra: {
                    as: assessmentSection,
                    indexValue: index,
                    lastValue: assessmentSections.length - 1,
                    tooltip: section.get('title')
                  },
                  valuePath: 'assessmentSectionCandidates',
                  cellComponent: 'model-table/recruiter/assessment/assessment-sections',
                  width: width,
                  sortWeight: 4 + index,
                  component: 'helpers/table-header-view',
                  tableType: "a-i-c",
                  isDefault: true,
                  sortable: false
                }
              }
            });
          });
        }));
      }).then(function () {
        return _this2.get('store').findAll('column/applied');
      }).then(function () {
        return _this2.set('masterColumns', _this2.get('store').peekAll('column/master').filterBy('tableType', 'a-i-c'));
      }).then(function (masterColumns) {
        var columns = Ember.A([]);
        masterColumns.forEach(function (column) {
          if (column.get('isColumnSelected')) {
            return columns.pushObject(column.toJSON());
          }
        });
        columns.pushObject({
          component: "helpers/table-checkboxs",
          cellComponent: "helpers/row-checkbox",
          sortable: false,
          width: '50px',
          isDefault: true,
          classNames: ['ember-table-head fixed-column padding-no ember-table-checkbox-th'],
          cellClassNames: ['fixed-column child padding-no'],
          sortWeight: 1,
          fixed: true
        });

        if (columns.get('length') > 8) {
          var column = _npmLodash.default.find(columns, ['label', 'Name']);

          column.cellClassNames = ['fixed-column padding-no fixed-column-shadow'];
          column.classNames = ['text-grey ember-table-head text-left fixed-column fixed-column-shadow'];
        }

        _this2.set('columns', columns.sortBy('sortWeight'));

        _this2.set('renderTable', true);
      });
    },
    getAdditionalColumn: function getAdditionalColumn(assessmentSections) {
      if (this.get('hasResumeMatch')) {
        this.get('store').push({
          data: [{
            "id": 6,
            "type": "column/master",
            "attributes": {
              "cellClassNames": ["text-center table-view"],
              "cellComponent": "candidate-helpers/resume-score",
              "classNames": ["text-grey ember-table-head text-center cursor-pointer"],
              "sortWeight": 41,
              "label": "Resume score",
              "sortable": true,
              "valuePath": "resumeMatchScore",
              "width": "160px",
              "isDefault": true,
              "component": 'helpers/table-header-view',
              "sortAttribute": "meta.resume-match-score",
              "tableType": "a-i-c",
              "extra": {
                columnType: "Resume subscores"
              }
            }
          }, {
            "id": 7,
            "type": "column/master",
            "attributes": {
              "cellClassNames": ["text-center table-view"],
              "cellComponent": "candidate-helpers/resume-score",
              "classNames": ["text-grey ember-table-head text-center cursor-pointer ellipsis-table"],
              "sortWeight": 42,
              "isSelected": false,
              "label": "Education",
              "sortable": true,
              "valuePath": "educationMatchScore",
              "width": "150px",
              "isDefault": false,
              "sortAttribute": "meta.education-match-score",
              "tableType": "a-i-c",
              "extra": {
                columnType: "Resume subscores",
                tooltip: "Academic match score"
              },
              "component": 'helpers/table-header-view'
            }
          }, {
            "id": 8,
            "type": "column/master",
            "attributes": {
              "cellClassNames": ["text-center table-view"],
              "cellComponent": "candidate-helpers/resume-score",
              "classNames": ["text-grey ember-table-head text-center cursor-pointer ellipsis-table"],
              "sortWeight": 43,
              "isSelected": false,
              "label": "Experience",
              "sortable": true,
              "valuePath": "experienceMatchScore",
              "width": "150px",
              "isDefault": false,
              "sortAttribute": "meta.experience-match-score",
              "tableType": "a-i-c",
              "extra": {
                columnType: "Resume subscores",
                tooltip: "Experience match score"
              },
              "component": 'helpers/table-header-view'
            }
          }, {
            "id": 9,
            "type": "column/master",
            "attributes": {
              "cellClassNames": ["text-center table-view"],
              "cellComponent": "candidate-helpers/resume-score",
              "classNames": ["text-grey ember-table-head text-center cursor-pointer ellipsis-table"],
              "sortWeight": 44,
              "isSelected": false,
              "label": "Skill",
              "sortable": true,
              "valuePath": "skillMatchScore",
              "sortAttribute": "meta.skill-match-score",
              "width": "150px",
              "isDefault": false,
              "tableType": "a-i-c",
              "extra": {
                columnType: "Resume subscores",
                tooltip: "Skill match score"
              },
              "component": 'helpers/table-header-view'
            }
          }]
        });
      }

      var containsProctoredSection = assessmentSections.map(function (as) {
        return as.get('is_proctor');
      });
      this.set('hasProctor', _npmLodash.default.includes(containsProctoredSection, true));

      if (this.get('hasProctor')) {
        this.get('store').push({
          data: {
            "id": 10,
            "type": "column/master",
            "attributes": {
              "label": "Proview Index",
              "width": "150px",
              "isShow": true,
              "isDefault": true,
              "tableType": "a-i-c",
              "valuePath": "formattedRating",
              "classNames": ["text-grey ember-table-head text-center cursor-pointer ellipsis-table"],
              "sortWeight": 46,
              "cellComponent": "helpers/proctor-rating",
              "sortable": false,
              "cellClassNames": ["text-center"]
            }
          }
        });
      }

      if (this.get('model.tbiQcEnabled')) {
        this.get('store').push({
          data: {
            "id": 11,
            "type": "column/master",
            "attributes": {
              label: 'Behavioral Profile',
              valuePath: 'formattedTbiStatus',
              cellComponent: "candidate-helpers/tbi-behavioral-rating",
              width: '185px',
              sortWeight: 45,
              classNames: ['text-grey ember-table-head text-center cursor-pointer'],
              cellClassNames: ['padding-no resumeColumn ignoreRowClick asst-candidate-status text-center'],
              "sortable": true,
              "sortAttribute": "meta.behavioural_fitment_index",
              "isShow": true,
              "isDefault": true,
              "tableType": "a-i-c",
              "component": 'helpers/table-header-view'
            }
          }
        });
      }
    },
    actions: {
      toggleFilter: function toggleFilter(property) {
        this.toggleProperty(property);
      },
      applyFilter: function applyFilter(showFilters) {
        if (this.get('showAppliedFilter')) {
          this.set('hideFilter', true);
        }

        if (showFilters) {
          this.send('toggleFilter', "".concat(showFilters));
        }

        this.set('appliedListFilters', Ember.Object.create(_npmLodash.default.groupBy(_npmLodash.default.concat(this.get('customListFilters'), this.get('appliedFilters')), 'type')));
      },
      resetFilter: function resetFilter() {
        this.setProperties({
          'sectionFilters.value': '',
          'sectionFilters.subFilters': Ember.A([]),
          'sectionFilters.appliedFilters': Ember.A([]),
          'addedFilters.panel': Ember.A([]),
          'addedFilters.finalStatus': Ember.A([]),
          'addedFilters.events': Ember.A([]),
          'addedFilters.advanceFilters': Ember.A([]),
          'addedFilters.evalDate': Ember.A([]),
          'dateFilters.values': Ember.A([]),
          'assessmentFilters': null
        });
      },
      resetAppliedFilters: function resetAppliedFilters() {
        this.setProperties({
          'appliedFilters': Ember.A([]),
          'customListFilters': Ember.A([]),
          'assessmentFilters': null,
          'appliedListFilters': Ember.A([])
        });
        this.send('resetFilter');

        if (this.get('showFilters')) {
          this.send('toggleFilter', 'showFilters');
        }
      },
      removeAppliedFilter: function removeAppliedFilter(filter, array, index) {
        var _this3 = this;

        filter.destroyRecord().then(function () {
          if (array) {
            var sectionFilters = _this3.get('sectionFilters.subFilters');

            if (!filter.get('filterValues.asID')) {
              sectionFilters.removeAt(_npmLodash.default.findIndex(sectionFilters, ['section.section.title', filter.get('sectionName')]));
            } else {
              sectionFilters.removeAt(_npmLodash.default.findIndex(sectionFilters, ['section.id', filter.get('filterValues.asID')]));
            }

            if (index) {
              array.removeAt(index);
            }
          }
        });
      },
      removeAddedField: function removeAddedField(array, index, customListFilters) {
        if (customListFilters) {
          var selectedObject = array.objectAt(index);

          switch (selectedObject.get('type')) {
            case 'panel':
              {
                this.get('addedFilters.panel.length') ? this.get('addedFilters.panel').removeAt(_npmLodash.default.findIndex(this.get('addedFilters.panel'), ['id', selectedObject.get('id')])) : null;
                break;
              }

            case 'finalStatus':
              {
                this.get('addedFilters.finalStatus.length') ? this.get('addedFilters.finalStatus').removeAt(_npmLodash.default.findIndex(this.get('addedFilters.finalStatus'), ['sort_key', selectedObject.get('sort_key')])) : null;
                break;
              }

            case 'proviewRatings':
              {
                this.get('addedFilters.proviewRatings.length') ? this.get('addedFilters.proviewRatings').removeObject(array) : null;
                break;
              }

            case 'events':
              {
                this.get('addedFilters.events.length') ? this.get('addedFilters.events').removeObject(array) : null;
                break;
              }

            case 'evalDate':
              {
                this.get('addedFilters.evalDate.length') ? this.get('addedFilters.evalDate').removeObject(array) : null;
                break;
              }

            case 'advanceFilters':
              {
                this.get('addedFilters.advanceFilters.length') ? this.get('addedFilters.advanceFilters').removeObject(array) : null;
                break;
              }
            //     case 'date' : {
            //       _.remove(this.get('dateFilters.values'), ['id', selectedObject.get('id')]);
            //       break;
            //     }
            //     case 'section' : {
            //       _.remove(this.get('sectionFilters.appliedFilters'), ['id', selectedObject.get('id')]);
            //       let sectionFilters = this.get('sectionFilters.subFilters')
            //       if(!selectedObject.get('filterValues.asID')){
            //         sectionFilters.removeAt(_.findIndex(sectionFilters, ['section.section.title', selectedObject.get('sectionName')]))
            //       } else {
            //         sectionFilters.removeAt(_.findIndex(sectionFilters, ['section.id', selectedObject.get('filterValues.asID')]))
            //       }
            //       this.send('removeAppliedFilter', selectedObject)
            //       break;
            //     }
          }

          if (this.get('showFilters')) {
            this.send('toggleFilter', 'showFilters');
          }

          customListFilters.removeObject(selectedObject);
        }

        if (array.length) {
          array.removeAt(index);
        }
      },
      setCandidates: function setCandidates(selectedCandidates) {
        this.set('candidates', selectedCandidates);
      },
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
      }
    }
  });

  _exports.default = _default;
});