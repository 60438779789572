define("recruit/routes/panel/live-session/join", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      skipEnddate: {
        replace: true
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('isLsForm', false);
      controller.set('liveSession', model.get('liveSession'));
      return model.get('liveSession').then(function (ls) {
        return ls.get('assc').then(function (assc) {
          if (assc || assc.get('content')) {
            controller.set('selectedAssc', assc);
            return assc.get('section').then(function (s) {
              return Ember.RSVP.resolve(s).then(function (section) {
                controller.set('section', section);

                if (section.get('evaluationFormUrl')) {
                  return controller.set('isLsForm', true);
                } else {
                  return controller.set('evaluationType', 'comment');
                }
              });
            });
          }
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      return model.get('liveSession').then(function (ls) {
        return {
          startAt: ls.get('starts_at'),
          endAt: ls.get('ends_at')
        };
      }).then(function (dates) {
        var startAt = dates.startAt.subtract(10, 'minutes');

        if (dates.startAt && startAt.isAfter((0, _moment.default)()) && !transition.queryParams.skipEnddate) {
          return _this.get('router').replaceWith('panel.live-session.view', model.get('liveSession.id'));
        } else if (dates.endAt && dates.endAt.isBefore((0, _moment.default)()) && !transition.queryParams.skipEnddate) {
          return _this.get('router').replaceWith('panel.live-session.view', model.get('liveSession.id'));
        }
      });
    },
    actions: {
      back: function back() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});