define("recruit/components/answer-views/question-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Slick from 'npm:slick-carousel';
  var _default = Ember.Component.extend({
    totalTab: Ember.computed('answers', function () {
      if (!(this.get('answers.length') % 5)) {
        return this.get('answers.length') / 5 - 1;
      } else {
        return Math.floor(+this.get('answers.length') / 5);
      }
    }),
    currentTab: 1,
    didRender: function didRender() {
      Ember.run.next(function () {
        Ember.$('[data-html]').popup({
          position: 'bottom center',
          inline: false,
          lastResort: true
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('[data-html]').popup('destroy');
    },
    currentShown: 5,
    isPreviousDisabled: Ember.computed('currentShown', function () {
      return this.get('currentShown') === 5;
    }),
    isNextDisabled: Ember.computed('currentShown', 'answers.isFulfilled', function () {
      return this.get('currentShown') === this.get('answers.length');
    }),
    emptyAnswers: Ember.computed('answers.length', function () {
      var l = this.get('answers.length');
      return new Array(5 - l % 5);
    }),
    isAnswerOverflow: Ember.computed('answers.isFulfilled', function () {
      return this.get('answers.length') > 5;
    }),
    actions: {
      changeAnswer: function changeAnswer(answer) {
        this.sendAction('changeAnswer', answer);
      },
      next: function next() {
        var _this = this;

        // if(this.get('currentShown') >= this.get('answers.length')) {
        //   $('#slider').animate({
        //     marginLeft: '0px'
        //   }, 500, () => {
        //     this.set('currentShown', 5)
        //   });
        // } else {
        if (this.get('currentTab') == this.get('totalTab')) {
          this.set('isNextDisabled', true);
        }

        if (this.get('currentTab') <= this.get('totalTab')) {
          this.set('currentTab', this.get('currentTab') + 1);
          Ember.$('.slider').animate({
            marginLeft: '-=177px'
          }, 500, function () {
            _this.set('currentShown', _this.get('currentShown') + 5);
          });
        }
      },
      previous: function previous() {
        var _this2 = this;

        if (this.get('currentTab') > 1) {
          this.set('currentTab', this.get('currentTab') - 1);
          this.set('isNextDisabled', false);
          Ember.$('.slider').animate({
            marginLeft: '+=177px'
          }, 500, function () {
            _this2.set('currentShown', _this2.get('currentShown') - 5);
          });
        }
      }
    }
  });

  _exports.default = _default;
});