define("recruit/templates/components/candidate-helpers/evaluator-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3aNTM4c9",
    "block": "{\"symbols\":[\"panel\",\"&default\"],\"statements\":[[4,\"each\",[[20,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-tooltip\",[19,1,[\"user\",\"name\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"margin-no ellipsis\"],[7],[1,[19,1,[\"user\",\"name\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[11,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-helpers/evaluator-names.hbs"
    }
  });

  _exports.default = _default;
});