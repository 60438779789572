define("recruit/components/reports/table/recruiter/time-to-hire", ["exports", "recruit/mixins/report-table-common"], function (_exports, _reportTableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTableCommon.default, {
    modelName: 'reports/recruiter-time',
    columns: Ember.computed('selectedGraphFilter', function () {
      return [{
        label: 'Recruiter',
        component: 'reports/helpers/assessment-header',
        classNames: ['table-head mis text-center border-right-grey'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        width: '28%',
        sortable: false,
        cellComponent: 'reports/helpers/user-details'
      }, {
        sortable: false,
        component: 'reports/helpers/column-with-toggle',
        value: "Avg time to process ( in ".concat(this.get('selectedGraphFilter') == 'hours' ? 'hours' : 'days', " )"),
        classNames: ['table-head mis text-center'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        subColumns: [{
          label: 'Send Invitations',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'inviteTime',
          columnTooltip: 'Average time taken to send invitations to the candidates by recruiter.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.get('selectedGraphFilter'),
          width: '12%'
        }, {
          label: 'Complete Assessment',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          columnTooltip: 'Average time taken by the candidates to complete the assessment after getting an invitation.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'completeTime',
          width: '12%'
        }, {
          label: 'Assign Panel',
          component: 'reports/helpers/table-header',
          columnTooltip: 'Average time taken by the recruiter to assign panel to the candidates.',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'assignTime',
          width: '12%'
        }, {
          label: 'Evaluate',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          columnTooltip: 'Average time taken by the panel to evaluate the candidates.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'evaluationTime',
          width: '12%'
        }, {
          label: 'Final Selection',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          columnTooltip: 'Average time taken by the recruiter to finally shortlist a candidate after the evaluation.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'shortlistTime',
          width: '12%'
        }, {
          label: 'Time to process',
          subLabel: "(".concat(this.get('selectedGraphFilter') == 'hours' ? 'Hours' : 'Days', ")"),
          hideDot: true,
          component: 'reports/helpers/table-header',
          columnTooltip: "Average time taken (in ".concat(this.get('selectedGraphFilter') == 'hours' ? 'hours' : 'days', ") by the recruiter to complete the entire process"),
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'timeToProcess',
          width: '12%'
        }]
      }];
    }),
    actions: {
      onRowClick: function onRowClick(row) {
        if (row.get('id')) {
          this.set('selectedRow', row);
        }
      },
      toggleGraph: function toggleGraph() {
        this.toggleProperty('hideGraph');
      }
    }
  });

  _exports.default = _default;
});