define("recruit/components/candidate-helpers/tbi-behavioral-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('.behavioral-rating').popup({
          position: 'bottom center'
        });
      });
    },
    actions: {
      toggleModal: function toggleModal(property) {
        if (property && this.get('row.tbiStatus') == 'Generated') {
          return this.toggleProperty(property);
        }
      }
    }
  });

  _exports.default = _default;
});