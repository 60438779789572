define("recruit/components/recruiter-view/candidate/assessment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui segments c-profile-current-tab']
  });

  _exports.default = _default;
});