define("recruit/components/assessment/rating-templates/recommendation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showTextIcon: Ember.computed(function () {
      return !this.get('currentUser.me.organization.isIconEvaluation');
    }),
    actions: {
      updateRatings: function updateRatings(section) {
        var _this = this;

        if (this.get('disabled')) {
          if (!this.get('toggleNag')) {
            this.toggleProperty('toggleNag');
          }

          return;
        }

        section.get('sectionParameters').then(function (paramterRatings) {
          if (paramterRatings && paramterRatings.length) {
            _this.set('isLoading', true);

            paramterRatings.forEach(function (parameter) {
              parameter.destroyRecord().catch(function () {
                _this.get('toast').error('Something went wrong', 'Error!');
              });
            });

            _this.send('removeForm', section.content ? section.content : section);
          } else {
            _this.send('removeForm', section.content ? section.content : section);
          }
        });
      },
      removeForm: function removeForm(section) {
        var _this2 = this;

        if (section.get('evaluationForm.id')) {
          section.set('evaluationForm', null);
          section.save().then(function () {
            _this2.get('toast').success('Ratings Updated', 'Success');
          }).catch(function () {
            _this2.get('toast').error('Something went wrong', 'Error!');
          }).finally(function () {
            _this2.set('isLoading', false);
          });
        } else {
          this.get('toast').success('Ratings Updated', 'Success');
        }
      }
    }
  });

  _exports.default = _default;
});