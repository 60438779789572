define("recruit/controllers/application", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    userRole: Ember.inject.service(),
    customRoutes: ['loading', '404', 'user.change-role', 'setup.user', 'setup.security', 'recruiter.live-session.videos', 'panel.live-session.videos', 'recruiter.recruiter-actions.actions', 'design.choose-account', 'integration.invite', 'integration.live-schedule', 'integration.success', 'panel.event.details.register', 'panel.event.details.invitation', 'panel.event.details.response'],
    showNavBar: Ember.computed('currentPath', function () {
      if (!this.get('session.isAuthenticated')) {
        return false;
      }

      return !_npmLodash.default.includes(this.get('customRoutes'), this.currentPath);
    }),
    isReports: Ember.computed('currentPath', function () {
      var pathName = this.currentPath;
      return pathName.includes('recruiter.reports');
    }),
    isAssessmentCreation: Ember.computed('currentPath', function () {
      var pathName = this.currentPath;
      return pathName.includes('assessment.create') || pathName.includes('assessment-update');
    }),
    currentPathName: Ember.computed('currentPath', function () {
      return this.get('currentPath');
    }),
    isCustomNavBar: Ember.computed.equal('currentPath', 'dashboard'),
    currentPathDidChange: function () {
      if (window.self != window.top) {
        this.transitionToRoute('error');
      }
    }.observes('currentPath'),
    getTargetRoute: function getTargetRoute() {
      var _this = this;

      //Manually storing the target Transition path in cookies that aborted for unauthenticated
      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      var cookies = Ember.getOwner(this).lookup('service:cookies');
      var attemptedTransition = this.get('session.attemptedTransition');

      if (attemptedTransition) {
        var protocol = function () {
          if (_this.get('_isFastBoot')) {
            return fastboot.get('request.protocol');
          } else {
            return window.location.protocol.replace(/:/, '');
          }
        }();

        if (!this.get('session.isAuthenticated')) {
          cookies.write('ember_simple_auth-redirectTarget', attemptedTransition.intent.url, {
            path: '/',
            secure: protocol === 'https'
          });
        }
      }
    },
    actions: {
      openModal: function openModal(id) {
        Ember.$('#' + id).modal('show');
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      },
      rollback: function rollback(changeset) {
        return changeset && changeset.rollback();
      },
      toggleSidebar: function toggleSidebar(id) {
        Ember.$("#".concat(id)).sidebar('toggle');
      },
      back: function back() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});