define("recruit/models/candidate-auth/log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string', {
      defaultValue: 'Auth Attempt'
    }),
    created_at: _emberData.default.attr('pgdate'),
    match: _emberData.default.attr('number'),
    file: _emberData.default.belongsTo('candidate-auth/file'),
    formattedMatch: Ember.computed('match', function () {
      return this.get('match') ? "".concat(this.get('match'), "%") : 'NA';
    })
  });

  _exports.default = _default;
});