define("recruit/models/questions/mcq", ["exports", "ember-data", "recruit/models/question", "recruit/validations/mcq", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberData, _question, _mcq, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _question.default.extend({
    type: _emberData.default.attr('string', {
      defaultValue: 'multiple-choice'
    }),
    validate: function validate() {
      var changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_mcq.default), _mcq.default);
      return changeset.validate().then(function () {
        return changeset;
      });
    }
  });

  _exports.default = _default;
});