define("recruit/helpers/array-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayLength = arrayLength;
  _exports.default = void 0;

  function arrayLength(length) {
    return length[0] - 1;
  }

  var _default = Ember.Helper.helper(arrayLength);

  _exports.default = _default;
});