define("recruit/components/404-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$('body').addClass('not_found_bg re');
    },
    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);

      Ember.$('body').removeClass('not_found_bg');
    },
    actions: {
      back: function back() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});