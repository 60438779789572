define("recruit/components/events/event-status-dropdown", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: Ember.computed('eventModel.status', 'selectedStatus', function () {
      return !_npmLodash.default.includes(this.get('selectedStatus.allowedTransitions'), this.get('status.id'));
    }),
    actions: {
      changeStatus: function changeStatus(status) {
        this.sendAction('changeStatus', status);
      },
      toggleModal: function toggleModal() {
        this.sendAction('toggleModal');
      }
    }
  });

  _exports.default = _default;
});