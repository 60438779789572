define("recruit/helpers/get-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getProperty = getProperty;
  _exports.default = void 0;

  function getProperty(object) {
    return Ember.get(object[0], object[1]);
  }

  var _default = Ember.Helper.helper(getProperty);

  _exports.default = _default;
});