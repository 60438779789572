define("recruit/components/candidate-helpers/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import _ from "npm:lodash";
  var _default = Ember.Component.extend({
    tagName: '',
    // statusColor:null,
    isBasic: true,
    statusColor: Ember.computed('value.sort_key', 'value.type', 'isBasic', function () {
      var typeId = this.get('value') || this.get('value.content') ? this.get('value.type') : null;
      var statusProperty = this.get('value') ? this.get('value.sort_key') : null;

      if (typeId) {
        if (typeId == 10) {
          switch (parseInt(statusProperty)) {
            case 10:
              //Added
              this.set('isBasic', false);
              return 'grey';

            case 20:
              //Invited
              return 'grey';

            case 30:
              //In Progress
              return 'yellow';

            case 50:
              //Completed
              // this.set('statusColor', 'blue')
              this.set('isBasic', false);
              return 'blue';

            case 60:
              //Evaluated
              this.set('isBasic', false);
              return 'brown';

            case 70:
              //Selected
              this.set('isBasic', false);
              return 'green';

            case 80:
              //Hold
              this.set('isBasic', false);
              return 'yellow';

            case 90:
              //Rejected
              this.set('isBasic', false);
              return 'red';

            case 100:
              //Suspended
              this.set('isBasic', false);
              return 'black';

            case 110:
              //Dropped
              // this.set('statusColor', 'grey')
              this.set('isBasic', false);
              return 'grey';

            case 120:
              //Archived
              // this.set('statusColor', 'black')
              // this.set('isBasic', false);
              return 'red';

            default:
              // this.set('statusColor','grey');
              return 'grey';
          }
        } else if (typeId == 20) {
          switch (parseInt(statusProperty)) {
            case 10:
              //Added
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 20:
              //Invited
              // this.set('statusColor', 'grey');
              return 'grey';

            case 30:
              //Attending
              // this.set('statusColor', 'grey');
              return 'yellow';

            case 50:
              //Completed
              // this.set('statusColor', 'blue');
              this.set('isBasic', false);
              return 'blue';

            case 60:
              //Evaluated
              // this.set('statusColor', 'brown');
              this.set('isBasic', false);
              return 'brown';

            case 70:
              //Selected
              // this.set('statusColor', 'green');
              this.set('isBasic', false);
              return 'green';

            case 80:
              //Hold
              // this.set('statusColor', 'yellow');
              this.set('isBasic', false);
              return 'yellow';

            case 90:
              //Rejected
              // this.set('statusColor', 'red');
              this.set('isBasic', false);
              return 'red';

            case 100:
              //Suspended
              // this.set('statusColor', 'black');
              this.set('isBasic', false);
              return 'black';

            case 110:
              //candidate no show
              // this.set('statusColor', 'brown');
              return 'violet';

            case 120:
              //Panel no show
              // this.set('statusColor', 'brown');
              return 'violet';

            case 130:
              //Both no show
              // this.set('statusColor', 'brown');
              return 'violet';

            default:
              // this.set('statusColor','grey');
              return 'grey';
          }
        } else if (typeId == 40) {
          switch (parseInt(statusProperty)) {
            case 0:
              //Lead
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 10:
              //Applied
              // this.set('statusColor', 'grey');
              return 'grey';

            case 20:
              //shortlisted
              // this.set('statusColor', 'green');
              this.set('isBasic', false);
              return 'green';

            case 30:
              //Assessment Scheduled
              // this.set('statusColor', 'green');
              return 'blue';

            case 50:
              //Assessed
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'blue';

            case 60:
              //Selected
              // this.set('statusColor', 'green');
              this.set('isBasic', false);
              return 'green';

            case 70:
              //Consider
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'yellow';

            case 80:
              //Rejected
              // this.set('statusColor', 'red');
              this.set('isBasic', false);
              return 'red';

            case 90:
              //HR Assessment
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'violet';

            case 100:
              //Offered
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'teal';

            case 110:
              //Offer Accepted
              // this.set('statusColor', 'green');
              return 'green';

            case 120:
              //Offer Rejected
              // this.set('statusColor', 'red');
              return 'red';

            case 130:
              //Onboarded
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'violet';

            case 140:
              //Joined
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'olive';

            case 150:
              //Suspended
              // this.set('statusColor', 'black');
              this.set('isBasic', false);
              return 'black';

            case 160:
              //Disqualified
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 170:
              //Not Interested
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 180:
              //Duplicate
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 500:
              //Archived
              // this.set('statusColor', 'black');
              return 'red';

            default:
              // this.set('statusColor','grey');
              return 'grey';
          }
        } else if (typeId == 50) {
          switch (parseInt(statusProperty)) {
            case 0:
              //Lead
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 5:
              //Lead
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 10:
              //Applied
              // this.set('statusColor', 'grey');
              return 'grey';

            case 20:
              //shortlisted
              // this.set('statusColor', 'green');
              return 'green';

            case 30:
              //Selected
              // this.set('statusColor', 'green');
              this.set('isBasic', false);
              return 'green';

            case 40:
              //Withdrawn
              // this.set('statusColor', 'grey');
              return 'black';

            case 50:
              //Rejected
              // this.set('statusColor', 'red');
              this.set('isBasic', false);
              return 'red';

            case 60:
              //Suspended
              // this.set('statusColor', 'black');
              this.set('isBasic', false);
              return 'black';

            case 70:
              //Disqualified
              // this.set('statusColor', 'grey');
              return 'red';

            case 80:
              //Not interested
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 90:
              //Duplicate
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'olive';

            case 100:
              //Archived
              // this.set('statusColor', 'grey');
              return 'red';

            case 110:
              //Parallel Process
              // this.set('statusColor', 'grey');
              return 'pink';

            case 300:
              //Assessment
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'violet';

            case 400:
              //Offer
              // this.set('statusColor', 'grey');
              return 'teal';

            case 500:
              //Background Check
              // this.set('statusColor', 'grey');
              return 'brown';

            case 600:
              //Onboarding
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'purple';

            case 700:
              //Verification
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'teal';

            default:
              // this.set('statusColor','grey');
              return 'grey';
          }
        } else if (typeId == 60) {
          switch (parseInt(statusProperty)) {
            case 10:
              //In Progress
              // this.set('statusColor', 'yellow');
              return 'yellow';

            case 20:
              //Skipped
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'orange';

            case 30:
              //Completed
              // this.set('statusColor', 'blue');
              this.set('isBasic', false);
              return 'blue';

            case 40:
              //Selected
              // this.set('statusColor', 'green');
              this.set('isBasic', false);
              return 'green';

            case 50:
              //Consider
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'yellow';

            case 60:
              //Rejected
              // this.set('statusColor', 'red');
              this.set('isBasic', false);
              return 'red';

            case 70:
              //Offer Made
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'teal';

            case 80:
              //Offer Accepted
              // this.set('statusColor', 'green');
              return 'green';

            case 90:
              //Offer Rejected
              // this.set('statusColor', 'red');
              return 'red';

            case 100:
              //Offer withdrawn
              // this.set('statusColor', 'grey');
              return 'black';

            case 110:
              //unfit for offer
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'grey';

            case 120:
              //Background check passed
              // this.set('statusColor', 'grey');
              return 'teal';

            case 130:
              //Background check failed
              // this.set('statusColor', 'grey');
              return 'orange';

            case 140:
              //Offer Prepared
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'purple';

            case 150:
              //Offer approved
              // this.set('statusColor', 'grey');
              return 'teal';

            case 160:
              //Offer disapproved
              // this.set('statusColor', 'grey');
              return 'orange';

            case 180:
              //candidate verification pending
              // this.set('statusColor', 'grey');
              return 'yellow';

            case 190:
              //candidate verification passed
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'olive';

            case 200:
              //candidate verification failed
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'orange';

            case 210:
              //Onboarding info requested
              // this.set('statusColor', 'grey');
              this.set('isBasic', false);
              return 'violet';

            case 220:
              //Offer info requested
              // this.set('statusColor', 'grey');
              return 'brown';

            default:
              return 'grey';
          }
        } else {
          return 'grey';
        }
      }
    }),
    actions: {
      changeStatus: function changeStatus() {
        this.sendAction('changeStatus', 'SectionChangeStatus');
      }
    }
  });

  _exports.default = _default;
});