define("recruit/components/recruiter-view/questions/create/typing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.set('selectedSkills', Ember.A([]));
      this.set('preSelectedSkills', Ember.A([]));
    },
    actions: {
      removeSkill: function removeSkill(skill, index) {
        this.sendAction('removeSkill', skill, index);
      },
      showInput: function showInput(property) {
        this.toggleProperty(property);
      },
      setTitle: function setTitle(property) {
        this.set('editComponent', true);
        this.set('addTitle', false);
        this.set('changeset.title', property);
      },
      selectSkills: function selectSkills(skill) {
        this.sendAction('selectSkills', skill);
      }
    }
  });

  _exports.default = _default;
});