define("recruit/components/answer-views/essay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.$('.essay-container').animate({
        scrollTop: 0
      }, 100);
    }
  });

  _exports.default = _default;
});