define("recruit/components/user-helpers/profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'img',
    classNames: ['ui avatar mini image'],
    classNameBindings: ['customClass'],
    attributeBindings: ['src', 'style', 'alt'],
    src: Ember.computed('value', function () {
      return this.get('source') || this.get('value');
    }),
    customClass: Ember.computed(function () {
      return this.get('column.customClass');
    })
  });

  _exports.default = _default;
});