define("recruit/components/live-session/join-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service('currentUser'),
    isSidebarClosable: Ember.computed('selectedAssc.section.evaluationFormUrl', function () {
      return this.get('selectedAssc.section.evaluationFormUrl') ? true : false;
    }),
    isSessionEvaluated: Ember.computed('selectedAssc', 'selectedAssc.panelCandidates', function () {
      var _this = this;

      if (this.get('selectedAssc')) {
        this.get('selectedAssc.panelCandidates').then(function (pc) {
          Ember.RSVP.resolve(pc);
          pc.forEach(function (panel) {
            panel.get('panel').then(function (p) {
              if (p.get('user.id') === _this.get('currentUser.me.id')) {
                _this.set('isSessionEvaluated', panel.get('isEvaluated'));
              }
            });
          });
        });
      }
    }),
    evaluationSidebarWidth: Ember.computed('isSidebarClosable', function () {
      return this.get('isSidebarClosable') ? Ember.String.htmlSafe('width : 1000px !important') : Ember.String.htmlSafe('width : 250px !important'); //Evaluation form sidebar position
    }),
    isAdhocLive: Ember.computed('model.liveSession.assessmentSectionCandidate', function () {
      return this.get('model.liveSession.assessmentSectionCandidate.content') ? false : true;
    }),
    initResizeObserver: Ember.on("didInsertElement", function () {
      Ember.$(window).on('resize', this.windowSizeObserver.bind(this)); // check window size for future resizing or re-renders
    }),
    didInsertElement: function didInsertElement() {
      this.set('currentSessionEvaluated', false);
      this.set('rerenderComponent', true);

      if (this.get('model.status') != 2) {
        this.set('model.status', 2);
      }

      if (this.get('isAdhocLive')) {
        this.set('meetViewWidth', Ember.String.htmlSafe("100%"));
      } else {
        this.set('meetViewWidth', Ember.String.htmlSafe("calc(100% - 80px)"));
        this.windowSizeObserver(this); // check window size for the first render
      }
    },
    setSidebarPosition: function setSidebarPosition() {
      if (!this.get('isSidebarClosable')) {
        this.set('toggleSidebar', true);
        this.set('meetViewWidth', Ember.String.htmlSafe("calc(100% - 250px)"));
        this.set('styleProperty', Ember.String.htmlSafe("right: 0px !important;"));
      } else {
        this.set('toggleSidebar', false);
        this.set('meetViewWidth', Ember.String.htmlSafe("calc(100% - 80px)"));
        this.set('styleProperty', Ember.String.htmlSafe("right: 0px !important;"));
      }
    },
    windowSizeObserver: function windowSizeObserver() {
      var windowSize = Ember.$(window.screen.availWidth);
      var element = Ember.$('#meetview');

      if (this.get('model.status') == 3 && !this.get('exitSession')) {
        this.setSidebarPosition();
      } else {
        if (windowSize[0] - (element[0].clientWidth + 80) > 240) {
          this.set('styleProperty', Ember.String.htmlSafe("left: ".concat(element[0].clientWidth - 80, "px !important;")));
        } else {
          this.set('styleProperty', Ember.String.htmlSafe("right: 0px !important;"));
        }

        if (this.get('toggleEvaluationSidebar')) {
          this.set('styleProperty', Ember.String.htmlSafe("right: 0px !important"));
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$(window).off("resize");
    },
    actions: {
      toggleSidebar: function toggleSidebar(property) {
        this.toggleProperty(property);

        if (this.get('model.status') == 3 && !this.get('exitSession') && this.get('isSidebarClosable')) {
          if (this.get('toggleSidebar')) {
            this.set('meetViewWidth', Ember.String.htmlSafe("calc(100% - 250px)"));
          } else {
            this.set('meetViewWidth', Ember.String.htmlSafe("calc(100% - 80px)"));
          }
        }
      },
      toggleEvaluationSidebar: function toggleEvaluationSidebar(property) {
        if (!this.get('toggleSidebar')) {
          this.toggleProperty('toggleSidebar');
        }

        this.toggleProperty(property);

        if (this.get('selectedAssc.section.evaluationFormUrl')) {
          if (this.get('toggleEvaluationSidebar')) {
            this.set('styleProperty', Ember.String.htmlSafe("right: 0px !important"));
            this.set('isLsForm', true);
          } else {
            this.windowSizeObserver(this);
          }
        }
      },
      toggleModal: function toggleModal(property) {
        var _this2 = this;

        this.set('model.status', 3);
        this.get('model').save().then(function () {
          if (_this2.get('toggleEvaluationSidebar')) {
            _this2.set('toggleEvaluationSidebar', false);
          }

          if (_this2.get('isAdhocLive') || _this2.get('currentSessionEvaluated')) {
            return window.history.back();
          } else {
            _this2.setSidebarPosition();
          }
        }).catch(function () {
          _this2.get('toast').error('Something went wrong!', "Please try after sometime.");
        }).finally(function () {
          _this2.toggleProperty(property);
        });
      },
      back: function back(property) {
        var _this3 = this;

        this.set('rerenderComponent', false);
        setTimeout(function () {
          _this3.set('rerenderComponent', true);
        }, 2);
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});