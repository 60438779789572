define("recruit/adapters/event-panel", ["exports", "recruit/adapters/event-user"], function (_exports, _eventUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _eventUser.default.extend();

  _exports.default = _default;
});