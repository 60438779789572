define("recruit/components/recruiter-view/actions/assessment-list", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    assessmentId: "",
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('listValues').then(function (ac) {
        ac.get('firstObject.assessment').then(function () {
          _this.set('assessmentRelationFulfilled', true);
        });
      });
    },
    assessments: Ember.computed('assessmentRelationFulfilled', function () {
      return _npmLodash.default.uniqBy(this.get('listValues').map(function (ac) {
        return {
          "acId": ac.get('id'),
          "assessmentId": ac.get('assessment.id'),
          "assessmentName": ac.get('assessment.titleWithId')
        };
      }), 'assessmentId');
    }),
    actions: {
      onChange: function onChange() {
        var _this2 = this;

        this.set('selectedListValues', Ember.A([]));
        this.get('listValues').forEach(function (ac) {
          if (ac.get('assessment.id') == _this2.get('assessmentId')) {
            _this2.get('selectedListValues').pushObject(ac);
          }
        });
      },
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});