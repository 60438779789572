define("recruit/routes/recruiter/candidate/create/additional", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('streetAddress', null);
      controller.set('city', null);
    },
    actions: {
      save: function save(candidate, streetAddress, city) {
        var _this = this;

        var CF = {
          StreetAddress: streetAddress,
          City: city
        }; // fieldMaster.forEach((field) => {
        //   if(field.get('value')) {
        //     CF[field.get('key')] = field.get('value')
        //   }
        // });

        candidate.set('cf', CF);
        candidate.save().then(function () {
          _this.get('toast').success('', 'Candidate Successfully created');

          return _this.get('router').transitionTo('recruiter.pending-candidates');
        }).catch(function () {
          _this.get('toast').error('Please try after sometime', 'Something went wrong');
        });
      }
    }
  });

  _exports.default = _default;
});