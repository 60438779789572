define("recruit/components/action-wrapper/live-modal/cancel", ["exports", "npm:lodash", "recruit/mixins/g-actions"], function (_exports, _npmLodash, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    tagName: '',
    store: Ember.inject.service(),
    isNotify: true,
    isLoading: false,
    actions: {
      cancelSession: function cancelSession() {
        var _this = this;

        this.set('isLoading', true);
        var liveSessionID = this.get('selectedListValues').map(function (ls) {
          return ls.get('id');
        });
        this.get('store').createRecord('actions/live-cancel', {
          liveSessionID: liveSessionID,
          isNotify: this.get('isNotify')
        }).save().then(function () {
          _this.get('toast').success('Live session cancelled', 'Successful');

          _npmLodash.default.forEach(_this.get('selectedListValues'), function (session) {
            session.set('status', 10);
          });

          return _this.send('back');
        }).catch(function (error) {
          _this.catchError(error);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});