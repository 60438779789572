define("recruit/components/model-table/recruiter/candidate", ["exports", "recruit/mixins/infinite-loader", "npm:lodash"], function (_exports, _infiniteLoader, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend(_infiniteLoader.default, {
    user: Ember.inject.service('current-user'),
    modelName: 'assessment-candidate',
    quickFilterObserver: Ember.observer('quickFilters.{isPendingInvite,isUnassigned,isPendingEval,isRecentEval}', function () {
      this.set('page', 1);
      this.get('tableRows').clear();
      this.get('fetchData').perform();
    }),
    // hasSingleSelection: computed('table.selectedRows', function () {
    //   return this.get('table.selectedRows.length') === 1;
    // }),
    // candidates:computed('table.selectedRows',function(){
    //   return this.get('table.selectedRows').map((row)=>row.get('content'))
    // }),
    // isFilterApplied: computed('isPendingInvite','isUnassigned','isPendingEval','isRecentEval', 'appliedFilters.length', function(){
    //   return this.get('isPendingInvite') || this.get('isUnassigned') || this.get('isPendingEval') || this.get('isRecentEval') || this.get('appliedFilters.length')
    // }),
    // // hasLiveSection: Ember.computed('table.selectedRows', 'table.selectedRows.@each.assessment.assessmentSections.section.isFulfilled', function() {
    // //   let sections = this.get('table.selectedRows.firstObject.assessment.assessmentSections').map(as => as.get('section.type_id'));
    // //   return _.includes(sections, 7);
    // // }),
    // allowAddingPanel: computed('table.selectedRows', function() {
    //   let assessments = this.get('table.selectedRows').map((ac) => +ac.get('content.assessment.id'));
    //   return _.uniq(assessments).length === 1;
    // }),
    actions: {
      // rowChecked(row) {
      //   this.get('table.selectedRows').pushObject(row);
      //   this.get('table.selectedRows').setEach('selected', true);
      // },
      // rowUnChecked(row) {
      //   this.get('table.selectedRows').removeObject(row);
      // },
      //
      selecteAll: function selecteAll(candidates, event) {
        var _this = this;

        if (event) {
          candidates.forEach(function (candidate) {
            candidate.set('isSelected', true);

            _this.sendAction.apply(_this, ['rowChecked', 'push'].concat(_toConsumableArray(candidates)));
          });
        } else {
          candidates.forEach(function (candidate) {
            candidate.set('isSelected', false);

            _this.sendAction.apply(_this, ['rowChecked', 'pop'].concat(_toConsumableArray(candidates)));
          });
        }
      },
      rowChecked: function rowChecked(eventType, candidate) {
        this.sendAction('rowChecked', eventType, candidate); // this.get('table.selectedRows').pushObject(row);
        // this.get('table.selectedRows').setEach('selected', true);
      },
      routeTransistion: function routeTransistion(row) {
        this.get('router').transitionTo('recruiter.candidate.details.assessment', row.get('candidate.id'), row.get('id'));
      }
    }
  });

  _exports.default = _default;
});