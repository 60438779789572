define("recruit/components/assessment/job-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    getFormattedValue: function getFormattedValue() {
      return this.get('model.description') ? this.get('model.description').trim() ? this.get('model.description').trim() : 'No job description available.' : 'No job description available.';
    },
    didInsertElement: function didInsertElement() {
      this.set('editMode', !this.get('model.description.length'));
      this.set('formattedValue', this.getFormattedValue());
    },
    actions: {
      save: function save(model, property) {
        var _this = this;

        if (model.get('hasDirtyAttributes')) {
          var changedProp = model.changedAttributes();

          if (changedProp.description) {
            this.set('isLoading', true);
            model.save().then(function () {
              _this.get('toast').success("Job description has been updated.", "UPDATED!");

              _this.toggleProperty(property);
            }).catch(function () {
              _this.get('toast').error("Something went wrong.", "ERROR!", {
                positionClass: 'assessment-toast-error'
              });
            }).finally(function () {
              _this.set('isLoading', false);

              _this.sendAction('toggleModal', property);
            });
          }
        } else {
          this.sendAction('toggleModal', property);
        }
      },
      setJD: function setJD(editor) {
        this.set('model.description', editor);
        this.set('formattedValue', this.getFormattedValue());
      },
      preview: function preview(editMode) {
        editMode ? this.set('editMode', false) : this.set('editMode', true);
      },
      cancel: function cancel(model, property) {
        if (model.get('hasDirtyAttributes')) {
          var changedProp = model.changedAttributes();

          if (changedProp.description) {
            model.rollbackAttributes();
          }
        }

        this.sendAction('toggleModal', property);
      }
    }
  });

  _exports.default = _default;
});