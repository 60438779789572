define("recruit/templates/components/recruiter-view/dashboard/calendar-events-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u3UxjHwB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"loading-item padding-md-top\"],[9,\"style\",\"border-bottom:1px solid #eee;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid flex-middle-align\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui four wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\" loader-header animated-background\"],[7],[8],[0,\"\\n\"],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui three wide right floated column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\" loader-header animated-background\"],[7],[8],[0,\"\\n\"],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui sixteen wide column padding-no-top\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui grid padding-md\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide centered column padding-no\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"calendar-lines animated-background\"],[7],[8],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"calendar-lines animated-background\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/dashboard/calendar-events-loading.hbs"
    }
  });

  _exports.default = _default;
});