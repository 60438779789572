define("recruit/routes/recruiter/events/details/overview", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    userRole: Ember.inject.service('user-role'),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('event', model);
      controller.set('currentUser', this.get('currentUser'));
      controller.set('userRole', this.get('userRole'));
    }
  });

  _exports.default = _default;
});