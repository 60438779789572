define("recruit/components/assessment/assessment-section-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    _sectionTitleObserver: Ember.observer('sectionModel.{title,sectionType,questionType,questions,assessmentQuestions}.@each', function () {
      this.get('sectionModel').save();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('sectionModel.sectionType.id')) {
        this.set('sectionModel.sectionType', this.get('availableSections.firstObject'));
        this.set('sectionModel.title', this.get('availableSections.firstObject.title'));
        this.set('sectionModel.questionType', this.get('questionTypeList.firstObject'));
        this.get('sectionModel').save();
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.$('.ui.dropdown').dropdown({
        on: 'hover'
      });
    }
  });

  _exports.default = _default;
});