define("recruit/components/live-session/live-videos-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentVideo: Ember.computed(function () {
      var _this = this;

      var previous_id;
      this.get('videos').forEach(function (videoObject, index) {
        videoObject.set('previous_video_id', previous_id);

        var next_video = _this.get('videos').objectAt(index + 1);

        if (next_video) {
          videoObject.set('next_video_id', next_video.get('id'));
        }

        previous_id = videoObject.get('id');
      });
      return this.get('videos.firstObject');
    }),
    actions: {
      changeVideo: function changeVideo(currentVideo, canPlayNext) {
        var id = currentVideo.get(canPlayNext ? 'next_video_id' : 'previous_video_id');
        var newVideo = this.get('videos').findBy('id', id);
        this.set('currentVideo', newVideo);
      }
    }
  });

  _exports.default = _default;
});