define("recruit/components/reports/filters/filters-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      Ember.run.next(function () {
        Ember.$('.filterLabels').popup({
          background: '#fff',
          color: '#000'
        });
      });
    },
    actions: {
      removeFilter: function removeFilter(arr, obj) {
        if (obj) {
          this.sendAction('removeFilter', arr, obj);
        }
      }
    }
  });

  _exports.default = _default;
});