define("recruit/components/model-table/table-empty-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    computedStyle: Ember.computed('height', function () {
      return Ember.String.htmlSafe("height : ".concat(this.get('height')));
    })
  });

  _exports.default = _default;
});