define("recruit/templates/components/recruiter-view/candidate/assessment-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "muS+hLKy",
    "block": "{\"symbols\":[\"assessmentSection\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[20,[\"assessmentSections\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"recruiter-view/candidate/assessment-section-icon\",null,[[\"assessmentSection\",\"index\",\"assessmentSections\",\"assessmentSectionCandidates\",\"selectedAssc\",\"selectedAs\",\"setAssc\"],[[19,1,[]],[19,2,[]],[20,[\"assessmentSections\"]],[20,[\"assessmentSectionCandidates\"]],[20,[\"selectedAssc\"]],[20,[\"selectedAs\"]],\"setAssc\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[11,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/candidate/assessment-sections.hbs"
    }
  });

  _exports.default = _default;
});