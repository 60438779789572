define("recruit/components/helpers/date-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    format: Ember.computed('column.dateFormat', function () {
      return this.get('column.dateFormat') ? this.get('column.dateFormat') : 'DD-MM-YY | hh:mm A';
    }),
    showNotPending: Ember.computed('value', function () {
      return this.get('value') === "not pending";
    })
  });

  _exports.default = _default;
});