define("recruit/routes/recruiter/assessment/details/assessment-update", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isDevelopment: _environment.default.isDevelopment,
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      sectionId: {
        replace: true
      },
      currentSlide: {
        replace: true
      },
      updateJd: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller, model, transitions) {
      this._super.apply(this, arguments);

      controller.set('model', model);

      if (transitions.queryParams.updateJd === "true") {
        controller.set('showJdAlert', true);
      }

      if (transitions.queryParams.sectionId && transitions.queryParams.sectionId.length) {
        controller.set('sectionId', transitions.queryParams.sectionId);
        this.get('store').findRecord('lf-adapter/assessment-section', transitions.queryParams.sectionId).then(function (as) {
          if (as) {
            model.get('assessmentSections').then(function () {
              as.get('section').then(function (sec) {
                if (!sec.get('title')) {
                  sec.get('sectionType').then(function (sectionType) {
                    sec.set('title', sectionType.get('title'));
                    sec.save();
                    model.set('isExtraSectionAvailable', true);
                    model.get('assessmentSections').insertAt(controller.get('currentSlide'), as);
                  });
                } else {
                  model.get('assessmentSections').insertAt(controller.get('currentSlide'), as);
                }
              });
            });
          }
        }).catch(function () {// this.set('renderCards', false);
        });
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.set('currentSlide', 0);
        this.controller.set('sectionId', null);

        if (this.controller.get('showJdAlert')) {
          this.set('showJdAlert', null);
        }
      }
    }
  });

  _exports.default = _default;
});