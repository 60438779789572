define("recruit/routes/recruiter/candidate/details/timeline", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController() {
      Ember.run.scheduleOnce('afterRender', function () {
        Ember.$('.ui.dropdown').dropdown();
      });
    }
  });

  _exports.default = _default;
});