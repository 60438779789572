define("recruit/routes/activation", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      c: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.set('params', params);

      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate().then(function () {
          window.location.replace(location.href);
        });
      }
    },
    setupController: function setupController(controller) {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('params.c')) {
        Ember.$.ajax({
          type: "POST",
          url: _environment.default.APP.API_ROOT + "/user/activate",
          dataType: "json",
          data: {
            'token': this.get('params.c')
          },
          success: function success(response) {
            return _this.get('session').authenticate('authenticator:tv-activate', response).then(function () {
              return _this.get('currentUser').load();
            }).then(function () {
              return _this.get('router').transitionTo('setup.user');
            }).catch(function (err) {
              if (err.errors) {
                err.errors.forEach(function (error) {
                  _this.get('toast').error(error.detail, 'Error!');
                });
              } else {
                _this.get('toast').error('Something went wrong', 'Please try after some time.');
              }
            });
          },
          error: function error(e) {
            if (e.responseJSON) {
              if (e.responseJSON.message == 'Token not found!') {
                controller.set('errorMsg', "Either the activation link is invalid or already used.");
              }
            }

            controller.set('showSupport', true);
          }
        });
      } else {
        controller.set('showSupport', true);
        controller.set('errorMsg', "The link you tried to access is not valid.");
      }
    }
  });

  _exports.default = _default;
});