define("recruit/templates/components/helpers/proctor-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5R59ClmH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"rating\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",[26,[\"ui proctor-rating text-size-normal \",[18,\"rating\"],\" padding-no\"]]],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"text-size-vsmall text-capitalize\"],[7],[1,[18,\"rating\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"class\",\"text-grey\"],[7],[6,\"i\"],[7],[0,\"Not available\"],[8],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/proctor-rating.hbs"
    }
  });

  _exports.default = _default;
});