define("recruit/components/recruiter-view/actions/unassign-panel", ["exports", "recruit/config/environment", "moment", "npm:moment-duration-format", "recruit/mixins/g-actions"], function (_exports, _environment, _moment, _npmMomentDurationFormat, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    toggle: true,
    oldPanel: null,
    panels: null,
    selectedPanels: Ember.A([]),
    endDate: (0, _moment.default)().add(10, 'd').toDate(),
    initProperty: function initProperty() {
      this.set("toggle", true);
      this.set("oldPanel", null);
      this.set("panels", null);
      this.set("selectedPanels", Ember.A([]));
    },
    isRelationshipLoaded: Ember.computed('model.assessmentSections.firstObject.section.isFulfilled', 'model.assessmentSections.isFulfilled', 'model.assessmentSections.firstObject.assessment.isFulfilled', 'model.panels.isFulfilled', 'model.panels.firstObject.user.isFulfilled', function () {
      return this.get('model.assessmentSections.firstObject.section.isFulfilled') && this.get('model.assessmentSections.isFulfilled') && this.get('model.assessmentSections.firstObject.assessment.isFulfilled') && this.get('model.panels.firstObject.user.isFulfilled');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.initProperty();
      (0, _npmMomentDurationFormat.default)(_moment.default);
    },
    didRender: function didRender() {
      Ember.$('.ui.rating').rating();
      Ember.$('[data-content]').popup();
    },
    willDestroyElement: function willDestroyElement() {
      this.initProperty();
    },
    actions: {
      toggleProperty: function toggleProperty() {
        this.toggleProperty('toggle');
      },
      remove: function remove(panel, index) {
        this.get('selectedPanels').removeAt(index);
      },
      proceed: function proceed(toastText) {
        var _this = this;

        var endDate = this.get('model.assessmentSections.firstObject.assessment.end_date') ? this.get('model.assessmentSections.firstObject.assessment.end_date').toISOString() : this.get('endDate').toISOString();
        var assessmentCandidates = this.get('selectedListValues').map(function (ac) {
          return ac.get('id');
        });
        this.get('store').createRecord('actions/add-panel', {
          assessmentSections: [{
            id: this.get('model.assessmentSections.firstObject.id')
          }],
          assessmentCandidates: assessmentCandidates,
          endDate: endDate,
          isNotify: true,
          panel: this.get('panels'),
          oldPanel: this.get('oldPanel'),
          sync: true
        }).save().then(function (res) {
          return Ember.RSVP.hash({
            panels: res.get('panels'),
            panelCandidates: res.get('panelCandidates')
          });
        }).then(function () {
          _this.get('toast').success("for ".concat(_this.get('selectedListValues.length'), " Candidates"), "Panel ".concat(toastText, " Successfully"));

          return _this.send('back');
        }).catch(function (e) {
          _this.catchError(e);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      unAssignPanel: function unAssignPanel() {
        var toastText;
        this.set('isLoading', true);
        this.set('oldPanel', [{
          id: this.get('model.panels.firstObject.user.id')
        }]);

        if (this.get('selectedPanels.length') && !this.get('toggle')) {
          this.set('panels', this.get('selectedPanels').map(function (p) {
            return {
              id: p.get('id')
            };
          }));
          toastText = 'Changed';
        } else {
          toastText = 'Unassigned';
        }

        this.send('proceed', toastText);
      },
      assignCandidate: function assignCandidate() {
        this.set('isLoading', true);
        this.set('panels', [{
          id: this.get('model.panels.firstObject.user.id')
        }]);
        this.send('proceed', 'Assigned');
      },
      sendReminder: function sendReminder() {
        var _this2 = this;

        this.set('isLoading', true);
        this.get('store').createRecord('actions/remind-panel', {
          panel: [{
            id: this.get('selectedListValues.firstObject.id')
          }],
          //Panel id
          isNotify: true
        }).save().then(function () {
          _this2.get('toast').success("to ".concat(_this2.get('selectedListValues.firstObject.user.name')), 'Reminder sent');

          return _this2.send('back');
        }).catch(function (e) {
          _this2.catchError(e);
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      addPanel: function addPanel(panel) {
        this.get('selectedPanels').pushObject(panel);
      }
    }
  });

  _exports.default = _default;
});