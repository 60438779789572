define("recruit/templates/components/reports/helpers/advance-graph-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MMXrZf3f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"selectedRow\",\"title\"]]],null,{\"statements\":[[0,\"\\t\"],[6,\"div\"],[9,\"class\",\"padding-md-left padding-md-right reports-text text-size-medium\"],[7],[0,\"\\n\\t  \"],[6,\"p\"],[9,\"class\",\"text-black margin-no\"],[7],[0,\"\\n\\t    \"],[6,\"span\"],[10,\"data-tooltip\",[26,[[20,[\"selectedRow\",\"title\"]]]]],[9,\"data-position\",\"bottom center\"],[7],[1,[20,[\"selectedRow\",\"title\"]],false],[8],[0,\"\\n\\t  \"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/advance-graph-header.hbs"
    }
  });

  _exports.default = _default;
});