define("recruit/components/helpers/multiple-checkboxs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isChecked: Ember.computed('values.[]', function () {
      var primaryKey = this.get('primaryKey') ? this.get('primaryKey') : 'id';

      if (this.get('values.length')) {
        return this.get('values').findBy("".concat(primaryKey), this.get("field.".concat(primaryKey)));
      }
    }),
    actions: {
      setValue: function setValue(checked) {
        if (typeof this.get('actionName') == 'function') {
          this.sendAction('actionName', checked, this.get('field'));
        } else {
          var index = this.get('values').indexOf(this.get('field'));

          if (checked && index == -1) {
            this.get('values').pushObject(this.get('field'));
          } else if (this.get('values.length') && index != -1) {
            this.get('values').removeAt(index);
          }
        }
      }
    }
  });

  _exports.default = _default;
});