define("recruit/models/reports/counts", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    'invitedCount': _emberData.default.attr('number'),
    'inprogressCount': _emberData.default.attr('number'),
    'completedCount': _emberData.default.attr('number'),
    'evaluatedCount': _emberData.default.attr('number'),
    'selectedCount': _emberData.default.attr('number'),
    'rejectedCount': _emberData.default.attr('number'),
    'holdCount': _emberData.default.attr('number'),
    'suspendedCount': _emberData.default.attr('number'),
    'inviteCount': _emberData.default.attr('number'),
    'createCandidateCount': _emberData.default.attr('number'),
    'changeStatusCount': _emberData.default.attr('number'),
    'remindPanelCount': _emberData.default.attr('number'),
    'remindCandidateCount': _emberData.default.attr('number'),
    'extendEndDateCount': _emberData.default.attr('number'),
    'cancelInviteCount': _emberData.default.attr('number'),
    'addPanelCount': _emberData.default.attr('number'),
    'liveScheduleCount': _emberData.default.attr('number'),
    'liveCancelCount': _emberData.default.attr('number'),
    'liveRescheduleCount': _emberData.default.attr('number'),
    'liveChangePanelCount': _emberData.default.attr('number'),
    'liveReminderCount': _emberData.default.attr('number'),
    'reInviteCount': _emberData.default.attr('number'),
    'createAssessmentCount': _emberData.default.attr('number'),
    'simScheduleCount': _emberData.default.attr('number'),
    'simCancelCount': _emberData.default.attr('number'),
    'simRescheduleCount': _emberData.default.attr('number'),
    'simReminderCount': _emberData.default.attr('number'),
    'evaluationCount': _emberData.default.attr('number'),
    'sectionWorkflowCount': _emberData.default.attr('number'),
    'panelHoldCount': _emberData.default.attr('number'),
    'panelRejectCount': _emberData.default.attr('number'),
    'panelSelectCount': _emberData.default.attr('number'),
    'panelAssignedCount': _emberData.default.attr('number'),
    'tat': _emberData.default.attr('number'),
    conversionRate: Ember.computed('selectedCount', 'invitedCount', function () {
      if (!this.get('selectedCount')) {
        return '0%';
      }

      var conversion = this.get('selectedCount') / this.get('invitedCount') * 100;
      var decimal = conversion - Math.floor(conversion);
      var val = decimal == 0 ? conversion.toFixed(0) : conversion.toFixed(2);
      return val + '%';
    }),
    panelEvaluatedCount: Ember.computed('panelSelectCount', 'panelRejectCount', 'panelHoldCount', function () {
      return this.get('panelSelectCount') + this.get('panelRejectCount') + this.get('panelHoldCount');
    }),
    panelConversionRate: Ember.computed('panelSelectCount', 'panelEvaluatedCount', function () {
      if (!this.get('panelSelectCount')) {
        return '0%';
      }

      var conversion = this.get('panelSelectCount') / this.get('panelEvaluatedCount') * 100;
      var decimal = conversion - Math.floor(conversion);
      var val = decimal == 0 ? conversion.toFixed(0) : conversion.toFixed(2);
      return val + '%';
    }),
    inprogressPercent: Ember.computed('inprogressCount', 'invitedCount', function () {
      return this.calculatePercent(this.get('inprogressCount'), this.get('invitedCount'));
    }),
    evaluatedPercent: Ember.computed('evaluatedCount', 'invitedCount', function () {
      return this.calculatePercent(this.get('evaluatedCount'), this.get('invitedCount'));
    }),
    completedPercent: Ember.computed('completedCount', function () {
      return this.calculatePercent(this.get('completedCount'), this.get('inprogressCount'));
    }),
    selectedPercent: Ember.computed('selectedCount', 'completedCount', function () {
      return this.calculatePercent(this.get('selectedCount'), this.get('completedCount'));
    }),
    onHoldPercent: Ember.computed('holdCount', 'completedCount', function () {
      return this.calculatePercent(this.get('holdCount'), this.get('completedCount'));
    }),
    rejectedPercent: Ember.computed('rejectedCount', 'completedCount', function () {
      return this.calculatePercent(this.get('rejectedCount'), this.get('completedCount'));
    }),
    panelSelectedPercent: Ember.computed('panelSelectCount', 'panelEvaluatedCount', function () {
      return this.calculatePercent(this.get('panelSelectCount'), this.get('panelEvaluatedCount'));
    }),
    panelOnHoldPercent: Ember.computed('panelHoldCount', 'panelEvaluatedCount', function () {
      return this.calculatePercent(this.get('panelHoldCount'), this.get('panelEvaluatedCount'));
    }),
    panelRejectedPercent: Ember.computed('panelRejectCount', 'panelEvaluatedCount', function () {
      return this.calculatePercent(this.get('panelRejectCount'), this.get('panelEvaluatedCount'));
    }),
    turnaroundTime: Ember.computed('tat', function () {
      return parseInt(_moment.default.duration(this.get('tat'), 'seconds').asHours()) + " h";
    }),
    calculatePercent: function calculatePercent(count, total) {
      var arr = [count];

      if (total) {
        var conversion = count / total * 100;
        var decimal = conversion - Math.floor(conversion);
        var val = decimal == 0 ? conversion.toFixed(0) : conversion.toFixed(2);
        arr.push(val);
      } else {
        arr.push(0);
      }

      return arr;
    },
    titleWithId: Ember.computed('external_id', function () {
      return this.get('external_id') ? "".concat(this.get('title'), " (").concat(this.get('external_id'), ")") : this.get('title');
    }),
    key: Ember.computed.alias('titleWithId')
  });

  _exports.default = _default;
});