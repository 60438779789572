define("recruit/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NQI9IPpx",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"component\",[[20,[\"errorComponent\"]]],[[\"back\",\"refresh\"],[\"back\",\"refresh\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/error.hbs"
    }
  });

  _exports.default = _default;
});