define("recruit/adapters/extended-candidate", ["exports", "recruit/adapters/candidate"], function (_exports, _candidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _candidate.default.extend({
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + '?expend=authToken';
    }
  });

  _exports.default = _default;
});