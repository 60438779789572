define("recruit/controllers/recruiter/reports/report-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['reportFilters', 'dateRange'],
    reportFilters: null,
    dateRange: null
  });

  _exports.default = _default;
});