define("recruit/templates/components/recruiter-view/ac-list-to-assc-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SbwxgoEa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"link-to\",[\"recruiter.candidate.details.assessment\",[20,[\"row\",\"content\",\"candidate\",\"id\"]],[20,[\"row\",\"content\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"value\",\"profilePic\"]],\"ui circular image profile-picture\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/ac-list-to-assc-link.hbs"
    }
  });

  _exports.default = _default;
});