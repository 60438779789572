define("recruit/templates/components/helpers/date-range-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jYpVTyCK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"dateValue\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-html\",[26,[\"<div><span class='text-weight-medium'>Starts on</span> \",[25,\"moment-format\",[[20,[\"dateValue\"]],[20,[\"hoverDateFormat\"]]],null],\"</div>\\n    <div><span class='text-weight-medium'>Ends on</span> \",[25,\"moment-format\",[[20,[\"endDate\"]],[20,[\"hoverDateFormat\"]]],null],\"</div>\"]]],[9,\"data-variation\",\"tiny\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[1,[18,\"formattedTime\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-center text-grey text-italics\"],[7],[1,[18,\"formattedTime\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/date-range-view.hbs"
    }
  });

  _exports.default = _default;
});