define("recruit/routes/recruiter/vm-schedule/form", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/validations/time-validator", "moment"], function (_exports, _authenticatedRouteMixin, _timeValidator, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      cid: {
        refreshModel: true
      },
      aid: {
        refreshModel: true
      },
      acid: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.set('params', params);
      return Ember.RSVP.hash({
        simulationSchedule: this.get('store').createRecord('actions/simulation-schedule'),
        timeZoneList: this.get('store').query('settingsAllowed', {
          settings_id: 1
        }),
        timeZone: this.get('store').query('settingsData', {
          settings_id: 1
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('params', this.get('params'));
      controller.set('simulationSchedule', model.simulationSchedule);
      controller.set('timeZone', model.timeZone);
      controller.set('timeZoneList', model.timeZoneList);
      controller.set('simulationSchedule.from', (0, _moment.default)().add(3, 'hours').toDate());
      controller.set('minimumDate', (0, _moment.default)().subtract(1, 'days').startOf('day').toDate());
      controller.set('candidateRecords', null);
      controller.set('selectedAssessmentSection', null);
      controller.set('assessmentSections', null);
      controller.set('scheduleType', 'now');
      controller.set('selectedPanels', null);
      controller.set('userTimeZone', controller.get('timeZone') ? this.get('store').peekRecord('settings-allowed', controller.get('timeZone.firstObject.settings_allowed_value_id')) : null);
    },
    actions: {
      save: function save(model, selectedPanels, candidate) {
        var _this = this;

        if (!this.controller.get('candidateRecords') && !this.get('params.cid')) {
          return this.get('toast').error('', 'Please select a candidate');
        }

        if (this.controller.get('scheduleType') == 'now') {
          if (!this.controller.get('userTimeZone')) {
            return this.get('toast').error('', "Please select the timezone");
          }

          if (!model.get('from')) {
            return this.get('toast').error('', "Please select the start time");
          }
        }

        var isValidDate = (0, _timeValidator.default)(model.get('from'), this.controller.get('userTimeZone.item_value'));

        if (!isValidDate) {
          return this.get('toast').error('', 'Cannot scheduled in past');
        }

        var startDateTime = !model.get('from') ? this.controller.get('currentDate') : model.get('from');
        var selectedTime = model.get('durationPreSelected').value;

        var endDateTime = (0, _moment.default)(startDateTime).add(selectedTime, "minutes")._d;

        if (this.controller.get('scheduleType') == 'now') {
          model.setProperties({
            startDate: startDateTime,
            endDate: endDateTime,
            currentOffset: (0, _moment.default)().local().utcOffset() * 60,
            timezone: this.controller.get('userTimeZone.item_value'),
            askPanel: this.controller.get('scheduleType') == 'panel',
            askCandidate: this.controller.get('scheduleType') == 'candidate'
          });
        } else {
          model.setProperties({
            askPanel: this.controller.get('scheduleType') == 'panel',
            askCandidate: this.controller.get('scheduleType') == 'candidate',
            panelMessage: this.controller.get('panelMessage'),
            candidateMessage: this.controller.get('candidateMessage')
          });
        }

        model.setProperties({
          assessmentCandidateID: this.get('params.acid'),
          candidateID: candidate.get('id'),
          email: candidate.get('email'),
          assessmentSectionID: this.controller.get('selectedAssessmentSection.id'),
          panel: selectedPanels ? selectedPanels.map(function (panel) {
            return {
              id: panel.get('id')
            };
          }) : null,
          isNotify: true
        });
        model.save().then(function (response) {
          if (response.get('status') == 99) {
            _this.get('toast').error(response.get('response'));
          } else {
            _this.get('toast').success('Session Scheduled', 'Success');

            _this.send('back');
          }
        }).catch(function (e) {
          if (e.errors) {
            e.errors.forEach(function (error) {
              _this.get('toast').error(error.detail, 'Error!');
            });
          } else {
            _this.get('toast').error('Something went wrong.', 'Error!');
          }
        });
      },
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});