define("recruit/adapters/current-user", ["exports", "recruit/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return "".concat(this.get('host'), "/user/me");
      }

      return this._super.apply(this, arguments);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      if (this.isLoggedUser(id)) {
        return "".concat(this._super('me', 'user', snapshot));
      }

      return this._super.apply(this, arguments);
    },
    isLoggedUser: function isLoggedUser(id) {
      return this.get('session.user.id') == id ? true : false;
    }
  });

  _exports.default = _default;
});