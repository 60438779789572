define("recruit/templates/components/recruiter-view/assessment/helpers/tags-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0dtXt26s",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments box-shadow-none border-none\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment box-shadow-none\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui small right icon input margin-right-ten\"],[9,\"style\",\"width:100%\"],[7],[0,\"\\n      \"],[1,[25,\"fa-icon\",[\"search\"],[[\"class\"],[\"input-icon-fix right text-grey\"]]],false],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"type\",\"placeholder\",\"value\",\"style\"],[\"text\",\"Search for tags\",[20,[\"tagSearchText\"]],\"border-radius:6px\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"tagList\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"tagList\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"ui segment text-black text-weight-medium box-shadow-none padding-no\"],[7],[0,\"\\n\"],[4,\"copy-button\",null,[[\"clipboardText\",\"success\",\"class\"],[[19,1,[\"tag\"]],\"copyLink\",\"border-none bg-white padding-md-left padding-md-right padding-sm-top padding-sm-bottom cursor-pointer outline-none full-width supportedTags\"]],{\"statements\":[[0,\"          \"],[6,\"p\"],[9,\"class\",\"margin-bottom-five text-left text-size-small\"],[7],[1,[19,1,[\"description\"]],false],[8],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"text-grey margin-no text-size-vsmall text-left\"],[7],[1,[19,1,[\"tag\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui segment text-black box-shadow-none border-none text-center text-size-small\"],[7],[0,\"\\n      No tag available\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/helpers/tags-list.hbs"
    }
  });

  _exports.default = _default;
});