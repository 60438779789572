define("recruit/templates/components/events/panel-match-popup-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WL9Z3Lw5",
    "block": "{\"symbols\":[\"panel\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"display-inline-block panel-match-tooltip events user-profile extra\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"text-center text-size-vsmall\"],[7],[0,\"\\n    +\"],[1,[20,[\"extraPanels\",\"length\"]],false],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"tooltip\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid margin-no\"],[9,\"style\",\"max-height: 140px; overflow: auto;\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"extraPanels\"]]],null,{\"statements\":[[0,\"          \"],[6,\"div\"],[9,\"class\",\"four wide column padding-no-right\"],[7],[0,\"\\n            \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[19,1,[\"user\",\"profilePic\"]],\"ui circular image events user-profile-medium display-inline-block\"]]],false],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"twelve wide column padding-no-right padding-no-left\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"text-black display-inline-block\"],[9,\"style\",\"line-height: 1.2;\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"text-black text-weight-medium\"],[7],[1,[19,1,[\"user\",\"name\"]],false],[8],[0,\"\\n              \"],[6,\"div\"],[7],[1,[19,1,[\"user\",\"email\"]],false],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/panel-match-popup-list.hbs"
    }
  });

  _exports.default = _default;
});