define("recruit/routes/recruiter/candidate/details/assessment-section/form", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var detailsController = this.controllerFor('recruiter.candidate.details');

      if (detailsController) {
        detailsController.set('selectedAssessmentId', model.get('assessment.id'));
      }
    }
  });

  _exports.default = _default;
});