define("recruit/templates/components/recruiter-view/questions/helpers/question-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dNB93BM4",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"sixteen wide reports-text column padding-no text-left margin-left-fifteen margin-top-seven\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"row\",\"content\",\"title\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[6,\"p\"],[9,\"class\",\"text-weight-medium margin-no\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"span\"],[9,\"class\",\"text-left ellipsis\"],[7],[1,[20,[\"row\",\"content\",\"title\"]],false],[8],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[6,\"p\"],[9,\"class\",\"margin-no\"],[7],[0,\"\\n\\t\\t  \"],[6,\"span\"],[9,\"class\",\"text-left ellipsis\"],[7],[6,\"span\"],[7],[1,[20,[\"row\",\"content\",\"showQuestionContent\"]],false],[8],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/question-details-cell-component.hbs"
    }
  });

  _exports.default = _default;
});