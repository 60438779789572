define("recruit/models/candidate-calendar", ["exports", "recruit/models/user-calendar"], function (_exports, _userCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userCalendar.default.extend({});

  _exports.default = _default;
});