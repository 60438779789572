define("recruit/models/status/assessment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "label": _emberData.default.attr(),
    "sort_key": _emberData.default.attr(),
    "key": Ember.computed.alias('label'),
    "value": Ember.computed.alias('sort_key')
  });

  _exports.default = _default;
});