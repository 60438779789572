define("recruit/templates/components/recruiter-view/assessment-section-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4u5vhovj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[3,\"action\",[[19,0,[]],\"selectAssessmentSection\",[20,[\"assessmentSection\"]]]],[7],[0,\"\\n  \"],[6,\"a\"],[10,\"class\",[26,[\"ui circular \",[18,\"outerClass\"],\" label steps-label \"]]],[10,\"data-content\",[20,[\"section\",\"title\"]],null],[7],[0,\"\\n\"],[0,\"    \"],[1,[25,\"fa-icon\",[[20,[\"section\",\"sectionType\",\"icon\"]]],[[\"class\"],[[25,\"concat\",[\"icon steps-icon \",[20,[\"innerClass\"]]],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"steps-title\"],[7],[1,[20,[\"section\",\"sectionType\",\"label\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"unless\",[[25,\"eq\",[[20,[\"index\"]],[25,\"array-length\",[[20,[\"ASlength\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"hr\"],[10,\"class\",[26,[\"steps-line \",[18,\"lineClass\"]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment-section-icon.hbs"
    }
  });

  _exports.default = _default;
});