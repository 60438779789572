define("recruit/components/candidate-helpers/resume-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames:['resumeColumn'],
    store: Ember.inject.service(),
    userRole: Ember.inject.service(),
    classNameBindings: ['isDownload:text-white'],
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          position: 'top right'
        });
      });
    },
    showError: function showError(err) {
      var _this = this;

      if (err.errors && err.errors.length) {
        err.errors.forEach(function (error) {
          _this.get('toast').error(error.detail);
        });
      } else {
        this.get('toast').error('Please try some time later', 'Something went wrong');
      }
    },
    fileUploadClass: Ember.computed(function () {
      return this.elementId;
    }),
    fileUploadTarget: Ember.computed('fileUploadClass', function () {
      return '.' + this.get('fileUploadClass');
    }),
    exsistingFile: Ember.computed('exsistingFiles.[]', function () {
      return this.get('exsistingFiles.firstObject');
    }),
    actions: {
      postUpload: function postUpload(file) {
        var _this2 = this;

        var resume = this.get('store').createRecord('candidate-file', {
          file_id: file.id,
          candidate: this.get('candidate')
        });
        resume.save().then(function (fileRelation) {
          _this2.set('fileRelation', fileRelation);

          _this2.set('exsistingFile', file);

          if (_this2.get('candidate.resume.length')) {
            _this2.get('candidate.resume').forEach(function (file) {
              _this2.get('store').unloadRecord(file);
            });
          }

          var storedFile = _this2.get('store').push(_this2.get('store').normalize('file', file));

          _this2.get('candidate.resume').pushObject(storedFile);

          _this2.set('fileUploding', false);

          _this2.get('toast').success(_this2.get('candidate.fullName') || _this2.get('candidate.email'), 'Resume uploaded for ');
        }).catch(function (err) {
          _this2.showError(err);
        });
      },
      removeResume: function removeResume() {
        var _this3 = this;

        if (this.get('fileRelation')) {
          this.send('deleteResume', this.get('fileRelation'));
        } else {
          this.get('candidate.candidateFileAttachments').then(function (fileRelation) {
            if (fileRelation.get('length')) {
              _this3.send('deleteResume', fileRelation.get('firstObject'));
            } else {
              return _this3.get('toast').error('Please try some time later', 'Something went wrong');
            }
          }).catch(function (error) {
            return _this3.showError(error);
          });
        }
      },
      deleteResume: function deleteResume(fileRelation) {
        var _this4 = this;

        fileRelation.destroyRecord().then(function () {
          _this4.get('candidate.resume').removeObject(_this4.get('exsistingFile'));

          _this4.set('exsistingFile', Ember.A([]));

          _this4.get('toast').success('Resume removed successfully');
        }).catch(function (err) {
          _this4.showError(err);
        });
      },
      toggleModal: function toggleModal(property, setTitle) {
        this.sendAction('toggleModal', property, setTitle);
      }
    }
  });

  _exports.default = _default;
});