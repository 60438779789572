define("recruit/models/settings-allowed", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    item_value: _emberData.default.attr('string'),
    caption: _emberData.default.attr('string'),
    description: Ember.computed.alias('caption'),
    key: Ember.computed.alias('caption'),
    value: Ember.computed.alias('item_value')
  });

  _exports.default = _default;
});