define("recruit/components/live-session/videos", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    showTextIcon: Ember.computed(function () {
      return !this.get('currentUser.me.organization.isIconEvaluation');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$('body').addClass('action_bg');
    },
    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);

      Ember.$('body').removeClass('action_bg');
    },
    actions: {
      back: function back() {
        history.back();
      }
    }
  });

  _exports.default = _default;
});