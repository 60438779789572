define("recruit/templates/components/recruiter-view/questions/helpers/type-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2LnIGpe+",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui centered two column grid margin-no\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"middle aligned column padding-no text-right margin-right-ten\"],[9,\"style\",\"max-width: 60px;\"],[7],[0,\"\\n\\t  \"],[1,[25,\"fa-icon\",[[20,[\"questionTypeImage\"]]],[[\"class\"],[\"text-size-fourteen text-black\"]]],false],[0,\"\\n\\t\"],[8],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"middle aligned column padding-no text-left text-size-fourteen text-weight-normal\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"text-black margin-no\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"text-capitalize\"],[7],[1,[18,\"questionDisplayType\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/type-details-cell-component.hbs"
    }
  });

  _exports.default = _default;
});