define("recruit/components/model-table/recruiter/assessment/assessment-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.set('arr', Ember.A([]));
    },
    statusValue: Ember.computed('value.isFulfilled', function () {
      var _this = this;

      if (this.get('value.isFulfilled')) {
        var asscs = this.get('value');
        var found = false,
            correctAssc = null;
        asscs.forEach(function (assc) {
          if (assc.get('assessmentSection.id') == _this.get('column.extra.as.id') && !found) {
            found = true;
            correctAssc = assc;
          }
        });

        if (found && correctAssc) {
          if (this.get('value.firstObject.id') == correctAssc.get('id')) {
            this.set('currentSection', true);
          }

          return correctAssc;
        } // let assc = this.get('value').filter((asscObj) => asscObj.get('assessmentSection.id') == this.get('column.as.id'));
        // // if(this.get('value.lastObject.id') == assc.get('firstObject.id')) {
        // //   this.set('currentSection', true)
        // // }
        // // console.log(this.get('row.id'), this.get('column.as.id'), assc.get('firstObject.id'))
        // return assc.get('lastObject');

      }
    })
  });

  _exports.default = _default;
});