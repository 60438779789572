define("recruit/adapters/expanded-assessment", ["exports", "recruit/adapters/assessment"], function (_exports, _assessment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _assessment.default.extend({
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + '?expand=addedCount,invitedCount,inProgressCount,attendedCount,assessedCount,selectedCount,holdCount,rejectedCount';
    }
  });

  _exports.default = _default;
});