define("recruit/components/recruiter-view/questions/helpers/type-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    questionDisplayType: Ember.computed('row.type', function () {
      return this.get('row.type') === "multiple choice" ? "MCQ" : this.get('row.type') === "multiple choice multiple answer" ? "MCA" : this.get('row.type');
    }),
    questionTypeImage: Ember.computed('row.type', function () {
      switch (this.get('row.type')) {
        case 'multiple choice':
          return 'list-ul';

        case 'multiple choice multiple answer':
          return 'tasks';

        case 'code':
          return 'terminal';

        case 'typing':
          return 'font';

        case 'essay':
          return 'marker';

        case 'interview':
          return 'user-headset';
      }
    })
  });

  _exports.default = _default;
});