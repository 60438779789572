define("recruit/components/model-table/recruiter/assessment/candidate/evaluation-table", ["exports", "npm:lodash", "recruit/mixins/table-common"], function (_exports, _npmLodash, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    modelType: 'assessmentSectionCandidate',
    s: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        'panel_user_id': null,
        'unassigned': null
      });
    },
    selectedCandidates: Ember.computed('table.selectedRows', function () {
      return this.get('table.selectedRows').map(function (row) {
        return row.get('assessmentCandidate.content');
      });
    }),
    customFilterObserver: Ember.observer('customListFilters.[]', function () {
      var _this = this;

      var groupKeys = _npmLodash.default.keys(_npmLodash.default.groupBy(this.get('customListFilters'), 'type'));

      var recommendation = this.get('customListFilters').filter(function (item) {
        return item.get('type') === "recommendation";
      });
      var query = Ember.A([]);
      var recQuery = Ember.A([]);

      if (recommendation && recommendation.length) {
        recommendation.map(function (rec) {
          recQuery.pushObject(rec.get('value'));
        });
      }

      groupKeys.forEach(function (key) {
        _npmLodash.default.groupBy(_this.get('customListFilters'), 'type')[key].forEach(function (obj) {
          if (obj.get('queryString')) {
            query.pushObject(obj.get('queryString'));
          }
        });
      });

      if (recQuery && recQuery.length) {
        query.pushObject("(latestEvaluation.recommendation: (".concat(recQuery.join(','), ") AND assessment_section_id: ").concat(this.get('assessmentSection.id'), ")"));
      }

      this.setProperties({
        'panel_user_id': this.get('customListFilters').filterBy('type', 'panel').map(function (user) {
          return user.get('id');
        }),
        'unassigned': this.get('customListFilters').filterBy('type', 'unassigned').get('firstObject.id'),
        'q': query.join(' AND ')
      });
    }),
    selectedCandidatesObserver: Ember.observer('selectedCandidates.[]', function () {
      this.sendAction('setCandidates', this.get('selectedCandidates'));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('emptyStateWidth', Ember.$('#assessmentCandidateTable').innerWidth());
    },
    actions: {
      routeTransistion: function routeTransistion(row) {
        this.get('router').transitionTo('recruiter.candidate.details.assessment', row.get('assessmentCandidate.candidate.id'), row.get('assessmentCandidate.id'));
      }
    }
  });

  _exports.default = _default;
});