define("recruit/models/column/master", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    align: _emberData.default.attr('string'),
    cellClassNames: _emberData.default.attr(),
    cellComponent: _emberData.default.attr('string'),
    classNames: _emberData.default.attr(),
    sortWeight: _emberData.default.attr('number', {
      defaultValue: 100
    }),
    label: _emberData.default.attr('string'),
    sortable: _emberData.default.attr('boolean'),
    sorted: _emberData.default.attr('boolean'),
    valuePath: _emberData.default.attr('string'),
    sortAttribute: _emberData.default.attr('string'),
    width: _emberData.default.attr('string'),
    showTimeAgo: _emberData.default.attr('boolean'),
    dateFormat: _emberData.default.attr('string'),
    objectType: _emberData.default.attr('string'),
    isDefault: _emberData.default.attr('boolean'),
    isShow: _emberData.default.attr('boolean'),
    customField: _emberData.default.attr('string'),
    tableType: _emberData.default.attr('string'),
    extra: _emberData.default.attr(),
    component: _emberData.default.attr('string'),
    fixed: _emberData.default.attr('boolean'),
    isColumnSelected: Ember.computed(function () {
      var appliedColumn = this.get('store').peekRecord('column/applied', this.get('id'));

      if (appliedColumn) {
        return appliedColumn.get('isSelected');
      } else {
        return this.get('isDefault');
      }
    }) // isColumnSelected: Ember.computed(function() {
    //   let appliedColumns = this.get('store').peekAll('column/applied')
    //   if(appliedColumns.get('length')) {
    //     let columnFound = false;
    //     appliedColumns.forEach((column) => {
    //       if(column.get('id') == this.get('id') && !columnFound) {
    //         columnFound = column.get('isSelected');
    //       }
    //     })
    //     this.set('isRecordFound', true);
    //     return columnFound;
    //   } else {
    //     this.set('isRecordFound', false);
    //     return this.get('isDefault');
    //   }
    // })

  });

  _exports.default = _default;
});