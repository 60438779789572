define("recruit/components/live-schedule/candidate-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('params.cid')) {
        this.get('store').findRecord('candidate', +this.get('params.cid')).then(function (res) {
          _this.set('candidateRecords', res);
        });
      }
    }
  });

  _exports.default = _default;
});