define("recruit/adapters/evaluation", ["exports", "recruit/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'evaluation';
    },
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + "?expand=assessment-section";
    }
  });

  _exports.default = _default;
});