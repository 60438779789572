define("recruit/models/panel-candidate", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    isEvaluated: _emberData.default.attr('boolean'),
    addedBy: _emberData.default.belongsTo('user'),
    evaluations: _emberData.default.hasMany('evaluation'),
    assessmentCandidate: _emberData.default.belongsTo('assessment-candidate'),
    panel: _emberData.default.belongsTo('panel'),
    assessmentSectionCandidate: _emberData.default.belongsTo('assessment-section-candidate', {
      inverse: null
    }),
    nextCandidateId: _emberData.default.attr('number'),
    // Model alias to reduce verbosity
    assc: Ember.computed.alias('assessmentSectionCandidate'),
    ac: Ember.computed.alias('assessmentCandidate'),

    /*--------------------------*/

    /* Ember computed Properties*/

    /*--------------------------*/
    isRelationsLoaded: Ember.computed(function () {
      var _this = this;

      this.get('assessmentCandidate').then(function () {
        return _this.get('assessmentCandidate.candidate');
      }).then(function () {
        return _this.get('assessmentCandidate.assessment');
      }).then(function () {
        return _this.get('assessmentCandidate.evaluations');
      }) // .then(() => this.get('assessmentCandidate.assessmentSectionCandidates'))
      // .then(asscs => asscs.map(assc => assc.get('assessmentSection')))
      .finally(function () {
        return _this.set('isRelationsLoaded', true);
      }); //eslint-disable-line ember/no-side-effects
    }),
    isNameLoaded: Ember.computed('addedBy', function () {
      var _this2 = this;

      return this.get('addedBy').then(function (obj) {
        _this2.set('isNameLoaded', obj.get('name')); //eslint-disable-line ember/no-side-effects

      });
    }),
    pending_since: Ember.computed('created_at', 'isEvaluated', 'assc.completed_at', function () {
      if (!this.get('isEvaluated')) {
        if (!this.get('assc.completed_at')) {
          return (0, _moment.default)(this.get('created_at'));
        } else {
          return _moment.default.min((0, _moment.default)(this.get('created_at')), (0, _moment.default)(this.get('assessmentSectionCandidate.completed_at')));
        }
      } else {
        return 'not pending';
      }
    }),
    evaluationStatus: Ember.computed('isEvaluated', 'evaluations.@each.recommendation', function () {
      if (this.get('isEvaluated') && this.get('evaluations.length')) {
        switch (this.get('evaluations.firstObject.recommendation')) {
          case 'select':
            return 'selected';

          case 'reject':
            return 'rejected';

          case 'hold':
            return 'hold';
        }
      } else {
        return 'pending';
      }
    }),
    evaluationComment: Ember.computed('isEvaluated', 'evaluations.@each.evaluationComments', function () {
      var comments;

      if (this.get('isEvaluated') && this.get('evaluations.length')) {
        comments = this.get('evaluations.firstObject.evaluationComments');

        if (comments.get('length')) {
          return comments.get('firstObject.content');
        }
      }
    })
  });

  _exports.default = _default;
});