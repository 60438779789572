define("recruit/components/recruiter-view/questions/question-bank-actions", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    selectedQuestion: Ember.computed('questions', function () {
      return this.get('questions').map(function (row) {
        return row.get('content');
      });
    }),
    actions: {
      importQuestions: function importQuestions() {
        this.get('router').transitionTo('recruiter.questions.bulk-create');
      },
      createQuestions: function createQuestions() {
        this.get('router').transitionTo('recruiter.questions.create');
      },
      editQuestion: function editQuestion(question) {
        this.get('router').transitionTo('recruiter.questions.edit', question.get('firstObject.id'));
      }
    }
  });

  _exports.default = _default;
});