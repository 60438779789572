define("recruit/components/helpers/group-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectObserver: Ember.observer('items.[]', 'items.@each.isColumnSelected', function () {
      var allChecked = true;
      var allUnchecked = true;
      var parentCheckbox = Ember.$(this.element).find('.master.checkbox');

      if (this.get('items.length')) {
        this.get('items').forEach(function (item) {
          if (item.get('isColumnSelected')) {
            allUnchecked = false;
          } else {
            allChecked = false;
          }
        });

        if (allChecked) {
          parentCheckbox.checkbox('set checked');
        } else if (allUnchecked) {
          parentCheckbox.checkbox('set unchecked');
        } else {
          parentCheckbox.checkbox('set indeterminate');
        }
      }
    }).on('didInsertElement'),
    actions: {
      selectedAll: function selectedAll(checked, attr) {
        var element = attr.element;
        var childCheckbox = Ember.$(element).closest('.checkbox').siblings('.list').find('.checkbox');

        if (checked) {
          childCheckbox.checkbox('check');
        } else {
          childCheckbox.checkbox('uncheck');
        }
      },
      sendProperty: function sendProperty(item, checked) {
        this.sendAction('actionName', item, checked);
      }
    }
  });

  _exports.default = _default;
});