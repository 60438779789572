define("recruit/templates/components/live-session/live-videos-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vfCNGIvJ",
    "block": "{\"symbols\":[\"selectedVideo\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"bottom-pd\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"currentVideo\",\"previous_video_id\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[9,\"class\",\"chevron left icon change-video left\"],[3,\"action\",[[19,0,[]],\"changeVideo\",[20,[\"currentVideo\"]],false]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"liquid-bind\",[[20,[\"currentVideo\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"video-flowplayer\",null,[[\"video\",\"customClass\"],[[19,1,[\"liveWebrtcRecording\"]],\"video-container padding-sm align-center flowplayer fp-outlined fp-slim\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[20,[\"currentVideo\",\"next_video_id\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[9,\"class\",\"chevron right icon change-video right\"],[3,\"action\",[[19,0,[]],\"changeVideo\",[20,[\"currentVideo\"]],true]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-session/live-videos-player.hbs"
    }
  });

  _exports.default = _default;
});