define("recruit/models/question", ["exports", "ember-data", "npm:lodash", "moment", "recruit/validations/question", "recruit/validations/essay", "recruit/validations/mcq", "recruit/validations/typing", "recruit/validations/automated", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberData, _npmLodash, _moment, _question, _essay, _mcq, _typing, _automated, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    EssayQuestionValidations: _essay.default,
    McqQuestionValidations: _mcq.default,
    TypingQuestionValidations: _typing.default,
    AsyncQuestionValidations: _automated.default,
    type: _emberData.default.attr('string', {
      defaultValue: 'multiple choice'
    }),
    title: _emberData.default.attr('string'),
    content: _emberData.default.attr(),
    contentRaw: _emberData.default.attr('string'),
    plainTxtContent: _emberData.default.attr('string'),
    hint: _emberData.default.attr('string'),
    choice_1: _emberData.default.attr('string'),
    choice_2: _emberData.default.attr('string'),
    choice_3: _emberData.default.attr('string'),
    choice_4: _emberData.default.attr('string'),
    choice_5: _emberData.default.attr('string'),
    correct_choice: _emberData.default.attr('number'),
    test_case_1: _emberData.default.attr('string'),
    test_result_1: _emberData.default.attr('string'),
    test_case_2: _emberData.default.attr('string'),
    test_result_2: _emberData.default.attr('string'),
    test_case_3: _emberData.default.attr('string'),
    test_result_3: _emberData.default.attr('string'),
    test_case_4: _emberData.default.attr('string'),
    test_result_4: _emberData.default.attr('string'),
    test_case_5: _emberData.default.attr('string'),
    test_result_5: _emberData.default.attr('string'),
    leftList: _emberData.default.attr(),
    rightList: _emberData.default.attr(),
    matchMap: _emberData.default.attr(),
    tagData: _emberData.default.attr(),
    testCase: _emberData.default.attr(),
    created_by: _emberData.default.belongsTo('user'),
    updated_by: _emberData.default.belongsTo('user'),
    updated_at: _emberData.default.attr('pgdate'),
    created_at: _emberData.default.attr('pgdate'),
    // skills          : DS.hasMany('skill'),
    questionSkills: _emberData.default.hasMany('question-skill'),
    files: _emberData.default.hasMany('file'),
    hasMultipleAnswer: Ember.computed('type', function () {
      return this.get('type') == 'multiple choice multiple answer';
    }),
    formComponent: Ember.computed('type', function () {
      var type = this.get('type') || 'multiple choice';

      switch (_npmLodash.default.toLower(type)) {
        case 'multiple choice multiple answer':
          return 'recruiter-view/questions/create/mcq';

        case 'multiple choice':
          return 'recruiter-view/questions/create/mcq';

        case 'mcq':
          return 'recruiter-view/questions/create/mcq';

        case 'interview':
          return 'recruiter-view/questions/create/automated';

        case 'automated':
          return 'recruiter-view/questions/create/automated';

        default:
          return "recruiter-view/questions/create/".concat(type);
      }
    }),
    QuestionValidations: Ember.computed('type', function () {
      var type = this.get('type') || 'multiple choice';

      switch (_npmLodash.default.toLower(type)) {
        case 'multiple choice multiple answer':
          return this.get('McqQuestionValidations');

        case 'multiple choice':
          return this.get('McqQuestionValidations');

        case 'mcq':
          return this.get('McqQuestionValidations');

        case 'typing':
          return this.get('TypingQuestionValidations');

        case 'essay':
          return this.get('EssayQuestionValidations');

        case 'interview':
          return this.get('AsyncQuestionValidations');

        case 'automated':
          return this.get('AsyncQuestionValidations');
      }
    }),
    questionTypeIcon: Ember.computed('type', function () {
      var type = this.get('type') || 'multiple choice';

      switch (_npmLodash.default.toLower(type)) {
        case 'multiple choice multiple answer':
          return 'tasks';

        case 'multiple choice':
          return "list";

        case 'mcq':
          return "list";

        case 'interview':
          return "user-headset";

        case 'automated':
          return "user-headset";

        case 'essay':
          return "marker";

        case 'typing':
          return "font";
      }
    }),
    shortType: Ember.computed('type', function () {
      var type = this.get('type') || 'multiple choice';

      switch (_npmLodash.default.toLower(type)) {
        case 'multiple choice multiple answer':
          return 'mca';

        case 'multiple choice':
          return "mcq";

        case 'mcq':
          return "mcq";

        case 'interview':
          return "automated";

        case 'automated':
          return "automated";

        default:
          return this.get('type') || 'mcq';
      }
    }),
    showQuestionContent: Ember.computed('plainTxtContent', 'content', function () {
      return this.get('plainTxtContent') ? this.get('plainTxtContent') : _npmLodash.default.includes(this.get('content'), "audio") ? "Audio" : "Image";
    }),
    createdAtFormat: Ember.computed('created_at', function () {
      return (0, _moment.default)(this.get('created_at')).format("DD MMM YYYY");
    }),
    modelAttrs: Ember.computed(function () {
      var arr = Ember.A([]);
      this.eachAttribute(function (name) {
        arr.push(name);
      });
      return arr;
    }),
    parse: function parse(row) {
      var _this = this;

      _npmLodash.default.forOwn(Object.keys(row), function (name) {
        if (_npmLodash.default.includes(_this.get('modelAttrs'), name)) {
          var v = _npmLodash.default.trim(_npmLodash.default.get(row, name) || null);

          _this.set(name, v);
        }
      });

      return Ember.RSVP.resolve(this);
    },
    validate: function validate() {
      var changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_question.default), _question.default);
      return changeset.validate().then(function () {
        return changeset;
      });
    }
  });

  _exports.default = _default;
});