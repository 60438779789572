define("recruit/routes/panel/candidate/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/data/filters/p-c"], function (_exports, _authenticatedRouteMixin, _pC) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      isPendingList: {
        replace: true
      },
      searchText: {
        replace: true
      },
      filters: {
        replace: true
      }
    },
    user: Ember.inject.service('current-user'),
    performance: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('store').push({
        data: _pC.default
      });
      this.get('performance').start('Panel:Candidates:list');
    },
    model: function model() {
      return Ember.RSVP.hash({
        masterFilters: this.store.peekAll('filter/master'),
        appliedFilters: this.store.query('filter/applied', {
          objectType: 'pc'
        }),
        columnMaster: this.store.query('column/master', {
          table_type: 'p-c'
        }),
        columnApplied: this.store.findAll('column/applied'),
        organization: this.get('user.me.organization')
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);

      var appliedFilters = Ember.A([]); // models.appliedFilters.forEach(filter => appliedFilters.pushObject(filter));

      controller.set('appliedFilters', appliedFilters);
      models.organization.getSectionTypeList(true);
      controller.set('availableSections', models.organization.availableSections);
      var columns = Ember.A([]);
      models.columnMaster.forEach(function (column) {
        if (column.get('isColumnSelected')) {
          var modifiedColumn = column.toJSON(); // modifiedColumn.width="200px";

          columns.pushObject(modifiedColumn);
        }
      });
      controller.set('columns', columns.sortBy('sortWeight'));
      controller.set('masterFilters', models.masterFilters.filterBy('objectType', 'pc'));
    },
    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        this.controller.set('searchText', null);
        var navRoutes = ["panel.calendar", "panel.live-session.index"];
        navRoutes.forEach(function (routeName) {
          if (transition.targetName == routeName) {
            _this.controller.set('isPendingList', false);

            _this.controller.set('filters', null);

            return;
          }
        });
      }
    }
  });

  _exports.default = _default;
});