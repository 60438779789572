define("recruit/routes/recruiter/assessment/details", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/data/section-filter", "npm:lodash"], function (_exports, _authenticatedRouteMixin, _sectionFilter, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var activeHeaderRoutes = ['recruiter.assessment.details.overview', 'recruiter.assessment.details.candidate', 'recruiter.assessment.details.assessment-section', 'recruiter.assessment.details.notification'];

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      this.get('store').push({
        data: _sectionFilter.default
      });
    },
    model: function model(params) {
      return this.get('store').findRecord('expanded-assessment', +params.id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', model);
      this.addObserver('router.currentRouteName', function () {
        if (_npmLodash.default.includes(activeHeaderRoutes, this.get('router.currentRouteName'))) {
          this.controller.set('invertedHeader', true);
        } else {
          this.controller.set('invertedHeader', false);
        }
      });
    },
    deactivate: function deactivate() {
      this.removeObserver('router.currentRouteName');
    }
  });

  _exports.default = _default;
});