define("recruit/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "recruit/config/environment", "recruit/data/index", "moment"], function (_exports, _applicationRouteMixin, _environment, _index, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global localforage */
  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service('currentUser'),
    offline: Ember.inject.service('offline'),
    heapIo: Ember.inject.service('heap-io'),
    // session: service(),
    sessionAuthenticated: function sessionAuthenticated() {
      //Overwriting ember-simple-auth's sessionAuthenticated function
      var attemptedTransition = this.get('session.attemptedTransition');
      var cookies = Ember.getOwner(this).lookup('service:cookies');
      var redirectTarget = cookies.read('ember_simple_auth-redirectTarget');

      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null); //Clearing stored cookie if exist for redirection, since transition is made by stored Transition.

        if (redirectTarget) {
          cookies.clear('ember_simple_auth-redirectTarget');
          /*
            cookies.clear() doesn't clear sometimes
            Soo, setting key's value to empty string and expiring instantly, which cleared the property from cookies
          */

          if (cookies.read('ember_simple_auth-redirectTarget')) {
            cookies.write('ember_simple_auth-redirectTarget', '', {
              path: '/',
              expires: (0, _moment.default)().toDate()
            });
          }
        }
      } else if (redirectTarget) {
        this.transitionTo(redirectTarget);
        cookies.clear('ember_simple_auth-redirectTarget');
      } else {
        this.transitionTo(this.get('routeAfterAuthentication'));
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (_environment.default.APP.VERSION) {
        console.log('Version: ' + _environment.default.APP.VERSION); // eslint-disable-line no-console

        console.log('Last Update: ' + (0, _moment.default)(_environment.default.APP.LAST_UPDATE).toDate()); // eslint-disable-line no-console
      } // Added to remove all applied filters


      if (localforage) {
        localforage.removeItem('tv_filters');
      }

      if (!_environment.default.isDevelopment) {
        this.get('heapIo').load();
      }

      return this._loadCurrentUser();
    },
    model: function model() {
      return this.get('store').push({
        data: _index.default
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('isDevelopment', _environment.default.isDevelopment);
      controller.getTargetRoute();
    },
    actions: {
      openHelpModal: function openHelpModal() {
        this.controller.toggleProperty('isHelpModal');
      },
      openReleaseModal: function openReleaseModal() {
        // if(!this.controller.get('isCognizant')) {
        this.controller.toggleProperty('isReleaseModal'); // }
      },
      openFeedBack: function openFeedBack() {
        var that = this;
        this.controller.toggleProperty('isHelpModal');
        Ember.$.feedback({
          ajaxURL: 'http://test.url.com/feedback',
          html2canvasURL: '/assets/js/html2canvas.js',
          feedbackButton: '.feedback-trigger',
          strokeStyle: 'black',
          // Border color for highlighter
          initialBox: false,
          //Description modal comes first
          lineWidth: 3,
          // Border width of hightlighted area
          postHTML: false,
          onFormSubmit: function onFormSubmit(feedback) {
            that.send('postFeedBack', feedback);
          }
        });
      },
      postFeedBack: function postFeedBack(feedback) {
        this.get('store').createRecord('feedback', feedback).save();
      } // willTransition(transition) {
      //   transition.then(() => {
      //     let params = this._mergeParams(transition.params);
      //     let url;
      //     // generate doesn't like empty params.
      //     if (_.isEmpty(params)) {
      //       url = transition.router.generate(transition.targetName);
      //     } else {
      //       url = transition.router.generate(transition.targetName, params);
      //     }
      //     // Do not save the url of the transition to login route.
      //     if (!url.includes('login')) {
      //       this.set('session.previousRouteName', url);
      //     }
      //   });
      // }

    },
    _loadCurrentUser: function _loadCurrentUser() {
      this.get('offline').load();
      return this.get('currentUser').load();
    }
  });

  _exports.default = _default;
});