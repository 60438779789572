define("recruit/models/lf-adapter/assessment-section", ["exports", "ember-data", "recruit/models/assessment-section"], function (_exports, _emberData, _assessmentSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _assessmentSection.default.extend({
    section: _emberData.default.belongsTo('lf-adapter/section')
  });

  _exports.default = _default;
});