define("recruit/models/filter/master", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    field: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    sortOrder: _emberData.default.attr('number'),
    roles: _emberData.default.attr(),
    isRemote: _emberData.default.attr('boolean'),
    doPeekModel: _emberData.default.attr('boolean'),
    objectType: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    optionLabel: _emberData.default.attr('string'),
    modelQuery: _emberData.default.attr(),
    queryTemplate: _emberData.default.attr('string'),
    addColumn: _emberData.default.attr('boolean'),
    list: _emberData.default.attr(),
    options: _emberData.default.attr(),
    // appliedFilter : DS.hasMany('filter/applied'),
    // Computed property for power select dropdown
    key: Ember.computed.alias('field')
  });

  _exports.default = _default;
});