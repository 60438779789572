define("recruit/routes/recruiter/candidate/create/basic-details", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/validations/candidate"], function (_exports, _authenticatedRouteMixin, _candidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('isBulkUpload', false);
      controller.set('CandidateValidations', _candidate.default);
      controller.set('file', null);
    }
  });

  _exports.default = _default;
});