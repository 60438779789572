define("recruit/components/helpers/table-checkboxs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasSelection: false,
    isChecked: false,
    selectionChanged: Ember.observer('table.rows.[]', 'table.selectedRows.[]', function () {
      var rows = this.get('table.rows.length');
      var selectedRows = this.get('table.selectedRows.length');
      this.set('isChecked', rows === selectedRows && rows);
    }),
    actions: {
      selectAll: function selectAll() {
        this.get('table.rows').setEach('selected', true);
        this.set('hasSelection', true);
      },
      deselectAll: function deselectAll() {
        this.get('table.selectedRows').setEach('selected', false);
        this.set('hasSelection', false);
      }
    }
  });

  _exports.default = _default;
});