define("recruit/serializers/column/master", ["exports", "recruit/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.forEach(function (column) {
        if (Ember.get(column, 'component')) {
          column.component = column.component.split('.').join('/');
        }

        if (Ember.get(column, 'cellComponent')) {
          column.cellComponent = column.cellComponent.split('.').join('/');
        }
      });
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});