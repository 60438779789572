define("recruit/templates/panel/live-session/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "znSUI8LB",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Live Session\"],null],false],[0,\"\\n\"],[1,[25,\"page-title\",[[20,[\"model\",\"name\"]]],null],false],[0,\"\\n\"],[1,[25,\"live-session/view\",null,[[\"model\",\"isPanel\"],[[20,[\"model\"]],true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/panel/live-session/view.hbs"
    }
  });

  _exports.default = _default;
});