define("recruit/components/events/event-invites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    hideActionsCard: Ember.computed('model.eventCandidates.[]', 'model.status', function () {
      return this.get('model.status') == 1 && !this.get('model.eventCandidates.length');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('renderComponent', true);
    },
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.dropdown').dropdown();
    },
    actions: {
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      },
      bulkImportCandidates: function bulkImportCandidates(property) {
        if (this.get('model.status') == 1) {
          this.send('toggleModal', "addCandidate");
        } else {
          this.toggleProperty(property);
        }
      },
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
      },
      rerenderTable: function rerenderTable(property) {
        var _this = this;

        if (property) {
          this.set('renderComponent', false);
          setTimeout(function () {
            _this.set('renderComponent', true);
          }, 2);
        }
      },
      sendReminder: function sendReminder(property) {
        var _this2 = this;

        this.set('isLoading', true);
        this.get('store').createRecord('actions/event-candidate-notify', _defineProperty({
          event: this.get('model')
        }, property, true)).save().then(function () {
          _this2.get('toast').success('Reminder sent to all the candidates successfully!');
        }).catch(function (error) {
          if (!error || Ember.typeOf(error.errors) != 'array') {
            _this2.get('toast').error('Please try after sometime', 'Reminding candidate failed');
          } else {
            error.errors.forEach(function (e) {
              return _this2.get('toast').error(e.detail);
            });
          }
        }).finally(function () {
          _this2.set('isLoading', false);

          _this2.send('toggleModal', property == "register_reminder" ? "remindToRegister" : "remindToAttend");
        });
      }
    }
  });

  _exports.default = _default;
});