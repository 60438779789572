define("recruit/templates/recruiter/reports/time-range/time-per-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+lMJe5Un",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"style\",\"margin: 5px;\"],[7],[0,\"\\n  \"],[1,[25,\"reports/graphs/time-range/time-per-month\",null,[[\"selectedRow\",\"hideGraph\",\"graphValue\",\"graphColors\"],[[20,[\"selectedRow\"]],[20,[\"hideGraph\"]],[20,[\"graphValue\"]],[20,[\"graphColors\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"style\",\"margin: 5px;\"],[7],[0,\"\\n  \"],[1,[25,\"reports/table/time-range/time-per-month\",null,[[\"selectedRow\",\"graphValue\",\"hideGraph\"],[[20,[\"selectedRow\"]],[20,[\"graphValue\"]],[20,[\"hideGraph\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/reports/time-range/time-per-month.hbs"
    }
  });

  _exports.default = _default;
});