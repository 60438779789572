define("recruit/routes/recruiter/candidate/details/assessment-section/answers", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var detailsController = this.controllerFor('recruiter.candidate.details');

      if (detailsController) {
        detailsController.set('selectedAssessmentId', model.get('assessment.id'));
      }

      controller.set('isComponentLoading', true);
      controller.set('assessmentPending', false); // controller.set('isDevelopment', ENV.isDevelopment);

      controller.set('selectedAssc', model);
      return Ember.RSVP.Promise.resolve().then(function () {
        return Ember.RSVP.hash({
          section: model.get('section'),
          status: model.get('status')
        });
      }).then(function (_ref) {
        var section = _ref.section;
        controller.set('currentSection', section);
        controller.set('assessmentPending', !model.get('showAnswers'));
      }).finally(function () {
        controller.set('isComponentLoading', false);
      });
    },
    actions: {
      backToAc: function backToAc() {
        history.back();
      },
      toggleModal: function toggleModal() {
        this.controller.toggleProperty('isSectionChangeStatus');
      }
    }
  });

  _exports.default = _default;
});