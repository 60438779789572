define("recruit/services/ember-load-config", ["exports", "ember-load/services/ember-load-config", "recruit/config/environment"], function (_exports, _emberLoadConfig, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var userConfig = _environment.default['ember-load'] || {};

  var _default = _emberLoadConfig.default.extend({
    loadingIndicatorClass: userConfig.loadingIndicatorClass
  });

  _exports.default = _default;
});