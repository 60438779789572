define("recruit/templates/components/events/panel-match-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ncEd9g2y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"panel\",\"user\",\"isFulfilled\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"threshold\"]]],null,{\"statements\":[[4,\"if\",[[25,\"lt\",[[20,[\"index\"]],[20,[\"threshold\"]]],null]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"event-user-details display-inline-block\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"display-inline-block panel-match-tooltip\"],[10,\"data-html\",[26,[[18,\"tooltipData\"]]]],[7],[0,\"\\n          \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"panel\",\"user\",\"profilePic\"]],[20,[\"customClass\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"event-user-details display-inline-block\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"display-inline-block panel-match-tooltip\"],[10,\"data-html\",[26,[[18,\"tooltipData\"]]]],[7],[0,\"\\n        \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"panel\",\"user\",\"profilePic\"]],[20,[\"customClass\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"loader-image animated-background display-inline-block\"],[9,\"style\",\"height: 27px; width: 27px; position: relative; top: 5px;\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/panel-match-user.hbs"
    }
  });

  _exports.default = _default;
});