define("recruit/templates/components/reports/advanced/conversion-report-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tAtSplgW",
    "block": "{\"symbols\":[\"list\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid text-black\"],[9,\"style\",\"padding: 15px 15px 45px;\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"filteredList\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"filteredList\"]]],null,{\"statements\":[[4,\"if\",[[19,1,[\"label\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"display-inline-block\"],[10,\"data-tooltip\",[26,[[19,1,[\"label\"]]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"class\",\"reports-advanced-label ellipsis\"],[9,\"style\",\"line-height: 1.3; display: inline;\"],[3,\"action\",[[19,0,[]],\"redirect\",[19,1,[]]]],[7],[0,\"\\n              \"],[6,\"i\"],[9,\"class\",\"blue chart line icon margin-right-five\"],[7],[8],[1,[19,1,[\"label\"]],false],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column text-center\"],[9,\"style\",\"word-wrap: break-word;\"],[7],[0,\"\\n      No results found for \"],[6,\"span\"],[9,\"class\",\"text-blue text-weight-medium\"],[7],[6,\"i\"],[7],[0,\"\\\"\"],[1,[18,\"filterValue\"],false],[0,\"\\\"\"],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/advanced/conversion-report-list.hbs"
    }
  });

  _exports.default = _default;
});