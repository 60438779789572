define("recruit/controllers/user/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showCustomNavBar: Ember.computed(function () {
      if (Ember.getOwner(this).lookup('controller:application').currentPath === 'user.settings.picture') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});