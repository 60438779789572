define("recruit/templates/components/recruiter-view/candidate/create/create-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Lng+kz0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"showHeader\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui segments border-radius-none box-shadw-none\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segment text-black margin-no\"],[7],[0,\"\\n      CREATE CANDIDATE\\n      \"],[6,\"div\"],[9,\"class\",\"text-blue float-right margin-ten-right\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui toggle checkbox\"],[7],[0,\"\\n          \"],[1,[25,\"ui-checkbox\",null,[[\"class\",\"label\",\"checked\",\"onChange\"],[\"toggle\",\"Bulk Upload\",[20,[\"isBulkUpload\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isBulkUpload\"]]],null]],null]]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"fieldMaster\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"ui segment padding-no margin-no\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui ordered fluid steps\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"step\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"title\"],[7],[0,\"Basic\"],[8],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"description\"],[7],[0,\"Basic details\"],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"step\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"title\"],[7],[0,\"Additional\"],[8],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"description\"],[7],[0,\"Custom Fields\"],[8],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/candidate/create/create-header.hbs"
    }
  });

  _exports.default = _default;
});