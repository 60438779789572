define("recruit/components/graphs/x-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this.drawChart();
    },
    drawChart: function drawChart() {
      Ember.$('#highcharts-container').highcharts({
        chart: {
          type: 'xrange'
        },
        title: {
          text: '<b>' + this.get('chartTitle') + '</b>',
          align: 'center',
          x: 67,
          style: {
            fontSize: '15px'
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          title: {
            text: "".concat(this.get('titleText'), " (in ").concat(this.get('graphTooltipSuffix'), ")"),
            margin: this.get('axisMargin'),
            style: {
              fontSize: this.get('axisLabelFontSize')
            }
          },
          minorTickLength: 0,
          tickLength: 0,
          lineWidth: 0,
          labels: {
            enabled: false
          },
          min: this.get('dataArray.firstObject.x'),
          max: this.get('dataArray.lastObject.x2'),
          type: 'Days'
        },
        yAxis: {
          title: {
            text: ''
          },
          labels: {
            style: {
              fontSize: this.get('axisLabelFontSize')
            }
          },
          categories: ['Send Invitations', 'Complete Assessment', 'Assign Panel', 'Evaluate', 'Final Selection'],
          reversed: true
        },
        tooltip: {
          backgroundColor: 'rgba(255,255,255,1)',
          pointFormat: "<b style=\"color:".concat(this.get('pointColor'), "; font-size:").concat(this.get('tooltipFontSize'), "\">{point.name}</b><br><b style=\"font-size: ").concat(this.get('subTooltipFont'), ";\">{point.diff} ").concat(this.get('graphTooltipSuffix'), "</b><br><div style=\"font-size: ").concat(this.get('subTooltipFont'), ";\">{point.tooltip_text}</div>")
        },
        plotOptions: {
          series: {
            showInLegend: false,
            borderRadius: 0,
            useHTML: true,
            dataLabels: {
              enabled: true,
              style: {
                textOutline: false,
                fontWeight: 'bold'
              },
              format: "<b style=\"color:#4c4c4c; font-size:".concat(this.get('plotFontSize'), ";\">{point.diff} ").concat(this.get('graphLabelSuffix'), "</b>")
            }
          }
        },
        "series": [{
          pointWidth: this.get('pointWidth'),
          data: this.get('dataArray')
        }]
      });
    }
  });

  _exports.default = _default;
});