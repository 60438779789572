define("recruit/components/file-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleView: function toggleView() {
        Ember.$('#files_attached').toggleClass('tv-hide');
      }
    }
  });

  _exports.default = _default;
});