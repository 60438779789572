define("recruit/routes/panel/candidate/evaluate/form", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.get('store').findRecord('panelCandidate', params.id);
    }
  });

  _exports.default = _default;
});