define("recruit/templates/components/panel/events/accept-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6VZROEAe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"eight wide column\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui centered grid\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"/images/check-circle.svg\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"twelve wide middle aligned column text-black text-size-fourteen\"],[7],[0,\"\\n      You have successfully acknowleged to join this event in the following round as a panel member.\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-black text-weight-medium text-size-fourteen text-capitalize\"],[7],[0,\"\\n      \"],[1,[20,[\"panel\",\"assessmentSection\",\"assessment\",\"title\"]],false],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"text-very-lightgrey text-size-fourteen\"],[7],[1,[20,[\"panel\",\"assessmentSection\",\"assessment\",\"external_id\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-black text-weight-medium text-capitalize text-size-fourteen\"],[7],[0,\"\\n      \"],[1,[20,[\"panel\",\"assessmentSection\",\"section\",\"title\"]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-black text-size-fourteen\"],[9,\"style\",\"margin-top: 25px;\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"text-black\"],[9,\"style\",\"margin-right: 3px;\"],[7],[0,\"Changed your mind?\"],[8],[6,\"span\"],[9,\"class\",\"text-blue cursor-pointer\"],[3,\"action\",[[19,0,[]],\"askDecline\"]],[7],[0,\"Ask for decline.\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/events/accept-form.hbs"
    }
  });

  _exports.default = _default;
});