define("recruit/components/events/helpers/member-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('user.status') == 2) {
        this.set('isPanelLoading', true);
        this.get('user.panel').then(function (panel) {
          _this.set('panel', panel);

          if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
            panel.get('assessmentSection').then(function (as) {
              as.get('section').then(function () {
                if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                  _this.set('isPanelLoading', false);

                  _this.tooltipInitializer();
                }
              });
            });
          } else {
            if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
              _this.set('isPanelLoading', false);

              _this.tooltipInitializer();
            }
          }
        });
      }
    },
    tooltipInitializer: function tooltipInitializer() {
      Ember.run.next('afterRender', function () {
        Ember.$('.assessment-details[data-html]').popup({
          position: 'bottom center'
        });
        Ember.$('.panel-tooltip[data-content]').popup({
          position: 'right center'
        });
      });
    }
  });

  _exports.default = _default;
});