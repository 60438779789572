define("recruit/components/assessment/section-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['two wide column thumbnail-width'],
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.later('afterRender', function () {
        Ember.$('.section-thumbnails-title').popup({
          position: "top center"
        });
        Ember.$('.thumbnail-tooltip').popup({
          position: "bottom center"
        });

        if (_this.get('disabled')) {
          Ember.$('.show-on-hover').popup({
            position: 'top center',
            html: _this.get('sectionFreezeTooltip')
          });
        }
      });
    },
    actions: {
      setCurrentSlide: function setCurrentSlide(index) {
        if (this.get('assessmentModel.isExtraSectionAvailable') && !this.get('assessmentSection.isExtraAs')) {
          return this.sendAction('getNewlyAddedSection');
        }

        if (this.get('selectedAS.hasDirtyAttributes')) {
          return this.toggleProperty('showAlert');
        }

        this.set('currentSlide', index);
      },
      toggleModal: function toggleModal(property, sectionModel) {
        if (sectionModel.get('id')) {
          if (this.get('assessmentModel.isExtraSectionAvailable')) {
            if (this.get('assessmentSection.isExtraAs')) {
              return this.sendAction('toggleModal', property, sectionModel);
            }

            return this.get('toast').error("You need to add/delete '".concat(this.get('assessmentSections.lastObject.section.title'), "' section before deleting any other section."), 'Error!', {
              positionClass: 'assessment-toast-error'
            });
          }

          return this.sendAction('toggleModal', property, sectionModel);
        } else {
          this.get('toast').error('Section not found', 'Error!', {
            positionClass: 'assessment-toast-error'
          });
        }
      }
    }
  });

  _exports.default = _default;
});