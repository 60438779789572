define("recruit/adapters/code-language", ["exports", "recruit/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'code-language';
    },
    namespace: 'models'
  });

  _exports.default = _default;
});