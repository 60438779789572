define("recruit/components/helpers/checkmark-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    statusIcon: Ember.computed('status', function () {
      return this.get('status') ? 'checkmark text-green' : 'remove text-red';
    })
  });

  _exports.default = _default;
});