define("recruit/components/reports/helpers/user-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').findRecord('user', +this.get('row.id')).then(function (res) {
        _this.set('userObject', res);
      });
    }
  });

  _exports.default = _default;
});