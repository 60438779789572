define("recruit/components/assessment/rating-templates/parameters", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    extra: {
      message: "Can't find the parameter you are looking for?",
      eventMessage: "Create Parameter"
    },
    dropdownColumnWidth: Ember.computed('columnWidth', function () {
      return this.get('columnWidth') ? this.get('columnWidth') : 'nine';
    }),
    cardColumnWidth: Ember.computed('columnWidth', function () {
      return this.get('columnWidth') ? this.get('columnWidth') : 'six';
    }),
    sectionParameterObj: Ember.computed('selectedAS.section.sectionParameters.length', function () {
      if (this.get('sectionParameters.length')) {
        return this.get('sectionParameters').sortBy('sort_order');
      }
    }),
    defaultParameters: Ember.computed('sectionParameterObj.length', function () {
      if (this.get('sectionParameterObj.length')) {
        return this.get('sectionParameterObj').map(function (a) {
          return a.get('parameter');
        });
      } else {
        return null;
      }
    }),
    showErrorMessage: function showErrorMessage(err) {
      var _this = this;

      if (err.errors) {
        err.errors.forEach(function (error) {
          return _this.get('toast').error(error.detail, 'Error!', {
            positionClass: 'assessment-toast-error'
          });
        });
      } else {
        return this.get('toast').error("Something went wrong.", 'Try again.', {
          positionClass: 'assessment-toast-error'
        });
      }
    },
    actions: {
      reorderItems: function reorderItems(sectionParameters) {
        var _this2 = this;

        sectionParameters.forEach(function (parameter, index) {
          parameter.set('sort_order', index + 1);
        });
        this.set('sectionParameterObj', sectionParameters);
        this.get('sectionParameterObj').forEach(function (sectionParameter) {
          if (sectionParameter.get('hasDirtyAttributes')) {
            _this2.set('isReordering', true);

            return sectionParameter.save().catch(function (error) {
              if (error.errors) {
                error.errors.forEach(function (e) {
                  _this2.get('toast').error(e.detail, 'Error', {
                    positionClass: 'assessment-toast-error'
                  });
                });
              } else {
                _this2.get('toast').error('Please try after some time', 'Something went wrong', {
                  positionClass: 'assessment-toast-error'
                });
              }
            }).finally(function () {
              _this2.set('sectionParameterObj', sectionParameters);

              _this2.set('isReordering', false);
            });
          }
        });
      },
      getParameter: function getParameter(parameter) {
        if (parameter) {
          if (!this.get('selectedAS.isExtraAs')) {
            this.get('store').createRecord('section-parameter', {
              parameter: parameter,
              sort_order: this.get('selectedAS.section.sectionParameters.length') + 1,
              section: this.get('section')
            });
          } else {
            this.get('store').createRecord('lf-adapter/section-parameter', {
              parameter: parameter,
              sort_order: this.get('selectedAS.section.sectionParameters.length') + 1,
              section: this.get('section')
            });
          }
        }
      },
      remove: function remove(sectionParameter) {
        var _this3 = this;

        sectionParameter.destroyRecord().then(function (res) {
          if (res.get('parameter.name')) {
            _this3.get('toast').success("Parameter \"".concat(_npmLodash.default.capitalize(res.get('parameter.name')), "\" removed"), 'Success', {
              positionClass: 'assessment-toast-error'
            });
          } else {
            _this3.get('toast').success('Parameter removed', 'Success', {
              positionClass: 'assessment-toast-error'
            });
          }
        }).catch(function (error) {
          _this3.showErrorMessage(error);
        });
      },
      createParameter: function createParameter(model) {
        var _this4 = this;

        model.save().then(function (res) {
          _this4.get('toast').success('Parameter create successfully');

          _this4.send('getParameter', res);

          _this4.send('toggleModal', 'openModal');
        }).catch(function (error) {
          _this4.showErrorMessage(error);
        }).finally(function () {
          _this4.set('isLoading', false);
        });
      },
      updateParams: function updateParams(section) {
        var _this5 = this;

        if (this.get('disabled')) {
          if (!this.get('toggleNag')) {
            this.toggleProperty('toggleNag');
          }

          return;
        }

        this.get('section.sectionParameters').then(function (sectionParameters) {
          if (!sectionParameters.length) {
            return _this5.get('toast').error('Select parameters to add', 'Error!', {
              positionClass: 'assessment-toast-error'
            });
          }

          sectionParameters.forEach(function (sectionParameter) {
            if (sectionParameter.get('isNew')) {
              _this5.set('isLoading', true);

              sectionParameter.save().then(function (res) {
                if (res.get('parameter.name')) {
                  _this5.get('toast').success("Parameter \"".concat(_npmLodash.default.capitalize(res.get('parameter.name')), "\" added"), 'Success', {
                    positionClass: 'assessment-toast-error'
                  });
                } else {
                  _this5.get('toast').success('Parameter added', 'Success', {
                    positionClass: 'assessment-toast-error'
                  });
                }

                if (section.get('evaluationForm.id')) {
                  section.set('evaluationForm', null);
                  section.save().catch(function () {
                    _this5.get('toast').error('Evaluation form not be deleted', 'Something went wrong', {
                      positionClass: 'assessment-toast-error'
                    });
                  });
                }
              }).catch(function (error) {
                _this5.showErrorMessage(error);
              }).finally(function () {
                _this5.set('isLoading', false);
              });
            }
          });
        });
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});