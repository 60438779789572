define("recruit/templates/components/recruiter-view/assessment/helpers/average-rating-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VzuNRoJ2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"value\",\"length\"]],[20,[\"value\",\"firstObject\",\"evaluationRatings\",\"length\"]]],null]],null,{\"statements\":[[4,\"if\",[[20,[\"value\",\"firstObject\",\"averageRating\"]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"averageRating\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui centered grid\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"thirteen wide column loader-header\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"thirteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"thirteen wide column loader-header\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"thirteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"thirteen wide column loader-header\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  --\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/helpers/average-rating-view.hbs"
    }
  });

  _exports.default = _default;
});