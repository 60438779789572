define("recruit/components/custom-form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      backToAc: function backToAc() {
        history.back();
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('isSectionChangeStatus');
      }
    }
  });

  _exports.default = _default;
});