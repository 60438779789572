define("recruit/components/action-wrapper/live-modal/remind", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    isNotify: true,
    isLoading: false,
    actions: {
      sendReminder: function sendReminder() {
        var _this = this;

        this.set('isLoading', true);
        var liveSessionIds = this.get('selectedListValues').map(function (ls) {
          return {
            id: ls.get('id')
          };
        });
        this.get('store').createRecord('actions/live-remind', {
          liveSessionID: liveSessionIds,
          isNotify: this.get('isNotify')
        }).save().then(function () {
          _this.get('toast').success('Reminder sent', 'Successful');

          _this.send('back');
        }).catch(function (error) {
          _this.catchError(error);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});