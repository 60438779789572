define("recruit/templates/components/events/event-candidate-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bDyp65Qp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"action-button-position-events action-btns-position-events\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui icon vertical buttons\"],[9,\"style\",\"width: 187px; margin-top: -5px;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segments padding-no\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[9,\"style\",\"height: 43px;\"],[7],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"text-center text-uppercase text-black\"],[7],[0,\"Actions\"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui segment text-left padding-md text-blue padding-vs-top overview-action\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui basic button text-left assessment-action padding-vs-bottom padding-vs-top margin-top-five padding-no\"],[9,\"style\",\"box-shadow: none !important; color: #4A90E2!important; border-bottom: none !important;\"],[3,\"action\",[[19,0,[]],\"toggleModal\",\"remindToRegister\"]],[7],[0,\"\\n          \"],[1,[25,\"fa-icon\",[\"envelope\"],[[\"class\"],[\"text-blue\"]]],false],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"margin-left-five\"],[7],[0,\"Remind to register\"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui basic button text-left assessment-action padding-vs-bottom padding-vs-top margin-top-five padding-no\"],[9,\"style\",\"box-shadow: none !important; color: #4A90E2!important;border-bottom: none !important;\"],[3,\"action\",[[19,0,[]],\"toggleModal\",\"remindToAttend\"]],[7],[0,\"\\n          \"],[1,[25,\"fa-icon\",[\"envelope\"],[[\"class\"],[\"text-blue\"]]],false],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"margin-left-five\"],[7],[0,\"Remind to attend\"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/event-candidate-actions.hbs"
    }
  });

  _exports.default = _default;
});