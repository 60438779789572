define("recruit/templates/components/diff-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CgxG9OQo",
    "block": "{\"symbols\":[\"part\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"display\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"parts\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"class\",[19,1,[\"class\"]],null],[7],[0,\"\\n      \"],[1,[19,1,[\"value\"]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/diff-view.hbs"
    }
  });

  _exports.default = _default;
});