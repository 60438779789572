define("recruit/components/live-session/helpers/live-modal-viewer", ["exports", "recruit/components/helpers/resume-viewer"], function (_exports, _resumeViewer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resumeViewer.default.extend({
    setModalTitle: Ember.computed('displayTitle', function () {
      switch (this.get('displayTitle')) {
        case 'isAssessmentJD':
          return "Job Description";

        case 'isCandidateResume':
          return "Candidate Resume";

        case 'isTBIReport':
          return "TBI REPORT";
      }
    }),
    actions: {
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      }
    }
  });

  _exports.default = _default;
});