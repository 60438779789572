define("recruit/components/events/event-creation-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          position: 'top center'
        });
      });
    },
    actions: {
      saveModel: function saveModel(eventModel, field, property) {
        var _this = this;

        var changedAttr = eventModel.changedAttributes();
        var changedProperties = Object.keys(changedAttr);

        if (changedProperties.length) {
          eventModel.save().then(function () {
            _this.get('toast').success("".concat(field, " updated"));
          }).catch(function (err) {
            if (err.errors) {
              err.errors.forEach(function (error) {
                _this.get('toast').error(error.detail, 'Error!');
              });
            } else {
              _this.get('toast').error("Something went wrong.", 'Try again.');
            }
          }).finally(function () {
            _this.send('toggleProperty', property);
          });
        } else {
          this.send('toggleProperty', property);
        }
      },
      toggleProperty: function toggleProperty(property) {
        Ember.run.next(function () {
          Ember.$(".".concat(property)).focus();
        });
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});