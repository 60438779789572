define("recruit/components/action-wrapper/live-modal/ask-reschedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});