define("recruit/mixins/event-status", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    userRole: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    eventStatuses: Ember.A([Ember.Object.create({
      "id": 1,
      "name": 'Draft',
      "color": 'yellow',
      "allowedTransitions": [2, 3, 4],
      "description": 'The event is completely editable but no candidates can be added to it.'
    }), Ember.Object.create({
      "id": 2,
      "name": 'Open',
      "color": 'green',
      "allowedTransitions": [3, 4],
      "description": 'Event is open for the candidates to register. Limited modifications allowed to the event details.'
    }), Ember.Object.create({
      "id": 3,
      "name": 'Canceled',
      "color": 'red',
      "allowedTransitions": [],
      "description": 'The event is canceled and can\'t be revoked again.'
    }), Ember.Object.create({
      "id": 4,
      "name": 'Live',
      "color": 'green',
      "allowedTransitions": [5, 6],
      "description": 'The event is live on the specified event date.'
    }), Ember.Object.create({
      "id": 5,
      "name": 'Panel Review',
      "color": 'blue',
      "allowedTransitions": [6],
      "description": 'The event is complete but the panelists are yet to complete evaluating  the candidates.'
    }), Ember.Object.create({
      "id": 6,
      "name": 'Completed',
      "color": 'blue',
      "allowedTransitions": [],
      "description": 'The event has finished.'
    })]),
    selectedStatus: Ember.computed('status', function () {
      return this.getSelectedStatus();
    }),
    getSelectedStatus: function getSelectedStatus() {
      return _npmLodash.default.find(this.get('eventStatuses'), ['id', this.get('status')]);
    },
    checkDraftStatusValidity: function checkDraftStatusValidity(newStatus) {
      if (newStatus.get('id') == 2 || newStatus.get('id') == 4) {
        if (_npmLodash.default.isNil(this.get('start_date')) && _npmLodash.default.isNil(this.get('end_date'))) {
          return Ember.Object.create({
            "isValid": false,
            "title": 'There was a problem',
            "message": "You need to add an event date before you can change the event status to \"".concat(newStatus.get('name'), "\"")
          });
        }

        if (_npmLodash.default.isNil(this.get('registration_start_date')) && _npmLodash.default.isNil(this.get('registration_end_date'))) {
          return Ember.Object.create({
            "isValid": false,
            "title": 'There was a problem',
            "message": "You need to add registration window before you can change the event status to \"".concat(newStatus.get('name'), "\"")
          });
        }

        if (!this.get('eventAssessmentsCount.length')) {
          return Ember.Object.create({
            "isValid": false,
            "title": 'There was a problem',
            "message": "You need to add atleast one assessment before you can change the event status to \"".concat(newStatus.get('name'), "\"")
          });
        }

        return Ember.Object.create({
          "isValid": true,
          "newStatus": newStatus
        });
      }

      return Ember.Object.create({
        "isValid": true,
        "newStatus": newStatus
      });
    },
    getHeadsUpMessage: function getHeadsUpMessage(newStatus) {
      switch (this.get('status')) {
        case 1:
          return this.getDraftHeadsupMessages(newStatus);

        case 2:
          return this.getOpenHeadsupMessages(newStatus);

        case 3:
          return {
            error: "",
            message: ""
          };

        case 4:
          return this.getLiveHeadsupMessages(newStatus);

        case 5:
          return this.getPanelReviewHeadsupMessages(newStatus);

        case 6:
          return {
            error: "",
            message: ""
          };
      }
    },
    getDraftHeadsupMessages: function getDraftHeadsupMessages(newStatus) {
      switch (newStatus.get('id')) {
        case 2:
          return Ember.Object.create({
            error: "",
            message: "By changing the status to Open, you will not be allowed to make changes to the event details or go back to the draft state.Additionally, an email will be sent to the recruiters that the registration has started."
          });

        case 3:
          return Ember.Object.create({
            error: "",
            message: "By canceling the event, you will not be allowed to make any changes. Additionally, an email will be sent to the recruiters that the event has been canceled."
          });

        case 4:
          return Ember.Object.create({
            error: "",
            message: "By changing the status to Live, you will not be allowed to make changes to the event details or go back to the draft state. Additionally, an email will be sent to the recruiters that the event is live."
          });

        default:
          return Ember.Object.create({
            error: "",
            message: ""
          });
      }
    },
    getOpenHeadsupMessages: function getOpenHeadsupMessages(newStatus) {
      switch (newStatus.get('id')) {
        case 3:
          return Ember.Object.create({
            error: "",
            message: "By canceling the event, you will not be allowed to make any changes. Additionally, an email will be sent to the recruiters and the registered candidates that the event has been canceled."
          });

        case 4:
          return Ember.Object.create({
            error: "",
            message: "By changing the status to Live, you will not be allowed to make changes to the event details or go back to the draft state. Additionally, an email will be sent to the recruiters and the registered candiates that the event date has changed and the event is now live."
          });

        default:
          return Ember.Object.create({
            error: "",
            message: ""
          });
      }
    },
    getLiveHeadsupMessages: function getLiveHeadsupMessages(newStatus) {
      switch (newStatus.get('id')) {
        case 5:
          return Ember.Object.create({
            error: "",
            message: "By changing the status to Panel Review, candidates will not be able to register to the event."
          });

        case 6:
          return Ember.Object.create({
            error: "",
            message: "By changing the status of an event to Complete, you or any recruiter will not be allowed to make any changes. The candidates tagged to the assessments can however be processed outside the event."
          });

        default:
          return Ember.Object.create({
            error: "",
            message: ""
          });
      }
    },
    getPanelReviewHeadsupMessages: function getPanelReviewHeadsupMessages(newStatus) {
      switch (newStatus.get('id')) {
        case 6:
          return Ember.Object.create({
            error: "",
            message: "By changing the status of an event to Complete, you or any recruiter will not be allowed to make any changes. The candidates tagged to the assessments can however be processed outside the event."
          });

        default:
          return Ember.Object.create({
            error: "",
            message: ""
          });
      }
    }
  });

  _exports.default = _default;
});