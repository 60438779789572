define("recruit/models/menu-card", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    is_private: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    starts_at: _emberData.default.attr('pgdate'),
    code: _emberData.default.attr('string'),
    ends_at: _emberData.default.attr('pgdate'),
    organization_id: _emberData.default.attr('string'),
    created_by: _emberData.default.belongsTo('user'),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    assessment: _emberData.default.hasMany('assessment'),
    candidate: _emberData.default.hasMany('candidate')
  });

  _exports.default = _default;
});