define("recruit/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HR0uxHC",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Login\"],null],false],[0,\"\\n\"],[1,[25,\"login-page\",null,[[\"adLogin\",\"backTo\"],[\"adLogin\",[20,[\"backTo\"]]]]],false],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/login.hbs"
    }
  });

  _exports.default = _default;
});