define("recruit/templates/components/helpers/quill-text-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JLpOyszu",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"quill-editor\"],[10,\"class\",[26,[\"quill-editor-custom-\",[18,\"className\"]]]],[7],[0,\"\\n  \"],[1,[25,\"quill-editor\",null,[[\"value\",\"editor\",\"options\",\"selectionChange\",\"textChange\"],[[20,[\"value\"]],[20,[\"editor\"]],[20,[\"options\"]],[25,\"action\",[[19,0,[]],\"setIndex\"],null],[25,\"action\",[[19,0,[]],\"contentsChanged\"],null]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"fileUploding\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"progress-bar padding-no margin-no full-width margin-no\"],[7],[0,\"\\n\"],[4,\"ui-progress\",null,[[\"class\",\"total\",\"value\",\"progress\"],[\"margin-no\",100,[20,[\"fileUploadProgress\"]],[20,[\"fileUploadProgress\"]]]],{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"bar bg-teal\"],[9,\"style\",\"height: 0.6em;\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-center text-capitalize bg-white text-size-vsmall padding-vs-top\"],[7],[0,\"\\n        uploading file ....\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[9,\"class\",\"display-none\"],[7],[0,\"\\n  \"],[1,[25,\"file-uploader\",null,[[\"file\",\"is_public\",\"file_type_id\",\"modelName\",\"multiMediaUpload\",\"openSelector\",\"fileUploding\",\"uploadedFile\",\"fileUploadProgress\",\"supportedExtensions\"],[[20,[\"file\"]],1,14,\"file\",true,[20,[\"openSelector\"]],[20,[\"fileUploding\"]],[20,[\"uploadedFile\"]],[20,[\"fileUploadProgress\"]],[20,[\"supportedExtensions\"]]]]],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/quill-text-editor.hbs"
    }
  });

  _exports.default = _default;
});