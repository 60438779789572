define("recruit/templates/components/recruiter-view/bulk/error-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9tJCH5rt",
    "block": "{\"symbols\":[\"candidate\",\"error\"],\"statements\":[[6,\"table\"],[9,\"class\",\"ui very basic collapsing celled table full-width padding-md-left padding-md-right\"],[7],[0,\"\\n  \"],[6,\"thead\"],[9,\"style\",\"background-color: #ECECEC;\"],[7],[0,\"\\n    \"],[6,\"tr\"],[7],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"text-center padding-sm\"],[7],[0,\"ROW\"],[8],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"padding-sm\"],[7],[0,\"ERRORS\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"failedRecords\"]]],null,{\"statements\":[[0,\"      \"],[6,\"tr\"],[7],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n            \"],[1,[25,\"get-value\",[[19,1,[\"rowCount\"]]],null],false],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"text-red\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"error\"]]],null,{\"statements\":[[4,\"each\",[[19,1,[\"error\"]]],null,{\"statements\":[[4,\"if\",[[19,2,[\"field\"]]],null,{\"statements\":[[0,\"                \"],[1,[19,2,[\"field\"]],false],[0,\" : \"],[1,[19,2,[\"detail\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[19,2,[\"key\"]]],null,{\"statements\":[[0,\"                \"],[1,[19,2,[\"key\"]],false],[0,\" : \\\"\"],[6,\"i\"],[7],[1,[19,2,[\"value\"]],false],[8],[0,\"\\\" \"],[1,[19,2,[\"validation\",\"firstObject\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"            --\\n\"]],\"parameters\":[]}],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/bulk/error-table.hbs"
    }
  });

  _exports.default = _default;
});