define("recruit/templates/components/events/helpers/event-candidate-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yqzm450n",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui centered grid margin-no\"],[9,\"style\",\"padding-left: 20px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"two wide middle aligned column margin-no padding-no\"],[7],[0,\"\\n    \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"computedValue\",\"profilePic\"]],\"ui circular image profile-picture\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"fourteen wide middle aligned column margin-no\"],[9,\"style\",\"padding-left: 10px;\"],[7],[0,\"\\n    \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"computedValue\",\"fullName\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n      \"],[6,\"p\"],[10,\"class\",[26,[\"margin-no \",[25,\"if\",[[20,[\"customColor\"]],[20,[\"customColor\"]],\"text-black\"],null],\" text-left text-weight-normal ellipsis\"]]],[7],[1,[20,[\"computedValue\",\"fullName\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"computedValue\",\"email\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"ellipsis text-grey text-size-small text-weight-light text-left ellipsis\"],[7],[1,[20,[\"computedValue\",\"email\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/helpers/event-candidate-details.hbs"
    }
  });

  _exports.default = _default;
});