define("recruit/templates/components/helpers/power-select-event-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZUgtjygy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"text-black text-uppercase padding-md text-center\"],[9,\"style\",\"border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;\"],[7],[1,[20,[\"extra\",\"text\"]],false],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/power-select-event-component.hbs"
    }
  });

  _exports.default = _default;
});