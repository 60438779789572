define("recruit/components/recruiter-view/assessment/helpers/base", ["exports", "recruit/components/recruiter-view/assessment/helpers/panel-view"], function (_exports, _panelView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _panelView.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isRelationsLoaded', false);

      if (this.get('value.length')) {
        this.get('value').forEach(function (evaluation) {
          evaluation.get('evaluationRatings').then(function () {
            return evaluation.get('evaluationComments');
          }).then(function () {
            return evaluation.get('formInstance');
          }).then(function () {
            return _this.set('isRelationsLoaded', true);
          });
        });
      }
    },
    selectedEvaluation: Ember.computed('isRelationsLoaded', 'row', 'value.@each.evaluations.isFulfilled', 'pc', 'row.currentPanelId', function () {
      if (this.get('pc')) {
        return this.get('value').findBy('panel.id', this.get('pc.panel.id'));
      } else if (this.get('row.currentPanelId')) {
        return this.get('value').findBy('panel.id', this.get('row.currentPanelId'));
      }
    })
  });

  _exports.default = _default;
});