define("recruit/components/events/event-candidate-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      }
    }
  });

  _exports.default = _default;
});