define("recruit/components/events/event-panel-list", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userRole: Ember.inject.service(),
    matchedPanels: Ember.computed('eventModel.panel.[]', 'eventPanels.[]', function () {
      return _npmLodash.default.filter(this.get('eventPanels').map(function (eventPanel) {
        return eventPanel.get('panel.id');
      }), function (elem) {
        return !_npmLodash.default.isNil(elem);
      }).length;
    }),
    actions: {
      invite: function invite() {
        this.sendAction('invite');
      },
      remind: function remind() {
        this.sendAction('toggleModal', 'isRemindPanel');
      }
    }
  });

  _exports.default = _default;
});