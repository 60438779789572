define("recruit/routes/recruiter/reports/panel/conversion", ["exports", "recruit/routes/recruiter/reports/report-filters"], function (_exports, _reportFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportFilters.default.extend({
    store: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('nodeValues', Ember.A(['panelAssignedCount', 'panelEvaluatedCount', 'panelSelectCount']));
      controller.set('cirlceLabels', Ember.A(['Candidates Assigned', 'Total Evaluated', 'Selected']));
    },
    actions: {
      setFilters: function setFilters(assessment, recruiter, panel) {
        this.controller.set('userAppliedFilters', Ember.A([]));
        this.controller.get('userAppliedFilters').pushObject({
          user_id: panel,
          assessment_id: assessment
        });
        this.checkIfFiltersApplied(this.controller.get('userAppliedFilters.firstObject'));
      },
      postRemoveFilter: function postRemoveFilter() {
        this.checkIfFiltersApplied(this.controller.get('userAppliedFilters.firstObject'));
      }
    }
  });

  _exports.default = _default;
});