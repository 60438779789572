define("recruit/validations/time-validator", ["exports", "npm:moment-timezone"], function (_exports, _npmMomentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //validating selected time is in past or not
  var TimeValidation = function TimeValidation(selectedTime, timeZone) {
    // current time of the selected timezone
    var currentTime = _npmMomentTimezone.default.tz(timeZone); // converting selected time to selected timezone


    var convertedTime = _npmMomentTimezone.default.tz((0, _npmMomentTimezone.default)(selectedTime).format('YYYY-MM-DD HH:mm'), timeZone); //passing only the time value without the timezone
    // checking if the selected time is same or after the current time of the selected timezone


    return convertedTime.isSameOrAfter(currentTime);
  };

  var _default = TimeValidation;
  _exports.default = _default;
});