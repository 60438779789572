define("recruit/controllers/ask-activation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      sendActivationLink: function sendActivationLink() {
        this.set('linkSent', true);
      }
    }
  });

  _exports.default = _default;
});