define("recruit/templates/components/helpers/dynamic-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UsY0cAxS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"isMutAction\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"ui-checkbox\",null,[[\"label\",\"value\",\"checked\",\"onChange\"],[[20,[\"labelName\"]],[20,[\"value\"]],[20,[\"isChecked\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"isChecked\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",[26,[[25,\"unless\",[[20,[\"hideTooltip\"]],\"popup-checkbox\"],null]]]],[10,\"data-content\",[26,[[18,\"tooltipData\"]]]],[7],[0,\"\\n    \"],[1,[25,\"ui-checkbox\",null,[[\"label\",\"class\",\"value\",\"checked\",\"disabled\",\"onChange\"],[[20,[\"labelName\"]],\"ellipsis\",[20,[\"value\"]],[20,[\"isChecked\"]],[20,[\"disabled\"]],[25,\"action\",[[19,0,[]],\"setProp\"],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/dynamic-checkbox.hbs"
    }
  });

  _exports.default = _default;
});