define("recruit/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I14fwltb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-wrapper\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"src\",\"/images/cuton_search.png\"],[9,\"alt\",\"cuton\"],[9,\"class\",\"ui image cuton-updown\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"twelve wide middle aligned left aligned column\"],[7],[0,\"\\n    \"],[6,\"h5\"],[9,\"class\",\"ui center aligned grey text\"],[7],[0,\"\\n      This role is not yet provisioned for your account.\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"hasEvaluatorAccess\"]]],null,{\"statements\":[[0,\"      \"],[6,\"p\"],[7],[0,\" Try changing your role.\"],[8],[0,\"\\n      \"],[4,\"link-to\",[\"user.change-role\"],null,{\"statements\":[[6,\"button\"],[9,\"class\",\"ui blue button\"],[7],[1,[25,\"fa-icon\",[\"arrow-left\"],[[\"class\"],[\"text-grey\"]]],false],[0,\"Change role\"],[8]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Please contact the administrator.\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/dashboard.hbs"
    }
  });

  _exports.default = _default;
});