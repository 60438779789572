define("recruit/templates/components/recruiter-view/candidate/assessment-section-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "byAbSCUv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[3,\"action\",[[19,0,[]],\"setAssc\",[20,[\"assessmentSection\"]]]],[7],[0,\"\\n  \"],[6,\"a\"],[10,\"class\",[26,[\"ui circular basic label profile-steps \",[18,\"statusClass\"],\" \",[18,\"selectedClass\"]]]],[10,\"data-content\",[20,[\"assessmentSection\",\"section\",\"title\"]],null],[7],[0,\"\\n\"],[0,\"    \"],[1,[25,\"fa-icon\",[[20,[\"assessmentSection\",\"section\",\"sectionType\",\"icon\"]]],null],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"title text-center\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"style\",\"display:block;\"],[7],[1,[20,[\"assessmentSection\",\"section\",\"sectionType\",\"label\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"unless\",[[25,\"eq\",[[20,[\"index\"]],[25,\"array-length\",[[20,[\"assessmentSections\",\"length\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"hr\"],[10,\"class\",[26,[\"profile-steps-line \",[18,\"lineClass\"]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/candidate/assessment-section-icon.hbs"
    }
  });

  _exports.default = _default;
});