define("recruit/components/model-table/recruiter/pending-candidate", ["exports", "recruit/mixins/table-common", "recruit/config/environment", "recruit/mixins/infinite-loader", "recruit/mixins/g-actions"], function (_exports, _tableCommon, _environment, _infiniteLoader, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_infiniteLoader.default, _gActions.default, {
    modelName: 'candidate',
    openModal: false,
    isDevelopment: _environment.default.isDevelopment,
    pendingObserver: Ember.observer('quickFilters.isPending', function () {
      this.set('page', 1);
      this.get('tableRows').clear();
      this.get('fetchData').perform();
    }),
    columns: Ember.computed(function () {
      return [{
        "component": "helpers/table-checkboxs",
        "cellComponent": "helpers/row-checkbox",
        "width": "40px",
        "classNames": ["table-head padding-no table-head-checkbox-th"],
        "cellClassNames": ["child padding-no"]
      }, {
        "align": null,
        "cellComponent": "candidate-helpers/profile-view-link",
        "classNames": ["table-head"],
        "valuePath": "picture",
        "width": "80px"
      }, {
        "align": null,
        "cellComponent": "candidate-helpers/candidate-details",
        "classNames": ["table-head text-center"],
        "label": "Candidate",
        "width": "184px"
      }, {
        "cellClassNames": ["ellipsis text-center"],
        "cellComponent": null,
        "classNames": ["table-head"],
        "label": "Candidate #",
        "valuePath": "formattedExternalId",
        "width": "167px"
      }, {
        "align": null,
        "cellClassNames": "text-center",
        "cellComponent": null,
        "classNames": ["table-head text-center"],
        "label": "Phone",
        "valuePath": "formattedPhone",
        "width": "160px"
      }, {
        "align": null,
        "cellClassNames": "text-center",
        "cellComponent": "helpers/date-view",
        "classNames": ["table-head text-center"],
        "label": "Added On",
        "sortable": false,
        "sorted": false,
        "valuePath": "created_at",
        "width": "184px",
        "showTimeAgo": false,
        "dateFormat": "DD-MMM-YY | hh:mm A"
      }];
    }),
    actions: {
      toggleModal: function toggleModal(modalName) {
        this.toggleProperty('is' + modalName);
      },
      rowChecked: function rowChecked(eventType, candidate) {
        this.sendAction('rowChecked', eventType, candidate); // this.get('table.selectedRows').pushObject(row);
        // this.get('table.selectedRows').setEach('selected', true);
      },
      // rowUnChecked(row) {
      //   this.get('table.selectedRows').removeObject(row);
      // },
      routeTransistion: function routeTransistion(row) {
        this.get('router').transitionTo('recruiter.candidate.details.profile', row.content.id);
      }
    }
  });

  _exports.default = _default;
});