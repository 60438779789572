define("recruit/components/live-session/join-flash-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('model.status', 2);
      this.get('model').save();
      window.addEventListener("message", function () {
        if (event.data == 'closeIframe') {
          _this.set('exitSession', true);
        }
      }, false);
    }
  });

  _exports.default = _default;
});