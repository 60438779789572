define("recruit/components/assessment/question/skill-based-dropdown", ["exports", "ember-cli-uuid"], function (_exports, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    skillQuestionCount: '0',
    modelName: Ember.computed(function () {
      if (this.get('relationName')) {
        return this._getModelName(this.get('relationName'));
      }
    }),
    didRender: function didRender() {
      Ember.run.next(function () {
        Ember.$('.questionCountInfo').popup({
          position: 'bottom center'
        });
      });
    },
    resetValues: function resetValues() {
      this.set('selectedSkill', null);
      this.set('skillQuestionCount', 0);
    },
    _getModelName: function _getModelName(type) {
      if (type == 'sectionQuestions') {
        return 'sectionQuestion';
      }

      if (type == 'combined') {
        return 'sectionCombined';
      }

      if (type == 'sectionSkills') {
        return 'sectionSkill';
      }
    },
    actions: {
      setQuestionCount: function setQuestionCount(skillObject, value) {
        var _this = this;

        if (parseInt(this.get('sectionModel.sectionType.id'), 10) == 10 && parseInt(value, 10) > 1 || this.get('questions.length') >= 1 && parseInt(this.get('sectionModel.sectionType.id'), 10) == 10) {
          return this.toggleProperty('showTypingError');
        }

        if (!skillObject) {
          this.resetValues();
          return this.get('toast').error('Select a skill to add.', 'Skill not selected', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (skillObject.get('qCount') == 0) {
          Ember.$('#questionCount').focus();
          this.get('toast').error('The selected skill does not have any questions.', '', {
            positionClass: 'assessment-toast-error'
          });
        } else if (value == 0) {
          Ember.$('#questionCount').focus();
          this.get('toast').error('You need to select atleast 1 question.', '', {
            positionClass: 'assessment-toast-error'
          });
        } else if (value % 1 != 0) {
          Ember.$('#questionCount').focus();
          this.get('toast').error('Question count needs to be an integer value.', 'Decimal not allowed', {
            positionClass: 'assessment-toast-error'
          });
        } else if (value < 0) {
          Ember.$('#questionCount').focus();
          this.get('toast').error('Question count needs to be an absolute value.', 'Negative values not allowed', {
            positionClass: 'assessment-toast-error'
          });
        } else if (value > skillObject.get('qCount')) {
          Ember.$('#questionCount').focus();
          this.get('toast').error("Select question count less than or equal to ".concat(skillObject.get('qCount')), '', {
            positionClass: 'assessment-toast-error'
          });
        } else {
          if (this.get('modelName')) {
            this.send('addSkill', this.get('modelName'), skillObject, value);
          } else {
            this.get('store').createRecord('lf-adapter/assessment-question', {
              id: (0, _emberCliUuid.uuid)(),
              skill_id: skillObject.get('id'),
              qCount: parseInt(value, 10),
              content: skillObject.get('name'),
              plainText: skillObject.get('name'),
              title: skillObject.get('description'),
              questionType: 2
            }).save().then(function (res) {
              _this.get('sectionModel.assessmentQuestions').pushObject(res);

              _this.get('sectionModel').save().then(function () {
                _this.resetValues();
              });
            }).catch(function () {
              _this.resetValues();

              _this.get('toast').error("Could not add skill set", '', {
                positionClass: 'assessment-toast-error'
              });
            });
          }
        }
      },
      addSkill: function addSkill(model, skill, count) {
        var _this2 = this;

        var skillRecord = this.get('store').createRecord(model, {
          skill: skill,
          q_count: count,
          q_order: this.get('questions.length') + 1,
          sort_order: this.get('questions.length') + 1,
          section: this.get('sectionModel')
        });
        skillRecord.save().then(function () {
          _this2.set('questions.skill', skill);

          _this2.get('toast').success("Question added", "", {
            positionClass: 'assessment-toast-error'
          });
        }).catch(function (err) {
          if (err.errors) {
            skillRecord.deleteRecord();
            err.errors.forEach(function (e) {
              _this2.get('toast').error(e.detail, 'Error', {
                positionClass: 'assessment-toast-error'
              });
            });
          } else {
            _this2.get('toast').error('Please try after some time', 'Something went wrong', {
              positionClass: 'assessment-toast-error'
            });
          }
        }).finally(function () {
          _this2.resetValues();
        });
      }
    }
  });

  _exports.default = _default;
});