define("recruit/components/recruiter-view/assessment/details/assessment-notification", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userRole: Ember.inject.service(),
    isDevelopment: _environment.default.isDevelopment,
    sendAt: Ember.computed('selectedNotification', function () {
      return this.get('selectedNotification.sent_at').format('D MMM YYYY [|] LT');
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.run.next(function () {
        Ember.$('.custom').popup({
          popup: Ember.$('.ui.form.popup'),
          on: 'click',
          position: 'bottom left'
        });
      });
    },
    actions: {
      setNotification: function setNotification(notification) {
        this.set('selectedNotification', notification);
      },
      editTemplate: function editTemplate(model) {
        this.get('router').transitionTo('recruiter.assessment.details.edit-template', model.id);
      },
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
      }
    }
  });

  _exports.default = _default;
});