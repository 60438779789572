define("recruit/mixins/ac-action", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { uuid } from 'ember-cli-uuid';
  var _default = Ember.Mixin.create({
    comment: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    total_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    error_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    success_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    requested_by: _emberData.default.belongsTo('user'),
    requested_at: _emberData.default.attr('pgdate'),
    completed_at: _emberData.default.attr('pgdate'),
    started_at: _emberData.default.attr('pgdate'),
    isNotify: _emberData.default.attr('boolean'),
    message: _emberData.default.attr('string'),
    response: _emberData.default.attr(),
    endDate: _emberData.default.attr('pgdate'),
    startDate: _emberData.default.attr('pgdate')
  });

  _exports.default = _default;
});