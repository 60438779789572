define("recruit/components/reports/graphs/time-range/conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedFilters: Ember.A([]),
    showDate: Ember.computed('openCalendar', function () {
      return !this.get('openCalendar');
    }),
    graphFilterObserver: Ember.observer('selectedGraphFilter', function () {
      this.set('model', Ember.A([]));
    }),
    updateGraphObserver: Ember.observer('model.length', function () {
      this.initialize();

      if (this.get('model.length')) {
        this.set('showEmptyState', false);
        this.getValues(this.get('model'));
        this.getLabels(this.get('model'));
      } else {
        this.set('showEmptyState', true);
        this.set('renderGraph', true);
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').query('user', {
        roles: 'Recruiter'
      }).then(function (res) {
        _this.set('defaultRecruiters', res.slice(0, 6));
      });
      this.get('store').query('user', {
        roles: 'Evaluator'
      }).then(function (res) {
        _this.set('defaultPanels', res.slice(0, 6));
      });
      this.get('store').findAll('assessment').then(function (res) {
        _this.set('defaultAssessments', res.slice(0, 6));
      });
      this.initialize();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('dateRecordSet')) {
        this.set('initialStartDate', this.get('startDate'));
        this.set('initialEndDate', this.get('endDate'));
      }
    },
    didRender: function didRender() {
      Ember.run.next(function () {
        Ember.$('.ui.cursor-help').popup({
          position: 'bottom center'
        });
      });
    },
    getLabels: function getLabels(model) {
      var axisLabels = [];

      if (model) {
        if (this.get('selectedGraphFilter') === 'days') {
          model.forEach(function (itr, index) {
            axisLabels.pushObject("Day " + (index + 1));
          });
        }

        if (this.get('selectedGraphFilter') === 'week') {
          model.forEach(function (itr, index) {
            axisLabels.pushObject("Week " + (index + 1));
          });
        }

        if (this.get('selectedGraphFilter') === 'month') {
          model.forEach(function (itr, index) {
            axisLabels.pushObject("Month " + (index + 1));
          });
        }

        if (this.get('selectedGraphFilter') === 'year') {
          model.forEach(function (itr, index) {
            axisLabels.pushObject("Year " + (index + 1));
          });
        }

        this.set('xAxisCategories', axisLabels);
      }
    },
    initialize: function initialize() {
      this.setProperties({
        'invitedCounts': Ember.A([]),
        'inProgressCounts': Ember.A([]),
        'completedCounts': Ember.A([]),
        'selectedCounts': Ember.A([]),
        'holdCounts': Ember.A([]),
        'rejectedCounts': Ember.A([]),
        'renderGraph': false
      });
    },
    getValues: function getValues(values) {
      var _this2 = this;

      values.forEach(function (value) {
        _this2.get('invitedCounts').pushObject(value.get('invitedCount'));

        _this2.get('inProgressCounts').pushObject(value.get('inprogressCount'));

        _this2.get('completedCounts').pushObject(value.get('completedCount'));

        _this2.get('selectedCounts').pushObject(value.get('selectedCount'));

        _this2.get('holdCounts').pushObject(value.get('holdCount'));

        _this2.get('rejectedCounts').pushObject(value.get('rejectedCount'));
      });
      var seriesArray = Ember.A([{
        name: 'Total Invitations',
        color: '#5940B9',
        data: this.get('invitedCounts')
      }, {
        name: 'candidates Attempted',
        color: '#ED9D18',
        data: this.get('inProgressCounts')
      }, {
        name: 'Candidates Completed',
        color: '#027EFF',
        data: this.get('completedCounts')
      }, {
        name: 'Selected',
        color: '#78C552',
        data: this.get('selectedCounts')
      }, {
        name: 'On-Hold',
        color: '#F0CC1E',
        data: this.get('holdCounts')
      }, {
        name: 'Rejected',
        color: '#D7302D',
        data: this.get('rejectedCounts')
      }]);
      this.set('dataArray', seriesArray);

      if (this.get('model.length')) {
        this.set('renderGraph', true);
      }
    },
    actions: {
      togglePopup: function togglePopup(property) {
        if (property == 'openCalendar') {
          if (this.get('openFilter')) {
            this.set('openFilter', false);
          }
        }

        if (property == 'openFilter') {
          if (this.get('openCalendar')) {
            this.set('openCalendar', false);
          }
        }

        this.toggleProperty(property);
      },
      setDateFilter: function setDateFilter(val) {
        this.sendAction('setDateFilter', val);
      },
      resetFilters: function resetFilters() {
        this.initialize();
      },
      setFilters: function setFilters(assessment, recruiter, panel, advanceFilters) {
        this.send('togglePopup', 'openFilter');
        this.sendAction('setAllFilters', assessment, recruiter, panel, advanceFilters);
      }
    }
  });

  _exports.default = _default;
});