define("recruit/routes/recruiter/candidate/create/advance", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    actions: {
      saveFile: function saveFile(file, candidate) {
        var _this = this;

        this.get('store').createRecord('candidate-file', {
          file_id: file.id,
          candidate_id: candidate.get('id')
        }).save().then(function () {
          return _this.get('toast').success('Resume Uploaded');
        }).catch(function () {
          return _this.get('toast').error('Failed');
        });
      }
    }
  });

  _exports.default = _default;
});