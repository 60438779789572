define("recruit/routes/user/settings/password", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/validations/update-password"], function (_exports, _authenticatedRouteMixin, _updatePassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    PasswordValidations: _updatePassword.default,
    model: function model() {
      return this.store.createRecord('updatePassword');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('model', model);
      controller.set('PasswordValidations', _updatePassword.default);
    }
  });

  _exports.default = _default;
});