define("recruit/components/assessment/tabs/assessment-configuration", ["exports", "recruit/mixins/assessment-section-settings"], function (_exports, _assessmentSectionSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_assessmentSectionSettings.default, {
    disabled: Ember.computed('assessmentModel.freezeAssessment', function () {
      return this.get('assessmentModel.freezeAssessment');
    }),
    isConfigurable: Ember.computed('selectedAS.section.isFulfilled', function () {
      return this.get('selectedAS.section.sectionType.id') != 7 && this.get('selectedAS.section.sectionType.id') != 15;
    }),
    _reviewObserver: Ember.observer('isReviewEnabled', function () {
      if (!this.get('isReviewEnabled')) {
        this.set('selectedAS.is_playlist', false);
        this.set('selectedAS.isReview', false);
      } else {
        if (!this.get('selectedAS.isPlaylist') && !this.get('selectedAS.is_review')) {
          return this.set('playlistOption', 'isPlaylist');
        } else {
          this.set('selectedAS.is_playlist', this.get('selectedAS.isPlaylist'));
          this.set('selectedAS.isReview', this.get('selectedAS.is_review'));
        }

        this.set('playlistOption', this.get('selectedAS.is_review') ? 'isReview' : 'isPlaylist');
      }
    }),
    _playlistObserver: Ember.observer('playlistOption', function () {
      if (!this.get('isReviewEnabled')) {
        return;
      }

      if (this.get('playlistOption') == 'isPlaylist') {
        this.set('selectedAS.isReview', false);
        return this.set('selectedAS.is_playlist', true);
      }

      if (this.get('playlistOption') == 'isReview') {
        this.set('selectedAS.is_playlist', false);
        return this.set('selectedAS.isReview', true);
      }
    }),
    showProctor: Ember.computed('selectedAS.section', function () {
      return this.get('selectedAS.section.sectionType.id') != 5 && this.get('selectedAS.section.sectionType.id') != 7;
    }),
    showReview: Ember.computed('selectedAS.section', function () {
      return this.get('selectedAS.section.sectionType.id') == 6 || this.get('selectedAS.section.sectionType.id') == 4 || this.get('selectedAS.section.sectionType.id') == 17;
    }),
    isAutomated: Ember.computed('selectedAS.section', function () {
      return this.get('selectedAS.section.sectionType.id') == 5;
    }),
    isCutoffChecked: Ember.computed('isCutoffChecked', function () {
      return this.get('selectedAS.cutOff') || this.get('selectedAS.cutOff') == 0;
    }),
    _cutOffObserver: Ember.observer('isCutoffChecked', function () {
      if (!this.get('isCutoffChecked')) {
        this.set('selectedAS.cutOff', null);
      } else {
        if (this.get('selectedAS.cut_off') == null) {
          this.set('selectedAS.cutOff', 0);
        } else {
          this.set('selectedAS.cutOff', this.get('selectedAS.cut_off'));
        }
      }
    }),
    showCutOff: Ember.computed('selectedAS.section', function () {
      return this.get('selectedAS.section.sectionType.id') == 6 || this.get('selectedAS.section.sectionType.id') == 19;
    }),
    showPositiveMarks: Ember.computed('selectedAS.section', function () {
      return this.get('selectedAS.section.sectionType.id') == 6;
    }),
    isExternalAssessment: Ember.computed('selectedAS.section', function () {
      return this.get('selectedAS.section.sectionType.id') == 21 || this.get('selectedAS.section.sectionType.id') == 19;
    }),
    didInsertElement: function didInsertElement() {
      this.set('isReviewEnabled', this.get('selectedAS.isReview') || this.get('selectedAS.is_playlist'));
      Ember.run.next('afterRender', function () {
        Ember.$('.ui.slider.checkbox').checkbox();
      });

      if (this.get('selectedAS.isExtraAs')) {
        var obj = {};
        this.setSectionSettings(this.get('selectedAS.section.sectionType.id'), this.get('selectedAS.section.assessmentQuestions.length'), obj);

        for (var prop in obj) {
          Ember.set(this.get('selectedAS'), "".concat(prop), obj[prop]);
        }
      }

      if (this.get('assessmentModel.freezeAssessment')) {
        this.toggleProperty('toggleNag');
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.run.next('afterRender', function () {
        Ember.$('.config-tooltip').popup({
          position: "bottom center"
        });
      });
    },
    checkIFQuestionAvailable: function checkIFQuestionAvailable(sectionTypeID) {
      if (sectionTypeID) {
        return sectionTypeID != 7 && sectionTypeID != 15 && sectionTypeID != 19 && sectionTypeID != 22;
      }
    },
    actions: {
      checkFreezeAssessment: function checkFreezeAssessment() {
        var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('assessmentModel');

        if (model.get('freezeAssessment')) {
          if (!this.get('toggleNag')) {
            this.toggleProperty('toggleNag');
          }
        }
      },
      updateAs: function updateAs(selectedAS) {
        if (+this.get('selectedAS.max_question_time') < 0) {
          Ember.$('.maxQuesTime').focus();
          return this.get('toast').error('Max Question Time can not be negative', 'Negative Values', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (+this.get('selectedAS.max_question_time') > 5) {
          Ember.$('.maxQuesTime').focus();
          return this.get('toast').error('Max Question Time can not be more than 5', '', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (+this.get('selectedAS.max_section_time') < 0) {
          Ember.$('.maxSecTime').focus();
          return this.get('toast').error('Max Section Time can not be negative', 'Negative Values', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (+this.get('selectedAS.questionDisplayTime') < 0) {
          Ember.$('.preparationTime').focus();
          return this.get('toast').error('Preparation Time can not be negative', 'Negative Values', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (+this.get('selectedAS.cutOff') < 0) {
          Ember.$('.cutoff').focus();
          return this.get('toast').error('Cutoff can not be negative', 'Negative Values', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (+this.get('selectedAS.positive_mark') < 0) {
          Ember.$('.positiveMarks').focus();
          return this.get('toast').error('Positive Marks can not be negative', 'Negative Values', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (+this.get('selectedAS.negative_mark') < 0) {
          Ember.$('.negativeMarks').focus();
          return this.get('toast').error('Negative Marks can not be negative', 'Negative Values', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (parseInt(this.get('selectedAS.cutOff')) != parseInt(this.get('selectedAS.cut_off'))) {
          this.set('selectedAS.cut_off', this.get('selectedAS.cutOff'));
        }

        if (this.get('selectedAS.isPlaylist') != this.get('selectedAS.is_playlist')) {
          this.set('selectedAS.isPlaylist', this.get('selectedAS.is_playlist'));
        }

        if (this.get('selectedAS.is_review') != this.get('selectedAS.isReview')) {
          this.set('selectedAS.is_review', this.get('selectedAS.isReview'));
        }

        this.sendAction('updateAs', selectedAS);
      }
    }
  });

  _exports.default = _default;
});