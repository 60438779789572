define("recruit/data/filters/r-ls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 5883,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate First Name",
      "objectType": "r-ls",
      "queryTemplate": "({{condition}} candidate.first_name{{exact}}{{value}})"
    }
  }, {
    "id": 5884,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate Last Name",
      "objectType": "r-ls",
      "queryTemplate": "({{condition}} candidate.last_name{{exact}}{{value}})"
    }
  }, {
    "id": 5885,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate Email",
      "objectType": "r-ls",
      "queryTemplate": "({{condition}} candidate.email{{exact}}{{value}})"
    }
  }, {
    "id": 5886,
    "type": "filter/master",
    "attributes": {
      "list": [{
        "key": "Scheduled",
        "value": 1
      }, {
        "key": "In Progress",
        "value": 2
      }, {
        "key": "Completed",
        "value": 3
      }, {
        "key": "Cancelled",
        "value": 10
      } // {
      //   "key": "Panel No Show",
      //   "value": 11
      // },
      // {
      //   "key": "Candidate No Show",
      //   "value": 12
      // },
      // {
      //   "key": "Both No Show",
      //   "value": 13
      // }
      ],
      "type": "list",
      "field": "Live Status",
      "isRemote": false,
      "objectType": "r-ls",
      "queryTemplate": "(status: {{value}})"
    }
  }, {
    "id": 5887,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Panel",
      "model": "user",
      "isRemote": true,
      "modelQuery": {
        "roles": "Evaluator"
      },
      "objectType": "r-ls",
      "queryTemplate": "(liveAttendees.user_id:{{value}})"
    }
  }, {
    "id": 5875,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Starts At",
      "objectType": "r-ls",
      "queryTemplate": "(starts_at:[{{start}} TO {{end}} ])"
    }
  }, {
    "id": 5876,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Timezone",
      "model": "settingsAllowed",
      "isRemote": true,
      "modelQuery": {
        "settings_id": 1
      },
      "objectType": "r-ls",
      "queryTemplate": "(timezone:{{value}})"
    }
  }, {
    "id": 5877,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Scheduled by",
      "model": "user",
      "isRemote": true,
      "objectType": "r-ls",
      "queryTemplate": "(hosted_by: {{value}})"
    }
  }, {
    "id": 5878,
    "type": "filter/master",
    "attributes": {
      "list": [{
        "key": "15 minutes",
        "value": "900"
      }, {
        "key": "30 minutes",
        "value": "1800"
      }, {
        "key": "45 minutes",
        "value": "2700"
      }, {
        "key": "1 hour",
        "value": "3600"
      }, {
        "key": "1 hour 30 minutes",
        "value": "4800"
      }, {
        "key": "2 hours",
        "value": "7200"
      }],
      "type": "list",
      "field": "Duration",
      "isRemote": false,
      "objectType": "r-ls",
      "queryTemplate": "(duration: {{value}})"
    }
  }, {
    "id": 5879,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Assessment Name",
      "model": "assessment",
      "isRemote": true,
      "objectType": "r-ls",
      "queryTemplate": "(assessment.id: {{value}})"
    }
  }, {
    "id": 5880,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Assessment Owner",
      "model": "user",
      "isRemote": true,
      "modelQuery": {
        "roles": ["Recruiter", "Master Recruiter"]
      },
      "objectType": "r-ls",
      "queryTemplate": "(assessment.owned_by: {{value}})"
    }
  }, {
    "id": 5881,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Assessment External ID",
      "objectType": "r-ls",
      "queryTemplate": "(assessment.external_id{{exact}}{{value}})"
    }
  }, {
    "id": 5882,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate #(id)",
      "objectType": "r-ls",
      "queryTemplate": "({{condition}} candidate.external_id{{exact}}{{value}})"
    }
  }];
  _exports.default = _default;
});