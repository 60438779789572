define("recruit/components/candidate-auth/log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    didInsertElement: function didInsertElement() {
      Ember.$('[data-html]').popup({
        lastResort: true
      });
    }
  });

  _exports.default = _default;
});