define("recruit/models/modal-actions/recruiter/actions", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    actionType: _emberData.default.attr('string'),
    relationType: _emberData.default.attr('string'),
    candidates: _emberData.default.hasMany('candidate'),
    assessmentCandidates: _emberData.default.hasMany('assessment-candidate'),
    liveSession: _emberData.default.hasMany('live-session'),
    internalView: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    panels: _emberData.default.hasMany('panel'),
    assessmentSections: _emberData.default.hasMany('assessment-section'),
    assessments: _emberData.default.hasMany('assessment'),
    eventAssessmentsCount: _emberData.default.hasMany('event-assessment'),
    questions: _emberData.default.hasMany('question'),
    invitedCandidatesCount: _emberData.default.attr('number'),
    isAssessmentFrozen: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    reinvite: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    events: _emberData.default.hasMany('event'),
    eventPanels: _emberData.default.hasMany('event-user'),
    recruiters: _emberData.default.hasMany('event-user'),
    isEventActions: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    redirectTo: _emberData.default.attr('string', {
      defaultValue: null
    }),
    //Flag for redirect to custom route
    assessmentSectionCandidates: _emberData.default.hasMany('assessment-section-candidate'),
    actionComponent: Ember.computed(function () {
      var actionType = this.get('actionType').toLowerCase();

      switch (actionType) {
        case 'invite to assessment':
          return 'recruiter-view/actions/assessment-invite';

        case 'reinvite to section':
          return 'recruiter-view/actions/assessment-invite';

        case 'invite to section':
          return 'recruiter-view/actions/assessment-invite';

        case 'select an invite status':
          return 'recruiter-view/actions/change-status';

        case 'assign panel':
          return 'recruiter-view/actions/assign-panel';

        case 'extend last date':
          return 'recruiter-view/actions/extend-date';

        case 'send candidate reminder':
          return 'recruiter-view/actions/remind-panel';

        case 'send panel reminder':
          return 'recruiter-view/actions/remind-panel';

        case 'cancel session':
          return 'action-wrapper/live-modal/cancel';

        case 'send reminder':
          return 'action-wrapper/live-modal/remind';

        case 'reschedule session':
          return 'action-wrapper/live-modal/reschedule';

        case 'manage attendees':
          return 'action-wrapper/live-modal/manage-panel';

        case 'suggest attendees':
          return 'action-wrapper/live-modal/suggest-panel';

        case 'ask for reschedule':
          return 'action-wrapper/live-modal/ask-reschedule';

        case 'ask for cancellation':
          return 'action-wrapper/live-modal/ask-cancel';

        case 'unassign panel':
          return 'recruiter-view/actions/unassign-panel';

        case 'assign candidate':
          return 'recruiter-view/actions/unassign-panel';

        case 'remind panel':
          return 'recruiter-view/actions/unassign-panel';

        case 'add panel':
          return 'recruiter-view/actions/add-panel';

        case 'delete assessment':
          return 'recruiter-view/actions/delete-view';

        case 'delete invite':
          return 'recruiter-view/actions/delete-view';

        case 'change assessment owner':
          return 'recruiter-view/actions/change-owner';

        case 'delete questions':
          return 'recruiter-view/actions/delete-view';

        case 'invite recruiters to the event':
          return 'recruiter-view/actions/add-to-event';

        case 'invite panel to the event':
          return 'recruiter-view/actions/add-to-event';

        case 'add assessments to the event':
          return 'recruiter-view/actions/add-to-event';
      }

      return actionType;
    }),
    storeRelation: Ember.computed('relationType', function () {
      switch (this.get('relationType')) {
        case 'candidates':
          return this.get('candidates');

        case 'assessmentCandidates':
          return this.get('assessmentCandidates');

        case 'liveSession':
          return this.get('liveSession');

        case 'panels':
          return this.get('panels');

        case 'assessments':
          return this.get('assessments');

        case 'assessmentSections':
          return this.get('assessmentSections');

        case 'questions':
          return this.get('questions');

        default:
          return this.get("".concat(this.get('relationType')));
      }
    })
  });

  _exports.default = _default;
});