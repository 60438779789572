define("recruit/models/assessment-metrics", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    external_id: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    status: _emberData.default.belongsTo('status/assessment'),
    owned_by: _emberData.default.belongsTo('user'),
    inviteTime: _emberData.default.attr('number'),
    completeTime: _emberData.default.attr('number'),
    assignTime: _emberData.default.attr('number'),
    evaluationTime: _emberData.default.attr('number'),
    shortlistTime: _emberData.default.attr('number')
  });

  _exports.default = _default;
});