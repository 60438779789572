define("recruit/templates/components/filters/section-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/mrqp6si",
    "block": "{\"symbols\":[\"status\",\"&default\"],\"statements\":[[1,[25,\"filters/section-name\",null,[[\"selectedAssessmentSection\",\"store\"],[[20,[\"selectedAssessmentSection\"]],[20,[\"store\"]]]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"field\"],[7],[0,\"\\n\"],[4,\"ui-dropdown\",null,[[\"class\",\"selected\",\"onChange\"],[\"selection fluid\",[20,[\"selectedSectionStatus\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"selectedSectionStatus\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"default text\"],[7],[0,\"Select \"],[1,[20,[\"filter\",\"field\"]],false],[8],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"dropdown icon\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"menu\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"statusList\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"item\"],[10,\"data-value\",[19,1,[\"id\"]],null],[7],[0,\"\\n          \"],[1,[19,1,[\"label\"]],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        Please select a assessment name.\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/filters/section-status.hbs"
    }
  });

  _exports.default = _default;
});