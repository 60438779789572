define("recruit/templates/components/answer-loading-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CYNNG0Oc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui centered center aligned grid\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"sixteen wide centered column\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background margin-top-thirty\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"answer-lines animated-background\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/answer-loading-placeholder.hbs"
    }
  });

  _exports.default = _default;
});