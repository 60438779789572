define("recruit/initializers/setup-sanitizers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-sanitize-setup-sanitizers',
    initialize: function initialize(container) {
      if (container.registerOptionsForType) {
        container.registerOptionsForType('sanitizer', {
          instantiate: false
        });
      } else {
        // Ember < 2
        container.optionsForType('sanitizer', {
          instantiate: false
        });
      }
    }
  };
  _exports.default = _default;
});