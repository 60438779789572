define("recruit/components/recruiter-view/questions/filters/filter-dropdown-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.setProperties({
        'addedFilters.skills': Ember.A([]),
        'addedFilters.type': Ember.A([]),
        'addedFilters.created_by': Ember.A([])
      });

      if (this.get('appliedListFilters.skill') && this.get('appliedListFilters.skill.length')) {
        this.get('appliedListFilters.skill').forEach(function (skill) {
          _this.get('addedFilters.skills').pushObject(skill);
        });
      }

      if (this.get('appliedListFilters.created_by') && this.get('appliedListFilters.created_by.length')) {
        this.get('appliedListFilters.created_by').forEach(function (user) {
          _this.get('addedFilters.created_by').pushObject(user);
        });
      }

      if (this.get('appliedListFilters.type') && this.get('appliedListFilters.type.length')) {
        this.get('appliedListFilters.type').forEach(function (type) {
          _this.get('addedFilters.type').pushObject(type);
        });
      }
    },
    questionTypeList: Ember.computed('typeList', 'typeSearchText', function () {
      var typesList = this.get('typeList');
      var searchText = this.get('typeSearchText');

      if (searchText) {
        return this.get('typeList').filter(function (questionType) {
          return questionType.get('name').toLowerCase().includes(searchText.toLowerCase().trimStart());
        });
      } else {
        return typesList;
      }
    }),
    actions: {
      toggleFilter: function toggleFilter(property) {
        this.sendAction('toggleFilter', property);
      },
      applyFilter: function applyFilter() {
        this.sendAction('applyFilter');
      },
      setSkills: function setSkills(checked, value) {
        var oldSkill = this.get('addedFilters.skills').findBy('id', value.get('id'));

        if (checked && !oldSkill) {
          this.get('addedFilters.skills').pushObject(Ember.Object.create({
            type: 'skill',
            id: value.get('id'),
            label: value.get('name')
          }));
        } else if (this.get('addedFilters.skills.length')) {
          this.get('addedFilters.skills').removeObject(oldSkill);
        }
      },
      setTypes: function setTypes(checked, value) {
        var oldType = this.get('addedFilters.type').findBy('id', value.get('id'));

        if (checked && !oldType) {
          this.get('addedFilters.type').pushObject(Ember.Object.create({
            type: 'type',
            id: value.get('id'),
            label: value.get('name') === "Objective" ? "multiple choice" : value.get('name').toLowerCase()
          }));
        } else if (this.get('addedFilters.type.length')) {
          this.get('addedFilters.type').removeObject(oldType);
        }
      },
      setUsers: function setUsers(checked, value) {
        var oldUser = this.get('addedFilters.created_by').findBy('id', value.get('id'));

        if (checked && !oldUser) {
          this.get('addedFilters.created_by').pushObject(Ember.Object.create({
            type: 'created_by',
            id: value.get('id'),
            label: value.get('name')
          }));
        } else if (this.get('addedFilters.created_by.length')) {
          this.get('addedFilters.created_by').removeObject(oldUser);
        }
      },
      resetAddedFilters: function resetAddedFilters() {
        this.sendAction('resetAddedFilters');
      }
    }
  });

  _exports.default = _default;
});