define("recruit/components/recruiter-view/assessment/helpers/notification-status", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    value: Ember.computed('status', function () {
      if (this.get('status') && _npmLodash.default.isString(this.get('status')) && _npmLodash.default.toLower(this.get('status'))) {
        switch (_npmLodash.default.toLower(this.get('status'))) {
          case 'enqueued':
            {
              return {
                label: 'Queued',
                color: 'yellow'
              };
            }

          case 'sent':
            {
              return {
                label: 'Sent',
                color: 'yellow'
              };
            }

          case 'delivered':
            {
              return {
                label: 'Delivered',
                color: 'green'
              };
            }

          case 'failed':
            {
              return {
                label: 'Failed',
                color: 'red'
              };
            }

          case 'bounced':
            {
              return {
                label: 'Bounced',
                color: 'red'
              };
            }

          case 'read':
            {
              return {
                label: 'Read',
                color: 'green'
              };
            }

          case 'archived':
            {
              return {
                label: 'Archived',
                color: 'red'
              };
            }

          case 'scheduled':
            {
              return {
                label: 'Scheduled',
                color: 'yellow'
              };
            }

          case 'deleted':
            {
              return {
                label: 'Deleted',
                color: 'red'
              };
            }
        }
      }
    }),
    typeColor: Ember.computed('templateType', function () {
      var label = this.get('templateType.label') ? _npmLodash.default.isString(this.get('templateType.label')) ? _npmLodash.default.toLower(this.get('templateType.label')) : '' : '';

      if (label) {
        if (label.includes('invite')) {
          return Ember.String.htmlSafe("color: #AE60D0");
        } else if (_npmLodash.default.includes(label, 'reminder')) {
          return Ember.String.htmlSafe("color: #FFBA49");
        } else if (_npmLodash.default.includes(label, 'select')) {
          return Ember.String.htmlSafe("color: #6FBE56");
        } else if (_npmLodash.default.includes(label, 'reject')) {
          return Ember.String.htmlSafe("color: #D7554E");
        } else if (_npmLodash.default.includes(label, 'extend')) {
          return Ember.String.htmlSafe("color: #FD96CA");
        } else if (_npmLodash.default.includes(label, 'complete') || _npmLodash.default.includes(label, 'panel notification')) {
          return Ember.String.htmlSafe("color: #17BEFA");
        }
      }
    })
  });

  _exports.default = _default;
});