define("recruit/routes/recruiter/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    setupController: function setupController(controller) {
      this.get('store').query('dashboard/count', {
        v: (0, _moment.default)().unix()
      }).then(function (counts) {
        return controller.set('counts', counts.get('firstObject'));
      });
    },
    actions: {
      goToCandidateList: function goToCandidateList(filterFlag) {
        this.get('router').transitionTo('recruiter.candidate', {
          queryParams: _defineProperty({}, filterFlag, true)
        });
      }
    }
  });

  _exports.default = _default;
});