define("recruit/templates/components/panel/set-availability/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mpc4MpKl",
    "block": "{\"symbols\":[\"occurrence\",\"timetable\",\"calendar\",\"&default\"],\"statements\":[[4,\"as-calendar\",null,[[\"title\",\"occurrences\",\"defaultTimeZoneQuery\",\"dayStartingTime\",\"dayEndingTime\",\"defaultOccurrenceTitle\",\"timeSlotDuration\",\"onAddOccurrence\",\"onUpdateOccurrence\",\"onRemoveOccurrence\",\"onNavigateWeek\"],[\"SET AVAILABILITY\",[20,[\"occurrences\"]],\"New York|London|Dubai|Hong Kong\",\"00:00\",\"23:59\",\"Available\",\"0:30\",[25,\"action\",[[19,0,[]],\"calendarAddOccurrence\"],null],[25,\"action\",[[19,0,[]],\"calendarUpdateOccurrence\"],null],[25,\"action\",[[19,0,[]],\"calendarRemoveOccurrence\"],null],[25,\"action\",[[19,0,[]],\"calendarNavigateWeek\"],null]]],{\"statements\":[[0,\"  \"],[1,[25,\"panel/set-availability/calendar-occurrence\",null,[[\"class\",\"model\",\"timeZone\",\"timeSlotHeight\",\"timetable\",\"timeSlotDuration\",\"isResizable\",\"isDraggable\",\"onUpdate\",\"onRemove\",\"revertModel\",\"updateOccurrence\"],[\"selection\",[19,1,[]],[19,3,[\"timeZone\"]],[19,3,[\"timeSlotHeight\"]],[19,2,[]],[19,3,[\"timeSlotDuration\"]],false,false,[25,\"action\",[[19,0,[]],\"calendarUpdateOccurrence\"],null],[25,\"action\",[[19,0,[]],\"calendarRemoveOccurrence\"],null],\"revertModel\",\"updateOccurrence\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2,3]},null],[0,\"\\n\"],[11,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/set-availability/calendar.hbs"
    }
  });

  _exports.default = _default;
});