define("recruit/components/recruiter-view/assessment/details/assessment-section-candidate", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    s: null,
    evaluationType: Ember.A([]),
    renderTable: false,
    panelSearchText: null,
    modelObserver: Ember.observer('model.isFulfilled', 'model.assessmentSectionCandidates.isFulfilled', 'model.section.isFulfilled', function () {
      var _this = this;

      var filters = this.get('store').peekAll('filter/master');
      this.set('masterFilters', filters.filter(function (item) {
        return item.get('objectType') === 'assc-filters';
      }));
      var dateFilters = this.get('masterFilters').get('lastObject');
      this.set('masterFilters', this.get('masterFilters').removeObject(dateFilters));
      this.constructColumns(this.get('commentWidth'));
      this.get('model.parameters').then(function (res) {
        return res.length ? _this.parametersColumn(res) : true;
      }).then(function () {
        return _this.get('model.section').then(function (section) {
          _this.addColumns(section);

          if (!section.get('hasScoreOrCutoff')) {
            var scoreFilters = _this.get('masterFilters').filterBy('type', 'range');

            _this.get('masterFilters').removeObjects(scoreFilters);
          }

          _this.set('advanceFilters', _this.get('masterFilters'));

          _this.set('dateFilters', dateFilters);
        });
      }) // .then(()=>{
      //   return this.get('model.section').then((section)=>{
      //     return section.get('evaluationForm').then((form)=>{
      //       if(form){
      //         return form.get('fieldKeys.length')? this.formFieldsColumn(form.get('id'), form.get('fieldKeys')): true
      //       }
      //     })
      //   })
      // })
      .finally(function () {
        _this.set('hasVm', _this.get('model.section.isVm'));

        if (_this.get('columns.length') > 8) {
          _this.get('columns').objectAt(2).cellClassNames = ['padding-md-right fixed-column fixed-column-shadow'];
          _this.get('columns').objectAt(2).classNames = ['text-grey ember-table-head text-left fixed-column fixed-column-shadow'];
        }

        _this.get('columns').findBy('label', 'Comments').width = _this.commentWidth();

        _this.set('columns', _this.get('columns').sortBy('sortWeight'));

        _this.set('renderTable', true);
      });
    }).on('init'),
    commentWidth: function commentWidth() {
      var fixedWidth = this.get('columns.length') > 7 ? 782 : 777;
      return this.get('columns.length') > 7 ? '180px' : "".concat(Ember.$('#assessmentCandidateTable').innerWidth() - 2 - fixedWidth, "px");
    },
    searchTextObserver: Ember.observer('searchText', function () {
      var _this2 = this;

      if (this.get('searchText')) {
        Ember.run.debounce(this, function () {
          _this2.set('s', _this2.get('searchText'));
        }, 1000);
      } else {
        this.set('s', null);
      }
    }),
    showAppliedFilter: Ember.computed('customListFilters.[]', 'appliedFilters.[]', function () {
      return this.get('customListFilters.length') || this.get('appliedFilters.length');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('defaultPanels', Ember.A([]));
      this.set('addedFilters', Ember.Object.create({
        panel: Ember.A([]),
        recommendation: Ember.A([])
      }));
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      Ember.run.next('afterRender', function () {
        Ember.$('.ui.dropdown').dropdown();
      });

      if (!this.get('defaultPanels.length')) {
        this.get('store').query('panel', {
          assessment_section_id: this.get('model.id')
        }).then(function (res) {
          _this3.set('defaultPanels', res.map(function (panel) {
            return panel.get('user');
          }).uniqBy('id'));

          _this3.set('panelList', _this3.get('defaultPanels'));
        });
      }
    },
    addColumns: function addColumns(sectionModel) {
      if (sectionModel.get('hasScoreOrCutoff')) {
        if (!this.get('columns').findBy('label', 'Score')) {
          this.get('columns').pushObject({
            "label": "Score",
            "width": "180px",
            "valuePath": 'score',
            "classNames": ["text-grey cursor-auto ember-table-head text-center"],
            "isDefault": false,
            "tableType": "r-c",
            "sortWeight": 401,
            "cellComponent": "recruiter-view/assessment/helpers/score-view",
            "cellClassNames": ["text-center"]
          });
        }

        if (!this.get('columns').findBy('label', 'Cutoff')) {
          this.get('columns').pushObject({
            "label": "Cutoff",
            "width": "180px",
            "valuePath": 'assessmentSection.cut_off',
            "classNames": ["text-grey cursor-auto ember-table-head text-center"],
            "isDefault": false,
            "tableType": "r-c",
            "sortWeight": 402,
            "cellComponent": "recruiter-view/assessment/helpers/score-view",
            "cellClassNames": ["text-center"]
          });
        }
      }

      if (sectionModel.get('evaluationFormUrl')) {
        if (!this.get('columns').findBy('label', 'Evaluation Form')) {
          this.get('columns').pushObject({
            "label": "Evaluation Form",
            "width": "180px",
            "classNames": ["text-grey cursor-auto ember-table-head text-center"],
            "isDefault": false,
            "tableType": "r-c",
            "valuePath": "evaluations",
            "sortWeight": 404,
            "cellComponent": "recruiter-view/assessment/helpers/view-form-link",
            "cellClassNames": ["text-center ignoreRowClick"]
          });
        }
      }
    },
    formFieldsColumn: function formFieldsColumn(id, fieldKeys) {
      var _this4 = this;

      fieldKeys = _npmLodash.default.filter(fieldKeys, function (item) {
        return item ? item.trim() : false;
      });
      var length = +this.get('columns.length') + 1;
      fieldKeys.forEach(function (key, index) {
        // if(index < 4){
        _this4.get('columns').pushObject({
          label: key,
          formId: id,
          valuePath: 'evaluations',
          width: '150px',
          component: 'helpers/table-header-view',
          cellComponent: "recruiter-view/assessment/helpers/forms-data-view",
          cellClassNames: ['text-center'],
          classNames: ['text-grey ember-table-head text-center text-capitalize'],
          sortWeight: length + index
        }); // }

      });
    },
    parametersColumn: function parametersColumn(parameters) {
      var _this5 = this;

      this.get('columns').pushObject({
        label: 'Average',
        valuePath: 'evaluations',
        cellComponent: "recruiter-view/assessment/helpers/average-rating-view",
        classNames: ['text-grey cursor-auto ember-table-head text-center'],
        cellClassNames: ['text-center'],
        width: '150px',
        sortWeight: 6
      });
      parameters.forEach(function (parameter, index) {
        _this5.get('columns').pushObject({
          label: parameter.get('name'),
          parameterId: parameter.get('id'),
          valuePath: 'evaluations',
          width: '130px',
          extra: {
            tooltip: parameter.get('name')
          },
          component: 'helpers/table-header-view',
          cellComponent: "recruiter-view/assessment/helpers/parameter-view",
          cellClassNames: ['text-center'],
          classNames: ['text-grey ember-table-head text-center text-capitalize'],
          sortWeight: 7 + index
        });
      });
    },
    constructColumns: function constructColumns() {
      this.set('columns', Ember.A([{
        component: "helpers/table-checkboxs",
        cellComponent: "helpers/row-checkbox",
        sortable: false,
        width: '50px',
        classNames: ['ember-table-head padding-no ember-table-checkbox-th fixed-column'],
        cellClassNames: ['child padding-no fixed-column'],
        sortWeight: 1,
        fixed: true
      }, {
        valuePath: 'assessmentCandidate.candidate',
        cellComponent: 'helpers/table-profile-picture',
        sortable: false,
        width: '60px',
        classNames: ['ember-table-head fixed-column'],
        cellClassNames: ["fixed-column"],
        sortWeight: 2,
        fixed: true
      }, {
        label: 'Name',
        valuePath: 'assessmentCandidate.candidate',
        width: '190px',
        tableOf: 'assessment',
        cellComponent: 'candidate-helpers/candidate-details',
        cellClassNames: ['padding-md-right fixed-column'],
        classNames: ['text-grey ember-table-head text-left fixed-column'],
        sortWeight: 3,
        fixed: true
      }, {
        label: 'Panel',
        cellComponent: 'recruiter-view/assessment/helpers/panel-view',
        valuePath: 'sortedPanelCandidates',
        classNames: ['text-grey cursor-auto ember-table-head text-left text-grey'],
        width: '175px',
        sortWeight: 4
      }, {
        label: 'Recommendation',
        valuePath: 'sortedPanelCandidates',
        classNames: ['text-grey cursor-auto ember-table-head text-center'],
        cellComponent: 'recruiter-view/assessment/helpers/recommendation-view',
        cellClassNames: ['text-center'],
        width: '150px',
        sortWeight: 5
      }, {
        label: 'Comments',
        valuePath: 'evaluations',
        classNames: ['text-grey cursor-auto ember-table-head text-center'],
        cellComponent: 'recruiter-view/assessment/helpers/comment-view',
        cellClassNames: ['text-center'],
        width: '180px',
        sortWeight: 400
      }, {
        label: 'Evaluated at',
        valuePath: 'evaluations',
        classNames: ['text-grey cursor-auto ember-table-head text-center'],
        tableOf: 'assessment',
        cellClassNames: ['text-center'],
        cellComponent: 'recruiter-view/assessment/helpers/date-view',
        width: '150px',
        sortWeight: 404
      }]));

      if (this.get('model.is_proctor')) {
        this.get('columns').pushObject({
          "label": "Proview Section Index",
          "width": "180px",
          "isShow": false,
          "classNames": ["text-grey cursor-auto ember-table-head text-center"],
          "isDefault": false,
          "tableType": "r-c",
          "valuePath": "proviewRating",
          "sortWeight": 405,
          "cellComponent": "helpers/proctor-rating",
          "cellClassNames": ["text-center"]
        });
      }
    },
    actions: {
      toggleFilter: function toggleFilter(property) {
        if (this.get('panelSearchText')) {
          this.set('panelSearchText', null);
        }

        this.toggleProperty(property);
      },
      setCandidates: function setCandidates(selectedCandidates) {
        this.set('candidates', selectedCandidates);
      },
      applyFilter: function applyFilter(showFilters) {
        if (this.get('showAppliedFilter')) {
          this.set('hideFilter', true);
        }

        if (showFilters) {
          this.send('toggleFilter', "".concat(showFilters));
        }

        this.set('appliedListFilters', Ember.Object.create(_npmLodash.default.groupBy(_npmLodash.default.concat(this.get('customListFilters'), this.get('appliedFilters')), 'type')));
      },
      resetFilter: function resetFilter() {
        this.setProperties({
          'addedFilters.panel': Ember.A([]),
          'addedFilters.recommendation': Ember.A([]),
          'addedFilters.evalDate': Ember.A([]),
          'addedFilters.unassigned': Ember.A([]),
          'addedFilters.advanceFilters': Ember.A([]),
          'assessmentFilters': null
        });
      },
      resetAppliedFilters: function resetAppliedFilters() {
        this.setProperties({
          'appliedFilters': Ember.A([]),
          'customListFilters': Ember.A([]),
          'assessmentFilters': null,
          'appliedListFilters': Ember.A([])
        });
        this.send('resetFilter');

        if (this.get('showFilters')) {
          this.send('toggleFilter', 'showFilters');
        }
      },
      removeAddedField: function removeAddedField(array, index, customListFilters) {
        if (customListFilters) {
          var selectedObject = array.objectAt(index);

          switch (selectedObject.get('type')) {
            case 'panel':
              {
                this.get('addedFilters.panel').removeObject(array);
                break;
              }

            case 'recommendation':
              {
                this.get('addedFilters.recommendation').removeObject(array);
                break;
              }

            case 'unassigned':
              {
                this.get('customListFilters').filterBy('type', 'unassigned').removeObject(array.get('firstObject'));
                break;
              }

            case 'advanceFilters':
              {
                this.get('addedFilters.advanceFilters').removeObject(array);
                break;
              }
          }

          if (this.get('showFilters')) {
            this.send('toggleFilter', 'showFilters');
          }

          customListFilters.removeObject(selectedObject);
        }

        if (array.length) {
          array.removeAt(index);
        }
      },
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
      }
    }
  });

  _exports.default = _default;
});