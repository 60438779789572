define("recruit/templates/components/reports/helpers/assessment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5fk8+eXB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[9,\"style\",\"height: 34px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"twelve wide column padding-no padding-md-left text-left\"],[9,\"style\",\"font-size: 12px;\"],[7],[0,\"\\n    \"],[6,\"div\"],[10,\"data-tooltip\",[26,[[20,[\"row\",\"title\"]]]]],[9,\"data-position\",\"bottom center\"],[9,\"class\",\"ignoreRowClick\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"recruiter.assessment.details.overview\",[20,[\"row\",\"id\"]]],[[\"class\"],[\"text-blue text-weight-medium margin-no ellipsis\"]],{\"statements\":[[1,[20,[\"row\",\"title\"]],false]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"data-tooltip\",[26,[[20,[\"row\",\"external_id\"]]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"text-black text-weight-light ellipsis\"],[7],[1,[20,[\"row\",\"external_id\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/assessment-details.hbs"
    }
  });

  _exports.default = _default;
});