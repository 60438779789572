define("recruit/templates/components/live-schedule/panel-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BAdk7nHl",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"style\",\"width: 32px; display:inline-block\"],[7],[0,\"\\n  \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"model\",\"profilePic\"]],\"ui tiny avatar image circular\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"padding-vs-left padding-no-right padding-vs-top\"],[9,\"style\",\"display:inline-block\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"margin-no text-black text-weight-normal\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"contentTooltip\"],[10,\"data-title\",[20,[\"model\",\"fullName\"]],null],[10,\"data-content\",[20,[\"model\",\"email\"]],null],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n      \"],[1,[20,[\"model\",\"fullName\"]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"padding-sm-left padding-sm-right cursor-pointer\"],[3,\"action\",[[19,0,[]],\"remove\",[20,[\"model\"]],[20,[\"index\"]]]],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"/images/icons/close.svg\"],[9,\"alt\",\"\"],[9,\"style\",\"width:8px\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-schedule/panel-card.hbs"
    }
  });

  _exports.default = _default;
});