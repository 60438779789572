define("recruit/templates/components/reports/helpers/user-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0fL4BsDu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"four wide column padding-no\"],[7],[0,\"\\n    \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"userObject\",\"profilePic\"]],\"ui circular image reports display-inline-block profilePic\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"twelve wide column padding-no text-left\"],[7],[0,\"\\n    \"],[6,\"div\"],[10,\"data-tooltip\",[26,[[20,[\"userObject\",\"name\"]]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"text-black text-weight-medium margin-no ellipsis text-size-small\"],[7],[1,[20,[\"userObject\",\"name\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"data-tooltip\",[26,[[20,[\"userObject\",\"username\"]]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"text-black text-weight-light ellipsis text-size-vsmall\"],[7],[1,[20,[\"userObject\",\"username\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/user-details.hbs"
    }
  });

  _exports.default = _default;
});