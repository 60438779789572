define("recruit/templates/components/model-table/recruiter/event-row-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dcEdmwWb",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"if\",[[20,[\"row\",\"content\",\"isRelationsLoaded\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"columns\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"component\",[[25,\"concat\",[\"light-table/cells/\",[19,1,[\"cellType\"]]],null],[19,1,[]],[20,[\"row\"]]],[[\"table\",\"rawValue\",\"tableActions\",\"extra\"],[[20,[\"table\"]],[25,\"get\",[[20,[\"row\"]],[19,1,[\"valuePath\"]]],null],[20,[\"tableActions\"]],[20,[\"extra\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"events/table-loading-placeholder\",null,[[\"columns\",\"class\",\"tagName\"],[[20,[\"columns\"]],\"events-table-loading-placeholder-heigh\",\"tr\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/model-table/recruiter/event-row-component.hbs"
    }
  });

  _exports.default = _default;
});