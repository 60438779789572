define("recruit/models/organization", ["exports", "ember-data", "npm:lodash", "moment"], function (_exports, _emberData, _npmLodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    logoutUrl: _emberData.default.attr('string'),
    users: _emberData.default.hasMany('user'),
    assessments: _emberData.default.hasMany('assessment'),
    hasAssessmentCreate: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isAdhocLive: _emberData.default.attr('boolean'),
    isIconEvaluation: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('pgdate'),
    // list of available assessment sections
    isSimulation: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isCode: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isEssay: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isEvaluation: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isInterview: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isLive: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isObjective: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isPearsonEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // isPublicAttachment       : DS.attr('boolean', {defaultValue: false}),
    isTyping: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    candidateIOSDownloadUrl: _emberData.default.attr('string', {
      defaultValue: 'https://itunes.apple.com/in/app/talview-candidate-app/id1015320525?mt=8'
    }),
    candidateDroidPackageName: _emberData.default.attr('string', {
      defaultValue: 'https://play.google.com/store/apps/details?id=com.talview.candidate.liveapp&hl=en_US'
    }),
    availableSections: Ember.A([]),
    logoStyle: Ember.computed('logo', function () {
      if (this.get('logo')) {
        return Ember.String.htmlSafe('background-image:url(' + this.get('logo') + ')');
      }
    }),
    getSectionTypeList: function getSectionTypeList() {
      var _this = this;

      var loadExternalAssessments = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var sectionList = this.get('store').peekAll('section-type');

      if (!_npmLodash.default.isNil(sectionList) && sectionList.length) {
        sectionList.forEach(function (sectionType) {
          if (sectionType.get('backendProperty')) {
            if (_this.get(sectionType.get('backendProperty')) && !_this.get('availableSections').findBy('id', sectionType.get('id'))) {
              _this.get('availableSections').pushObject(sectionType);
            }
          }
        });
      }

      if (loadExternalAssessments) {
        this.getExternalAssessments();
      }
    },
    getExternalAssessments: function getExternalAssessments() {
      var _this2 = this;

      this.get('store').findAll('external-assessment').then(function (easp) {
        if (easp.get('length') && !_this2.get('availableSections').findBy('id', '21')) {
          _this2.get('availableSections').pushObject(_this2.get('store').peekRecord('section-type', 21));
        }
      });
    }
  });

  _exports.default = _default;
});