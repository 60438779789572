define("recruit/components/panel-evaluation/section-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    answers: [],
    openModal: false,
    store: Ember.inject.service(),
    isEvaluations: false,
    selectedAnswer: Ember.computed('answers.@each', function () {
      return this.get('answers.firstObject');
    }),
    actions: {
      toggleEvaluations: function toggleEvaluations() {
        this.toggleProperty('isEvaluations');
      },
      changeAnswer: function changeAnswer(answer) {
        this.set('selectedAnswer', answer);
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('openModal');
      },
      gotoNextAnswer: function gotoNextAnswer(answer) {
        this.set('selectedAnswer', this.get('store').peekRecord('answer', answer.get('nextAnswerId')));
      }
    }
  });

  _exports.default = _default;
});