define("recruit/routes/recruiter/assessment/details/job-description", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('assessmentSection', Ember.A([]));
    },
    actions: {
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});