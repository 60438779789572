define("recruit/models/answer", ["exports", "ember-data", "lodash", "ember-sanitize-html/utils/sanitize-html"], function (_exports, _emberData, _lodash, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('number'),
    answered_at: _emberData.default.attr('pgdate'),
    score: _emberData.default.attr('number'),
    choice: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    max_sequence: _emberData.default.attr('number'),
    submitted_files: _emberData.default.attr(),
    // was array
    videos: _emberData.default.hasMany('file'),
    thumbnails: _emberData.default.hasMany('file'),
    question: _emberData.default.belongsTo('question'),
    nextAnswerId: _emberData.default.attr('number'),
    plainTxtContent: _emberData.default.attr('string'),
    typingAccuracy: _emberData.default.attr('number'),
    typingSpeed: _emberData.default.attr('number'),
    typingCompletion: _emberData.default.attr('number'),
    attachments: _emberData.default.hasMany('file'),
    answerCodeSubmissions: _emberData.default.hasMany('answer-code-submission'),
    assessmentSection: _emberData.default.belongsTo('assessment-section'),
    assessmentCandidate: _emberData.default.belongsTo('assessment-candidate'),
    assessmentSectionCandidate: _emberData.default.belongsTo('assessment-section-candidate'),
    vmAttachment: _emberData.default.hasMany('file'),
    vmRecordings: _emberData.default.hasMany('file'),
    // answerVideo: Ember.computed('videos.isLoaded','videos.@each.isLoaded', function() {
    answerVideo: Ember.computed(function () {
      return this.get('videos').then(function (videos) {
        var vArray = [];
        videos.forEach(function (video) {
          vArray.push(video.get('flowplayerObject'));
        });
        return vArray;
      });
    }),
    averageMemory: Ember.computed('answerCodeSubmissions', function () {
      var _this = this;

      return this.get('answerCodeSubmissions').then(function (codeSubmissions) {
        if (codeSubmissions.length == 0) {
          _this.set('averageMemory', '--');
        } else {
          var size = _lodash.default.mean(codeSubmissions.mapBy('memory'));

          var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));

          _this.set('averageMemory', (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]);
        }
      });
    }),
    averageTime: Ember.computed('answerCodeSubmissions', function () {
      var _this2 = this;

      return this.get('answerCodeSubmissions').then(function (codeSubmissions) {
        if (codeSubmissions) {
          _this2.set('averageTime', '--');
        } else {
          _this2.set('averageTime', _lodash.default.mean(codeSubmissions.mapBy('time') || 0));
        }
      });
    }),
    passedSubmissions: Ember.computed.filterBy('answerCodeSubmissions', 'isSuccessFull', true),
    VMVideos: Ember.computed(function () {
      return this.get('vmRecordings').then(function (videos) {
        var vArray = [];
        videos.forEach(function (video) {
          vArray.push(video.get('flowplayerObject'));
        });
        return vArray;
      });
    }),
    scoreText: Ember.computed(function () {
      return this.get('score') || 'N/A';
    }),
    sanitizeOptions: Ember.computed(function () {
      return {
        allowedAttributes: {
          'a': ['href', 'style', 'target'],
          'img': ['src', 'style'],
          'span': ['style'],
          'div': ['style'],
          'p': ['style']
        },
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'img', 's', 'p', 'li', 'ol', 'ul', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'u', 'span', 'div'],
        allowedClasses: {
          'p': ['ql-align-center', 'ql-align-right', 'ql-align-left']
        }
      };
    }),
    sanitizedAnswer: Ember.computed('text', function () {
      return (0, _sanitizeHtml.default)(this.get('text'), this.get('sanitizeOptions'));
    })
  });

  _exports.default = _default;
});