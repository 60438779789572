define("recruit/validators/confirm-presence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateConfirmPresence;

  function validateConfirmPresence() {
    return function (key, newValue) {
      var isValid = newValue && newValue.trim().length ? true : false;
      return isValid || key.capitalize() + ' cannot be blank';
    };
  }
});