define("recruit/models/event-panel", ["exports", "ember-data", "recruit/models/event-user"], function (_exports, _emberData, _eventUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _eventUser.default.extend({
    event: _emberData.default.belongsTo('event', {
      inverse: 'panel'
    })
  });

  _exports.default = _default;
});