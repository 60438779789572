define("recruit/components/answer-views/objective", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    asscLoaded: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').query('assessment-section-candidate', {
        expand: ['correctCount', 'incorrectCount', 'skippedCount'].join(','),
        ids: [this.get('selectedAssc.id')]
      }).then(function (response) {
        _this.set('asscLoaded', true);

        _this.set('asscModel', response.get('firstObject'));
      });
    },
    totalQuestions: Ember.computed('asscModel', function () {
      return this.get('asscModel.section.questionsCount');
    }),
    correctCountPercent: Ember.computed('asscModel', function () {
      var correctCount = this.get('asscModel.correctCount');
      return correctCount > 0 ? correctCount / this.get('totalQuestions') * 100 : 0;
    }),
    incorrectCountPercent: Ember.computed('asscModel', function () {
      var incorrectCount = this.get('asscModel.incorrectCount');
      return incorrectCount > 0 ? incorrectCount / this.get('totalQuestions') * 100 : 0;
    }),
    skippedCountPercent: Ember.computed('asscModel', function () {
      var skippedCount = this.get('asscModel.skippedCount');
      return skippedCount > 0 ? skippedCount / this.get('totalQuestions') * 100 : 0;
    }),
    unAnsweredCount: Ember.computed('asscModel', function () {
      return this.get('totalQuestions') - (this.get('asscModel.skippedCount') + this.get('asscModel.correctCount') + this.get('asscModel.incorrectCount'));
    }),
    unAsnweredCountPercent: Ember.computed('asscModel', 'unAnsweredCount', function () {
      var unAnsweredCount = this.get('unAnsweredCount');
      return unAnsweredCount > 0 ? unAnsweredCount / this.get('totalQuestions') * 100 : 0;
    }),
    graphArray: Ember.computed('selectedAssc.section', 'selectedAssc.evaluationRatings.[]', function () {
      var _this2 = this;

      var arr = Ember.A([]);

      if (this.get('selectedAssc.section.isExternal')) {
        this.get('selectedAssc.evaluationRatings').forEach(function (p, index) {
          arr.pushObject({
            y: p,
            percentage: _this2.get('selectedAssc.percentageScore').objectAt(index)
          });
        });
        return arr;
      } else {
        return this.get('selectedAssc.evaluationRatings');
      }
    }),
    ratingsObservers: Ember.observer('selectedAssc.evaluationRatings.@each', 'selectedAssc.parameterMaxValue.@each', 'selectedAssc.parameterName.@each', function () {
      if (this.get('selectedAssc.evaluationRatings.length') && this.get('selectedAssc.parameterMaxValue.length') && this.get('selectedAssc.parameterName.length')) {
        this.set('chartOptions', {
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          xAxis: {
            min: 0,
            title: {
              text: 'Skills'
            },
            categories: this.get('selectedAssc.catagories')
          },
          yAxis: [{
            min: 0,
            title: {
              text: 'Score'
            }
          }],
          legend: {
            shadow: false
          },
          tooltip: {
            shared: true
          },
          plotOptions: {
            column: {
              grouping: false,
              shadow: false,
              borderWidth: 0
            }
          },
          series: [{
            name: 'Total',
            color: '#F0F0F0',
            data: this.get('selectedAssc.parameterMaxValue'),
            pointPadding: 0.3,
            pointPlacement: -0.2,
            tooltip: {
              pointFormat: '<span>{series.name} : {point.y}</span><br>'
            }
          }, {
            name: 'Scored',
            color: '#41536B',
            data: this.get('graphArray'),
            pointPadding: 0.4,
            pointPlacement: -0.2,
            tooltip: {
              pointFormat: this.get('selectedAssc.section.isExternal') ? '<span">{series.name} : {point.y}</span><br><span>Percentage : {point.percentage} %</span>' : '<span">{series.name} : {point.y}</span>'
            }
          }]
        });
      }
    }).on('init')
  });

  _exports.default = _default;
});