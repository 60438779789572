define("recruit/models/assessment-section", ["exports", "ember-data", "moment", "ember-data-has-many-query"], function (_exports, _emberData, _moment, _emberDataHasManyQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataHasManyQuery.default.ModelMixin, {
    is_public: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    is_proctor: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isRetake: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isPlaylist: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    is_playlist: Ember.computed.oneWay('isPlaylist'),
    is_skip: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isSkipAllowed: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    is_screenshare: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isExtraAs: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    is_review: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isReview: Ember.computed.oneWay('is_review'),
    allowedCompilers: _emberData.default.attr('string'),
    wpmCutoff: _emberData.default.attr('string'),
    // isReview                    : Ember.computed.alias('is_review'),//DS.attr('boolean', {defaultValue: false}),
    publicLinkUrl: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('pgdate'),
    end_date: _emberData.default.attr('pgdate'),
    max_section_time: _emberData.default.attr('seconds-minutes', {
      defaultValue: "00:10:00"
    }),
    max_question_time: _emberData.default.attr('seconds-minutes', {
      defaultValue: "00:05:00"
    }),
    questionDisplayTime: _emberData.default.attr('number', {
      defaultValue: 10
    }),
    intro: _emberData.default.attr('string'),
    conclusion: _emberData.default.attr('string'),
    cut_off: _emberData.default.attr('number'),
    cutOff: Ember.computed.oneWay('cut_off'),
    positive_mark: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    negative_mark: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    parameters: _emberData.default.hasMany('parameter'),
    section: _emberData.default.belongsTo('section'),
    assessment: _emberData.default.belongsTo('assessment', {
      inverse: null
    }),
    assessmentSectionCandidates: _emberData.default.hasMany('assessment-section-candidate', {
      inverse: null
    }),
    questions: _emberData.default.hasMany('question'),
    panel: _emberData.default.hasMany('panel'),
    isObjectiveScoreBreakup: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    unassigned: _emberData.default.hasMany('assessment-section-candidate', {
      inverse: null
    }),
    reportUrl: _emberData.default.attr('string'),
    formattedSectionTime: Ember.computed('max_section_time', function () {
      if (this.get('max_section_time')) {
        return _moment.default.utc(this.get('max_section_time') * 1000).format('HH:mm:ss');
      } else {
        return '--';
      }
    }),
    totalMarks: Ember.computed('section.questionsCount', 'positive_mark', function () {
      return this.get('section.questionsCount') && this.get('positive_mark') ? this.get('section.questionsCount') * this.get('positive_mark') : 0;
    }),
    formattedQuestionTime: Ember.computed('max_question_time', function () {
      if (this.get('max_question_time')) {
        return _moment.default.utc(this.get('max_question_time') * 1000).format('HH:mm:ss');
      } else {
        return '--';
      }
    }),
    formattedPreparationTime: Ember.computed('questionDisplayTime', function () {
      if (this.get('questionDisplayTime')) {
        return _moment.default.utc(this.get('questionDisplayTime') * 1000).format('mm:ss');
      } else {
        return '--';
      }
    }),
    formattedCutOff: Ember.computed('cut_off', function () {
      if (this.get('cut_off')) {
        return this.get('cut_off');
      } else {
        return '--';
      }
    }),
    isFormLoaded: Ember.computed(function () {
      var _this = this;

      this.get('parameters').then(function () {
        return _this.get('section');
      }).finally(function () {
        return _this.set('isFormLoaded', true);
      });
    }),
    QRCodeUrl: Ember.computed('is_public', 'publicLinkUrl', function () {
      if (this.get('publicLinkUrl')) {
        return "https://chart.googleapis.com/chart?cht=qr&chl=".concat(this.get('publicLinkUrl'), "&chs=80x80&chld=L|0");
      }
    }),
    // Used in:
    // 1. Assign panel component
    key: Ember.computed.alias('section.title')
  });

  _exports.default = _default;
});