define("recruit/models/actions/live-change-panel", ["exports", "ember-data", "recruit/mixins/ac-action"], function (_exports, _emberData, _acAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_acAction.default, {
    liveSessionID: _emberData.default.attr('number'),
    panel: _emberData.default.attr(),
    sync: _emberData.default.attr('boolean'),
    liveAttendees: _emberData.default.hasMany('live-attendee')
  });

  _exports.default = _default;
});