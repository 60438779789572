define("recruit/templates/recruiter/candidate/bulk-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n97iLVkb",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"margin-top-hundred\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui centered grid\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui twelve wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n        \"],[1,[25,\"bulk-file-import\",null,[[\"labelName\",\"isLiveVmSection\",\"showHeader\",\"modelName\"],[\"Candidate\",true,true,\"Candidate\"]]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/candidate/bulk-create.hbs"
    }
  });

  _exports.default = _default;
});