define("recruit/templates/components/user-helpers/user-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZPuGKD/b",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"model\",\"name\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"model\",\"name\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"margin-no ellipsis text-size-small\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"data-tooltip\",\"Anonymous\"],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n    \"],[6,\"p\"],[10,\"class\",[26,[\"margin-no ellipsis text-size-small \",[18,\"customClass\"]]]],[7],[0,\"Anonymous\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[25,\"and\",[[20,[\"isEmailRequired\"]],[20,[\"model\",\"email\"]]],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"model\",\"email\"]],null],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"ellipsis text-grey text-size-small text-weight-light\"],[7],[1,[20,[\"model\",\"email\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/user-helpers/user-details.hbs"
    }
  });

  _exports.default = _default;
});