define("recruit/components/recruiter-view/bulk/bulk-upload", ["exports", "npm:lodash", "recruit/mixins/g-actions"], function (_exports, _npmLodash, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    isUploadComplete: false,
    showUploadScreen: true,
    isBulkUpload: true,
    isUploadingCandidates: false,
    uploadComplete: false,
    toggleBtnObserver: Ember.observer('isBulkUpload', function () {
      if (!this.get('isBulkUpload')) {
        this.set('isBulkUpload', false);
      }
    }),
    didInsertElement: function didInsertElement() {
      this.initializeValues();
    },
    initializeValues: function initializeValues() {
      this.setProperties({
        feErrors: Ember.A([]),
        bcErrors: Ember.A([]),
        uploadedRecords: Ember.A([]),
        totalRecords: 0,
        showUploadScreen: true,
        isBulkUpload: true,
        isAssessmentView: false,
        isUploadingCandidates: false,
        uploadComplete: false,
        rerenderTable: true
      });
    },
    successFullRecords: Ember.computed('uploadedRecords.[]', function () {
      if (this.get('uploadedRecords.length')) {
        return this.get('uploadedRecords').sortBy('count');
      }
    }),
    setActionsParmas: function setActionsParmas(key, value, type) {
      return {
        key: key,
        value: value,
        type: type
      };
    },
    actions: {
      postUpload: function postUpload(candidates) {
        var _this = this;

        candidates = _npmLodash.default.forEach(candidates, function (candidate, index) {
          return candidate.count = index + 1;
        });
        candidates = _npmLodash.default.remove(candidates, null);
        this.set('totalRecords', candidates.length);

        if (candidates.length) {
          if (this.get('isAssessmentOverview') || this.get('isEventsModal')) {
            this.setProperties({
              showUploadScreen: false,
              isUploadingCandidates: true,
              uploadPercent: 10
            });
            this.send('invitedCandidates', candidates);
          } else {
            this.setProperties({
              showUploadScreen: false,
              isUploadingCandidates: true,
              uploadPercent: 10
            });

            _npmLodash.default.chunk(candidates, Math.ceil(candidates.length / 8)).forEach(function (candidateChunks) {
              return candidateChunks.reduce(function (promise, candidate) {
                return promise.then(function () {
                  return _this.get('store').createRecord('candidate').parse(candidate).validate().then(function (changeset) {
                    if (changeset.get('isValid')) {
                      return changeset.save().then(function (savedCandidate) {
                        savedCandidate.set('count', candidate.count);
                        return _this.get('uploadedRecords').pushObject(savedCandidate);
                      }).catch(function (e) {
                        _this.get('bcErrors').pushObject({
                          error: e.errors,
                          rowCount: candidate.count
                        });

                        return Ember.RSVP.Promise.resolve();
                      }).finally(function () {
                        if (candidates.length == _this.get('uploadedRecords.length') + _this.get('bcErrors.length') + _this.get('feErrors.length')) {
                          _this.set('uploadPercent', 100);

                          setTimeout(function () {
                            _this.set('isUploadingCandidates', false);

                            _this.set('uploadComplete', true);
                          }, 1000);
                        }

                        if (_this.get('uploadPercent') < 75) {
                          _this.set('uploadPercent', _this.get('uploadPercent') + 10);
                        }
                      });
                    } else {
                      _this.get('feErrors').pushObject({
                        error: changeset.get('errors'),
                        rowCount: candidate.count
                      });

                      return Ember.RSVP.Promise.resolve();
                    }
                  }).finally(function () {
                    if (candidates.length == _this.get('feErrors.length')) {
                      _this.set('uploadPercent', 100);

                      setTimeout(function () {
                        _this.set('isUploadingCandidates', false);

                        _this.set('uploadComplete', true);
                      }, 1000);
                    }

                    if (_this.get('uploadPercent') < 75) {
                      _this.set('uploadPercent', _this.get('uploadPercent') + 10);
                    }
                  });
                });
              }, Ember.RSVP.resolve().then(function () {
                return Ember.RSVP.resolve();
              }));
            });
          }
        } else {
          this.get('toast').error('Either file is empty or invalid', 'Error', {
            timeOut: 5000
          });
        }
      },
      completedView: function completedView(value) {
        if (this.get('isAssessmentOverview')) {
          this.send('inviteToAssessment', this.get('uploadedRecords'));
        } else {
          if (value) {
            this.set('isUploadComplete', true);
          }
        }
      },
      importAgain: function importAgain() {
        this.initializeValues();
      },
      back: function back() {
        return history.back();
      },
      invitedCandidates: function invitedCandidates(candidates) {
        var _this2 = this;

        var candidateModel = Ember.A([]);

        _npmLodash.default.chunk(candidates, Math.ceil(candidates.length / 8)).forEach(function (candidateChunks) {
          return candidateChunks.reduce(function (promise, candidate) {
            return promise.then(function () {
              return _this2.get('store').createRecord('candidate').parse(candidate).validate().then(function (changeset) {
                if (changeset.get('isValid')) {
                  candidateModel.pushObject(changeset.get('_content'));

                  _this2.get('uploadedRecords').pushObject(changeset.get('_content'));

                  if (candidates.length == candidateModel.length) {
                    if (_this2.get('isEventsModal')) {
                      _this2.send('createEventCandidate', candidateModel);
                    } else {
                      _this2.send('inviteToAssessment', candidateModel);
                    }
                  }
                } else {
                  _this2.get('feErrors').pushObject({
                    error: changeset.get('errors'),
                    rowCount: candidate.count
                  });

                  return Ember.RSVP.Promise.resolve();
                }
              }).finally(function () {
                if (candidates.length == candidateModel.length + _this2.get('feErrors.length')) {
                  _this2.set('uploadPercent', 100);

                  setTimeout(function () {
                    _this2.set('isUploadingCandidates', false);

                    _this2.set('uploadComplete', true);
                  }, 1000);
                }

                if (_this2.get('uploadPercent') < 75) {
                  _this2.set('uploadPercent', _this2.get('uploadPercent') + 10);
                }
              });
            });
          }, Ember.RSVP.resolve().then(function () {
            return Ember.RSVP.resolve();
          }));
        });
      },
      bulkImportCandidates: function bulkImportCandidates(property) {
        this.sendAction('bulkImportCandidates', property);
      },
      createEventCandidate: function createEventCandidate(candidates) {
        var _this3 = this;

        this.get('store').createRecord('actions/event-candidate', {
          event: this.get('model'),
          candidates: candidates
        }).save().then(function () {
          _this3.get('toast').success(candidates.length + ' candidates added successfully!');

          _this3.set('rerenderTable', true);

          _this3.send('rerenderTable', _this3.get('rerenderTable'));
        }).catch(function (e) {
          if (e.errors) {
            e.errors.forEach(function (error) {
              _this3.get('toast').error(error.detail, 'Error!');
            });
          } else {
            _this3.get('toast').error('Please try after some time', 'Something went wrong');
          }
        });
      },
      rerenderTable: function rerenderTable(property) {
        this.sendAction('rerenderTable', property);
      },
      inviteToAssessment: function inviteToAssessment(candidates) {
        if (this.get('assessmentModel')) {
          this.send('routeActions', candidates, 'candidates', 'invite to assessment', this.setActionsParmas('assessments', this.get('assessmentModel'), 'value'), this.setActionsParmas('isAssessmentFrozen', true, 'flag'), this.setActionsParmas('redirectTo', 'recruiter.candidate.index', 'flag'));
        } else {
          this.send('routeActions', candidates, 'candidates', 'invite to assessment', this.setActionsParmas('assessments', this.get('assessmentModel'), 'value'), this.setActionsParmas('isAssessmentFrozen', false, 'flag'), this.setActionsParmas('redirectTo', 'recruiter.candidate.index', 'flag'));
        }
      },
      redirect: function redirect() {
        var _this4 = this;

        this.send("bulkImportCandidates", "openBulkImport");
        Ember.run.next(function () {
          _this4.get('router').transitionTo('recruiter.events.details.candidate', _this4.get('model'));
        });
      }
    }
  });

  _exports.default = _default;
});