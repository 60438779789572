define("recruit/templates/recruiter/events/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oy6v8b8G",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"inverted-header\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"events-header\"],[7],[0,\"\\n    \"],[1,[25,\"events/event-creation-header\",null,[[\"eventModel\"],[[20,[\"model\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/events/details.hbs"
    }
  });

  _exports.default = _default;
});