define("recruit/templates/components/graphs/pie-semi-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AZyeahbT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments border-none box-shadow-none\"],[9,\"style\",\"background: #FFF !important; border: none !important; \"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"id\",\"pie-chart-container\"],[9,\"class\",\"ui segment padding-no\"],[9,\"style\",\"text-align: center; background: #FFF; clear: both;height:150px\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[25,\"gt\",[[20,[\"pieData\",\"firstObject\",\"y\"]],0],null]],null,{\"statements\":[[0,\"\\t\"],[6,\"span\"],[9,\"class\",\"text-weight-medium text-center assessment-stats-pie-info-text\"],[7],[0,\"Conversion Ratio\\n\\t  \"],[6,\"span\"],[9,\"data-content\",\"This is the ratio of selected candidates out of the total invited candidates. This ratio gives an idea of how many candidates need to be invited to an assessment to fill in one position.\"],[7],[0,\"\\n\\t\\t  \"],[1,[25,\"fa-icon\",[\"info-circle\"],[[\"class\"],[\"assessment-stats-pie-info-icon margin-left-five config-tooltip\"]]],false],[0,\"\\n\\t  \"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \\t\"],[6,\"span\"],[9,\"class\",\"text-weight-medium text-center assessment-stats-pie-info-text\"],[7],[0,\"Conversion Ratio\\n\\t  \"],[6,\"span\"],[9,\"data-content\",\"This is the ratio of selected candidates out of the total invited candidates.\"],[7],[0,\"\\n\\t\\t  \"],[1,[25,\"fa-icon\",[\"info-circle\"],[[\"class\"],[\"assessment-stats-pie-info-icon margin-left-five config-tooltip\"]]],false],[0,\"\\n\\t  \"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/graphs/pie-semi-circle.hbs"
    }
  });

  _exports.default = _default;
});