define("recruit/components/events/assessment-overview-events-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.set('showMore', false);

      if (this.get('hasLiveEvents.length')) {
        this.set('openEventsAccordion', true);
      } else {
        this.set('openEventsAccordion', false);
      }
    },
    liveEventsOrdered: Ember.computed('model.length', 'model.[]', function () {
      var orderedList = Ember.A([]);
      orderedList.pushObjects(this.get('model').toArray());
      orderedList.forEach(function (list) {
        if (list.get('status') == 4) {
          orderedList.removeObject(list);
          orderedList.unshift(list);
        }
      });
      return orderedList;
    }),
    actions: {
      toggleAccordion: function toggleAccordion(property) {
        this.toggleProperty(property);
      },
      openEvents: function openEvents(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});