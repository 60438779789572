define("recruit/services/user-role", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    currentRole: null,
    initialized: false,
    isPanel: Ember.computed.equal('currentRole', 'Evaluator'),
    isRecruiter: Ember.computed('currentRole', function () {
      return _npmLodash.default.includes(["Master Recruiter", "Recruiter"], this.get('currentRole'));
    }),
    isContentManager: Ember.computed.equal('currentRole', 'Content Manager'),
    isMasterRecruiter: Ember.computed.equal('currentRole', 'Master Recruiter'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('session.user.id')) {
        this.set('initialized', true);
        var userRoles = JSON.parse(localStorage.getItem('tv_user_roles'));
        this.set('currentRole', Ember.get(userRoles || {}, this.get('session.user.id')) || _npmLodash.default.head(this.get('session.user.roles')));

        if (!userRoles) {
          this.update(_npmLodash.default.head(this.get('session.user.roles')));
        }
      }
    },
    update: function update(role) {
      var userRoles = JSON.parse(localStorage.getItem('tv_user_roles')) || {};
      userRoles[this.get('session.user.id')] = role;
      localStorage.setItem('tv_user_roles', JSON.stringify(userRoles));
      return this.set('currentRole', role);
    }
  });

  _exports.default = _default;
});