define("recruit/models/assessment-section-candidate", ["exports", "ember-data", "moment", "npm:lodash", "ember-data-has-many-query"], function (_exports, _emberData, _moment, _npmLodash, _emberDataHasManyQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var durationList = [{
    key: '15 minutes',
    value: 15
  }, {
    key: '30 minutes',
    value: 30
  }, {
    key: '45 minutes',
    value: 45
  }, {
    key: '1 hour',
    value: 60
  }, {
    key: '2 hours',
    value: 120
  }, {
    key: '3 hours',
    value: 180
  }];

  var _default = _emberData.default.Model.extend(_emberDataHasManyQuery.default.ModelMixin, {
    timeElapsed: _emberData.default.attr('string'),
    isComplete: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    c: _emberData.default.attr('string'),
    verification_code: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('pgdate'),
    end_date: _emberData.default.attr('pgdate'),
    isRegistered: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    started_at: _emberData.default.attr('pgdate'),
    completed_at: _emberData.default.attr('pgdate'),
    evaluated_at: _emberData.default.attr('pgdate'),
    invited_at: _emberData.default.attr('pgdate'),
    score: _emberData.default.attr('string'),
    correctCount: _emberData.default.attr('number'),
    skippedCount: _emberData.default.attr('number'),
    incorrectCount: _emberData.default.attr('number'),
    positiveScore: _emberData.default.attr('number'),
    negativeScore: _emberData.default.attr('number'),
    appliedCutOff: _emberData.default.attr('number'),
    reportUrl: _emberData.default.attr('string'),
    proviewRating: _emberData.default.attr('string'),
    answers: _emberData.default.hasMany('answer'),
    statusReason: _emberData.default.belongsTo('statusReason'),
    status: _emberData.default.belongsTo('status'),
    section: _emberData.default.belongsTo('section'),
    assessment: _emberData.default.belongsTo('assessment'),
    panelCandidates: _emberData.default.hasMany('panel-candidate', {
      inverse: null
    }),
    assessmentSection: _emberData.default.belongsTo('assessment-section', {
      inverse: null
    }),
    assessmentCandidate: _emberData.default.belongsTo('assessment-candidate', {
      inverse: null
    }),
    proctorLog: _emberData.default.attr('string'),
    duration: _emberData.default.attr('string'),
    evaluations: _emberData.default.hasMany('evaluation'),
    selfEvaluations: _emberData.default.hasMany('evaluation'),
    externalInvitations: _emberData.default.hasMany('externalInvitation'),
    // liveSessions              : DS.hasMany('liveSession'),
    liveSessionsWithRecording: _emberData.default.hasMany('liveSession'),
    // formInstances             : DS.hasMany('formInstance'),
    preFormInstances: _emberData.default.hasMany('formInstance'),
    // evaluationFormInstances   : DS.hasMany('formInstance'),
    reports: _emberData.default.hasMany('file'),
    durations: durationList,
    durationPreSelected: durationList[3],
    averageRatings: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    showAnswers: Ember.computed('status', 'section', function () {
      if (this.get('isComplete') || this.get('section.isLive')) {
        return true;
      }

      return this.get('status.sort_key') == 30 && this.get('status.type') == 20;
    }),
    proviewLogColor: Ember.computed(function () {
      if (this.get('proviewRating')) {
        switch (_npmLodash.default.toLower(this.get('proviewRating'))) {
          case 'high':
            return 'text-green';

          case 'medium':
            return 'text-yellow';

          case 'low':
            return 'text-red';

          default:
            return 'text-grey';
        }
      } else {
        return 'text-grey';
      }
    }),
    blockInvite: Ember.computed('status.sort_key', 'isComplete', function () {
      return this.get('isComplete') || this.get('status.sort_key') == 30 || this.get('status.sort_key') == 20;
    }),
    enableReinvite: Ember.computed('status.sort_key', 'isComplete', function () {
      return this.get('isComplete') || this.get('status.sort_key') == 30 || this.get('status.sort_key') == 90;
    }),
    isPanelCandidateLoaded: Ember.computed('panelCandidates', function () {
      var _this = this;

      this.get('panelCandidates').then(function () {
        return _this.set('isPanelCandidateLoaded', true);
      });
    }),
    timeTaken: Ember.computed('timeElapsed', function () {
      if (this.get('timeElapsed')) {
        if ((0, _moment.default)().startOf('years').seconds(this.get('timeElapsed')).format("HH") == "00") {
          return (0, _moment.default)().startOf('years').seconds(this.get('timeElapsed')).format("mm[m]:ss[s]");
        } else {
          return (0, _moment.default)().startOf('years').seconds(this.get('timeElapsed')).format("HH[h]:mm[m]:ss[s]");
        }
      } else {
        return '--';
      }
    }),
    sortedPanelCandidates: Ember.computed('panelCandidates.[]', 'panelCandidates.isFulfilled', 'panelCandidates.firstObject.evaluations.isFulfilled', 'panelCandidates.firstObject.evaluations.[]', 'panelCandidates.@each.evaluations.isFulfilled', function () {
      if (this.get('panelCandidates.firstObject.evaluations.isFulfilled')) {
        return this.get('panelCandidates').map(function (pc) {
          if (pc.get('evaluations.length')) {
            var time = (0, _moment.default)(pc.get('evaluations.firstObject.evaluated_at')).valueOf();
            pc.set('sort_index', time);
          } else {
            pc.set('sort_index', 0);
          }

          return pc;
        }).sortBy('sort_index').reverse();
      }
    }),
    sanitizedScore: Ember.computed('score', function () {
      return this.get('score') ? this.get('score') : '--';
    }),
    isSectionParametersLoaded: Ember.computed(function () {
      var _this2 = this;

      this.get('section').then(function (section) {
        _this2.set('fetchedSection', section);

        section.get('parameters').then(function (parameters) {
          _this2.set('fetchedParam', parameters);

          _this2.set('isSectionParametersLoaded', true);
        });
      });
    }),
    hideEvaluation: Ember.computed('fetchedSection', 'fetchedParam', function () {
      if (this.get('fetchedParam') && this.get('fetchedSection')) {
        return this.get('fetchedParam.length') || this.get('fetchedSection.evaluationFormUrl');
      }
    }),
    evaluationRatings: Ember.computed(function () {
      var _this3 = this;

      this.get('evaluations').then(function (evaluations) {
        if (evaluations.get('length')) {
          evaluations.get('firstObject.evaluationRatings').then(function (evalRatings) {
            _this3.set('catagories', evalRatings.map(function (er, index) {
              return index + 1;
            }));

            _this3.set('evaluationRatings', evalRatings.map(function (er) {
              return +er.get('rating');
            }));

            var maxValue = Ember.A([]);

            _this3.set('percentageScore', Ember.A([]));

            evalRatings.map(function (er) {
              maxValue.pushObject(er.get('parameter'));
            });
            return Ember.RSVP.all(maxValue);
          }).then(function (params) {
            _this3.set('parameterMaxValue', params.map(function (p) {
              return p.get('max_value');
            }));

            params.forEach(function (p, index) {
              _this3.get('percentageScore').pushObject(_npmLodash.default.round(_this3.get('evaluationRatings').objectAt(index) * 100 / p.get('max_value'), 2));
            });

            _this3.set('isAllLoaded', true);

            return _this3.set('parameterName', params.map(function (p) {
              return p.get('name');
            }));
          });
        } else {
          _this3.set('isAllLoaded', false);
        }
      });
    }),
    evaluationComments: Ember.computed(function () {
      var _this4 = this;

      this.get('evaluations').then(function (evaluations) {
        if (evaluations.get('firstObject')) {
          evaluations.get('firstObject.evaluationComments').then(function (evalComments) {
            _this4.set('evaluationComments', evalComments.map(function (content) {
              _this4.set('evalCommentsLoaded', true);

              return content.get('content');
            }));
          });
        }
      });
    }),
    isRelationsLoaded: Ember.computed('assessmentCandidate.candidate.isFulfilled', function () {
      var _this5 = this;

      this.get('assessmentCandidate').then(function (res) {
        return res.get('candidate');
      }).then(function () {
        return _this5.get('panelCandidates');
      }).then(function () {
        return _this5.set('isRelationsLoaded', true);
      });
    }),
    // Model alias to reduce verbosity
    as: Ember.computed.alias('assessmentSection'),
    ac: Ember.computed.alias('assessmentCandidate')
  });

  _exports.default = _default;
});