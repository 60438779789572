define("recruit/components/live-session/helpers/assessment-section-details-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('showMore', false);
    },
    allAssessmentSectionCandidate: Ember.computed('model.assessmentSectionCandidate.assessmentCandidate', function () {
      return this.get('store').query('assessment-section-candidate', {
        assessment_candidate_id: this.get('model.assessmentSectionCandidate.assessmentCandidate.id')
      });
    }),
    sortedAssessmentSectionCandidates: Ember.computed('allAssessmentSectionCandidate.[]', function () {
      return this.get('allAssessmentSectionCandidate.length') ? this.get('allAssessmentSectionCandidate').toArray().reverse() : Ember.A([]);
    }),
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.accordion').accordion();
    },
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          position: 'top center'
        });
      });
    },
    actions: {
      toggleAccordion: function toggleAccordion(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});