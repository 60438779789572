define("recruit/components/answer-views/live", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentRecording: null,
    actions: {
      openVideo: function openVideo(recording) {
        this.set('currentRecording', recording);
        this.toggleProperty('openModal');
      },
      closeModal: function closeModal() {
        this.toggleProperty('openModal');
      }
    }
  });

  _exports.default = _default;
});