define("recruit/models/lf-adapter/section", ["exports", "ember-data", "recruit/models/lf-adapter/skill"], function (_exports, _emberData, _skill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _skill.default.extend({
    type: _emberData.default.attr('string'),
    type_id: _emberData.default.attr('number'),
    external_id: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    parameters: _emberData.default.hasMany('parameter'),
    sectionParameters: _emberData.default.hasMany('lf-adapter/section-parameter'),
    isForm: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isParameter: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    evaluationForm: _emberData.default.belongsTo('form'),
    sectionType: _emberData.default.belongsTo('section-type'),
    assessmentSection: _emberData.default.belongsTo('lf-adapter/assessment-section'),
    external_assessment_id: _emberData.default.attr('number'),
    questionsCount: _emberData.default.attr('number'),
    // questionType            : DS.attr('number'),
    evaluationFormUrl: _emberData.default.attr('string'),
    //for testing: , {defaultValue: "http://localhost:1313/evaluation/tech-ios/"}
    questions: _emberData.default.hasMany('question'),
    assessmentQuestions: _emberData.default.hasMany('lf-adapter/assessment-question'),
    // skills               : DS.hasMany('lf-adapter/skill'),
    questionType: _emberData.default.attr(),
    externalAssessment: _emberData.default.belongsTo('external-assessment'),
    isAutomated: Ember.computed('type_id', function () {
      return this.get('sectionType.id') == 5;
    }),
    isPearson: Ember.computed('type_id', 'sectionType', function () {
      return this.get('sectionType.id') == 19;
    }),
    isExternal: Ember.computed('type_id', 'sectionType', function () {
      return this.get('sectionType.id') == 21;
    }),
    checkIFQuestionAvailable: function checkIFQuestionAvailable(sectionTypeID) {
      if (sectionTypeID) {
        return sectionTypeID != 7 && sectionTypeID != 15 && sectionTypeID != 19 && sectionTypeID != 22 && sectionTypeID != 21;
      }
    }
  });

  _exports.default = _default;
});