define("recruit/components/reports/table/assessments/conversion-table", ["exports", "recruit/mixins/report-table-common"], function (_exports, _reportTableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTableCommon.default, {
    modelName: 'reports/assessment-conversion',
    columns: Ember.computed(function () {
      return [{
        label: 'Assessments',
        component: 'reports/helpers/assessment-header',
        classNames: ['table-head mis text-center border-right-grey'],
        cellComponent: 'reports/helpers/assessment-details',
        cellClassNames: ['text-center border-right-grey padding-vs'],
        sortable: false,
        width: '23%'
      }, {
        component: 'reports/helpers/column-with-toggle',
        classNames: ['table-head mis text-center'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        value: 'CONVERSION',
        sortable: false,
        subColumns: [{
          label: 'Total Invited',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The cumulative number of candidates invited in the specified time period.',
          valuePath: 'invitedCount',
          width: '11%'
        }, {
          label: 'Total Attempted',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates that have attempted the assessment.',
          valuePath: 'inprogressPercent',
          width: '11%'
        }, {
          label: 'Total Completed',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates that have completed the assessment.',
          valuePath: 'completedPercent',
          width: '11%'
        }, {
          label: 'Selected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates selected out of those who completed the assessment.',
          valuePath: 'selectedPercent',
          width: '11%'
        }, {
          label: 'On-hold',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates that have been put on hold.',
          valuePath: 'onHoldPercent',
          width: '11%'
        }, {
          label: 'Rejected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates rejected by the panel.',
          valuePath: 'rejectedPercent',
          width: '11%'
        }, {
          label: 'Conversion',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center'],
          subLabel: '( in %)',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The conversion of candidates (in percentage) from the invite stage to the final selection.',
          valuePath: 'conversionRate',
          width: '11%'
        }]
      }];
    }),
    actions: {
      toggleGraph: function toggleGraph() {
        this.toggleProperty('hideGraph');
      }
    }
  });

  _exports.default = _default;
});