define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-section/section-details-table", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedASObserver: Ember.observer('selectedAS', function () {
      var _this = this;

      this.set('isUnassignAcLoaded', false);
      this.get('selectedAS').query('unassigned', {
        v: (0, _moment.default)().unix()
      }).then(function (asscs) {
        if (asscs) {
          _this.set('unassignAcCount', asscs.meta.total);

          _this.set('isUnassignAcLoaded', true);

          _this.set('unassignAc', asscs.map(function (assc) {
            return assc.get('assessmentCandidate.content');
          }));
        }
      });
    }).on('init'),
    didRender: function didRender() {
      Ember.$('.custom-tooltip').popup();
    },
    actions: {
      redirectToAssc: function redirectToAssc(model, selectedAS) {
        this.get('router').transitionTo('recruiter.assessment.details.assessment-section', model, selectedAS, {
          queryParams: {
            assessmentFilters: JSON.stringify({
              id: 1,
              type: 'unassigned',
              label: 'unassigned',
              value: 1
            })
          }
        });
      }
    }
  });

  _exports.default = _default;
});