define("recruit/components/assessment/tabs/assessment-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isRatingAvailable: Ember.computed('selectedAS.section.isFulfilled', function () {
      return this.get('selectedAS.section.sectionType.id') != 6;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedAS.selectedRating', null);
      this.setProperties({
        isParameter: false,
        isForm: false,
        counter: 0
      });

      if (this.get('assessmentModel.freezeAssessment')) {
        this.toggleProperty('toggleNag');
      }
    },
    selectedASObserver: Ember.observer('selectedAS.section.isFulfilled', function () {
      var _this = this;

      this.get('selectedAS.section').then(function (section) {
        section.get('sectionParameters').then(function (parameterRatings) {
          if (parameterRatings && parameterRatings.length) {
            section.set('isParameter', true);
          } else {
            section.set('isParameter', false);
          }

          _this.setRatings();
        });
        return section;
      }).then(function (section) {
        if (section) {
          section.get('evaluationForm').then(function (form) {
            form ? form.get('isEmpty') ? section.set('isForm', false) : section.set('isForm', true) : section.set('isForm', false);

            _this.setRatings();
          });
        }
      });
    }).on('init'),
    setRatings: function () {
      var isParameter = this.get('selectedAS.section.isParameter');
      var isForm = this.get('selectedAS.section.isForm');

      if (isParameter && isForm) {
        this.set('selectedAS.selectedRating', 'form-parameters');
      } else if (isParameter) {
        this.set('selectedAS.selectedRating', 'parameters');
      } else if (isForm) {
        this.set('selectedAS.selectedRating', 'form');
      } else {
        this.set('selectedAS.selectedRating', 'recommendation');
      }

      this.incrementProperty('counter');

      if (this.get('counter') == 2) {
        this.set('renderView', true);
      }
    }.observes('selectedAS.section.isParameter', 'selectedAS.section.isForm'),
    actions: {
      showFormAlert: function showFormAlert() {
        this.toggleProperty('showAlert');
      }
    }
  });

  _exports.default = _default;
});