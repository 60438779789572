define("recruit/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "recruit/config/environment"], function (_exports, _unauthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      var subDivistions = window.location.host.split('.');

      if (subDivistions.length === 3) {
        var subDomain = subDivistions[0];

        if (subDomain.includes('cognizant')) {
          transition.abort();
          return location.replace('https://ssocognizant.talview.com');
        } else {
          return this._super.apply(this, arguments);
        }
      } else {
        return this._super.apply(this, arguments);
      }
    },
    actions: {
      adLogin: function adLogin() {
        var authContext = new AuthenticationContext({
          clientId: _environment.default.APP.AZURE_CLIENT_ID,
          redirectUri: location.origin + "/auth/aad"
        });
        authContext.login();
      }
    }
  });

  _exports.default = _default;
});