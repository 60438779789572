define("recruit/components/reports/graphs/conversion-graph", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    selectedFilters: Ember.A([]),
    setFirstRecord: Ember.observer('firstTableRecord.id', 'isLoading', function () {
      if (this.get('firstTableRecord.id') && !this.get('isLoading')) {
        this.set('showEmptyState', false);
        this.set('selectedRow', this.get('firstTableRecord'));
        this.getValues(this.get('firstTableRecord'));
      } else {
        if (!this.get('isLoading')) {
          this.set('renderGraph', true);
        }

        this.set('showEmptyState', true);
      }
    }).on('didInsertElement'),
    initialize: function initialize() {
      this.setProperties({
        'dropPercent': Ember.A([]),
        'conversionGraph': Ember.A([]),
        'graphValues': Ember.A([]),
        'hideGraph': false,
        'showEmptyState': false
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').query('user', {
        roles: 'Recruiter'
      }).then(function (res) {
        _this.set('defaultRecruiters', res.slice(0, 6));
      });
      this.get('store').query('user', {
        roles: 'Evaluator'
      }).then(function (res) {
        _this.set('defaultPanels', res.slice(0, 6));
      });
      this.get('store').findAll('assessment').then(function (res) {
        _this.set('defaultAssessments', res.slice(0, 6));
      });
      this.initialize();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('dateRecordSet')) {
        this.set('initialStartDate', this.get('startDate'));
        this.set('initialEndDate', this.get('endDate'));
      }
    },
    didRender: function didRender() {
      Ember.run(function () {
        Ember.$('.ui.selected.details, .ui.cursor-help').popup({
          position: 'bottom center'
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments); // this.set('firstTableRecord', Ember.A([]));
      // this.initialize();

    },
    showDate: Ember.computed('openCalendar', function () {
      return !this.get('openCalendar');
    }),
    _tableToggleObserver: Ember.observer('hideGraph', function () {
      var _this2 = this;

      if (!this.get('hideGraph')) {
        Ember.run.later(function () {
          _this2.getValues(_this2.get('selectedRow'));
        });
      }
    }),
    graphJsonObserver: Ember.observer('selectedRow', function () {
      var _this3 = this;

      var routeName = this.get('router.currentRouteName').split('.')[2];
      Ember.run.once(function () {
        if (_this3.get('selectedRow.id')) {
          if ((routeName == 'recruiter' || routeName == 'panel') && !_this3.get('hideFilters')) {
            _this3.get('store').findRecord('user', +_this3.get('selectedRow.id')).then(function (res) {
              _this3.set('userObject', res);
            });
          }

          if (!_this3.get('hideGraph')) {
            _this3.getValues(_this3.get('selectedRow'));
          }
        }
      });
    }),
    getValues: function getValues(res) {
      var arr;
      var initialNodes = {
        "nodes": [{
          "color": "purple",
          "gradientName": 'invitedGradient'
        }, {
          "color": "blue",
          "gradientName": 'completedGradient'
        }, {
          "color": "yellow",
          "gradientName": 'evaluatedGradient'
        }, {
          "color": "green",
          "gradientName": 'selectedGradient'
        }]
      };

      if (res) {
        arr = this.get('nodeValues').map(function (val) {
          return res.get("".concat(val));
        });
        var colors = this.get('nodeColors').split(' ');
        var nodes = initialNodes.nodes.filter(function (obj) {
          return _npmLodash.default.includes(colors, obj.color);
        });
        this.constructJSON(arr, nodes);
      }
    },
    constructJSON: function constructJSON(graphValues, nodes) {
      var _this4 = this;

      this.set('dropPercent', Ember.A([]));
      var maxValue;
      var radius = [];
      maxValue = this.findMaxValue(graphValues);

      if (graphValues.every(this.isBelowThreshold)) {
        if (graphValues.length == 4) {
          radius = Ember.A([100, 90, 80, 70]);
        } else {
          radius = Ember.A([100, 90, 80]);
        }
      } else {
        radius = this.calcRadius(graphValues, maxValue);
      }

      var totalCirlceWidth = this.calcTotal(radius);
      var containerWidth = Ember.$('#conversionGraph').innerWidth();
      nodes.forEach(function (node, index) {
        node.r = radius[index];
        node.strokeWidth = _this4.setStrokeWidth(index);

        if (index == nodes.length - 2) {
          node.dropOff = _this4.getDropOff(index, node.r);
        }

        node.originLabel = graphValues[index];
        node.centerTextPos = node.originLabel.toString().length * 10;
        node.label = _this4.get('cirlceLabels')[index];
        node.labelX = node.label.length * 4.5;
        node.labelY = parseInt(node.r) + 40;

        if (index && index < graphValues.length) {
          _this4.calcPercent(graphValues, index);
        }

        var cumulativeRadius = 0;

        for (var i = 0; i < index; i++) {
          cumulativeRadius += radius[i];
        }

        node.x = 2 * node.r + index * ((containerWidth - totalCirlceWidth) / nodes.length) + 2 * cumulativeRadius;
      });
      nodes.forEach(function (node, index) {
        node.x1 = parseInt(node.r) + 10;

        if (index < nodes.length - 1) {
          node.x2 = nodes[index + 1].x - nodes[index].x - radius[index + 1] - 20;
          node.mid = (+node.x1 + node.x2) / 2 - _this4.get('dropPercent').objectAt(index).toString().length * 5;
        }

        node.x3 = parseInt(node.x1) + 50;
      }, this);
      this.getViewBox(nodes, totalCirlceWidth, containerWidth);
      this.set('lastNode', nodes.length - 1);
      this.set('graphJson', nodes);
      this.set('renderGraph', true);
    },
    calcRadius: function calcRadius(counts, max) {
      var values = counts.map(function (val) {
        return val / max * 100;
      });
      var upperLimit = 100;
      var lowerLimit = 80;
      values.forEach(function (val, index) {
        var newUpperLimit = upperLimit - index * 5;
        var newLowerLimit = lowerLimit - index * 8;

        if (!_npmLodash.default.inRange(val, newLowerLimit, newUpperLimit)) {
          if (val >= newUpperLimit) {
            values[index] = newUpperLimit;
          }

          if (val <= lowerLimit) {
            values[index] = newLowerLimit;
          }
        }
      });
      return values;
    },
    getViewBox: function getViewBox(nodes, totalCirlceWidth, containerWidth) {
      var sum = 0;

      _npmLodash.default.forEach(nodes, function (node, index) {
        if (index < nodes.length - 1) {
          sum += node.x2 - node.x1 - nodes[index + 1].r + 25;
        } else {
          sum += node.r;
        }
      });

      var totalWidth = 2 * totalCirlceWidth + sum;
      this.set('viewBoxX', containerWidth - totalWidth);
      this.set('viewBoxWidth', window.innerWidth);
    },
    setStrokeWidth: function setStrokeWidth(index) {
      switch (index) {
        case 0:
          return 10;

        case 1:
          return 8;

        case 2:
          return 7;

        case 3:
          return 6;
      }
    },
    getDropOff: function getDropOff(index, pos) {
      return [{
        label: 'On-Hold',
        value: this.get('selectedRow.holdCount'),
        x: parseInt(pos) + 25,
        y: -105,
        arrow_x: parseInt(pos) - 2,
        arrow_y: -112,
        translateY: '-35',
        textColor: '#E0AE1E',
        triangle: 'TriangleYellow'
      }, {
        label: 'Rejected',
        value: this.get('selectedRow.rejectedCount'),
        x: parseInt(pos) + 25,
        y: 110,
        arrow_x: parseInt(pos) - 2,
        arrow_y: 103,
        translateY: '30',
        textColor: '#CA0505',
        triangle: 'TriangleRed'
      }];
    },
    findMaxValue: function findMaxValue(arr) {
      var largestValue = arr.reduce(function (a, b) {
        return Math.max(a, b);
      });
      return largestValue;
    },
    calcTotal: function calcTotal(counts) {
      var sum = 0;
      counts.forEach(function (val) {
        return sum += val;
      });
      return sum;
    },
    calcPercent: function calcPercent(arr, indexValue) {
      var percent, decimal;

      if (arr[indexValue - 1] === 0 && arr[indexValue] === 0) {
        percent = 0;
      } else {
        percent = arr[indexValue] / arr[indexValue - 1] * 100;
        decimal = percent - Math.floor(percent);
        percent = decimal == 0 ? parseInt(percent) : percent;
      }

      if (percent < 1) {
        this.get('dropPercent').pushObject(0);
      } else {
        percent == 100 || decimal == 0 ? this.get('dropPercent').pushObject(percent) : this.get('dropPercent').pushObject(percent.toFixed(2));
      }
    },
    isBelowThreshold: function isBelowThreshold(currentValue) {
      return currentValue === 0;
    },
    actions: {
      togglePopup: function togglePopup(property) {
        if (property == 'openCalendar') {
          if (this.get('openFilter')) {
            this.set('openFilter', false);
          }
        }

        if (property == 'openFilter') {
          if (this.get('openCalendar')) {
            this.set('openCalendar', false);
          }
        }

        this.toggleProperty(property);
      },
      setDateFilter: function setDateFilter(val) {
        this.set('renderGraph', true);
        this.sendAction('setDateFilter', val);
      },
      resetFilters: function resetFilters() {
        this.initialize();
      },
      setFilters: function setFilters(assessment, recruiter, panel, advanceFilters) {
        this.set('renderGraph', true);
        this.send('togglePopup', 'openFilter');
        this.sendAction('setAllFilters', assessment, recruiter, panel, advanceFilters);
      }
    }
  });

  _exports.default = _default;
});