define("recruit/components/events/panel-match-popup-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['customStyle:style'],
    customStyle: Ember.computed('threshold', function () {
      return new Ember.String.htmlSafe("right: ".concat(this.get('threshold') * 10, "px; position: relative;"));
    })
  });

  _exports.default = _default;
});