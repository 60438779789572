define("recruit/controllers/master", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      back: function back() {
        history.back();
      },
      save: function save(changeset) {
        var _this = this;

        var snapshot = changeset.snapshot();
        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              if (_this.get('timeZoneSelected')) {
                return _this.get('store').createRecord('settingsData', {
                  settings_id: 1,
                  settings_allowed_value_id: _this.get('timeZoneSelected.id')
                }).save();
              } else {
                return Ember.RSVP.resolve();
              }
            }).then(function () {
              _this.get('toast').success("Successfully Updated");
            }).catch(function (e) {
              Ember.Logger.error(e);

              var toast = _this.get('toast');

              if (!e || !e.errors) {
                toast.error('There was an error Processing your request', 'Error!');
              } else {
                e.errors.forEach(function (elem) {
                  toast.error(elem.detail, elem.field);
                });
              }

              changeset.restore(snapshot);
            });
          } else {
            _this.get('toast').error('', 'Please check the form errors');
          }
        });
      }
    }
  });

  _exports.default = _default;
});