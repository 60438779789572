define("recruit/models/event", ["exports", "ember-data", "npm:lodash", "recruit/mixins/event-status", "moment"], function (_exports, _emberData, _npmLodash, _eventStatus, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    EVENT STATUS:
    id: 1, name: 'Draft',
    id: 2, name: 'Open',
    id: 3, name: 'Canceled',
    id: 4, name: 'Live',
    id: 5, name: 'Panel Review
    id: 6, name: 'Completed',
   */
  var _default = _emberData.default.Model.extend(_eventStatus.default, {
    external_id: _emberData.default.attr('string'),
    registration_start_date: _emberData.default.attr('pgdate'),
    registration_end_date: _emberData.default.attr('pgdate'),
    registration_form_id: _emberData.default.attr('number'),
    status: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    is_public: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    title: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    location: _emberData.default.attr(),
    formUrl: _emberData.default.attr('string'),
    canSelectAssessment: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    start_date: _emberData.default.attr('pgdate'),
    end_date: _emberData.default.attr('pgdate'),
    owned_by: _emberData.default.belongsTo('user'),
    special_instructions: _emberData.default.attr('string'),
    point_of_contact: _emberData.default.attr('string'),
    panel: _emberData.default.hasMany('event-panel', {
      inverse: 'event'
    }),
    recruiter: _emberData.default.hasMany('event-recruiter', {
      inverse: 'event'
    }),
    candidates: _emberData.default.hasMany('candidate'),
    assessmentsCount: _emberData.default.hasMany('assessment'),
    eventAssessmentsCount: _emberData.default.hasMany('event-assessment'),
    eventCandidates: _emberData.default.hasMany('event-candidate'),
    // eventAssessments           : DS.hasMany('event-assessment'),
    addedCount: _emberData.default.attr('number'),
    invitedCount: _emberData.default.attr('number'),
    inProgressCount: _emberData.default.attr('number'),
    attendedCount: _emberData.default.attr('number'),
    assessedCount: _emberData.default.attr('number'),
    selectedCount: _emberData.default.attr('number'),
    holdCount: _emberData.default.attr('number'),
    rejectedCount: _emberData.default.attr('number'),
    publicUrl: _emberData.default.attr('string'),
    isEventEditable: Ember.computed('status', function () {
      return this.get('status') != 3 && this.get('status') != 6;
    }),
    isPanelReview: Ember.computed('status', function () {
      return this.get('status') == 5;
    }),
    canEditEvent: Ember.computed('userRole.currentRole', 'owned_by.id', 'isEventEditable', function () {
      return (this.get('currentUser.me.id') == this.get('owned_by.id') || this.get('userRole.currentRole') == "Master Recruiter") && this.get('isEventEditable');
    }),
    allowAddingCandidate: Ember.computed('status', function () {
      return (this.get('status') === 2 || this.get('status') === 4) && this.get('currentUser.me.id') == this.get('owned_by.id') || this.get('userRole.isRecruiter') && !this.get('isPanelReview');
    }),
    isRelationsLoaded: Ember.computed('owned_by.isFulfilled', function () {
      var _this = this;

      return this.get('owned_by').then(function () {
        return _this.set('isRelationsLoaded', true);
      }) //eslint-disable-line ember/no-side-effects
      .catch(function () {
        return _this.set('isRelationsLoaded', true);
      }); //eslint-disable-line ember/no-side-effects
    }),
    canAddRecruiter: Ember.computed('status', 'selectedStatus.id', function () {
      if (this.get('status') == 1 || this.get('status') == 2) {
        return this.get('canEditEvent');
      }
    }),
    canAddAssessment: Ember.computed('recruiter.{isFulfilled,@each.user,firstObject.user.isFulfilled}', 'selectedStatus.id', 'status', function () {
      if (this.get('status') == 1 || this.get('status') == 2) {
        if (this.get('canEditEvent')) {
          return true;
        } else if (this.get('recruiter.isFulfilled') && this.get('recruiter.firstObject.user.isFulfilled')) {
          return this.get('recruiter').filterBy('user.id', this.get('currentUser.me.id')).length;
        }
      }
    }),
    canRemindInvitePanel: Ember.computed('canAddAssessment', 'recruiter.{isFulfilled,@each.assessmentCount}', 'status', 'eventAssessmentsCount.{isFulfilled,[]}', function () {
      if (this.get('recruiter.isFulfilled') && this.get('status') != 3 && this.get('status') != 6 && this.get('eventAssessmentsCount.length')) {
        var recruiter = this.get('recruiter').findBy('user.id', this.get('currentUser.me.id'));

        if (recruiter) {
          return recruiter.get('assessmentCount');
        }

        return this.get('canEditEvent');
      }
    }),
    eventShareUrl: Ember.computed('publicUrl', function () {
      if (this.get('is_public') && this.get('publicUrl')) {
        return "https://chart.googleapis.com/chart?cht=qr&chl=".concat(this.get('publicUrl'), "&chs=80x80&chld=L|0");
      }
    }),
    _EventAssessmentsListObserver: Ember.observer('userRole.currentRole', 'eventAssessmentsCount.isFulfilled', 'eventAssessmentsCount.[]', 'eventAssessmentsCount.lastObject.assessmentExpanded.isFulfilled', 'eventAssessmentsCount.@each.assessmentExpanded.owned_by.isFulfilled', function () {
      var _this2 = this;

      this.set('eventAssessmentsList', Ember.A([]));
      this.get('eventAssessmentsCount').then(function (eventAssessments) {
        Ember.RSVP.all(eventAssessments.map(function (eventAssessment) {
          return eventAssessment;
        })).then(function (eventAssessments) {
          var sortedAssessments = _npmLodash.default.sortBy(eventAssessments, function (eventAssessment) {
            return eventAssessment.get('assessmentExpanded.owned_by.id') != _this2.get('currentUser.me.id');
          });

          if (_this2.get('userRole.currentRole') == 'Recruiter' && _this2.get('eventAssessmentsCount.lastObject.assessmentExpanded.isFulfilled')) {
            return _this2.set('eventAssessmentsList', sortedAssessments.filter(function (assessment) {
              return _this2.get('currentUser.me.id') === assessment.get('assessmentExpanded.owned_by.id') || _this2.get('currentUser.me.id') === _this2.get('owned_by.id');
            }));
          } else if (_this2.get('eventAssessmentsCount.lastObject.assessmentExpanded.isFulfilled')) {
            return _this2.set('eventAssessmentsList', sortedAssessments);
          }
        }).catch(function () {
          _this2.get('toast').error('Something went wrong');
        });
      });
    }),
    reloadEventRelations: function reloadEventRelations() {
      var _this3 = this;

      return this.get('store').query('event-assessment', {
        event_id: this.get('id'),
        v: (0, _moment.default)().unix(),
        expand: ["addedCount", "declinedCount", "invitedCount", "inProgressCount", "attendedCount", "assessedCount", "selectedCount", "holdCount", "rejectedCount", "assessmentExpanded"].join(',')
      }).then(function () {
        return _this3.get('store').query('event-recruiter', {
          event_id: _this3.get('id'),
          v: (0, _moment.default)().unix(),
          role: "Recruiter"
        });
      }).then(function () {
        return _this3.get('store').query('event-panel', {
          event_id: _this3.get('id'),
          v: (0, _moment.default)().unix(),
          role: "Evaluator"
        });
      }).then(function () {
        return _this3.get('store').query('event', {
          id: _this3.get('id'),
          v: (0, _moment.default)().unix()
        });
      });
    },
    sortedPanels: Ember.computed('panel.{[],isFulfilled,@each.status}', function () {
      if (this.get('panel.isFulfilled') && this.get('panel.length')) {
        var panels = this.get('panel').map(function (panel) {
          panel.set('sort_index', panel.get('status') == 2 ? 1 : panel.get('status') == 1 ? 2 : 3);
          return panel;
        });
        return _npmLodash.default.sortBy(panels, 'sort_index');
      }
    })
  });

  _exports.default = _default;
});