define("recruit/templates/components/live-session/scheduling/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J4uD1FWm",
    "block": "{\"symbols\":[\"occurrence\",\"timetable\",\"calendar\",\"&default\"],\"statements\":[[4,\"as-calendar\",null,[[\"title\",\"occurrences\",\"showHeader\",\"startFromDate\",\"timeZoneOptions\",\"dayStartingTime\",\"dayEndingTime\",\"defaultOccurrenceTitle\",\"timeSlotDuration\",\"timeSlotHeight\",\"onAddOccurrence\",\"onNavigateWeek\"],[\"Schedule Live\",[20,[\"occurrences\"]],false,true,[20,[\"timeZoneList\"]],\"00:00\",\"23:59\",\"Schedule\",\"0:30\",15,[25,\"action\",[[19,0,[]],\"calendarAddOccurrence\"],null],[25,\"action\",[[19,0,[]],\"calendarNavigateWeek\"],null]]],{\"statements\":[[0,\"  \"],[1,[25,\"live-session/scheduling/calendar-occurrence\",null,[[\"class\",\"model\",\"calendar\",\"liveSession\",\"timeZone\",\"timeSlotHeight\",\"timetable\",\"timeSlotDuration\",\"isResizable\",\"isDraggable\",\"createSession\",\"revertModel\"],[[19,1,[\"content\",\"bgClass\"]],[19,1,[]],[19,3,[]],[20,[\"liveSession\"]],[19,3,[\"timeZone\"]],[19,3,[\"timeSlotHeight\"]],[19,2,[]],[19,3,[\"timeSlotDuration\"]],false,false,\"createSession\",\"revertModel\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2,3]},null],[11,4],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-session/scheduling/calendar.hbs"
    }
  });

  _exports.default = _default;
});