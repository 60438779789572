define("recruit/components/helpers/custom-field", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['text-center'],
    customFieldValue: Ember.computed('value.[]', function () {
      if (this.get('value')) {
        var cf = _npmLodash.default.find(this.get('value'), ['key', this.get('column').customField]);

        return cf ? cf.value != null ? cf.value : '--' : '--';
      } else {
        return '--';
      }
    })
  });

  _exports.default = _default;
});