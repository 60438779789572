define("recruit/templates/components/helpers/detailed-time-on-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7J7x1XxB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"span\"],[10,\"data-tooltip\",[25,\"moment-format\",[[20,[\"dateTime\"]],\"hh:mm A\"],null],null],[7],[0,\"\\n  \"],[1,[25,\"moment-format\",[[20,[\"dateTime\"]],\"DD-MMM-YY\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/detailed-time-on-hover.hbs"
    }
  });

  _exports.default = _default;
});