define("recruit/components/helpers/searchable-dropdown", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    listSource: Ember.computed('s', 'section_type_id', function () {
      if (this.get('peekModel')) {
        return this.get('store').peekAll(this.get('modelName'));
      } else {
        var query = _npmLodash.default.omitBy(this.getProperties(['roles', 's', 'is_template', 'section_type_id', 'expand']), _npmLodash.default.isNil);

        _npmLodash.default.merge(query, this.get('modelQuery') || {});

        return this.get('store').query(this.get('modelName'), query);
      }
    }),
    willDestroyElement: function willDestroyElement() {
      if (this.get('listSource.length')) {
        this.get('listSource').forEach(function (item) {
          if (_npmLodash.default.isObject(item) && _npmLodash.default.has(item, 'disabled')) {
            item.set('disabled', false);
          }
        });
      }
    },
    actions: {
      searchAction: function searchAction(searchText) {
        this.set('s', _npmLodash.default.trim(searchText));
      },
      getValue: function getValue(value) {
        this.sendAction('actionName', value, this.get('roles'));
      },
      customAction: function customAction() {
        this.sendAction('customAction');
      },
      disabledOption: function disabledOption() {
        var _this = this;

        if (this.get('disabledItems.length')) {
          if (this.get('listSource.length')) {
            this.get('listSource').forEach(function (item) {
              var object = _this.get('disabledItems').findBy('id', item.get('id'));

              if (object) {
                object.set('disabled', true);
              } else {
                item.set('disabled', false);
              }
            });
          }
        }
      },
      handleBlur: function handleBlur() {
        if (this.get('onblurAction')) {
          this.sendAction('onblurAction');
        }
      },
      onclose: function onclose() {
        this.set('s', null);
      }
    }
  });

  _exports.default = _default;
});