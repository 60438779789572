define("recruit/components/recruiter-view/assessment/helpers/forms-data-view", ["exports", "npm:lodash", "recruit/components/recruiter-view/assessment/helpers/base"], function (_exports, _npmLodash, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    fieldData: Ember.computed('selectedEvaluation', 'row.currentPanelId', 'value.isFulfilled', 'value.@each.formInstance', 'pc', function () {
      var _this = this;

      var selectedEvaluation = this.get('selectedEvaluation');

      if (selectedEvaluation) {
        selectedEvaluation.get('formInstance').then(function (formInstance) {
          if (formInstance.get('length')) {
            var data = formInstance.findBy('form_id', +_this.get('column.formId')).get('formData');
            return _npmLodash.default.find(data, function (field) {
              return field.label.toLowerCase() == _this.get('column.label').toLowerCase() ? _this.set('fieldData', field.value) : null;
            });
          }
        });
      }
    })
  });

  _exports.default = _default;
});