define("recruit/controllers/user/settings/security", ["exports", "recruit/controllers/master"], function (_exports, _master) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _master.default.extend({
    canScan: true,
    // tfaChecked: true,
    actions: {
      toggleThis: function toggleThis(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});