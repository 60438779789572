define("recruit/components/live-schedule/calendar-card", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('eventSet', Ember.A([]));
    },
    didInsertElement: function didInsertElement() {
      Ember.run.next('afterRender', function () {
        Ember.$('.live-schedule-calendar-card').popup();
      });
    },
    findLongEvent: function findLongEvent(rangeStart, rangeEnd, startOfDay, endOfDay) {
      return this.get('events').filter(function (calendarEvent) {
        var start = calendarEvent.get('from').valueOf();
        var end = calendarEvent.get('to').valueOf();
        return startOfDay < start && start < rangeStart && rangeStart < end && end < endOfDay;
      });
    },
    userEventObserver: Ember.observer('time', 'events.[]', function () {
      var _this = this;

      if (this.get('events')) {
        this.set('columnWidth', Ember.$(this.get('element')).innerWidth());
        this.get('loadingEvents', true);
        this.get('events').forEach(function (calendarEvent) {
          var startAt = calendarEvent.get('from').valueOf();
          var endAt = calendarEvent.get('to').valueOf();

          var rangeStart = _this.get('time').valueOf();

          var rangeEnd = rangeStart + 3600000;
          var startOfDay = (0, _moment.default)(_this.get('time')).startOf('days').valueOf();
          var endOfDay = (0, _moment.default)(_this.get('time')).endOf('days').valueOf();

          if (startAt >= rangeStart && startAt < rangeEnd) {
            var preEvent = _this.get('eventSet').find(function (item) {
              if (item.get('startAt') == startAt && item.get('endAt') == endAt) {
                return item;
              }
            });

            if (!preEvent) {
              var overlappingEvents = _this.findLongEvent(rangeStart, rangeEnd, startOfDay, endOfDay);

              var tabLength = _this.get('eventSet.length') + overlappingEvents.get('length'); //calculating position of card

              var top = 100 * (startAt - rangeStart) / (rangeEnd - rangeStart);
              var calcHeight = 100 * (endAt - startAt) / (rangeEnd - rangeStart);
              var height = _this.get('allDayEvent.length') ? 100 : calcHeight < 50 ? 50 : calcHeight;
              var lineHeight = height <= 50 ? '15px' : '30px';
              var left = tabLength > 0 ? tabLength * 30 : 0;
              left = left > _this.get('columnWidth') - 40 ? _this.get('columnWidth') - 40 : left;

              var zIndex = _this.get('eventSet.length');

              var eventRange = "".concat((0, _moment.default)(startAt).format('h:mm'), "-").concat((0, _moment.default)(endAt).format('h:mm a'));

              _this.get('eventSet').pushObject(Ember.Object.create({
                startAt: startAt,
                endAt: endAt,
                eventContent: calendarEvent,
                eventRange: eventRange,
                cardStyle: Ember.String.htmlSafe("top: ".concat(top, "%; height: ").concat(height, "%; line-height:").concat(lineHeight, "; left: ").concat(left, "px; width:calc(100% - ").concat(left, "px) !important; z-index:").concat(zIndex))
              }));
            }
          }
        });
        this.get('loadingEvents', false);
      }
    }).on('init')
  });

  _exports.default = _default;
});