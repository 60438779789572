define("recruit/models/live-schedule-occurrence", ["exports", "ember-data", "moment", "npm:lodash"], function (_exports, _emberData, _moment, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var durationList = [{
    key: '15 minutes',
    value: 15
  }, {
    key: '30 minutes',
    value: 30
  }, {
    key: '45 minutes',
    value: 45
  }, {
    key: '1 hour',
    value: 60
  }, {
    key: '1 hour 30 minutes',
    value: 90
  }, {
    key: '2 hours',
    value: 120
  }];

  var _default = _emberData.default.Model.extend({
    from: _emberData.default.attr('pgdate'),
    to: _emberData.default.attr('pgdate'),
    event: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    date: _emberData.default.attr('pgdate'),
    user: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    startsAt: Ember.computed.alias('from'),
    endsAt: Ember.computed.alias('to'),
    title: Ember.computed.alias('event'),
    endTime: _emberData.default.attr('pgdate'),
    isOffline: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    location: _emberData.default.attr(),
    durations: durationList,
    durationSelected: _emberData.default.attr(),
    durationPreSelected: Ember.computed('to', function () {
      if (this.get('to')) {
        var from = (0, _moment.default)(this.get('from')).hours();
        var to = (0, _moment.default)(this.get('to')).hours();
        var min = (to - from) * 60 - Math.abs((0, _moment.default)(this.get('from')).minutes() - (0, _moment.default)(this.get('to')).minutes());

        if (min > 0) {
          var key = _npmLodash.default.findKey(durationList, {
            value: min
          });

          return durationList[key];
        } else {
          return durationList[3];
        }
      } else {
        return durationList[3];
      }
    }),
    bgClass: Ember.computed('type', function () {
      return this.get('type') === 2 ? 'available' : 'booked';
    }),
    formattedDate: Ember.computed('date', function () {
      if (this.get('date')) {
        return (0, _moment.default)(this.get('date')).format('MMM DD, YYYY');
      } else {
        return null;
      }
    }),
    formattedFrom: Ember.computed('from', function () {
      if (this.get('from')) {
        return (0, _moment.default)(this.get('from')).format('hh:mm A');
      } else {
        return null;
      }
    }),
    formattedTo: Ember.computed('to', function () {
      if (this.get('to')) {
        return (0, _moment.default)(this.get('to')).format('hh:mm A');
      } else {
        return null;
      }
    }),
    sessionStartTime: Ember.computed('date', 'from', function () {
      var startTime = (0, _moment.default)(this.get('from'));
      var date = (0, _moment.default)(this.get('date')).startOf('day').add(startTime.hours(), 'hours').add(startTime.minutes(), 'minutes').add(startTime.seconds(), 'seconds').toDate();
      return date;
    }),
    sessionEndTime: Ember.computed('date', 'to', function () {
      var endTime = (0, _moment.default)(this.get('to'));
      var date = (0, _moment.default)(this.get('date')).startOf('day').add(endTime.hours(), 'hours').add(endTime.minutes(), 'minutes').add(endTime.seconds(), 'seconds').toDate();
      return date;
    })
  });

  _exports.default = _default;
});