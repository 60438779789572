define("recruit/components/reports/reports-sidebar", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    setActive: Ember.observer('currentPathName', function () {
      this.setActiveClass();
    }),
    advanceReport: Ember.computed(function () {
      return this.get('store').query('custom-field-master', {
        'type': 1
      });
    }),
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.accordion').accordion();
      this.set('showText', false);
      this.set('toggleSidebar', false);
      this.setActiveClass();
    },
    setActiveClass: function setActiveClass() {
      var activeElem = Ember.$('.reports-sidebar').find('.reports-active');

      if (activeElem.length) {
        Ember.$('.reports-sidebar').find('.reports-active').parents().eq(0).next().css('margin-top', '0px');
        activeElem.removeClass('reports-active closed');
      }

      var routeName = this.get('currentPathName').split('.')[2];

      if (routeName) {
        Ember.$("#".concat(routeName, " .reports-sidebar-options")).addClass('reports-active');

        if (!this.get('toggleSidebar')) {
          Ember.$('.reports-sidebar').find('.reports-active').addClass('closed');
          Ember.$('.reports-sidebar').find('.reports-active').parents().eq(0).next().css('margin-top', '66px');
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._toggleText);
      Ember.run.cancel(this._toggleClasses);
    },
    actions: {
      toggleSidebar: function toggleSidebar(expanded) {
        var _this = this;

        if (!expanded) {
          Ember.$('.reports-sidebar').find('.reports-active').removeClass('closed');
          Ember.$('.reports-sidebar').find('.reports-active').parents().eq(0).next().css('margin-top', '0px');
          var contentElem = Ember.$('.reports-list.content');
          var titleElem = Ember.$('.title');
          this.toggleProperty('toggleSidebar');

          if (contentElem.hasClass('active')) {
            contentElem.removeClass('active');
            titleElem.removeClass('active');
          }

          Ember.run.cancel(this._toggleClasses);

          if (this.get('toggleSidebar')) {
            this._toggleText = Ember.run.later(this, function () {
              _this.set('showText', true);
            }, 300);
          } else {
            Ember.run.cancel(this._toggleText);
            this._toggleClasses = Ember.run.later(this, function () {
              Ember.$('.reports-sidebar').find('.reports-active').addClass('closed');
              Ember.$('.reports-sidebar').find('.reports-active').parents().eq(0).next().css('margin-top', '66px');
            }, 300);
            this.set('showText', false);
          }
        }
      },
      redirect: function redirect(link) {
        this.get('router').transitionTo(link);
      }
    }
  });

  _exports.default = _default;
});