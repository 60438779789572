define("recruit/templates/recruiter/assessment/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j41dsLOW",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Assessment\"],null],false],[0,\"\\n\"],[1,[25,\"page-title\",[[20,[\"model\",\"title\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[20,[\"invertedHeader\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"recruiter-view/assessment/assessment-overview/overview-header\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"invertedHeader\"]],\"inverted-view-margin\"],null]]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column padding-no\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"padding-no margin-ten\"],[9,\"style\",\"margin-top: 0; background: transparent;\"],[7],[0,\"\\n        \"],[1,[18,\"liquid-outlet\"],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/assessment/details.hbs"
    }
  });

  _exports.default = _default;
});