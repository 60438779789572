define("recruit/components/candidate-helpers/candidate-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: Ember.computed('value', 'row', function () {
      return this.get('value') || this.get('row.content');
    })
  });

  _exports.default = _default;
});