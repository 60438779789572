define("recruit/templates/components/recruiter-view/assessment/helpers/recommendation-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QEeRjzpj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"status-helpers/evaluation\",null,[[\"value\"],[[20,[\"evaluationStatus\"]]]]],false],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/helpers/recommendation-view.hbs"
    }
  });

  _exports.default = _default;
});