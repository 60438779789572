define("recruit/services/custom-ajax", ["exports", "ember-ajax/services/ajax", "recruit/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: Ember.computed({
      get: function get() {
        return _environment.default.APP.API_ROOT == '/api' ? location.origin + '/api' : _environment.default.APP.API_ROOT;
      }
    }),
    headers: Ember.computed({
      get: function get() {
        var headers = {};
        var authToken = 'Bearer ' + this.get('session.session.content.authenticated.access_token');

        if (authToken) {
          headers['authorization'] = authToken;
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});