define("recruit/templates/components/candidate-auth/log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vu6I+X1L",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"td\"],[7],[1,[20,[\"log\",\"description\"]],false],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"text-center\"],[7],[1,[25,\"moment-format\",[[20,[\"log\",\"created_at\"]],\"DD-MMM-YY | HH:mm A\"],null],false],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"text-center\"],[7],[1,[20,[\"log\",\"formattedMatch\"]],false],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui record images\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"class\",\"ui image\"],[9,\"src\",\"/images/icons/picture.svg\"],[10,\"data-html\",[26,[\"<img src=\",[20,[\"log\",\"file\",\"resourceUrl\"]],\"  height='200px' class='padding: 0px !important;'/>\"]]],[7],[8],[0,\"\\n\"],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-auth/log.hbs"
    }
  });

  _exports.default = _default;
});