define("recruit/templates/components/helpers/multiple-checkboxs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SoaIMrSH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"helpers/dynamic-checkbox\",null,[[\"labelName\",\"value\",\"isChecked\",\"disabled\",\"hideTitle\",\"hideTooltip\",\"actionName\"],[[20,[\"labelName\"]],[20,[\"field\"]],[20,[\"isChecked\"]],[20,[\"disabled\"]],[20,[\"hideTitle\"]],[20,[\"hideTooltip\"]],[25,\"action\",[[19,0,[]],\"setValue\"],null]]]],false],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/multiple-checkboxs.hbs"
    }
  });

  _exports.default = _default;
});