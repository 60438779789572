define("recruit/components/recruiter-view/actions/change-owner", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    owner: null,
    disabledOwners: Ember.computed('model.assessments.firstObject.owned_by', function () {
      var owners = Ember.A([]);
      owners.pushObject(this.get('model.assessments.firstObject.owned_by'));
      return owners;
    }),
    didRender: function didRender() {
      Ember.$('[data-content]').popup();
    },
    actions: {
      remove: function remove() {
        this.set('owner', null);
      },
      changeOwner: function changeOwner() {
        var _this = this;

        this.set('isLoading', true);
        var assessment = this.get('selectedListValues.firstObject');
        assessment.set('owned_by', this.get('owner')); //User Id

        assessment.save().then(function () {
          return _this.get('toast').success("Assessment Owner changed successfully.", "Success!");
        }).then(function () {
          return _this.send('back');
        }).catch(function (e) {
          _this.catchError(e);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});