define("recruit/components/recruiter-view/questions/bulk-create/bulk-import-review-index", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentQuestionIndex', 0);
      this.set('questionImported', 0);
      this.set('files', Ember.A([]));
    },
    didInsertElement: function didInsertElement() {
      this.set('selectedSkills', Ember.A([]));
      this.set('preSelectedSkills', Ember.A([]));
    },
    currentQuestion: Ember.computed('currentQuestionIndex', function () {
      return this.get('questionsArray').objectAt(this.get('currentQuestionIndex'));
    }),
    actions: {
      removeSkill: function removeSkill(skill, index) {
        this.get('selectedSkills').removeAt(index);

        if (skill.get('isDeleteable')) {
          this.get('preSelectedSkills').removeAt(index);
        }

        skill.rollbackAttributes();
      },
      goBack: function goBack() {
        this.toggleProperty('cancelReview');
        this.get('toast').success('Question Review Cancelled!');
        this.get('router').transitionTo('recruiter.questions.index');
      },
      selectSkills: function selectSkills(skill) {
        this.get('selectedSkills').pushObject(skill);

        if (!this.get('preSelectedSkills').findBy('id', skill.get('id'))) {
          skill.set('isDeleteable', true);
          this.get('preSelectedSkills').pushObject(skill);
        }
      },
      discardQuestion: function discardQuestion() {
        this.get('toast').success('Question has beed discarded!');
        this.incrementProperty('currentQuestionIndex');
      },
      saveQuestion: function saveQuestion(changeset, skills, files) {
        var _this = this;

        switch (_npmLodash.default.toLower(changeset.get('type'))) {
          case 'automated':
            changeset.set('type', "interview");
            break;

          case 'mcq':
            changeset.set('type', "multiple choice");
            break;
        }

        changeset.save().then(function (createdQuestion) {
          if (Ember.get(skills, 'length') || Ember.get(files, 'length')) {
            var allFiles = files.map(function (file) {
              return _this.get('store').createRecord('question-file', {
                file: file,
                question: createdQuestion
              }).save();
            });
            var allSkills = skills.map(function (skill) {
              return _this.get('store').createRecord('question-skill', {
                skill: skill,
                question: createdQuestion
              }).save();
            });

            var promiseArray = _npmLodash.default.concat(allSkills, allFiles);

            Ember.RSVP.Promise.all(promiseArray).then(function () {
              _this.get('toast').success('Question Imported successfully', 'Success');

              _this.incrementProperty('currentQuestionIndex');

              _this.incrementProperty('questionImported');
            });
          } else {
            _this.get('toast').success('Question Imported successfully', 'Success');

            _this.incrementProperty('currentQuestionIndex');

            _this.incrementProperty('questionImported');
          }
        }).catch(function (error) {
          if (error.errors) {
            error.errors.forEach(function (e) {
              _this.get('toast').error(e.detail, 'Error');
            });
          } else {
            _this.get('toast').error('Please try after sometime', 'Something went wrong!');
          }
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});