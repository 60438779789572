define("recruit/mixins/high-chart-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    contentDidChange: Ember.observer('selectedRow', function () {
      this._super.apply(this, arguments);

      if (this.get('selectedRow')) {
        var chart = this.get('chart');
        chart.chartOptions.series[0].update({
          name: 'seriesName',
          data: this.get('selectedRow')
        }, false);
        chart.redraw();
      }
    })
  });

  _exports.default = _default;
});