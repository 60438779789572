define("recruit/models/event-candidate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "event": _emberData.default.belongsTo('event'),
    "candidate": _emberData.default.belongsTo('candidate'),
    "assessmentName": _emberData.default.attr('string'),
    "created_at": _emberData.default.attr('pgdate'),
    "created_by": _emberData.default.belongsTo('user'),
    "updated_at": _emberData.default.attr('pgdate'),
    "assessmentCandidate": _emberData.default.belongsTo('assessment-candidate'),
    "formInstance": _emberData.default.belongsTo('formInstance'),
    "updated_by": _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});