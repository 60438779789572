define("recruit/components/recruiter-view/assessment/filters/assessment-filter", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sectionList: Ember.computed('assessmentSections', function () {
      var list = Ember.A([]);

      if (this.get('assessmentSections.length') > 1) {
        list.pushObject(Ember.Object.create({
          section: {
            title: 'Any'
          }
        }));
      }

      this.get('assessmentSections').forEach(function (section) {
        return list.pushObject(section);
      });
      return list;
    }),
    defaultPanelObserver: Ember.observer('panelSearchText', function () {
      var _this = this;

      if (this.get('panelSearchText')) {
        this.set('panelList', this.get('defaultPanels').filter(function (user) {
          return user.get('name').toLowerCase().includes(_this.get('panelSearchText').toLowerCase().trimStart());
        }));

        if (this.get('addedFilters.panel.length')) {
          this.get('addedFilters.panel').forEach(function (user) {
            _this.get('panelList').unshift(_this.get('defaultPanels').findBy('id', user.get('id')));
          });
          this.set('panelList', this.get('panelList').uniqBy('id'));
        }
      } else {
        this.set('panelList', this.get('defaultPanels'));
      }
    }),
    showAddedFilters: Ember.computed('sectionFilters.appliedFilters.[]', 'addedFilters.finalStatus.[]', 'dateFilters.values.[]', 'addedFilters.panel.[]', 'addedFilters.proviewRatings.[]', 'addedFilters.eventList.[]', 'addedFilters.advanceFilters.[]', 'addedFilters.recommendation.[]', 'addedFilters.evalDate.[]', function () {
      return this.get('sectionFilters.appliedFilters.length') || this.get('addedFilters.finalStatus.length') || this.get('dateFilters.values.length') || this.get('addedFilters.panel.length') || this.get('addedFilters.proviewRatings.length') || this.get('addedFilters.eventList.length') || this.get('addedFilters.advanceFilters.length') || this.get('addedFilters.recommendation.length') || this.get('addedFilters.evalDate.length');
    }),
    showProctorRatingFilter: Ember.computed('model.assessmentSections.isFulfilled', function () {
      if (this.get('model.assessmentSections')) {
        var proctoredArray = this.get('model.assessmentSections').map(function (as) {
          return as.get('is_proctor');
        });
        return _npmLodash.default.includes(proctoredArray, true);
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments); // if(this.get('panelFilters.values').length){
      //   this.get('panelFilters.values').forEach((panel)=>{
      //     if(!this.get('defaultPanels').findBy('id',panel.get('id'))){
      //       panel.set('isDeletable', true)
      //       this.get('defaultPanels').pushObject(panel)
      //     }
      //   })
      // }


      this.setProperties({
        'addedFilters.panel': Ember.A([]),
        'addedFilters.finalStatus': Ember.A([]),
        'addedFilters.proviewRatings': Ember.A([]),
        'addedFilters.recommendation': Ember.A([]),
        'addedFilters.unassigned': Ember.A([]),
        'addedFilters.evalDate': Ember.A([]),
        'addedFilters.events': Ember.A([]),
        'addedFilters.advanceFilters': Ember.A([]),
        'proviewRatings': Ember.A([Ember.Object.create({
          id: 1,
          label: 'High',
          value: false
        }), Ember.Object.create({
          id: 2,
          label: 'Medium',
          value: false
        }), Ember.Object.create({
          id: 3,
          label: 'Low',
          value: false
        })]),
        'recommendation': Ember.A([Ember.Object.create({
          sort_key: 70,
          id: 6,
          label: 'Selected',
          type: 10,
          value: 'select'
        }), Ember.Object.create({
          sort_key: 90,
          id: 8,
          label: 'Rejected',
          type: 10,
          value: 'reject'
        }), Ember.Object.create({
          sort_key: 80,
          id: 7,
          label: 'Hold',
          type: 10,
          value: 'hold'
        })])
      });

      if (this.get('appliedListFilters.recommendation') && this.get('appliedListFilters.recommendation.length')) {
        this.get('appliedListFilters.recommendation').forEach(function (rec) {
          if (!_this2.get('addedFilters.recommendation').findBy('id', rec.get('id'))) {
            _this2.get('addedFilters.recommendation').pushObject(rec);
          }
        });
      }

      if (this.get('appliedListFilters.unassigned') && this.get('appliedListFilters.unassigned.length')) {
        this.get('appliedListFilters.unassigned').forEach(function (u) {
          if (!_this2.get('addedFilters.unassigned').findBy('id', u.get('id'))) {
            _this2.get('addedFilters.unassigned').pushObject(u);
          }
        });
      }

      if (this.get('appliedListFilters.panel') && this.get('appliedListFilters.panel.length')) {
        this.get('appliedListFilters.panel').forEach(function (user) {
          if (!_this2.get('addedFilters.panel').findBy('id', user.get('id'))) {
            _this2.get('addedFilters.panel').pushObject(user);
          }
        });
      }

      if (this.get('appliedListFilters.finalStatus') && this.get('appliedListFilters.finalStatus.length')) {
        this.get('appliedListFilters.finalStatus').forEach(function (status) {
          if (!_this2.get('addedFilters.finalStatus').findBy('sort_key', status.get('sort_key'))) {
            _this2.get('addedFilters.finalStatus').pushObject(status);
          }
        });
      }

      if (this.get('appliedListFilters.proviewRatings') && this.get('appliedListFilters.proviewRatings.length')) {
        this.get('appliedListFilters.proviewRatings').forEach(function (rating) {
          if (!_this2.get('addedFilters.proviewRatings').findBy('id', rating.get('id'))) {
            _this2.get('addedFilters.proviewRatings').pushObject(rating);
          }
        });
      }

      if (this.get('appliedListFilters.event') && this.get('appliedListFilters.event.length')) {
        this.get('appliedListFilters.event').forEach(function (event) {
          if (!_this2.get('addedFilters.events').findBy('id', event.get('id'))) {
            _this2.get('addedFilters.events').pushObject(event);
          }
        });
      }

      if (this.get('appliedListFilters.evalDate') && this.get('appliedListFilters.evalDate.length')) {
        this.get('appliedListFilters.evalDate').forEach(function (date) {
          if (!_this2.get('addedFilters.evalDate').findBy('id', date.get('id'))) {
            _this2.get('addedFilters.evalDate').pushObject(date);
          }
        });
      }

      if (this.get('appliedListFilters.advanceFilters') && this.get('appliedListFilters.advanceFilters.length')) {
        this.get('appliedListFilters.advanceFilters').forEach(function (filter) {
          if (!_this2.get('addedFilters.advanceFilters').findBy('id', filter.get('id'))) {
            _this2.get('addedFilters.advanceFilters').pushObject(filter);
          }
        });
      }

      var filters = this.get('store').peekAll('filter/master');
      var masterFilters = Ember.A([]);

      if (this.get('model.tbiQcEnabled') || this.get('hasResumeMatch') || this.get('showProctorRatingFilter')) {
        //Pushing TBI filters in advance filters if enabled
        if (this.get('model.tbiQcEnabled')) {
          this.constructAdvanceFilters('tbi', filters, masterFilters);
        } //Pushing Skillate filters in advance filters if enabled


        if (this.get('hasResumeMatch')) {
          this.constructAdvanceFilters('skillate', filters, masterFilters);
        } //Pushing proview filters in advance filters if enabled


        if (this.get('showProctorRatingFilter')) {
          this.constructAdvanceFilters('proview', filters, masterFilters);
        }

        this.set('masterFilters', masterFilters);
      }
    },
    constructAdvanceFilters: function constructAdvanceFilters(type, filters, masterFilters) {
      filters.forEach(function (item) {
        if (item.get('objectType') === 'assessment-filters' && item.get('options.filterType') === type) {
          if (!_npmLodash.default.find(masterFilters, ['id', item.get('id')])) {
            masterFilters.pushObject(item);
          }
        }
      });
    },
    updateFilterObject: function updateFilterObject(createdFilter, selectedFilterObject, value, as) {
      createdFilter.set('filterValues', {
        asID: as.get('id'),
        value: value.get('id') ? value.get('id') : value.get('value')
      });
      createdFilter.set('formattedFilterValue', value.get('key'));
      createdFilter.set('sectionName', as.get('section.title'));
      return Ember.RSVP.resolve(createdFilter);
    },
    findOrCreate: function findOrCreate(selectedFilter) {
      var _this3 = this;

      return this.get('store').query('filter/applied', {
        filter: selectedFilter,
        objectType: selectedFilter.get('objectType')
      }).then(function (appliedFilters) {
        return Ember.RSVP.resolve(appliedFilters.forEach(function (filter) {
          return filter.destroyRecord();
        }));
      }).then(function () {
        var newRecord = _this3.get('store').createRecord('filter/applied', {
          filter: selectedFilter,
          objectType: selectedFilter.get('objectType')
        });

        return Ember.RSVP.resolve(newRecord);
      });
    },
    // updateProviewFilter(createdFilter, selectedFilterObject, filter) {
    //   createdFilter.set('filter', filter);
    //   createdFilter.set('filterValues', {value:  selectedFilterObject.value})
    //   createdFilter.set('formattedFilterValue', selectedFilterObject.key);
    //   return Ember.RSVP.resolve(createdFilter);
    // },
    actions: {
      addPanel: function addPanel(checked, panel) {
        var old = this.get('addedFilters.panel').findBy('id', panel.get('id'));

        if (checked && !old) {
          this.get('addedFilters.panel').pushObject(Ember.Object.create({
            type: 'panel',
            id: panel.get('id'),
            label: panel.get('name'),
            nameWithEmail: panel.get('nameWithEmail')
          }));
        } else if (this.get('addedFilters.panel.length')) {
          this.get('addedFilters.panel').removeObject(old);
        }

        if (this.get('panelSearchText')) {
          this.send('clearSearchText');
        }
      },
      addEvents: function addEvents(checked, event) {
        var old = this.get('addedFilters.events').findBy('id', event.get('id'));

        if (checked && !old) {
          this.get('addedFilters.events').pushObject(Ember.Object.create({
            type: 'event',
            id: event.get('id'),
            label: event.get('title')
          }));
        } else if (this.get('addedFilters.events.length')) {
          this.get('addedFilters.events').removeObject(old);
        }
      },
      addStatus: function addStatus(checked, status) {
        var old = this.get('addedFilters.finalStatus').findBy('sort_key', status.get('sort_key'));

        if (checked && !old) {
          this.get('addedFilters.finalStatus').pushObject(Ember.Object.create({
            type: 'finalStatus',
            id: status.get('id'),
            label: status.get('label'),
            sort_key: status.get('sort_key'),
            type_id: status.get('type')
          }));
        } else if (this.get('addedFilters.finalStatus.length')) {
          this.get('addedFilters.finalStatus').removeObject(old);
        }
      },
      addRatings: function addRatings(checked, rating) {
        var old = this.get('addedFilters.proviewRatings').findBy('id', rating.get('id'));

        if (checked && !old) {
          this.get('addedFilters.proviewRatings').pushObject(Ember.Object.create({
            id: rating.get('id'),
            type: 'proviewRatings',
            label: rating.get('label'),
            "queryString": "(meta.proview-rating: ".concat(rating.label, ")")
          }));
        } else if (this.get('addedFilters.proviewRatings.length')) {
          this.get('addedFilters.proviewRatings').removeObject(old);
        }
      },
      addRecommendation: function addRecommendation(checked, rec) {
        var old = this.get('addedFilters.recommendation').findBy('sort_key', rec.get('sort_key'));

        if (checked && !old) {
          this.get('addedFilters.recommendation').pushObject(Ember.Object.create({
            id: rec.get('id'),
            type: 'recommendation',
            sort_key: rec.get('sort_key'),
            label: rec.get('label'),
            value: rec.get('value')
          }));
        } else if (this.get('addedFilters.recommendation.length')) {
          this.get('addedFilters.recommendation').removeObject(old);
        }
      },
      addAdvanceFilters: function addAdvanceFilters(obj) {
        if (obj) {
          if (obj.get('filter.field') == "Evaluation Date") {
            this.get('addedFilters.evalDate').pushObject(Ember.Object.create({
              id: obj.get('id'),
              type: 'evalDate',
              filterField: obj.get('filter.field'),
              label: obj.get('formattedFilterValue') ? obj.get('formattedFilterValue') : obj.get('filterValues') ? obj.get('filterValues.value') : null,
              "queryString": "(".concat(obj.get('queryString'), " AND assessment_section_id: ").concat(this.get('model.id'), ")")
            }));
          } else {
            var old = this.get('addedFilters.advanceFilters').findBy('filterField', obj.get('filter.field'));
            var value = obj.get('formattedFilterValue') ? obj.get('formattedFilterValue') : obj.get('filterValues') ? obj.get('filterValues.value') : null;
            var statusLabel = obj.get('filter.field') == "Invite Status" ? obj.get('formattedFilterValue') ? obj.get('formattedFilterValue') : null : value;

            if (old) {
              this.get('addedFilters.advanceFilters').removeObject(old);
              this.send('setAdvanceFilter', obj, value, statusLabel);
            } else {
              this.send('setAdvanceFilter', obj, value, statusLabel);
            }
          }
        }
      },
      setAdvanceFilter: function setAdvanceFilter(obj, value, statusLabel) {
        if (this.get('isAsscTable')) {
          this.get('addedFilters.advanceFilters').pushObject(Ember.Object.create({
            id: obj.get('id'),
            type: 'advanceFilters',
            filterField: obj.get('filter.field'),
            label: obj.get('filter.field') == "Invite Status" ? statusLabel : value,
            "queryString": "(".concat(obj.get('queryString'), " AND assessment_section_id: ").concat(this.get('model.id'), ")")
          }));
        } else {
          this.get('addedFilters.advanceFilters').pushObject(Ember.Object.create({
            id: obj.get('id'),
            type: 'advanceFilters',
            filterField: obj.get('filter.field'),
            label: value,
            "queryString": "".concat(obj.get('queryString'), " AND (assessment.id: ").concat(this.get('model.id'), ")")
          }));
        }
      },
      addFilter: function addFilter(filters) {
        var _this4 = this;

        filters.forEach(function (filter) {
          _this4.get('customListFilters').pushObject(Ember.Object.create({
            type: filter.get('type'),
            id: filter.get('id'),
            value: filter.get('value'),
            sort_key: filter.get('sort_key') ? filter.get('sort_key') : null,
            label: filter.get('name') ? filter.get('name') : filter.get('label'),
            type_id: filter.get('type_id') ? filter.get('type_id') : null,
            filterField: filter.get('filterField') ? filter.get('filterField') : null,
            queryString: filter.get('queryString') ? filter.get('queryString') : null
          }));
        });
      },
      applyFilter: function applyFilter() {
        if (this.get('addedFilters.finalStatus.values') || this.get('addedFilters.panel.length') || this.get('addedFilters.advancefilters.length') || this.get('customListFilters.length') || this.get('addedFilters.proviewRatings.length') || this.get('addedFilters.events.length') || this.get('addedFilters.recommendation.length') || this.get('addedFilters.evalDate.length') || this.get('addedFilters.unassigned.length')) {
          this.set('customListFilters', Ember.A([]));

          if (this.get('addedFilters.panel.length')) {
            this.send('addFilter', this.get('addedFilters.panel'));
          }

          if (this.get('addedFilters.unassigned.length')) {
            this.send('addFilter', this.get('addedFilters.unassigned'));
          }

          if (this.get('addedFilters.finalStatus.values')) {
            this.send('addFilter', this.get('addedFilters.finalStatus'));
          }

          if (this.get('addedFilters.proviewRatings.length')) {
            this.send('addFilter', this.get('addedFilters.proviewRatings'));
          }

          if (this.get('addedFilters.events.length')) {
            this.send('addFilter', this.get('addedFilters.events'));
          }

          if (this.get('addedFilters.recommendation.length')) {
            this.send('addFilter', this.get('addedFilters.recommendation'));
          }

          if (this.get('addedFilters.advanceFilters.length')) {
            this.send('addFilter', this.get('addedFilters.advanceFilters'));
          }

          if (this.get('addedFilters.evalDate.length')) {
            this.send('addFilter', this.get('addedFilters.evalDate'));
          }
        }

        if (this.get('sectionFilters.appliedFilters.length') || this.get('dateFilters.values.length') || this.get('appliedFilters.length')) {
          this.set('appliedFilters', Ember.A([]));
          this.set('appliedFilters', _npmLodash.default.concat(this.get('dateFilters.values'), this.get('sectionFilters.appliedFilters')));
        }

        this.sendAction('applyFilter', 'showFilters');
      },
      resetFilter: function resetFilter() {
        this.sendAction('resetFilter');
      },
      addSectionSubFilter: function addSectionSubFilter(field, value, as, selectedFilters) {
        var _this5 = this;

        this.findOrCreate(selectedFilters).then(function (createdFilter) {
          return _this5.updateFilterObject(createdFilter, selectedFilters, value, as);
        }).then(function (updatedFilter) {
          return updatedFilter.save();
        }).then(function (savedFilter) {
          if (savedFilter.get('sectionName') == 'Any') {
            var qt = savedFilter.get('queryString');
            var regex = /assessmentSectionCandidates.assessment_section_id: AND /gi;
            savedFilter.set('queryString', qt.replace(regex, ''));
          }

          savedFilter.set('type', 'section');

          if (_this5.get('sectionFilters.appliedFilters.length')) {
            var oldFilteredIndex = _npmLodash.default.findIndex(_this5.get('sectionFilters.appliedFilters'), function (data) {
              return data.get('sectionName') == savedFilter.get('sectionName') && data.get('filter.field') == savedFilter.get('filter.field');
            });

            if (oldFilteredIndex != -1) {
              _this5.get('sectionFilters.appliedFilters').removeAt(oldFilteredIndex);
            }
          }

          _this5.get('sectionFilters.appliedFilters').pushObject(savedFilter);

          _this5.set('sectionFilters.value', null);
        }).catch(function () {
          return _this5.get('toast').error('Something went wrong', 'Please try after some time');
        });
      },
      removeAppliedFilter: function removeAppliedFilter(filter, array, index) {
        this.sendAction('removeAppliedFilter', filter, array, index);
      },
      removeAddedField: function removeAddedField(array, index, objectArray, objectId) {
        if (objectArray && objectArray.get('length')) {
          if (_npmLodash.default.findIndex(objectArray, ['id', objectId]) != -1) {
            objectArray.removeAt(_npmLodash.default.findIndex(objectArray, ['id', objectId]));
          }
        }

        this.sendAction('removeAddedField', array, index);
      },
      // addDateFilter(date){
      //   date.set('type', 'date')
      //   let oldDate = this.get('dateFilters.values').findBy('filter.field', date.get('filter.field'))
      //   if(oldDate){
      //     let index = _.findIndex(this.get('dateFilters.values'), ['id', oldDate.get('id')])
      //     this.get('dateFilters.values').removeAt(index)
      //   }
      //   this.get('dateFilters.values').pushObject(date)
      //   this.send('removeAppliedFilter', date)
      // },
      clearSearchText: function clearSearchText() {
        this.set('panelSearchText', null);
      },
      toggleFilter: function toggleFilter(property) {
        if (this.get('panelSearchText')) {
          this.send('clearSearchText');
        }

        this.sendAction('toggleFilter', property);
      }
    }
  });

  _exports.default = _default;
});