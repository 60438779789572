define("recruit/routes/recruiter/reports/time-range/time-per-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('graphValue', Ember.A([3, 15, 3, 5]));
      controller.set('graphColors', Ember.A(['linear-gradient(90deg, #FF7B2B 0%, #9013FE 100%)', 'linear-gradient(270deg, #2784F0 0%, #9013FE 100%)', 'linear-gradient(90deg, #2883F0 0%, #F8E71C 100%)', 'linear-gradient(90deg, #F8E71C 0%, #63AD2F 100%)']));
    }
  });

  _exports.default = _default;
});