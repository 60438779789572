define("recruit/components/reports/tbi-view-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isReportAvailable: Ember.computed('assessmentCandidate.tbiReport.isFulfilled', function () {
      return this.get('assessmentCandidate.tbiReport.length');
    }),
    actions: {
      toggleModal: function toggleModal() {
        this.sendAction('toggleModal');
      }
    }
  });

  _exports.default = _default;
});