define("recruit/serializers/application", ["exports", "ember-data", "npm:lodash"], function (_exports, _emberData, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return _npmLodash.default.replace(attr, '-', '_');
    },
    keyForRelationship: function keyForRelationship(attr) {
      // to do assigned_to relation
      if (Ember.typeOf(this.attrsOption) === 'function' && _npmLodash.default.includes(_npmLodash.default.keys(this.attrsOption(attr)), 'embedded')) {
        return attr;
      }

      return Ember.String.underscore(attr) + (Ember.typeOf(attr) === 'string' && (attr.endsWith('By') || attr.endsWith('_by')) ? '' : '_id');
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (Ember.isArray(payload)) {
        payload.forEach(function (item) {
          item.links = item._links;
        });
      } else {
        payload.links = payload._links;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    extractMeta: function extractMeta(store, typeClass, payload) {
      if (payload && payload.hasOwnProperty('meta')) {
        var meta = payload.meta;
        delete payload.meta;
        return meta;
      }
    }
  });

  _exports.default = _default;
});