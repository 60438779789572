define("recruit/components/candidate-helpers/nested-custom-field", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tableHeader: Ember.computed(function () {
      return _npmLodash.default.keysIn(this.get('field.value.0'));
    }),
    tableRow: Ember.computed(function () {
      var values = this.get('field.value');
      var valueArray = [];
      values.forEach(function (value) {
        valueArray.pushObject(_npmLodash.default.valuesIn(value));
      });
      return valueArray;
    })
  });

  _exports.default = _default;
});