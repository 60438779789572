define("recruit/components/helpers/resume-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable */
  var _default = Ember.Component.extend({
    isFileSupported: Ember.computed('file', function () {
      return this.get('file.pdfLink') ? this.get('file.pdfLink').includes('.pdf') : false;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('isFileSupported')) {
        emberAutoImportDynamic("pdfjs-dist").then(function (pdfjsLib) {
          var filePath = _this.get('file.pdfLink');

          var Num = function Num(num) {
            var num = num;
            return function () {
              return num;
            };
          };

          var renderPDF = function renderPDF(url, canvasContainer, options) {
            options = options || {
              scale: _this.get('scale') ? _this.get('scale') : 1.358
            };

            var func,
                pdfDoc,
                def = $.Deferred(),
                promise = $.Deferred().resolve().promise(),
                width,
                height,
                makeRunner = function makeRunner(func, args) {
              return function () {
                return func.call(null, args);
              };
            };

            var renderPage = function renderPage(num) {
              var def = $.Deferred(),
                  currPageNum = +num;
              pdfDoc.getPage(currPageNum).then(function (page) {
                var viewport = page.getViewport(+options.scale);
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                var renderContext = {
                  canvasContext: ctx,
                  viewport: viewport
                };

                if (currPageNum === 1) {
                  height = viewport.height;
                  width = viewport.width;
                }

                canvas.height = height;
                canvas.width = width;
                canvasContainer.appendChild(canvas);
                page.render(renderContext).then(function () {
                  def.resolve();
                });
              });
              return def.promise();
            };

            var renderPages = function renderPages(data) {
              pdfDoc = data;
              var pagesCount = pdfDoc.numPages;

              for (var i = 1; i <= pagesCount; i++) {
                var _func = renderPage;
                promise = promise.then(makeRunner(_func, i));
              }
            };

            pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/js/pdf.worker.min.js';
            var task = pdfjsLib.getDocument(url);
            task.promise.then(renderPages);
          };

          var body = document.getElementById("tv-resume-viewer");
          renderPDF(filePath, body);
        });
      }
    }
  });

  _exports.default = _default;
});