define("recruit/components/filters/applied-filters", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    actions: {
      removeAppliedFilter: function removeAppliedFilter(filter) {
        this.sendAction('removeAppliedFilter', filter);
      },
      clearFilters: function clearFilters() {
        this.sendAction('clearFilters');
      },
      saveFilters: function saveFilters() {
        this.sendAction('saveFilters');
        Ember.$('#save_filter_button').toggleClass('tv-hide');
      }
    }
  });

  _exports.default = _default;
});