define("recruit/templates/components/recruiter-view/questions/helpers/time-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I7qvGmBo",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"sixteen wide reports-text column padding-no text-left margin-left-fifteen text-weight-normal text-size-fourteen margin-top-seven\"],[7],[0,\"\\n\\t\\t\"],[6,\"p\"],[9,\"class\",\"text-black margin-no\"],[7],[0,\"\\n\\t\\t  \"],[6,\"span\"],[10,\"data-tooltip\",[26,[[25,\"moment-format\",[[20,[\"row\",\"created_at\"]],\"DD MMM' YYYY | h:mm a\"],null]]]],[9,\"data-position\",\"bottom center\"],[7],[1,[20,[\"row\",\"createdAtFormat\"]],false],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/time-details-cell-component.hbs"
    }
  });

  _exports.default = _default;
});