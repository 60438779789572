define("recruit/templates/components/candidate-helpers/candidate-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1onPENpc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"data-tooltip\",[20,[\"model\",\"fullName\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n  \"],[6,\"p\"],[10,\"class\",[26,[\"margin-no \",[25,\"if\",[[20,[\"customColor\"]],[20,[\"customColor\"]],\"text-black\"],null],\" text-weight-normal ellipsis\"]]],[7],[1,[20,[\"model\",\"fullName\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"model\",\"email\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"ellipsis text-grey text-size-small text-weight-light\"],[7],[1,[20,[\"model\",\"email\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-helpers/candidate-details.hbs"
    }
  });

  _exports.default = _default;
});