define("recruit/components/assessment/update-assessment", ["exports", "ember-cli-uuid", "npm:lodash", "recruit/config/environment"], function (_exports, _emberCliUuid, _npmLodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    user: Ember.inject.service('current-user'),
    isDevelopment: _environment.default.isDevelopment,
    _assessmentTitleObserver: Ember.observer('assessmentModel.{title}', function () {
      this.set('isTypingTitle', true);
      Ember.run.debounce(this, this.saveModel, 'Title', 3000);
    }),
    _assessmentExtIdObserver: Ember.observer('assessmentModel.{external_id}', function () {
      this.set('isTypingId', true);
      Ember.run.debounce(this, this.saveModel, 'Id', 3000);
    }),
    canAddSections: Ember.computed('assessmentModel.allSections.isFulfilled', 'assessmentModel.assessmentSections.isFulfilled', function () {
      if (this.get('allSections.length') && this.get('assessmentSections.length')) {
        return this.get('assessmentSections.lastObject.id') != this.get('allSections.lastObject.id');
      }
    }),
    selectedAS: Ember.computed('currentSlide', 'assessmentSections.isFulfilled', function () {
      return this.get('assessmentSections').objectAt(this.get('currentSlide'));
    }),
    openJdAlert: Ember.computed('showJdAlert', function () {
      return this.get('showJdAlert') && !this.get('assessmentModel.hasValidJD');
    }),

    /* Uncomment the below code to show assessment status tooltip for assessment workflow freeze */

    /* sectionFreezeTooltip: Ember.computed('assessmentModel.freezeAssessment', function() {
      return '<div class="text-size-small text-black"><div class="text-weight-medium">Unable to add/remove sections</div><div>Can\'t add/remove section as the assessment is in open state.</div></div>'
    }), */
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isLoading', false);
      this.set('pearsonAssessments', this.get('store').findAll('pearson-assessment'));
    },
    didRender: function didRender() {
      if (this.get('toggleInput') && Ember.$("#assessmentTitle").length) {
        Ember.run.later(function () {
          Ember.$("#assessmentTitle").focus();
        }, 100);
      }

      if (this.get('toggleExtId') && Ember.$(".externalId").length) {
        Ember.run.later(function () {
          Ember.$(".externalId").focus();
        }, 100);
      }
      /* Uncomment the below code to show assessment status tooltip for assessment workflow freeze */
      // if(this.get('assessmentModel.freezeAssessment')) {
      //   Ember.run.later('afterRender', () => {
      //     Ember.$('.addIcon').popup({
      //       position: 'top center',
      //       html: this.get('sectionFreezeTooltip')
      //     })
      //   })
      // }
      // Ember.run.later('afterRender', () => {
      //   Ember.$('.assessmentStatus').popup({
      //     inline: false,
      //     hoverable: true,
      //     html: this.get('assessmentModel').getStatusTooltipContent(),
      //     position: 'bottom center'
      //   })
      // })

    },
    setRatingType: function setRatingType(assessmentSection) {
      switch (assessmentSection.get('selectedRating')) {
        case 'parameters':
          {
            return assessmentSection.set('section.evaluationForm', null);
          }

        case 'form':
          {
            return assessmentSection.set('parameters', Ember.A([]));
          }

        case 'form-parameters':
          {
            return true;
          }

        default:
          {
            return assessmentSection.setProperties({
              'section': {
                'evaluationForm': null
              },
              'parameters': Ember.A([])
            });
          }
      }
    },
    showError: function showError(err) {
      var _this = this;

      if (err.errors) {
        err.errors.forEach(function (error) {
          _this.get('toast').error(error.detail, 'Error!', {
            positionClass: 'assessment-toast-error'
          });
        });
      } else {
        this.get('toast').error("Something went wrong.", 'Try again.', {
          positionClass: 'assessment-toast-error'
        });
      }
    },
    saveModel: function saveModel(property) {
      var _this2 = this;

      this.get('assessmentModel').save().then(function () {
        _this2.set("isTyping".concat(property), false);

        _this2.set("is".concat(property, "Updated"), true);
      }).catch(function (error) {
        _this2.get('assessmentModel').rollbackAttributes(['title', 'external_id']);

        _this2.showError(error);
      }).finally(function () {
        setTimeout(function () {
          _this2.set("is".concat(property, "Updated"), false);
        }, 2000);
        return _this2.set("isTyping".concat(property), false);
      });
    },
    actions: {
      addNewSection: function addNewSection(index) {
        var _this3 = this;

        if (this.get('assessmentModel.freezeAssessment')) {
          if (!this.toggleProperty('toggleNag')) {
            this.toggleProperty('toggleNag');
          }

          return;
        }

        if (this.get('selectedAS.hasDirtyAttributes')) {
          return this.toggleProperty('showAlert');
        }

        if (!this.get('canAddSections')) {
          return this.get('toast').error('You can\'t add sections to this assessment as it was created in an older version of the application.', '', {
            timeOut: '6000',
            positionClass: 'assessment-toast-error'
          });
        }

        if (this.get('assessmentModel.isExtraSectionAvailable')) {
          return this.send('getNewlyAddedSection');
        }

        this.get('user.me.organization').then(function (res) {
          res.getSectionTypeList(true);

          _this3.set('availableSections', res.availableSections);
        });
        this.get('store').createRecord('lf-adapter/assessment-section', {
          id: (0, _emberCliUuid.uuid)()
        }).save().then(function (assessmentSection) {
          _this3.set('sectionId', assessmentSection.get('id'));

          _this3.send('createNewSection', assessmentSection, index);
        }).catch(function () {
          _this3.get('toast').error('Something went wrong.', '', {
            positionClass: 'assessment-toast-error'
          });
        });
      },
      createNewSection: function createNewSection(assessmentSection, index) {
        var _this4 = this;

        var sectionModel = this.get('store').createRecord('lf-adapter/section', {
          id: (0, _emberCliUuid.uuid)(),
          sectionType: this.get('availableSections.firstObject'),
          title: this.get('availableSections.firstObject.name')
        });
        sectionModel.save().then(function (section) {
          assessmentSection.set('section', section);
          assessmentSection.set('isExtraAs', true);
          assessmentSection.save().then(function () {
            _this4.get('assessmentModel.assessmentSections').insertAt(index, assessmentSection);

            _this4.set('currentSlide', index);

            _this4.set('assessmentModel.isExtraSectionAvailable', true);
          }).catch(function () {
            _this4.get('toast').error('Could not save assessment section', '', {
              positionClass: 'assessment-toast-error'
            });
          });
        }).catch(function () {
          _this4.get('toast').error('Could not add assessment section', '', {
            positionClass: 'assessment-toast-error'
          });
        });
      },
      deleteSection: function deleteSection(property, assessmentSection) {
        var _this5 = this;

        if (this.get('assessmentSections.length') == 1) {
          return this.get('toast').error('You need to add atleast 1 section to the assessment', "Error !", {
            positionClass: 'assessment-toast-error'
          });
        }

        if (!this.get("".concat(property))) {
          this.set('sectionToBeDeleted', assessmentSection);
          return this.toggleProperty(property);
        }

        this.set('isDeletingSection', true);
        assessmentSection.destroyRecord().then(function () {
          _this5.get('toast').success('Section deleted', "Success", {
            positionClass: 'assessment-toast-error'
          });

          _this5.set('sectionId', null);

          _this5.set('assessmentModel.isExtraSectionAvailable', false);

          _this5.set('currentSlide', _this5.get('assessmentSections.length') - 1);
        }).catch(function (error) {
          _this5.showError(error);
        }).finally(function () {
          _this5.set('isDeletingSection', false);

          return _this5.toggleProperty(property);
        });
      },
      toggleModal: function toggleModal(property1, property2) {
        this.toggleProperty(property1);

        if (!_npmLodash.default.isNil(property2)) {
          this.toggleProperty(property2);
        }
      },
      clickOutside: function clickOutside(property) {
        if (!_npmLodash.default.isNil(property) && this.get(property)) {
          this.toggleProperty(property);
        }
      },
      updateAs: function updateAs() {
        var _this6 = this;

        var assessmentSection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('selectedAS');

        if (assessmentSection.get('hasDirtyAttributes')) {
          this.set('isLoading', true);
          assessmentSection.save().then(function () {
            _this6.get('toast').success('Assessment Updated', 'Success', {
              positionClass: 'assessment-toast-error'
            });
          }).catch(function (err) {
            if (err.errors) {
              err.errors.forEach(function (e) {
                _this6.get('toast').error(e.message, 'Error', {
                  positionClass: 'assessment-toast-error'
                });
              });
            } else {
              _this6.get('toast').error('Please try after some time', 'Something went wrong', {
                positionClass: 'assessment-toast-error'
              });
            }
          }).finally(function () {
            if (_this6.get('showAlert')) {
              _this6.toggleProperty('showAlert');
            }

            _this6.set('isLoading', false);
          });
        } else {
          this.get('toast').success('Already Updated', '', {
            positionClass: 'assessment-toast-error'
          });
        }
      },
      discard: function discard() {
        var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('selectedAS');

        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
          this.toggleProperty('showAlert');
        }
      },
      back: function back(selectedAS) {
        if (this.get('assessmentModel.isExtraSectionAvailable')) {
          return this.send('getNewlyAddedSection');
        }

        if (selectedAS.get('hasDirtyAttributes')) {
          return this.toggleProperty("showAlert");
        }

        window.history.back();
      },
      getNewlyAddedSection: function getNewlyAddedSection() {
        var as = this.get('assessmentSections').find(function (as) {
          return as.get('isExtraAs');
        });

        if (as) {
          return this.get('toast').error("You need to add/delete '".concat(as.get('section.title'), "' section before switching to any other section."), '', {
            timeOut: '6000',
            positionClass: 'assessment-toast-error'
          });
        }

        return this.get('toast').error("You need to add/delete newly created section before switching to any other section.", '', {
          timeOut: '6000',
          positionClass: 'assessment-toast-error'
        });
      }
    }
  });

  _exports.default = _default;
});