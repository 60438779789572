define("recruit/components/reports/helpers/time-range-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var arr = this.get('column.model').map(function (a) {
        return a.get('id');
      });
      this.set('indexNumber', arr.indexOf(this.get('row.id')) + 1);
    },
    startDate: Ember.computed(function () {
      return (0, _moment.default)(this.get('row.date')).format("DD MMM' YYYY");
    }),
    endDate: Ember.computed('startDate', function () {
      return this.setDates();
    }),
    setDates: function setDates() {
      switch (this.get('column.selectedGraphFilter')) {
        case 'month':
          return (0, _moment.default)(this.get('row.date')).add(1, 'M').subtract(1, 'days').format("DD MMM' YYYY");

        case 'week':
          return (0, _moment.default)(this.get('row.date')).add(6, 'days').format("DD MMM' YYYY");

        case 'year':
          return this.yearEndDate();
      }
    },
    yearEndDate: function yearEndDate() {
      var today = (0, _moment.default)().format("DD MMMM YYYY");

      if ((0, _moment.default)(today).isBefore((0, _moment.default)(this.get('row.date')).add('years', 1).subtract(1, 'days').format("DD MMM' YYYY"))) {
        return (0, _moment.default)().subtract(1, 'days').format("DD MMMM YYYY");
      } else {
        return (0, _moment.default)(this.get('row.date')).add('years', 1).subtract(1, 'days').format("DD MMM' YYYY");
      }
    }
  });

  _exports.default = _default;
});