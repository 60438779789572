define("recruit/templates/components/panel/error-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nozkyqNs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"eight wide centered center aligned column\"],[7],[0,\"\\n  \"],[6,\"img\"],[10,\"src\",[26,[\"/images/empty_states/\",[18,\"errorImage\"]]]],[9,\"alt\",\"\"],[9,\"class\",\"ui centered image margin-top-thirty\"],[10,\"style\",[18,\"customStyle\"],null],[7],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"primaryMessage\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"margin-top-thirty text-size-normal text-center\"],[7],[1,[18,\"primaryMessage\"],false],[8],[0,\"\\n    \"],[6,\"br\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"p\"],[9,\"class\",\"text-size-normal text-center\"],[7],[1,[18,\"secondaryMessage\"],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/error-component.hbs"
    }
  });

  _exports.default = _default;
});