define("recruit/data/live-column-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1057,
    type: "column/master",
    attributes: {
      label: "Timezone",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      cellComponent: 'helpers/live-session/assessment-helper',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "timezone",
      width: "100px"
    }
  }, {
    id: 1058,
    type: "column/master",
    attributes: {
      label: "Section Name",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      cellComponent: 'helpers/live-session/assessment-helper',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "assessmentSectionCandidate.section.title",
      width: "110px"
    }
  }, {
    id: 1059,
    type: "column/master",
    attributes: {
      label: "Assessment Name",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      cellComponent: 'helpers/live-session/assessment-helper',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "assessmentSectionCandidate.assessment.title",
      width: "170px"
    }
  }, {
    id: 1066,
    type: "column/master",
    attributes: {
      label: "Assessment Start Date",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      isDefault: false,
      isShow: false,
      showTimeAgo: false,
      cellComponent: 'helpers/date-view',
      dateFormat: 'DD-MMM-YY | hh:mm A',
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "assessmentSectionCandidate.assessment.start_date",
      width: '200px'
    }
  }, {
    id: 1067,
    type: "column/master",
    attributes: {
      label: "Assessment End Date",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      isDefault: false,
      isShow: false,
      showTimeAgo: false,
      cellComponent: 'helpers/date-view',
      dateFormat: 'DD-MMM-YY | hh:mm A',
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "assessmentSectionCandidate.assessment.end_date",
      width: '200px'
    }
  }, {
    id: 1068,
    type: "column/master",
    attributes: {
      label: "Assessment Owner",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      cellComponent: 'helpers/live-session/assessment-helper',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "assessmentSectionCandidate.assessment.owned_by.name",
      width: "170px"
    }
  }, {
    id: 1069,
    type: "column/master",
    attributes: {
      label: "Invite Status",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      cellComponent: 'status-helpers/live',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "assessmentSectionCandidate.assessment.statusText",
      width: "170px"
    }
  }, {
    id: 10070,
    type: "column/master",
    attributes: {
      label: 'Created On',
      valuePath: 'created_at',
      cellComponent: 'helpers/date-view',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      showTimeAgo: false,
      dateFormat: 'DD-MMM-YY | hh:mm A',
      classNames: ['table-head'],
      cellClassNames: ['text-center'],
      width: '170px'
    }
  }, {
    id: 1071,
    type: "column/master",
    attributes: {
      label: 'Ends At',
      valuePath: 'ends_at',
      cellComponent: 'helpers/date-view',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      showTimeAgo: false,
      dateFormat: 'DD-MMM-YY | hh:mm A',
      classNames: ['table-head'],
      cellClassNames: ['text-center'],
      width: '170px'
    }
  }, {
    id: 1072,
    type: "column/master",
    attributes: {
      label: "Evaluator",
      classNames: ["table-head"],
      cellClassNames: ['text-center'],
      cellComponent: 'candidate-helpers/evaluator-names',
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "panels",
      width: "170px"
    }
  }, {
    id: 1073,
    type: "column/master",
    attributes: {
      label: "Scheduled By",
      classNames: ["table-head"],
      cellComponent: 'helpers/live-session/assessment-helper',
      cellClassNames: ['text-center'],
      isDefault: false,
      isShow: false,
      sortWeight: 10,
      tableType: "r-ls",
      sortable: false,
      valuePath: "hostedBy.name",
      width: "170px"
    }
  }, {
    id: 10060,
    type: "column/master",
    attributes: {
      valuePath: 'candidate',
      isDefault: true,
      isShow: true,
      sortWeight: 1,
      tableType: "r-ls",
      cellComponent: 'helpers/table-profile-picture',
      sortable: false,
      width: '70px',
      classNames: ['table-head']
    }
  }, {
    id: 10061,
    type: "column/master",
    attributes: {
      label: 'Candidate',
      valuePath: 'candidate',
      isDefault: true,
      isShow: true,
      sortWeight: 2,
      tableType: "r-ls",
      cellComponent: 'candidate-helpers/candidate-details',
      sortable: false,
      classNames: ['table-head'],
      cellClassNames: ['text-left'],
      width: '225px'
    }
  }, {
    id: 10062,
    type: "column/master",
    attributes: {
      label: 'Session ID',
      cellComponent: 'helpers/live-session/assessment-helper',
      valuePath: 'name',
      isDefault: true,
      isShow: true,
      sortWeight: 3,
      tableType: "r-ls",
      classNames: ['table-head ellipsis'],
      cellClassNames: ['text-center'],
      width: '100px'
    }
  }, {
    id: 10063,
    type: "column/master",
    attributes: {
      label: 'Status',
      cellComponent: 'status-helpers/live',
      valuePath: 'statusLabel',
      isDefault: true,
      isShow: true,
      sortWeight: 4,
      tableType: "r-ls",
      classNames: ['table-head'],
      cellClassNames: ['text-center'],
      width: '100px'
    }
  }, {
    id: 10064,
    type: "column/master",
    attributes: {
      label: 'Starts at',
      valuePath: 'starts_at',
      cellComponent: 'helpers/date-view',
      isDefault: true,
      isShow: true,
      sortWeight: 5,
      tableType: "r-ls",
      showTimeAgo: false,
      dateFormat: 'DD-MMM-YY | hh:mm A',
      classNames: ['table-head'],
      cellClassNames: ['text-center'],
      width: '200px'
    }
  }, {
    id: 10065,
    type: "column/master",
    attributes: {
      label: 'Duration',
      cellComponent: 'helpers/live-session/assessment-helper',
      valuePath: 'formattedDuration',
      isDefault: true,
      isShow: true,
      sortWeight: 6,
      tableType: "r-ls",
      classNames: ['table-head'],
      cellClassNames: ['text-center'],
      width: '120px'
    }
  }];
  _exports.default = _default;
});