define("recruit/templates/panel/candidate/evaluate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jZDY9kEo",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Evaluate\"],null],false],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/panel/candidate/evaluate.hbs"
    }
  });

  _exports.default = _default;
});