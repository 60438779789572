define("recruit/components/recruiter-view/assessment/assessment-section-details", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    panels: Ember.computed('selectedAS', 'allPanels.[]', function () {
      return this.get('allPanels').filterBy('assessmentSection.id', this.get('selectedAS.id'));
    }),
    didRender: function didRender() {
      Ember.$('.ui.dropdown').dropdown();
    }
  });

  _exports.default = _default;
});