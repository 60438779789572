define("recruit/templates/components/recruiter-view/questions/helpers/errors-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q4ytbhxT",
    "block": "{\"symbols\":[\"errors\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segment margin-no border-bottom-left-radius-none border-bottom-right-radius-none\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui grid margin-top-hundred margin-bottom-seventy\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n          \"],[6,\"img\"],[9,\"src\",\"/images/close.svg\"],[9,\"style\",\"height: 75px !important;\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"text-weight-medium text-black text-center text-size-sixteen\"],[7],[0,\"\\n          You can't create a question with the following errors :\\n          \"],[6,\"div\"],[9,\"class\",\"margin-top-ten text-center\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"errors\"]]],null,{\"statements\":[[0,\"              \"],[6,\"p\"],[9,\"class\",\"text-red text-center\"],[7],[6,\"i\"],[9,\"class\",\"times circle red icon\"],[7],[8],[1,[19,1,[\"key\"]],false],[0,\" : \"],[1,[19,1,[\"validation\"]],false],[0,\" !\"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"text-black text-center text-size-sixteen\"],[7],[0,\"\\n          This action can't be undone.\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment text-center margin-no border-top-left-radius-none border-top-right-radius-none\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui basic button red text-capitalize\"],[3,\"action\",[[19,0,[]],\"toggleModal\",\"cannotCreate\"]],[7],[0,\"Close\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/errors-modal.hbs"
    }
  });

  _exports.default = _default;
});