define("recruit/templates/components/model-table/recruiter/questions-row-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7jRaKdep",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"if\",[[20,[\"row\",\"content\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"columns\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"component\",[[25,\"concat\",[\"light-table/cells/\",[19,1,[\"cellType\"]]],null],[19,1,[]],[20,[\"row\"]]],[[\"table\",\"rawValue\",\"tableActions\",\"extra\"],[[20,[\"table\"]],[25,\"get\",[[20,[\"row\"]],[19,1,[\"valuePath\"]]],null],[20,[\"tableActions\"]],[20,[\"extra\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"td\"],[9,\"class\",\"padding-no full-width\"],[9,\"style\",\"border-top: none;\"],[7],[0,\"\\n    \"],[1,[18,\"recruiter-view/questions/helpers/questions-table-placeholder\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/model-table/recruiter/questions-row-component.hbs"
    }
  });

  _exports.default = _default;
});