define("recruit/components/helpers/dynamic-checkbox", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['display-inline-block'],
    isChecked: Ember.computed('selectedOptions', 'selectedOptions.length', function () {
      if (this.get('selectedOptions.length')) {
        var arr = this.get('selectedOptions').map(function (ob) {
          return ob.get('id');
        });
        return _npmLodash.default.includes(arr, this.get('value.id'));
      }
    }),
    tooltipData: Ember.computed(function () {
      return this.get('value.nameWithEmail') || this.get('value.titleWithId');
    }),
    didRender: function didRender() {
      var _this = this;

      Ember.run.later(function () {
        Ember.$("#".concat(_this.get('elementId'), ">.popup-checkbox")).popup({
          position: 'bottom center',
          inline: false,
          title: _npmLodash.default.isNil(_this.get('hideTitle')) ? _this.get('labelName') : null,
          content: _this.get('tooltipData') ? _this.get('tooltipData') : ''
        });
      });
    },
    labelName: Ember.computed(function () {
      if (this.get('isQuestionsFilter')) {
        return null;
      } else {
        if (this.get('value.name')) {
          return this.get('value.name');
        }

        if (this.get('value.title')) {
          return this.get('value.title');
        }

        if (this.get('value.label')) {
          return this.get('value.label');
        } else {
          return '--';
        }
      }
    }),
    actions: {
      setProp: function setProp(val, obj) {
        this.sendAction('actionName', val, obj.get('value'));
      }
    }
  });

  _exports.default = _default;
});