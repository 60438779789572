define("recruit/components/model-table/recruiter/questions-table", ["exports", "recruit/mixins/table-common"], function (_exports, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    modelType: 'question',
    questionsObserver: Ember.observer('table.selectedRows', function () {
      this.set('selectedQuestions', this.get('table.selectedRows'));
    }),
    customFilterObserver: Ember.observer('customListFilters.[]', function () {
      this.setProperties({
        'skills': this.get('customListFilters').filterBy('type', 'skill').map(function (sk) {
          return sk.get('id');
        }),
        'type': this.get('customListFilters').filterBy('type', 'type').map(function (ty) {
          return ty.get('label');
        }),
        'created_by': this.get('customListFilters').filterBy('type', 'created_by').map(function (user) {
          return user.get('id');
        })
      });
      Ember.run.once(this, 'search');
    }),
    columns: Ember.computed(function () {
      return [{
        component: "helpers/table-checkboxs",
        cellComponent: "helpers/row-checkbox",
        width: '40px',
        sortable: false,
        classNames: ['table-head padding-no table-head-checkbox-th'],
        cellClassNames: ['child padding-no']
      }, {
        label: 'Question',
        width: '400px',
        cellComponent: 'recruiter-view/questions/helpers/question-details-cell-component',
        classNames: ['table-head text-left padding-twenty-five-left'],
        cellClassNames: ['text-left']
      }, {
        label: 'Type',
        width: '200px',
        cellComponent: 'recruiter-view/questions/helpers/type-details-cell-component',
        cellClassNames: ['text-center'],
        classNames: ['table-head text-center']
      }, {
        label: 'Skill',
        width: '125px',
        cellComponent: 'recruiter-view/questions/helpers/skills-cell-component',
        classNames: ['table-head text-center'],
        cellClassNames: ['text-left']
      }, {
        label: 'Added On',
        width: '125px',
        classNames: ['table-head'],
        cellComponent: 'recruiter-view/questions/helpers/time-details-cell-component',
        cellClassNames: ['text-center']
      }, {
        label: 'Added By',
        width: '215px',
        cellComponent: 'recruiter-view/questions/helpers/user-details-cell-component',
        cellClassNames: 'text-center text-weight-light',
        classNames: ['table-head']
      }];
    }),
    actions: {
      rowClicked: function rowClicked(row) {
        this.get('router').transitionTo('recruiter.questions.edit', row.get('id'));
      }
    }
  });

  _exports.default = _default;
});