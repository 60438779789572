define("recruit/models/event-assessment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    userRole: Ember.inject.service(),
    event: _emberData.default.belongsTo('event'),
    created_by: _emberData.default.belongsTo('user'),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    assessment: _emberData.default.belongsTo('assessment'),
    assessmentExpanded: _emberData.default.belongsTo('assessment'),
    updated_by: _emberData.default.belongsTo('user'),
    assessmentTitle: Ember.computed.alias('assessmentExpanded.title'),
    owned_by: Ember.computed.alias('assessmentExpanded.owned_by'),
    invitedCount: _emberData.default.attr('number'),
    inProgressCount: _emberData.default.attr('number'),
    attendedCount: _emberData.default.attr('number'),
    assessedCount: _emberData.default.attr('number'),
    selectedCount: _emberData.default.attr('number'),
    rejectedCount: _emberData.default.attr('number'),
    holdCount: _emberData.default.attr('number'),
    isDeleteable: Ember.computed('userRole.currentRole', 'assessmentExpanded.{owned_by,owned_by.isFulfilled}', 'event.{selectedStatus.id,@each.status}', function () {
      if (this.get('assessmentExpanded') && this.get('event.status') == 1) {
        if (this.get('userRole.currentRole') == "Master Recruiter") {
          return true;
        } else if (this.get('currentUser.me.id') == this.get('created_by.id')) {
          //if event-owner has added the assessment
          return true;
        } else if (this.get('owned_by.id') && this.get('currentUser.me.id') == this.get('owned_by.id')) {
          //if recruiter/me is the owner of the assessment
          return true;
        } else {
          return false;
        }
      }
    }),
    isClickable: Ember.computed('assessmentExpanded', 'userRole.currentRole', function () {
      return this.get('currentUser.me.id') === this.get('assessmentExpanded.owned_by.id') || this.get('userRole.isMasterRecruiter');
    })
  });

  _exports.default = _default;
});