define("recruit/components/panel/evaluate/count-tab", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item column'],
    highlight: Ember.computed('currentSelected', 'type', function () {
      return _npmLodash.default.toLower(this.get('type')) === _npmLodash.default.toLower(this.get('currentSelected'));
    }),
    actions: {
      changeType: function changeType(type) {
        this.sendAction('changeType', _npmLodash.default.toLower(type));
      }
    }
  });

  _exports.default = _default;
});