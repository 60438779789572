define("recruit/templates/components/reports/helpers/date-filter-quick-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MRKMbR3/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"a\"],[10,\"id\",[26,[[18,\"links\"]]]],[10,\"class\",[26,[\"text-capitalize margin-bottom-five padding-no reports-date-picker-label \",[25,\"if\",[[25,\"eq\",[[20,[\"selectedFilterValue\"]],[20,[\"links\"]]],null],\"label-active\"],null]]]],[3,\"action\",[[19,0,[]],\"setDate\",[20,[\"links\"]]]],[7],[0,\"\\n \\t\"],[1,[18,\"linkValues\"],false],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/date-filter-quick-links.hbs"
    }
  });

  _exports.default = _default;
});