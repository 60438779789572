define("recruit/components/recruiter-view/candidate/assessment-section-icon", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didRender: function didRender() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('[data-content]').popup({
          position: 'bottom center',
          inline: false,
          variation: 'inverted'
        });
      });
    },
    statusClass: Ember.computed('assessmentSection', 'selectedAs', 'assessmentSectionCandidates.@each.status', function () {
      var _this = this;

      var asscs = this.get('assessmentSectionCandidates');
      var found = false,
          correctAssc = null;
      asscs.forEach(function (assc) {
        if (assc.get('assessmentSection.id') == _this.get('assessmentSection.id') && !found) {
          found = true;
          correctAssc = assc;
        }
      });

      if (found && correctAssc) {
        switch (correctAssc.get('status.sort_key')) {
          case 90:
            //Rejected
            return 'rejected';

          case 70:
            // Selected status
            return 'selected';

          case 50:
            return 'completed';

          case 30: //Attend status

          case 80:
            //Hold status
            return 'attending';

          case 20: //Invite, added, pending status

          default:
            return 'pending';
        }
      } else {
        return 'pending';
      }
    }),
    lineClass: Ember.computed('assessmentSection', function () {
      var asIds = this.get('assessmentSectionCandidates').map(function (assc) {
        return assc.get('assessmentSection.id');
      });
      return _npmLodash.default.includes(asIds, this.get('assessmentSection.id')) ? 'green' : 'grey';
    }),
    selectedClass: Ember.computed('selectedAs', 'assessmentSection', function () {
      if (this.get('selectedAs.id') == this.get('assessmentSection.id')) {
        return 'current';
      }
    }),
    actions: {
      setAssc: function setAssc(as) {
        var asscFound = false,
            currentAssc;
        this.get('assessmentSectionCandidates').forEach(function (assc) {
          if (assc.get('assessmentSection.id') == as.get('id') && !asscFound) {
            asscFound = true;
            currentAssc = assc;
          }
        });
        this.set('selectedAs', as);
        this.set('selectedAssc', currentAssc);
        this.sendAction('setAssc');
      }
    }
  });

  _exports.default = _default;
});