define("recruit/templates/panel/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BcEBT6nm",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"margin-top-hundred\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid \"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide centered column\"],[7],[0,\"\\n      \"],[1,[25,\"panel/set-availability/calendar\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/panel/calendar.hbs"
    }
  });

  _exports.default = _default;
});