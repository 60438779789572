define("recruit/templates/components/recruiter-view/assessment/candidate-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7cfBcgAz",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"column padding-no text-size-small center aligned stats \",[18,\"type\"]]]],[10,\"data-tooltip\",[18,\"toolTip\"],null],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"count\"]]],null,{\"statements\":[[0,\"      \"],[1,[18,\"count\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      0\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/candidate-count.hbs"
    }
  });

  _exports.default = _default;
});