define("recruit/components/model-table/panel/candidate-row-component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    performance: Ember.inject.service(),
    performanceObserver: Ember.observer('row.isRelationsLoaded', function () {
      var _this = this;

      Ember.run.once('afterRender', function () {
        var rows = _this.get('table.rows');

        if (rows.get('length') <= 25 && rows.get('lastObject.id') === _this.get('row.id') && _this.get('row.isRelationsLoaded')) {
          _this.get('performance').end('Panel:Candidates:list');
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});