define("recruit/components/recruiter-view/questions/helpers/question-creation-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeErrors: function closeErrors() {
        this.sendAction('closeErrors');
      },
      showErrors: function showErrors(property) {
        this.sendAction('showErrors', property);
      }
    }
  });

  _exports.default = _default;
});