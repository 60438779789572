define("recruit/components/action-wrapper/simulation-modal/reschedule", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isNotify: true,
    timeZoneList: Ember.computed(function () {
      return this.get('store').query('settingsAllowed', {
        settings_id: 1
      });
    }),
    didInsertElement: function didInsertElement() {// $('#calendarDateTime').calendar({
      //   ampm: false
      // })
    },
    actions: {
      closeModal: function closeModal(modalProperty) {
        this.sendAction('toggleModal', modalProperty);
      },
      rescheduleLive: function rescheduleLive(assc) {
        var _this = this;

        var startDateTime = Ember.$('#calendarDateTime').calendar('get date');

        var endDateTime = (0, _moment.default)(startDateTime).add(assc.get('durationPreSelected').value, "minutes")._d;

        if (!this.get('timeZoneValue')) {
          this.get('toast').error('Timezone can not be blank', 'Error!');
        } else if (!startDateTime) {
          this.get('toast').error('Date can not be blank', 'Error!');
        } else {
          this.get('store').createRecord('actions/simulation-reschedule', {
            assessmentSectionCandidateID: assc.get('id'),
            startDate: startDateTime,
            endDate: endDateTime,
            currentOffset: (0, _moment.default)().local().utcOffset() * 60,
            isNotify: this.get('isNotify'),
            timezone: this.get('timeZoneValue.item_value')
          }).save().then(function (response) {
            if (response.get('status') == 99) {
              _this.get('toast').error(response.get('response'));
            } else {
              _this.get('toast').success('Simulation session scheduled successfully');

              return _this.send('closeModal', _this.get('modalProperty'));
            }
          }).catch(function (e) {
            if (e.errors) {
              e.errors.forEach(function (error) {
                _this.get('toast').error(error.detail, 'Error!');
              });
            } else {
              _this.get('toast').error('Please try after some time', 'Something went wrong');
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});