define("recruit/components/events/assessment-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      redirectTo: function redirectTo(path, model, canViewAssessment) {
        this.sendAction('redirectTo', path, model.get('id'), canViewAssessment);
      },
      toggleModal: function toggleModal(property, item) {
        this.sendAction('toggleModal', property, item);
      }
    }
  });

  _exports.default = _default;
});