define("recruit/templates/components/helpers/table-header-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cacdUgcG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"column\",\"extra\",\"tooltip\"]],\"header-popup\"],null]]]],[10,\"data-content\",[26,[[25,\"if\",[[20,[\"column\",\"extra\",\"tooltip\"]],[20,[\"column\",\"extra\",\"tooltip\"]],[20,[\"column\",\"label\"]]],null]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"column\",\"extra\",\"as\"]]],null,{\"statements\":[[4,\"link-to\",[\"recruiter.assessment.details.assessment-section\",[20,[\"column\",\"extra\",\"as\",\"assessment\",\"id\"]],[20,[\"column\",\"extra\",\"as\",\"id\"]]],[[\"class\"],[\"text-capitalize ellipsis text-capitalize\"]],{\"statements\":[[0,\"      \"],[1,[20,[\"column\",\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"ellipsis text-capitalize\"],[7],[0,\"\\n      \"],[1,[20,[\"column\",\"label\"]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"sortIconProperty\"]]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",[26,[\"lt-sort-icon \",[25,\"get\",[[20,[\"sortIcons\"]],[20,[\"sortIconProperty\"]]],null],\" text-blue\"]]],[9,\"style\",\"float: none\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/table-header-view.hbs"
    }
  });

  _exports.default = _default;
});