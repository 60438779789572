define("recruit/templates/recruiter/candidate/details/profile-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFkukQof",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments margin-no\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui blue segment\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui grid\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"four wide column padding-bg-left\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"three wide right floated column padding-bg-right\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[9,\"style\",\"height: calc(100vh - 230px); overflow-y:hidden; padding-bottom:30px;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"loading-item\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"style\",\"margin: -1rem 0rem;\"],[7],[0,\"\\n\"],[4,\"placeholder-repeat\",null,[[\"times\"],[40]],{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/candidate/details/profile-placeholder.hbs"
    }
  });

  _exports.default = _default;
});