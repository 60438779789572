define("recruit/models/menu-card-assessment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created_by: _emberData.default.belongsTo('user'),
    assessment_id: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    updated_by: _emberData.default.attr('string'),
    menu_card_id: _emberData.default.attr('string')
  });

  _exports.default = _default;
});