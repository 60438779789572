define("recruit/routes/recruiter/candidate/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/data/filters/r-ac"], function (_exports, _authenticatedRouteMixin, _rAc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      searchText: {
        replace: true
      },
      filters: {
        replace: true
      },
      isPendingInvite: {
        replace: true
      },
      isUnassigned: {
        replace: true
      },
      isPendingEval: {
        replace: true
      },
      isRecentEval: {
        replace: true
      }
    },
    currentUser: Ember.inject.service('current-user'),
    performance: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('store').push({
        data: _rAc.default
      });
      this.get('performance').start('Recruiter:Invites:list');
    },
    model: function model() {
      return Ember.RSVP.hash({
        masterFilters: this.store.peekAll('filter/master') //   appliedFilters: this.store.query('filter/applied', {objectType: 'ac'}),
        //   columnMaster: this.store.query('column/master', {table_type: 'r-c'}),
        //   columnApplied: this.store.findAll('column/applied')

      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set('selectedRows', Ember.A([]));
      controller.set('appliedFilters', Ember.A([]));
      controller.set('searchText', controller.get('searchText'));
      controller.set('quickFilters', Ember.Object.create({
        isPendingInvite: controller.get('isPendingInvite'),
        isUnassigned: controller.get('isUnassigned'),
        isPendingEval: controller.get('isPendingEval'),
        isRecentEval: controller.get('isRecentEval')
      }));
      controller.set('masterFilters', model.masterFilters.filterBy('objectType', 'ac')); // let appliedFilters = A([]);
      // // model.appliedFilters.forEach(filter => appliedFilters.pushObject(filter));
      // controller.set('appliedFilters', appliedFilters);
      // let columns = A([
      //   {
      //     component : "helpers/table-checkboxs",
      //     cellComponent : "helpers/row-checkbox",
      //     sortable : false,
      //     width : '40px',
      //     classNames : ['table-head padding-no table-head-checkbox-th'],
      //     cellClassNames : ['child padding-no'],
      //   }
      // ]);
      // model.columnMaster.forEach(column => {
      //   if(column.get('isColumnSelected')) {
      //     let modifiedColumn = column.toJSON();
      //     // modifiedColumn.width="200px";
      //     columns.pushObject(modifiedColumn);
      //   }
      // });
      // controller.set('currentUser', this.get('currentUser.me'));
      // return controller.set('columns', columns.sortBy('sortWeight'));
    },
    actions: {
      onFilterChange: function onFilterChange(quickFilters) {
        var _this = this;

        Object.keys(this.get('queryParams')).forEach(function (qp) {
          return _this.set("controller.".concat(qp), quickFilters.get(qp));
        });
      },
      willTransition: function willTransition(transition) {
        var _this2 = this;

        this.controller.set('searchText', null);
        var navRoutes = ["recruiter.pending-candidates", "recruiter.assessment.index", "recruiter.live-session.index", "recruiter.reports.dashboard", "dashboard.index", "user.settings.account"];
        navRoutes.forEach(function (routeName) {
          if (transition.targetName == routeName) {
            _this2.controller.setProperties({
              'filters': null,
              'isPendingInvite': null,
              'isUnassigned': null,
              'isPendingEval': null,
              'isRecentEval': null
            });

            return;
          }
        });
      }
    }
  });

  _exports.default = _default;
});