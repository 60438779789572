define("recruit/components/live-session/scheduling/calendar-occurrence", ["exports", "ember-calendar/components/as-calendar/timetable/occurrence"], function (_exports, _occurrence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import moment from 'moment';
  var _default = _occurrence.default.extend({
    store: Ember.inject.service(),
    showPopUp: false,
    isSaveDisabled: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        Ember.$().on('dblclick', function () {
          _this.set('showPopUp', true);
        });

        if (_this.get('model.content.isFresh')) {
          _this.set('showPopUp', true);
        }
      });
    },
    actions: {
      saveModel: function saveModel() {
        this.set('showPopUp', false);
        this.sendAction('createSession', this.get('timeZone')); // Ember.run.next(()=> {
        //   let _m = moment(model.get('to'));
        //   let to = moment(model.get('from'))
        //     .startOf('day')
        //     .add(_m.hours(),'hours')
        //     .add(_m.minutes(),'minutes')
        //     .add(_m.seconds(),'seconds')
        //     .toDate();
        //   //hack as model is already rendered
        //   let saveModel = this.get('store').createRecord('actions/live-schedule', {
        //     event                 : model.get('event'),
        //     startDate             : model.get('startDate'),
        //     endDate               : to,
        //     panel                 : model.get('panel'),
        //     assessmentCandidateID : model.get('assessmentCandidateID'),
        //     assessmentSectionID   : model.get('assessmentSectionID'),
        //     type                  : 1
        //   });
        //   if(!moment(saveModel.get('from')).isBefore(moment(saveModel.get('to')))) {
        //     return this.get('toast').error('Start time should be before end time', 'Invalid time');
        //   }
        //   this.set('isSaveDisabled', true)
        //   saveModel
        //   .save()
        //   .then(() => {
        //     this.get('toast').success('Scheduled', 'Successfully');
        //     this.set('isSaveDisabled', false)
        //     return this.get('router').transitionTo('recruiter.live-session');
        //   })
        //   .catch(() => {
        //     this.get('toast').error('Please try after some time', 'Something went wrong');
        //   })
        // });
      },
      revertModel: function revertModel(model) {
        this.set('showPopUp', false);
        this.sendAction('revertModel', model);
      }
    }
  });

  _exports.default = _default;
});