define("recruit/templates/components/recruiter-view/actions/delete-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z4s0YFKY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment padding-no\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui centered aligned grid margin-no text-size-normal\"],[9,\"style\",\"height:calc(60vh - 84px); overflow-y:auto;\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui row padding-no-top\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"middle aligned fourteen wide column text-center\"],[7],[0,\"\\n            \"],[6,\"img\"],[9,\"src\",\"/images/delete-asst.png\"],[9,\"alt\",\"\"],[9,\"class\",\"ui tiny centered image\"],[9,\"style\",\"width:110px !important\"],[7],[8],[0,\"\\n            \"],[6,\"p\"],[9,\"class\",\"text-size-small text-black text-weight-bold margin-top-thirty\"],[7],[0,\"The selected \"],[1,[18,\"listContain\"],false],[0,\" will be deleted from your account.\"],[8],[0,\"\\n            \"],[6,\"p\"],[9,\"class\",\"text-size-small margin-top-thirty text-red text-weight-medium\"],[9,\"style\",\"width:450px; margin:auto\"],[7],[0,\"These changes cannot be reverted, are you sure you want to permanently delete the \"],[1,[18,\"listContain\"],false],[0,\"?\"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\"\\n    \"],[1,[25,\"recruiter-view/actions/actions-footer\",null,[[\"isNegativeBasic\",\"disabled\",\"isPositiveLoading\",\"positiveButtonText\",\"negativeAction\",\"positiveAction\"],[true,[25,\"or\",[[20,[\"isLoading\"]],[25,\"not\",[[20,[\"noSelectedValue\"]]],null]],null],[20,[\"isLoading\"]],\"Confirm\",[25,\"action\",[[19,0,[]],\"back\"],null],[25,\"action\",[[19,0,[]],\"delete\"],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/actions/delete-view.hbs"
    }
  });

  _exports.default = _default;
});