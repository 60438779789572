define("recruit/templates/recruiter/live-session/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aMuOusFl",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Live Sessions\"],null],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"margin-top-hundred\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[9,\"style\",\"box-shadow:2px 2px 2px rgba(0,0,0,0.3); padding:0; margin-top:15px;\"],[7],[0,\"\\n      \"],[1,[25,\"filters/master-filter\",null,[[\"searchText\",\"appliedFilters\",\"masterFilters\",\"masterColumns\",\"columns\",\"objectType\",\"tableType\",\"filters\"],[[20,[\"searchText\"]],[20,[\"appliedFilters\"]],[20,[\"masterFilters\"]],[20,[\"model\",\"columnMaster\"]],[20,[\"columns\"]],\"r-ls\",\"r-ls\",[20,[\"filters\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"twelve wide column\"],[7],[0,\"\\n      \"],[1,[25,\"model-table/live-session/list\",null,[[\"searchText\",\"appliedFilters\",\"isRecruiter\",\"columns\"],[[20,[\"searchText\"]],[20,[\"appliedFilters\"]],true,[20,[\"columns\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/live-session/index.hbs"
    }
  });

  _exports.default = _default;
});