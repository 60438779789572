define("recruit/components/reports/table/panel/conversion-table", ["exports", "recruit/mixins/report-table-common"], function (_exports, _reportTableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTableCommon.default, {
    modelName: 'reports/panel',
    columns: Ember.computed(function () {
      return [{
        label: 'Panel',
        component: 'reports/helpers/assessment-header',
        classNames: ['table-head mis text-center border-right-grey'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        cellComponent: 'reports/helpers/user-details',
        sortable: false,
        width: '25%'
      }, {
        component: 'reports/helpers/column-with-toggle',
        classNames: ['table-head mis text-center'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        value: 'CONVERSION',
        sortable: false,
        subColumns: [{
          label: 'Candidates Assigned',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'panelAssignedCount',
          columnTooltip: 'Total candidates assigned to a selected panel in specified period of time.',
          width: '10.71%'
        }, {
          label: 'Total Evaluated',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'panelEvaluatedCount',
          columnTooltip: 'Total candidates evaluated by the selected panel out of the assigned candidates.',
          width: '10.71%'
        }, {
          label: 'Selected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'Total candidates selected out of the total evaluated candidates by the panel.',
          valuePath: 'panelSelectedPercent',
          width: '10.71%'
        }, {
          label: 'On-hold',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: ' Total candidates put on hold out of the total evaluated candidates by the panel.',
          valuePath: 'panelOnHoldPercent',
          width: '10.71%'
        }, {
          label: 'Rejected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'Total candidates rejected out of the total evaluated candidates by the panel.',
          valuePath: 'panelRejectedPercent',
          width: '10.71%'
        }, {
          label: 'Conversion',
          subLabel: '(in %)',
          component: 'reports/helpers/table-header',
          hideDot: true,
          classNames: ['table-head text-center'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The conversion(in percentage) from the total assigned candidates to the ones finally selected.',
          valuePath: 'panelConversionRate',
          width: '10.71%'
        }, {
          label: 'Turn around time',
          subLabel: '(in hours)',
          component: 'reports/helpers/table-header',
          hideDot: true,
          classNames: ['table-head text-center'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'Average time taken to evaluate a candidate.',
          valuePath: 'turnaroundTime',
          width: '10.71%'
        }]
      }];
    }),
    actions: {
      toggleGraph: function toggleGraph() {
        this.toggleProperty('hideGraph');
      }
    }
  });

  _exports.default = _default;
});