define("recruit/components/model-table/recruiter/assessment/candidate/candidate-table", ["exports", "recruit/mixins/table-common", "npm:lodash"], function (_exports, _tableCommon, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    modelType: 'assessmentCandidate',
    status: {
      sort_key: null,
      id: null,
      type: null
    },
    hasSingleSelection: Ember.computed('table.selectedRows', function () {
      return this.get('table.selectedRows.length') === 1;
    }),
    allowAddingPanel: Ember.computed('table.selectedRows', function () {
      var assessments = this.get('table.selectedRows').map(function (ac) {
        return +ac.get('content.assessment.id');
      });
      return _npmLodash.default.uniq(assessments).length === 1;
    }),
    selectedCandidates: Ember.computed('table.selectedRows.[]', function () {
      if (this.get('table.rows.length')) {
        return this.get('table.selectedRows').map(function (row) {
          return row.get('content');
        });
      }
    }),
    selectedCandidatesObserver: Ember.observer('selectedCandidates.[]', function () {
      this.sendAction('setCandidates', this.get('selectedCandidates'));
    }),
    customFilterObserver: Ember.observer('customListFilters.[]', 'sort', function () {
      var _this = this;

      var groupKeys = _npmLodash.default.keys(_npmLodash.default.groupBy(this.get('customListFilters'), 'type'));

      var q = Ember.A([]);
      groupKeys.forEach(function (key) {
        _npmLodash.default.groupBy(_this.get('customListFilters'), 'type')[key].forEach(function (obj) {
          if (obj.get('queryString')) {
            q.pushObject(obj.get('queryString'));
          }
        });
      });

      if (this.get('sort') && this.get('assessment_id')) {
        q.pushObject("(assessment.id: ".concat(this.get('assessment_id'), ")"));
      }

      this.setProperties({
        'panel_id': this.get('customListFilters').filterBy('type', 'panel').map(function (user) {
          return user.get('id');
        }),
        'status.sort_key': this.get('customListFilters').filterBy('type', 'finalStatus').map(function (st) {
          return st.get('sort_key');
        }),
        'status.type': this.get('customListFilters').filterBy('type', 'finalStatus').map(function (st) {
          return st.get('type_id');
        }),
        'event_id': this.get('customListFilters').filterBy('type', 'event').map(function (event) {
          return event.get('id');
        }),
        'q': q.join(' AND ')
      });
    }),
    _initProperties: function () {
      this.setProperties({
        'panel_id': null,
        'status.id': null,
        'status.sort_key': null,
        'status.type': null,
        'event_id': null
      });
      this.set('emptyStateWidth', Ember.$('#assessmentCandidateTable').innerWidth());
    }.on('init'),
    actions: {
      routeTransistion: function routeTransistion(row) {
        this.get('router').transitionTo('recruiter.candidate.details.assessment', row.get('candidate.id'), row.get('id'));
      },
      importcandidate: function importcandidate() {
        this.get('router').transitionTo('recruiter.assessment.details.import');
      }
    }
  });

  _exports.default = _default;
});