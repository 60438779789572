define("recruit/initializers/component-router-injector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'toast', 'service:toast');
    application.inject('component', 'toast', 'service:toast');
    application.inject('controller', 'toast', 'service:toast');
    application.inject('component', 'router', 'router:main');
  }

  var _default = {
    name: 'component-router-injector',
    initialize: initialize
  };
  _exports.default = _default;
});