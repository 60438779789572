define("recruit/templates/dashboard/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yPdStUdA",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/dashboard/redirect.hbs"
    }
  });

  _exports.default = _default;
});