define("recruit/templates/components/events/helpers/assessment-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MGCENBCc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"computedValue\",\"assessmentCandidate\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"computedValue\",\"assessmentName\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"margin-no text-weight-normal text-black ellipsis\"],[7],[1,[20,[\"computedValue\",\"assessmentName\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"computedValue\",\"assessmentCandidate\",\"assessment\",\"external_id\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"ellipsis text-grey text-size-small text-weight-light\"],[7],[1,[20,[\"computedValue\",\"assessmentCandidate\",\"assessment\",\"external_id\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"computedValue\",\"formInstance\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"ellipsis text-grey text-size-small text-weight-light\"],[7],[0,\"\\n      \"],[6,\"i\"],[7],[0,\"Registered to the Event\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"ellipsis text-grey text-size-small text-weight-light\"],[7],[0,\"\\n      \"],[1,[25,\"fa-icon\",[\"times-circle\"],[[\"class\"],[\"text-grey text-size-twelve margin-right-five\"]]],false],[0,\" \"],[6,\"i\"],[7],[0,\"Not registered\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/helpers/assessment-details-cell-component.hbs"
    }
  });

  _exports.default = _default;
});