define("recruit/templates/components/recruiter-view/assessment/assessment-overview/additional-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HUfN2n44",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"liquid-if\",[[20,[\"showMore\"]]],[[\"class\"],[\"liquid-accordion\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-black text-size-normal\"],[9,\"style\",\"margin: 15px 0;\"],[7],[0,\"\\n    Additional Details\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n    \"],[1,[25,\"helpers/render-custom-field\",null,[[\"customField\"],[[20,[\"model\",\"customFieldsSorted\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/assessment-overview/additional-information.hbs"
    }
  });

  _exports.default = _default;
});