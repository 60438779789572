define("recruit/components/recruiter-view/actions/assessment-invite", ["exports", "moment", "recruit/config/environment", "recruit/mixins/g-actions", "npm:lodash"], function (_exports, _moment, _environment, _gActions, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    endDate: (0, _moment.default)().add(10, 'd').toDate(),
    minDate: (0, _moment.default)().toDate(),
    isNotify: true,
    isLoading: false,
    isDirectInvite: false,
    isAssessmentFrozen: false,
    preFilledAssessment: Ember.computed('isIntegration', 'internalView', 'model.assessments.isFulfilled', function () {
      return this.get('isIntegration') || this.get('model.isAssessmentFrozen');
    }),
    didInsertElement: function didInsertElement() {
      //Prefilled assessment setup
      if (this.get('preFilledAssessment')) {
        var assessments = this.get('model.assessments');
        this.set('isDirectInvite', true);

        if (assessments && (Array.isArray(assessments) || Array.isArray(assessments.get('content').map(function (a) {
          return a;
        })))) {
          this.set('currentAssessment', assessments.get('firstObject'));
          this.set('selectedAssessment', assessments.get('firstObject'));
        }
      }
    },
    computedHeight: Ember.computed(function () {
      if (this.get('isIntegration')) {
        return Ember.String.htmlSafe('height:calc(80vh - 84px);overflow-y:auto;');
      }

      return Ember.String.htmlSafe('height:calc(60vh - 84px);overflow-y:auto;');
    }),
    assessmentSectionsObserver: Ember.observer('selectedAssessment', 'selectedAssessment.assessmentSections', function () {
      var _this = this;

      this.set('asFulfilled', false);

      if (this.get('selectedAssessment')) {
        if (this.get('isIntegration')) {
          this.get('selectedAssessment.assessmentSections').then(function (assessmentSections) {
            Ember.RSVP.Promise.all(assessmentSections.map(function (as) {
              return as.get('section');
            })).then(function () {
              assessmentSections = assessmentSections.filter(function (as) {
                return !as.get('section.isEvaluationOnly');
              });

              if (Ember.get(assessmentSections, 'length')) {
                _this.set('assessmentSections', assessmentSections);

                assessmentSections.set('firstObject.isFirst', true);
                assessmentSections.forEach(function (as, index) {
                  as.setProperties({
                    isLast: true,
                    index: index
                  });
                });

                _this.set('selectedAssessmentSection', assessmentSections.get('firstObject'));
              }

              _this.set('asFulfilled', true);
            });
          });
        } else if (this.get('model.assessmentSections.length') && this.get('preFilledAssessment')) {
          this.set('assessmentSections', this.get('model.assessmentSections'));
          this.set('selectedAssessmentSection', this.get('model.assessmentSections.firstObject'));
          this.set('preFilledAs', true);
          this.set('asFulfilled', true);
        } else {
          this.get('selectedAssessment.assessmentSections').then(function (assessmentSections) {
            if (Ember.get(assessmentSections, 'length')) {
              _this.set('assessmentSections', assessmentSections);

              assessmentSections.set('firstObject.isFirst', true);
              assessmentSections.forEach(function (as, index) {
                as.setProperties({
                  isLast: true,
                  index: index
                });
              });

              _this.set('selectedAssessmentSection', assessmentSections.get('firstObject'));
            }

            _this.set('asFulfilled', true);
          });
        }
      }
    }),
    disabled: Ember.computed('selectedAssessmentSection', 'assessmentSections.length', 'noSelectedValue', 'isLoading', 'selectedAssessmentSection.section.sectionType.isInvite', function () {
      return !this.get('selectedAssessmentSection.section.sectionType.isInvite') || this.get('isLoading') || !this.get('noSelectedValue') || !this.get('assessmentSections.length') || !this.get('selectedAssessmentSection');
    }),
    actions: {
      assessmentInvite: function assessmentInvite() {
        var _this2 = this;

        if (!this.get('endDate')) {
          return this.get('toast').error('', "End Date can't be blank");
        }

        this.set('isLoading', true);
        var candidates = this.get('selectedListValues').map(function (ac) {
          if (ac.get('_internalModel.modelName') == 'candidate') {
            if (ac.get('id')) {
              return {
                "id": ac.get('id')
              };
            } else {
              return {
                first_name: ac.get('first_name'),
                last_name: ac.get('last_name'),
                email: ac.get('email'),
                phone: ac.get('phone')
              };
            }
          } else {
            return {
              "id": ac.get('candidate.id')
            };
          }
        });
        var asscId = this.get('model.reinvite') ? this.get('model.assessmentSectionCandidates.firstObject.id') : null;
        var endPoint = this.get('model.reinvite') ? 'actions/re-invite' : 'actions/invite';
        var assessmentInvites = this.get('store').createRecord(endPoint, {
          candidates: candidates,
          externalAssessmentID: this.get('selectedExternalAssessment.id'),
          assessmentSectionID: this.get('selectedAssessmentSection.id'),
          isNotify: this.get('isNotify'),
          message: this.get('message'),
          endDate: (0, _moment.default)(this.get('endDate')).toISOString(),
          reinvite: this.get('model.reinvite'),
          assessmentSectionCandidateID: asscId
        });
        assessmentInvites.save().then(function () {
          _this2.get('toast').success('Candidate successfuly invited');

          if (_this2.get('model.redirectTo')) {
            return _npmLodash.default.includes(_this2.get('model.redirectTo'), 'recruiter.candidate.index') ? _this2.get('router').transitionTo(_this2.get('model.redirectTo')) : _this2.get('router').transitionTo(_this2.get('model.redirectTo'), _this2.get('model.assessments.firstObject.id'));
          } else {
            return _this2.send('back');
          }
        }).catch(function (e) {
          _this2.catchError(e);
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      selectAssessmentSection: function selectAssessmentSection(newAssesssmentSection) {
        // if(this.get('isIntegration')) {
        //   if(newAssesssmentSection.get('section.isLive')) {
        //     this.get('router').transitionTo('integration.live-schedule', {
        //       queryParams: {
        //         cid: this.get('candidates.firstObject.id'),
        //         aid: this.get('selectedAssessment.id')
        //       }
        //     })
        //   }
        // }
        if (!newAssesssmentSection.get('isFirst')) {
          var assessmentSections = this.get('assessmentSections') ? this.get('assessmentSections').toArray() : [];

          for (var i = 0; i < newAssesssmentSection.get('index'); i++) {
            var localAc = assessmentSections[i];
            localAc.set('isReject', true);
            localAc.set('isFirst', false);
          }

          for (i = newAssesssmentSection.get('index'); i < assessmentSections.length; i++) {
            var _localAc = assessmentSections[i];

            _localAc.set('isReject', false);

            _localAc.set('isFirst', false);
          }

          newAssesssmentSection.set('isFirst', true);
          this.set('selectedAssessmentSection', newAssesssmentSection);
        }
      }
    }
  });

  _exports.default = _default;
});