define("recruit/templates/components/events/panel-match-popup-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dln1r606",
    "block": "{\"symbols\":[\"panel\",\"index\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"matchedPanels\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"matchedPanels\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"events/panel-match-user\",null,[[\"panel\",\"index\",\"threshold\",\"extraPanels\",\"customClass\",\"class\"],[[19,1,[]],[19,2,[]],[20,[\"threshold\"]],[20,[\"extraPanels\"]],\"ui circular image events user-profile-small display-inline-block\",\"display-inline-block\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[4,\"if\",[[20,[\"extraPanels\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"events/panel-match-popup-list\",null,[[\"extraPanels\",\"threshold\"],[[20,[\"extraPanels\"]],[20,[\"threshold\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"i\"],[9,\"class\",\"text-grey\"],[7],[0,\"None\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/panel-match-popup-users.hbs"
    }
  });

  _exports.default = _default;
});