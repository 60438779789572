define("recruit/data/filters/r-ac", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 5773,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "First Name",
      "objectType": "ac",
      "queryTemplate": "({{condition}} candidate.first_name{{exact}}{{value}})"
    }
  }, {
    "id": 5772,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Last Name",
      "objectType": "ac",
      "queryTemplate": "({{condition}} candidate.last_name{{exact}}{{value}})"
    }
  }, {
    "id": 5783,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Email",
      "objectType": "ac",
      "queryTemplate": "({{condition}} candidate.email{{exact}}{{value}})"
    }
  }, {
    "id": 5775,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Phone",
      "objectType": "ac",
      "queryTemplate": "({{condition}} candidate.phone{{exact}}{{value}})"
    }
  }, {
    "id": 5782,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Invite status",
      "model": "status",
      "isRemote": true,
      "modelQuery": {
        "type": 10
      },
      "objectType": "ac",
      "queryTemplate": "(status_id:{{value}})"
    }
  }, {
    "id": 5778,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Invited date",
      "isRemote": false,
      "objectType": "ac",
      "queryTemplate": "(invited_at:[{{start}} TO {{end}} ])"
    }
  }, {
    "id": 5777,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Completion date",
      "isRemote": false,
      "objectType": "ac",
      "queryTemplate": "(attended_at:[{{start}} TO {{end}} ])"
    }
  }, {
    "id": 5780,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Assessment Name",
      "model": "assessment",
      "isRemote": true,
      "objectType": "ac",
      "queryTemplate": "(assessment_id:{{value}})"
    }
  }, {
    "id": 5779,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Assessment Owner",
      "model": "user",
      "isRemote": true,
      "modelQuery": {
        "roles": "Recruiter"
      },
      "objectType": "ac",
      "queryTemplate": "(assessment.owned_by:{{value}})"
    }
  }, {
    "id": 6223,
    "type": "filter/master",
    "attributes": {
      "list": [{
        "key": "High",
        "value": false
      }, {
        "key": "Medium",
        "value": false
      }, {
        "key": "Low",
        "value": false
      }],
      "type": "checkbox",
      "field": "Proview Index",
      "addColumn": true,
      "objectType": "ac",
      "queryTemplate": "meta.proview-rating:({{value}})"
    }
  }];
  _exports.default = _default;
});