define("recruit/templates/user/settings/capture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2wH+7kT",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui center aligned attached segment no-border\"],[7],[0,\"\\n    \"],[6,\"video\"],[9,\"autoplay\",\"\"],[9,\"id\",\"video\"],[9,\"style\",\"width:500px;\"],[7],[8],[0,\"\\n    \"],[6,\"canvas\"],[9,\"id\",\"canvas\"],[9,\"style\",\"display: none;\"],[7],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"output\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"id\",\"photo\"],[9,\"style\",\"width:500px; height:375px; display:block; margin:0 auto;\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui horizontal divider\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui center aligned segment actions margin-top-thirty\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isCaptured\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"ui blue button\"],[10,\"onClick\",[25,\"action\",[[19,0,[]],\"initializeCamera\"],null],null],[7],[0,\"\\n          Recapture\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"ui blue button\"],[10,\"onClick\",[25,\"action\",[[19,0,[]],\"capturePicture\"],null],null],[7],[0,\"\\n            Capture\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[6,\"div\"],[9,\"class\",\"ui red deny button\"],[10,\"onClick\",[25,\"action\",[[19,0,[]],\"back\"],null],null],[7],[0,\"\\n        Cancel\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/user/settings/capture.hbs"
    }
  });

  _exports.default = _default;
});