define("recruit/routes/recruiter/questions/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "npm:lodash"], function (_exports, _authenticatedRouteMixin, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      searchText: {
        replace: true
      },
      skills: {
        replace: true
      },
      created_by: {
        replace: true
      },
      type: {
        replace: true
      }
    },
    currentUser: Ember.inject.service('current-user'),
    model: function model() {
      return Ember.RSVP.hash({
        currentUser: this.get('currentUser.me'),
        organization: this.get('currentUser.me.organization'),
        skills: this.get('store').findAll('skill'),
        user: this.get('store').findAll('user')
      });
    },
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      this._super.apply(this, arguments);

      model.organization.getSectionTypeList(false);
      this.controller.set('availableSections', model.organization.availableSections);
      controller.set('skillModel', model.skills);
      controller.set('users', model.user);
      controller.set('showFilters', false);
      controller.set('showAppliedFilter', false);
      controller.set('hideFilter', false);
      controller.set('addedFilters', Ember.Object.create({
        skills: Ember.A([]),
        type: Ember.A([]),
        created_by: Ember.A([])
      }));
      controller.set('customListFilters', Ember.A([]));
      controller.set('appliedListFilters', null);

      if (transition.queryParams.skills || transition.queryParams.created_by || transition.queryParams.type) {
        this.controller.set('filters', Ember.A([]));
        controller.set('transition', transition);
        Ember.RSVP.resolve(this.controller.setFilterParams()).then(function () {
          if (controller.get('filters.length')) {
            controller.set('customListFilters', controller.get('filters'));
          }

          if (controller.get('customListFilters.length')) {
            controller.set('appliedListFilters', Ember.Object.create(_npmLodash.default.groupBy(_this.controller.get('customListFilters'), 'type')));
            controller.set('hideFilter', true);
          }
        });
      }
    },
    actions: {
      toggleFilter: function toggleFilter(property) {
        this.controller.set('hideFilter', true);
        this.controller.toggleProperty(property);
      },
      addFilter: function addFilter(filters) {
        var _this2 = this;

        filters.forEach(function (filter) {
          _this2.controller.get('customListFilters').pushObject(Ember.Object.create({
            type: filter.get('type'),
            id: filter.get('id'),
            label: filter.get('label')
          }));
        });
      },
      applyFilter: function applyFilter() {
        this.controller.get('customListFilters').clear();

        if (this.controller.get('addedFilters.skills.length')) {
          this.send('addFilter', this.controller.get('addedFilters.skills'));
        }

        if (this.controller.get('addedFilters.created_by.length')) {
          this.send('addFilter', this.controller.get('addedFilters.created_by'));
        }

        if (this.controller.get('addedFilters.type.length')) {
          this.send('addFilter', this.controller.get('addedFilters.type'));
        }

        this.send('toggleFilter', 'showFilters');
        this.controller.set('appliedListFilters', Ember.Object.create(_npmLodash.default.groupBy(this.controller.get('customListFilters'), 'type')));
      },
      removeAddedField: function removeAddedField(array, index, customListFilters) {
        if (customListFilters) {
          var selectedObject = array.objectAt(index);

          switch (selectedObject.get('type')) {
            case 'skills':
              {
                this.controller.get('addedFilters.skills').removeAt(_npmLodash.default.findIndex(this.controller.get('addedFilters.skills'), ['id', selectedObject.get('id')]));
                break;
              }

            case 'created_by':
              {
                this.controller.get('addedFilters.created_by').removeAt(_npmLodash.default.findIndex(this.controller.get('addedFilters.created_by'), ['id', selectedObject.get('id')]));
                break;
              }

            case 'type':
              {
                this.controller.get('addedFilters.type').removeAt(_npmLodash.default.findIndex(this.controller.get('addedFilters.type'), ['id', selectedObject.get('id')]));
                break;
              }
          }

          customListFilters.removeObject(selectedObject);
        }

        if (array.length) {
          array.removeAt(index);
        }
      },
      resetAddedFilters: function resetAddedFilters() {
        this.controller.setProperties({
          'addedFilters.skills': Ember.A([]),
          'addedFilters.created_by': Ember.A([]),
          'addedFilters.type': Ember.A([])
        });
      },
      resetAppliedFilters: function resetAppliedFilters() {
        this.controller.set('customListFilters', Ember.A([]));
        this.controller.setProperties({
          'skills': Ember.A([]),
          'created_by': Ember.A([]),
          'type': Ember.A([])
        });
        this.controller.set('appliedListFilters', null);
        this.send('resetAddedFilters');
        this.controller.set('showFilters', false);
      }
    }
  });

  _exports.default = _default;
});