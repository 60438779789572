define("recruit/components/recruiter-view/questions/create/mcq", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.set('addTitle', false);
      this.set('selectedSkills', Ember.A([]));
      this.set('preSelectedSkills', Ember.A([]));

      if (this.get('editComponent') && this.get('changeset.correct_choice')) {
        this.set('showMoreChoices', true);
      }

      var choiceArray = Ember.A([{
        choice: this.get('changeset._content.choice_1'),
        option: 1,
        property: "choice_1"
      }, {
        choice: this.get('changeset._content.choice_2'),
        option: 2,
        property: "choice_2"
      }, {
        choice: this.get('changeset._content.choice_3'),
        option: 3,
        property: "choice_3"
      }, {
        choice: this.get('changeset._content.choice_4'),
        option: 4,
        property: "choice_4"
      }, {
        choice: this.get('changeset._content.choice_5'),
        option: 5,
        property: "choice_5"
      }]);
      this.set('mcqChoiceArray', choiceArray);
    },
    sortChoices: function sortChoices(currectChoices) {
      return _npmLodash.default.sortBy(currectChoices.split('')).join('');
    },
    actions: {
      setValue: function setValue(value) {
        this.set('changeset.correct_choice', value);
      },
      setMultiValue: function setMultiValue(value, checked) {
        var choice = this.get('changeset.correct_choice');

        if (checked && choice && !choice.toString().includes(value)) {
          return this.set('changeset.correct_choice', +this.sortChoices("".concat(choice).concat(value)));
        } else if (checked && !choice) {
          return this.set('changeset.correct_choice', value);
        } else if (choice.toString().includes(value)) {
          var currentValue = choice.toString().replace(value, '');
          return this.set('changeset.correct_choice', currentValue ? +this.sortChoices(currentValue) : undefined);
        }
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      },
      showInput: function showInput(property) {
        this.send('toggleModal', property);
      },
      removeSkill: function removeSkill(skill, index) {
        this.sendAction('removeSkill', skill, index);
      },
      selectSkills: function selectSkills(skill) {
        this.sendAction('selectSkills', skill);
      },
      addMoreChoices: function addMoreChoices(property) {
        if (!this.get('editComponent')) {
          this.set('showProperty', true);
        }

        this.send('toggleModal', property);
      },
      uploadFile: function uploadFile(file, property) {
        if (file) {
          this.set("changeset.".concat(property), "<img src=\"".concat(file.resourceUrl, "\" height=\"50px\"/>"));
        } else {
          this.set("changeset.".concat(property), null);
        }
      },
      setTitle: function setTitle(property) {
        this.set('editComponent', true);
        this.set('addTitle', false);
        this.set('changeset.title', property);
      },
      editChoice: function editChoice(property) {
        this.send('toggleModal', property);
      },
      saveChoice: function saveChoice(image, changeset, property) {
        this.set('editComponent', true);
        this.sendAction('saveChoice', image, changeset, property);
      },
      saveChanges: function saveChanges(image, changeset, property) {
        this.set('editComponent', true);
        this.sendAction('saveChanges', image, changeset, property);
      }
    }
  });

  _exports.default = _default;
});