define("recruit/templates/activation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A+f/yv0m",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-wrapper\",null,[[\"showSupport\"],[[20,[\"showSupport\"]]]],{\"statements\":[[4,\"if\",[[20,[\"showSupport\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"/images/cuton_search.png\"],[9,\"alt\",\"cuton\"],[9,\"class\",\"ui image cuton-updown\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ten wide middle aligned left aligned column\"],[7],[0,\"\\n      \"],[6,\"h1\"],[9,\"class\",\"ui blue header margin-bottom-no\"],[7],[0,\"Invalid Link\"],[8],[0,\"\\n      \"],[6,\"h5\"],[9,\"class\",\"ui center aligned grey text margin-no\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"errorMsg\"]]],null,{\"statements\":[[0,\"          \"],[1,[18,\"errorMsg\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"/images/hourglass.gif\"],[9,\"alt\",\"cuton\"],[9,\"class\",\"ui image\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ten wide middle aligned left aligned column\"],[7],[0,\"\\n      \"],[6,\"h5\"],[9,\"class\",\"ui center aligned grey text\"],[7],[0,\"\\n        Please wait while we are activating your account.\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/activation.hbs"
    }
  });

  _exports.default = _default;
});