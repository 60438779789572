define("recruit/templates/components/range-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FNg9UeR7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui calendar display-inline-block\"],[10,\"id\",[26,[[18,\"startDateID\"]]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui input\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"text\",\"From\",[20,[\"startDate\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ui calendar display-inline-block\"],[10,\"id\",[26,[[18,\"endDateID\"]]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui input\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"text\",\"To\",[20,[\"endDate\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/range-date-picker.hbs"
    }
  });

  _exports.default = _default;
});