define("recruit/templates/components/recruiter-helpers/sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c5EVsp6J",
    "block": "{\"symbols\":[\"assc\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"\"],[9,\"style\",\"display:table;\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"value\"]]],null,{\"statements\":[[0,\"\\t\"],[6,\"div\"],[9,\"class\",\"\"],[9,\"style\",\"display:table-cell;\"],[7],[0,\"\\n\\t\\t\"],[1,[25,\"helpers/status-icon\",null,[[\"status\",\"score\",\"sectionType\",\"isScore\"],[[19,1,[\"status\"]],[19,1,[\"score\"]],[19,1,[\"section\",\"sectionType\",\"label\"]],false]]],false],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"],[11,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-helpers/sections.hbs"
    }
  });

  _exports.default = _default;
});