define("recruit/components/recruiter-view/candidate/create/create-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isBulkUpload: false,
    showHeader: Ember.computed('router.currentPath', function () {
      return !this.get('router.currentPath').includes('bulk-create');
    }),
    toggleBtnObserver: Ember.observer('isBulkUpload', function () {
      if (!this.get('isBulkUpload')) {
        this.set('isBulkUpload', true);
      }
    })
  });

  _exports.default = _default;
});