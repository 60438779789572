define("recruit/routes/recruiter/candidate/details/authentication", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query('candidate-auth/identity', {
        v: (0, _moment.default)().unix()
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('currentDocType', 'picture');
      model.get('firstObject.files').then(function () {
        return controller.set('currentDoc', model.get('firstObject.authPhoto'));
      });
      controller.set('candidate', model.get('firstObject'));
    },
    actions: {
      toggleDoc: function toggleDoc(doc, type) {
        this.controller.set('currentDoc', doc);
        this.controller.set('currentDocType', type);
      },
      toggleModal: function toggleModal() {
        this.controller.toggleProperty('isModalOpen');
      }
    }
  });

  _exports.default = _default;
});