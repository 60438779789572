define("recruit/templates/recruiter/recruiter-actions/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NLx+nu8n",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"recruiter-actions/actions\",null,[[\"model\",\"listValues\"],[[20,[\"model\"]],[20,[\"model\",\"storeRelation\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/recruiter-actions/actions.hbs"
    }
  });

  _exports.default = _default;
});