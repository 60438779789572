define("recruit/components/assessment-helpers/create-parameters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    model: Ember.computed('openModal', function () {
      return this.get('store').createRecord('parameter');
    }),
    willDestroyElement: function willDestroyElement() {
      if (this.get('model.hasDirtyAttributes')) {
        this.get('model').destroyRecord();
      }
    },
    actions: {
      toggleModal: function toggleModal() {
        this.sendAction('toggleModal', 'openModal');
      },
      create: function create(model) {
        var parameterName = model.get('name');
        var parameterMaxValue = model.get('max_value');
        var recommendedScore = model.get('recommended_value');

        if (!parameterName || !parameterName.length) {
          Ember.$('.parameterName').focus();
          this.set('errorMessage', 'Parameter name can\'t be blank.');
          return this.set('showError', true);
        }

        if (!parseInt(parameterMaxValue, 10)) {
          Ember.$('.parameterMaxValue').focus();
          this.set('errorMessage', 'Max Value can\'t be blank');
          return this.set('showError', true);
        }

        if (parseInt(parameterMaxValue, 10) < 0) {
          Ember.$('.parameterMaxValue').focus();
          this.set('errorMessage', 'Max Value can\'t be negative');
          return this.set('showError', true);
        }

        if (parseInt(recommendedScore, 10) < 0) {
          Ember.$('.recommendedScore').focus();
          this.set('errorMessage', 'Recommended score can\'t be negative');
          return this.set('showError', true);
        }

        if (parseInt(recommendedScore, 10) > parseInt(parameterMaxValue, 10)) {
          Ember.$('.recommendedScore').focus();
          this.set('errorMessage', 'Recommended score can\'t be more than the max value.');
          return this.set('showError', true);
        } else {
          this.set('errorMessage', null);
          this.set('showError', false);
        }

        this.sendAction('createParameter', model);
      }
    }
  });

  _exports.default = _default;
});