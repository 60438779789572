define("recruit/models/actions/section-workflow", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "parent": _emberData.default.attr('number'),
    "child": _emberData.default.attr('number'),
    "assessmentID": _emberData.default.attr('number'),
    "type": _emberData.default.attr('string'),
    "section": _emberData.default.attr(),
    "sync": _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});