define("recruit/templates/components/assessment/question/question-based-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J1gx7iLN",
    "block": "{\"symbols\":[\"question\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"sectionModel\",\"sectionType\"]]],null,{\"statements\":[[4,\"helpers/searchable-dropdown\",null,[[\"allowClear\",\"searchEnable\",\"isRemoteSearch\",\"renderOptionInPlace\",\"searchField\",\"placeHolder\",\"renderInPlace\",\"optionLabel\",\"modelName\",\"section_type_id\",\"selectedOption\",\"searchEnabled\"],[true,true,true,true,\"plainTxtContent\",\"Search questions\",false,\"plainTxtContent\",\"question\",[20,[\"section_type_id\"]],[20,[\"selectedQuestion\"]],true]],{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"text-black text-weight-medium\"],[7],[1,[19,1,[\"title\"]],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"text-black\"],[7],[1,[19,1,[\"plainTxtContent\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/assessment/question/question-based-dropdown.hbs"
    }
  });

  _exports.default = _default;
});