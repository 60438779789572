define("recruit/templates/components/live-session/helpers/live-modal-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y4/T6+eq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segment text-center margin-no border-radius-none border-none\"],[7],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"text-weight-medium text-uppercase text-black\"],[7],[0,\"\\n    \"],[1,[18,\"setModalTitle\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"displayTitle\"]],\"isAssessmentJD\"],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui segment border-none\"],[9,\"style\",\"height:calc(100vh - 300px)\"],[7],[0,\"\\n    \"],[1,[25,\"iframe-html\",null,[[\"src\",\"class\"],[[20,[\"src\"]],\"iframe-html-height\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"file\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui segment border-none\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"id\",\"tv-resume-viewer\"],[9,\"style\",\"height:calc(100vh - 300px); overflow: auto;\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui active inverted dimmer\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui text loader\"],[7],[0,\"\\n      Loading ...\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[6,\"div\"],[9,\"class\",\"ui segment text-right margin-no border-radius-none border-none padding-no-top\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"ui basic button teal text-capitalize cursor-pointer\"],[3,\"action\",[[19,0,[]],\"toggleModal\",\"openModal\"]],[7],[0,\"\\n    close\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-session/helpers/live-modal-viewer.hbs"
    }
  });

  _exports.default = _default;
});