define("recruit/controllers/panel/live-session/join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['skipEnddate'],
    skipEnddate: false //For skipping session expiry date validation

  });

  _exports.default = _default;
});