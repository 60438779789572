define("recruit/components/helpers/see-more", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    shownFullText: false,
    content: null,
    length: 300,
    separator: null,
    modifiedContent: Ember.computed('content', function () {
      if (this.get('content') && this.get('content.length') > this.get('length')) {
        return this.truncateText(this.get('content'));
      } else {
        return this.get('content');
      }
    }),
    isSeeMoreVisible: Ember.computed('content', 'hasAdditionalContent', function () {
      return this.get('content.length') > this.get('length') || this.get('hasAdditionalContent');
    }),
    truncateText: function truncateText(text) {
      return _npmLodash.default.truncate(text, {
        'length': this.get('length'),
        'separator': this.get('separator')
      });
    },
    actions: {
      toggleText: function toggleText() {
        this.toggleProperty('shownFullText');
      }
    }
  });

  _exports.default = _default;
});