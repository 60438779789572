define("recruit/templates/components/dark-modal-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AyKTTQ1G",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui container full-height\"],[7],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[4,\"if\",[[20,[\"showSupport\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui grid\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui center aligned centered column row\"],[7],[0,\"\\n        Contact Support :\\n        \"],[6,\"a\"],[9,\"href\",\"mailto:support@talview.com?&subject=Page Not Found?&body=Page%20not%20found\"],[7],[0,\"support@talview.com\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"\"],[9,\"style\",\"position:fixed; bottom:30px; right:30px;\"],[7],[0,\"\\n\\n    \"],[6,\"img\"],[9,\"src\",\"/images/poweredby.png\"],[9,\"alt\",\"\"],[9,\"class\",\"ui small image\"],[7],[8],[0,\"\\n\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/dark-modal-wrapper.hbs"
    }
  });

  _exports.default = _default;
});