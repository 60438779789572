define("recruit/components/reports/table/time-range/conversion", ["exports", "recruit/mixins/report-table-common"], function (_exports, _reportTableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTableCommon.default, {
    modelName: 'reports/time-range-conversion',
    group_by: 'week',
    columns: Ember.computed('selectedGraphFilter', function () {
      return [{
        label: 'Invited In',
        classNames: ['table-head mis text-center border-right-grey'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        width: '23%',
        sortable: false,
        model: this.get('model'),
        selectedGraphFilter: this.get('selectedGraphFilter'),
        valuePath: 'date',
        cellComponent: 'reports/helpers/time-range-details'
      }, {
        sortable: false,
        component: 'reports/helpers/column-with-toggle',
        value: "Avg time to process ( in ".concat(this.get('selectedGraphFilter'), "s )"),
        classNames: ['table-head mis text-center'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        subColumns: [{
          label: 'Total Invitations',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The cumulative number of candidates invited in the specified time period.',
          valuePath: 'invitedCount',
          width: '11%'
        }, {
          label: 'Candidates Attempted',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates that have attempted the assessment, who were invited in mentioned period of time.',
          width: '11%',
          valuePath: 'inprogressPercent'
        }, {
          label: 'Candidates Completed',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates that have completed the assessment, who were invited in mentioned period of time.',
          width: '11%',
          valuePath: 'completedPercent'
        }, {
          label: 'Selected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          columnTooltip: 'The total number of candidates shortlisted, out of those invited in the mentioned period of time.',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'selectedPercent',
          width: '11%'
        }, {
          label: 'On-hold',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates on hold, out of those invited in the mentioned period of time.',
          valuePath: 'onHoldPercent',
          width: '11%'
        }, {
          label: 'Rejected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates rejected, out of those invited in the mentioned period of time.',
          valuePath: 'rejectedPercent',
          width: '11%'
        }, {
          label: 'Overall Conversion',
          subLabel: "( ".concat(this.get('selectedGraphFilter'), "s )"),
          component: 'reports/helpers/table-header',
          valuePath: 'conversionRate',
          classNames: ['table-head mis text-center'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'Conversion (in percentage) from the invite to the final selection of the candidates who were invited in mentioned period of time.',
          width: '11%'
        }]
      }];
    }),
    actions: {
      onRowClick: function onRowClick(row) {
        row.set('selected', false);
      },
      toggleGraph: function toggleGraph() {
        this.toggleProperty('hideGraph');
      }
    }
  });

  _exports.default = _default;
});