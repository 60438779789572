define("recruit/templates/components/table-resume-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xwmRjpzc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"modelInstance\",\"resume\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"candidate-helpers/resume-uploader\",null,[[\"candidate\",\"exsistingFiles\",\"resumeColumn\"],[[20,[\"modelInstance\",\"content\"]],[20,[\"modelInstance\",\"resume\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"i\"],[7],[0,\"Loading...\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/table-resume-uploader.hbs"
    }
  });

  _exports.default = _default;
});