define("recruit/components/recruiter-view/assessment/helpers/notification-template-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    template: Ember.computed('notificationTemplates.isFulfilled', 'selectedTemplate', function () {
      var _this = this;

      if (this.get('notificationTemplates.length') && this.get('selectedTemplate.type_id')) {
        return this.get('notificationTemplates').find(function (template) {
          return template.get('type_id') == _this.get('selectedTemplate.type_id');
        });
      }
    }),
    templateObserver: Ember.observer('template', function () {
      var _this2 = this;

      if (this.get('template')) {
        this.get('template.tags').then(function (tags) {
          _this2.set('supportedTags', tags);
        });
      }
    }),
    resetTemplate: function resetTemplate() {
      this.get('notificationTemplates').forEach(function (template) {
        if (template.get('hasDirtyAttributes')) {
          template.rollbackAttributes();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.resetTemplate();
    },
    disabled: Ember.computed('template.hasDirtyAttributes', function () {
      return !this.get('template.hasDirtyAttributes');
    }),
    actions: {
      toggleSideBar: function toggleSideBar() {
        this.sendAction('toggleSideBar');
      },
      save: function save(template) {
        this.sendAction('save', template);
      },
      back: function back() {
        window.history.back();
      },
      contentsChanged: function contentsChanged(editor) {
        this.set('template.content', editor);
      }
    }
  });

  _exports.default = _default;
});