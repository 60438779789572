define("recruit/components/status-helpers/live", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    statusColor: null,
    isBasic: false,
    didReceiveAttrs: function didReceiveAttrs() {
      var v = this.get('value');

      switch (_npmLodash.default.toLower(v)) {
        case 'completed':
          this.set('statusColor', 'green');
          break;

        case 'scheduled':
          this.set('statusColor', 'blue');
          break;

        case 'in progress':
          this.set('statusColor', 'yellow');
          break;

        case 'cancelled':
          this.set('statusColor', 'red');
          break;

        case 'panel no show':
          this.set('statusColor', 'grey');
          this.set('isBasic', true);
          break;

        case 'candidate no show':
          this.set('statusColor', 'grey');
          this.set('isBasic', true);
          break;

        case 'both no show':
          this.set('statusColor', 'grey');
          this.set('isBasic', true);
          break;

        case 'active':
          this.set('statusColor', 'green');
          break;

        case 'inactive':
          this.set('statusColor', 'grey');
          break;

        case 'attended':
          this.set('statusColor', 'green');
          break;

        case 'left':
          this.set('statusColor', 'brown');
          break;

        case 'no show':
          this.set('statusColor', 'grey');
          break;

        case 'ready':
          this.set('statusColor', 'yellow');
          break;

        default:
          this.set('statusColor', 'grey');
          break;
      }
    }
  });

  _exports.default = _default;
});