define("recruit/adapters/candidate-auth/base", ["exports", "recruit/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: 'https://candidate-api.talview.com',
    namespace: 'auth',
    headers: Ember.computed(function () {
      return {
        "Authorization": 'Bearer ' + localStorage.getItem('tv_c_authToken')
      };
    }).property().volatile()
  });

  _exports.default = _default;
});