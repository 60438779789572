define("recruit/components/helpers/remote-searchbox", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(Ember.TextSupport, {
    store: Ember.inject.service(),
    tagName: 'input',
    interval: 1000,
    attributeBindings: ['placeholder', 'value', 'type'],
    value: null,
    s: Ember.computed.alias('value'),
    valueObserver: Ember.observer('value', function () {
      var _this = this;

      this.set('isLoadingList', true);
      var searchList = Ember.A([]);
      Ember.run.debounce(function () {
        if (_this.get('value') && _this.get('key')) {
          searchList = _this.get('defaultList').filter(function (obj) {
            return obj.get("".concat(_this.get('key'))).toLowerCase().includes(_this.get('value').toLowerCase().trim());
          });
          Ember.RSVP.resolve(searchList).then(function () {
            if (!searchList.get('length') && !_this.get('isLocalSearch')) {
              var query = _npmLodash.default.omitBy(_this.getProperties(['roles', 's']), _npmLodash.default.isNil);

              _npmLodash.default.merge(query, _this.get('modelQuery') || {});

              return _this.get('store').query(_this.get('modelName'), query).then(function (res) {
                searchList = res.map(function (obj) {
                  return obj;
                });
              });
            }
          }).then(function () {
            _this.sendAction('getList', searchList, _this.get('modelName'));
          });
        } else {
          _this.set('listSource', _this.get('defaultList'));
        }
      }, this.get('interval'));
    })
  });

  _exports.default = _default;
});