define("recruit/templates/components/spinkit-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBtkZixp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n      \"],[6,\"div\"],[9,\"class\",\"sk-circle\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle1 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle2 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle3 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle4 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle5 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle6 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle7 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle8 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle9 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle10 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle11 sk-child\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-circle12 sk-child\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/spinkit-circle.hbs"
    }
  });

  _exports.default = _default;
});