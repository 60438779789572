define("recruit/templates/recruiter/questions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5f8EmkWD",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"recruiter-view/questions/list-view-index\",null,[[\"type\",\"users\",\"skills\",\"skillModel\",\"searchText\",\"created_by\",\"hideFilter\",\"showFilters\",\"addedFilters\",\"availableSections\",\"customListFilters\",\"appliedListFilters\",\"applyFilter\",\"removeAddedField\",\"resetAddedFilters\",\"resetAppliedFilters\"],[[20,[\"type\"]],[20,[\"users\"]],[20,[\"skills\"]],[20,[\"skillModel\"]],[20,[\"searchText\"]],[20,[\"created_by\"]],[20,[\"hideFilter\"]],[20,[\"showFilters\"]],[20,[\"addedFilters\"]],[20,[\"availableSections\"]],[20,[\"customListFilters\"]],[20,[\"appliedListFilters\"]],\"applyFilter\",\"removeAddedField\",\"resetAddedFilters\",\"resetAppliedFilters\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/questions/index.hbs"
    }
  });

  _exports.default = _default;
});