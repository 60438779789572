define("recruit/components/events/event-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.later('afterRender', function () {
        Ember.$('.linkedin-div').find('svg').replaceWith("<img src='/images/icons/LinkedIn_logo_initials.png'/>");
      });
    },
    actions: {
      toggleModal: function toggleModal() {
        this.sendAction('toggleModal');
      },
      copyLink: function copyLink(text) {
        this.get('toast').success(text.text, 'Link copied to clipboard');
      },
      download: function download(QRCodeUrl) {
        var a = Ember.$("<a>").attr("href", QRCodeUrl).attr("target", "_blank").attr("download", "QR.png").appendTo("body");
        a[0].click();
      },
      bulkImportCandidates: function bulkImportCandidates(property) {
        var _this = this;

        this.sendAction('toggleModal');
        Ember.run.later(function () {
          _this.sendAction('bulkImportCandidates', property);
        }, 500);
      }
    }
  });

  _exports.default = _default;
});