define("recruit/components/reports/dashboard/dashboard-landing", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.set('filterValue', null);

      if (this.get('model.length')) {
        this.setList(this.get('model'));
      }
    },
    setList: function setList(column) {
      var _this = this;

      column.forEach(function (col) {
        var obj = {
          title: " Conversion: ".concat(col.get('label')),
          type: 'Advanced',
          parameter: col,
          graphName: 'recruiter-conversion',
          description: "The conversion of <b> ".concat(col.get('label'), " </b> from the invite stage to the completed stage in an assessment."),
          redirectUrl: 'recruiter.reports.advanced.conversion.conversion-index'
        };

        _this.get('list').pushObject(obj);
      });
      column.forEach(function (col) {
        var obj = {
          title: " Time to Process: ".concat(col.get('label')),
          type: 'Advanced',
          parameter: col,
          graphName: 'time-to-hire',
          description: "The average time a candidate takes to complete an assessment.",
          redirectUrl: 'recruiter.reports.advanced.time.time-to-process'
        };

        _this.get('list').pushObject(obj);
      });
    },
    filteredList: Ember.computed('filterValue', function () {
      var filterValue = _npmLodash.default.toLower(this.get('filterValue'));

      if (!filterValue) {
        return this.get('list');
      }

      return this.get('list').filter(function (tile) {
        return _npmLodash.default.toLower(tile.type).includes(filterValue) || _npmLodash.default.toLower(tile.title).includes(filterValue) || _npmLodash.default.toLower(tile.text).includes(filterValue) || _npmLodash.default.toLower(tile.description).includes(filterValue);
      });
    }),
    dashboardList: Ember.computed('filteredList', function () {
      var tiles = this.get('filteredList'),
          groups = [];
      tiles.forEach(function (tile) {
        var hasGroup = groups.findBy('type', tile.type);

        if (!hasGroup) {
          groups.pushObject(Ember.Object.create({
            type: tile.type,
            tiles: []
          }));
        }

        groups.findBy('type', tile.type).get('tiles').pushObject(tile);
      });
      return groups;
    }),
    actions: {
      clearText: function clearText(filterValue) {
        if (filterValue) {
          this.set('filterValue', null);
        }
      } // searchReports(value) {
      //   this.set('filterValue', value)
      // }

    }
  });

  _exports.default = _default;
});