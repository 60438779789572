define("recruit/routes/panel/event/details/register", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      event_user_id: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set('model', model);

      if (transition.queryParams.event_user_id) {
        this.get('store').findRecord('event-user', transition.queryParams.event_user_id).then(function (eu) {
          controller.set('eventUser', eu);

          if (eu.get('status') == 2) {
            _this.get('router').transitionTo('panel.event.details.response', {
              queryParams: {
                status: 'accept',
                panel_id: eu.get('panel.id'),
                event_user_id: eu.get('id')
              }
            });
          } else if (eu.get('status') == 3) {
            _this.get('router').transitionTo('panel.event.details.response', {
              queryParams: {
                status: 'decline',
                event_user_id: eu.get('id')
              }
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});