define("recruit/templates/recruiter/events/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "76Xkcxkd",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"events/event-list\",null,[[\"searchText\",\"isEventsEnabled\",\"isLoaded\"],[[20,[\"searchText\"]],[20,[\"isEventsEnabled\"]],[20,[\"isLoaded\"]]]]],false],[0,\"\\n\"],[1,[18,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/events/index.hbs"
    }
  });

  _exports.default = _default;
});