define("recruit/models/lf-adapter/skill", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    external_id: _emberData.default.attr('number'),
    qCount: _emberData.default.attr('number'),
    requiredCount: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    skill_id: _emberData.default.attr('number') // questions             : DS.hasMany('question'),

  });

  _exports.default = _default;
});