define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-section-counts", ["exports", "npm:lodash", "moment"], function (_exports, _npmLodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').query('assessmentSectionCandidate', {
        assessment_section_id: this.get('assessmentSection.id')
      }).then(function (response) {
        _this.get('sectionCandidates').pushObject({
          total: +response.get('meta.total'),
          title: _this.get('assessmentSection.section.title'),
          sectionType: _this.get('assessmentSection.section.sectionType.label'),
          index: _this.get('asIndex'),
          as: _this.get('assessmentSection')
        });

        if (_this.get('totalSections') === _this.get('sectionCandidates.length')) {
          _this.set('sectionCandidates', _npmLodash.default.orderBy(_this.get('sectionCandidates'), 'index', 'asc'));

          _this.sendAction('contentLoaded', true);
        }
      });
      this.get('store').query('panel', {
        assessment_section_id: this.get('assessmentSection.id'),
        expand: ['evaluatedCount', 'pending', 'assignedCount'].join(','),
        v: (0, _moment.default)().unix()
      }).then(function (res) {
        if (res.get('length')) {
          res.forEach(function (panel) {
            if (!_this.get('allPanels').findBy('id', panel.get('id'))) {
              _this.get('allPanels').pushObject(panel);
            }
          });
        }
      }).catch(function (err) {
        if (err.errors) {
          err.errors.forEach(function (e) {
            _this.get('toast').error(e.detail, 'Error');
          });
        } else {
          _this.get('toast').error('Please try after some time', 'Something went wrong');
        }
      });
    }
  });

  _exports.default = _default;
});