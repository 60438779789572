define("recruit/templates/components/candidate-files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SMrfR2u0",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\" \"],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"text-blue cursor-pointer\"],[7],[0,\"\\n      Files\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"files\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"style\",\"background: #fff;\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\"\\n          \"],[6,\"a\"],[10,\"download\",[19,1,[\"originalName\"]],null],[10,\"href\",[19,1,[\"resourceUrl\"]],null],[9,\"target\",\"_blank\"],[7],[0,\"\\n            \"],[6,\"i\"],[9,\"class\",\"download blue large icon\"],[7],[8],[0,\"\\n            \"],[6,\"span\"],[7],[1,[19,1,[\"originalName\"]],false],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\" \"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-files.hbs"
    }
  });

  _exports.default = _default;
});