define("recruit/templates/recruiter/events/details/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vcazfqsg",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"events/event-details\",null,[[\"eventModel\",\"selectedEvent\"],[[20,[\"event\"]],[20,[\"event\",\"selectedEvent\"]]]]],false],[0,\"\\n\"],[1,[25,\"events/assessment-view\",null,[[\"class\",\"eventModel\",\"currentUser\",\"userRole\"],[\"ui segments border-none box-shadow-none\",[20,[\"event\"]],[20,[\"currentUser\"]],[20,[\"userRole\"]]]]],false],[0,\"\\n\"],[1,[25,\"events/panel-view\",null,[[\"class\",\"eventModel\",\"eventPanels\",\"currentUser\",\"userRole\"],[\"ui segments border-none box-shadow-none\",[20,[\"event\"]],[20,[\"event\",\"panel\"]],[20,[\"currentUser\"]],[20,[\"userRole\"]]]]],false],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/events/details/overview.hbs"
    }
  });

  _exports.default = _default;
});