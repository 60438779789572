define("recruit/components/model-table/recruiter/candidate-row-component-old", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    performance: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      if (Ember.$('#horizontalScrollElement') && Ember.$('#horizontalScrollElement').length) {
        Ember.run.next('afterRender', function () {
          Ember.$('#horizontalScrollElement').trigger('scroll');
        });
      }
    },
    performanceObserver: Ember.observer('row.isRelationsLoaded', function () {
      var _this = this;

      Ember.run.once('afterRender', function () {
        var rows = _this.get('table.rows');

        if (rows.get('length') <= 25 && rows.get('lastObject.id') === _this.get('row.id') && _this.get('row.isRelationsLoaded')) {
          switch (_this.get('table.tableType')) {
            case "r_c_list":
              _this.get('performance').end('Recruiter:Candidates:list');

              break;

            case "r_ac_list":
              _this.get('performance').end('Recruiter:Invites:list');

              break;

            case "r_a_ac_list":
              _this.get('performance').end('Recruiter:Assessment:Invites:list');

              break;

            case "r_a_assc_list":
              _this.get('performance').end('Recruiter:Assessment:Section:list');

              break;
          }
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});