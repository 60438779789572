define("recruit/components/live-session/view", ["exports", "recruit/config/environment", "recruit/mixins/g-actions"], function (_exports, _environment, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    liveSessions: Ember.computed(function () {
      return Ember.A([this.get('model')]);
    }),
    setInternalView: function setInternalView() {
      return {
        key: 'internalView',
        value: true,
        type: 'flag'
      };
    },
    actions: {
      reschedule: function reschedule(model) {
        this.send('routeActions', model, 'liveSession', "reschedule session", this.setInternalView());
      },
      cancelSession: function cancelSession(model) {
        this.send('routeActions', model, 'liveSession', "cancel session", this.setInternalView());
      },
      managePanel: function managePanel(model) {
        this.send('routeActions', model, 'liveSession', "manage attendees", this.setInternalView());
      },
      suggestPanel: function suggestPanel(model) {
        this.send('routeActions', model, 'liveSession', "suggest attendees", this.setInternalView());
      },
      askReschedule: function askReschedule(model) {
        this.send('routeActions', model, 'liveSession', "ask for reschedule", this.setInternalView());
      },
      askCancel: function askCancel(model) {
        this.send('routeActions', model, 'liveSession', "ask for cancellation", this.setInternalView());
      },
      remindAll: function remindAll(attendee) {
        var _this = this;

        var remind = this.get('store').createRecord('actions/live-remind', {
          liveSessionID: [{
            id: this.get('model.id')
          }]
        });

        if (attendee) {
          remind.set('liveAttendees', [{
            id: attendee.get('id')
          }]);
        }

        remind.save().then(function () {
          return _this.get('toast').success('Reminder sent', 'Successful');
        }).catch(function (e) {
          if (e.errors.length) {
            e.errors.forEach(function (error) {
              _this.get('toast').error(error.detail, 'Error!');
            });
          } else {
            _this.get('toast').error('Please try some time later', 'Something went wrong');
          }
        });
      } // openModal(modalName) {
      //   $('.ui.' + modalName + '.modal').modal('show');
      // },
      // approveModal: function(element, component) {
      //   alert('approve ' + component.get('name'));
      //   return false;
      // },
      // denyModal: function(element, component) {
      //   alert('deny ' + component.get('name'));
      //   return true;
      // }

    }
  });

  _exports.default = _default;
});