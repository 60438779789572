define("recruit/templates/components/recruiter-view/assessment/helpers/notification-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6nGWeIP8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"statusLabel\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"class\",[26,[\"ui \",[20,[\"value\",\"color\"]],\" basic label text-capitalize assessment-notification-status\"]]],[7],[0,\"\\n    \"],[1,[20,[\"value\",\"label\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"isTemplateType\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"class\",\"ellipsis text-capitalize\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"circle small icon assessment-notification-status\"],[10,\"style\",[18,\"typeColor\"],null],[7],[8],[0,\"\\n    \"],[1,[20,[\"templateType\",\"label\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"statusText\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"class\",[26,[[20,[\"value\",\"color\"]],\" assessment-notification-status\"]]],[7],[0,\"\\n    \"],[1,[20,[\"value\",\"label\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"i\"],[9,\"class\",\"circle small icon assessment-notification-status\"],[10,\"style\",[18,\"typeColor\"],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/helpers/notification-status.hbs"
    }
  });

  _exports.default = _default;
});