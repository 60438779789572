define("recruit/templates/components/reports/helpers/time-range-graph-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rpt+pTjs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"padding-md-left padding-md-right\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"text-weight-medium text-black text-size-medium center\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"selectedGraphFilter\"]],\"week\"],null]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"ui selected details\"],[9,\"data-content\",\"WEEKLY REPORT\"],[7],[0,\"WEEKLY REPORT\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,\"eq\",[[20,[\"selectedGraphFilter\"]],\"year\"],null]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"ui selected details\"],[9,\"data-content\",\"WEEKLY REPORT\"],[7],[0,\"YEARLY REPORT\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,\"eq\",[[20,[\"selectedGraphFilter\"]],\"month\"],null]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"ui selected details\"],[9,\"data-content\",\"WEEKLY REPORT\"],[7],[0,\"MONTHLY REPORT\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/time-range-graph-header.hbs"
    }
  });

  _exports.default = _default;
});