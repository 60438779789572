define("recruit/templates/recruiter/reports/advanced/time/time-to-process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ry2r/Ur9",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"style\",\"background: #FFF; padding: 30px; margin-top: 10px; padding-bottom: 10px; padding-top: 15px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"style\",\"margin: 20px 5px 5px;\"],[7],[0,\"\\n    \"],[1,[25,\"reports/graphs/assessments/time-to-hire\",null,[[\"isLoading\",\"selectedRow\",\"hideFilters\",\"startDate\",\"endDate\",\"selectedFilterValue\",\"selectedGraphFilter\",\"objectType\",\"graphTitle\",\"graphInfo\",\"appliedFilters\",\"firstTableRecord\",\"dateFilters\",\"hideGraph\",\"dateRecordSet\",\"showAssessmentDetails\",\"graphColors\",\"setDateFilter\",\"actionName\"],[[20,[\"isLoading\"]],[20,[\"selectedRow\"]],true,[20,[\"startDate\"]],[20,[\"endDate\"]],[20,[\"selectedFilterValue\"]],[20,[\"selectedGraphFilter\"]],\"rf\",\"advanced-time-to-process\",\"The average time a candidate takes to complete an assessment.\",[20,[\"userAppliedFilters\"]],[20,[\"firstTableRecord\"]],[20,[\"dateFilters\"]],[20,[\"hideGraph\"]],[20,[\"dateRecordSet\"]],true,[20,[\"graphColors\"]],\"setDateFilter\",\"graphFilterObserver\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"style\",\"margin-bottom: 30px;\"],[7],[0,\"\\n    \"],[1,[25,\"reports/table/time-to-process\",null,[[\"isLoading\",\"selectedFilterValue\",\"dateFilters\",\"firstTableRecord\",\"selectedRow\",\"hideGraph\",\"columns\",\"dateRecordSet\",\"startDate\",\"endDate\",\"start_date\",\"end_date\",\"selectedGraphFilter\",\"custom_field_master_id\",\"modelName\"],[[20,[\"isLoading\"]],[20,[\"selectedFilterValue\"]],[20,[\"dateFilters\"]],[20,[\"firstTableRecord\"]],[20,[\"selectedRow\"]],[20,[\"hideGraph\"]],[20,[\"columns\"]],[20,[\"dateRecordSet\"]],[20,[\"startDate\"]],[20,[\"endDate\"]],[20,[\"start_date\"]],[20,[\"end_date\"]],[20,[\"selectedGraphFilter\"]],[20,[\"custom_field_master_id\"]],\"reports/custom-field-time\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/reports/advanced/time/time-to-process.hbs"
    }
  });

  _exports.default = _default;
});