define("recruit/components/recruiter-view/assessment/candidate-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '' // count: Ember.computed('assessmentId', function() {
    //   this.get('store').query('assessmentCandidate', {
    //     assessment_id: this.get('assessmentId'),
    //     status_sort_key: this.get('statusKey')
    //   })
    //   .then((acs) => this.set('count', acs.get('meta.total')));
    // })

  });

  _exports.default = _default;
});