define("recruit/templates/components/helpers/formatted-date-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cwQkZJNW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"showTimeAgo\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-tooltip\",[18,\"formattedTime\"],null],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[1,[25,\"moment-from-now\",[[20,[\"dateTime\"]]],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-tooltip\",[25,\"moment-from-now\",[[20,[\"dateTime\"]]],null],null],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n    \"],[1,[18,\"formattedTime\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/formatted-date-time.hbs"
    }
  });

  _exports.default = _default;
});