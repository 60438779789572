define("recruit/templates/components/helpers/date-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AUPbr8hz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-weight-normal\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"showNotPending\"]]],null,{\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"column\",\"tableOf\"]],\"assessment\"],null]],null,{\"statements\":[[0,\"        \"],[6,\"p\"],[9,\"class\",\"text-center\"],[9,\"data-tooltip\",\"Not Pending\"],[9,\"data-position\",\"bottom center\"],[7],[0,\"Not Pending\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"p\"],[9,\"class\",\"text-center\"],[9,\"data-tooltip\",\"Not Pending\"],[9,\"data-position\",\"bottom center\"],[7],[0,\"Not Pending\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"helpers/formatted-date-time\",null,[[\"dateTime\",\"column\",\"showTimeAgo\",\"format\",\"tableOf\"],[[20,[\"value\"]],[20,[\"column\"]],[20,[\"column\",\"showTimeAgo\"]],[20,[\"format\"]],[20,[\"column\",\"tableOf\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  --\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/date-view.hbs"
    }
  });

  _exports.default = _default;
});