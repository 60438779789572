define("recruit/components/reports/dashboard/dashboard-reports-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.later(function () {
        Ember.$('.ui.accordion').accordion();
      });
    }
  });

  _exports.default = _default;
});