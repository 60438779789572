define("recruit/components/live-schedule/section-dropdown-placeholder", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLoading: Ember.computed('placeholder', function () {
      return _npmLodash.default.includes(_npmLodash.default.lowerCase(this.get('placeholder')), 'loading');
    })
  });

  _exports.default = _default;
});