define("recruit/templates/components/live-session/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4eWWaTrE",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui centered middle aligned grid margin-top-hundred margin-no\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"thirteen wide column\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n      \"],[1,[25,\"live-session/live-schedule-status\",null,[[\"model\",\"remindAll\",\"askCancel\",\"cancelSession\",\"reschedule\",\"suggestPanel\"],[[20,[\"model\"]],\"remindAll\",\"askCancel\",\"cancelSession\",\"reschedule\",\"suggestPanel\"]]],false],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui segment padding-no padding-bg\"],[9,\"style\",\"overflow-y: auto;\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"and\",[[25,\"or\",[[20,[\"model\",\"isSessionCompleted\"]],[20,[\"model\",\"sessionExpired\"]]],null],[25,\"not\",[[20,[\"model\",\"isSessionCancelled\"]]],null]],null]],null,{\"statements\":[[0,\"          \"],[1,[25,\"live-session/live-candidate-details\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n          \"],[1,[25,\"live-session/panel-attendees-table\",null,[[\"remindAll\",\"model\"],[\"remindAll\",[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"live-session/live-candidate-details\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"liveAttendees\",\"length\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"live-session/live-session-attendees\",null,[[\"model\",\"remindAll\",\"managePanel\"],[[20,[\"model\"]],\"remindAll\",\"managePanel\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-session/view.hbs"
    }
  });

  _exports.default = _default;
});