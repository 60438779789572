define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-section/section-details-table/table-row", ["exports", "recruit/mixins/g-actions", "moment"], function (_exports, _gActions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('assessmentCandidates', Ember.A([]));
      this.set('modelPc', this.get('store').query('panel-candidate', {
        panel_id: this.get('panel.id'),
        v: (0, _moment.default)().unix()
      }));
      this.get('modelPc').then(function (res) {
        if (res.get('length')) {
          res.forEach(function (data) {
            data.get('assessmentCandidate').then(function (ac) {
              Ember.RSVP.resolve(ac).then(function (c) {
                return _this.get('assessmentCandidates').pushObject(c);
              });
            });
          });
        }
      }).catch(function (err) {
        if (err.errors) {
          err.errors.forEach(function (e) {
            _this.get('toast').error(e.detail, 'Error');
          });
        } else {
          _this.get('toast').error('Please try after some time', 'Something went wrong');
        }
      });
    }
  });

  _exports.default = _default;
});