define("recruit/routes/reset-password", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      c: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.set('params', params);
    },
    setupController: function setupController(controller) {
      if (!this.get('params.c')) {
        controller.set('showSupport', true);
        controller.set('errorMsg', "The link you tried to access is not valid.");
      }
    },
    actions: {
      changePass: function changePass() {
        var _this = this;

        var newPassword = this.controller.get('newPass1');
        var newPasswordRepeat = this.controller.get('newPass2');

        if (!newPassword || !newPasswordRepeat) {
          this.get('toast').error('Try again', 'Password can not be empty');
        }

        if (newPassword != newPasswordRepeat) {
          this.get('toast').error('Try again', 'Password do not match');
        } else {
          if (this.get('params.c')) {
            this.get('store').createRecord('reset-password', {
              token: this.get('params.c'),
              new_password: newPassword,
              new_password_repeat: newPasswordRepeat
            }).save().then(function () {
              _this.get('toast').success('Password Changed');

              _this.get('router').transitionTo('login');
            }).catch(function (e) {
              if (e.errors) {
                e.errors.forEach(function (error) {
                  _this.get('toast').error(error.detail, 'Error!');
                });
              } else {
                _this.get('toast').error('Something went wrong', 'Please try after some time.');
              }
            });
          } else {
            this.get('toast').error('Try again', 'Invalid Link');
          }
        }
      }
    }
  });

  _exports.default = _default;
});