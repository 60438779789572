define("recruit/components/action-wrapper/live-modal/manage-panel", ["exports", "npm:lodash", "recruit/mixins/g-actions"], function (_exports, _npmLodash, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    isNotify: true,
    isLoading: false,
    didInsertElement: function didInsertElement() {
      this.setProperties({
        'addedPanels': Ember.A([]),
        'morePanel': Ember.A([])
      });
    },
    users: Ember.computed('s', function () {
      if (this.get('s')) {
        return this.get('store').query('user', {
          s: this.get('s')
        });
      } else {
        return this.get('store').findAll('user');
      }
    }),
    currentPanelObserver: Ember.observer('selectedListValues.firstObject.liveAttendees.isFulfilled', 'selectedListValues.firstObject.liveAttendees.[]', function () {
      if (this.get('selectedListValues.firstObject.liveAttendees.isFulfilled')) {
        var existingPanels = this.get('selectedListValues.firstObject.panels').map(function (panel) {
          return panel.get('user');
        });
        this.set('addedPanels', existingPanels.map(function (p) {
          return p;
        }));
        this.set('currentPanel', existingPanels);
      } else {
        this.set('currentPanel', Ember.A([]));
      }
    }),
    actions: {
      removePanel: function removePanel(panel, isDefault) {
        if (isDefault) {
          if (_npmLodash.default.includes(this.get('currentPanel'), panel)) {
            this.get('currentPanel').removeObject(panel);
            this.get('addedPanels').removeObject(panel);
          }
        } else {
          if (_npmLodash.default.includes(this.get('morePanel'), panel)) {
            this.get('morePanel').removeObject(panel);
            this.get('addedPanels').removeObject(panel);
          }
        }
      },
      managePanel: function managePanel() {
        var _this = this;

        var panels = [].concat(this.get('currentPanel')).concat(this.get('morePanel'));
        var panelObjects = panels.map(function (p) {
          return {
            email: p.get('email')
          };
        });

        if (panelObjects.length) {
          this.set('isLoading', true);
          this.get('store').createRecord('actions/live-change-panel', {
            liveSessionID: this.get('selectedListValues.firstObject.id'),
            panel: panelObjects,
            sync: true,
            isNotify: this.get('isNotify')
          }).save().then(function (response) {
            if (response.get('status') == 99) {
              return _this.get('toast').error(response.get('response'));
            } else {
              response.get('liveAttendees').then(function (attendees) {
                if (!_npmLodash.default.isNil(attendees)) {
                  _this.get('selectedListValues.firstObject.panels').forEach(function (panel) {
                    _this.get('selectedListValues.firstObject.liveAttendees').removeObject(panel);
                  });

                  _this.set('morePanel', Ember.A([])); // this.get('selectedListValues.firstObject.liveAttendees').pushObjects(attendees)


                  _this.get('toast').success('Panel updated', 'Successful');
                }

                return _this.send('back');
              }).catch(function (err) {
                _this.catchError(err);
              });
            }
          }).catch(function (err) {
            _this.catchError(err);
          }).finally(function () {
            _this.set('isLoading', false);
          });
        } else {
          this.get('toast').error('Panel can not be blank', 'Error');
        }
      },
      selectMorePanels: function selectMorePanels(panel) {
        this.get('morePanel').pushObject(panel);
        this.get('addedPanels').pushObject(panel);
      }
    }
  });

  _exports.default = _default;
});