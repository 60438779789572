define("recruit/components/action-wrapper/live-modal/reschedule", ["exports", "moment", "npm:lodash", "recruit/validations/time-validator", "recruit/mixins/g-actions"], function (_exports, _moment, _npmLodash, _timeValidator, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    tagName: '',
    store: Ember.inject.service(),
    isNotify: true,
    isLoading: false,
    minimumDate: Ember.computed('minimumDate', function () {
      return (0, _moment.default)().subtract(1, 'days').startOf('day').toDate();
    }),
    popupOptions: {
      position: 'bottom left'
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').query('settingsAllowed', {
        settings_id: 1
      }).then(function (timezones) {
        if (timezones.get('length')) {
          _this.set('timeZoneList', timezones);

          var preSelectedTimezone = timezones.findBy('item_value', _this.get('selectedListValues.firstObject.timezone'));

          if (preSelectedTimezone) {
            return _this.set('timeZoneValue', preSelectedTimezone);
          }
        }
      });
    },
    actions: {
      rescheduleLive: function rescheduleLive() {
        var _this2 = this;

        var liveSession = this.get('selectedListValues.firstObject');
        var startDateTime = liveSession.get('from');

        var endDateTime = (0, _moment.default)(startDateTime).add(liveSession.get('durationPreSelected').value, "minutes")._d;

        if (!this.get('timeZoneValue')) {
          this.get('toast').error('Timezone can not be blank', 'Error!');
        } else if (!startDateTime) {
          this.get('toast').error('Date can not be blank', 'Error!');
        } else if (!(0, _timeValidator.default)(startDateTime, this.get('timeZoneValue.item_value'))) {
          this.get('toast').error('Cannot schedule in past', 'Error!');
        } else {
          this.set('isLoading', true);
          this.get('store').createRecord('actions/live-reschedule', {
            liveSessionID: liveSession.get('id'),
            startDate: startDateTime,
            endDate: endDateTime,
            currentOffset: (0, _moment.default)().local().utcOffset() * 60,
            isNotify: this.get('isNotify'),
            timezone: this.get('timeZoneValue.item_value')
          }).save().then(function (response) {
            if (response.get('status') == 99) {
              _this2.get('toast').error(response.get('response'));
            } else {
              _this2.get('toast').success('Live session rescheduled successfully');

              _npmLodash.default.forEach(_this2.get('selectedListValues'), function (session) {
                session.set('status', 1);
              });

              return _this2.send('back');
            }
          }).catch(function (e) {
            _this2.catchError(e);
          }).finally(function () {
            _this2.set('isLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});