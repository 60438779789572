define("recruit/routes/recruiter/reports/advanced/time/time-to-process", ["exports", "recruit/routes/recruiter/reports/report-filters", "recruit/data/reports-advance-filters-recruiter"], function (_exports, _reportFilters, _reportsAdvanceFiltersRecruiter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportFilters.default.extend({
    queryParams: {
      selectedGraphFilter: {
        replace: true
      }
    },
    store: Ember.inject.service(),
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.get('store').push({
        data: _reportsAdvanceFiltersRecruiter.default
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set('columns', this.getColumns(model));
      controller.set('graphTitle', model.get('label') + '-CONVERSION');
      controller.set('graphInfo', "The conversion of ".concat(model.get('label'), " from the invite stage to the final selection for each assessment."));
      controller.set('custom_field_master_id', model.get('id'));
      controller.set('selectedGraphFilter', transition.queryParams.selectedGraphFilter ? transition.queryParams.selectedGraphFilter : 'days');
    },
    getColumns: function getColumns(model) {
      return [{
        label: model.get('label'),
        component: 'reports/helpers/assessment-header',
        classNames: ['table-head mis text-center border-right-grey'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        width: '28%',
        sortable: false,
        cellComponent: 'reports/helpers/advanced-row-component'
      }, {
        sortable: false,
        component: 'reports/helpers/column-with-toggle',
        value: "Avg time to process ( in ".concat(this.controller.get('selectedGraphFilter') == 'hours' ? 'hours' : 'days', " )"),
        classNames: ['table-head mis text-center'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        subColumns: [{
          label: 'Send Invitations',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'inviteTime',
          columnTooltip: 'Average time taken to send invitations to the candidates by recruiter.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.controller.get('selectedGraphFilter'),
          width: '12%'
        }, {
          label: 'Complete Assessment',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          columnTooltip: 'Average time taken by the candidates to complete the assessment after getting an invitation.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.controller.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'completeTime',
          width: '12%'
        }, {
          label: 'Assign Panel',
          component: 'reports/helpers/table-header',
          columnTooltip: 'Average time taken by the recruiter to assign panel to the candidates.',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.controller.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'assignTime',
          width: '12%'
        }, {
          label: 'Evaluate',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          columnTooltip: 'Average time taken by the panel to evaluate the candidates.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.controller.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'evaluationTime',
          width: '12%'
        }, {
          label: 'Final Selection',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          columnTooltip: 'Average time taken by the recruiter to finally shortlist a candidate after the evaluation.',
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.controller.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'shortlistTime',
          width: '12%'
        }, {
          label: 'Time to process',
          subLabel: "(".concat(this.controller.get('selectedGraphFilter') == 'hours' ? 'Hours' : 'Days', ")"),
          hideDot: true,
          component: 'reports/helpers/table-header',
          columnTooltip: "Average time taken (in ".concat(this.controller.get('selectedGraphFilter') == 'hours' ? 'hours' : 'days', ") by the recruiter to complete the entire process"),
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/time-row-component',
          selectedGraphFilter: this.controller.get('selectedGraphFilter'),
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'timeToProcess',
          width: '12%'
        }]
      }];
    },
    actions: {
      graphFilterObserver: function graphFilterObserver(filter) {
        this.controller.set('selectedGraphFilter', filter);
        this.controller.set('columns', this.getColumns(this.controller.get('model')));
      }
    }
  });

  _exports.default = _default;
});