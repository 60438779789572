define("recruit/templates/user/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LnK5JJ2Q",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"margin-top-hundred\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isDevelopment\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,[\"model\",\"isRecruiter\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Not available\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/user/profile.hbs"
    }
  });

  _exports.default = _default;
});