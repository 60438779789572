define("recruit/templates/components/recruiter-view/questions/helpers/question-title-input-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pcfI2prm",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui form margin-no\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"margin-bottom-ten-negative input-helper-border\"],[7],[0,\"\\n\\t\\t\"],[1,[25,\"validated-field\",null,[[\"modelName\",\"type\",\"isCustomErrors\",\"questionTitle\",\"class\",\"titleType\",\"changeset\",\"property\",\"focusOutAction\"],[[20,[\"modelName\"]],\"text\",true,true,\"field-margin-no\",true,[20,[\"changeset\"]],\"title\",[25,\"action\",[[19,0,[]],\"focusOutAction\"],null]]]],false],[0,\"\\n\\t\"],[8],[0,\"\\n  \"],[6,\"span\"],[9,\"data-tooltip\",\"Cancel\"],[9,\"data-position\",\"bottom center\"],[9,\"style\",\"bottom: 10px !important;\"],[9,\"class\",\"cursor-pointer float-right\"],[3,\"action\",[[19,0,[]],\"showInput\",\"addTitle\"]],[7],[0,\"\\n  \\t\"],[6,\"img\"],[9,\"src\",\"/images/cross.svg\"],[9,\"style\",\"height: 10px;\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/question-title-input-helper.hbs"
    }
  });

  _exports.default = _default;
});