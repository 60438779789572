define("recruit/components/helpers/multiple-dropdown", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    listSource: Ember.computed('s', function () {
      var query = _npmLodash.default.omitBy(this.getProperties(['roles', 's']), _npmLodash.default.isNil);

      _npmLodash.default.merge(query, this.get('modelQuery') || {});

      return this.get('store').query(this.get('modelName'), query);
    }),
    actions: {
      searchAction: function searchAction(searchText) {
        var _this = this;

        Ember.run.debounce(this, function () {
          _this.set('s', searchText);
        }, 500);
      },
      customAction: function customAction(value) {
        this.sendAction('actionName', value.get('firstObject'));
      },
      disabledOption: function disabledOption() {
        var _this2 = this;

        this.get('listSource').forEach(function (item) {
          var object = _this2.get('disabledItems').findBy('id', item.get('id'));

          if (object) {
            object.set('disabled', true);
          } else {
            item.set('disabled', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});