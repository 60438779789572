define("recruit/components/candidate-auth/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    playing: false,
    actions: {
      togglePlay: function togglePlay() {
        var _this = this;

        var player = document.getElementById('tv-audio-player');

        if (this.get('playing')) {
          player.pause();
        } else {
          player.play();
        }

        this.toggleProperty('playing');
        setTimeout(function () {
          _this.toggleProperty('playing');
        }, 5000);
      }
    }
  });

  _exports.default = _default;
});