define("recruit/components/helpers/custom-columns-button", ["exports", "recruit/components/filters/master-filter", "npm:lodash"], function (_exports, _masterFilter, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _masterFilter.default.extend({
    groupColumns: Ember.computed('customColumns', 'customColumns.[]', function () {
      if (this.get('customColumns.length')) {
        var customColumns = this.get('customColumns').map(function (column) {
          column.set('isDisabled', column.get('isDefault'));
          column.set('isChecked', column.get('isColumnSelected'));
          column.set('parent', column.get('extra.columnType') ? column.get('extra.columnType') : "");
          return column;
        });
        return Ember.Object.create(_npmLodash.default.groupBy(customColumns, 'parent'));
      }
    })
  });

  _exports.default = _default;
});