define("recruit/components/reports/table/time-to-process", ["exports", "recruit/mixins/report-table-common"], function (_exports, _reportTableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTableCommon.default, {
    // modelName: 'reports/recruiter',
    // hideGraph: false,
    actions: {
      toggleGraph: function toggleGraph() {
        this.toggleProperty('hideGraph');
      }
    }
  });

  _exports.default = _default;
});