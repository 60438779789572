define("recruit/components/reports/graphs/recruiter/activities-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedFilters: Ember.A([]),
    currentUser: Ember.inject.service('current-user'),
    graphValues: Ember.observer('firstTableRecord', 'isLoading', function () {
      if (this.get('firstTableRecord.id')) {
        this.set('showEmptyState', false);
        this.computeGraphValues(this.get('firstTableRecord'));
      } else {
        this.set('showEmptyState', true);
      }
    }),
    valuesObserver: Ember.observer('selectedRow', function () {
      if (this.get('selectedRow.id')) {
        this.set('userObject', this.get('store').findRecord('user', +this.get('selectedRow.id')));
        this.computeGraphValues(this.get('selectedRow'));
      }
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('dateRecordSet')) {
        this.set('initialStartDate', this.get('startDate'));
        this.set('initialEndDate', this.get('endDate'));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').query('user', {
        roles: 'Recruiter'
      }).then(function (res) {
        _this.set('defaultRecruiters', res.slice(0, 6));
      });
      this.get('store').findAll('assessment').then(function (res) {
        _this.set('defaultAssessments', res.slice(0, 6));
      });
    },
    didRender: function didRender() {
      Ember.run(function () {
        Ember.$('.ui.cursor-help').popup({
          position: 'bottom center'
        });
      });
    },
    computeGraphValues: function computeGraphValues(values) {
      if (values.get('id')) {
        this.set('selectedRow', values);
      }

      var x_axis_values = Ember.A([{
        y: values.get('createCandidateCount'),
        color: '#4A90E2',
        name: 'Create Candidates',
        tooltip_text: 'Candidate(s) created'
      }, {
        y: values.get('inviteCount'),
        color: '#9E71CD',
        name: 'Invite to Assessment',
        tooltip_text: 'Candidate(s) invited to assessment'
      }, {
        y: values.get('liveScheduleCount'),
        color: '#03C1A1',
        name: 'Schedule Live Session',
        tooltip_text: 'Live Session(s) scheduled'
      }, {
        y: values.get('liveRescheduleCount'),
        color: '#F3708F',
        name: 'Rechedule Live Session',
        tooltip_text: 'Candidate(s) moved'
      }, {
        y: values.get('evaluationCount'),
        color: '#ED8068',
        name: 'Evaluations',
        tooltip_text: 'Candidate(s) evaluated'
      }, {
        y: values.get('changeStatusCount'),
        color: '#83CA6D',
        name: 'Change Invite Status',
        tooltip_text: 'Candidate(s) final invite changed'
      }]);
      this.set('dataArray', x_axis_values);
      this.set('renderGraph', true);
    },
    showDate: Ember.computed('openCalendar', function () {
      return !this.get('openCalendar');
    }),
    actions: {
      togglePopup: function togglePopup(property) {
        if (property == 'openCalendar') {
          if (this.get('openFilter')) {
            this.set('openFilter', false);
          }
        }

        if (property == 'openFilter') {
          if (this.get('openCalendar')) {
            this.set('openCalendar', false);
          }
        }

        this.toggleProperty(property);
      },
      setFilters: function setFilters(assessment, recruiter, panel, advanceFilters) {
        this.send('togglePopup', 'openFilter');
        this.sendAction('setAllFilters', assessment, recruiter, panel, advanceFilters);
      },
      resetFilters: function resetFilters() {
        this.initialize();
      },
      setDateFilter: function setDateFilter(obj) {
        this.sendAction('setDateFilter', obj);
      }
    }
  });

  _exports.default = _default;
});