define("recruit/helpers/get-index-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getIndexValue = getIndexValue;
  _exports.default = void 0;

  function getIndexValue(object) {
    return Ember.get(object[0], object[1].toString());
  }

  var _default = Ember.Helper.helper(getIndexValue);

  _exports.default = _default;
});