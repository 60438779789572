define("recruit/components/iframe-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    srcObserver: Ember.observer('src', function () {
      return this.setIframe();
    }),
    classNames: ['full-width'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      // TODO: Remove setTimeout to fix the race condition
      Ember.run.next('afterRender', function () {
        setTimeout(function () {
          // let frames = Ember.$('#assessmentDescription');
          // if(this.get('src') && frames.length){
          //   let src = this.get('src');
          //   frames.get(0).contentDocument.write(src);
          // }
          _this.setIframe();
        }, 200);
      });
    },
    setEmbeddedWindow: function setEmbeddedWindow(iframe) {
      Ember.$(iframe).find('a').attr('target', '_blank');
    },
    setIframe: function setIframe() {
      var frames = Ember.$('#assessmentDescription');

      if (this.get('src') && frames.length) {
        var src = this.get('src');
        frames.get(0).contentDocument.open();
        frames.get(0).contentDocument.write(src);
        frames.get(0).contentDocument.close();

        if (this.get('isTargetBlank')) {
          this.setEmbeddedWindow(frames.get(0).contentDocument);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('#assessmentDescription').remove();
    }
  });

  _exports.default = _default;
});