define("recruit/components/recruiter-view/questions/bulk-create/bulk-import-review-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      back: function back() {
        this.get('router').transitionTo('recruiter.questions.index');
      }
    }
  });

  _exports.default = _default;
});