define("recruit/components/live-session/scheduling/calendar-column", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    requestMadeFor: (0, _moment.default)().startOf('days'),
    //fetching calendar events beyond 30 days from current date
    fetchCalendarEvents: function fetchCalendarEvents() {
      var _this = this;

      var startOfselectedDate = (0, _moment.default)(this.get('timeRange.firstObject')).startOf('days');
      var endOfselectedDate = (0, _moment.default)(this.get('timeRange.firstObject')).endOf('days');

      if (startOfselectedDate.isAfter((0, _moment.default)().add(1, 'months'), 'day') && !startOfselectedDate.isSame(this.get('requestMadeFor'), 'day')) {
        Ember.RSVP.resolve(this.get('model.calendar')).then(function () {
          return _this.get('model.calendar').filter(function (event) {
            return event.get('from').isSame(_this.get('timeRange.firstObject'), 'day');
          });
        }).then(function (res) {
          if (!res.length) {
            _this.set('requestMadeFor', startOfselectedDate);

            _this.set('isLoading', true);

            Ember.RSVP.hash({
              busyCalendar: _this.get('store').query('extended-user-calendar', {
                type: 3,
                user_id: _this.get('model.id'),
                startsAt: startOfselectedDate.unix(),
                endsAt: endOfselectedDate.unix()
              }),
              availability: _this.get('store').query('extended-user-calendar', {
                type: 2,
                user_id: _this.get('model.id'),
                startsAt: startOfselectedDate.unix(),
                endsAt: endOfselectedDate.unix()
              })
            }).then(function (res) {
              Ember.RSVP.resolve(_this.formatEvents(res.busyCalendar, _this.get('model.calendar'))).then(function () {
                return _this.formatEvents(res.availability, _this.get('model.calendar'));
              }).then(function () {
                return _this.set('model.calendar', _this.get('model.calendar').uniqBy('id'));
              });
            }).catch(function (e) {
              if (e.errors.length) {
                e.errors.forEach(function (error) {
                  _this.get('toast').error(error.detail);
                });
              } else {
                _this.get('toast').error('Please try some time later', 'Something went wrong');
              }
            }).finally(function () {
              _this.set('isLoading', false);
            });
          }
        });
      }
    },
    allDayEvent: Ember.computed('model.calendar.[]', 'timeRange', function () {
      var _this2 = this;

      if (this.get('model.busyCalendarEvents.isFulfilled') && this.get('model.availablityEvents.isFulfilled')) {
        this.fetchCalendarEvents();
      }

      if (this.get('model.calendar.length')) {
        return this.get('model.calendar').filter(function (calendarEvent) {
          var startAt = calendarEvent.get('from').valueOf();
          var endAt = calendarEvent.get('to').valueOf();
          var startOfDay = (0, _moment.default)(_this2.get('timeRange.firstObject')).startOf('days').valueOf();
          var endOfDay = (0, _moment.default)(_this2.get('timeRange.firstObject')).endOf('days').valueOf();
          return startAt == startOfDay && endAt == endOfDay;
        });
      }
    }),
    formatEvents: function formatEvents(events, calendarEvents) {
      if (events.get('length')) {
        events.forEach(function (calendar) {
          if ((0, _moment.default)(calendar.get('from')).endOf('day').isBefore(calendar.get('to'))) {
            calendar.set('to', (0, _moment.default)(calendar.get('from')).endOf('day'));
          }

          calendarEvents.pushObject(calendar);
        });
      }
    },
    modelObserver: Ember.observer('model', function () {
      var _this3 = this;

      var model = this.get('model');
      this.set('isLoading', true);
      model.set('calendar', Ember.A([]));
      return Ember.RSVP.hash([model.get('busyCalendarEvents').then(function (uc) {
        return _this3.formatEvents(uc, model.get('calendar'));
      }), model.get('availablityEvents').then(function (uc) {
        return _this3.formatEvents(uc, model.get('calendar'));
      })]).catch(function (e) {
        if (e.errors.length) {
          e.errors.forEach(function (error) {
            _this3.get('toast').error(error.detail);
          });
        } else {
          _this3.get('toast').error('Please try some time later', 'Something went wrong');
        }
      }).finally(function () {
        _this3.set('isLoading', false);
      });
    }).on('init')
  });

  _exports.default = _default;
});