define("recruit/data/filters/r-c", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 6066,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate # (id)",
      "objectType": "c",
      "queryTemplate": "({{condition}} external_id{{exact}}{{value}})"
    }
  }, {
    "id": 5853,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "First Name",
      "isRemote": false,
      "objectType": "c",
      "queryTemplate": "({{condition}} first_name{{exact}}{{value}})"
    }
  }, {
    "id": 5854,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Last Name",
      "isRemote": false,
      "objectType": "c",
      "queryTemplate": "({{condition}} last_name{{exact}}{{value}})"
    }
  }, {
    "id": 5855,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Email",
      "isRemote": false,
      "objectType": "c",
      "queryTemplate": "({{condition}} email{{exact}}{{value}})"
    }
  }, {
    "id": 5856,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Phone",
      "isRemote": false,
      "objectType": "c",
      "queryTemplate": "({{condition}} phone{{exact}}{{value}})"
    }
  }, {
    "id": 5857,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Added on",
      "isRemote": false,
      "objectType": "c",
      "queryTemplate": "({{condition}} created_at:[{{start}} TO {{end}}])"
    }
  }];
  _exports.default = _default;
});