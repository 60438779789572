define("recruit/templates/components/recruiter-view/questions/helpers/skills-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gXb3BK/p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"row\",\"content\",\"questionSkills\"]]],null,{\"statements\":[[4,\"if\",[[25,\"gt\",[[20,[\"row\",\"content\",\"questionSkills\",\"length\"]],1],null]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[10,\"data-tooltip\",[26,[[20,[\"row\",\"content\",\"questionSkills\",\"firstObject\",\"skill\",\"name\"]]]]],[9,\"data-position\",\"bottom center\"],[9,\"class\",\"text-size-fourteen margin-bottom-no\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[7],[0,\"\\n        \"],[1,[20,[\"row\",\"content\",\"questionSkills\",\"firstObject\",\"skill\",\"name\"]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"text-size-vsmall\"],[7],[0,\"\\n      \"],[1,[25,\"fa-icon\",[\"plus\"],[[\"class\"],[\"text-grey text-size-vsmall\"]]],false],[0,\"\\n      \"],[1,[25,\"dec\",[[20,[\"row\",\"content\",\"questionSkills\",\"length\"]]],null],false],[0,\" more\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"p\"],[10,\"data-tooltip\",[26,[[20,[\"row\",\"content\",\"questionSkills\",\"firstObject\",\"skill\",\"name\"]]]]],[9,\"data-position\",\"bottom center\"],[9,\"class\",\"text-size-fourteen text-grey\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[7],[0,\"\\n        \"],[1,[20,[\"row\",\"content\",\"questionSkills\",\"firstObject\",\"skill\",\"name\"]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"p\"],[9,\"class\",\"text-size-vsmall text-grey text-italics\"],[7],[0,\"No skill(s) added\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/skills-cell-component.hbs"
    }
  });

  _exports.default = _default;
});