define("recruit/components/recruiter-view/questions/helpers/question-title-input-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next('afterRender', function () {
        if (_this.$().find('input')) {
          _this.$().find('input').focus();
        }
      });
    },
    actions: {
      showInput: function showInput(property) {
        this.sendAction('showInput', property);
      },
      focusOutAction: function focusOutAction() {
        if (this.get('changeset.title')) {
          this.send('setTitle', this.get('changeset.title'));
        }
      },
      setTitle: function setTitle(property) {
        this.sendAction('setTitle', property);
      }
    }
  });

  _exports.default = _default;
});