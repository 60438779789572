define("recruit/components/status-helpers/assessment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isBasic: true,
    statusColor: Ember.computed('statusColor', 'value.isFulfilled', function () {
      var statusProperty = this.get('value.sort_key'); // if(statusProperty) {

      switch (statusProperty) {
        case 1:
          //draft
          this.set('isBasic', false);
          return 'yellow';

        case 2:
          //closed
          this.set('isBasic', false);
          return 'brown';

        case 3:
          //open
          this.set('isBasic', false);
          return 'green';

        case 4:
          //filled
          this.set('isBasic', false);
          return 'teal';

        default:
          return 'grey';
      } // }

    })
  });

  _exports.default = _default;
});