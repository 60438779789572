define("recruit/components/recruiter-view/actions/live-actions", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service()
  });

  _exports.default = _default;
});