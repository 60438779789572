define("recruit/templates/components/reports/helpers/time-range-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I7VWkDzC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"column\",\"selectedGraphFilter\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[9,\"style\",\"height: 34px;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"twelve wide column padding-no padding-md-left text-left\"],[9,\"style\",\"font-size: 12px;\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-center text-weight-medium\"],[7],[1,[20,[\"column\",\"selectedGraphFilter\"]],false],[0,\" \"],[1,[18,\"indexNumber\"],false],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"data-tooltip\",[26,[[18,\"startDate\"],\" - \",[18,\"endDate\"]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"text-black text-center margin-no ellipsis\"],[7],[1,[18,\"startDate\"],false],[0,\" - \"],[1,[18,\"endDate\"],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  LOading...\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/time-range-details.hbs"
    }
  });

  _exports.default = _default;
});