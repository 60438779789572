define("recruit/components/live-session/helpers/assessment-details-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userRole: Ember.inject.service(),
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          position: 'top center'
        });
      });
    },
    actions: {
      toggleModal: function toggleModal(property, setTitle) {
        this.sendAction('toggleModal', property, setTitle);
      },
      toggleAssessmentModal: function toggleAssessmentModal(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});