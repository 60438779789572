define("recruit/mixins/g-actions", ["exports", "ember-cli-uuid", "npm:lodash"], function (_exports, _emberCliUuid, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    internalView: false,
    catchError: function catchError(error) {
      var _this = this;

      if (error.errors && error.errors.length) {
        return error.errors.forEach(function (e) {
          if (e.detail.length) {
            return _this.get('toast').error(e.detail);
          }
        });
      }

      return this.get('toast').error('Please try some time later', 'Something went wrong');
    },
    getContentValues: function getContentValues(values, type) {
      if (values && Array.isArray(values) && values.get('firstObject.content') && type !== 'questions') {
        // type: Since questions model has property named 'content'
        return values.map(function (v) {
          return v.get('content');
        });
      } else if (values && Array.isArray(values)) {
        return values;
      } else if (values && values.get('content') && type !== 'questions') {
        if (_npmLodash.default.includes(type, 'event') || type == "recruiters") {
          return values;
        }

        return values.get('content.length') ? values.map(function (v) {
          return v;
        }) : [values.get('content')];
      } else if (values) {
        return [values];
      }
    },
    actions: {
      routeActions: _npmLodash.default.rest(function (values, relationType, actionType, rest) {
        var _this2 = this;

        /*First three parameters should be always there(bcoz rest makes last arg as array even if theres only one arg passing)
          We cannot initialized rest with default value
          'rest' is an Array of object{'key':[string], 'value':[any], type:[string:value/flag]}*/
        var model = this.get('store').createRecord('modal-actions/recruiter/actions');
        model.set('id', (0, _emberCliUuid.uuid)());
        model.set('actionType', actionType);
        model.set("".concat(relationType), this.getContentValues(values, relationType));
        model.set("relationType", relationType);
        model.set('internalView', this.get('internalView'));

        if (rest && rest.length) {
          rest.forEach(function (prop) {
            if (prop.value) {
              model.set("".concat(prop.key), prop.type == 'value' ? _this2.getContentValues(prop.value, relationType) : prop.value);
            }
          });
        }

        model.save().then(function (response) {
          _this2.get('router').transitionTo('recruiter.recruiter-actions.actions', response);
        }).catch(function (err) {
          _this2.catchError(err);
        });
      }),
      back: function back() {
        var redirectTo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

        if (redirectTo) {
          this.get('router').transitionTo(redirectTo);
        } else {
          window.history.back();
        }
      }
    }
  });

  _exports.default = _default;
});