define("recruit/templates/components/recruiter-view/dashboard/candidate-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fkGsHw/8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"ui \",[18,\"cardColor\"],\" segment\"]]],[9,\"style\",\"border-top-width: 4px!important; height:100%;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"six wide column\"],[7],[0,\"\\n      \"],[6,\"img\"],[10,\"src\",[26,[\"/images/dashboard/\",[18,\"cardImage\"]]]],[9,\"alt\",\"\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ten wide column padding-no-left\"],[7],[0,\"\\n      \"],[1,[18,\"cardText\"],false],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"padding-md text-blue dashboard-counts\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"count\"]]],null,{\"statements\":[[0,\"          \"],[1,[18,\"count\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[18,\"recruiter-view/dashboard/candidate-count-loading\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/dashboard/candidate-count.hbs"
    }
  });

  _exports.default = _default;
});