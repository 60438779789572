define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-section/section-actions", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    actions: {
      sectionView: function sectionView(selectedAS) {
        this.get('router').transitionTo('recruiter.assessment.details.assessment-section', selectedAS);
      }
    }
  });

  _exports.default = _default;
});