define("recruit/routes/recruiter/reports/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      return this.get('store').query('custom-field-master', {
        'type': 1
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('list', Ember.A([{
        title: 'Conversion',
        type: 'Assessment',
        graphName: 'recruiter-conversion',
        description: 'The conversion of candidates from the invite stage to the completed stage in an assessment.',
        redirectUrl: 'recruiter.reports.assessments.conversion'
      }, {
        title: 'Time to Process',
        type: 'Assessment',
        graphName: 'time-to-hire',
        description: 'The average time a candidate takes to complete an assessment.',
        redirectUrl: 'recruiter.reports.assessments.time-to-hire'
      }, {
        title: 'Conversion',
        type: 'Recruiter',
        graphName: 'recruiter-conversion',
        description: 'The conversion rate of candidates from invite to completion by a recruiter.',
        redirectUrl: 'recruiter.reports.recruiter.conversion'
      }, {
        title: 'Time to Process',
        type: 'Recruiter',
        graphName: 'time-to-hire',
        description: 'The average time a recruiter takes to shortlist a candidate.',
        redirectUrl: 'recruiter.reports.recruiter.time-to-hire'
      }, {
        title: 'Activity',
        type: 'Recruiter',
        graphName: 'activities',
        description: 'View the activities of recruiters within a selected period of time.',
        redirectUrl: 'recruiter.reports.recruiter.activities'
      }, {
        title: 'Conversion',
        type: 'Panel',
        graphName: 'panel-conversion',
        description: 'The conversion rate of candidates from invite to completion by a panel member.',
        redirectUrl: 'recruiter.reports.panel.conversion'
      }, // {title: 'Time to Process', type: 'Panel', graphName: 'time-to-hire', description: 'The average time (in days) a panel member takes to evaluate a candidate.'},
      // {title: 'User', type: 'Panel', graphName: 'user', description: 'Lorem Ipsum'},
      {
        title: 'Conversion Report',
        type: 'Time Range',
        graphName: 'time-range-conversion',
        description: 'A comparison of candidate conversion rates over a selected period of time.',
        redirectUrl: 'recruiter.reports.time-range.conversion'
      } // {title: 'Time to Process Report', type: 'Time Range', graphName: 'time-to-hire', description: 'A comparison of the average time taken to process candidates in the recruitment funnel over a selected period of time.'},
      // {title: 'Conversion', type: 'Advanced', graphName: 'conversion', description: 'The conversion rate of candidates from invite to completion, sorted by a selected custom filter.'},
      // {title: 'Time to Process', type: 'Advanced', graphName: 'time-to-hire', description: 'The average time (in days) a panel member takes to evaluate a candidate.'}
      ]));
    }
  });

  _exports.default = _default;
});