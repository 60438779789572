define("recruit/controllers/user/settings/capture", ["exports", "recruit/controllers/master"], function (_exports, _master) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _master.default.extend({
    init: function init() {
      Ember.run.schedule("afterRender", this, function () {
        this.send('initializeCamera');
      });
    },
    actions: {
      initializeCamera: function initializeCamera() {
        var _this = this;

        Ember.$('#video').show();
        Ember.$('#photo').hide(); // Ember.$('#video').addClass('align-center');

        this.set('isCaptured', false);
        var videoConstrains = {
          width: {
            exact: 640
          },
          height: {
            exact: 480
          }
        };
        var constrains = {
          video: videoConstrains,
          audio: false
        };
        navigator.mediaDevices.getUserMedia(constrains).then(function (stream) {
          _this.send('onCameraAccessed', stream);
        }).catch(function (error) {
          alert(error);
        });
      },
      onCameraAccessed: function onCameraAccessed(stream) {
        var video = document.querySelector('video');
        window.stream = stream;
        video.srcObject = stream; // window.URL = window.URL || window.webkitURL || window.mozURL || window.msURL;
        // $('video').attr('src', (window.URL && window.URL.createObjectURL(stream)) || stream);
      },
      onError: function onError(error) {
        alert(error);
      },
      capturePicture: function capturePicture() {
        var canvas = document.getElementById('canvas');
        var video = document.getElementById('video');
        var photo = document.getElementById('photo');
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        var data = canvas.toDataURL('image/png');
        photo.setAttribute('src', data);
        this.send('stopStream');
        Ember.$('#video').hide();
        Ember.$('#photo').show();
        this.set('isCaptured', true);
        var objects = new tracking.ObjectTracker(["face"]);
        var img = document.getElementById('photo');
        objects.setStepSize(1.7);
        tracking.track('#photo', objects);
        objects.on('track', function (event) {
          event.data.forEach(function (rect) {
            window.plot(rect.x, rect.y, rect.width, rect.height);
          });
        });

        window.plot = function (x, y, w, h) {
          var rect = document.createElement('div');
          document.querySelector('.output').appendChild(rect);
          rect.classList.add('rect');
          rect.style.width = "".concat(w + 50, "px");
          rect.style.height = "".concat(h + 50, "px");
          rect.style.left = img.offsetLeft + x - 25 + 'px';
          rect.style.top = img.offsetTop + y - 25 + 'px';
          rect.style.position = 'absolute';
          rect.style.border = '2px solid red';
        };
      },
      back: function back() {
        this.send('stopStream');

        this._super.apply(this, arguments);
      },
      stopStream: function stopStream() {
        window.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    }
  });

  _exports.default = _default;
});