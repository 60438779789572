define("recruit/models/live-session", ["exports", "ember-data", "moment", "npm:lodash", "recruit/mixins/profile-pic-initials"], function (_exports, _emberData, _moment, _npmLodash, _profilePicInitials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var durationList = [{
    key: '15 minutes',
    value: 15
  }, {
    key: '30 minutes',
    value: 30
  }, {
    key: '45 minutes',
    value: 45
  }, {
    key: '1 hour',
    value: 60
  }, {
    key: '1 hour 30 minutes',
    value: 90
  }, {
    key: '2 hours',
    value: 120
  }];

  var _default = _emberData.default.Model.extend(_profilePicInitials.default, {
    currentUser: Ember.inject.service(),
    name: _emberData.default.attr('string'),
    is_record: _emberData.default.attr('boolean'),
    description: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    starts_at: _emberData.default.attr('pgdate'),
    ends_at: _emberData.default.attr('pgdate'),
    created_at: _emberData.default.attr('pgdate'),
    duration: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    assessmentSectionCandidate: _emberData.default.belongsTo('assessment-section-candidate'),
    recording_status: _emberData.default.attr('number'),
    hostedBy: _emberData.default.belongsTo('user'),
    version: _emberData.default.attr('number'),
    liveAttendees: _emberData.default.hasMany('live-attendee'),
    liveRecordingFlash: _emberData.default.hasMany('live-flash-recording'),
    webRTCRecordings: _emberData.default.hasMany('file'),
    date: _emberData.default.attr('date'),
    from: Ember.computed.alias('startDate'),
    to: Ember.computed.alias('endDate'),
    domain: _emberData.default.attr('string'),
    isOffline: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    location: _emberData.default.attr(),
    panelMembers: Ember.A([]),
    durations: durationList,
    candidate_email: _emberData.default.attr('string'),
    email: Ember.computed.alias('candidate_email'),
    candidate_name: _emberData.default.attr('string'),
    fullName: Ember.computed.alias('candidate_name'),
    candidate_id: _emberData.default.attr('string'),
    profilePicSrc: Ember.computed('candidate_name', 'candidate_email', function () {
      if (this.get('candidate_name') == this.get('candidate_email')) {
        return this.buildInitials(this.get('email'), false);
      } else if (_npmLodash.default.trim(this.get('candidate_name'))) {
        return this.buildInitials(this.get('candidate_name'), true);
      } else {
        return this.buildInitials(this.get('candidate_email'), false);
      }
    }),
    currentId: Ember.computed.alias('candidate_id'),
    //alias for profile-picture-mixin
    durationSelected: _emberData.default.attr(),
    assc: Ember.computed.alias('assessmentSectionCandidate'),
    isRelationsLoaded: Ember.computed('liveAttendees.isFulfilled', function () {
      var _this = this;

      this.get('liveAttendees').then(function () {
        return _this.set('isRelationsLoaded', true);
      });
    }),
    durationPreSelected: Ember.computed('to', function () {
      if (this.get('to')) {
        var from = (0, _moment.default)(this.get('from')).hours();
        var to = (0, _moment.default)(this.get('to')).hours();
        var min = (to - from) * 60 - Math.abs((0, _moment.default)(this.get('from')).minutes() - (0, _moment.default)(this.get('to')).minutes());

        if (min > 0) {
          var key = _npmLodash.default.findKey(durationList, {
            value: min
          });

          return durationList[key];
        } else {
          return durationList[3];
        }
      } else {
        return durationList[3];
      }
    }),
    candidatePhone: Ember.computed(function () {
      var _this2 = this;

      if (this.get('assessmentSectionCandidate.id')) {
        this.get('assessmentSectionCandidate').then(function (assc) {
          assc.get('assessmentCandidate').then(function (ac) {
            ac.get('candidate').then(function (can) {
              _this2.set('candidatePhone', can.get('phone') || '--'); //eslint-disable-line ember/no-side-effects

            });
          });
        });
      } else {
        return '--';
      }
    }),
    allPanelCandidates: Ember.computed(function () {
      var _this3 = this;

      if (this.get('assessmentSectionCandidate.content')) {
        this.get('assessmentSectionCandidate').then(function (assc) {
          assc.get('panelCandidates').then(function (pc) {
            Ember.RSVP.resolve(pc);

            _this3.set('allPanelCandidates', pc); //eslint-disable-line ember/no-side-effects

          });
        });
      }
    }),
    startsAtFormat: Ember.computed('starts_at', function () {
      return _moment.default.tz(this.get('starts_at'), this.get('timezone'));
    }),
    startsAtTimeZoneFormated: Ember.computed('startsAtFormat', function () {
      return this.get('startsAtFormat').format('YYYY-MM-DD HH:mm:ssZ');
    }),
    endsAtFormat: Ember.computed('ends_at', function () {
      return _moment.default.tz(this.get('ends_at'), this.get('timezone'));
    }),
    startDate: Ember.computed('starts_at', function () {
      return this.get('starts_at')._i;
    }),
    endDate: Ember.computed('ends_at', function () {
      return this.get('ends_at')._d;
    }),
    isFlashVersion: Ember.computed.equal('version', 1),
    formattedDescription: Ember.computed(function () {
      return this.get('description') || '--';
    }),
    formattedDuration: Ember.computed(function () {
      if (this.get('starts_at').isValid() && this.get('ends_at').isValid()) {
        var duration = _moment.default.duration((0, _moment.default)(this.get('ends_at')).diff((0, _moment.default)(this.get('starts_at')))).asHours();

        if (Math.round(duration) !== duration) {
          return duration.toFixed(2) + ' hour(s)';
        } else {
          return duration + ' hour(s)';
        }
      } else {
        return '--';
      }
    }),
    statusLabel: Ember.computed('status', function () {
      switch (this.get('status')) {
        case 1:
          return 'Scheduled';

        case 2:
          return 'In Progress';

        case 3:
          return 'Completed';

        case 10:
          return 'Cancelled';

        case 11:
          return 'Panel No Show';

        case 12:
          return 'Candidate No Show';

        case 13:
          return 'Both No Show';
      }
    }),
    sessionExpired: Ember.computed(function () {
      var now = (0, _moment.default)().utc().format();
      var end = (0, _moment.default)(this.get('ends_at').utc().format());
      return end.isBefore(now) && this.get('ends_at').isBefore(now);
    }),
    startTimeStatus: Ember.computed(function () {
      if ((0, _moment.default)(this.get('starts_at').utc().format("DD MMM' YY"))._i === (0, _moment.default)().utc().format("DD MMM' YY")) {
        var start = (0, _moment.default)(this.get('starts_at')).utc();
        var now = (0, _moment.default)().utc().format();

        if (Math.abs(start.diff(now, 'minutes')) <= 10 || start.isBefore(now)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }),
    sessionStarted: Ember.computed('startTimeStatus', function () {
      var start = (0, _moment.default)(this.get('starts_at')).utc();
      var end = (0, _moment.default)(this.get('ends_at')).utc();
      var now = (0, _moment.default)().utc().format();
      return now === start || start.isBefore(now) && end.isAfter(now);
    }),
    statusChangeObserver: Ember.observer('status', function () {
      if (this.get('hasDirtyAttributes')) {
        this.save();
      }

      if (!this.get('isSaving') && this.get('isLoaded')) {
        this.reload();
      }
    }),
    isSessionCancelled: Ember.computed('status', function () {
      return this.get('status') === 10;
    }),
    isSessionCompleted: Ember.computed('status', function () {
      return this.get('status') === 3;
    }),
    isSessionScheduled: Ember.computed('status', function () {
      return this.get('status') === 1;
    }),
    isSessionInProgress: Ember.computed('status', function () {
      return this.get('status') === 2;
    }),
    candidate: Ember.computed('liveAttendees.[]', function () {
      var _this4 = this;

      return this.get('liveAttendees').then(function (la) {
        return _this4.set('candidate', la.findBy('role', 1));
      }); //eslint-disable-line ember/no-side-effects
    }),
    panelsObserver: Ember.observer('liveAttendees.[]', 'liveAttendees.isFulfilled', function () {
      var _this5 = this;

      if (this.get('liveAttendees.isFulfilled')) {
        this.set('panels', this.get('liveAttendees').filterBy('role', 2));
      } else {
        this.get('liveAttendees').then(function (la) {
          return _this5.set('panels', la.filterBy('role', 2));
        }); //eslint-disable-line ember/no-side-effects
      }
    }),
    owners: Ember.computed('liveAttendees.[]', function () {
      var _this6 = this;

      this.get('liveAttendees').then(function (la) {
        return _this6.set('owners', la.filterBy('role', 3));
      });
    }),
    joinedAttendees: Ember.computed('liveAttendees.[]', function () {
      var _this7 = this;

      if (this.get('liveAttendees.isFulfilled')) {
        return this.get('liveAttendees').filterBy('status', 2);
      } else {
        this.get('liveAttendees').then(function (la) {
          return _this7.set('joinedAttendees', la.filterBy('status', 2));
        }); //eslint-disable-line ember/no-side-effects
      }
    }),
    isAttendee: Ember.computed('liveAttendees.[]', function () {
      var _this8 = this;

      this.get('liveAttendees').then(function (liveAttendees) {
        liveAttendees.forEach(function (attendee) {
          if (attendee.get('user.id') === _this8.get('currentUser.me.id')) {
            _this8.set('isAttendee', true); //eslint-disable-line ember/no-side-effects

          }
        });
      });
    }),
    // isAlsoPanelObserver: Ember.observer('liveAttendees.[]', 'liveAttendees.isFulfilled', function() {
    //   this.set('isAlsoPanel', false)
    //   this.get('liveAttendees').then((la) =>{
    //     let panelAttendees = la.filterBy('role', 2);
    //     panelAttendees.forEach((panels) => {
    //       if(panels.get('user.id') === this.get('owners.firstObject.user.id')) {
    //         this.set('isAlsoPanel', true);//eslint-disable-line ember/no-side-effects
    //       }
    //     })
    //   })
    // }),
    liveRecordingsObject: Ember.computed(function () {
      return this.get('webRTCRecordings').then(function (videos) {
        var lsArray = [];
        videos.forEach(function (video) {
          lsArray.push(video.get('flowplayerObject'));
        });
        return lsArray;
      });
    })
  });

  _exports.default = _default;
});