define("recruit/routes/recruiter/reports/report-filters", ["exports", "moment", "npm:lodash"], function (_exports, _moment, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      dateRange: {
        replace: true
      },
      reportFilters: {
        replace: true
      }
    },
    store: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set('userId', this.get('currentUser.me.id'));
      this.initialize();

      if (!_npmLodash.default.isEmpty(transition.queryParams.dateRange)) {
        localStorage.setItem("user-".concat(controller.get('userId')), transition.queryParams.dateRange);
        this.setDateTime(JSON.parse(transition.queryParams.dateRange));
      } else {
        if (localStorage.getItem("user-".concat(controller.get('userId')))) {
          var obj = JSON.parse(localStorage.getItem("user-".concat(controller.get('userId'))));
          this.setDateTime(obj);
        } else {
          this.setLocalStorage();
        }
      }

      if (!_npmLodash.default.isEmpty(transition.queryParams.reportFilters)) {
        controller.set('isReportFiltersAvailable', true);
        Ember.run.later('afterRender', function () {
          var arr = JSON.parse(transition.queryParams.reportFilters);

          _this.getModel(arr, 'assessment-conversion').then(function (res) {
            _this.send('setFilters', res.assessmentObj, res.recruiterObj, res.panelObj, arr.advanceFilters ? arr.advanceFilters : Ember.A([]));
          });
        });
      } else {
        controller.set('isReportFiltersAvailable', false);
      }
    },
    setDateTime: function setDateTime(dateObj) {
      if (dateObj.date_filter === 'all-time') {
        this.controller.set('start_date', null);
        this.controller.set('end_date', null);
      } else {
        this.controller.set('start_date', (0, _moment.default)(dateObj.start_date).toISOString());
        this.controller.set('end_date', (0, _moment.default)(dateObj.end_date).toISOString());
      }

      this.controller.set('dateRange', JSON.stringify(dateObj));
      this.controller.set('startDate', (0, _moment.default)(dateObj.start_date).format("DD MMMM YYYY"));
      this.controller.set('endDate', (0, _moment.default)(dateObj.end_date).format("DD MMMM YYYY"));
      this.controller.set('selectedFilterValue', dateObj.date_filter);
      this.controller.set('dateRecordSet', true);
    },
    setLocalStorage: function setLocalStorage() {
      var startDate = (0, _moment.default)().startOf('day').subtract(30 * 86400 * 1000).unix();
      var endDate = (0, _moment.default)().subtract(1 * 86400 * 1000).unix();
      var obj = {
        start_date: (0, _moment.default)(_moment.default.unix(startDate).format("DD MMMM YYYY")).toISOString(),
        end_date: (0, _moment.default)(_moment.default.unix(endDate).format("DD MMMM YYYY")).toISOString(),
        date_filter: 'last-30-days'
      };
      localStorage.setItem("user-".concat(this.controller.get('userId')), JSON.stringify(obj));
      this.setDateTime(obj);
    },
    initialize: function initialize() {
      this.controller.setProperties({
        'applyFilters': Ember.A([]),
        'reportFilters': Ember.A([]),
        'userAppliedFilters': Ember.A([]),
        'dateFilters': Ember.A([]),
        'isFiltersApplied': false
      });
    },
    checkIfFiltersApplied: function checkIfFiltersApplied(filters) {
      if (!_npmLodash.default.isEmpty(filters)) {
        this.controller.set('isFiltersApplied', true);
        this.setupQuery(filters);
      } else {
        this.controller.set('isFiltersApplied', false);
        this.controller.set('reportFilters', Ember.A([]));
      }
    },
    setupQuery: function setupQuery(filters) {
      var arr = {};

      _npmLodash.default.forEach(filters, function (value, key) {
        if (value.length && key != 'advanceFilters') {
          arr[key] = value.map(function (a) {
            return a.get('id');
          });
        } else if (key == 'advanceFilters') {// if(value.length) {
          //   let advanceFilterValues = [];
          //   value.forEach((appliedFilter) => {
          //     let obj = Ember.Object.create({
          //       id             : appliedFilter.get('filter.id'),
          //       filterValues   : appliedFilter.get('filterValues'),
          //       formattedValue : appliedFilter.get('formattedFilterValue'),
          //       objectType     : appliedFilter.get('objectType')
          //     })
          //     advanceFilterValues.pushObject(obj);
          //   })
          //   arr[key] = advanceFilterValues
          // }
        }
      });

      this.controller.set('reportFilters', JSON.stringify(arr));
    },
    getModel: function getModel(arr) {
      var currentPathName = this.get('router.currentPath').split('.')[2];
      var modelName;

      switch (currentPathName) {
        case 'recruiter':
          modelName = currentPathName;
          break;

        case 'panel':
          modelName = currentPathName;
          break;

        default:
          modelName = 'assessment-conversion';
          break;
      }

      if (modelName == 'recruiter' || modelName == 'panel') {
        return Ember.RSVP.hash({
          assessmentObj: arr.hasOwnProperty('assessment_id') ? this.get('store').query("reports/assessment-conversion", {
            assessment_id: arr.assessment_id
          }) : Ember.A([]),
          recruiterObj: arr.hasOwnProperty('user_id') ? this.get('store').query('user', {
            ids: arr.user_id,
            roles: 'Recruiter'
          }) : Ember.A([]),
          panelObj: arr.hasOwnProperty('user_id') && modelName == 'panel' ? this.get('store').query('user', {
            ids: arr.user_id,
            roles: 'Evaluator'
          }) : Ember.A([])
        });
      } else {
        return Ember.RSVP.hash({
          assessmentObj: arr.hasOwnProperty('assessment_id') ? this.get('store').query("reports/".concat(modelName), {
            assessment_id: arr.assessment_id
          }) : Ember.A([]),
          recruiterObj: arr.hasOwnProperty('recruiter_id') ? this.get('store').query('user', {
            ids: arr.recruiter_id,
            roles: 'Recruiter'
          }) : Ember.A([]),
          panelObj: arr.hasOwnProperty('panel_id') ? this.get('store').query('user', {
            ids: arr.panel_id,
            roles: 'Evaluator'
          }) : Ember.A([]),
          advanceFilterObj: arr.hasOwnProperty('advanceFilters') ? arr.advanceFilters : Ember.A([])
        });
      }
    },
    // fetchAdvanceFilters(filters) {
    //   filters.forEach((filter) => {
    //     let masterFilter = this.controller.get('advanceFilters').find((masterFilterObj) => {
    //       return masterFilterObj.get('id') === filter.id
    //     })
    //     this.getRecords(masterFilter, filter).then((response) => {
    //       this.get('appliedFilters').pushObject(response);
    //     })
    //   })
    // },
    // getRecords(masterFilter, filter) {
    //   let newRecord = this.get('store').createRecord('filter/applied', {
    //     filter                : masterFilter,
    //     filterValues          : filter.filterValues,
    //     formattedFilterValue  : filter.formattedValue,
    //     objectType            : filter.objectType
    //   });
    //   return Ember.RSVP.resolve(newRecord);
    // },
    actions: {
      setDateFilter: function setDateFilter(obj) {
        this.controller.set('dateRange', JSON.stringify(obj));
        this.controller.set('dateFilters', Ember.A([]));
        this.controller.get('dateFilters').pushObject(obj);
      },
      resetAppliedFilters: function resetAppliedFilters() {
        this.initialize();
        this.controller.set('isFiltersApplied', false);
      },
      toggleFilterResults: function toggleFilterResults(property) {
        this.controller.toggleProperty(property);
      },
      removeFilter: function removeFilter(arr, id) {
        var ids = arr.map(function (a) {
          return a.get('id');
        });

        if (_npmLodash.default.includes(ids, id)) {
          arr.removeAt(ids.indexOf(id));
        }

        this.send('postRemoveFilter');
      }
    }
  });

  _exports.default = _default;
});