define("recruit/components/reports/helpers/table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dynamicName: Ember.computed(function () {
      return this.get('column.label').camelize();
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // let elem = this.get('column.label').split(' ')[0];


      Ember.run.later('afterRender', function () {
        Ember.$(".".concat(_this.get('dynamicName'), ".ui.head")).popup({
          position: 'top center',
          inline: false,
          title: _this.get('column.label'),
          content: _this.get('column.columnTooltip')
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.run.cancel();
    }
  });

  _exports.default = _default;
});