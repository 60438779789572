define("recruit/components/recruiter-view/actions/delete-view", ["exports", "recruit/config/environment", "recruit/mixins/g-actions"], function (_exports, _environment, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    actions: {
      delete: function _delete() {
        var _this = this;

        this.set('isLoading', true);
        Ember.RSVP.Promise.all(this.get('selectedListValues')).then(function () {
          if (_this.get('selectedListValues.length')) {
            return _this.get('selectedListValues').reduce(function (res, model) {
              return model.destroyRecord();
            }, _this.get('selectedListValues.firstObject'), 1);
          }
        }).then(function () {
          _this.get('toast').success("".concat(_this.get('selectedListValues.length'), " ").concat(_this.get('listContain'), "(s)"), 'Successfully deleted');

          if (_this.get('model.redirectTo')) {
            _this.get('router').transitionTo(_this.get('model.redirectTo'));
          } else {
            _this.send('back');
          }
        }).catch(function (e) {
          return _this.catchError(e);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});