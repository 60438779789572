define("recruit/components/reports/helpers/date-filter-quick-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    linkValues: Ember.computed('links', function () {
      return this.get('links').replace(/-/g, ' ');
    }),
    filterValueObserver: Ember.observer('filterValue', function () {
      this.set('selectedFilterValue', this.get('filterValue'));
    }),
    actions: {
      setDate: function setDate(filter) {
        this.sendAction('setDate', filter);
      }
    }
  });

  _exports.default = _default;
});