define("recruit/templates/components/recruiter-view/assessment/assessment-overview/assessment-section-counts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/jh9UIzO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"text-blue padding-md-left padding-md-top padding-md-bottom margin-no margin-bottom-five\"],[9,\"style\",\"background: linear-gradient(90deg,#ECF1F5 100%,transparent 30%); /*padding: 10px; margin: 5px;/*\"],[7],[0,\"\\n  Calculating...\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/assessment-overview/assessment-section-counts.hbs"
    }
  });

  _exports.default = _default;
});