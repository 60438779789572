define("recruit/templates/components/recruiter-view/questions/helpers/type-dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7xdWLi2U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"item text-blue padding-sm-top padding-sm-bottom padding-md-left padding-md-right cursor-pointer\"],[10,\"data-value\",[20,[\"section\",\"label\"]],null],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"ui grid margin-no padding-no\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"one wide column margin-right-five padding-no\"],[7],[0,\"\\n\\t\\t\\t\"],[1,[25,\"fa-icon\",[[20,[\"section\",\"icon\"]]],[[\"class\"],[\"text-size-small text-blue\"]]],false],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"fourteen wide column margin-no padding-no\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"p\"],[9,\"class\",\"text-blue text-weight-medium margin-bottom-no\"],[7],[1,[20,[\"section\",\"label\"]],false],[8],[0,\"\\n\\t\\t\\t\"],[6,\"p\"],[9,\"class\",\"text-black margin-bottom-no text-weight-normal text-size-small\"],[7],[4,\"if\",[[20,[\"section\",\"description\"]]],null,{\"statements\":[[1,[20,[\"section\",\"description\"]],false]],\"parameters\":[]},{\"statements\":[[1,[20,[\"section\",\"data\",\"description\"]],false]],\"parameters\":[]}],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/type-dropdown-menu.hbs"
    }
  });

  _exports.default = _default;
});