define("recruit/components/helpers/status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      Ember.run.next(function () {
        Ember.$("[data-content]").popup({
          variation: 'inverted',
          position: 'bottom center'
        });
      });
    },
    tooltipContent: Ember.computed('status.isFulfilled', 'sectionType', function () {
      return "".concat(this.get('sectionType'), ": ").concat(this.get('status.label'));
    })
  });

  _exports.default = _default;
});