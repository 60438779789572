define("recruit/templates/components/reports/dashboard/dashboard-reports-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eNL8y+9A",
    "block": "{\"symbols\":[\"dashboard\",\"index\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"dashboardObject\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"dashboardObject\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"id\",[26,[[19,1,[\"type\"]]]]],[9,\"class\",\"ui accordion\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"active title text-black text-weight-medium\"],[9,\"style\",\"border-bottom: 1px solid #C5DAEB; margin: 15px; font-size: 1.2em\"],[7],[0,\"\\n        \"],[1,[19,1,[\"type\"]],false],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"dropdown icon text-black\"],[9,\"style\",\"float: right;\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"active content\"],[7],[0,\"\\n        \"],[1,[25,\"reports/dashboard/dashboard-list-tab\",null,[[\"tile\",\"dashboardObject\",\"indexValue\"],[[19,1,[\"tiles\"]],[20,[\"dashboardObject\"]],[19,2,[]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"margin-top-sixty\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"class\",\"ui medium centered image\"],[9,\"src\",\"/images/mis_report/no_activity.png\"],[9,\"alt\",\"cuton\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"margin-thirty text-center\"],[9,\"style\",\"word-wrap: break-word;\"],[7],[0,\"\\n      No results found for \"],[6,\"span\"],[9,\"class\",\"text-blue text-weight-medium\"],[7],[6,\"i\"],[7],[0,\"\\\"\"],[1,[18,\"filterValue\"],false],[0,\"\\\"\"],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/dashboard/dashboard-reports-list.hbs"
    }
  });

  _exports.default = _default;
});