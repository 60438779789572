define("recruit/templates/components/recruiter-view/actions/candidate-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "grw1J1Uw",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[6,\"div\"],[9,\"class\",\"action-candidate-list bg-grey light\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segments border-radius-none border-right-none border-bottom-none\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segment bg-white margin-no border-radius-none border-right-none padding-no\"],[9,\"style\",\"height:calc(60vh - 84px); overflow-y:auto;overflow-x:hidden;\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui segments padding-no border-none border-radius-none\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"listValues\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"recruiter-view/actions/candidate-detail\",null,[[\"value\",\"model\",\"parentObject\",\"internalView\",\"selectedListValues\",\"emptyList\",\"class\"],[[25,\"if\",[[20,[\"property\"]],[25,\"get-property\",[[19,1,[]],[20,[\"property\"]]],null],[19,1,[]]],null],[20,[\"model\"]],[19,1,[]],[20,[\"internalView\"]],[20,[\"selectedListValues\"]],[20,[\"emptyList\"]],\"ui basic segment padding-no border-radius-none\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segment text-center margin-no border-bottom-none\"],[9,\"style\",\"height: 66px\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"margin-top-ten\"],[7],[0,\"\\n        Total: \"],[6,\"span\"],[9,\"class\",\"action-candidate-list-score\"],[7],[0,\"\\n          \"],[1,[25,\"get-value\",[[20,[\"listValues\",\"length\"]]],null],false],[0,\"\\n          \"],[8],[0,\"  |   Selected: \"],[6,\"span\"],[9,\"class\",\"action-candidate-list-score\"],[7],[0,\"\\n          \"],[1,[25,\"get-value\",[[20,[\"selectedListValues\",\"length\"]]],null],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/actions/candidate-list.hbs"
    }
  });

  _exports.default = _default;
});