define("recruit/components/candidate-helpers/resume-score", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userPermission: Ember.inject.service('user-permission'),
    currentUser: Ember.inject.service('current-user'),
    openJD: false,
    resumeScore: Ember.computed('row.id', 'row.resumeMatchScore', function () {
      return _lodash.default.padStart(_lodash.default.round(this.get('row.resumeMatchScore')), 2, '0');
    }),
    score: Ember.computed('value', function () {
      if (this.get('row') && this.get('value')) {
        return _lodash.default.padStart(_lodash.default.round(_lodash.default.toInteger(this.get('value'))), 2, '0');
      } else {
        return null;
      }
    }),
    willDestroyElement: function willDestroyElement() {
      this.set('openJD', false);
    },
    hasResumeScore: Ember.computed.gt('row.resumeMatchScore', 0),
    hasCandidateResume: Ember.computed.gte('row.candidate.resume.length', 1),
    hasValidJD: Ember.computed.alias('row.assessment.hasValidJD'),
    actions: {
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});