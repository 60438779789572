define("recruit/templates/components/helpers/status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ov8PZkLb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"status\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[7],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",[26,[\"panel-status \",[20,[\"status\",\"statusClass\"]]]]],[10,\"data-content\",[18,\"tooltipContent\"],null],[7],[0,\"\\n\"],[4,\"if\",[[25,\"and\",[[20,[\"isScore\"]],[20,[\"score\"]]],null]],null,{\"statements\":[[0,\"        \"],[6,\"p\"],[9,\"class\",\"text-center\"],[9,\"style\",\"margin-top:4px;\"],[7],[1,[18,\"score\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"p\"],[9,\"class\",\"text-center\"],[9,\"style\",\"margin-top:4px;\"],[7],[1,[20,[\"status\",\"formattedLabel\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"span\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"panel-status dead\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"text-center\"],[9,\"style\",\"margin-top:4px;\"],[7],[0,\"_\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/status-icon.hbs"
    }
  });

  _exports.default = _default;
});