define("recruit/components/recruiter-view/candidate/section-level-evaluations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        Ember.$('.ui.accordion').accordion();

        _this.get('evaluations').forEach(function (e) {
          e.get('formInstance');
        });
      });
    },
    actions: {
      toggleModal: function toggleModal(evaluation) {
        var _this2 = this;

        if (evaluation) {
          evaluation.get('formInstance').then(function (formData) {
            _this2.setProperties({
              selectedEvaluation: evaluation,
              selectedFormData: formData.get('formData')
            });

            _this2.toggleProperty('openModal');
          });
        } else {
          this.toggleProperty('openModal');
        }
      }
    }
  });

  _exports.default = _default;
});