define("recruit/routes/recruiter/reports/assessments/conversion", ["exports", "recruit/routes/recruiter/reports/report-filters"], function (_exports, _reportFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportFilters.default.extend({
    store: Ember.inject.service(),
    model: function model() {
      return this.store.query('filter/master', {
        object_type: 'a'
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('advanceFilters', model);
      controller.set('nodeValues', Ember.A(['invitedCount', 'inprogressCount', 'completedCount', 'selectedCount']));
      controller.set('cirlceLabels', Ember.A(['Total Invited', 'Total Attempted', 'Completed', 'Selected']));
    },
    actions: {
      setFilters: function setFilters(assessment, recruiter, panel, advanceFilters) {
        this.controller.set('applyFilters', Ember.A([]));
        this.controller.get('applyFilters').pushObject({
          recruiter_id: recruiter,
          panel_id: panel,
          assessment_id: assessment,
          advanceFilters: advanceFilters
        });
        this.checkIfFiltersApplied(this.controller.get('applyFilters.firstObject'));
      },
      postRemoveFilter: function postRemoveFilter() {
        this.checkIfFiltersApplied(this.controller.get('applyFilters.firstObject'));
      }
    }
  });

  _exports.default = _default;
});