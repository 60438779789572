define("recruit/templates/components/panel/events/request-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CNVTjQuJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"eight wide column\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui centered grid\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"/images/message.png\"],[9,\"style\",\"height: 71px;\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-yellow text-weight-medium text-size-sixteen padding-no-bottom\"],[7],[0,\"\\n      Requested\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"twelve wide middle aligned column text-black text-size-fourteen\"],[7],[0,\"\\n      We have shared your request with the recruiter. You may close this window now.\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/events/request-form.hbs"
    }
  });

  _exports.default = _default;
});