define("recruit/templates/recruiter/assessment/details/assessment-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ImXgjMlJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"assessment/update-assessment\",null,[[\"assessmentModel\",\"allSections\",\"assessmentSections\",\"isDevelopment\",\"currentUser\",\"currentSlide\",\"sectionId\",\"showJdAlert\"],[[20,[\"model\"]],[20,[\"model\",\"allSections\"]],[20,[\"model\",\"assessmentSections\"]],[20,[\"isDevelopment\"]],[20,[\"currentUser\"]],[20,[\"currentSlide\"]],[20,[\"sectionId\"]],[20,[\"showJdAlert\"]]]]],false],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/assessment/details/assessment-update.hbs"
    }
  });

  _exports.default = _default;
});