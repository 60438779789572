define("recruit/templates/components/reports-table-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OLzyi/mo",
    "block": "{\"symbols\":[\"column\",\"index\",\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"reports-table-placeholder \",[25,\"if\",[[20,[\"customColor\"]],[20,[\"customColor\"]]],null]]]],[10,\"style\",[26,[\"width: \",[20,[\"columns\",\"firstObject\",\"width\"]],\"; padding: 15px; border-bottom: 1px solid #eee; border-right: 1px solid #eee; display: inline; float: left;\"]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[9,\"style\",\"position: relative; bottom: 5px;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"columns\",\"lastObject\",\"subColumns\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"customColor\"]],[20,[\"customColor\"]]],null]]]],[10,\"style\",[26,[\"width: \",[19,1,[\"width\"]],\"; border-bottom: 1px solid #eee; border-right: 1px solid #eee; display: inline; padding: 15px; float: left;\"]]],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[9,\"style\",\"position: relative; bottom: 5px;\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column loader-header animated-background\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1,2]},null],[11,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports-table-placeholder.hbs"
    }
  });

  _exports.default = _default;
});