define("recruit/templates/components/reports/helpers/assessment-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A2nq0m83",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"\"],[9,\"style\",\"padding-bottom: 10px;\"],[7],[0,\"\\n    \"],[1,[20,[\"column\",\"label\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ui category search item\"],[9,\"style\",\"width: 100%;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui icon input\"],[9,\"style\",\"width: 70%; margin-right: 15px;\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"reports-table-search-box\",\"Search...\",[20,[\"searchText\"]]]]],false],[0,\"\\n    \"],[6,\"i\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"searchText\"]],\"remove circle\",\"search\"],null],\" icon cursor-pointer cursor-pointer-events-all\"]]],[3,\"action\",[[19,0,[]],\"clearSearchText\"]],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/assessment-header.hbs"
    }
  });

  _exports.default = _default;
});