define("recruit/models/assessment-candidate", ["exports", "ember-data", "npm:lodash"], function (_exports, _emberData, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    invited_at: _emberData.default.attr('pgdate'),
    completed_at: _emberData.default.attr('pgdate'),
    attended_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    proviewRating: _emberData.default.attr('string'),
    reasonOfFailure: _emberData.default.attr('string'),
    //Reason why TBI report was not generated
    tbiStatus: _emberData.default.attr('string'),
    behaviouralProfileMatch: _emberData.default.attr('string'),
    behaviouralFitmentIndex: _emberData.default.attr('number'),
    invited_by: _emberData.default.belongsTo('user'),
    status: _emberData.default.belongsTo('status'),
    candidate: _emberData.default.belongsTo('candidate', {
      inverse: null
    }),
    assessment: _emberData.default.belongsTo('assessment', {
      async: true,
      inverse: 'assessmentCandidates'
    }),
    assessmentSectionCandidates: _emberData.default.hasMany('assessment-section-candidate', {
      inverse: null
    }),
    evaluations: _emberData.default.hasMany('evaluation'),
    tbiReport: _emberData.default.hasMany('file'),
    resumeMatchScore: _emberData.default.attr('number'),
    skillMatchScore: _emberData.default.attr('number'),
    educationMatchScore: _emberData.default.attr('number'),
    experienceMatchScore: _emberData.default.attr('number'),
    tbiMatch: Ember.computed.alias('behaviouralProfileMatch'),
    tbiIndex: Ember.computed.alias('behaviouralFitmentIndex'),
    hasTbiTooltip: Ember.computed.and('tbiMatch', 'tbiIndex'),
    hasValidTbi: Ember.computed.equal('tbiStatus', 'Generated'),
    formattedRating: Ember.computed('proviewRating', function () {
      return _npmLodash.default.toLower(this.get('proviewRating'));
    }),
    formattedTbiStatus: Ember.computed('tbiStatus', function () {
      switch (this.get('tbiStatus')) {
        case 'To Be Generated':
          return Ember.Object.create({
            value: 'Generating...',
            color: 'grey'
          });

        case 'Generating':
          return Ember.Object.create({
            value: 'Generating...',
            color: 'grey'
          });

        case 'Generated':
          return this.get('behaviouralProfileMatch') ? Ember.Object.create({
            value: this.get('behaviouralProfileMatch'),
            color: this.get('behaviouralProfileMatch') == 'Recommended' ? 'green' : 'red'
          }) : Ember.Object.create({
            value: 'Not available',
            color: 'grey'
          });

        case 'Not Available':
          return Ember.Object.create({
            value: 'Not available',
            color: 'grey'
          });

        default:
          return Ember.Object.create({
            value: 'Not available',
            color: 'grey'
          });
      }
    }),
    isRelationsLoaded: Ember.computed('status', 'candidate', 'assessment', 'assessmentSectionCandidates.@each', 'assessmentSectionCandidates.@each.status', function () {
      var _this = this;

      this.get('status').then(function () {
        return _this.get('candidate');
      }).then(function () {
        return _this.get('assessment');
      }) // .then(() => this.get('assessmentSectionCandidates'))
      // .then(assessmentSectionCandidates => assessmentSectionCandidates.map(item => item.get('status')))
      .then(function () {
        return _this.set('isRelationsLoaded', true);
      }).catch(function () {
        return _this.set('isRelationsLoaded', true);
      });
    }),
    can: Ember.computed.alias('candidate'),
    proviewLogColor: Ember.computed(function () {
      if (this.get('proviewRating')) {
        switch (_npmLodash.default.toLower(this.get('proviewRating'))) {
          case 'high':
            return 'text-green';

          case 'medium':
            return 'text-yellow';

          case 'low':
            return 'text-red';

          default:
            return 'text-grey';
        }
      } else {
        return 'text-grey';
      }
    }),
    isCompleted: Ember.computed.gte('status.sort_key', 50),
    behavioralTooltip: Ember.computed('tbiStatus', 'isCompleted', function () {
      if (this.get('isCompleted')) {
        if (this.get('tbiStatus') == 'Generating' || this.get('tbiStatus') == 'To Be Generated') {
          return "<div class=\"text-size-small\">We are generating the behavioral report.</div>";
        } else if (this.get('tbiStatus') == 'Generated') {
          return "<div class=\"text-size-small\"><div>Match: <span class=\"text-weight-medium text-".concat(this.get('formattedTbiStatus.color'), "\">").concat(this.get('formattedTbiStatus.value'), "</span></div>\n        <div>Behavioral Fitment Index : <span class=\"text-weight-medium\">").concat(this.get('behaviouralFitmentIndex'), "</span></div></div>");
        } else {
          return "<div class=\"text-size-small\"><div class=\"text-red text-weight-medium\">Behavioral report not available.</div>\n        <div class=\"text-black\">No reliable report can be generated due to lack of sufficient data.</div></div>";
        }
      } else {
        return "<div class=\"text-size-small\">The candidate has not completed the assessment.</div>";
      }
    })
  });

  _exports.default = _default;
});