define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-stats", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('model.all').then(function (res) {
        _this.set('total', res.get('meta.total'));

        _this.set('shortListed', _this.get('model.selectedCount'));

        _this.generatePieChart(_this.get('total'), _this.get('shortListed'));
      });
      this.generateTimeTakenChart();
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          inline: true,
          position: 'top-center'
        });
      });
    },
    generatePieChart: function generatePieChart(total, shortListed) {
      if (shortListed) {
        var l1 = Math.round(shortListed / shortListed);
        var l2 = Math.round(total / shortListed);
        this.set('pieData', [{
          y: l1,
          color: '#4A90E2',
          name: 'Shortlisted'
        }, {
          y: l2,
          color: '#F0F0F0',
          name: 'Invited'
        }]);
      } else {
        this.set('pieData', [{
          y: shortListed,
          color: '#4A90E2'
        }, {
          y: total,
          color: '#F0F0F0'
        }]);
      }

      this.set('renderPie', true);
    },
    generateTimeTakenChart: function generateTimeTakenChart() {
      var _this2 = this;

      this.get('model.metrics').then(function (res) {
        _this2.getValues(res);
      });
    },
    getLabelName: function getLabelName(sortValue) {
      switch (sortValue) {
        case 0:
          return 'Send Invitations';

        case 1:
          return 'Complete Assessment';

        case 2:
          return 'Assign Panel';

        case 3:
          return 'Evaluate';

        case 4:
          return 'Final Selection';
      }
    },
    getValues: function getValues(res) {
      var x_axis_values = [res.get('inviteTime'), res.get('completeTime'), res.get('assignTime'), res.get('evaluationTime'), res.get('shortlistTime')];
      x_axis_values.forEach(function (val, index) {
        x_axis_values[index] = parseInt(_moment.default.duration(val, 'seconds').asDays());
      });
      this.computeXRange(x_axis_values);
    },
    computeXRange: function computeXRange(x_axis_values) {
      var second_limit = x_axis_values.objectAt(0) + x_axis_values.objectAt(1);
      var third_limit = x_axis_values.objectAt(0) + x_axis_values.objectAt(2);
      var max_val = Math.max(second_limit, third_limit);
      var fourth_limit = max_val + x_axis_values.objectAt(3);
      var fifth_limit = fourth_limit + x_axis_values.objectAt(4);
      var x_array = Ember.A([{
        x: 0,
        x2: x_axis_values.objectAt(0),
        diff: x_axis_values.objectAt(0),
        tooltip_text: 'Average time taken to send invitations to the candidates by recruiter.'
      }, {
        x: x_axis_values.objectAt(0),
        x2: second_limit,
        diff: x_axis_values.objectAt(1),
        tooltip_text: 'Average time taken by the candidates to complete the assessment.'
      }, {
        x: x_axis_values.objectAt(0),
        x2: third_limit,
        diff: x_axis_values.objectAt(2),
        tooltip_text: 'Average time taken by the recruiter to assign panel to the candidates.'
      }, {
        x: max_val,
        x2: fourth_limit,
        diff: x_axis_values.objectAt(3),
        tooltip_text: 'Average time taken by the panel to evaluate candidates.'
      }, {
        x: fourth_limit,
        x2: fifth_limit,
        diff: x_axis_values.objectAt(4),
        tooltip_text: 'Average time taken by the recruiter to finally shortlist a candidate.'
      }]);
      var overAll = x_axis_values.objectAt(0) + Math.max(x_axis_values.objectAt(1), x_axis_values.objectAt(2)) + x_axis_values.objectAt(3) + x_axis_values.objectAt(4);
      this.set('overallTimeTaken', overAll);
      this.computeDataArray(x_array);
    },
    computeDataArray: function computeDataArray(x_array) {
      var _this3 = this;

      var y_axis_values = [0, 1, 2, 3, 4];
      var count = y_axis_values.map(function (val) {
        return {
          name: _this3.getLabelName(val),
          x: x_array.objectAt(val).x,
          x2: x_array.objectAt(val).x2,
          diff: x_array.objectAt(val).diff,
          y: val,
          tooltip_text: x_array.objectAt(val).tooltip_text,
          color: '#D2E7FF'
        };
      });
      this.set('dataArray', count);
      this.set('renderGraph', true);
    }
  });

  _exports.default = _default;
});