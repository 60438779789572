define("recruit/templates/components/events/panel-match-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S9JqZULL",
    "block": "{\"symbols\":[\"match\",\"index\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui middle aligned grid margin-no text-size-small padding-sm\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"matchedObject\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"nine wide column text-black\"],[9,\"style\",\"padding: 3px;\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"assessmentSection\",\"section\",\"isFulfilled\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"isClickable\"]]],null,{\"statements\":[[4,\"link-to\",[\"recruiter.assessment.details.assessment-section\",[20,[\"assessment\",\"id\"]],[19,1,[\"assessmentSection\",\"id\"]],[25,\"query-params\",null,[[\"event\"],[[20,[\"eventModel\",\"id\"]]]]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"fa-icon\",[[19,1,[\"assessmentSection\",\"section\",\"sectionType\",\"icon\"]]],[[\"class\"],[\"margin-right-five\"]]],false],[0,\"\\n            \"],[1,[19,1,[\"assessmentSection\",\"section\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"fa-icon\",[[19,1,[\"assessmentSection\",\"section\",\"sectionType\",\"icon\"]]],[[\"class\"],[\"margin-right-five\"]]],false],[0,\"\\n          \"],[1,[19,1,[\"assessmentSection\",\"section\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[1,[25,\"events/panel-match-popup-users\",null,[[\"matchedPanels\",\"index\",\"threshold\",\"extraPanels\"],[[19,1,[\"panels\"]],[19,2,[]],[20,[\"threshold\"]],[20,[\"extraPanels\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[8],[0,\"\\n\"],[11,3]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/panel-match-popup.hbs"
    }
  });

  _exports.default = _default;
});