define("recruit/transitions/left-right", ["exports", "liquid-fire", "recruit/transitions/move-over"], function (_exports, _liquidFire, _moveOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = leftRight;

  // import fade from "./fade";
  function leftRight() {
    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    (0, _liquidFire.stop)(this.oldElement); // if (this.oldElement) {
    //   this.oldElement.css('opacity', '1');
    // }
    // if (this.newElement) {
    //   this.newElement.css('opacity', '1');
    // }

    if (this.newValue.get('id') > this.oldValue.get('id')) {
      return _liquidFire.Promise.all([_moveOver.default.call(this, 'x', -1, opts) // animate(this.oldElement, {opacity: 0.5}, opts),
      // animate(this.newElement, {opacity: [(opts.maxOpacity || 1), 0], duration: 500}, opts),
      ]);
    } else {
      return _liquidFire.Promise.all([_moveOver.default.call(this, 'x', 1, opts) // animate(this.oldElement, {opacity: 0.5}, opts),
      // animate(this.newElement, {opacity: [(opts.maxOpacity || 1), 0], duration: 500}, opts),
      ]);
    }
  }
});