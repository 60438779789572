define("recruit/templates/components/helpers/group-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nHKPc8QY",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"item\"],[7],[0,\"\\n  \"],[1,[25,\"ui-checkbox\",null,[[\"label\",\"class\",\"onChange\"],[[20,[\"parentLabel\"]],\"master padding-vs-bottom\",[25,\"action\",[[19,0,[]],\"selectedAll\"],null]]]],false],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"list padding-md-left\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"items\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"item padding-sm-bottom\"],[7],[0,\"\\n        \"],[1,[25,\"ui-checkbox\",null,[[\"label\",\"checked\",\"class\",\"onChange\",\"disabled\"],[[25,\"if\",[[19,1,[\"extra\",\"tooltip\"]],[19,1,[\"extra\",\"tooltip\"]],[19,1,[\"label\"]]],null],[19,1,[\"isChecked\"]],\"child\",[25,\"action\",[[19,0,[]],\"sendProperty\",[19,1,[]]],null],[19,1,[\"isDisabled\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/group-checkbox.hbs"
    }
  });

  _exports.default = _default;
});