define("recruit/components/validated-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: false,
    classNames: ['field'],
    classNameBindings: ['required'],
    passwordStrength: 0,
    allErrors: Ember.A([]),
    //Services
    ajax: Ember.inject.service('custom-ajax'),
    session: Ember.inject.service(),
    actions: {
      validateProperty: function validateProperty(changeset, property) {
        changeset.execute();
        return changeset.validate(property);
      },
      customAction: function customAction(key) {
        this.sendAction('customAction', key);
      },
      focusOutAction: function focusOutAction(changeset) {
        changeset.execute();
        this.sendAction('focusOutAction');
      },
      contentsChanged: function contentsChanged(value) {
        this.get('changeset').validate(value);
        this.set('changeset.content', value);
      },
      uploadImage: function uploadImage(image) {
        this.sendAction('uploadImage', image);
      },
      passwordChanged: function passwordChanged(changeset, property) {
        var _this = this;

        var newPassword = changeset._changes[property];

        if (newPassword) {
          Ember.run.debounce(this, function () {
            return _this.send('checkStrength', newPassword.value);
          }, 1000);
        }
      },
      checkStrength: function checkStrength(newPassword) {
        var _this2 = this;

        this.get('ajax').post('password-strength', {
          namespace: 'user/me',
          method: 'POST',
          contentType: 'application/json',
          data: {
            password: newPassword
          }
        }).then(function (data) {
          _this2.set('passwordStrength', data.score * 20);
        });
      }
    }
  });

  _exports.default = _default;
});