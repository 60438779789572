define("recruit/components/recruiter-view/actions/change-status", ["exports", "recruit/config/environment", "moment", "recruit/mixins/g-actions"], function (_exports, _environment, _moment, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    isNotify: false,
    isLoading: false,
    allowNotify: Ember.computed('selectedStatus', function () {
      if (!this.get('selectedStatus')) return false;
      return this.get('selectedStatus') == 90 || this.get('selectedStatus') == 70;
    }),
    ReasonText: Ember.computed('selectedStatus', function () {
      if (this.get('selectedStatus') === '90') {
        return 'Rejection';
      }

      if (this.get('selectedStatus') === '100') {
        return 'Suspending';
      }

      if (this.get('selectedStatus') === '120') {
        return 'Archiving';
      }
    }),
    actions: {
      changeStatus: function changeStatus() {
        var _this = this;

        if (!this.get('selectedStatus')) {
          return this.get('toast').error('', "Status Can't be blank");
        }

        this.set('isLoading', true);
        this.set('assessmentCandidates', this.get('selectedListValues').map(function (ac) {
          return ac.get('id');
        }));
        this.get('store').createRecord('actions/change-status', {
          assessmentCandidates: this.get('assessmentCandidates'),
          isNotify: this.get('isNotify'),
          statusSortKey: this.get('selectedStatus'),
          reasonText: this.get('reasonText'),
          sync: true
        }).save().then(function () {
          _this.get('store').query('assessment-candidate', {
            ids: _this.get('assessmentCandidates'),
            v: (0, _moment.default)().unix()
          }).then(function () {
            _this.get('toast').success("for ".concat(_this.get('selectedListValues.length'), " Candidates"), 'Status Successfully Updated');
          });
        }).catch(function (e) {
          _this.catchError(e);
        }).finally(function () {
          _this.set('isLoading', false);

          return _this.send('back');
        });
      }
    }
  });

  _exports.default = _default;
});