define("recruit/models/section-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "name": _emberData.default.attr('string'),
    "title": _emberData.default.attr('string'),
    "isInvite": _emberData.default.attr('boolean'),
    "label": _emberData.default.attr('string'),
    "icon": _emberData.default.attr('icon'),
    "sections": _emberData.default.hasMany('sections'),
    "component": _emberData.default.attr('string'),
    "answerComponent": _emberData.default.attr('string'),
    "backendProperty": _emberData.default.attr('string')
  });

  _exports.default = _default;
});