define("recruit/components/nag/nag-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['nagFixed'],
    nagFixed: Ember.computed(function () {
      return this.get('fixed');
    }),
    actions: {
      toggle: function toggle(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});