define("recruit/models/notification-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    from_address: _emberData.default.attr('string'),
    type_id: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    category_id: _emberData.default.attr('string'),
    organization_id: _emberData.default.belongsTo('organization'),
    assessment: _emberData.default.belongsTo('assessment'),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    created_by: _emberData.default.belongsTo('user'),
    updated_by: _emberData.default.belongsTo('user'),
    tags: _emberData.default.hasMany('notification-template-tag')
  });

  _exports.default = _default;
});