define("recruit/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "recruit/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/authenticators/oauth2.js
  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.APP.API_ROOT + '/oauth2/token',
    clientId: 'web-application-3'
  });

  _exports.default = _default;
});