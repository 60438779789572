define("recruit/templates/recruiter/candidate/create/bulk-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AZzmoTp8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"text-black\"],[7],[0,\"\\n  \"],[1,[18,\"recruiter-view/bulk/bulk-upload\"],false],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/candidate/create/bulk-create.hbs"
    }
  });

  _exports.default = _default;
});