define("recruit/components/filters/advance-filters", ["exports", "moment", "npm:lodash"], function (_exports, _moment, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getFormattedTimeStamp(date, type, isPast, isStart) {
    date = (0, _moment.default)(date);
    date = isPast ? date.subtract(1, type) : date;
    date = isStart ? date.startOf(type) : date.endOf(type);
    return date.toISOString();
  }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedFilter: null,
    updateFilterObject: function updateFilterObject(createdFilter, selectedFilterObject) {
      var filterSubType = this.get('filterSubType');
      var startTime, endTime;

      switch (selectedFilterObject.get('type')) {
        case 'radio-input':
          createdFilter.set('filterValues', {
            value: this.get('radioValue')
          });
          createdFilter.set('formattedFilterValue', this.get('radioValue'));
          break;

        case 'plain-input':
          createdFilter.set('filterValues', {
            value: this.get('plainInput')
          });
          createdFilter.set('formattedFilterValue', this.get('plainInput'));
          break;

        case 'text':
          // if(filterSubType == 'is') {
          // createdFilter.set('filterValues', {condition: '', value: this.get('filterSubValue'), exact: '.raw:'});
          createdFilter.set('filterValues', {
            condition: '',
            value: this.get('filterSubValue'),
            exact: ':'
          });
          createdFilter.set('formattedFilterValue', this.get('filterSubValue')); // } else if(filterSubType == 'contains') {
          // createdFilter.set('filterValues', {condition: '', value: `*${this.get('filterSubValue')}*`, exact: ':'});
          // createdFilter.set('formattedFilterValue', 'contains ' + this.get('filterSubValue'));
          // }
          //  else {
          //   createdFilter.set('filterValues', {condition: 'NOT', value: this.get('filterSubValue'), exact: ':'});
          //   createdFilter.set('formattedFilterValue', 'not contains ' + this.get('filterSubValue'));
          // }

          break;

        case 'date':
          // filter.filterSubType = filterSubType;
          switch (filterSubType) {
            case 'is':
              switch (this.get('filterSubValue')) {
                case "today":
                  startTime = getFormattedTimeStamp(new Date(), 'day', false, true);
                  endTime = getFormattedTimeStamp(new Date(), 'day', false, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "yesterday":
                  startTime = getFormattedTimeStamp(new Date(), 'day', true, true);
                  endTime = getFormattedTimeStamp(new Date(), 'day', true, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "this-week":
                  startTime = getFormattedTimeStamp(new Date(), 'week', false, true);
                  endTime = getFormattedTimeStamp(new Date(), 'week', false, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "last-week":
                  startTime = getFormattedTimeStamp(new Date(), 'week', true, true);
                  endTime = getFormattedTimeStamp(new Date(), 'week', true, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "this-month":
                  startTime = getFormattedTimeStamp(new Date(), 'month', false, true);
                  endTime = getFormattedTimeStamp(new Date(), 'month', false, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "last-month":
                  startTime = getFormattedTimeStamp(new Date(), 'month', true, true);
                  endTime = getFormattedTimeStamp(new Date(), 'month', true, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;
              }

              break;

            case 'is-equal':
              startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true);
              endTime = getFormattedTimeStamp(this.get('date'), 'day', false, false);
              createdFilter.set('filterValues', {
                start: startTime,
                end: endTime
              });
              createdFilter.set('formattedFilterValue', (0, _moment.default)(this.get('date')).format("DD MMM'YY"));
              break;

            case 'is-after':
              startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true); // endTime + getFormattedTimeStamp(this.get('date'), 'day', false, false);

              createdFilter.set('filterValues', {
                start: startTime,
                end: '*'
              });
              createdFilter.set('formattedFilterValue', ' after ' + (0, _moment.default)(this.get('date')).format("DD MMM'YY"));
              break;

            case 'is-before':
              // startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true);
              endTime = getFormattedTimeStamp(this.get('date'), 'day', false, false);
              createdFilter.set('filterValues', {
                start: '*',
                end: endTime
              });
              createdFilter.set('formattedFilterValue', ' before ' + (0, _moment.default)(this.get('date')).format("DD MMM'YY"));
              break;

            case 'is-between':
              startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true);
              endTime = getFormattedTimeStamp(this.get('date1'), 'day', false, false);
              createdFilter.set('filterValues', {
                start: startTime,
                end: endTime
              });
              createdFilter.set('formattedFilterValue', ' between ' + (0, _moment.default)(this.get('date')).format("DD MMM'YY") + ' and ' + (0, _moment.default)(this.get('date1')).format("DD MMM'YY"));
              break;
          }

          break;

        case 'date-list':
          // filter.filterSubType = filterSubType;
          switch (filterSubType) {
            case 'is':
              switch (this.get('filterSubValue')) {
                case "today":
                  startTime = getFormattedTimeStamp(new Date(), 'day', false, true);
                  endTime = getFormattedTimeStamp(new Date(), 'day', false, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "yesterday":
                  startTime = getFormattedTimeStamp(new Date(), 'day', true, true);
                  endTime = getFormattedTimeStamp(new Date(), 'day', true, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "this-week":
                  startTime = getFormattedTimeStamp(new Date(), 'week', false, true);
                  endTime = getFormattedTimeStamp(new Date(), 'week', false, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "last-week":
                  startTime = getFormattedTimeStamp(new Date(), 'week', true, true);
                  endTime = getFormattedTimeStamp(new Date(), 'week', true, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "this-month":
                  startTime = getFormattedTimeStamp(new Date(), 'month', false, true);
                  endTime = getFormattedTimeStamp(new Date(), 'month', false, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;

                case "last-month":
                  startTime = getFormattedTimeStamp(new Date(), 'month', true, true);
                  endTime = getFormattedTimeStamp(new Date(), 'month', true, false);
                  createdFilter.set('filterValues', {
                    start: startTime,
                    end: endTime
                  });
                  createdFilter.set('formattedFilterValue', _npmLodash.default.chain(this.get('filterSubValue')).lowerCase().value());
                  break;
              }

              break;

            case 'is equal to':
              startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true);
              endTime = getFormattedTimeStamp(this.get('date'), 'day', false, false);
              createdFilter.set('filterValues', {
                start: startTime,
                end: endTime
              });
              createdFilter.set('formattedFilterValue', (0, _moment.default)(this.get('date')).format("DD MMM'YY"));
              break;

            case 'is after':
              startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true); // endTime + getFormattedTimeStamp(this.get('date'), 'day', false, false);

              createdFilter.set('filterValues', {
                start: startTime,
                end: '*'
              });
              createdFilter.set('formattedFilterValue', ' after ' + (0, _moment.default)(this.get('date')).format("DD MMM'YY"));
              break;

            case 'is before':
              // startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true);
              endTime = getFormattedTimeStamp(this.get('date'), 'day', false, false);
              createdFilter.set('filterValues', {
                start: '*',
                end: endTime
              });
              createdFilter.set('formattedFilterValue', ' before ' + (0, _moment.default)(this.get('date')).format("DD MMM'YY"));
              break;

            case 'is between':
              startTime = getFormattedTimeStamp(this.get('date'), 'day', false, true);
              endTime = getFormattedTimeStamp(this.get('date1'), 'day', false, false);
              createdFilter.set('filterValues', {
                start: startTime,
                end: endTime
              });
              createdFilter.set('formattedFilterValue', ' between ' + (0, _moment.default)(this.get('date')).format("DD MMM'YY") + ' and ' + (0, _moment.default)(this.get('date1')).format("DD MMM'YY"));
              break;
          }

          break;

        case 'date-range':
          startTime = getFormattedTimeStamp(this.get('startDate'), 'day', false, true);
          endTime = getFormattedTimeStamp(this.get('endDate'), 'day', false, false);
          createdFilter.set('filterValues', {
            start: startTime,
            end: endTime
          });
          createdFilter.set('formattedFilterValue', ' between ' + (0, _moment.default)(this.get('startDate')).format("DD MMM'YY") + ' and ' + (0, _moment.default)(this.get('endDate')).format("DD MMM'YY"));
          break;

        case 'range':
          if (filterSubType == 'is-between') {
            createdFilter.set('filterValues', {
              start: this.get('filterSubValue'),
              end: this.get('filterSubValue1')
            });
            createdFilter.set('formattedFilterValue', "between ".concat(this.get('filterSubValue'), " and ").concat(this.get('filterSubValue1')));
          } else if (filterSubType == 'is-equal') {
            createdFilter.set('filterValues', {
              start: this.get('filterSubValue'),
              end: this.get('filterSubValue')
            });
            createdFilter.set('formattedFilterValue', '=' + this.get('filterSubValue'));
          } else if (filterSubType == 'is-less') {
            createdFilter.set('filterValues', {
              start: '*',
              end: +this.get('filterSubValue') - 1
            });
            createdFilter.set('formattedFilterValue', '<' + this.get('filterSubValue'));
          } else if (filterSubType == 'is-more') {
            createdFilter.set('filterValues', {
              start: +this.get('filterSubValue') + 1,
              end: '*'
            });
            createdFilter.set('formattedFilterValue', '>' + this.get('filterSubValue'));
          }

          break;

        case 'list':
          if (selectedFilterObject.get('isRemote')) {
            var l = this.get('listValue');
            createdFilter.set('filterValues', {
              value: l.get('value')
            });
            createdFilter.set('formattedFilterValue', l.get('key'));
          } else {
            var v = this.get('listValue');

            if (selectedFilterObject.get('field') == "Invite Status") {
              createdFilter.set('filterValues', {
                value: v.id
              });
              createdFilter.set('formattedFilterValue', v.key);
            } else {
              createdFilter.set('filterValues', {
                value: v.value
              });
              createdFilter.set('formattedFilterValue', v.key);
            }
          }

          break;

        case 'sectionName':
          createdFilter.set('filterValues', this.get('selectedAssessmentSection'));
          break;
        // case 'sectionStatus':
        // // filter.value = [];
        // //   filter.value[this.get('selectedAssessmentSection')] = this.get('selectedSectionStatus');
        //   // filter.value = {}
        //   // Object.defineProperty(filter.value, this.get('selectedAssessmentSection'), {value: [this.get('selectedSectionStatus')]})
        // break;

        case 'checkbox':
          var checkedObjects = selectedFilterObject.get('list').filter(function (obj) {
            return obj.value == true;
          });
          var value = checkedObjects.map(function (obj) {
            if (selectedFilterObject.get('field') === "Behavioral Profile Match") {
              /* if more conditions are coming then change query structure from backend. */
              return "(".concat(obj.key, ")");
            }

            return obj.key;
          });
          createdFilter.set('filterValues', {
            value: value.join(',')
          });
          createdFilter.set('formattedFilterValue', checkedObjects.map(function (a) {
            return a.key;
          }).join(','));
          break;
      }

      return Ember.RSVP.resolve(createdFilter);
    },
    findOrCreate: function findOrCreate(selectedFilter) {
      var _this = this;

      return this.get('store').query('filter/applied', {
        filter: selectedFilter,
        objectType: selectedFilter.get('objectType')
      }).then(function (appliedFilters) {
        return Ember.RSVP.resolve(appliedFilters.forEach(function (filter) {
          return filter.destroyRecord();
        }));
      }).then(function () {
        var newRecord = _this.get('store').createRecord('filter/applied', {
          filter: selectedFilter,
          objectType: selectedFilter.get('objectType')
        });

        return Ember.RSVP.resolve(newRecord);
      });
    },
    actions: {
      cleanProperties: function cleanProperties(selectedFilterObject) {
        this.setProperties({
          selectedFilter: this.get('isAsscDateFilter') ? this.get('selectedFilter') : null,
          filterSubType: null,
          filterSubValue: null,
          date: null,
          date1: null,
          startDate: null,
          endDate: null,
          listValue: null,
          plainInput: null,
          list: null
        });

        if (selectedFilterObject && selectedFilterObject.get('addColumn')) {
          this.sendAction('addColumn');
        }
      },
      applyFilter: function applyFilter() {
        var _this2 = this;

        var selectedFilterObject = this.get('selectedFilter'); // if(selectedFilterObject.get('type') == 'text' || selectedFilterObject.get('type') == 'date') {
        //   if(!this.get('filterSubType')) {
        //     return this.get('toast').error('Please select a type', 'Error');
        //   }
        // }

        if (selectedFilterObject.get('type') == 'list' && !this.get('listValue')) {
          return this.get('toast').error("".concat(selectedFilterObject.get('field'), " can't be blank"), 'Error');
        }

        if (selectedFilterObject.get('type') == 'plain-input' && !this.get('plainInput')) {
          return this.get('toast').error("".concat(selectedFilterObject.get('field'), " can't be blank"), 'Error');
        }

        if (selectedFilterObject.get('type') == 'text' && !this.get('filterSubValue')) {
          return this.get('toast').error("".concat(selectedFilterObject.get('field'), " can't be blank"), 'Error');
        }

        if (selectedFilterObject.get('type') == 'date' || selectedFilterObject.get('type') == 'date-list') {
          if (this.get('filterSubType') == 'is' && !this.get('filterSubValue')) {
            return this.get('toast').error("".concat(selectedFilterObject.get('field'), " can't be blank"), 'Error');
          }

          if (this.get('filterSubType') != 'is' && !this.get('date')) {
            return this.get('toast').error("".concat(selectedFilterObject.get('field'), " can't be blank"), 'Error');
          }
        }

        if (selectedFilterObject.get('type') == 'checkbox') {
          if (_npmLodash.default.isNil(_npmLodash.default.find(_npmLodash.default.map(selectedFilterObject.get('list'), function (elem) {
            return Ember.get(elem, 'value');
          }), function (elem) {
            return elem === true;
          }))) {
            return this.get('toast').error("".concat(selectedFilterObject.get('field'), " can't be blank"), 'Error');
          }
        }

        if (selectedFilterObject.get('type') == 'range') {
          if (_npmLodash.default.isNil(this.get('filterSubValue')) || !this.get('filterSubValue.length') || this.get('filterSubType') == 'is-between' && _npmLodash.default.isNil(this.get('filterSubValue1') || !this.get('filterSubValue1.length'))) {
            if (this.get('selectedFilter.field')) {
              return this.get('toast').error("".concat(this.get('selectedFilter.field'), " can't be blank"));
            }

            return this.get('toast').error("Field can't be blank");
          }

          if (isNaN(this.get('filterSubValue')) || this.get('filterSubValue1') && isNaN(this.get('filterSubValue1'))) {
            if (this.get('selectedFilter.field')) {
              return this.get('toast').error("".concat(this.get('selectedFilter.field'), " can't be text"));
            }

            return this.get('toast').error("Field can't be text");
          }
        }

        this.findOrCreate(selectedFilterObject).then(function (createdFilter) {
          return _this2.updateFilterObject(createdFilter, selectedFilterObject);
        }).then(function (updatedFilter) {
          return updatedFilter.save();
        }).then(function (savedFilter) {
          return _this2.sendAction('applyFilter', savedFilter);
        }).then(function () {
          if (selectedFilterObject.get('objectType') == 'as') {
            _this2.set('checked', false);
          } else {
            _this2.send('cleanProperties', selectedFilterObject);
          }
        }).catch(function () {
          return _this2.get('toast').error('Something went wrong', 'Please try after some time');
        });
      }
    }
  });

  _exports.default = _default;
});