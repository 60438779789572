define("recruit/data/filters/p-c", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 5872,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "First Name",
      "objectType": "pc",
      "queryTemplate": "({{condition}} candidate.first_name{{exact}}{{value}})"
    }
  }, {
    "id": 5873,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Last Name",
      "objectType": "pc",
      "queryTemplate": "({{condition}} candidate.last_name{{exact}}{{value}})"
    }
  }, {
    "id": 5874,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Email",
      "objectType": "pc",
      "queryTemplate": "({{condition}} candidate.email{{exact}}{{value}})"
    }
  }, {
    "id": 58748765456,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Candidate # (id)",
      "objectType": "pc",
      "queryTemplate": "({{condition}} candidate.external_id{{exact}}{{value}})"
    }
  }, {
    "id": 5863,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Evaluation end date",
      "isRemote": false,
      "objectType": "pc",
      "queryTemplate": "(evaluation_end_date:[{{start}} TO {{end}}])"
    }
  }, {
    "id": 5858,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Phone",
      "objectType": "pc",
      "queryTemplate": "({{condition}} candidate.phone{{exact}}{{value}})"
    }
  }, {
    "id": 5859,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Assessment Name",
      "model": "assessment",
      "isRemote": true,
      "objectType": "pc",
      "queryTemplate": "(assessment.id:{{value}})"
    }
  }, {
    "id": 5860,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Recruiter name",
      "model": "user",
      "isRemote": true,
      "modelQuery": {
        "roles": "Recruiter"
      },
      "objectType": "pc",
      "queryTemplate": "(assessment.owned_by:{{value}})"
    }
  }, {
    "id": 5861,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Assigned date",
      "isRemote": false,
      "objectType": "pc",
      "queryTemplate": "(created_at:[{{start}} TO {{end}}])"
    }
  }, {
    "id": 5862,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Pending date",
      "isRemote": false,
      "objectType": "pc",
      "queryTemplate": "(pending_since:[{{start}} TO {{end}}])"
    }
  }, {
    "id": 5865,
    "type": "filter/master",
    "attributes": {
      "type": "date",
      "field": "Completed date",
      "isRemote": false,
      "objectType": "pc",
      "queryTemplate": "(assessmentSectionCandidate.completed_at:[{{start}} TO {{end}}])"
    }
  }, {
    "id": 5868,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Section type",
      "model": "section-type",
      "isRemote": false,
      "objectType": "pc",
      "queryTemplate": "(section.section_type_id:{{value}})"
    }
  }, {
    "id": 5871,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Verification Code (id)",
      "isRemote": false,
      "objectType": "pc",
      "queryTemplate": "({{condition}} assessmentSectionCandidate.verification_code{{exact}}{{value}})"
    }
  }];
  _exports.default = _default;
});