define("recruit/components/model-table/recruiter/assessment-row-component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    tagName: 'tr',
    performance: Ember.inject.service(),
    classNameBindings: ['assessment.isMarkedForScroll:isMarkedForScroll'],
    perfObserver: Ember.observer('assessment.isRelationsLoaded', function () {
      var _this = this;

      Ember.run.once('afterRender', function () {
        var rows = _this.get('assessments');

        if (rows.get('length') <= 25 && rows.get('lastObject.id') === _this.get('assessment.id') && window.performance) {
          _this.get('performance').end('Recuiter:Assessments:list');

          _this.sendAction('onFirstPageRender');
        }
      });
    }).on('init'),
    click: function click() {
      this.get('router').transitionTo('recruiter.assessment.details.overview', this.get('assessment.id'));
    }
  });

  _exports.default = _default;
});