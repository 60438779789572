define("recruit/routes/panel/event/details/response", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    queryParams: {
      status: {
        refreshModel: true
      },
      panel_id: {
        refreshModel: true
      },
      event_user_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.get('store').findRecord('event-user', +params.event_user_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', model);
      controller.set('organization', this.get('currentUser.me.organization'));

      if (this.controller.get('panel_id')) {
        controller.set('panel', this.get('store').findRecord('panel', +this.controller.get('panel_id')));
      }

      if (this.controller.get('status') === "decline" && controller.get('model.status') != 3) {
        this.setPanelResponse(this.controller.get('status'), "Invitation declined successfully!");
      } else if (this.controller.get('status') === "request-decline" && controller.get('model.status') != 3) {
        this.setPanelResponse(this.controller.get('status'), "Invitation decline request submitted successfully!");
      }
    },
    setPanelResponse: function setPanelResponse(response, toast) {
      var _this = this;

      if (response == "request-decline") {
        this.controller.set('model.panel', null);
      }

      this.controller.set('model.status', 3);
      this.controller.get('model').save().then(function () {
        _this.get('toast').success(toast);
      }).catch(function (error) {
        if (error.errors) {
          error.errors.forEach(function (e) {
            _this.get('toast').error(e.detail, 'Error');
          });
        } else {
          _this.get('toast').error('Please try after some time', 'Something went wrong');
        }
      });
    },
    actions: {
      askDecline: function askDecline() {
        this.controller.set('status', "request-decline");
        this.setPanelResponse(this.controller.get('status'), "Invitation decline request submitted successfully!");
      }
    }
  });

  _exports.default = _default;
});