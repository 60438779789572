define("recruit/components/filters/master-filter", ["exports", "recruit/config/environment", "npm:lodash"], function (_exports, _environment, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('masterFilters')) {
        this.get('store').query('filter/master', {
          object_type: this.get('objectType'),
          organization_id: this.get('currentUser.me.organization.id')
        }).then(function (filters) {
          _this.get('masterFilters').pushObjects(filters.toArray());

          _this.set('masterFilters', _this.get('masterFilters').uniqBy('field'));
        });
      }
    },
    customColumns: Ember.computed(function () {
      return this.get('masterColumns').filter(function (column) {
        return column.get('isDefault') === false;
      });
    }),
    didRender: function didRender() {
      Ember.run.next(function () {
        Ember.$('[data-content]').popup({
          position: 'bottom center',
          inline: false,
          variation: 'inverted'
        });
      });
    },
    createColumnRecord: function createColumnRecord(column) {
      var _this2 = this;

      var appliedColumns = this.get('store').peekAll('column/applied').map(function (column) {
        return column.get('id');
      });

      if (column && !_npmLodash.default.includes(appliedColumns, column.get('id'))) {
        this.get('store').createRecord('column/applied', {
          id: column.get('id'),
          isSelected: column.get('isColumnSelected'),
          appliedColumn: column,
          tableType: this.get('tableType')
        }).save().then(function () {
          _this2.get('columns').pushObject(column.toJSON());

          _this2.set('columns', _this2.get('columns').sortBy('sortWeight'));
        });
      }
    },
    actions: {
      toggleQuickFilter: function toggleQuickFilter(property, checked) {
        if (checked) {
          this.set("quickFilters.".concat(property), checked);
        } else {
          this.set("quickFilters.".concat(property), null);
        }

        this.sendAction('onFilterChange', this.get('quickFilters'));
      },
      toggleFilterFlags: function toggleFilterFlags(value, checked) {
        if (checked) {
          this.set(value, true);
        } else {
          this.set(value, null);
        }
      },
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
      },
      applyFilter: function applyFilter(filter) {
        // https://github.com/emberjs/data/issues/3530
        this.get('appliedFilters').pushObject(filter);
      },
      removeAppliedFilter: function removeAppliedFilter(filter) {
        var _this3 = this;

        filter.destroyRecord().then(function () {
          return _this3.get('appliedFilters').removeObject(filter);
        });
      },
      clearFilters: function clearFilters() {
        var _this4 = this;

        return this.get('store').query('filter/applied', {
          'objectType': this.get('objectType')
        }).then(function (records) {
          return records.map(function (filter) {
            return filter.destroyRecord();
          });
        }).then(function () {
          _this4.set('appliedFilters', Ember.A([]));

          _this4.set('filters', null);
        });
      },
      setQuickFilter: function setQuickFilter(status) {
        this.sendAction('setQuickFilter', status);
      },
      saveFilters: function saveFilters() {
        this.sendAction('saveFilters', this.get('model'), this.get('appliedFilters'));
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('openColumnModal');
      },
      addColumn: function addColumn() {
        var selectedColumn = this.get('customColumns').find(function (a) {
          return a.get('label') == 'Proview Index';
        });
        selectedColumn.set('isColumnSelected', true);
        this.createColumnRecord(selectedColumn);
      },
      resetColumns: function resetColumns() {
        var _this5 = this;

        this.get('masterColumns').forEach(function (column) {
          if (column.get('hasDirtyAttributes')) {
            var modifedColumn = _this5.get('store').peekRecord('column/applied', column.get('id'));

            if (modifedColumn && !modifedColumn.get('isDeleted')) {
              modifedColumn.destroyRecord().then(function (deletedColumn) {
                modifedColumn.unloadRecord();
                var deletedJson = deletedColumn.get('appliedColumn.content').toJSON();

                _this5.get('columns').removeObject(_this5.get('columns').findBy('label', deletedJson.label));
              });
            } else {
              _this5.createColumnRecord(column);
            }

            column.set('hasDirtyAttributes', false);
            column.rollbackAttributes();
          }
        });
        this.toggleProperty('openColumnModal');
      },
      updateColumn: function updateColumn(column, checked) {
        column.set('isColumnSelected', checked);
        column.toggleProperty('hasDirtyAttributes');
      }
    }
  });

  _exports.default = _default;
});