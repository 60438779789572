define("recruit/components/file-uploader", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader", "npm:lodash", "recruit/config/environment"], function (_exports, _fileField, _uploader, _npmLodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    supportedExtensions: 'doc docx odt pdf rtf txt ps',
    is_public: 0,
    supportedAudioFiles: ['mp3', 'wma', 'wav'],
    supportedImageFiles: ['jpeg', 'jpg', 'png', 'svg'],
    checkMultiMediaType: function checkMultiMediaType(extension) {
      if (!_npmLodash.default.includes(this.get('supportedAudioFiles'), extension)) {
        this.set('uploadedFileType', "Audio");
      } else if (!_npmLodash.default.includes(this.get('supportedImageFiles'), extension)) {
        this.set('uploadedFileType', "Image");
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('target')) {
          Ember.$(this.get('target')).click(_npmLodash.default.bind(function () {
            this.$().click();
          }, this));
        }

        if (this.get('openSelector')) {
          this.$().click();
          this.toggleProperty('openSelector');
        }
      });
    },
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var extension = files[0].name.split('.').pop();

      if (this.get('multiMediaUpload')) {
        this.checkMultiMediaType(extension);
      }

      if (!_npmLodash.default.includes(this.get('supportedExtensions').split(' '), _npmLodash.default.toLower(extension))) {
        if (this.get('uploadedFileType')) {
          this.get('toast').error("Try uploading an ".concat(this.get('uploadedFileType'), " file."), 'File type not supported!');
        } else {
          this.get('toast').error('File type not supported.', 'Error!');
        }

        return false;
      }

      this.set('fileUploding', true);

      var uploader = _uploader.default.create({
        url: _environment.default.APP.API_ROOT + '/models/' + this.get('modelName'),
        ajaxSettings: {
          headers: {
            Authorization: "Bearer ".concat(this.get('session.session.authenticated.access_token'))
          }
        }
      });

      this.set('saveDisabled', true);
      this.set('file', files[0]);
      uploader.upload(files[0], {
        file_type_id: this.get('file_type_id'),
        is_public: this.get('is_public')
      });
      uploader.on('didError', function () {
        _this.set('fileUploding', false);

        _this.get('toast').error('Something went wrong', 'Please try after some time.');

        _this.set('saveDisabled', false);

        _this.set('uploadedFile', null);

        _this.set('file', null);
      });
      uploader.on('progress', function (e) {
        _this.set('fileUploadProgress', e.percent);
      });
      uploader.on('didUpload', function (uploadedFile) {
        _this.set('uploadedFile', uploadedFile);

        _this.set('saveDisabled', false);

        if (_this.get('postUpload')) {
          _this.sendAction('postUpload', uploadedFile);
        } else {
          _this.set('fileUploding', false);

          _this.get('toast').success('File uploaded', 'Successful');
        }
      }); // Rest selected file

      this.$().val(null);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('target')) {
        Ember.$(this.get('target')).off('click');
      }
    }
  });

  _exports.default = _default;
});