define("recruit/templates/components/video-loading-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KJA9u9K9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui centered center aligned grid\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"sixteen wide centered column\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"style\",\"width:90%; height:100%; border:1px solid #eeeeee; margin: 0 auto;\"],[9,\"class\",\"padding-sm margin-top-thirty animated-background\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"massive notched circle loading icon\"],[9,\"style\",\"margin:15% auto; display:block; color:#e0e0e0;\"],[7],[8],[0,\"\\n\"],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/video-loading-placeholder.hbs"
    }
  });

  _exports.default = _default;
});