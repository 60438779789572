define("recruit/templates/components/panel/set-availability/calendar-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hiM9+o7f",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ui form\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"fields\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide field\"],[7],[0,\"\\n      \"],[6,\"label\"],[7],[0,\"Date\"],[8],[0,\"\\n      \"],[1,[25,\"moment-format\",[[20,[\"model\",\"date\"]],\"DD MMM, YYYY(ddd)\"],[[\"timeZone\"],[[20,[\"timeZone\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"fields\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"eight wide field\"],[7],[0,\"\\n      \"],[6,\"label\"],[7],[0,\"From\"],[8],[0,\"\\n      \"],[1,[25,\"ui-calendar\",null,[[\"type\",\"date\",\"onChange\",\"placeholder\",\"disableMinute\"],[\"time\",[20,[\"model\",\"from\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"model\",\"from\"]]],null]],null],\"Start time\",true]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"eight wide field\"],[7],[0,\"\\n      \"],[6,\"label\"],[7],[0,\"To\"],[8],[0,\"\\n      \"],[1,[25,\"ui-calendar\",null,[[\"type\",\"date\",\"onChange\",\"placeholder\",\"disableMinute\"],[\"time\",[20,[\"model\",\"to\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"model\",\"to\"]]],null]],null],\"End time\",true]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"text-center margin-top-thirty\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui button\"],[3,\"action\",[[19,0,[]],\"revertModel\",[20,[\"occurrence\",\"content\"]]],[[\"bubbles\"],[false]]],[7],[0,\"Cancel\"],[8],[0,\"\\n    \"],[6,\"button\"],[10,\"class\",[26,[\"ui primary button \",[25,\"if\",[[20,[\"isSaveDisabled\"]],\"loading\"],null]]]],[3,\"action\",[[19,0,[]],\"saveModel\",[20,[\"model\"]],[20,[\"occurrence\",\"content\"]],[20,[\"timeZone\"]]],[[\"bubbles\"],[false]]],[7],[0,\"Save\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/set-availability/calendar-card.hbs"
    }
  });

  _exports.default = _default;
});