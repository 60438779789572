define("recruit/templates/components/panel/events/decline-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zXs2OylC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"eight wide column\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui centered grid\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column\"],[9,\"style\",\"font-size: 80px;\"],[7],[0,\"\\n      \"],[1,[25,\"fa-icon\",[\"ban\"],[[\"class\"],[\"text-red\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-red text-weight-medium text-size-sixteen padding-no-bottom\"],[7],[0,\"\\n      Declined\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"twelve wide middle aligned column text-black text-size-fourteen\"],[7],[0,\"\\n      You have declined to join this event. You may close this window now.\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/events/decline-form.hbs"
    }
  });

  _exports.default = _default;
});