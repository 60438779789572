define("recruit/templates/components/events/event-candidate-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4G/7NOa3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isRelationLoaded\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"text-size-normal text-black margin-no padding-vs-bottom text-weight-medium\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"data-tooltip\",\"Number of candidates in each state of the assessment at present\"],[9,\"data-position\",\"bottom center\"],[7],[0,\"Current Candidate Status\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[1,[25,\"high-chart-view\",null,[[\"columnHeight\",\"headerFormat\",\"chartText\",\"model\",\"bindGraphActions\",\"noCountsLoaded\",\"isEventOverview\",\"bulkImportCandidates\"],[\"180\",\"<span style=\\\"font-size:13px\\\">{series.name}</span><br>\",\"\",[20,[\"expandedEventModel\"]],false,[20,[\"noCountsLoaded\"]],true,[25,\"action\",[[19,0,[]],\"bulkImportCandidates\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui active inverted dimmer\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui text loader\"],[7],[6,\"i\"],[7],[0,\"Loading graph...\"],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/event-candidate-graph.hbs"
    }
  });

  _exports.default = _default;
});