define("recruit/templates/components/reports/helpers/column-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WUNfFAaV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"value\",\"length\"]]],null,{\"statements\":[[1,[20,[\"value\",\"firstObject\"]],false],[0,\" (\"],[1,[20,[\"value\",\"lastObject\"]],false],[0,\"%)\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[18,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/column-cell-component.hbs"
    }
  });

  _exports.default = _default;
});