define("recruit/models/section-question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "question_order": _emberData.default.attr('number'),
    "created_at": _emberData.default.attr('pgdate'),
    "created_by": _emberData.default.attr('number'),
    "updated_at": _emberData.default.attr('pgdate'),
    "updated_by": _emberData.default.attr('number'),
    'section': _emberData.default.belongsTo('section'),
    'question': _emberData.default.belongsTo('question')
  });

  _exports.default = _default;
});