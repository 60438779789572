define("recruit/components/events/event-list", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isDevelopment: _environment.default.isDevelopment,
    actions: {
      clearSearchText: function clearSearchText() {
        this.set('searchText', null);
      },
      toggleModal: function toggleModal(property, url, response) {
        var _this = this;

        this.toggleProperty(property);
        Ember.run.next(function () {
          if (url) {
            _this.get('router').transitionTo(url, response);
          }
        });
      }
    }
  });

  _exports.default = _default;
});