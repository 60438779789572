define("recruit/components/recruiter-view/assessment/helpers/recommendation-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    viewRecommendation: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('value.length')) {
        this.get('value').forEach(function (pc) {
          pc.get('evaluations').then(function (res) {
            if (res.get('length')) {
              res.get('recommendation');

              _this.set('viewRecommendation', true);
            }
          });
        });
      }
    },
    findStatus: function findStatus(panelCandidate) {
      if (panelCandidate.get('evaluationStatus')) {
        return panelCandidate.get('evaluationStatus');
      } else {
        return '--';
      }
    },
    evaluationStatus: Ember.computed('row', 'viewRecommendation', 'value.@each.evaluations.isFulfilled', 'pc', function () {
      if (this.get('pc')) {
        this.set('panelCandidate', this.get('value').findBy('id', this.get('pc.id')));
      } else if (this.get('row.currentPanelId')) {
        this.set('panelCandidate', this.get('value').findBy('panel.id', this.get('row.currentPanelId')));
      }

      return this.get('panelCandidate') ? this.findStatus(this.get('panelCandidate')) : '--';
    })
  });

  _exports.default = _default;
});