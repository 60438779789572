define("recruit/templates/components/panel/evaluate/count-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vzPKiqqu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[3,\"action\",[[19,0,[]],\"changeType\",[20,[\"type\"]]]],[7],[0,\"\\n  \"],[6,\"span\"],[10,\"class\",[26,[\"text-size-small \",[25,\"unless\",[[20,[\"highlight\"]],\"text-grey\",\"text-blue\"],null]]]],[7],[1,[18,\"type\"],false],[0,\"\\n    \"],[6,\"br\"],[7],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"style\",\"line-height:1.4em;\"],[7],[4,\"if\",[[20,[\"count\"]]],null,{\"statements\":[[0,\" (\"],[1,[18,\"count\"],false],[0,\") \"]],\"parameters\":[]},null],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/evaluate/count-tab.hbs"
    }
  });

  _exports.default = _default;
});