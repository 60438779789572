define("recruit/components/panel/set-availability/calendar-occurrence", ["exports", "ember-calendar/components/as-calendar/timetable/occurrence", "moment"], function (_exports, _occurrence, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _occurrence.default.extend({
    showPopUp: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        // this.$().on('dblclick', () => {
        //   this.set('showPopUp', true);
        // });
        if (_this.get('model.content.isFresh')) {
          _this.set('showPopUp', true);
        }
      });
    },
    actions: {
      saveModel: function saveModel(model, occurrence, timeZone) {
        var _this2 = this;

        if ((0, _moment.default)(model.get('from')).isBefore((0, _moment.default)(model.get('to')))) {
          var isNewRecord = model.get('isFresh');
          model.save().then(function (savedAvailability) {
            if (isNewRecord) {
              _this2.get('toast').success("From: ".concat((0, _moment.default)(savedAvailability.get('from')).tz(timeZone).format('hh:mm A'), " <br>To: ").concat((0, _moment.default)(savedAvailability.get('to')).tz(timeZone).format('hh:mm A')), "Availability added: ".concat((0, _moment.default)(savedAvailability.get('from')).format('MMM DD, YYYY')));
            } else {
              _this2.get('toast').success("From: ".concat((0, _moment.default)(savedAvailability.get('from')).tz(timeZone).format('hh:mm A'), " <br>To: ").concat((0, _moment.default)(savedAvailability.get('to')).tz(timeZone).format('hh:mm A')), "Availability updated: ".concat((0, _moment.default)(savedAvailability.get('from')).format('MMM DD, YYYY')));
            }

            _this2.set('showPopUp', false);

            return _this2.sendAction('updateOccurrence', model, occurrence);
          });
        } else {
          this.get('toast').error('Start time should be before end time', 'Invalid time');
        }
      },
      revertModel: function revertModel(model) {
        this.set('showPopUp', false);
        this.sendAction('revertModel', model);
      }
    }
  });

  _exports.default = _default;
});