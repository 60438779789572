define("recruit/routes/recruiter/assessment/details/import", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/validations/candidate"], function (_exports, _authenticatedRouteMixin, _candidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', model);
      controller.set('file', null);
      controller.set('isBulkUpload', true);
      controller.set('isAssessmentFrozen', true);
      controller.set('candidateModel', this.get('store').createRecord('candidate'));
      controller.set('CandidateValidations', _candidate.default);
    },
    actions: {
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});