define("recruit/components/events/members-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      invite: function invite() {
        this.sendAction('invite');
      },
      toggleModal: function toggleModal(property, item) {
        //item is an object {key: [property stored current selection], value}
        if (item) {
          this.set(item.key, item.value);
        }

        this.toggleProperty(property);
        return false;
      },
      remind: function remind(user) {
        this.sendAction('remind', '', user);
      },
      remove: function remove(item) {
        this.sendAction('remove', item);
      }
    }
  });

  _exports.default = _default;
});