define("recruit/routes/recruiter/candidate/details", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.get('store').findRecord('candidate', params.id).then(function (candidate) {
        return Ember.RSVP.hash({
          candidate: candidate,
          assessmentCandidates: candidate.get('assessmentCandidates')
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('candidate', model.candidate);
      controller.set('assessmentCandidates', model.assessmentCandidates);
      this.addObserver('router.currentRouteName', function () {
        if (this.get('router.currentRouteName') == 'recruiter.candidate.details.profile') {
          this.controller.set('selectedAssessmentId', null);
          this.controller.set('isProfile', true);
        } else {
          this.controller.set('isProfile', false);
        }

        if (this.get('router.currentRouteName') == 'recruiter.candidate.details.assessments') {
          this.controller.set('selectedAssessmentId', null);
        }

        if (this.get('router.currentRouteName') == 'recruiter.candidate.details.assessment-section.evaluate') {
          this.controller.set('isEvaluate', true);
        } else {
          this.controller.set('isEvaluate', false);
        }
      });
    },
    actions: {
      takeToAuthentication: function takeToAuthentication(candidate) {
        var _this = this;

        Ember.RSVP.resolve().then(function () {
          return localStorage.setItem('tv_c_authToken', candidate.get('authToken.access_token'));
        }).then(function () {
          return _this.get('router').transitionTo('recruiter.candidate.details.authentication', _this.controller.get('candidate.id'));
        });
      }
    }
  });

  _exports.default = _default;
});