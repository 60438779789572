define("recruit/components/recruiter-view/assessment/assessment-overview", ["exports", "recruit/config/environment", "moment", "npm:lodash", "recruit/mixins/g-actions"], function (_exports, _environment, _moment, _npmLodash, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    isDevelopment: _environment.default.isDevelopment,
    currentUser: Ember.inject.service(),
    userRole: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$('.ui.floating.dropdown').dropdown();
      this.set('selectedCategories', Ember.A([]));
      this.set('preSelectedCategories', Ember.A([]));
      this.set('presentMenuCards', Ember.A([]));
      this.cardsObserver();
      this.get('model.publicSection').then(function (as) {
        if (!as.length) {
          _this.set('noPublicSection', true);
        }
      });
    },
    paddingClass: Ember.computed('model.firstAssessmentSection.is_public', function () {
      return this.get('model.firstAssessmentSection.is_public') ? "padding-vs-bottom" : "padding-sm-bottom";
    }),
    hasLiveEvents: Ember.computed('model.events', function () {
      return this.get('model.events').filterBy('status', 4);
    }),
    cardsObserver: function () {
      var _this2 = this;

      var cards = Ember.A([]);

      if (this.get('model.presentMenuCards.length')) {
        this.get('model.presentMenuCards').forEach(function (card) {
          var allCards = _this2.get('getMenuCard').findBy('id', card.get('menu_card_id'));

          cards.pushObject(allCards);
        });
      }

      this.set('presentMenuCards', _npmLodash.default.uniq(cards));
      this.set('selectedCategories', _npmLodash.default.uniq(cards));
    }.observes('model.presentMenuCards.[]'),
    minDate: (0, _moment.default)().toDate(),
    getMenuCard: Ember.computed(function () {
      return this.get('store').findAll('menu-card');
    }),
    actions: {
      catchErrors: function catchErrors(error) {
        var _this3 = this;

        if (error.errors) {
          error.errors.forEach(function (e) {
            _this3.get('toast').error(e.detail, 'Error');
          });
        } else {
          this.get('toast').error('Please try after some time', 'Something went wrong');
        }
      },
      toggleShow: function toggleShow(property1, property2) {
        var _this4 = this;

        if (!_npmLodash.default.isNil(property1)) {
          this.toggleProperty(property1);
        }

        if (!_npmLodash.default.isNil(property1)) {
          Ember.run.later(function () {
            _this4.toggleProperty(property2);
          }, 500);
        }
      },
      copyLink: function copyLink(text) {
        this.get('toast').success(text.text, 'Copied to clipboard');
      },
      toggleModal: function toggleModal(property, showWindow) {
        var _this5 = this;

        if (showWindow) {
          this.set('hideAssessmentWindow', true);
        }

        if (property == "shareAssessment") {
          if (this.get('model.firstAssessmentSection.isFulfilled')) {
            this.toggleProperty(property);
          } else {
            this.get('model.firstAssessmentSection').then(function (response) {
              if (response.get('is_public')) {
                _this5.toggleProperty(property);
              }
            });
          }
        } else {
          this.toggleProperty(property);
        }
      },
      download: function download(QRCodeUrl) {
        var a = Ember.$("<a>").attr("href", QRCodeUrl).attr("target", "_blank").attr("download", "QR.png").appendTo("body");
        a[0].click();
      },
      selectCategories: function selectCategories(category) {
        this.get('selectedCategories').pushObject(category);

        if (!this.get('preSelectedCategories').findBy('id', category.get('id'))) {
          category.set('isDeleteable', true);
          this.get('preSelectedCategories').pushObject(category);
        }
      },
      removeCategory: function removeCategory(category) {
        this.get('selectedCategories').removeObject(category);

        if (category.get('isDeleteable')) {
          this.get('preSelectedCategories').removeObject(category);
        }

        category.rollbackAttributes();
      },
      unpublishAssessment: function unpublishAssessment(property) {
        var _this6 = this;

        this.get('store').query('menu-card-assessment', {
          assessment_id: this.get('model.id')
        }).then(function (response) {
          if (!_npmLodash.default.isNil(response) && response.length) {
            response.forEach(function (res) {
              res.destroyRecord();
            });
          }
        });
        this.set('model.firstAssessmentSection.is_public', false);
        this.get('model.firstAssessmentSection.content').save().then(function () {
          _this6.set('model.firstAssessmentSection', null);

          _this6.get('toast').success("Assessment unpublished");

          _this6.set('model.start_date', null);

          _this6.set('model.end_date', null);

          _this6.get('model').save().then(function () {
            _this6.get('toast').success("Assessment Window updated Successfully!");
          }).catch(function (error) {
            _this6.send('catchErrors', error);
          });
        }).catch(function (error) {
          _this6.send('catchErrors', error);
        }).finally(function () {
          _this6.send('toggleModal', property);

          _this6.set('selectedCategories', Ember.A([]));
        });
      },
      viewCandidates: function viewCandidates(model) {
        this.get('router').transitionTo('recruiter.assessment.details.candidate', model.id);
      },
      viewJobDescription: function viewJobDescription(model) {
        this.get('router').transitionTo('recruiter.assessment.details.job-description', model.id);
      },
      deleteAssessment: function deleteAssessment() {
        var _this7 = this;

        this.get('model').destroyRecord().then(function () {
          _this7.get('toast').success('Assessment deleted', 'Success');

          _this7.send('toggleModal', 'isDeleteModal');

          window.history.back();
        }).catch(function (e) {
          if (e.errors.length) {
            e.errors.forEach(function (error) {
              _this7.get('toast').error(error.detail);
            });
          } else {
            _this7.get('toast').error('Please try some time later', 'Something went wrong');
          }
        });
      }
    }
  });

  _exports.default = _default;
});