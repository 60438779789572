define("recruit/models/question-file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    file: _emberData.default.belongsTo('file'),
    question: _emberData.default.belongsTo('question')
  });

  _exports.default = _default;
});