define("recruit/components/graphs/activities-bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.drawChart();
    },
    drawChart: function drawChart() {
      Ember.$('#highcharts-container').highcharts({
        chart: {
          type: 'bar',
          backgroundColor: '#fff'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: ['Create Candidates', 'Invite to Assessment', 'Schedule Live Session', 'Reschedule Live Session', 'Evaluations', 'Change Invite Status'],
          title: {
            text: ''
          },
          labels: {
            useHTML: true,
            style: {
              fontSize: '1em',
              padding: '15px'
            }
          }
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          title: {
            useHTML: true,
            text: 'COUNTS' + '<span style="font-size:25px; margin-left:10px; position: relative; top:3px;">&#x279D;</span>',
            margin: 10,
            style: {
              fontSize: '1em'
            }
          }
        },
        tooltip: {
          backgroundColor: '#fff',
          pointFormat: '<b style="color:{point.color}; font-size:15px;">{point.name}</b>: <br><b>{point.y} </b><b style="font-size:15px;">{point.tooltip_text}</b>'
        },
        plotOptions: {
          series: {
            showInLegend: false,
            borderRadius: 0,
            useHTML: true
          },
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        "series": [{
          colorByPoint: true,
          pointWidth: 20,
          data: this.get('dataArray')
        }]
      });
    }
  });

  _exports.default = _default;
});