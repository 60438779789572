define("recruit/components/filters/assessment/date-filter", ["exports", "recruit/components/filters/advance-filters"], function (_exports, _advanceFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _advanceFilters.default.extend({
    className: {
      calendar: 'calendar tiny'
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});