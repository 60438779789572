define("recruit/components/panel/set-availability/calendar-card", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui popup bottom right transition visible calendar-card'],
    store: Ember.inject.service(),
    model: Ember.computed('occurrence', function () {
      return this.get('store').createRecord('user-calendar', {
        event: this.get('occurrence.content.title') || 'Available',
        date: this.get('occurrence.content.startsAt'),
        from: this.get('occurrence.content.startsAt'),
        to: this.get('occurrence.content.endsAt'),
        type: 2
      });
    }),
    timeZonedFrom: Ember.computed('occurrence.content.startsAt', function () {
      return (0, _moment.default)(this.get('occurrence.content.startsAt')).tz(this.get('timeZone'));
    }),
    timeZonedTo: Ember.computed('occurrence.content.endsAt', function () {
      return (0, _moment.default)(this.get('occurrence.content.endsAt')).tz(this.get('timeZone'));
    }),
    actions: {
      saveModel: function saveModel(model, occurrence, timeZone) {
        this.sendAction('saveModel', model, occurrence, timeZone);
      },
      revertModel: function revertModel(model) {
        this.sendAction('revertModel', model);
      }
    }
  });

  _exports.default = _default;
});