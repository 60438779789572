define("recruit/routes/recruiter/events/details/candidate", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    queryParams: {
      searchText: {
        replace: true
      },
      assessmentFilters: {
        replace: true
      }
    },
    // model() {
    //   return Ember.RSVP.hash({
    //     candidate   : this.get('store').createRecord('candidate'),
    //     fieldMaster : this.get('store').query('custom-field-master',{'type': 2}),
    //   });
    // },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', model);
    }
  });

  _exports.default = _default;
});