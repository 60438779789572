define("recruit/models/form-instance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    assessmentSection: _emberData.default.belongsTo('assessmentSection', {
      inverse: null
    }),
    assessmentCandidate: _emberData.default.belongsTo('assessmentCandidate', {
      inverse: null
    }),
    assessmentSectionCandidate: _emberData.default.belongsTo('assessmentSectionCandidate', {
      inverse: null
    }),
    evaluation: _emberData.default.belongsTo('evaluation', {
      inverse: null
    }),
    form_id: _emberData.default.attr('number'),
    formData: _emberData.default.attr(),
    file: _emberData.default.hasMany('file')
  });

  _exports.default = _default;
});