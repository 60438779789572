define("recruit/components/recruiter-view/assessment/publish-assessment", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.initialize();
    },
    initialize: function initialize() {
      this.setProperties({
        'showEndDatePicker': false,
        'showStartDatePicker': false,
        'menuCardsUnchanged': false,
        'newStartDate': null,
        'newEndDate': null
      });
    },
    menuCardsObserver: Ember.observer('selectedCategories.[]', 'presentMenuCards.[]', function () {
      var _this = this;

      var newCards = Ember.A([]);
      var noCards = Ember.A([]);

      if (this.get('selectedCategories.length') != this.get('presentMenuCards.length')) {
        this.set('menuCardsUnchanged', true);
      } else {
        this.get('selectedCategories').forEach(function (card) {
          if (card && !_this.get('presentMenuCards').findBy('id', card.get('id'))) {
            newCards.pushObject(card.id);
          }
        });
        this.get('presentMenuCards').forEach(function (card) {
          if (card && !_this.get('selectedCategories').findBy('id', card.get('id'))) {
            noCards.pushObject(card.id);
          }
        });

        if (newCards != noCards) {
          if (newCards.length != 0 && noCards.length != 0) {
            this.set('menuCardsUnchanged', true);
          } else {
            this.set('menuCardsUnchanged', false);
          }
        }
      }
    }),
    becomeFocused: Ember.on('didRender', function () {
      this.$('input[type=text]').focus();
    }),
    currentDate: Ember.computed('model.start_date', function () {
      return this.get('model.start_date') ? this.get('model.start_date') : (0, _moment.default)().subtract(1, 'minutes'); //The attend link takes time to become public, there's a backend validation for start time (it will accept start time only upto 5 minutes less than the current time), hence reducing the start time by 1 minute.
    }),
    curentEndDate: Ember.computed('model.end_date', 'model.start_date', function () {
      return this.get('model.end_date') ? this.get('model.end_date') : this.get('model.start_date') ? (0, _moment.default)(this.get('model.start_date')).add(10, 'days') : (0, _moment.default)().add(10, 'days');
    }),
    changeAssessmentWindow: function changeAssessmentWindow() {
      var _this2 = this;

      var assessmentStart = this.get('newStartDate') ? this.get('newStartDate') : this.get('model.start_date');
      var assessmentEnd = this.get('newEndDate') ? this.get('newEndDate') : this.get('model.end_date');

      if (this.get('model.start_date') == assessmentStart || this.get('model.end_date') == assessmentEnd) {
        this.set('model.start_date', assessmentStart);
        this.set('model.end_date', assessmentEnd);
        this.get('model').save().then(function () {
          _this2.get('toast').success("Assessment Window updated Successfully!");
        }).catch(function (error) {
          _this2.send('catchErrors', error);
        });
      }
    },
    actions: {
      catchErrors: function catchErrors(error) {
        this.sendAction('catchErrors', error);
      },
      selectCategories: function selectCategories(category) {
        this.sendAction('selectCategories', category);
      },
      showDatePicker: function showDatePicker(property) {
        this.toggleProperty(property);
      },
      removeCategory: function removeCategory(category, index) {
        this.sendAction('removeCategory', category, index);
      },
      publish: function publish() {
        var _this3 = this;

        var cardIds = Ember.A([]);
        var removeCards = Ember.A([]);
        !this.get('model.start_date') ? this.set('model.start_date', this.get('currentDate')) : this.get('model.start_date');
        !this.get('model.end_date') ? this.set('model.end_date', this.get('curentEndDate')) : this.get('model.end_date');
        this.changeAssessmentWindow(); //find ids to add

        this.get('selectedCategories').forEach(function (card) {
          if (!_this3.get('presentMenuCards').findBy('id', card.get('id'))) {
            cardIds.pushObject(card.id);
          }
        }); // find ids to remove

        this.get('presentMenuCards').forEach(function (card) {
          if (!_this3.get('selectedCategories').findBy('id', card.get('id'))) {
            removeCards.pushObject(card.id);
          }
        }); // add assessment to menu card

        if (cardIds.length) {
          cardIds.forEach(function (card) {
            _this3.get('store').createRecord('menu-card-assessment', {
              menu_card_id: card,
              assessment_id: _this3.get('model.id')
            }).save().then(function () {
              _this3.get('toast').success("Assessment Published to Menu Card(s)");
            }).catch(function (error) {
              _this3.send('catchErrors', error);
            });
          });
        } // remove assessment from menu card


        if (removeCards.length) {
          removeCards.forEach(function (card) {
            _this3.get('store').query('menu-card-assessment', {
              assessment_id: _this3.get('model.id'),
              v: (0, _moment.default)().unix()
            }).then(function (response) {
              if (response.get('length')) {
                response.forEach(function (res) {
                  if (res.get('menu_card_id') === card) {
                    res.destroyRecord();
                  }
                });
              }
            });
          });
        }

        this.send('toggleModal', 'publishAssessment');
      },
      publishSettings: function publishSettings() {
        var _this4 = this;

        this.set('isLoading', true);

        if (this.get('newStartDate') || this.get('newEndDate')) {
          this.changeAssessmentWindow();
        }

        this.send('publish');
        this.set('model.publicSection.firstObject.is_public', true);
        this.get('model.publicSection.firstObject').save().then(function (as) {
          _this4.set('model.firstAssessmentSection', as);

          _this4.get('toast').success("Assessment Published");

          Ember.run.later(function () {
            _this4.send('toggleModal', 'shareAssessment', true);
          }, 500);
        }).catch(function (error) {
          _this4.send('catchErrors', error);
        }).finally(function () {
          _this4.set('isLoading', false);
        });
        this.initialize();
      },
      confirmSettings: function confirmSettings() {
        if (this.get('selectedCategories.length') < 1) {
          this.send('unpublishAssessment', 'publishAssessment');
        } else {
          if (this.get('menuCardsUnchanged')) {
            this.send('publishSettings');
          } else {
            if (this.get('newStartDate') || this.get('newEndDate')) {
              this.changeAssessmentWindow();
            }

            this.send('toggleModal', 'publishAssessment');
          }
        }
      },
      unpublishAssessment: function unpublishAssessment(property) {
        this.sendAction('unpublishAssessment', property);
      },
      unpublish: function unpublish(property1, property2) {
        this.sendAction('unpublish', property1, property2);
      },
      onHideStartDate: function onHideStartDate() {
        if (this.get('newStartDate')) {
          this.get('model.start_date') ? this.set('model.start_date', this.get('newStartDate')) : this.set('currentDate', this.get('newStartDate'));
        }

        this.set('showStartDatePicker', false);
      },
      onHideEndDate: function onHideEndDate() {
        if (this.get('newEndDate')) {
          this.get('model.end_date') ? this.set('model.end_date', this.get('newEndDate')) : this.set('curentEndDate', this.get('newEndDate'));
        }

        this.set('showEndDatePicker', false);
      },
      toggleModal: function toggleModal(property, showWindow) {
        this.initialize();
        this.sendAction('toggleModal', property, showWindow);
      }
    }
  });

  _exports.default = _default;
});