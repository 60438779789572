define("recruit/templates/components/recruiter-view/actions/assessment-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BNIsV6aQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"action-button-position assessment\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui icon vertical buttons\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"currentUser\",\"organization\",\"hasAssessmentCreate\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"data-tooltip\",\"Create Assessment\"],[9,\"data-position\",\"left center\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"recruiter.assessment.create\"],[[\"class\",\"tagName\"],[\"ui large basic button\",\"button\"]],{\"statements\":[[0,\"          \"],[1,[25,\"fa-icon\",[\"plus\"],[[\"class\"],[\"text-blue\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"isDevelopment\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"data-tooltip\",\"Download Report\"],[9,\"data-position\",\"left center\"],[7],[0,\"\\n        \"],[6,\"a\"],[9,\"href\",\"#\"],[9,\"download\",\"#\"],[9,\"class\",\"ui large basic button\"],[7],[0,\"\\n          \"],[1,[25,\"fa-icon\",[\"download\"],[[\"class\"],[\"text-blue\"]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/actions/assessment-list-actions.hbs"
    }
  });

  _exports.default = _default;
});