define("recruit/components/events/helpers/create-event-candidate", ["exports", "recruit/validations/candidate"], function (_exports, _candidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_candidate.default, {
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.set('CandidateValidations', _candidate.default);
      this.set('file', null);
    },
    candidateModel: Ember.computed(function () {
      return this.get('store').createRecord('candidate');
    }),
    actions: {
      rerenderTable: function rerenderTable(property) {
        this.sendAction('rerenderTable', property);
      },
      bulkImportCandidates: function bulkImportCandidates(property) {
        this.sendAction('bulkImportCandidates', property);
      }
    }
  });

  _exports.default = _default;
});