define("recruit/transforms/seconds-minutes", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized ? _moment.default.duration(serialized, 'seconds').asMinutes() : null;
    },
    serialize: function serialize(deserialized) {
      return _moment.default.utc(deserialized * 60000).format('HH:mm:ss');
    }
  });

  _exports.default = _default;
});