define("recruit/routes/recruiter/pending-candidates", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/config/environment", "recruit/data/filters/r-c"], function (_exports, _authenticatedRouteMixin, _environment, _rC) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      isPending: {
        replace: true
      },
      searchText: {
        replace: true
      },
      filters: {
        replace: true
      }
    },
    performance: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('store').push({
        data: _rC.default
      });
      this.get('performance').start('Recruiter:Candidates:list');
    },
    model: function model() {
      // return this.get('store').query('candidate', { page: 1, pageSize: 25});
      return Ember.RSVP.hash({
        // appliedFilters: this.store.query('filter/applied', {objectType: 'c'}),
        masterFilters: this.store.peekAll('filter/master') //   columnMaster: this.store.query('column/master', {table_type: 'r-pc'}),
        //   columnApplied: this.store.findAll('column/applied'),

      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set('selectedRows', Ember.A([]));
      controller.set('appliedFilters', Ember.A([]));
      controller.set('quickFilters', Ember.Object.create({
        isPending: controller.get('isPending')
      }));
      controller.set('masterFilters', model.masterFilters.filterBy('objectType', 'c')); // controller.set('isDevelopment', ENV.isDevelopment);
      // let appliedFilters = A([]);
      // controller.set('appliedFilters', appliedFilters);
      // let columns = A([
      //   {
      //     component : "helpers/table-checkboxs",
      //     cellComponent : "helpers/row-checkbox",
      //     sortable : false,
      //     width : '40px',
      //     classNames : ['table-head padding-no table-head-checkbox-th'],
      //     cellClassNames : ['child padding-no'],
      //   }
      // ]);
      // model.columnMaster.forEach(column => {
      //   if(column.get('isColumnSelected')) {
      //     let modifiedColumn = column.toJSON();
      //     // modifiedColumn.width="150px";
      //     columns.pushObject(modifiedColumn);
      //   }
      // });
      // return controller.set('columns', columns.sortBy('sortWeight'));
    },
    actions: {
      onFilterChange: function onFilterChange(quickFilters) {
        var _this = this;

        Object.keys(this.get('queryParams')).forEach(function (qp) {
          return _this.set("controller.".concat(qp), quickFilters.get(qp));
        });
      },
      willTransition: function willTransition(transition) {
        var _this2 = this;

        this.controller.set('searchText', null);
        var navRoutes = ["recruiter.candidate.index", "recruiter.assessment.index", "recruiter.live-session.index", "recruiter.reports.dashboard", "user.settings.account", "dashboard.index"];
        navRoutes.forEach(function (routeName) {
          if (transition.targetName == routeName) {
            _this2.controller.setProperties({
              'filters': null,
              'isPendingList': false
            });

            return;
          }
        });
      }
    }
  });

  _exports.default = _default;
});