define("recruit/templates/components/helpers/file-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CHGtZn8K",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[4,\"each\",[[20,[\"attachments\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"download\",[19,1,[\"originalName\"]],null],[10,\"href\",[19,1,[\"resourceUrl\"]],null],[9,\"style\",\"display: inline-block;\"],[9,\"target\",\"_blank\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"file-attachment-div\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"attach blue large icon\"],[9,\"style\",\"font-size: 1.2em;\"],[7],[8],[1,[19,1,[\"originalName\"]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/file-download.hbs"
    }
  });

  _exports.default = _default;
});