define("recruit/components/recruiter-view/actions/section-status-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    statusReasons: Ember.computed('selectedStatus', function () {
      return this.get('store').query('statusReason', {
        status_id: this.get('selectedStatus.id')
      });
    }),
    actions: {
      changeStatus: function changeStatus(modalProperty) {
        var _this = this;

        this.get('selectedAssc').setProperties({
          status: this.get('selectedStatus'),
          statusReason: this.get('selectedStatusReason')
        });
        this.get('selectedAssc').save().then(function () {
          _this.get('toast').success("Status: ".concat(_this.get('selectedStatus.label')), 'Status updated Successfully');

          _this.sendAction('toggleModal', modalProperty);
        }).catch(function () {
          return _this.get('toast').error('Please try after some time', 'Something went wrong');
        });
      },
      toggleModal: function toggleModal(modalProperty) {
        this.sendAction('toggleModal', modalProperty);
      }
    }
  });

  _exports.default = _default;
});