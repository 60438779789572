define("recruit/templates/components/assessment/tabs/remove-form-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+H7Clux3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid margin-no\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"sixteen wide column text-center\"],[7],[0,\"\\n        \"],[6,\"p\"],[7],[6,\"img\"],[9,\"src\",\"/images/warning_new.svg\"],[9,\"alt\",\"alert\"],[9,\"class\",\"ui tiny centered image\"],[9,\"style\",\"opacity:0.8;\"],[7],[8],[8],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"text-black\"],[7],[0,\"Are you sure you want to remove the added form?\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment text-center\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui basic red button\"],[3,\"action\",[[19,0,[]],\"removeForm\",[20,[\"sectionModel\"]]]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"fa-icon\",[\"circle-notch\"],[[\"spin\"],[\"true\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      Yes, Remove\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"ui blue button\"],[3,\"action\",[[19,0,[]],\"toggleModal\"]],[7],[0,\"\\n      No\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/assessment/tabs/remove-form-alert.hbs"
    }
  });

  _exports.default = _default;
});