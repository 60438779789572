define("recruit/services/offline", ["exports", "npm:offline-js", "recruit/config/environment"], function (_exports, _npmOfflineJs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    Offline: _npmOfflineJs.default,
    current: null,
    toast: Ember.inject.service(),
    load: function load() {
      var off = window.Offline;
      off.options = {
        checks: {
          xhr: {
            url: _environment.default.APP.API_ROOT + '/connection-test'
          }
        },
        // Should we check the connection status immediatly on page load.
        checkOnLoad: false,
        // Should we monitor AJAX requests to help decide if we have a connection.
        interceptRequests: true,
        // Should we automatically retest periodically when the connection is down (set to false to disable).
        reconnect: {
          // How many seconds should we wait before rechecking.
          initialDelay: 3 // How long should we wait between retries.
          //  delay: (1.5 * last delay, capped at 1 hour)

        },
        // Should we store and attempt to remake requests which fail while the connection is down.
        requests: true,
        // Should we show a snake game while the connection is down to keep the user entertained?
        // It's not included in the normal build, you should bring in js/snake.js in addition to
        // offline.min.js.
        game: false
      };
      var toast = this.get('toast');
      off.on('down', function () {
        Ember.Logger.debug('Connection Down');
        toast.error('Your connection seems to be offline', 'Offline', {
          timeOut: 0,
          extendedTimeOut: 0,
          closeButton: false
        });
      });
      off.on('up', function () {
        toast.clear();
      });
    }
  });

  _exports.default = _default;
});