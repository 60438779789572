define("recruit/components/recruiter-view/questions/bulk-create/bulk-import-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.set('cannotCreate', false);
      this.set('moreErrors', true);
    },
    backToList: function backToList() {
      this.get('router').transitionTo('recruiter.questions.index');
    },
    actions: {
      removeSkill: function removeSkill(skill, index) {
        this.sendAction('removeSkill', skill, index);
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      },
      validate: function validate(changeset, skills, files) {
        var _this = this;

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            _this.set('cannotCreate', true);
          } else {
            _this.set('cannotCreate', false);

            _this.send('saveQuestion', changeset, skills, files);
          }
        });
      },
      discardQuestion: function discardQuestion(question, property) {
        var _this2 = this;

        this.toggleProperty(property);
        Ember.run.next(function () {
          _this2.sendAction('discardQuestion', question);
        });
      },
      goBack: function goBack(property) {
        var _this3 = this;

        this.toggleProperty(property);
        Ember.run.next(function () {
          _this3.get('toast').success('Question Review Cancelled!');

          _this3.backToList();
        });
      },
      selectSkills: function selectSkills(skill) {
        this.sendAction('selectSkills', skill);
      },
      showErrors: function showErrors(property) {
        this.toggleProperty(property);
      },
      saveQuestion: function saveQuestion(questionObject, skills, files) {
        this.sendAction('saveQuestion', questionObject, skills, files);
      },
      closeErrors: function closeErrors() {
        this.set('cannotCreate', false);
      },
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});