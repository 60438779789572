define("recruit/components/events/event-panel-match", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row events-panel-match padding-sides-vs'],
    threshold: 4,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('matchedObject', Ember.A());
      this.set('matchedPanels', Ember.A());
      this.set('matchedAssessmentSections', Ember.A());
      this.set('extraPanels', Ember.A());
      var matchedPanels = this.get('eventPanels').map(function (ep) {
        if (ep.get('status') == 2) {
          return ep.get('panel.id');
        }
      });
      this.get('eventAssessment.assessmentSections').then(function (assessmentSections) {
        if (assessmentSections.get('length')) {
          assessmentSections.forEach(function (assessmentSection) {
            _this.get('matchedObject').pushObject(Ember.Object.create({
              assessmentSection: assessmentSection,
              panels: Ember.A()
            }));

            assessmentSection.get('panel').then(function (panels) {
              if (!_npmLodash.default.isNil(panels) && panels.get('length')) {
                Ember.RSVP.all(panels.map(function (panel) {
                  if (_npmLodash.default.includes(matchedPanels, panel.get('id'))) {
                    var matchedObj = _this.get('matchedObject').find(function (obj) {
                      return obj.get('assessmentSection.id') == assessmentSection.get('id');
                    });

                    _this.get('matchedPanels').pushObject(panel);

                    matchedObj.get('panels').pushObject(panel);

                    _this.get('matchedAssessmentSections').pushObject(assessmentSection);
                  }

                  return _this;
                })).finally(function () {
                  if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                    _this.set('relationsLoaded', true);
                  }
                });
              } else {
                if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
                  _this.set('relationsLoaded', true);
                }
              }
            });
          });
        } else {
          if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
            _this.set('relationsLoaded', true);
          }
        }
      });
    },
    didRender: function didRender() {
      Ember.run.next(function () {
        Ember.$('.panel-match-tooltip').popup();
      });
    },
    actions: {
      redirectToAssessmentView: function redirectToAssessmentView(path, model, canViewAssessment) {
        if (!path || !model || !canViewAssessment) {
          return false;
        }

        this.get('router').transitionTo(path, model, {
          queryParams: {
            assessmentFilters: JSON.stringify({
              id: this.get('eventModel.id'),
              type: 'event',
              label: model.get('title')
            })
          }
        });
      }
    }
  });

  _exports.default = _default;
});