define("recruit/templates/components/candidate-helpers/section-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t5le31dw",
    "block": "{\"symbols\":[\"assc\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"\"],[9,\"style\",\"display:table;\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"value\",\"assessmentSectionCandidates\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"\"],[9,\"style\",\"display:table-cell;\"],[7],[0,\"\\n    \"],[1,[25,\"helpers/status-icon\",null,[[\"status\",\"sectionType\"],[[19,1,[\"status\"]],[19,1,[\"section\",\"sectionType\",\"label\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-helpers/section-status.hbs"
    }
  });

  _exports.default = _default;
});