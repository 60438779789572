define("recruit/components/model-table/recruiter/assessment", ["exports", "recruit/mixins/infinite-loader", "recruit/config/environment"], function (_exports, _infiniteLoader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_infiniteLoader.default, {
    modelName: 'assessment',
    isDevelopment: _environment.default.isDevelopment,
    listContents: Ember.A([]),
    expand: ['invitedCount', 'inProgressCount', 'attendedCount', 'assessedCount', 'selectedCount', 'rejectedCount', 'count'].join(','),
    // modelType: 'assessment',
    // expand: [
    //   'invitedCount',
    //   'inProgressCount',
    //   'attendedCount',
    //   'assessedCount',
    //   'selectedCount',
    //   'rejectedCount',
    //   'count'
    // ].join(','),
    // assessments: computed('table.selectedRows', function(){
    //   return this.get('table.selectedRows').map((row) => row.get('content'))
    // }),
    //   columns: Ember.computed(function() {
    //     return [
    //     {
    //       label: 'Name',
    //       valuePath: 'assessment',
    //       cellComponent: 'assessment-helpers.assessment-details',
    //       width: '150px',
    //       classNames: ['table-head text-left'],
    //       cellClassNames: ['']
    //     },
    //     {
    //       valuePath: 'owned_by',
    //       cellComponent: 'helpers/table-profile-picture-small',
    //       sortable: false,
    //       width: '50px',
    //       classNames: ['table-head']
    //     },
    //     {
    //       label: 'Owner',
    //       valuePath: 'owned_by',
    //       width: '128px',
    //       cellComponent: 'user-helpers.user-details',
    //       classNames: ['table-head text-left'],
    //       cellClassNames: ['']
    //     },
    //     {
    //       label: 'Summary',
    //       valuePath: 'id',
    //       cellComponent: 'recruiter-view.assessment.summary',
    //       width: '228px',
    //       classNames: ['table-head'],
    //       cellClassNames: ['text-center']
    //     },
    //     {
    //       label: 'Status',
    //       cellComponent: 'candidate-helpers.status',
    //       valuePath: 'statusText',
    //       width: '80px',
    //       cellClassNames: 'text-center text-weight-light',
    //       classNames: ['table-head'],
    //     },
    //     {
    //       label: 'End Date',
    //       cellComponent: 'helpers.date-view',
    //       width: '150px',
    //       showTimeAgo: false,
    //       dateFormat: 'DD-MMM-YY',
    //       valuePath: 'end_date',
    //       classNames: ['table-head'],
    //       cellClassNames: ['text-center']
    //     },
    //
    //   ];
    // }),
    actions: {
      rowClicked: function rowClicked(row) {
        this.get('router').transitionTo('recruiter.assessment.details.overview', row.get('id'));
      }
    }
  });

  _exports.default = _default;
});