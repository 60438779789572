define("recruit/components/recruiter-view/actions/candidate-detail", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          inline: false,
          position: 'bottom center'
        });
      });
    },
    checked: Ember.computed('selectedListValues.[]', function () {
      return _npmLodash.default.find(this.get('selectedListValues'), this.get('parentObject'));
    }),
    changeStatus: Ember.computed('model.actionType', function () {
      return _npmLodash.default.toLower(this.get('model.actionType')) == 'select an invite status';
    }),
    liveSession: Ember.computed('model.relationType', function () {
      return _npmLodash.default.toLower(this.get('model.relationType')) == 'livesession';
    }),
    actions: {
      selectCandidates: function selectCandidates(model, checked) {
        if (checked) {
          this.set('emptyList', false);
          this.get('selectedListValues').pushObject(model);
        } else {
          var index = _npmLodash.default.findIndex(this.get('selectedListValues'), ['id', model.get('id')]);

          if (index != -1) {
            this.get('selectedListValues').removeAt(index);
            this.set('emptyList', !this.get('selectedListValues.length'));
          }
        }
      }
    }
  });

  _exports.default = _default;
});