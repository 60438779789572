define("recruit/components/diff-view", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    src: null,
    dest: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (!_npmLodash.default.isEmpty(this.get('src')) && !_npmLodash.default.isEmpty(this.get('dest')) && _npmLodash.default.isString(this.get('src')) && _npmLodash.default.isString(this.get('dest'))) {
        emberAutoImportDynamic("diff").then(function (JsDiff) {
          var parts = JsDiff.diffChars(_this.get('src'), _this.get('dest'));

          _this.set('parts', _npmLodash.default.map(parts, function (part) {
            return {
              value: part.value,
              class: part.added ? 'text-green' : part.removed ? 'text-red' : 'text-grey'
            };
          }));
        });
      }
    }
  });

  _exports.default = _default;
});