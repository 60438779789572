define("recruit/components/evaluation-buttons-card", ["exports", "recruit/components/panel/evaluation-strip"], function (_exports, _evaluationStrip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _evaluationStrip.default.extend({});

  _exports.default = _default;
});