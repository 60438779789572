define("recruit/templates/recruiter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zw0vl4BK",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Recruiter\"],null],false],[0,\"\\n\"],[4,\"if\",[[20,[\"errorInRole\"]]],null,{\"statements\":[[4,\"modal-wrapper\",null,null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"five wide column\"],[7],[0,\"\\n      \"],[6,\"img\"],[9,\"src\",\"/images/cuton_search.png\"],[9,\"alt\",\"cuton\"],[9,\"class\",\"ui image cuton-updown\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"eleven wide middle aligned center aligned column padding-no\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\" text-black text-size-normal margin-no\"],[7],[0,\"\\n        You current role does not have an access\"],[6,\"br\"],[7],[8],[0,\" to this link.\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-center padding-bg-top\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"user.change-role\"],null,{\"statements\":[[0,\"          \"],[6,\"button\"],[9,\"class\",\"ui basic blue compact button\"],[7],[6,\"i\"],[9,\"class\",\"exchange blue icon\"],[7],[8],[0,\" Change your role\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[6,\"div\"],[9,\"class\",\"ui horizontal divider\"],[7],[0,\"OR\"],[8],[0,\"\\n        \"],[6,\"p\"],[7],[0,\"Contact your administrator.\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[18,\"liquid-outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter.hbs"
    }
  });

  _exports.default = _default;
});