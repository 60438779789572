define("recruit/templates/components/helpers/filters-card-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y+b1Vjy+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"filterField\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"data-tooltip\",[26,[[18,\"filterField\"],\" : \",[18,\"labelName\"]]]],[9,\"data-position\",\"bottom center\"],[9,\"class\",\"ui segment margin-bottom-ten margin-top-no text-black box-shadow-none padding-vs text-size-small display-inline-block filter-card-shape filter-card-background\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"text-grey\"],[7],[1,[18,\"filterField\"],false],[8],[0,\" : \"],[1,[18,\"labelName\"],false],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"remove icon cursor-pointer padding-no\"],[9,\"style\",\"margin: 0 0 0 5px;\"],[3,\"action\",[[19,0,[]],\"remove\",[20,[\"index\"]]]],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"data-tooltip\",[26,[[18,\"labelName\"]]]],[9,\"data-position\",\"bottom center\"],[9,\"class\",\"ui segment margin-bottom-ten margin-top-no text-black box-shadow-none padding-vs text-size-small display-inline-block filter-card-shape filter-card-background\"],[7],[0,\"\\n    \"],[1,[18,\"labelName\"],false],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"remove icon cursor-pointer padding-no\"],[9,\"style\",\"margin: 0 0 0 5px;\"],[3,\"action\",[[19,0,[]],\"remove\",[20,[\"index\"]]]],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/filters-card-view.hbs"
    }
  });

  _exports.default = _default;
});