define("recruit/templates/components/reports/helpers/graph-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7kM+s0+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid margin-bottom-five\"],[9,\"style\",\"height: 40px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"eight wide column text-left padding-no\"],[9,\"style\",\"height: 40px;\"],[7],[0,\"\\n    \"],[1,[25,\"component\",[[20,[\"graphType\"]]],[[\"userObject\",\"isLoading\",\"showEmptyState\",\"selectedRow\",\"graphTitle\",\"selectedGraphFilter\"],[[20,[\"userObject\"]],[20,[\"isLoading\"]],[20,[\"showEmptyState\"]],[20,[\"selectedRow\"]],[20,[\"graphTitle\"]],[20,[\"selectedGraphFilter\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"unless\",[[25,\"or\",[[20,[\"showEmptyState\"]],[20,[\"hideNote\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"eight wide column padding-no text-right text-black\"],[7],[0,\"\\n      \"],[6,\"b\"],[7],[0,\"Note:\"],[8],[0,\" Select another row to view respective graphical representation.\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/graph-header.hbs"
    }
  });

  _exports.default = _default;
});