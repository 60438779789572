define("recruit/models/section", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    type_id: _emberData.default.attr('number'),
    external_id: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    isForm: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isParameter: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    external_assessment_id: _emberData.default.attr('number'),
    questionsCount: _emberData.default.attr('number'),
    questionType: _emberData.default.attr(),
    evaluationFormUrl: _emberData.default.attr('string'),
    //for testing: , {defaultValue: "http://localhost:1313/evaluation/tech-ios/"}
    sectionParameters: _emberData.default.hasMany('section-parameter'),
    parameters: _emberData.default.hasMany('parameter'),
    sectionType: _emberData.default.belongsTo('section-type'),
    assessmentSections: _emberData.default.hasMany('assessment-section'),
    evaluationForm: _emberData.default.belongsTo('form'),
    externalAssessment: _emberData.default.belongsTo('external-assessment'),
    questions: _emberData.default.hasMany('question'),
    assessmentQuestions: _emberData.default.hasMany('assessment-question'),
    sectionQuestions: _emberData.default.hasMany('section-question'),
    sectionSkills: _emberData.default.hasMany('section-skill'),
    combined: _emberData.default.hasMany('section-combined'),
    formattedQuestionsCount: Ember.computed('questionsCount', function () {
      if (this.get('questionsCount')) {
        return this.get('questionsCount');
      } else {
        return '--';
      }
    }),
    isMcq: Ember.computed('type_id', function () {
      return this.get('type_id') === 6;
    }),
    isLive: Ember.computed('type_id', function () {
      return this.get('type_id') === 7;
    }),
    isAutomated: Ember.computed('type_id', function () {
      return this.get('type_id') === 5;
    }),
    isVm: Ember.computed('type_id', function () {
      return this.get('type_id') === 22;
    }),
    isEvaluationOnly: Ember.computed('type_id', function () {
      return this.get('type_id') === 15;
    }),
    isPearson: Ember.computed('type_id', 'sectionType', function () {
      return this.get('sectionType.id') == 19;
    }),
    isExternal: Ember.computed('type_id', function () {
      return this.get('type_id') === 21;
    }),
    isTyping: Ember.computed('type_id', function () {
      return this.get('type_id') === 10;
    }),
    isEssay: Ember.computed('type_id', function () {
      return this.get('type_id') === 4;
    }),
    isCode: Ember.computed('type_id', function () {
      return this.get('type_id') === 17;
    }),
    hasScoreOrCutoff: Ember.computed('type_id', 'isMcq', 'isPearson', function () {
      return this.get('isMcq') || this.get('isPearson');
    }),
    shouldHaveAnswers: Ember.computed(function () {
      return this.get('isAutomated') || this.get('isEssay') || this.get('isTyping') || this.get('isVm') || this.get('isCode');
    }),
    canEvaluateAnswers: Ember.computed(function () {
      return this.get('shouldHaveAnswers') || this.get('isLive') || this.get('isEvaluationOnly');
    }),
    checkIFQuestionAvailable: function checkIFQuestionAvailable(sectionTypeID) {
      if (sectionTypeID) {
        return sectionTypeID != 7 && sectionTypeID != 15 && sectionTypeID != 19 && sectionTypeID != 22;
      }
    }
  });

  _exports.default = _default;
});