define("recruit/controllers/forgot", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showSupport: false,
    resetLinkSent: false,
    host: _environment.default.APP.API_ROOT,
    actions: {
      sendResetLink: function sendResetLink() {
        var _this = this;

        if (this.get('emailAddress.length')) {
          Ember.$.ajax({
            type: "POST",
            url: this.get('host') + "/user/reset-password/request",
            dataType: "application/json",
            data: {
              'username': this.get('emailAddress')
            },
            success: function success() {
              _this.set('resetLinkSent', true);

              _this.set('showSupport', true);
            },
            error: function error() {
              _this.get('toast').error('Something went wrong', 'Please try after some time.');
            }
          });
        } else {
          this.get('toast').error('Please try again.', 'Email can not be empty');
        }
      }
    }
  });

  _exports.default = _default;
});