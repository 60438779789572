define("recruit/components/live-session/live-candidate-details", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userRole: Ember.inject.service(),
    isDevelopment: _environment.default.isDevelopment,
    actions: {
      toggleModal: function toggleModal(type) {
        this.toggleProperty(type);
      }
    }
  });

  _exports.default = _default;
});