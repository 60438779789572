define("recruit/templates/components/recruiter-view/questions/helpers/user-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C/2DPk8V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"row\",\"created_by\"]],[20,[\"row\",\"created_by\",\"profilePic\"]],[20,[\"row\",\"created_by\",\"name\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[6,\"div\"],[9,\"class\",\"ui centered two column grid margin-no\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"column padding-no reports-text\"],[9,\"style\",\"max-width: 60px;\"],[7],[0,\"\\n\\t\\t  \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"row\",\"created_by\",\"profilePic\"]],\"ui circular centered image questions user-profile\"]]],false],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"middle aligned column reports-text padding-no text-left text-size-fourteen\"],[7],[0,\"\\n\\t    \"],[6,\"p\"],[9,\"class\",\"text-black text-weight-normal margin-no\"],[7],[0,\"\\n\\t      \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[10,\"data-content\",[26,[[20,[\"row\",\"created_by\",\"name\"]]]]],[7],[1,[20,[\"row\",\"created_by\",\"name\"]],false],[8],[0,\"\\n\\t    \"],[8],[0,\"\\n\\t  \"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui centered two column grid margin-no\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"column padding-no reports-text\"],[9,\"style\",\"max-width: 60px;\"],[7],[0,\"\\n\\t\\t  \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[\"/images/user_light.svg\",\"ui circular centered image questions user-profile\"]]],false],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"middle aligned column reports-text padding-no text-left text-size-fourteen\"],[7],[0,\"\\n\\t    \"],[6,\"p\"],[9,\"class\",\"text-grey text-weight-normal margin-no\"],[7],[0,\"\\n\\t      \"],[6,\"span\"],[7],[0,\"Anonymous\"],[8],[0,\"\\n\\t    \"],[8],[0,\"\\n\\t  \"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/user-details-cell-component.hbs"
    }
  });

  _exports.default = _default;
});