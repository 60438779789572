define("recruit/components/panel/evaluate-filter-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'four wide column padding-no',
    s: null,
    isLoading: true,
    evaluated: false,
    recommendation: null,
    currentSelected: 'pending',
    candidateCounts: function () {
      var _this = this;

      this.set('isLoading', true);
      return Ember.RSVP.hash({
        pending: this.get('store').query('panel-candidate', {
          s: this.get('s'),
          panel_id: this.get('panelCandidate.panel.id'),
          isPending: 1
        }),
        selected: this.get('store').query('panel-candidate', {
          s: this.get('s'),
          panel_id: this.get('panelCandidate.panel.id'),
          recommendation: 'select'
        }),
        rejected: this.get('store').query('panel-candidate', {
          s: this.get('s'),
          panel_id: this.get('panelCandidate.panel.id'),
          recommendation: 'reject'
        }),
        hold: this.get('store').query('panel-candidate', {
          s: this.get('s'),
          panel_id: this.get('panelCandidate.panel.id'),
          recommendation: 'hold'
        })
      }).then(function (panelCandidates) {
        _this.set('allPanelCandidates', panelCandidates);

        _this.set('panelCandidates', panelCandidates[_this.get('currentSelected')]);

        Object.keys(panelCandidates).forEach(function (key) {
          _this.set(key + 'Count', panelCandidates[key]['meta']['total']);
        });

        _this.set('isLoading', false);
      });
    }.observes('s').on('init'),
    search: function () {
      var _this2 = this;

      Ember.run.debounce(this, function () {
        _this2.set('s', _this2.get('searchText'));
      }, 500);
    }.observes('searchText'),
    actions: {
      switchCandidate: function switchCandidate(panelCandidate) {
        return this.sendAction('switchCandidate', panelCandidate);
      },
      changeType: function changeType(status) {
        this.set('panelCandidates', this.get("allPanelCandidates.".concat(status)));
        this.set('currentSelected', status);
      }
    }
  });

  _exports.default = _default;
});