define("recruit/models/lf-adapter/assessment-creation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    assessment: _emberData.default.belongsTo('assessment'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    external_id: _emberData.default.attr('string'),
    currentSlide: _emberData.default.attr('number'),
    sections: _emberData.default.hasMany('lf-adapter/section'),
    skills: _emberData.default.hasMany('lf-adapter/skill'),
    hasValidJD: Ember.computed('description', function () {
      var jd = String(this.get('description') || "").replace(/(<([^>]+)>)/ig, "").trim();
      return jd.length && jd !== '(not set)' && jd !== 'not set';
    }) // selectedOption: DS.attr(),
    // assessmentOptions: DS.attr()

  });

  _exports.default = _default;
});