define("recruit/templates/recruiter/candidate/create/basic-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RiwCaOcL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui centered grid margin-no padding-no\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"twelve wide column marign-no padding-no\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isBulkUpload\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"ui segments bg-white margin-top-hundred\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"text-black\"],[7],[0,\"\\n          \"],[1,[25,\"recruiter-view/bulk/bulk-upload\",null,[[\"isBulkUpload\",\"CandidateValidations\"],[[20,[\"isBulkUpload\"]],[20,[\"CandidateValidations\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"ui segments bg-white margin-top-hundred\"],[7],[0,\"\\n        \"],[1,[25,\"recruiter-view/candidate/create/create-header\",null,[[\"model\",\"candidateModel\",\"isBulkUpload\"],[[20,[\"model\"]],[20,[\"model\",\"candidate\"]],[20,[\"isBulkUpload\"]]]]],false],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui segment padding-no\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"ui large form light-text bg-white\"],[7],[0,\"\\n            \"],[1,[25,\"candidate-helpers/candidate-basic-details\",null,[[\"hasCustomFields\",\"file\",\"candidateModel\",\"CandidateValidations\"],[[20,[\"model\",\"fieldMaster\",\"length\"]],[20,[\"file\"]],[20,[\"model\",\"candidate\"]],[20,[\"CandidateValidations\"]]]]],false],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/candidate/create/basic-details.hbs"
    }
  });

  _exports.default = _default;
});