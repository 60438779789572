define("recruit/routes/recruiter/reports/advanced/conversion/conversion-index", ["exports", "recruit/routes/recruiter/reports/report-filters", "recruit/data/reports-advance-filters-recruiter"], function (_exports, _reportFilters, _reportsAdvanceFiltersRecruiter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportFilters.default.extend({
    store: Ember.inject.service(),
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.get('store').push({
        data: _reportsAdvanceFiltersRecruiter.default
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('columns', this.getColumns(model));
      controller.set('graphTitle', model.get('label') + '-CONVERSION');
      controller.set('graphInfo', "The conversion of ".concat(model.get('label'), " from the invite stage to the final selection for each assessment."));
      controller.set('custom_field_master_id', model.get('id'));
      controller.set('advanceFilters', this.get('store').peekAll('filter/master'));
      controller.set('nodeValues', Ember.A(['invitedCount', 'inprogressCount', 'completedCount', 'selectedCount']));
      controller.set('cirlceLabels', Ember.A(['Total Invited', 'Attempted', 'Completed', 'Selected']));
    },
    getColumns: function getColumns(model) {
      return [{
        label: model.get('label'),
        component: 'reports/helpers/assessment-header',
        classNames: ['table-head mis text-center border-right-grey'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        width: '23%',
        sortable: false,
        cellComponent: 'reports/helpers/advanced-row-component'
      }, {
        sortable: false,
        component: 'reports/helpers/column-with-toggle',
        value: 'CONVERSION',
        classNames: ['table-head mis text-center'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        subColumns: [{
          label: 'Total Invited',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The cumulative number of candidates invited in the specified time period.',
          valuePath: 'invitedCount',
          width: '11%'
        }, {
          label: 'Total Attempted',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates that have attempted the assessment.',
          valuePath: 'inprogressPercent',
          width: '11%'
        }, {
          label: 'Completed',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates that have completed the assessment.',
          valuePath: 'completedPercent',
          width: '11%'
        }, {
          label: 'Selected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates selected out of those who completed the assessment.',
          valuePath: 'selectedPercent',
          width: '11%'
        }, {
          label: 'On-hold',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: ' The total number of candidates that have been put on hold.',
          valuePath: 'onHoldPercent',
          width: '11%'
        }, {
          label: 'Rejected',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The total number of candidates rejected by the panel.',
          valuePath: 'rejectedPercent',
          width: '11%'
        }, {
          label: 'Conversion',
          subLabel: '(in %)',
          component: 'reports/helpers/table-header',
          hideDot: true,
          valuePath: 'conversionRate',
          classNames: ['table-head mis text-center'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'The conversion of candidates (in percentage) from the invite stage to the final selection.',
          width: '11%'
        }]
      }];
    },
    actions: {
      setFilters: function setFilters(assessment, recruiter, panel, advanceFilters) {
        this.controller.set('applyFilters', Ember.A([]));
        this.controller.get('applyFilters').pushObject({
          recruiter_id: recruiter,
          panel_id: panel,
          assessment_id: assessment,
          advanceFilters: advanceFilters
        });
        this.checkIfFiltersApplied(this.controller.get('applyFilters.firstObject'));
      },
      postRemoveFilter: function postRemoveFilter() {
        this.checkIfFiltersApplied(this.controller.get('applyFilters.firstObject'));
      }
    }
  });

  _exports.default = _default;
});