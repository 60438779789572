define("recruit/templates/components/iframe-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rQOjpn8V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"iframe\"],[9,\"id\",\"assessmentDescription\"],[9,\"frameBorder\",\"0\"],[9,\"width\",\"100%\"],[9,\"height\",\"100%\"],[9,\"seamless\",\"seamless\"],[9,\"style\",\"height:100%;width:100%;\"],[7],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/iframe-html.hbs"
    }
  });

  _exports.default = _default;
});