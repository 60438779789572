define("recruit/components/panel/events/invite-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    organization: Ember.computed(function () {
      return this.get('currentUser.me.organization');
    }),
    catchErrors: function catchErrors(error) {
      var _this = this;

      if (error.errors) {
        error.errors.forEach(function (e) {
          _this.get('toast').error(e.detail, 'Error');
        });
      } else {
        this.get('toast').error('Please try after some time', 'Something went wrong');
      }
    },
    addEventPanel: function addEventPanel(panelObject) {
      var _this2 = this;

      this.set('eventUser.status', 2);
      this.set('eventUser.panel', panelObject);
      this.get('eventUser').save().then(function () {
        _this2.get('toast').success("Invitation Accepted Successfully!");

        _this2.get('router').transitionTo('panel.event.details.response', {
          queryParams: {
            status: 'accept',
            panel_id: panelObject.get('id'),
            event_user_id: _this2.get('eventUser.id')
          }
        });
      }).catch(function (error) {
        _this2.catchErrors(error);
      }).finally(function () {
        _this2.set('isLoading', false);
      });
    },
    actions: {
      toggleModal: function toggleModal(property, setTitle) {
        this.sendAction('toggleModal', property, setTitle);
      },
      decline: function decline() {
        this.get('router').transitionTo('panel.event.details.response', {
          queryParams: {
            status: 'decline',
            event_user_id: this.get('eventUser.id')
          }
        });
      },
      checkForPanel: function checkForPanel(selectedAssessmentSection) {
        var _this3 = this;

        if (selectedAssessmentSection) {
          this.set('isLoading', true);
          this.get('store').query('panel', {
            assessment_section_id: selectedAssessmentSection.id,
            user_id: this.get('eventUser.user.id')
          }).then(function (response) {
            if (response.get('firstObject')) {
              _this3.addEventPanel(response.get('firstObject'));
            } else {
              _this3.get('store').createRecord('panel', {
                user: _this3.get('eventUser.user'),
                assessmentSection: selectedAssessmentSection,
                eventUser: _this3.get('eventUser')
              }).save().then(function (panelObject) {
                _this3.addEventPanel(panelObject);
              }).catch(function (error) {
                _this3.catchErrors(error);
              });
            }
          }).catch(function (error) {
            _this3.catchErrors(error);
          });
        } else {
          this.get('toast').error('You need to select one section to accept the invite!');
        }
      }
    }
  });

  _exports.default = _default;
});