define("recruit/serializers/form-instance", ["exports", "recruit/serializers/application", "npm:lodash"], function (_exports, _application, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.data = json.formData;
      delete json.formData;
      return json;
    },
    normalize: function normalize(typeClass, hash) {
      var formItems = [];

      if (!Array.isArray(hash.data)) {
        _npmLodash.default.forOwn(hash.data, function (item, key) {
          formItems.pushObject({
            label: _npmLodash.default.capitalize(key),
            value: item
          });
        });
      } else {
        _npmLodash.default.forOwn(hash.data, function (item) {
          if (!Array.isArray(item.value)) {
            formItems.pushObject({
              label: _npmLodash.default.capitalize(item.label),
              value: item.value
            });
          } else {
            var values = item.value.map(function (nestedValue) {
              var mapped = nestedValue.map(function (x) {
                if (x.label && x.key) {
                  return _defineProperty({}, x.label, x.value);
                }
              });
              return Object.assign.apply(Object, [{}].concat(_toConsumableArray(mapped)));
            });
            formItems.pushObject({
              label: _npmLodash.default.capitalize(item.label),
              is_object: true,
              value: values
            });
          }
        });
      }

      hash.formData = formItems;
      delete hash.data;
      return this._super(typeClass, hash);
    }
  });

  _exports.default = _default;
});