define("recruit/components/live-session/candidate-sidebar-detailed-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hideCloseButton: Ember.computed('isSidebarClosable', 'model.status', function () {
      if (this.get('model.status') === 3) {
        return this.get('isSidebarClosable');
      } else {
        return true;
      }
    }),
    setSidebarViewHeight: Ember.computed('model.status', 'selectedAssc.section.evaluationFormUrl', 'selectedAssc.section.sectionParameters.length', function () {
      if (this.get('model.status') === 3) {
        return Ember.String.htmlSafe("height : calc(100vh - 360px) !important");
      } else {
        if (this.get('selectedAssc.section.evaluationFormUrl') || this.get('selectedAssc.section.sectionParameters.length')) {
          return Ember.String.htmlSafe("height : calc(100vh - 425px) !important");
        } else {
          return Ember.String.htmlSafe("height : calc(100vh - 530px) !important");
        }
      }
    }),
    actions: {
      toggleSidebar: function toggleSidebar(property) {
        this.sendAction('toggleSidebar', property);
      },
      toggleEvaluationSidebar: function toggleEvaluationSidebar(property) {
        this.sendAction('toggleEvaluationSidebar', property);
      },
      toggleModal: function toggleModal(property, setTitle) {
        if (setTitle) {
          this.set('displayTitle', setTitle);
        }

        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});