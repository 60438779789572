define("recruit/components/dark-modal-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$('body').addClass('outside-app-bg re');
    },
    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);

      Ember.$('body').removeClass('outside-app-bg');
    }
  });

  _exports.default = _default;
});