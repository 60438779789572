define("recruit/components/model-table/recruiter/assessment/notification", ["exports", "recruit/mixins/table-common"], function (_exports, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    modelType: 'notification-log',
    contentObserver: Ember.observer('table.rows.[]', 'isLoading', function () {
      if (this.get('page') == 1 && this.get('table.rows.length') && !this.get('isCountLoading')) {
        this.set('table.rows.firstObject.selected', true);
        return this.set('selectedNotification', this.get('table.rows.firstObject.content'));
      } else {
        return this.setFlags();
      }
    }),
    setFlags: function () {
      var _this = this;

      Ember.run.schedule('actions', function () {
        _this.set('emptyNotification', _this.get('table.isEmpty'));

        _this.set('isTableLoading', _this.get('isLoading'));

        if (_this.get('table.isEmpty')) {
          _this.set('selectedNotification', null);
        }
      });
    }.observes('table.isEmpty', 'isCountLoading', 'isLoading'),
    columns: Ember.computed(function () {
      return [{
        cellComponent: 'recruiter-view/assessment/helpers/notification-card',
        sortable: false,
        cellClassNames: ['padding-sm-left padding-vs-right assessment-notification-card cursor-pointer padding-no']
      }];
    }),
    actions: {
      onRowClick: function onRowClick(row) {
        this.sendAction('setNotification', row.get('content'));
      }
    }
  });

  _exports.default = _default;
});