define("recruit/templates/components/helpers/download-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J1sMh/On",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"fa-icon\",[\"download\"],[[\"class\"],[\"margin-right-five\"]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"showText\"]]],null,{\"statements\":[[0,\"  Download\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/download-reports.hbs"
    }
  });

  _exports.default = _default;
});