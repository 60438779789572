define("recruit/components/recruiter-view/actions/actions-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      back: function back() {
        window.history.back();
      },
      negative: function negative() {
        if (this.get('negativeAction')) {
          this.sendAction('negativeAction');
        } else {
          this.send('back');
        }
      },
      positive: function positive() {
        if (this.get('positiveAction')) {
          this.sendAction('positiveAction');
        }
      }
    }
  });

  _exports.default = _default;
});