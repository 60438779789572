define("recruit/components/reports/helpers/assessment-graph-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      Ember.run.later(function () {
        Ember.$('.assessment.details').popup({
          position: 'bottom center'
        });
      });
    }
  });

  _exports.default = _default;
});