define("recruit/components/model-table/recruiter/candidate-row-component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    tagName: 'tr',
    performance: Ember.inject.service(),
    classNameBindings: ['candidate.isSelected:is-selected', 'candidate.isMarkedForScroll:isMarkedForScroll'],
    // didUpdateAttrs() {
    //   if(Ember.$('#horizontalScrollElement') && Ember.$('#horizontalScrollElement').length) {
    //     Ember.run.next('afterRender', () => {
    //       Ember.$('#horizontalScrollElement').trigger('scroll')
    //     })
    //   }
    // },
    checked: Ember.computed.alias('candidate.isSelected'),
    click: function click(element) {
      if (element.target.parentElement.classList.contains('checkbox')) {
        return;
      }

      return this.get('router').transitionTo('recruiter.candidate.details.profile', this.get('candidate.id'));
    },
    performanceObserver: Ember.observer('candidate.isRelationsLoaded', function () {
      var _this = this;

      Ember.run.once('afterRender', function () {
        var rows = _this.get('candidates');

        if (rows.get('length') <= 100 && rows.get('lastObject.id') === _this.get('candidate.id') && _this.get('candidate.isRelationsLoaded')) {
          _this.get('performance').end('Recruiter:Candidates:list');

          _this.sendAction('onFirstPageRender'); // switch(this.get('table.tableType')) {
          //   case "r_c_list":
          //     this.get('performance').end('Recruiter:Candidates:list');
          //   break;
          //   case "r_ac_list":
          //     this.get('performance').end('Recruiter:Invites:list');
          //   break;
          //   case "r_a_ac_list":
          //     this.get('performance').end('Recruiter:Assessment:Invites:list');
          //   break;
          //   case "r_a_assc_list":
          //     this.get('performance').end('Recruiter:Assessment:Section:list');
          //   break;
          // }

        }
      });
    }).on('init'),
    actions: {
      onRowChecked: function onRowChecked(candidate, event) {
        this.sendAction('onRowChecked', candidate, event);
      }
    }
  });

  _exports.default = _default;
});