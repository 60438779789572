define("recruit/adapters/reports/report-dates", ["exports", "ember-localforage-adapter/adapters/localforage"], function (_exports, _localforage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localforage.default.extend({
    namespace: 'report-dates'
  });

  _exports.default = _default;
});