define("recruit/components/model-table/live-session/row", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    tagName: 'tr',
    performance: Ember.inject.service(),
    classNameBindings: ['ls.isSelected:is-selected', 'ls.isMarkedForScroll:isMarkedForScroll'],
    checked: Ember.computed.alias('ls.isSelected'),
    click: function click(element) {
      if (element.target.parentElement.classList.contains('checkbox')) {
        return;
      }

      if (this.get('isRecruiter')) {
        return this.get('router').transitionTo('recruiter.live-session.view', this.get('ls.id'));
      } else {
        return this.get('router').transitionTo('panel.live-session.view', this.get('ls.id'));
      }
    },
    performanceObserver: Ember.observer('ls.isRelationsLoaded', function () {
      var _this = this;

      Ember.run.once('afterRender', function () {
        var rows = _this.get('tableRows');

        if (rows.get('length') <= 100 && rows.get('lastObject.id') === _this.get('ls.id') && _this.get('ls.isRelationsLoaded')) {
          if (_this.get('isRecruiter')) {
            _this.get('performance').end('Recruiter:Live-Session:list');
          } else {
            _this.get('performance').end('Panel:Live-Session:list');
          }

          _this.sendAction('onFirstPageRender');
        }
      });
    }).on('init'),
    actions: {
      onRowChecked: function onRowChecked(item, event) {
        this.sendAction('onRowChecked', item, event);
      }
    }
  });

  _exports.default = _default;
});