define("recruit/authenticators/tv", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    restore: function restore(data) {
      return Ember.RSVP.resolve(data);
    },
    authenticate: function authenticate(payload) {
      return Ember.RSVP.resolve().then(function () {
        return JSON.parse(atob(payload));
      });
    },
    invalidate: function invalidate() {
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});