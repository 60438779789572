define("recruit/models/external-assessment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    reference_id: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('pgdate'),
    end_date: _emberData.default.attr('pgdate'),
    description: _emberData.default.attr('string'),
    duration: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    externalInvitations: _emberData.default.hasMany('external-invitation'),
    sections: _emberData.default.hasMany('section')
  });

  _exports.default = _default;
});