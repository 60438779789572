define("recruit/models/section-parameter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "parameter": _emberData.default.belongsTo('parameter'),
    "section": _emberData.default.belongsTo('section'),
    "sort_order": _emberData.default.attr('number')
  });

  _exports.default = _default;
});