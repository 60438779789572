define("recruit/routes/panel/candidate/evaluate/tech-ios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      submitForm: function submitForm() {
        this.get('toast').success('Evaluation saved', 'Successful');
        this.get('router').transitionTo('panel.candidate');
      }
    }
  });

  _exports.default = _default;
});