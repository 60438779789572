define("recruit/templates/components/recruiter-view/dashboard/candidate-count-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N+OcCNkF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"number-loading animated-background\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"inside\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"number-loading animated-background\"],[9,\"style\",\"left:-3px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"inside\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"number-loading animated-background\"],[9,\"style\",\"left:-5px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"inside\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/dashboard/candidate-count-loading.hbs"
    }
  });

  _exports.default = _default;
});