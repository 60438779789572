define("recruit/controllers/recruiter/assessment/details/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['searchText'],
    searchText: null
  });

  _exports.default = _default;
});