define("recruit/components/assessment/tabs/assessment-question", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['padding-md'],
    _pushQuestion: Ember.observer('selectedQuestion', function () {
      var _this = this;

      if (this.get('selectedQuestion.id')) {
        if (parseInt(this.get('sectionModel.sectionType.id'), 10) == 10 && this.get('questions.length') >= 1) {
          this.set('selectedQuestion', null);
          return this.toggleProperty('showTypingError');
        }

        var questionIds = this.get('questions').map(function (obj) {
          return obj.get('question.id');
        });

        if (_npmLodash.default.includes(questionIds, this.get('selectedQuestion.id'))) {
          this.set('selectedQuestion', null);
          return this.get('toast').error('Question already added to this section', "Error!", {
            positionClass: 'assessment-toast-error'
          });
        }

        if (this.get('relationName')) {
          var questionsRecord = this.get('store').createRecord(this._getModelName(this.get('relationName')), {
            question: this.get('selectedQuestion'),
            question_order: this.get('questions.length') + 1,
            sort_order: this.get('questions.length') + 1,
            section: this.get('sectionModel')
          });
          questionsRecord.save().then(function () {
            _this.set('questions.question', _this.get('selectedQuestion'));

            _this.get('toast').success("Question added", '', {
              positionClass: 'assessment-toast-error'
            });
          }).catch(function (err) {
            if (err.errors) {
              questionsRecord.deleteRecord();
              err.errors.forEach(function (e) {
                _this.get('toast').error(e.detail, 'Error', {
                  positionClass: 'assessment-toast-error'
                });
              });
            } else {
              _this.get('toast').error('Please try after some time', 'Something went wrong', {
                positionClass: 'assessment-toast-error'
              });
            }
          }).finally(function () {
            _this.set('selectedQuestion', null);
          });
        }
      }
    }),
    isRelationsLoaded: Ember.computed('sectionModel.{sectionSkills,sectionQuestions}.isFulfilled', function () {
      return this.get('sectionModel.sectionSkills.isFulfilled') && this.get('sectionModel.sectionQuestions.isFulfilled') || this.get('selectedAS.isExtraAs');
    }),
    questions: Ember.computed('isRelationsLoaded', '{sectionCombined,sectionQuestions,sectionSkills}.length', function () {
      if (this.get('sectionSkills.length')) {
        this.set('relationName', 'sectionSkills');
        return this.get('sectionSkills');
      }

      if (this.get('sectionQuestions.length')) {
        this.set('relationName', 'sectionQuestions');
        return this.get('sectionQuestions');
      }

      this.set('relationName', 'combined');

      if (this.get('sectionCombined.length')) {
        return this.get('sectionCombined').sortBy('sort_order');
      }

      return this.get('sectionCombined');
    }),
    _totalQuestionsAddedObserver: Ember.observer('questions.@each', function () {
      this.getTotalQuestions();
    }),
    isQuestionsAvailable: Ember.computed('sectionModel', function () {
      var sectionTypeID = this.get('sectionModel.type_id');
      return sectionTypeID != 7 && sectionTypeID != 15 && sectionTypeID != 19 && sectionTypeID != 22 && sectionTypeID != 21;
    }),
    _questionTypeObserver: Ember.observer('sectionModel.questionType', function () {
      if (this.get('relationName') == 'sectionQuestions') {
        return this.get('sectionModel.questionType.id') == 1 ? this.set('showError', true) : this.set('showError', false);
      }

      if (this.get('relationName') == 'sectionSkills') {
        return this.get('sectionModel.questionType.id') == 0 ? this.set('showError', true) : this.set('showError', false);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('questionTypeList', Ember.A([{
        id: 0,
        type: 'question',
        name: 'Questions',
        title: 'questions'
      }, {
        id: 1,
        type: 'skill',
        name: 'Skills',
        title: 'skills'
      }]));

      if (this.get('questions.firstObject._internalModel.modelName') == 'section-skill') {
        this.set('sectionModel.questionType', this.get('questionTypeList.lastObject'));
      } else {
        this.set('sectionModel.questionType', this.get('questionTypeList.firstObject'));
      }

      if (this.get('assessmentModel.freezeAssessment')) {
        this.toggleProperty('toggleNag');
      }

      this.getTotalQuestions();
    },
    getTotalQuestions: function getTotalQuestions() {
      if (this.get('questions.length')) {
        var qCount = this.get('questions').map(function (question) {
          return +question.get('q_count') || 1;
        });
        this.set('totalQuestionsAdded', _npmLodash.default.sum(qCount));
      }
    },
    _getModelName: function _getModelName(type) {
      if (type == 'sectionQuestions') {
        return 'sectionQuestion';
      }

      if (type == 'combined') {
        return 'sectionCombined';
      }

      if (type == 'sectionSkills') {
        return 'sectionSkill';
      }
    },
    actions: {
      reorderItems: function reorderItems(draggedModel) {
        var _this2 = this;

        draggedModel.forEach(function (question, index) {
          if (question.get('question_order')) {
            question.set('question_order', index + 1);
          } else {
            question.set('q_order', index + 1);
          }
        });
        this.set('questions', draggedModel);
        this.get('questions').forEach(function (question) {
          if (question.get('hasDirtyAttributes')) {
            _this2.set('isReodering', true);

            return question.save().catch(function (error) {
              if (error.errors) {
                error.errors.forEach(function (e) {
                  _this2.get('toast').error(e.detail, 'Error', {
                    positionClass: 'assessment-toast-error'
                  });
                });
              } else {
                _this2.get('toast').error('Please try after some time', 'Something went wrong', {
                  positionClass: 'assessment-toast-error'
                });
              }
            }).finally(function () {
              _this2.set('questions', draggedModel);

              _this2.set('isReodering', false);
            });
          }
        });
      },
      removeQuestion: function removeQuestion(index, question) {
        var _this3 = this;

        if (this.get('questions.length') < 2 && parseInt(this.get('sectionModel.sectionType.id'), 10) != 10) {
          return this.get('toast').error('You need to add atleast 1 question to this assessment Atleast one question is required. You may add another question to delete this.', '', {
            positionClass: 'assessment-toast-error'
          });
        }

        question.destroyRecord().then(function () {
          _this3.get('toast').success('Question deleted', '', {
            positionClass: 'assessment-toast-error'
          });
        }).catch(function () {
          _this3.get('toast').error("Error!", "Something went wrong.", {
            positionClass: 'assessment-toast-error'
          });
        });
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});