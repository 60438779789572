define("recruit/controllers/recruiter/questions/index", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var filterObjectList = [{
    key: 'skills',
    modelName: 'skill',
    content: 'skillModel',
    type: 'skill'
  }, {
    key: 'created_by',
    modelName: 'user',
    content: 'users',
    type: 'created_by'
  }, {
    key: 'type',
    modelName: '',
    content: 'availableSections',
    type: 'type'
  }];

  var _default = Ember.Controller.extend({
    queryParams: ['searchText', 'skills', 'created_by', 'type'],
    searchText: null,
    skills: Ember.A([]),
    created_by: Ember.A([]),
    type: Ember.A([]),
    setFilterParams: function setFilterParams() {
      var _this = this;

      return filterObjectList.forEach(function (obj) {
        if (obj.key == 'type' && _this.get('transition.queryParams.type')) {
          JSON.parse(_this.get('transition.queryParams.type')).forEach(function (t) {
            var qtype = _this.get('availableSections').findBy('name', t === "multiple choice" ? "Objective" : t.capitalize());

            if (qtype) {
              _this.initializeParams('type', qtype);
            }
          });
        } else if (_this.get("transition.queryParams.".concat(obj.key))) {
          return JSON.parse(_this.get("transition.queryParams.".concat(obj.key))).forEach(function (id) {
            var value = _this.get("".concat(obj.content)).findBy('id', id);

            if (!value) {
              _this.get('store').findRecord("".concat(obj.modelName), id).then(function (response) {
                if (response) {
                  _this.initializeParams("".concat(obj.type), response);

                  if (_this.get('customListFilters.length')) {
                    _this.set('appliedListFilters', Ember.Object.create(_npmLodash.default.groupBy(_this.get('customListFilters'), 'type')));
                  }
                }
              });
            } else {
              return _this.initializeParams("".concat(obj.type), value);
            }
          });
        }
      });
    },
    initializeParams: function initializeParams(type, value) {
      return this.get('filters').pushObject(Ember.Object.create({
        type: "".concat(type),
        id: value.get('id'),
        label: value.get('name')
      }));
    }
  });

  _exports.default = _default;
});