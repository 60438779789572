define("recruit/routes/logout", ["exports", "ember-simple-auth/mixins/application-route-mixin", "recruit/config/environment"], function (_exports, _applicationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service('currentUser'),
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('session.isAuthenticated')) {
        this.set('logoutUrl', this.get('currentUser.me.organization.logoutUrl'));
        this.get('session').invalidate().then(function () {
          if (localStorage.getItem('is_tv_azure_login')) {
            localStorage.removeItem('is_tv_azure_login');
            var authContext = new AuthenticationContext({
              clientId: _environment.default.APP.AZURE_CLIENT_ID,
              postLogoutRedirectUri: location.origin + '/login'
            });
            authContext.logOut();
          } else {
            window.location.replace(_this.get('logoutUrl'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});