define("recruit/adapters/candidate-auth/log", ["exports", "recruit/adapters/candidate-auth/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    pathForType: function pathForType() {
      return 'request';
    }
  });

  _exports.default = _default;
});