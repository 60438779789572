define("recruit/components/events/panel-match-popup-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['seven wide column text-right'],
    attributeBindings: ['customStyle:style'],
    customStyle: Ember.computed('threshold', function () {
      if (this.get('matchedPanels.length')) {
        return new Ember.String.htmlSafe("padding: 3px; position: relative; left: ".concat(this.get('matchedPanels.length') - 1, "0px;"));
      }

      return new Ember.String.htmlSafe("padding: 3px;");
    })
  });

  _exports.default = _default;
});