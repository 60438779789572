define("recruit/components/panel/set-availability/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    model: null,
    occurrences: Ember.A([]),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('model')) {
        return this.get('model.availablityEvents').then(function (events) {
          events.set('isFresh', false);
          return events.forEach(function (event) {
            return _this.get('occurrences').pushObject(event);
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    actions: {
      calendarAddOccurrence: function calendarAddOccurrence(occurrence) {
        var _this2 = this;

        this.get('occurrences').forEach(function (occurrence) {
          if (occurrence.get('isFresh')) {
            _this2.get('occurrences').removeObject(occurrence);
          }
        }); // let model = this.get('store').createRecord('user-calendar', {
        //   event   : occurrence.get('title') || 'Available',
        //   from    : occurrence.get('startsAt'),
        //   to      : occurrence.get('endsAt'),
        //   type    : 2
        // });
        // return this.get('occurrences').pushObject(model);

        occurrence.set('isFresh', true);
        return this.get('occurrences').pushObject(occurrence);
      },
      calendarUpdateOccurrence: function calendarUpdateOccurrence(occurrence, properties) {
        var _this3 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this3, _this3._updateEvent, occurrence, properties, resolve, reject, 300);
        });
      },
      calendarRemoveOccurrence: function calendarRemoveOccurrence(occurrence) {
        var _this4 = this;

        return occurrence.destroyRecord().then(function () {
          _this4.get('toast').success('', 'Availablity removed');

          return _this4.get('occurrences').removeObject(occurrence);
        });
      },
      calendarNavigateWeek: function calendarNavigateWeek() {},
      showEvent: function showEvent() {},
      revertModel: function revertModel(model) {
        if (model.get('isFresh')) {
          this.get('occurrences').removeObject(model);
        } else {
          model.rollbackAttributes();
        }
      },
      updateOccurrence: function updateOccurrence(model, occurrence) {
        this.get('occurrences').removeObject(occurrence);
        model.set('isFresh', false);
        this.get('occurrences').pushObject(model);
      }
    }
  });

  _exports.default = _default;
});