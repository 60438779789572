define("recruit/models/reports/panel", ["exports", "ember-data", "recruit/models/reports/counts"], function (_exports, _emberData, _counts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _counts.default.extend({
    'external_id': _emberData.default.attr('string'),
    'username': _emberData.default.attr('string')
  });

  _exports.default = _default;
});