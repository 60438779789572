define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-actions", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    user: Ember.inject.service('current-user'),
    canUpdateAssessment: Ember.computed(function () {
      return this.get('user.me.organization.hasAssessmentCreate');
    }),
    actions: {
      importCandidates: function importCandidates() {
        this.get('router').transitionTo('recruiter.assessment.details.import');
      },
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      },
      deleteAssessment: function deleteAssessment() {
        if (this.get('assessment.all.length')) {
          this.send('toggleModal', 'isCantDeleteModal');
        } else {
          this.send('toggleModal', 'isDeleteModal');
        }
      }
    }
  });

  _exports.default = _default;
});