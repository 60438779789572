define("recruit/components/live-session/live-session-attendees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    userRole: Ember.inject.service(),
    actions: {
      remindAll: function remindAll(attendees) {
        this.sendAction('remindAll', attendees);
      },
      managePanel: function managePanel(model) {
        this.sendAction('managePanel', model);
      }
    }
  });

  _exports.default = _default;
});