define("recruit/templates/components/answer-views/live-webrtc-videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fCnQiEZy",
    "block": "{\"symbols\":[\"video\",\"index\"],\"statements\":[[6,\"div\"],[9,\"style\",\"\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"videos\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui buttons\"],[3,\"action\",[[19,0,[]],\"selectVideo\",[19,1,[]]]],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"ui button \",[25,\"if\",[[25,\"eq\",[[20,[\"selectedVideo\"]],[19,1,[]]],null],\"black disabled\",\"green\"],null]]]],[7],[1,[25,\"inc\",[[19,2,[]]],null],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1,2]},null],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"wait\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"video-flowplayer\",null,[[\"video\",\"customClass\"],[[20,[\"selectedVideo\",\"liveWebrtcRecording\"]],\"video-container padding-sm margin-top-thirty align-center flowplayer fp-outlined fp-slim\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/answer-views/live-webrtc-videos.hbs"
    }
  });

  _exports.default = _default;
});