define("recruit/templates/components/assessment-helpers/assessment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXzuXjhT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"model\",\"title\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n    \"],[6,\"p\"],[10,\"class\",[26,[\"margin-no \",[25,\"if\",[[20,[\"boldTitle\"]],\"text-weight-medium\",\"text-weight-normal\"],null],\" \",[25,\"if\",[[20,[\"textColor\"]],[20,[\"textColor\"]],\"text-black\"],null],\" ellipsis\"]]],[7],[1,[20,[\"model\",\"title\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"data-tooltip\",[20,[\"model\",\"external_id\"]],null],[9,\"data-position\",\"bottom left\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"ellipsis text-grey text-size-small text-weight-light\"],[7],[1,[20,[\"model\",\"external_id\"]],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/assessment-helpers/assessment-details.hbs"
    }
  });

  _exports.default = _default;
});