define("recruit/templates/components/reports/graphs/time-range/time-per-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lAo3d4KP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"style\",\"text-align: center; background: #2B2B2B; padding: 15px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[10,\"style\",[26,[\"padding-bottom: 15px; \",[25,\"if\",[[20,[\"hideGraph\"]],\"padding-bottom: 0 !important; margin-bottom: 0 !important;\"],null],\";\"]]],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"margin-bottom-ten\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"float-left text-weight-normal text-size-medium text-white margin-bottom-five\"],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"calendar outline icon\"],[7],[8],[0,\"\\n        \"],[6,\"span\"],[7],[0,\"Time Taken per month\"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"float-right text-white margin-bottom-five\"],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"calendar alternate outline icon text-size-medium\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"style\",\"border-bottom:1px solid green; clear: both;\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n\"],[4,\"unless\",[[20,[\"hideGraph\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"chartOptions\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[7],[0,\"\\n        \"],[1,[25,\"graphs/stacked-bar-chart\",null,[[\"chartOptions\",\"selectedRow\"],[[20,[\"chartOptions\"]],[20,[\"selectedRow\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/graphs/time-range/time-per-month.hbs"
    }
  });

  _exports.default = _default;
});