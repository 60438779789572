define("recruit/components/live-session/candidate-sidebar", ["exports", "recruit/components/panel/evaluation-strip"], function (_exports, _evaluationStrip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _evaluationStrip.default.extend({
    hideEvalButtons: Ember.computed('selectedAssc.section.sectionParameters.length', 'selectedAssc.section.evaluationFormUrl', function () {
      return !this.get('selectedAssc.section.sectionParameters.length') && !this.get('selectedAssc.section.evaluationFormUrl');
    }),
    actions: {
      toggleSidebar: function toggleSidebar(property) {
        this.sendAction('toggleSidebar', property);
      },
      toggleEvaluationSidebar: function toggleEvaluationSidebar(property) {
        this.sendAction('toggleEvaluationSidebar', property);
      }
    }
  });

  _exports.default = _default;
});