define("recruit/models/live-attendee-flash", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    flashJoinUrl: _emberData.default.attr('string'),
    liveAttendee: _emberData.default.belongsTo('live-attendee')
  });

  _exports.default = _default;
});