define("recruit/templates/components/reports/table/time-range/time-per-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2uBiq5eS",
    "block": "{\"symbols\":[\"t\",\"body\",\"index\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segment padding-no\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"style\",\"overflow-x: auto;\"],[9,\"class\",\"bg-white\"],[7],[0,\"\\n\"],[4,\"light-table\",[[20,[\"table\"]]],[[\"height\",\"width\",\"tableClassNames\",\"tableActions\"],[[25,\"unless\",[[20,[\"hideGraph\"]],\"calc(60vh - 200px)\",\"calc(100vh - 200px)\"],null],\"100%\",\"ui table border-none report-table\",[25,\"hash\",null,[[\"searchTable\",\"toggleGraph\"],[[25,\"action\",[[19,0,[]],\"searchTable\"],null],[25,\"action\",[[19,0,[]],\"toggleGraph\"],null]]]]]],{\"statements\":[[0,\"      \"],[1,[25,\"component\",[[19,1,[\"head\"]]],[[\"onColumnClick\",\"fixed\"],[[25,\"action\",[[19,0,[]],\"onColumnClick\"],null],true]]],false],[0,\"\\n\\n\"],[4,\"component\",[[19,1,[\"body\"]]],[[\"multiSelect\",\"searchTable\",\"onRowClick\",\"onScrolledToBottom\"],[false,\"searchTable\",[25,\"action\",[[19,0,[]],\"onRowClick\"],null],[25,\"action\",[[19,0,[]],\"onScrolledToBottom\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[4,\"component\",[[19,2,[\"loader\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"pageLoadRow\"]]],null,{\"statements\":[[0,\"              \"],[1,[18,\"table-loading-placeholder\"],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/table/time-range/time-per-month.hbs"
    }
  });

  _exports.default = _default;
});