define("recruit/components/helpers/row-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkbox-area'],
    checked: Ember.computed('row.selected', function () {
      return this.get('row.selected');
    }),
    click: function click(event) {
      if (this.get('checked')) {
        this.sendAction('tableActions.onRowClick', this.get('row'), event);
      } else {
        this.sendAction('tableActions.rowChecked', this.get('row'));
      }

      return false;
    }
  });

  _exports.default = _default;
});