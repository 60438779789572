define("recruit/models/section-combined", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "q_count": _emberData.default.attr('number'),
    'section': _emberData.default.belongsTo('section'),
    "skill": _emberData.default.belongsTo('skill'),
    // "skill_id"    : DS.attr('number'),
    // "question_id" : DS.attr('number'),
    "question": _emberData.default.belongsTo('question'),
    "section_id": _emberData.default.attr('number'),
    "sort_order": _emberData.default.attr('number'),
    "created_by": _emberData.default.attr('number'),
    "updated_by": _emberData.default.attr('number'),
    q_order: Ember.computed.alias('sort_order')
  });

  _exports.default = _default;
});