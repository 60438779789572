define("recruit/templates/recruiter/candidate/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KoraZXF8",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Assessment Candidates\"],null],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"margin-top-hundred\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[9,\"style\",\"box-shadow:2px 2px 2px rgba(0,0,0,0.3); padding:0; margin-top:15px;\"],[7],[0,\"\\n      \"],[1,[25,\"filters/master-filter\",null,[[\"searchText\",\"appliedFilters\",\"objectType\",\"masterFilters\",\"masterColumns\",\"columns\",\"params\",\"onFilterChange\",\"tableType\",\"filters\",\"quickFilters\"],[[20,[\"searchText\"]],[20,[\"appliedFilters\"]],\"ac\",[20,[\"masterFilters\"]],[20,[\"model\",\"columnMaster\"]],[20,[\"columns\"]],[20,[\"params\"]],\"onFilterChange\",\"r-c\",[20,[\"filters\"]],[20,[\"quickFilters\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"twelve wide column\"],[9,\"style\",\"height: calc(100vh - 120px);\"],[7],[0,\"\\n      \"],[1,[25,\"model-table/recruiter/candidate\",null,[[\"quickFilters\",\"searchText\",\"appliedFilters\",\"rowChecked\"],[[20,[\"quickFilters\"]],[20,[\"searchText\"]],[20,[\"appliedFilters\"]],\"rowChecked\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/candidate/index.hbs"
    }
  });

  _exports.default = _default;
});