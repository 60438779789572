define("recruit/templates/components/spinkit-folding-cube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zA6/jNfO",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n      \"],[6,\"div\"],[9,\"class\",\"sk-folding-cube\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-cube1 sk-cube\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-cube2 sk-cube\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-cube4 sk-cube\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sk-cube3 sk-cube\"],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/spinkit-folding-cube.hbs"
    }
  });

  _exports.default = _default;
});