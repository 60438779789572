define("recruit/components/helpers/date-range-view", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hoverDateFormat: "DD MMM YYYY | hh:mm A",
    dateValue: Ember.computed('value', function () {
      return this.get('value') ? this.get('value') : null;
    }),
    endDate: Ember.computed('row.content', function () {
      return this.get('row.content.end_date');
    }),
    dateFormat: Ember.computed('column.dateFormat', function () {
      return this.get('column.dateFormat') ? this.get('column.dateFormat') : 'DD-MM-YY | hh:mm A';
    }),
    formattedTime: Ember.computed('dateValue', 'dateFormat', function () {
      if (this.get('dateValue')) {
        return (0, _moment.default)(this.get('dateValue')).format(this.get('dateFormat'));
      } else {
        return "Not added";
      }
    }),
    didInsertElement: function didInsertElement() {
      Ember.$('[data-html]').popup({
        position: 'bottom center',
        inline: false
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('[data-html]').popup('destroy');
    }
  });

  _exports.default = _default;
});