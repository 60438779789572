define("recruit/templates/components/graphs/time-range-conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JOwbZdui",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments\"],[9,\"style\",\"background: #FFF !important; border: none; height: 30vh; box-shadow: none;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"id\",\"highcharts-container\"],[9,\"class\",\"ui segment\"],[9,\"style\",\"width: 100%; text-align: center; background: #FFF; clear: both; height: 30vh;\"],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/graphs/time-range-conversion.hbs"
    }
  });

  _exports.default = _default;
});