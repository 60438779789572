define("recruit/templates/components/helpers/see-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0fuv/juY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"shownFullText\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[7],[1,[18,\"content\"],true],[8],[0,\"\\n  \"],[11,1,[[19,0,[]]]],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"cursor-pointer text-blue text-size-small\"],[3,\"action\",[[19,0,[]],\"toggleText\"]],[7],[0,\"See less\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"span\"],[7],[1,[18,\"modifiedContent\"],true],[1,[25,\"if\",[[25,\"and\",[[25,\"lt\",[[20,[\"content\",\"length\"]],[20,[\"length\"]]],null],[20,[\"hasAdditionalContent\"]]],null],\"...\"],null],false],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"isSeeMoreVisible\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"cursor-pointer text-blue text-size-small\"],[3,\"action\",[[19,0,[]],\"toggleText\"]],[7],[0,\"See more\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/see-more.hbs"
    }
  });

  _exports.default = _default;
});