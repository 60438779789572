define("recruit/components/assessment/create-assessment", ["exports", "npm:lodash", "recruit/mixins/assessment-section-settings"], function (_exports, _npmLodash, _assessmentSectionSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_assessmentSectionSettings.default, {
    store: Ember.inject.service(),
    userPermissions: Ember.inject.service('user-permission'),
    _assessmentModelObserver: Ember.observer('assessmentModel.{title,external_id,description}.@each', function () {
      this.get('assessmentModel').save();
    }),
    sectionModel: Ember.computed('currentSlide', 'assessmentModel.sections.isFulfilled', function () {
      return this.get('assessmentModel.sections').objectAt(this.get('currentSlide'));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('assessmentModel.sections.length')) {
        this.send('createNewSection', this.get('assessmentModel'));
      }

      this.set('showJdWarning', this.get('userPermissions.hasResumeMatch'));
    },
    actions: {
      addNewSection: function addNewSection(index) {
        if (!_npmLodash.default.isNil(index)) {
          this.send('createNewSection', this.get('assessmentModel'), index);
        } else {
          this.get('toast').error('Something went wrong', '', {
            positionClass: 'assessment-toast-error'
          });
        }
      },
      createNewSection: function createNewSection(assessmentModel, index) {
        var _this = this;

        var sectionModel = this.get('store').createRecord('lf-adapter/section', {
          id: Math.floor(Date.now() / 1000) //Unix timestamp (in seconds) for adding transition animations to section cards.

        });
        sectionModel.save().then(function (section) {
          assessmentModel.get('sections').insertAt(index, section);
          assessmentModel.save().then(function () {
            _this.set('currentSlide', index ? index : 0);
          }).catch(function () {
            _this.get('toast').error('Could not save assessment section', '', {
              positionClass: 'assessment-toast-error'
            });
          });
        }).catch(function () {
          _this.get('toast').error('Could not add assessment section', '', {
            positionClass: 'assessment-toast-error'
          });
        });
      },
      createAssessment: function createAssessment(assessmentModel, sections) {
        var _this2 = this;

        var showJdWarning = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var sectionObject = [];
        var counter = 0;

        if (!assessmentModel.get('title')) {
          Ember.$('#assessmentTitle').focus();
          return this.get('toast').error('Assessment Title can not be blank', 'Required Field', {
            positionClass: 'assessment-toast-error'
          });
        }

        if (!assessmentModel.get('hasValidJD') && showJdWarning) {
          return this.toggleProperty('showJdAlert');
        }

        sections.forEach(function (section, index) {
          if (!section.get('title')) {
            _this2.set('currentSlide', index);

            return _this2.get('toast').error('Section Title can not be blank', 'Required Field', {
              positionClass: 'assessment-toast-error'
            });
          }

          var secObj = {
            order: index + 1,
            title: section.get('title'),
            section_type_id: section.get('sectionType.id')
          };

          if (section.get('sectionType.id') == 19) {
            if (!section.get('external_id')) {
              return _this2.get('toast').error('Select a versant assessment type', 'Error', {
                positionClass: 'assessment-toast-error'
              });
            }

            secObj.external_id = section.get('external_id');
          }

          if (section.get('sectionType.id') == 21) {
            if (!section.get('externalAssessment')) {
              return _this2.get('toast').error('Select an external assessment type', 'Error', {
                positionClass: 'assessment-toast-error'
              });
            }

            secObj.external_assessment_id = section.get('externalAssessment.id');
          }

          var questionObj = [];
          section.get('assessmentQuestions').then(function (questionsObj) {
            if (!questionsObj.get('length') && section.checkIFQuestionAvailable(section.get('sectionType.id'))) {
              _this2.set('currentSlide', index);

              return _this2.get('toast').error("Add atleast 1 question to '".concat(section.get('title'), "' section"), 'Required Field', {
                positionClass: 'assessment-toast-error'
              });
            } else {
              _this2.setSectionSettings(section.get('sectionType.id'), questionsObj.get('length'), secObj);

              questionsObj.forEach(function (question, index) {
                if (question.get('questionType') == 1) {
                  questionObj.pushObject({
                    question_id: question.get('question_id'),
                    sort_order: index + 1
                  });
                } else {
                  questionObj.pushObject({
                    skill_id: question.get('skill_id'),
                    sort_order: index + 1,
                    q_count: +question.get('qCount')
                  });
                }
              });
              secObj.combined = questionObj;
              sectionObject.pushObject(secObj);
              counter++;

              if (counter == assessmentModel.get('sections.length')) {
                _this2.set('isLoading', true);

                _this2.get('store').createRecord('actions/create-assessment', {
                  externalID: assessmentModel.get('external_id'),
                  title: assessmentModel.get('title'),
                  description: assessmentModel.get('description'),
                  owned_by: _this2.get('userModel.id'),
                  sections: sectionObject,
                  sync: true
                }).save().then(function (res) {
                  _this2.get('toast').success("\"".concat(res.get('title'), "\" has been created."), 'Assessment created', {
                    positionClass: 'assessment-toast-error'
                  });

                  _this2.get('router').transitionTo('recruiter.assessment');
                }).catch(function (error) {
                  if (error.errors) {
                    error.errors.forEach(function (err) {
                      if (err.detail && err.field) {
                        _this2.get('toast').error(err.detail.capitalize(), err.field.toUpperCase(), {
                          positionClass: 'assessment-toast-error'
                        });
                      } else {
                        _this2.get('toast').error(err.detail.capitalize(), "Error!", {
                          positionClass: 'assessment-toast-error'
                        });
                      }
                    });
                  } else {
                    _this2.get('toast').error("Something went wrong", 'Error!', {
                      positionClass: 'assessment-toast-error'
                    });
                  }
                }).finally(function () {
                  _this2.set('isLoading', false);
                });
              }
            }
          });
        });
      },
      reorderItems: function reorderItems(itemModels) {
        this.set('assessmentModel.sections', itemModels);
        this.get('assessmentModel').save();
      },
      deleteSection: function deleteSection(assessmentModel, sectionModel) {
        var _this3 = this;

        if (assessmentModel.get('sections.length') != 1) {
          assessmentModel.get('sections').removeObject(sectionModel);
          return sectionModel.destroyRecord().then(function () {
            return _this3.get('assessmentModel').save().then(function () {
              _this3.get('toast').success('Section deleted', 'Success', {
                positionClass: 'assessment-toast-error'
              });
            }).finally(function () {
              _this3.set('currentSlide', assessmentModel.get('sections.length') - 1);

              _this3.toggleProperty('deleteModal');
            });
          });
        } else {
          this.toggleProperty('deleteModal');
          this.get('toast').error('You need to add atleast 1 section to create assessment', '', {
            positionClass: 'assessment-toast-error'
          });
        }
      },
      toggleModal: function toggleModal(property, sectionModel) {
        if (sectionModel) {
          this.set('sectionToBeDeleted', sectionModel);
        }

        this.toggleProperty(property);
      },
      toggleJD: function toggleJD(property, property2) {
        this.toggleProperty(property);

        if (property2) {
          this.toggleProperty(property2);
        }
      },
      ignoreAndCreate: function ignoreAndCreate(property) {
        if (property) {
          this.toggleProperty(property);
        }

        return this.send('createAssessment', this.get('assessmentModel'), this.get('assessmentModel.sections'), false);
      },
      back: function back(property) {
        this.toggleProperty(property);
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});