define("recruit/components/action-wrapper/live-modal/suggest-panel", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import _ from 'npm:lodash';
  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    morePanel: [],
    isNotify: true,
    isLoading: false,
    users: Ember.computed('s', function () {
      if (this.get('s')) {
        return this.get('store').query('user', {
          s: this.get('s')
        });
      } else {
        return this.get('store').findAll('user');
      }
    }),
    currentPanel: Ember.computed('selectedCandidateList.firstObject.liveAttendees.isFulfilled', function () {
      if (this.get('selectedCandidateList.firstObject.liveAttendees.isFulfilled')) {
        return this.get('selectedCandidateList.firstObject.panels').map(function (panel) {
          return panel.get('user');
        });
      } else {
        return [];
      }
    }),
    actions: {
      removePanel: function removePanel(panel, isDefault) {
        if (isDefault) {
          this.get('currentPanel').removeObject(panel);
        } else {
          this.get('morePanel').removeObject(panel);
        }
      },
      managePanel: function managePanel() {
        var _this = this;

        this.set('isLoading', true);
        var panels = [].concat(this.get('currentPanel')).concat(this.get('morePanel'));
        var panelObjects = panels.map(function (p) {
          return {
            email: p.get('email')
          };
        });
        this.get('store').createRecord('actions/live-change-panel', {
          liveSessionID: this.get('selectedCandidateList.firstObject.id'),
          panel: panelObjects
        }).save().then(function () {
          _this.get('toast').success('Panel updated', 'Successful');

          return _this.send('back');
        }).catch(function (err) {
          _this.catchError(err);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});