define("recruit/mixins/assessment-section-settings", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setSectionSettings: function setSectionSettings(sectionTypeId) {
      var totalQuestions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var obj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (sectionTypeId) {
        switch (sectionTypeId) {
          // case "3"  : this.setFormProperties(obj, totalQuestions);
          //             break;
          case "4":
            this.setEssayProperties(obj, totalQuestions);
            break;

          case "5":
            this.setInterviewProperties(obj);
            break;

          case "6":
            this.setObjectiveProperties(obj, totalQuestions);
            break;

          case "7":
            this.setLiveProperties(obj, totalQuestions);
            break;
          // case "8"  : this.setPostProperties(obj, totalQuestions);
          //             break;
          // case "9"  : this.setPromoProperties(obj, totalQuestions);
          //             break;

          case "10":
            this.setTypingProperties(obj);
            break;
          // case "11" : this.setInstructionsProperties(obj, totalQuestions);
          //             break;
          // case "12" : this.setCustomPageProperties(obj, totalQuestions);
          //             break;
          // case "13" : this.setMixedProperties(obj, totalQuestions);
          //             break;
          // case "14" : this.setFaceToFaceProperties(obj, totalQuestions);
          //             break;
          // case "15" : this.setEvaluationProperties(obj, totalQuestions);
          //             break;
          // case "16" : this.setWalkinProperties(obj, totalQuestions);
          //             break;

          case "17":
            this.setCodeProperties(obj, totalQuestions);
            break;
          // case "18" : this.setIntegrationProperties(obj, totalQuestions);
          //             break;

          case "19":
            this.setExternalASProperties(obj);
            break;
          // case "20" : this.setVitaPoweredProperties(obj, totalQuestions);
          //             break;

          case "21":
            this.setExternalASProperties(obj); //for external assessments; configurations are same as of versant's.

            break;

          case "22":
            this.setVMProperties(obj);
            break;

          default:
            this.setDefaultProperties(obj);
            break;
        }
      }
    },
    setObjectiveProperties: function setObjectiveProperties(obj, totalQuestions) {
      Ember.set(obj, 'positive_mark', 1);
      Ember.set(obj, 'negative_mark', 0);
      Ember.set(obj, 'isPlaylist', true);
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'is_proctor', true);
      Ember.set(obj, 'isSkipAllowed', true);
      Ember.set(obj, 'is_screenshare', false);
      Ember.set(obj, 'max_section_time', _moment.default.duration(totalQuestions * 1, 'minutes').asSeconds());
    },
    setInterviewProperties: function setInterviewProperties(obj) {
      Ember.set(obj, 'isRetake', false);
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'is_proctor', false);
      Ember.set(obj, 'isPlaylist', false);
      Ember.set(obj, 'isSkipAllowed', true);
      Ember.set(obj, 'questionDisplayTime', 10);
      Ember.set(obj, 'max_question_time', _moment.default.duration(3, 'minutes').asSeconds());
    },
    setTypingProperties: function setTypingProperties(obj) {
      Ember.set(obj, 'is_proctor', true);
      Ember.set(obj, 'isPlaylist', false);
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'is_screenshare', false);
      Ember.set(obj, 'max_section_time', _moment.default.duration(5, 'minutes').asSeconds());
    },
    setEssayProperties: function setEssayProperties(obj, totalQuestions) {
      Ember.set(obj, 'isPlaylist', false);
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'is_proctor', true);
      Ember.set(obj, 'isSkipAllowed', true);
      Ember.set(obj, 'is_screenshare', false);
      Ember.set(obj, 'max_section_time', _moment.default.duration(totalQuestions * 15, 'minutes').asSeconds());
    },
    setCodeProperties: function setCodeProperties(obj, totalQuestions) {
      Ember.set(obj, 'is_proctor', true);
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'isPlaylist', false);
      Ember.set(obj, 'isSkipAllowed', true);
      Ember.set(obj, 'is_screenshare', false);
      Ember.set(obj, 'max_section_time', _moment.default.duration(totalQuestions * 15, 'minutes').asSeconds());
    },
    setExternalASProperties: function setExternalASProperties(obj) {
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'isPlaylist', false);
      Ember.set(obj, 'is_proctor', true);
      Ember.set(obj, 'max_section_time', _moment.default.duration(60, 'minutes').asSeconds());
    },
    setVMProperties: function setVMProperties(obj) {
      Ember.set(obj, 'is_proctor', true);
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'isPlaylist', false);
      Ember.set(obj, 'is_screenshare', false);
      Ember.set(obj, 'max_section_time', _moment.default.duration(2, 'hours').asSeconds());
    },
    setLiveProperties: function setLiveProperties(obj) {
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'is_proctor', false);
      Ember.set(obj, 'isPlaylist', false);
      return obj;
    },
    setDefaultProperties: function setDefaultProperties(obj) {
      Ember.set(obj, 'is_proctor', true);
      Ember.set(obj, 'is_review', false);
      Ember.set(obj, 'isPlaylist', false);
      Ember.set(obj, 'isSkipAllowed', true);
      Ember.set(obj, 'is_screenshare', false);
      Ember.set(obj, 'max_section_time', _moment.default.duration(10, 'minutes').asSeconds());
    }
  });

  _exports.default = _default;
});