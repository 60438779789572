define("recruit/templates/components/helpers/table-checkboxs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lX6pohpF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"hasSelection\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"ui-checkbox\",null,[[\"name\",\"checked\",\"title\",\"class\",\"onChange\"],[\"checkMaster\",[20,[\"isChecked\"]],\"Select all\",\"table-head-checkbox\",[25,\"action\",[[19,0,[]],\"deselectAll\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"ui-checkbox\",null,[[\"name\",\"checked\",\"title\",\"class\",\"onChange\"],[\"checkMaster\",[20,[\"isChecked\"]],\"Select all\",\"table-head-checkbox\",[25,\"action\",[[19,0,[]],\"selectAll\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/table-checkboxs.hbs"
    }
  });

  _exports.default = _default;
});