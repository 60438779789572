define("recruit/templates/panel/candidate/evaluate/tech-ios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+uqP4CeI",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"forms/tech-ios\",null,[[\"panelCandidate\",\"isForm\",\"assessmentSection\"],[[20,[\"model\"]],\"true\",[20,[\"model\",\"assessmentSectionCandidate\",\"assessmentSection\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/panel/candidate/evaluate/tech-ios.hbs"
    }
  });

  _exports.default = _default;
});