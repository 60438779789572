define("recruit/data/filters/r-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 5712,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Assessment Reference ID",
      "isRemote": false,
      "objectType": "a",
      "queryTemplate": "({{condition}} external_id{{exact}}{{value}})"
    }
  }, {
    "id": 5713,
    "type": "filter/master",
    "attributes": {
      "type": "list",
      "field": "Assessment Owner",
      "model": "user",
      "isRemote": true,
      "modelQuery": {
        "roles": "Recruiter"
      },
      "objectType": "a",
      "queryTemplate": "(owned_by:{{value}})"
    }
  }, {
    "id": 5714,
    "type": "filter/master",
    "attributes": {
      "type": "text",
      "field": "Assessment Title",
      "isRemote": false,
      "objectType": "a",
      "queryTemplate": "({{condition}} title{{exact}}{{value}})"
    }
  }];
  _exports.default = _default;
});