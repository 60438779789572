define("recruit/components/events/assessment-view", ["exports", "recruit/mixins/g-actions", "recruit/config/environment"], function (_exports, _gActions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    isDevelopment: _environment.default.isDevelopment,
    hideRecruitersListView: Ember.computed('userRole.currentRole', function () {
      return this.get('userRole.currentRole') == 'Recruiter' && this.get('currentUser.me.id') != this.get('eventModel.owned_by.id');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.initializeProperty();
    },
    initializeProperty: function initializeProperty() {
      this.setProperties({
        'isAssessmentDelete': false,
        'selectedAssessment': null,
        'selectedRecruiter': null,
        'isRecruiterDelete': false,
        'isLoading': false,
        'value': null
      });
    },
    listSource: Ember.computed('userRole.currentRole', 'eventModel.eventAssessmentsList.length', 'eventModel.eventAssessmentsList.[]', function () {
      return this.get('eventModel.eventAssessmentsList');
    }),
    actions: {
      redirectTo: function redirectTo(path, model, canViewAssessment) {
        if (this.get('isAssessmentDelete')) {
          return false;
        }

        if (canViewAssessment) {
          this.get('router').transitionTo(path, model, {
            queryParams: {
              assessmentFilters: JSON.stringify({
                id: this.get('eventModel.id'),
                type: 'event',
                label: this.get('eventModel.title')
              })
            }
          });
        }
      },
      toggleModal: function toggleModal(property, item) {
        //item is an object {key: [property stored current selection], value}
        if (item) {
          this.set(item.key, item.value);
        }

        this.toggleProperty(property);
        return false;
      },
      remove: function remove(togglableProperty, item) {
        var _this = this;

        //item is an object {key: [property stored current selection], value}
        var model = item.value;
        this.set('isLoading', true);
        model.destroyRecord().then(function () {
          return _this.get('eventModel').reloadEventRelations();
        }).then(function () {
          if (togglableProperty == 'isRecruiterDelete') {
            _this.get('toast').success("Recruiter Removed");
          } else if (togglableProperty == "isAssessmentDelete") {
            _this.get('toast').success("Assessment Removed");
          }

          _this.send('toggleModal', togglableProperty, {
            key: item.key,
            value: null
          });
        }).catch(function (e) {
          return _this.catchError(e);
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      },
      getList: function getList(assessments) {
        this.set('listSource', assessments);
      },
      clearSearchText: function clearSearchText() {
        this.set('value', null);
      },
      invite: function invite(values, relationType, actionType) {
        var isEventActions = {
          key: 'isEventActions',
          value: true,
          type: 'flag'
        };
        var event = {
          key: 'events',
          value: this.get('eventModel'),
          type: 'value'
        };
        this.send('routeActions', values, relationType, actionType, isEventActions, event);
      }
    }
  });

  _exports.default = _default;
});