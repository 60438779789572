define("recruit/models/actions/create-assessment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    externalID: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    templateID: _emberData.default.attr('number'),
    owned_by: _emberData.default.attr('number'),
    sections: _emberData.default.attr(),
    sync: _emberData.default.attr('boolean'),
    assessment: _emberData.default.belongsTo('assessment')
  });

  _exports.default = _default;
});