define("recruit/components/recruiter-view/candidate/assessment-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['padding-md section-strip horizontal-scroll'],
    actions: {
      setAssc: function setAssc() {
        this.sendAction('setAssc');
      }
    }
  });

  _exports.default = _default;
});