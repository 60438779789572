define("recruit/adapters/user", ["exports", "recruit/adapters/current-user"], function (_exports, _currentUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _currentUser.default.extend({
    namespace: 'models'
  });

  _exports.default = _default;
});