define("recruit/components/live-session/panel-attendees-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    userRole: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      remindAll: function remindAll(panelCandidate) {
        var _this = this;

        this.get('store').createRecord('actions/remind-panel', {
          isNotify: this.get('isNotify'),
          panel: [{
            id: panelCandidate.get('panel.id')
          }]
        }).save().then(function () {
          return _this.get('toast').success('Reminder sent', 'Successful');
        }).catch(function (e) {
          if (e.errors.length) {
            e.errors.forEach(function (error) {
              _this.get('toast').error(error.detail);
            });
          } else {
            _this.get('toast').error('Please try some time later', 'Something went wrong');
          }
        });
      },
      viewEvaluation: function viewEvaluation(panelCandidateID) {
        localStorage.setItem('panelCandidateID', panelCandidateID);
        this.get('router').transitionTo('recruiter.candidate.details.assessment-section.evaluations', this.get('model.assessmentSectionCandidate.assessmentCandidate.candidate.id'), this.get('model.assessmentSectionCandidate.id'));
      }
    }
  });

  _exports.default = _default;
});