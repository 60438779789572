define("recruit/models/custom-field-master", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('number'),
    sort_order: _emberData.default.attr('number'),
    label: _emberData.default.attr('string'),
    key: _emberData.default.attr('string'),
    data_type: _emberData.default.attr('string'),
    value: _emberData.default.attr(),
    is_report: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});