define("recruit/templates/components/nag/nag-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ac9aPXFX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"liquid-if\",[[20,[\"open\"]]],[[\"class\"],[\"accordion\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"nag-alert\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n\"],[4,\"if\",[[20,[\"closable\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"float-right cursor-pointer\"],[3,\"action\",[[19,0,[]],\"toggle\",\"open\"]],[7],[0,\"\\n        \"],[1,[25,\"fa-icon\",[\"times\"],[[\"class\"],[\"text-size-normal\"]]],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/nag/nag-alert.hbs"
    }
  });

  _exports.default = _default;
});