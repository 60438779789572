define("recruit/helpers/get-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getValue = getValue;
  _exports.default = void 0;

  function getValue(object) {
    if (object[0] < 10) {
      return "0" + object[0];
    } else {
      return object[0];
    }
  }

  var _default = Ember.Helper.helper(getValue);

  _exports.default = _default;
});