define("recruit/components/recruiter-view/assessment/details/edit-notification-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('mailTemplateTypes', this.createTemplateArray('mail'));
      this.set('smsTemplateTypes', Ember.A([Ember.Object.create({
        type_id: 901,
        type: 'sms',
        value: 'private invite'
      }), Ember.Object.create({
        type_id: 904,
        type: 'sms',
        value: 'public invite'
      })]));
      this.set('lastRange', null);
    },
    createTemplateArray: function createTemplateArray(type) {
      return Ember.A([Ember.Object.create({
        type_id: 210,
        type: type,
        value: 'invite'
      }), //section invite
      Ember.Object.create({
        type_id: 211,
        type: type,
        value: 'reminder'
      }), //section reminder
      Ember.Object.create({
        type_id: 203,
        type: type,
        value: 'selection'
      }), Ember.Object.create({
        type_id: 204,
        type: type,
        value: 'rejection'
      }), Ember.Object.create({
        type_id: 209,
        type: type,
        value: 'extension'
      }), Ember.Object.create({
        type_id: 202,
        type: type,
        value: 'complete'
      })]);
    },
    notificationTemplates: Ember.computed('model', 'model.templates.isFulfilled', function () {
      if (this.get('model.templates.length')) {
        return this.get('model.templates').map(function (template) {
          return template;
        });
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(this, function () {
        Ember.$('.menu.item').tab();
        Ember.$('.ui.sidebar').sidebar({
          context: '.showTags'
        }).sidebar('setting', 'transition', 'overlay');

        _this.set('selectedTemplate', _this.get('mailTemplateTypes.firstObject'));
      });
    },
    actions: {
      copyLink: function copyLink(text) {
        this.get('toast').success(text.text, 'Copied to clipboard');
        this.send('toggleSideBar');
      },
      toggleSideBar: function toggleSideBar() {
        Ember.$('.ui.sidebar').sidebar('toggle');
      },
      setTemplateType: function setTemplateType(type) {
        this.set('selectedTemplate', type);
      },
      back: function back() {
        window.history.back();
      },
      save: function save(template) {
        var _this2 = this;

        this.set('isLoading', true);
        this.get('store').createRecord('notification-template', {
          type_id: template.get('type_id'),
          content: template.get('content'),
          subject: template.get('subject'),
          assessment: this.get('model')
        }).save().then(function () {
          _this2.get('toast').success('Successfully saved');
        }).catch(function (e) {
          if (e.errors.length) {
            e.errors.forEach(function (error) {
              _this2.get('toast').error(error.detail);
            });
          } else {
            _this2.get('toast').error('Please try some time later', 'Something went wrong');
          }
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});