define("recruit/adapters/event-assessment", ["exports", "recruit/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'models',
    pathForType: function pathForType() {
      return 'event-assessment';
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType && requestType == "query" && query && query.expand && query.expand.includes('assessmentExpanded')) {
        return this._super.apply(this, arguments);
      } else {
        return this._super.apply(this, arguments) + '?expand=assessmentExpanded';
      }
    }
  });

  _exports.default = _default;
});