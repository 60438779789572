define("recruit/components/graphs/pie-semi-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this.drawChart();
    },
    drawChart: function drawChart() {
      Ember.$('#pie-chart-container').highcharts({
        chart: {
          height: 200,
          plotBorderWidth: 0,
          plotShadow: false,
          spacingTop: -5
        },
        title: {
          text: this.get('pieData.firstObject.y') + ':' + this.get('pieData.lastObject.y'),
          align: 'center',
          verticalAlign: 'middle',
          y: 5
        },
        credits: {
          enabled: false
        },
        subtitle: {
          useHTML: true,
          verticalAlign: 'middle',
          x: 5,
          y: 40
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '60%'],
            states: {
              hover: {
                brightness: 0
              }
            }
          }
        },
        series: [{
          type: 'pie',
          name: 'Conversion Ratio',
          innerSize: '65%',
          data: this.get('pieData')
        }]
      });
    }
  });

  _exports.default = _default;
});