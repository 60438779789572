define("recruit/components/status-helpers/evaluation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    statusColor: Ember.computed('value', function () {
      var value = this.get('value');

      if (value) {
        switch (value.toLowerCase()) {
          case 'pending':
            return 'grey';

          case 'selected':
            return 'green';

          case 'hold':
            return 'yellow';

          case 'rejected':
            return 'red';

          case 'evaluated':
            return 'blue';

          default:
            return 'grey';
        }
      }
    })
  });

  _exports.default = _default;
});