define("recruit/components/reports/helpers/time-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      setGraphFilter: function setGraphFilter(value) {
        this.set('selectedGraphFilter', value);
      }
    }
  });

  _exports.default = _default;
});