define("recruit/models/user-calendar", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    from: _emberData.default.attr('pgdate'),
    to: _emberData.default.attr('pgdate'),
    event: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    date: _emberData.default.attr('pgdate'),
    user: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    startsAt: Ember.computed.alias('from'),
    endsAt: Ember.computed.alias('to'),
    title: Ember.computed.alias('event'),
    live_session_id: _emberData.default.attr('number'),
    hasLiveSession: _emberData.default.attr('boolean'),
    bgClass: Ember.computed('type', function () {
      return this.get('type') === 2 ? 'available' : 'booked';
    })
  });

  _exports.default = _default;
});