define("recruit/routes/auth/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    queryParams: {
      token: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      return this.get('session').authenticate('authenticator:encoded-stringified-token', params.token).then(function () {
        _this.set('loading', false);

        return _this.get('currentUser').load();
      });
    }
  });

  _exports.default = _default;
});