define("recruit/templates/components/candidate-helpers/nested-custom-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YGHWV0+j",
    "block": "{\"symbols\":[\"row\",\"data\",\"header\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"field\",\"value\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[6,\"table\"],[9,\"class\",\"nested-custom-field\"],[7],[0,\"\\n    \"],[6,\"thead\"],[7],[0,\"\\n      \"],[6,\"tr\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"tableHeader\"]]],null,{\"statements\":[[0,\"          \"],[6,\"th\"],[9,\"class\",\"text-size-small text-black bg-grey light text-center\"],[7],[1,[19,3,[]],false],[8],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"tableRow\"]]],null,{\"statements\":[[0,\"        \"],[6,\"tr\"],[7],[0,\"\\n\"],[4,\"each\",[[19,1,[]]],null,{\"statements\":[[0,\"            \"],[6,\"td\"],[9,\"class\",\"text-size-small text-center text-grey\"],[7],[1,[19,2,[]],true],[8],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  No requestions found.\\n\"]],\"parameters\":[]}],[11,4]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-helpers/nested-custom-field.hbs"
    }
  });

  _exports.default = _default;
});