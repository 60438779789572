define("recruit/components/reports/filters/filter-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    actions: {
      removeFilter: function removeFilter(arr, obj) {
        if (obj) {
          this.sendAction('removeFilter', arr, obj.get('id'));
        }
      }
    }
  });

  _exports.default = _default;
});