define("recruit/templates/recruiter/candidate/details/assessment-section/evaluations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hzDzt7iT",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"five wide column padding-no text-blue cursor-pointer\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"recruiter.candidate.details.assessment\",[20,[\"model\",\"ac\",\"can\",\"id\"]],[20,[\"model\",\"ac\",\"id\"]]],[[\"class\"],[\"text-blue\"]],{\"statements\":[[1,[25,\"fa-icon\",[\"arrow-left\"],[[\"class\"],[\"margin-right-five text-blue\"]]],false],[0,\" Back\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"eleven wide right aligned column padding-no text-black text-uppercase ellipsis\"],[7],[0,\"EVALUATIONS (\"],[1,[20,[\"model\",\"assessmentSection\",\"section\",\"title\"]],false],[0,\")\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"isPanelCandidateLoaded\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"recruiter-view/candidate/evaluations\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/candidate/details/assessment-section/evaluations.hbs"
    }
  });

  _exports.default = _default;
});