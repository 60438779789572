define("recruit/templates/components/error-states/error-504", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nxSr59yC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui twelve column centered middle aligned grid full-height\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ten wide column\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui raised very padded segment\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid margin-top-five margin-bottom-five\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-center\"],[7],[0,\"\\n          \"],[6,\"img\"],[9,\"src\",\"/images/error_states/504_error.svg\"],[9,\"style\",\"height: 130px; width: 130px;\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-center\"],[7],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"text-capitalize text-black\"],[7],[0,\"Gateway Timeout\"],[8],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"text-uppercase text-red\"],[7],[0,\"error 504\"],[8],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"margin-bottom-no\"],[7],[0,\"The server encountered a temporary error and could not\"],[8],[0,\"\\n          \"],[6,\"p\"],[7],[0,\"complete your request. Try again in 30 seconds.\"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"sixteen wide middle aligned column text-center\"],[7],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"ui basic primary button text-capitalize\"],[9,\"style\",\"width: 120px;\"],[3,\"action\",[[19,0,[]],\"refresh\"]],[7],[0,\"refresh\"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/error-states/error-504.hbs"
    }
  });

  _exports.default = _default;
});