define("recruit/data/ac-advance-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 512,
    type: "filter/master",
    attributes: {
      "type": 'checkbox',
      "field": "Proview Index",
      "objectType": "assessment-filters",
      "options": {
        "filterType": "proview"
      },
      "isRemote": false,
      "addColumn": true,
      'list': [Ember.Object.create({
        id: 1,
        key: 'High',
        value: false
      }), Ember.Object.create({
        id: 2,
        key: 'Medium',
        value: false
      }), Ember.Object.create({
        id: 3,
        key: 'Low',
        value: false
      })],
      "queryTemplate": '(meta.proview-rating: {{value}})'
    }
  }, {
    id: 513,
    type: "filter/master",
    attributes: {
      "type": 'checkbox',
      "field": "Behavioral Profile Match",
      "objectType": "assessment-filters",
      "options": {
        "filterType": "tbi"
      },
      "isRemote": false,
      "addColumn": true,
      'list': [Ember.Object.create({
        id: 1,
        key: 'Recommended',
        value: false
      }), Ember.Object.create({
        id: 2,
        key: 'Not Recommended',
        value: false
      })],
      "queryTemplate": '(meta.behavioural_profile_match:({{value}}) AND meta.tbi_status: Generated)'
    }
  }, {
    id: 514,
    type: "filter/master",
    attributes: {
      "type": "range",
      "field": "Behavioral Fitment Index",
      "isRemote": false,
      "objectType": "assessment-filters",
      "options": {
        "filterType": "tbi"
      },
      "queryTemplate": "(meta.behavioural_fitment_index: [{{start}} TO {{end}}] AND meta.tbi_status: Generated)"
    }
  }, {
    id: 515,
    type: "filter/master",
    attributes: {
      "type": "range",
      "field": "Resume score",
      "isRemote": false,
      "objectType": "assessment-filters",
      "options": {
        "filterType": "skillate"
      },
      "queryTemplate": "(meta.resume-match-score: [{{start}} TO {{end}}] )"
    }
  }, {
    id: 516,
    type: "filter/master",
    attributes: {
      "type": "range",
      "field": "Academic Match Score",
      "isRemote": false,
      "objectType": "assessment-filters",
      "options": {
        "filterType": "skillate"
      },
      "queryTemplate": "(meta.education-match-score: [{{start}} TO {{end}}] )"
    }
  }, {
    id: 517,
    type: "filter/master",
    attributes: {
      "type": "range",
      "field": "Experience Match Score",
      "isRemote": false,
      "objectType": "assessment-filters",
      "options": {
        "filterType": "skillate"
      },
      "queryTemplate": "(meta.experience-match-score: [{{start}} TO {{end}}] )"
    }
  }, {
    id: 518,
    type: "filter/master",
    attributes: {
      "type": "range",
      "field": "Skill Match Score",
      "isRemote": false,
      "objectType": "assessment-filters",
      "options": {
        "filterType": "skillate"
      },
      "queryTemplate": "(meta.skill-match-score: [{{start}} TO {{end}}] )"
    }
  }];
  _exports.default = _default;
});