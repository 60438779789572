define("recruit/templates/components/events/event-status-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJ9TFH/k",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"click-outside\",null,[[\"action\",\"except-selector\"],[[25,\"action\",[[19,0,[]],\"toggleModal\"],null],\".events-status-dropdown\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",[26,[\"ui grid margin-no events-status-dropdown-cell text-black \",[25,\"if\",[[20,[\"disabled\"]],\"disabled\"],null]]]],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"one wide column padding-sm padding-no-right\"],[7],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",[26,[\"events-status dot \",[20,[\"status\",\"name\"]]]]],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"fifteen wide column padding-sm cursor-pointer\"],[3,\"action\",[[19,0,[]],\"changeStatus\",[20,[\"status\"]],[20,[\"eventModel\"]]]],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-weight-bold text-size-normal\"],[7],[1,[20,[\"status\",\"name\"]],false],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-weight-normal events-status-description\"],[7],[1,[20,[\"status\",\"description\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/event-status-dropdown.hbs"
    }
  });

  _exports.default = _default;
});