define("recruit/templates/components/helpers/load-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0sfMhag5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[20,[\"isScriptLoaded\"]]],null,{\"statements\":[[0,\"  \"],[6,\"script\"],[10,\"src\",[18,\"url\"],null],[10,\"sync\",[18,\"sync\"],null],[9,\"defer\",\"\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/load-script.hbs"
    }
  });

  _exports.default = _default;
});