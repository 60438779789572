define("recruit/models/expanded-assessment", ["exports", "recruit/models/assessment"], function (_exports, _assessment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _assessment.default.extend();

  _exports.default = _default;
});