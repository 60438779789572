define("recruit/templates/integration/invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y+FwUqE1",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui container\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui middle aligned centered grid\"],[9,\"style\",\"height:100vh;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui centered grid margin-no\"],[9,\"style\",\"\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"twelve wide column bg-white padding-md\"],[9,\"style\",\"border-bottom:1px solid #ddd;\"],[7],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"text-uppercase text-size-normal text-center text-black\"],[7],[0,\"Invite Candidate\"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"twelve wide column padding-no bg-white\"],[7],[0,\"\\n          \"],[1,[25,\"recruiter-view/actions/assessment-invite\",null,[[\"model\",\"listValues\",\"selectedListValues\",\"isIntegration\",\"isAssessmentFrozen\",\"internalView\",\"noSelectedValue\"],[[20,[\"model\"]],[20,[\"candidates\"]],[20,[\"candidates\"]],true,[20,[\"isAssessmentFrozen\"]],true,[20,[\"noSelectedValue\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/integration/invite.hbs"
    }
  });

  _exports.default = _default;
});