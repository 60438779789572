define("recruit/components/model-table/live-session/list", ["exports", "recruit/mixins/infinite-loader"], function (_exports, _infiniteLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_infiniteLoader.default, {
    user: Ember.inject.service('current-user'),
    modelName: 'live-session',
    openModal: false,

    /*
    columns: Ember.computed(function() {
      if(this.get('user.me.current_role') !== 'Evaluator') {
        this.set('width', '27px');
        this.set('cellComponentValue', "helpers/row-checkbox");
      }
      return [
          {
            component : "helpers/table-checkboxs",
            cellComponent : this.get('cellComponentValue'),
            sortable : false,
            width : this.get('width'),
            classNames : ['table-head'],
            cellClassNames : ['child'],
          },
          {
          valuePath: 'candidate',
          cellComponent: 'helpers/table-profile-picture',
          sortable: false,
          width: '70px',
          classNames: ['table-head']
        },
        //TODO : K Add candidate name to the table
        {
          label: 'Candidate',
          valuePath: 'candidate',
          cellComponent: 'candidate-helpers/candidate-details',
          sortable: false,
          classNames: ['table-head text-left'],
          // width: '200px'
        },
        {
          label: 'Session ID',
          valuePath: 'name',
          classNames: ['table-head'],
          cellClassNames: ['text-center'],
          // width: '200px'
        },this.get('table.selectedRows').length
        // {
        //   label: 'Description',
        //   valuePath: 'formattedDescription',
        //   classNames: ['table-head'],
        //   cellClassNames: ['text-center']
        // },
        //TODO : K Add tv-status and color class to
        {
          label: 'Status',
          cellComponent: 'status-helpers.live',
          valuePath: 'statusLabel',
          classNames: ['table-head'],
          cellClassNames: ['text-center','child'],
          width: '100px'
        },
        {
          label : 'Starts at',
          valuePath: 'starts_at',
          cellComponent: 'helpers.date-view',
          showTimeAgo: false,
          dateFormat: 'DD-MMM-YY | hh:mm A',
          classNames: ['table-head'],
          cellClassNames: ['text-center'],
          // width: '200px'
        },
        {
          label: 'Duration',
          valuePath: 'formattedDuration',
          classNames: ['table-head'],
          cellClassNames: ['text-center'],
          width: '120px'
        }
      ];
    }),
    */
    actions: {
      routeTransistion: function routeTransistion(row) {
        if (this.get('isRecruiter')) {
          this.get('router').transitionTo('recruiter.live-session.view', row);
        } else {
          this.get('router').transitionTo('panel.live-session.view', row);
        }
      }
    }
  });

  _exports.default = _default;
});