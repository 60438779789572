define("recruit/components/live-session/live-schedule-status", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.inject.service('current-user'),
    userRole: Ember.inject.service(),
    isDevelopment: _environment.default.isDevelopment,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$('.ui.dropdown').dropdown();
    },
    actions: {
      seeVideos: function seeVideos(model) {
        if (this.get('userRole.isRecruiter')) {
          this.get('router').transitionTo('recruiter.live-session.videos', model);
        } else {
          this.get('router').transitionTo('panel.live-session.videos', model);
        }
      },
      joinSession: function joinSession(attendee) {
        if (this.get('userRole.isRecruiter')) {
          this.get('router').transitionTo('recruiter.live-session.join', attendee);
        } else {
          this.get('router').transitionTo('panel.live-session.join', attendee);
        }
      },
      askCancel: function askCancel(model) {
        this.sendAction('askCancel', model);
      },
      remindAll: function remindAll(attendees) {
        this.sendAction('remindAll', attendees);
      },
      reschedule: function reschedule(model) {
        this.sendAction('reschedule', model);
      },
      suggestPanel: function suggestPanel(model) {
        this.sendAction('suggestPanel', model);
      },
      cancelSession: function cancelSession(model) {
        this.sendAction('cancelSession', model);
      }
    }
  });

  _exports.default = _default;
});