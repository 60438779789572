define("recruit/components/recruiter-view/questions/edit-question", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isBrowserIE: Ember.computed(function () {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');

      if (msie != -1) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');

      if (trident != -1) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      } // other browser


      return false;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('selectedSkills', Ember.A([]));
      this.set('presentSkills', Ember.A([]));
      this.get('model.questionSkills').then(function (response) {
        if (response.length) {
          _this.set('questionSkills', response);

          response.forEach(function (res) {
            _this.get('selectedSkills').pushObject(res.get('skill'));

            _this.get('presentSkills').pushObject(res.get('skill'));
          });
        }
      });
      this.set('files', Ember.A([]));
      this.set('cannotCreate', false);
      this.set('moreErrors', true);
    },
    actions: {
      removeSkill: function removeSkill(skill) {
        this.get('selectedSkills').removeObject(skill);

        if (skill.get('isDeleteable')) {
          this.get('preSelectedSkills').removeObject(skill);
        }
      },
      selectSkills: function selectSkills(skill) {
        this.get('selectedSkills').pushObject(skill);

        if (!this.get('preSelectedSkills').findBy('id', skill.get('id'))) {
          skill.set('isDeleteable', true);
          this.get('preSelectedSkills').pushObject(skill);
        }
      },
      validate: function validate(changeset, skills, files) {
        var _this2 = this;

        changeset.validate().then(function () {
          if (changeset.get('isInvalid')) {
            _this2.set('cannotCreate', true);
          } else {
            _this2.send('saveQuestion', changeset, skills, files);
          }
        });
      },
      saveQuestion: function saveQuestion(questionObject, skills, files) {
        var _this3 = this;

        var newSkills = Ember.A([]);
        var removedSkills = Ember.A([]);
        this.set('isLoading', true);
        skills.forEach(function (skill) {
          if (!_this3.get('presentSkills').findBy('id', skill.get('id'))) {
            newSkills.pushObject(skill);
          }
        });

        if (this.get('questionSkills')) {
          this.get('questionSkills').forEach(function (questionSkill) {
            if (!skills.findBy('id', questionSkill.get('skill.id'))) {
              removedSkills.pushObject(questionSkill);
            }
          });
        }

        questionObject.save().then(function (createdQuestion) {
          if (newSkills.length || Ember.get(files, 'length') || removedSkills.length) {
            var allFiles = files.map(function (file) {
              return _this3.get('store').createRecord('question-file', {
                file: file,
                question: createdQuestion
              }).save();
            });
            var allSkills = newSkills.map(function (skill) {
              return _this3.get('store').createRecord('question-skill', {
                skill: skill,
                question: createdQuestion
              }).save();
            });

            if (removedSkills.length) {
              removedSkills.forEach(function (skill) {
                skill.destroyRecord();
              });
            }

            var promiseArray = _npmLodash.default.concat(allSkills, allFiles);

            Ember.RSVP.Promise.all(promiseArray).then(function () {
              _this3.get('toast').success('Question updated successfully', 'Success');

              Ember.run.next(function () {
                window.history.back();
              });
            });
          } else {
            _this3.get('toast').success('Question updated successfully', 'Success');

            Ember.run.next(function () {
              window.history.back();
            });
          }
        }).catch(function (error) {
          if (error.errors) {
            error.errors.forEach(function (e) {
              _this3.get('toast').error(e.detail, 'Error');
            });
          } else {
            _this3.get('toast').error('Please try after sometime', 'Something went wrong!');
          }
        }).finally(function () {
          _this3.set('isLoading', false);
        });
      },
      showErrors: function showErrors(property) {
        this.toggleProperty(property);
      },
      saveChoice: function saveChoice(image, changeset, property) {
        if (image) {
          changeset.set("".concat(property), image);
        } else {
          changeset.set("".concat(property), changeset.get('changes.firstObject.value'));
        }
      },
      saveChanges: function saveChanges(image, changeset, property) {
        changeset.set("".concat(property), image);
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      },
      closeErrors: function closeErrors() {
        this.set('cannotCreate', false);
      },
      back: function back() {
        if (this.get('model.hasDirtyAttributes')) {
          this.get('model').rollbackAttributes();
        }

        window.history.back();
      }
    }
  });

  _exports.default = _default;
});