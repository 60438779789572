define("recruit/templates/components/modal-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u0WVB6mY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui twelve column centered middle aligned grid full-height\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"eight wide column\"],[7],[0,\"\\n\"],[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui raised very padded blue segment animatio-card\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui grid t-modal\"],[7],[0,\"\\n        \"],[11,1],[0,\"\\n      \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"showSupport\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"ui  divider\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui grid\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ui center aligned centered column row\"],[7],[0,\"\\n          Contact Support :\\n          \"],[6,\"a\"],[9,\"href\",\"mailto:support@talview.com?&subject=Page Not Found?&body=Page%20not%20found\"],[7],[0,\"support@talview.com\\n          \"],[8],[0,\" \"],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ui grid\"],[9,\"style\",\"position:relative;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"two wide right floated column\"],[9,\"style\",\"position:absolute; bottom:10px; right:60px;\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"src\",\"/images/poweredby.svg\"],[9,\"alt\",\"\"],[9,\"class\",\"ui image\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/modal-wrapper.hbs"
    }
  });

  _exports.default = _default;
});