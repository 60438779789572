define("recruit/components/assessment/rating-templates/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dropdownColumnWidth: Ember.computed('columnWidth', function () {
      return this.get('columnWidth') ? this.get('columnWidth') : 'eight';
    }),
    modelQuery: {
      type: 2
    },
    showErrorMessage: function showErrorMessage(err) {
      var _this = this;

      if (err.errors) {
        err.errors.forEach(function (error) {
          return _this.get('toast').error(error.detail, 'Error!', {
            positionClass: 'assessment-toast-error'
          });
        });
      } else {
        return this.get('toast').error("Something went wrong.", 'Try again.', {
          positionClass: 'assessment-toast-error'
        });
      }
    },
    actions: {
      getForm: function getForm(form) {
        this.set('selectedAS.section.evaluationForm', form);
      },
      removeForm: function removeForm() {
        this.set('selectedAS.section.evaluationForm', null);
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      },
      addForm: function addForm(section, form) {
        var _this2 = this;

        if (this.get('disabled')) {
          if (!this.get('toggleNag')) {
            this.toggleProperty('toggleNag');
          }

          return;
        }

        if (!form.get('id')) {
          return this.get('toast').error('Select a form to add', 'Error!', {
            positionClass: 'assessment-toast-error'
          });
        }

        this.set('isLoading', true);
        section.get('sectionParameters').then(function (sectionParameters) {
          if (sectionParameters && sectionParameters.length) {
            return _this2.send('deleteParameters', section, sectionParameters);
          }

          _this2.send('saveForm', section);
        });
      },
      deleteParameters: function deleteParameters(section, parameters) {
        var _this3 = this;

        var counter = 0;
        parameters.forEach(function (parameter) {
          parameter.destroyRecord().then(function () {
            counter++;

            if (parameters.length == counter) {
              _this3.send('saveForm', section);
            }
          }).catch(function (error) {
            _this3.showErrorMessage(error);
          });
        });
      },
      saveForm: function saveForm(section) {
        var _this4 = this;

        section.save().then(function () {
          _this4.get('toast').success('Form Added', 'Success', {
            positionClass: 'assessment-toast-error'
          });
        }).catch(function (error) {
          _this4.showErrorMessage(error);
        }).finally(function () {
          _this4.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});