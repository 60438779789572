define("recruit/templates/components/graphs/x-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VxvJ3sDf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments box-shadow-none border-none bg-white\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"id\",\"highcharts-container\"],[9,\"class\",\"ui segment padding-no bg-white\"],[9,\"style\",\"width: 100%; text-align: center; clear: both; height: 280px;\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/graphs/x-range.hbs"
    }
  });

  _exports.default = _default;
});