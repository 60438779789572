define("recruit/templates/components/candidate-helpers/bulk-import/on-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "je/VUmPx",
    "block": "{\"symbols\":[\"candidate\"],\"statements\":[[6,\"table\"],[9,\"class\",\"ui very basic collapsing celled table full-width padding-md-left padding-md-right\"],[7],[0,\"\\n  \"],[6,\"thead\"],[9,\"style\",\"background-color: #ECECEC;\"],[7],[0,\"\\n    \"],[6,\"tr\"],[7],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"text-center padding-sm text-weight-medium\"],[7],[0,\"ROW\"],[8],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"padding-sm text-weight-medium\"],[7],[0,\"NAME\"],[8],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"padding-sm text-weight-medium\"],[7],[0,\"EMAIL\"],[8],[0,\"\\n\"],[4,\"if\",[[25,\"not\",[[20,[\"isEventsModal\"]]],null]],null,{\"statements\":[[0,\"        \"],[6,\"th\"],[9,\"class\",\"padding-sm text-weight-medium\"],[7],[0,\"RESUME\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"candidates\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"candidate-helpers/bulk-import/success-row\",null,[[\"candidate\",\"isEventsModal\"],[[19,1,[]],[20,[\"isEventsModal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-helpers/bulk-import/on-success.hbs"
    }
  });

  _exports.default = _default;
});