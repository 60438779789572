define("recruit/adapters/generate-qr", ["exports", "recruit/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'user/me',
    pathForType: function pathForType() {
      return 'generate-otp-uri';
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.get) {
        delete query.get;
        return "".concat(this._super.apply(this, arguments));
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});