define("recruit/components/helpers/load-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isScriptLoaded: Ember.computed(function () {
      return window[this.get('property')];
    })
  });

  _exports.default = _default;
});