define("recruit/components/live-session/scheduling/calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isLoading: false,
    occurrences: Ember.A(),
    actions: {
      calendarAddOccurrence: function calendarAddOccurrence(occurrence) {
        var _this = this;

        this.get('occurrences').forEach(function (occurrence) {
          if (occurrence.get('isFresh')) {
            _this.get('occurrences').removeObject(occurrence);
          }
        });

        if ((0, _moment.default)(occurrence.startsAt).isBefore((0, _moment.default)())) {
          this.get('toast').error('', 'Scheduling live session in past is not allowed');
          return false;
        }

        this.set('isLoading', true);
        occurrence.set('isFresh', true);
        occurrence.set('bgClass', 'booked');
        this.get('liveSession').setProperties({
          event: occurrence.get('title') || 'Available',
          date: occurrence.get('startsAt'),
          from: occurrence.get('startsAt'),
          to: occurrence.get('endsAt'),
          type: 2
        });
        return this.get('occurrences').pushObject(occurrence);
      },
      calendarUpdateOccurrence: function calendarUpdateOccurrence(occurrence, properties) {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this2, _this2._updateEvent, occurrence, properties, resolve, reject, 300);
        });
      },
      calendarRemoveOccurrence: function calendarRemoveOccurrence() {
        return;
      },
      calendarNavigateWeek: function calendarNavigateWeek() {},
      showEvent: function showEvent() {},
      createSession: function createSession(timeZone) {
        this.sendAction('createSession', timeZone);
      },
      revertModel: function revertModel(model) {
        if (model.get('isNew')) {
          this.get('occurrences').removeObject(model);
        } else {
          model.rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});