define("recruit/components/reports/dashboard/dashboard-list-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.next(function () {
        Ember.$('.ui.accordion').accordion();
      });
    },
    actions: {
      redirect: function redirect(redirectUrl, parameter) {
        if (parameter) {
          this.get('router').transitionTo(redirectUrl, parameter);
        } else {
          this.get('router').transitionTo(redirectUrl);
        }
      }
    }
  });

  _exports.default = _default;
});