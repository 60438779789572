define("recruit/components/assessment/assessment-section-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['display-inline-block'],
    currentSection: Ember.computed('selectedAS', function () {
      return this.get('section.id') == this.get('selectedAS.id');
    }),
    actions: {
      changeSection: function changeSection(sectionObj) {
        this.sendAction('setSelectedAS', sectionObj);
      }
    }
  });

  _exports.default = _default;
});