define("recruit/templates/components/recruiter-view/questions/helpers/filters-users-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dqfschAi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"helpers/dynamic-checkbox\",null,[[\"selectedOptions\",\"isMutAction\",\"value\",\"isQuestionsFilter\",\"actionName\"],[[20,[\"selectedOptions\"]],false,[20,[\"item\"]],true,[25,\"action\",[[19,0,[]],[20,[\"actionName\"]]],null]]]],false],[0,\"\\n\"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"item\",\"profilePic\"]],\"ui circular centered image questions-profilePic\"]]],false],[0,\"\\n\"],[6,\"p\"],[9,\"class\",\"text-black text-weight-normal cursor-pointer margin-top-twenty-negative margin-left-fifty-five\"],[3,\"action\",[[19,0,[]],\"setUsers\",true,[20,[\"item\"]]]],[7],[6,\"span\"],[7],[1,[20,[\"item\",\"name\"]],false],[8],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/helpers/filters-users-checkbox.hbs"
    }
  });

  _exports.default = _default;
});