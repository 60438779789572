define("recruit/components/recruiter-view/questions/bulk-create/index", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    initializeProperties: function initializeProperties() {
      this.setProperties({
        questionsArray: Ember.A([]),
        errorData: Ember.A([]),
        modelName: null,
        showUploadScreen: true,
        uploadComplete: false,
        isUploadingQuestions: false
      });
    },
    actions: {
      back: function back() {
        history.back();
      },
      uploadAgain: function uploadAgain() {
        this.initializeProperties();
      },
      postUpload: function postUpload(questions) {
        var _this = this;

        questions = _npmLodash.default.remove(questions, null);

        if (questions.length) {
          questions.forEach(function (question) {
            _this.setProperties({
              uploadPercent: 10,
              isUploadingQuestions: true,
              showUploadScreen: false
            });

            _this.get('store').createRecord('question').parse(question).then(function (qModel) {
              _this.get('questionsArray').pushObject(qModel);
            }).catch(function (e) {
              var a = {
                'error': ""
              };
              a['error'] = _npmLodash.default.reduce(e.errors, function (err, element) {
                return err + [element.field, '-', element.detail].join(' ');
              }, '');

              _this.get('errorData').pushObject(a);
            }).finally(function () {
              if (questions.length == _this.get('questionsArray.length') || questions.length == _this.get('errorData.length')) {
                _this.set('uploadPercent', 100);

                setTimeout(function () {
                  _this.set('isUploadingQuestions', false);

                  _this.set('uploadComplete', true);
                }, 1000);
              }

              if (_this.get('uploadPercent') < 75) {
                _this.set('uploadPercent', _this.get('uploadPercent') + 10);
              }
            });
          });
        } else {
          this.get('toast').error('Either file is empty or invalid', 'Error', {
            timeOut: 5000
          });
        }
      }
    }
  });

  _exports.default = _default;
});