define("recruit/helpers/get-duration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getProperty = getProperty;
  _exports.default = void 0;

  function getProperty(object) {
    return (0, _moment.default)(object[0]).fromNow();
  }

  var _default = Ember.Helper.helper(getProperty);

  _exports.default = _default;
});