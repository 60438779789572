define("recruit/routes/signed-out", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('isDevelopment', _environment.default.isDevelopment);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$('body').addClass('not_found_bg re');
    },
    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);

      Ember.$('body').removeClass('not_found_bg');
    }
  });

  _exports.default = _default;
});