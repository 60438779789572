define("recruit/templates/components/reports/table/recruiter/user-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RhCzt2FG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/table/recruiter/user-component.hbs"
    }
  });

  _exports.default = _default;
});