define("recruit/components/filters/quick-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    statusFilter: null,
    quickFilters: [{
      key: "Pending",
      value: "pending"
    }, {
      key: "Evaluated",
      value: "evaluated"
    }, {
      key: "Selected",
      value: "selected"
    }, {
      key: "On Hold",
      value: "hold"
    }, {
      key: "Rejected",
      value: "rejected"
    }],
    actions: {
      setQuickFilter: function setQuickFilter(status) {
        var filterStatus = {
          evaluated: null,
          recommendation: null
        };

        switch (status) {
          case 'pending':
            filterStatus.evaluated = false;
            break;

          case 'evaluated':
            filterStatus.evaluated = true;
            break;

          case 'selected':
            filterStatus.recommendation = 'select';
            break;

          case 'hold':
            filterStatus.recommendation = 'hold';
            break;

          case 'rejected':
            filterStatus.recommendation = 'reject';
            break;
        }

        this.sendAction('setQuickFilter', filterStatus);
      }
    }
  });

  _exports.default = _default;
});