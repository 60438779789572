define("recruit/components/recruiter-view/questions/helpers/editable-preview-component", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    choiceExists: Ember.computed('changeset._changes', 'file', function () {
      return this.get("changeset.".concat(this.get('property'))) ? this.get("changeset.".concat(this.get('property'))) : this.get('uploadedFile') ? this.get('uploadedFile') : null;
    }),
    answerProperty: Ember.computed('displayProperty', function () {
      if (_npmLodash.default.includes(this.get('displayProperty'), "audio")) {
        return "".concat(this.get('displayProperty'));
      } else {
        var choiceProperty = this.get('displayProperty').replace('<p>', '<span>').replace('</p>', '</span>');
        return Ember.String.htmlSafe("".concat(choiceProperty));
      }
    }),
    uploadedFileObserver: Ember.observer('uploadedFile', function () {
      this.send('uploadFile', this.get('uploadedFile'));
    }),
    imageSrc: Ember.computed('uploadedFile', 'imageTags', 'changeset._changes', function () {
      if (this.get('editComponent') && this.get("changeset.".concat(this.get('property')))) {
        return this.get("changeset.".concat(this.get('property')));
      } else {
        if (this.get('imageTags')) {
          return this.get('imageTags');
        } else {
          return this.get('uploadedFile.resourceUrl') ? "<img src=\"".concat(this.get('uploadedFile.resourceUrl'), "\" style=\"height: 200px;\"/>") : this.get('uploadedFile') ? this.get('uploadedFile') : null;
        }
      }
    }),
    isChecked: Ember.computed('changeset.correct_choice', 'type', function () {
      if (this.get('changeset.correct_choice')) {
        return this.get('changeset.correct_choice').toString().includes(this.get('correctOption'));
      }
    }),
    actions: {
      editChoice: function editChoice(property) {
        this.send('toggleModal', property);
      },
      toggleModal: function toggleModal(type) {
        var _this = this;

        this.toggleProperty(type);

        if (type == 'showProperty' && this.get('showProperty')) {
          Ember.run.next('afterRender', function () {
            _this.$().find('input[type="text"]').focus();
          });
        }
      },
      removeFile: function removeFile() {
        this.set('file', null);
        this.set('imageTags', null);
        this.set('uploadedFile', null);
        this.send('toggleModal', 'showProperty');
      },
      saveChoice: function saveChoice(changeset, property) {
        this.set('displayProperty', this.get('imageTags'));
        this.sendAction('saveChoice', this.get('imageTags'), changeset, property);
        this.send('toggleModal', 'isEditorModal');
      },
      contentsChanged: function contentsChanged(newContents) {
        this.set('imageTags', newContents);
      },
      saveChanges: function saveChanges(changeset, property) {
        this.set('displayProperty', this.get('imageTags'));
        this.sendAction('saveChanges', this.get('imageTags'), changeset, property);
        this.send('toggleModal', 'imagePreview');
      },
      uploadFile: function uploadFile(file) {
        if (this.get('file')) {
          this.sendAction('uploadFile', file, this.get('property'));
        } else {
          this.sendAction('uploadFile', this.get('file'), this.get('property'));
        }
      },
      setValue: function setValue(value) {
        this.sendAction('setValue', value);
      },
      focusOutAction: function focusOutAction() {
        if (this.get("changeset.".concat(this.get('property')))) {
          this.set('editComponent', true);
          this.set('displayProperty', this.get("changeset.".concat(this.get('property'))));
        } else {
          this.set('displayProperty', null);
        }

        this.set('showProperty', false);
      }
    }
  });

  _exports.default = _default;
});