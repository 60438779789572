define("recruit/models/status", ["exports", "ember-data", "npm:lodash"], function (_exports, _emberData, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sort_key: _emberData.default.attr('number'),
    label: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    formattedLabel: Ember.computed('label', function () {
      return this.get('label') ? this.get('label')[0] : null;
    }),
    statusClass: Ember.computed('label', function () {
      return _npmLodash.default.kebabCase(_npmLodash.default.lowerCase(this.get('label')));
    }),
    key: Ember.computed(function () {
      return this.get('label');
    }),
    value: Ember.computed(function () {
      return this.get('id');
    })
  });

  _exports.default = _default;
});