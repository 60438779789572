define("recruit/validations/update-password", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var _default = {
    current_password: [(0, _validators.validatePresence)(true)],
    new_password: [(0, _validators.validatePresence)(true)],
    new_password_repeat: [(0, _validators.validateConfirmation)({
      on: 'new_password',
      message: "Password does not match the confirm password."
    })]
  };
  _exports.default = _default;
});