define("recruit/components/recruiter-view/actions/candidate", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('isDirect')) {
        this.set('isDirectInvite', true);
        this.toggleProperty('isAssessmentInvite');
      }
    },
    actions: {
      importCandidates: function importCandidates() {
        this.get('router').transitionTo('recruiter.assessment.details.import');
      }
    }
  });

  _exports.default = _default;
});