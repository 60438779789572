define("recruit/models/panel", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    assessmentSection: _emberData.default.belongsTo('assessment-section'),
    evaluatedCount: _emberData.default.attr('number'),
    assignedCount: _emberData.default.attr('number'),
    pending: _emberData.default.hasMany('panel-candidate'),
    showOtherEvaluations: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    assessment: _emberData.default.belongsTo('assessment'),
    eventUser: _emberData.default.belongsTo('event-user'),
    pendingCount: Ember.computed('evaluatedCount', 'assignedCount', 'pending.{isFulfilled,[]}', function () {
      return +this.get('assignedCount') - +this.get('evaluatedCount');
    })
  });

  _exports.default = _default;
});