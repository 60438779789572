define("recruit/models/actions/event-panel-notify", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    event_id: _emberData.default.attr('string'),
    eventUsers: _emberData.default.attr(),
    attend_reminder: _emberData.default.attr('boolean'),
    register_reminder: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});