define("recruit/components/reports/graphs/assessments/time-to-hire", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    selectedFilters: Ember.A([]),
    showAssessmentDetails: Ember.computed(function () {
      return this.get('graphTitle').includes('assessment');
    }),
    valueObserver: Ember.observer('selectedRow', function () {
      if (this.get('selectedRow.id')) {
        if (!this.get('showAssessmentDetails')) {
          this.set('userObject', this.get('store').findRecord('user', +this.get('selectedRow.id')));
        }

        this.getValues(this.get('selectedRow'));
      }
    }),
    graphLabelSuffix: Ember.computed('selectedGraphFilter', function () {
      this.set('graphTooltipSuffix', this.get('selectedGraphFilter') == 'hours' ? 'hours' : 'days');

      if (this.get('actionName')) {
        this.sendAction('actionName', this.get('selectedGraphFilter'));
      }

      return this.get('selectedGraphFilter') == 'hours' ? 'h' : 'd';
    }),
    graphValues: Ember.observer('firstTableRecord', 'graphLabelSuffix', 'isLoading', function () {
      if (!this.get('isDestroyed')) {
        if (this.get('firstTableRecord.id')) {
          this.set('showEmptyState', false);
          this.set('selectedRow', this.get('firstTableRecord'));
          this.getValues(this.get('firstTableRecord'));
        } else {
          this.set('showEmptyState', true);
        }
      }
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('dateRecordSet')) {
        this.set('initialStartDate', this.get('startDate'));
        this.set('initialEndDate', this.get('endDate'));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.initialize();
      this.get('store').query('user', {
        roles: 'Recruiter'
      }).then(function (res) {
        _this.set('defaultRecruiters', res.slice(0, 6));
      });
      this.get('store').query('user', {
        roles: 'Evaluator'
      }).then(function (res) {
        _this.set('defaultPanels', res.slice(0, 6));
      });
      this.get('store').findAll('assessment').then(function (res) {
        _this.set('defaultAssessments', res.slice(0, 6));
      });
      this.set('advanceFilters', this.get('store').peekAll('filter/master'));
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.run.next(function () {
        Ember.$('.ui.cursor-help').popup({
          position: 'bottom center'
        });
      });
    },
    initialize: function initialize() {
      this.setProperties({
        'selectedAssessments': Ember.A([]),
        'selectedRecruiters': Ember.A([]),
        'selectedPanels': Ember.A([]),
        'selectedAdvanceFilters': Ember.A([]),
        'allAppliedFilters': Ember.A([]),
        'selectedRow': Ember.A([]),
        'renderGraph': false
      });
    },
    getLabelColor: function getLabelColor(sortValue) {
      switch (sortValue) {
        case 0:
          return '#9975CC';

        case 1:
          return '#4A90E2';

        case 2:
          return '#03C1A1';

        case 3:
          return '#ED8068';

        case 4:
          return '#83CA6D';
      }
    },
    getLabelName: function getLabelName(sortValue) {
      switch (sortValue) {
        case 0:
          return 'Send Invitations';

        case 1:
          return 'Complete Assessment';

        case 2:
          return 'Assign Panel';

        case 3:
          return 'Evaluate';

        case 4:
          return 'Final Selection';
      }
    },
    showDate: Ember.computed('openCalendar', function () {
      return !this.get('openCalendar');
    }),
    getValues: function getValues(res) {
      var x_axis_values = [res.get('inviteTime'), res.get('completeTime'), res.get('assignTime'), res.get('evaluationTime'), res.get('shortlistTime')];

      if (this.get('selectedGraphFilter') == 'hours') {
        x_axis_values.forEach(function (val, index) {
          x_axis_values[index] = parseInt(_moment.default.duration(val, 'seconds').asHours());
        });
      } else {
        x_axis_values.forEach(function (val, index) {
          x_axis_values[index] = parseInt(_moment.default.duration(val, 'seconds').asDays());
        });
      }

      this.computeXRange(x_axis_values);
    },
    computeXRange: function computeXRange(x_axis_values) {
      var second_limit = x_axis_values.objectAt(0) + x_axis_values.objectAt(1);
      var third_limit = x_axis_values.objectAt(0) + x_axis_values.objectAt(2);
      var max_val = Math.max(second_limit, third_limit);
      var fourth_limit = max_val + x_axis_values.objectAt(3);
      var fifth_limit = fourth_limit + x_axis_values.objectAt(4);
      var x_array = Ember.A([{
        x: 0,
        x2: x_axis_values.objectAt(0),
        diff: x_axis_values.objectAt(0),
        tooltip_text: 'Average time taken to send invitations to the candidates by recruiter.'
      }, {
        x: x_axis_values.objectAt(0),
        x2: second_limit,
        diff: x_axis_values.objectAt(1),
        tooltip_text: 'Average time taken by the candidates to complete the assessment.'
      }, {
        x: x_axis_values.objectAt(0),
        x2: third_limit,
        diff: x_axis_values.objectAt(2),
        tooltip_text: 'Average time taken by the recruiter to assign panel to the candidates.'
      }, {
        x: max_val,
        x2: fourth_limit,
        diff: x_axis_values.objectAt(3),
        tooltip_text: 'Average time taken by the panel to evaluate candidates.'
      }, {
        x: fourth_limit,
        x2: fifth_limit,
        diff: x_axis_values.objectAt(4),
        tooltip_text: 'Average time taken by the recruiter to finally shortlist a candidate.'
      }]);
      this.computeDataArray(x_array);
    },
    computeDataArray: function computeDataArray(x_array) {
      var _this2 = this;

      var y_axis_values = [0, 1, 2, 3, 4];
      var count = y_axis_values.map(function (val) {
        return {
          name: _this2.getLabelName(val),
          x: x_array.objectAt(val).x,
          x2: x_array.objectAt(val).x2,
          diff: x_array.objectAt(val).diff,
          y: val,
          tooltip_text: x_array.objectAt(val).tooltip_text,
          color: _this2.getLabelColor(val)
        };
      });
      this.set('dataArray', count);
      this.set('renderGraph', true);
    },
    actions: {
      togglePopup: function togglePopup(property) {
        if (property == 'openCalendar') {
          if (this.get('openFilter')) {
            this.set('openFilter', false);
          }
        }

        if (property == 'openFilter') {
          if (this.get('openCalendar')) {
            this.set('openCalendar', false);
          }
        }

        this.toggleProperty(property);
      },
      setFilters: function setFilters(assessment, recruiter, panel, advanceFilters) {
        this.send('togglePopup', 'openFilter');
        this.sendAction('setAllFilters', assessment, recruiter, panel, advanceFilters);
      },
      resetFilters: function resetFilters() {
        this.initialize();
      },
      setDateFilter: function setDateFilter(obj) {
        this.sendAction('setDateFilter', obj);
      }
    }
  });

  _exports.default = _default;
});