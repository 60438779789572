define("recruit/routes/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "npm:lodash"], function (_exports, _authenticatedRouteMixin, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    userRole: Ember.inject.service(),
    model: function model() {
      if (this.get('currentUser.me')) {
        return this.get('currentUser.me');
      } else {
        return this.get('currentUser').load();
      }
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      this.get('userRole').init();

      if (this.get('userRole.isContentManager')) {
        return this.replaceWith('recruiter.questions');
      }

      if (this.get('userRole.isPanel')) {
        this.replaceWith("panel.dashboard");
      } else {
        // Ember.run.later(() => {
        //   if(!_.includes(_.get(model.get('appState'), 'web.releaseShown'), 'v7.0.0')) {
        //     model.set('appState', {web: {releaseShown: ['v7.0.0']}});
        //     model.save();
        //     this.controllerFor('application').send('openReleaseModal'); //Calls action is application route
        //   }
        // })
        this.replaceWith('recruiter.dashboard');
      }
    }
  });

  _exports.default = _default;
});