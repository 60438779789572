define("recruit/templates/components/reports/helpers/time-row-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CHDPwu0x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"data-tooltip\",[26,[[18,\"tooltip_text\"]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n  \"],[1,[18,\"timeValue\"],false],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/time-row-component.hbs"
    }
  });

  _exports.default = _default;
});