define("recruit/components/reports/filters/reports-date-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var linkValues = Ember.A(['yesterday', 'previous-week', 'last-7-days', 'all-time', 'last-30-days', 'last-60-days', 'last-90-days', 'last-6-months', 'this-year', 'last-year']);
      this.set('quickLinks', linkValues);
    },
    didInsertElement: function didInsertElement() {
      this.initializeDatePicker();
    },
    getDateRange: function getDateRange(filterValue) {
      var end = (0, _moment.default)().subtract(1 * 86400 * 1000).unix();
      var start = null;

      switch (filterValue) {
        case 'yesterday':
          start = (0, _moment.default)().startOf('day').subtract(86400 * 1000).unix();
          break;

        case 'previous-week':
          start = (0, _moment.default)().startOf('week').subtract(7 * 86400 * 1000).unix();
          end = (0, _moment.default)().startOf('week').subtract(1 * 86400 * 1000).unix();
          break;

        case 'this-week':
          start = (0, _moment.default)().startOf('week').unix();
          break;

        case 'last-7-days':
          start = (0, _moment.default)().startOf('day').subtract(7 * 86400 * 1000).unix();
          break;

        case 'last-30-days':
          start = (0, _moment.default)().startOf('day').subtract(30 * 86400 * 1000).unix();
          break;

        case 'last-60-days':
          start = (0, _moment.default)().startOf('day').subtract(60 * 86400 * 1000).unix();
          break;

        case 'last-90-days':
          start = (0, _moment.default)().startOf('day').subtract(90 * 86400 * 1000).unix();
          break;

        case 'last-6-months':
          start = (0, _moment.default)().startOf('day').subtract(180 * 86400 * 1000).unix();
          break;

        case 'this-year':
          start = (0, _moment.default)().startOf('year').unix();
          break;

        case 'last-year':
          start = (0, _moment.default)().startOf('year').subtract(86400 * 1000).startOf('year').unix();
          end = (0, _moment.default)().startOf('year').subtract(1 * 86400 * 1000).unix();
          break;

        default:
          start = 0;
          break;
      }

      if (this.get('filterValue') === 'all-time') {
        this.set('startDate', (0, _moment.default)(this.get('currentUser.me.organization.created_at')).format("DD MMMM YYYY"));
      } else {
        this.set('startDate', start ? _moment.default.unix(start).format("DD MMMM YYYY") : null);
      }

      this.set('endDate', end ? _moment.default.unix(end).format("DD MMMM YYYY") : null);
    },
    initializeDatePicker: function () {
      var today = new Date();

      var _that = this;

      var allTimeDate = (0, _moment.default)(this.get('currentUser.me.organization.created_at')).format('DD MMMM YYYY');
      Ember.$('#rangestart').calendar({
        type: 'date',
        minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - Math.trunc(_moment.default.duration((0, _moment.default)().diff((0, _moment.default)(allTimeDate))).asDays())),
        formatter: {
          date: function date(_date) {
            return (0, _moment.default)(_date).format("DD MMMM YYYY");
          }
        },
        onChange: function onChange() {
          Ember.set(_that, "dateFilterSet", true);
          Ember.set(_that, "filterValue", null);
        },
        endCalendar: Ember.$('#rangeend'),
        maxDate: (0, _moment.default)().toDate()
      });
      Ember.$('#rangeend').calendar({
        type: 'date',
        minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - Math.trunc(_moment.default.duration((0, _moment.default)().diff((0, _moment.default)(this.get('startDate')))).asDays())),
        formatter: {
          date: function date(_date2) {
            return (0, _moment.default)(_date2).format("DD MMMM YYYY");
          }
        },
        onChange: function onChange() {
          Ember.set(_that, "dateFilterSet", true);
          Ember.set(_that, "filterValue", null);
        },
        maxDate: (0, _moment.default)().toDate()
      });
    }.observes('startDate'),
    showError: Ember.computed('startDate', 'endDate', function () {
      if ((0, _moment.default)(this.get('endDate')).toDate() > (0, _moment.default)().toDate() || (0, _moment.default)(this.get('startDate')).toDate() > (0, _moment.default)().toDate()) {
        return true;
      } else {
        return false;
      }
    }),
    initialize: function initialize() {
      this.set('startDate', this.get('initialStartDate'));
      this.set('endDate', this.get('initialEndDate'));
    },
    actions: {
      setDate: function setDate(filterValue) {
        this.set('filterValue', filterValue);
        this.set('dateFilterSet', true);
        this.getDateRange(this.get('filterValue'));
      },
      closePopup: function closePopup() {
        this.initialize();
        this.set('openCalendar', false);
      },
      submit: function submit() {
        if (!this.get('startDate')) {
          this.set('startDateError', true);
        }

        if (!this.get('endDate')) {
          this.set('endDateError', true);
        }

        if (this.get('startDate') && this.get('endDate')) {
          this.set('startUnix', (0, _moment.default)(this.get('startDate')).format());
          this.set('endUnix', (0, _moment.default)(this.get('endDate')).format());
        }

        var dateObject = {
          start_date: this.get('startUnix'),
          end_date: this.get('endUnix'),
          date_filter: this.get('filterValue')
        };

        if (!this.get('dateFilterSet')) {
          if (this.get('filterValue')) {
            this.set('selectedFilterValue', this.get('filterValue'));
          }

          this.send('closePopup');
        } else {
          this.set('selectedFilterValue', this.get('filterValue'));
          this.sendAction('setDateFilter', dateObject);
          this.set('initialStartDate', this.get('startDate'));
          this.set('initialEndDate', this.get('endDate'));
        }

        this.set('openCalendar', false);
      }
    }
  });

  _exports.default = _default;
});