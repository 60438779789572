define("recruit/components/recruiter-view/actions/extend-date", ["exports", "recruit/config/environment", "moment", "recruit/mixins/g-actions"], function (_exports, _environment, _moment, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    isNotify: true,
    isLoading: false,
    endDate: (0, _moment.default)().add(10, 'd').toDate(),
    minDate: (0, _moment.default)().toDate(),
    actions: {
      extendLastDate: function extendLastDate() {
        var _this = this;

        if (!this.get('endDate')) {
          return this.get('toast').error('', "Date Can't be blank");
        }

        this.set('isLoading', true);
        var assessmentCandidates = this.get('selectedListValues').map(function (ac) {
          return {
            "id": ac.get('id')
          };
        });
        this.get('store').createRecord('actions/extend-date', {
          assessmentCandidates: assessmentCandidates,
          endDate: (0, _moment.default)(this.get('endDate')),
          isNotify: this.get('isNotify')
        }).save().then(function () {
          _this.get('toast').success('for ' + assessmentCandidates.length + ' Candidates', 'Last Date Successfully Updated');

          return _this.send('back');
        }).catch(function (e) {
          _this.catchError(e);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});