define("recruit/components/helpers/formatted-date-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    format: 'DD-MMM-YY',
    dataPosition: "bottom center",
    formattedTime: Ember.computed('dateTime', function () {
      var dt = this.get('dateTime') || this.get('row.content');
      return dt ? (0, _moment.default)(dt).format(this.get('format')) : null;
    }),
    didInsertElement: function didInsertElement() {
      Ember.$('[data-content]').popup({
        position: 'bottom center',
        inline: false,
        variation: 'inverted'
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('[data-content]').popup('destroy');
    }
  });

  _exports.default = _default;
});