define("recruit/components/events/event-create-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('eventModel', this.get('store').createRecord('event'));
    },
    actions: {
      createEvent: function createEvent() {
        var _this = this;

        var eventModel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('eventModel');

        if (!eventModel.get('title.length')) {
          return this.set('showError', true);
        }

        if (this.get('showError')) {
          this.set('showError', false);
        }

        this.set('isLoading', true);
        eventModel.set('owned_by', this.get('user.me'));
        eventModel.save().then(function (response) {
          _this.set('isLoading', false);

          _this.sendAction('toggleModal', 'createEvent', 'recruiter.events.details.overview', response);
        }).catch(function (err) {
          if (err.errors) {
            err.errors.forEach(function (error) {
              _this.get('toast').error(error.detail, 'Error!');
            });
          } else {
            _this.get('toast').error("Something went wrong.", 'Try again.');
          }
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      toggleModal: function toggleModal() {
        this.sendAction('toggleModal', 'createEvent');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('eventModel').rollbackAttributes();
    }
  });

  _exports.default = _default;
});