define("recruit/adapters/performance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    host: 'https://wc-performance-metrics.talview.com',
    pathForType: function pathForType() {
      return 'capture';
    }
  });

  _exports.default = _default;
});