define("recruit/routes/auth/callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var isEmberRedirect,
          redirectUrl = params.url,
          redirectId = params.id;

      if (redirectId) {
        isEmberRedirect = true;
      }

      if (!redirectUrl) {
        isEmberRedirect = true;
        redirectUrl = 'dashboard';
      }

      return this.get('session').authenticate('authenticator:tv', params.c).then(function () {
        _this.set('loading', false);

        return _this.get('currentUser').load();
      }).then(function () {
        if (isEmberRedirect) {
          if (redirectId) {
            _this.get('router').transitionTo(redirectUrl, redirectId);
          } else {
            _this.get('router').transitionTo(redirectUrl);
          }
        } else {
          window.location.href = redirectUrl;
        }
      });
    }
  });

  _exports.default = _default;
});