define("recruit/components/reports/table/recruiter/activities-table", ["exports", "recruit/mixins/report-table-common"], function (_exports, _reportTableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTableCommon.default, {
    modelName: 'reports/user-activity',
    columns: Ember.computed(function () {
      return [{
        label: 'Recruiters',
        component: 'reports/helpers/assessment-header',
        classNames: ['table-head mis text-center border-right-grey'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        cellComponent: 'reports/helpers/user-details',
        sortable: false,
        width: '28%'
      }, {
        component: 'reports/helpers/column-with-toggle',
        classNames: ['table-head text-center mis'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        value: 'Recruiter Activities',
        sortable: false,
        subColumns: [{
          label: 'Create Candidates',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center mis border-right-grey'],
          cellClassNames: ['text-center border-right-grey'],
          columnTooltip: 'Count of candidates created by the recruiter.',
          valuePath: 'createCandidateCount',
          width: '12%'
        }, {
          label: 'Invite to Assessment',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center mis border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'Count of candidates invited to an assessment',
          valuePath: 'inviteCount',
          width: '12%'
        }, {
          label: 'Schedule Live Session',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center mis'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'Number of times live sessions were scheduled.',
          valuePath: 'liveScheduleCount',
          width: '12%'
        }, {
          label: 'Reschedule Live Session',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center mis border-right-grey'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey'],
          columnTooltip: 'Number of times live sessions were rescheduled.',
          valuePath: 'liveRescheduleCount',
          width: '12%'
        }, {
          label: 'Evaluations',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center mis'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey'],
          columnTooltip: 'Number of times the recruiter evaluated the candidates.',
          valuePath: 'evaluationCount',
          width: '12%'
        }, {
          label: 'Change Invite Status',
          component: 'reports/helpers/table-header',
          classNames: ['table-head text-center mis'],
          cellComponent: 'reports/helpers/column-cell-component',
          cellClassNames: ['text-center border-right-grey padding-vs'],
          columnTooltip: 'Number of times the recruiter changed the final status of the candidates.',
          valuePath: 'changeStatusCount',
          width: '12%'
        }]
      }];
    }),
    actions: {
      toggleGraph: function toggleGraph() {
        this.toggleProperty('hideGraph');
      }
    }
  });

  _exports.default = _default;
});