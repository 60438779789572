define("recruit/components/candidate-helpers/assessment-candidate-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userPermission: Ember.inject.service('user-permission'),
    dataTooltip: Ember.computed(function () {
      return "<div style=\"width: 250px;\"><b>Invited At:</b> ".concat(this.get('model.invited_at').format("Do MMM YY"), " at ").concat(this.get('model.invited_at').format('h:mm A'), "\n    <br><b>Updated At:</b> ").concat(this.get('model.updated_at').format("Do MMM YY"), "  at ").concat(this.get('model.updated_at').format('h:mm A'), "</div>");
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.next('afterRender', function () {
        Ember.$('.time-tooltip').popup({
          position: 'bottom left'
        });
      });
      this.set('hasResumeMatch', this.get('userPermission.hasResumeMatch'));
    },
    actions: {
      back: function back() {
        this.sendAction('back');
      }
    }
  });

  _exports.default = _default;
});