define("recruit/templates/components/events/panel-match-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WwcsKQ5f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"sectionsLoaded\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"class\",[26,[\"text-size-small \",[25,\"if\",[[25,\"eq\",[[20,[\"currentUser\",\"me\",\"id\"]],[20,[\"assessment\",\"owned_by\",\"id\"]]],null],\"text-blue\"],null]]]],[3,\"action\",[[19,0,[]],\"toggleModal\",\"showPopup\"]],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ellipsis\"],[9,\"style\",\"max-width: 240px;\"],[7],[1,[18,\"matchedSections\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"events-panel-match-popup popup\"],[7],[0,\"\\n    \"],[1,[25,\"events/panel-match-popup\",null,[[\"eventModel\",\"assessment\",\"isClickable\",\"matchedObject\",\"threshold\",\"assessmentSection\"],[[20,[\"eventModel\"]],[20,[\"assessment\"]],[20,[\"isClickable\"]],[20,[\"matchedObject\"]],[20,[\"threshold\"]],[20,[\"assessmentSection\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-black text-size-vsmall\"],[7],[6,\"i\"],[7],[0,\"Loading sections...\"],[8],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/panel-match-sections.hbs"
    }
  });

  _exports.default = _default;
});