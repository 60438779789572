define("recruit/controllers/user/settings/password", ["exports", "recruit/controllers/master"], function (_exports, _master) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _master.default.extend({
    actions: {
      updatePassword: function updatePassword(changeset) {
        var _this = this;

        var snapshot = changeset.snapshot();
        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              _this.get('toast').success('Successfuly password changed!');
            }).catch(function (e) {
              var toast = _this.get('toast');

              if (!e || !e.errors) {
                toast.error('There was an error Processing your request', 'Error!');
              } else {
                e.errors.forEach(function (elem) {
                  toast.warning(elem.detail, elem.field);
                });
              }

              changeset.restore(snapshot);
            });
          } else {
            //changeset.errors
            _this.get('toast').error('', 'error');
          }
        });
      }
    }
  });

  _exports.default = _default;
});