define("recruit/templates/components/live-schedule/calendar-occurrence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G/YUySjO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"as-calendar-occurrence__container\"],[7],[0,\"\\n\"],[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"h1\"],[9,\"class\",\"as-calendar-occurrence__title \"],[10,\"data-tooltip\",[26,[[25,\"moment-format\",[[20,[\"tempLiveSession\",\"content\",\"startsAt\"]],\"hh:mm A\"],[[\"timeZone\"],[[20,[\"timeZone\"]]]]],\" -\",[25,\"moment-format\",[[20,[\"tempLiveSession\",\"content\",\"endsAt\"]],\"hh:mm A\"],[[\"timeZone\"],[[20,[\"timeZone\"]]]]]]]],[7],[1,[18,\"title\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[20,[\"isInteracting\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"isRemovable\"]]],null,{\"statements\":[[0,\"      \"],[6,\"a\"],[9,\"class\",\"as-calendar-occurrence__remove\"],[3,\"action\",[[19,0,[]],\"remove\"],[[\"bubbles\"],[false]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[20,[\"showPopUp\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"live-schedule/calendar-card\",null,[[\"tempLiveSession\",\"timeZone\",\"saveModel\",\"revertModel\"],[[20,[\"tempLiveSession\"]],[20,[\"timeZone\"]],\"saveModel\",\"revertModel\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-schedule/calendar-occurrence.hbs"
    }
  });

  _exports.default = _default;
});