define("recruit/routes/integration/live-schedule", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/routes/recruiter/live-schedule/form"], function (_exports, _authenticatedRouteMixin, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _form.default.extend(_authenticatedRouteMixin.default, {
    templateName: 'recruiter/live-schedule/form'
  });

  _exports.default = _default;
});