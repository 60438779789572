define("recruit/models/notification-log", ["exports", "ember-data", "npm:lodash"], function (_exports, _emberData, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    to_address: _emberData.default.attr('string'),
    cc_address: _emberData.default.attr('string'),
    assessment: _emberData.default.belongsTo('assessment', {
      inverse: null
    }),
    bcc_address: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('pgdate'),
    created_by: _emberData.default.belongsTo('user'),
    sent_by: _emberData.default.belongsTo('user'),
    updated_at: _emberData.default.attr('pgdate'),
    status: _emberData.default.attr('string'),
    scheduled_at: _emberData.default.attr('pgdate'),
    sent_at: _emberData.default.attr('pgdate'),
    read_at: _emberData.default.attr('pgdate'),
    subject: _emberData.default.attr('string'),
    notification_template_type_id: _emberData.default.attr('string'),
    notificationStatus: Ember.computed('status', function () {
      switch (this.get('status').toLowerCase()) {
        case 'enqueued':
          return 'Queued';

        case 'sent':
          return 'Sent';

        case 'delivered':
          return 'Delivered';

        case 'failed':
          return 'Failed';

        case 'bounced':
          return 'Bounced';

        case 'read':
          return 'Read';

        case 'archived':
          return 'Archived';

        case 'scheduled':
          return 'Scheduled';

        case 'deleted':
          return 'Deleted';
      }
    }),
    smsNotification: Ember.computed('notification_template_type_id', function () {
      return _npmLodash.default.includes(['901', '902', '903', '904', '905'], this.get('notification_template_type_id'));
    }),
    templateType: Ember.computed('notification_template_type_id', function () {
      return this.get('store').query('notification-template-type', {
        'type_id': this.get('notification_template_type_id')
      });
    })
  });

  _exports.default = _default;
});