define("recruit/templates/components/live-session/helpers/live-candidate-sections-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9xyqTXwi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"text-center live-candidate-sections-icon live-sections-bg-\",[18,\"statusColor\"]]]],[7],[0,\"\\n  \"],[1,[25,\"fa-icon\",[[20,[\"assessmentSectionCandidates\",\"assessmentSection\",\"section\",\"sectionType\",\"icon\"]]],[[\"class\"],[\"text-white text-size-small margin-top-five\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[25,\"not-eq\",[[20,[\"index\"]],[25,\"dec\",[[20,[\"length\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[6,\"hr\"],[10,\"class\",[26,[\"live-candidate-sections-line live-sections-color-\",[18,\"statusColor\"]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-session/helpers/live-candidate-sections-icon.hbs"
    }
  });

  _exports.default = _default;
});