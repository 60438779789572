define("recruit/models/live-attendee", ["exports", "ember-data", "recruit/mixins/profile-pic-initials"], function (_exports, _emberData, _profilePicInitials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_profilePicInitials.default, {
    currentUser: Ember.inject.service(),
    live_session_id: _emberData.default.attr('number'),
    email: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    role: _emberData.default.attr('number'),
    status: _emberData.default.attr('number'),
    joined_at: _emberData.default.attr('pgdate'),
    user: _emberData.default.belongsTo('user'),
    left_at: _emberData.default.attr('pgdate'),
    passcode: _emberData.default.attr('string'),
    joinUrl: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    liveAttendeeFlash: _emberData.default.belongsTo('live-attendee-flash'),
    liveSession: _emberData.default.belongsTo('live-session'),
    profile_pic: _emberData.default.attr('string', {
      default: null
    }),
    profilePicSource: Ember.computed.alias('profile_pic'),
    candidate: _emberData.default.belongsTo('candidate'),
    currentId: Ember.computed.alias('id'),
    // isFlashView: Ember.computed('liveSession.version', function() {
    //   return this.get('liveSession.version') == 1;
    // }),
    attendeeStatus: Ember.computed('status', function () {
      switch (this.get('status')) {
        case 1:
          return 'Inactive';

        case 2:
          return 'Active';

        case 3:
          return 'Left';

        case 10:
          return 'Cancelled';

        case 11:
          return 'No Show';

        case null:
          return 'Inactive';
      }
    }),
    isAttendeeInactive: Ember.computed('status', function () {
      return this.get('status') === 1 || this.get('status') === null;
    }),
    isAttendeeActive: Ember.computed('status', function () {
      return this.get('status') === 2;
    }),
    isWebRTCView: Ember.computed('liveSession.version', function () {
      return this.get('liveSession.version') == 2;
    }),
    isCurrentAttendee: Ember.computed(function () {
      return this.get('currentUser.me.id') == this.get('user.id');
    })
  });

  _exports.default = _default;
});