define("recruit/helpers/sanitize-html", ["exports", "ember-sanitize/utils/sanitize", "ember-getowner-polyfill"], function (_exports, _sanitize, _emberGetownerPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var config,
          configName = params[1];

      if (configName) {
        //lookup the config
        config = (0, _emberGetownerPolyfill.default)(this).lookup('sanitizer:' + configName);
      }

      var sanitized = (0, _sanitize.sanitize)(params[0], config);
      return new Ember.String.htmlSafe(sanitized);
    }
  });

  _exports.default = _default;
});