define("recruit/templates/components/helpers/row-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XU7EcmMv",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"ui-checkbox\",null,[[\"name\",\"checked\",\"class\"],[\"checkBox\",[20,[\"checked\"]],\"checkbox-circle\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/row-checkbox.hbs"
    }
  });

  _exports.default = _default;
});