define("recruit/components/video-flowplayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: [],
    classNameBindings: ['customClass'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('video')) {
        this.get('video').then(function (video) {
          _this.$().flowplayer({
            key: "$596781335969285",
            ratio: 0.75,
            embed: false,
            share: false,
            autoplay: false,
            clip: {
              sources: video
            }
          });
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$().flowplayer().stop();
    }
  });

  _exports.default = _default;
});