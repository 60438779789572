define("recruit/models/form", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('string'),
    end_date: _emberData.default.attr('string'),
    is_public: _emberData.default.attr('boolean'),
    is_editable: _emberData.default.attr('boolean'),
    url: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    custom_url: _emberData.default.attr('string'),
    fieldKeys: _emberData.default.attr(),
    dummyUrl: _emberData.default.attr('string', {
      defaultValue: 'https://forms.talview.com/evaluation/cognizant/484/hr-ios-india/index.html'
    })
  });

  _exports.default = _default;
});