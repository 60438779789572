define("recruit/routes/user/settings/account", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/validations/user-account"], function (_exports, _authenticatedRouteMixin, _userAccount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    UserAccountValidations: _userAccount.default,
    model: function model() {
      return Ember.RSVP.hash({
        currentUser: this.get('session.user'),
        settingsAllowed: this.get('store').query('settingsAllowed', {
          settings_id: 1
        }),
        timeZone: this.get('store').query('settingsData', {
          settings_id: 1
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);

      controller.set('model', models.currentUser);
      controller.set('settingsAllowed', models.settingsAllowed);
      controller.set('timeZone', models.timeZone);
      controller.set('UserAccountValidations', _userAccount.default);
    }
  });

  _exports.default = _default;
});