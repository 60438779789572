define("recruit/models/live-flash-recording", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    endTime: _emberData.default.attr('number'),
    meeting_id: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    playbackFormatLength: _emberData.default.attr('string'),
    playbackFormatType: _emberData.default.attr('string'),
    playbackFormatUrl: _emberData.default.attr('string'),
    playbackUrl: _emberData.default.attr('string'),
    record_id: _emberData.default.attr('string'),
    startTime: _emberData.default.attr('number'),
    startsAt: Ember.computed(function () {
      return (0, _moment.default)(this.get('startTime'));
    }),
    endsAt: Ember.computed(function () {
      return (0, _moment.default)(this.get('endTime'));
    })
  });

  _exports.default = _default;
});