define("recruit/components/live-session/join-web-rtc", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global JitsiMeetExternalAPI */
  var _default = Ember.Component.extend({
    isIE: Ember.computed(function () {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');

      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');

      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      } // other browser


      return false;
    }),
    didInsertElement: function didInsertElement() {
      this.set('sessionJoined', false);
      var domain = !this.get('isIE') ? this.get('liveSession.domain') : 'meet-ie.talview.com';
      var options = {
        roomName: this.get('liveSession.name'),
        parentNode: document.getElementById('meet_talview')
      };

      if (!_npmLodash.default.isNil(this.get('model.token'))) {
        options.jwt = this.get('model.token');
      }

      var jitsiApi = new JitsiMeetExternalAPI(domain, options);
      this.set('jitsiApi', jitsiApi);
      jitsiApi.addEventListener('videoConferenceJoined', this.videoConferenceJoined.bind(this));
      jitsiApi.addEventListener('readyToClose', this.readyToClose.bind(this));
      jitsiApi.executeCommands({
        displayName: this.get('userName'),
        email: this.get('userEmail')
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.get('jitsiApi').executeCommand('displayName', this.get('userName'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('jitsiApi').dispose();
    },
    videoConferenceJoined: function videoConferenceJoined() {
      var _this = this;

      this.set('sessionJoined', true);

      if (this.get('model.status') != 2) {
        setTimeout(function () {
          _this.set('model.status', 2);

          _this.get('model').save();
        }, 90000);
      }
    },
    readyToClose: function readyToClose() {
      this.set('exitSession', true);
    }
  });

  _exports.default = _default;
});