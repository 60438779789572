define("recruit/routes/recruiter/assessment/create", ["exports", "ember-cli-uuid", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberCliUuid, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    userPermissions: Ember.inject.service('user-permission'),
    model: function model() {
      return this.get('store').createRecord('actions/create-assessment');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', model);
      controller.set('selectedAssessment', null);
    },
    actions: {
      createAssessment: function createAssessment(model) {
        var _this = this;

        if (model.get('title')) {
          this.controller.set('isLoading', true);
          model.setProperties({
            "templateID": this.controller.get('selectedAssessment.id'),
            "sync": true
          });
          model.save().then(function (response) {
            if (response.get('status') == 99) {
              _this.get('toast').error(response.get('response'));
            } else {
              _this.get('toast').success('Assessment successfully created');

              response.get('assessment').then(function (assessment) {
                if (assessment.get('id')) {
                  _this.get('router').transitionTo('recruiter.assessment.details.assessment-update', assessment, {
                    queryParams: {
                      "updateJd": _this.get('userPermissions.hasResumeMatch')
                    }
                  });
                } else {
                  _this.get('router').transitionTo('recruiter.assessment');
                }
              });
            }
          }).catch(function (e) {
            if (e.errors) {
              e.errors.forEach(function (error) {
                _this.get('toast').error(error.detail, 'Please try again');
              });
            }
          }).finally(function () {
            _this.controller.set('isLoading', false);
          });
        } else {
          Ember.$('#assessmentTitle').focus();
          this.get('toast').error('Title can not be blank');
        }
      },
      createNewAssessment: function createNewAssessment(url) {
        var _this2 = this;

        this.get('store').createRecord('lf-adapter/assessment-creation', {
          id: (0, _emberCliUuid.uuid)(),
          currentSlide: 0
        }).save().then(function (response) {
          _this2.get('router').transitionTo(url, response.get('id'));
        }).catch(function () {
          _this2.get('toast').error('Something went wrong.');
        });
      },
      cancel: function cancel() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});