define("recruit/routes/recruiter/assessment/details/overview", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('isDevelopment', _environment.default.isDevelopment);
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});