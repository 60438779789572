define("recruit/components/recruiter-view/actions/add-to-event", ["exports", "npm:lodash", "recruit/mixins/g-actions"], function (_exports, _npmLodash, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.getActionType();
      this.set('isLoading', false);
      this.set('selectedOption', null);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.getEventUsers();
    },
    disabled: Ember.computed.oneWay('addedList.length'),
    getEventUsers: function () {
      var _this = this;

      this.get('model.events.firstObject.panel').then(function (panels) {
        _this.get('model.events.firstObject.recruiter').then(function (recruiters) {
          _this.set('currentRecruiter', Ember.A([]));

          if (panels.get('length')) {
            panels.forEach(function (panel) {
              if (!_this.get('currentRecruiter').findBy('user.id', panel.get('user.id'))) {
                _this.get('currentRecruiter').pushObject(panel.get('user'));
              }
            });
          }

          if (recruiters.get('length')) {
            recruiters.forEach(function (recruiter) {
              if (!_this.get('currentRecruiter').findBy('user.id', recruiter.get('user.id'))) {
                _this.get('currentRecruiter').pushObject(recruiter.get('user'));
              }
            });
          }
        });
      });
    }.observes('model.events.firstObject.panel.isFulfilled', 'model.events.firstObject.recruiter.isFulfilled'),
    dropdownLabel: Ember.computed('actionType', function () {
      var actionType = this.get('actionType').toLowerCase();

      switch (actionType) {
        case 'assessment':
          return 'Select an assessment to add';

        case 'recruiter':
          return 'Select a recruiter';

        case 'panel':
          return 'Select a panel member';

        default:
          return 'assessment';
      }
    }),
    disabledItemsObserver: Ember.observer('actionType', 'selectedListValues.isFulfilled', 'selectedListValues.[].assessment', 'model.events.firstObject.isFulfilled', function () {
      var _this2 = this;

      if (this.get('actionType') == 'assessment') {
        this.get('selectedListValues').forEach(function (eventAssessment) {
          return eventAssessment.get('assessment').then(function (assessment) {
            return assessment && _this2.get('disabledItems').pushObject(assessment);
          });
        });
      }
    }).on('afterRender', 'init'),
    getActionType: function getActionType() {
      var actionType = this.get('model.actionType').toLowerCase();

      switch (actionType) {
        case 'add assessments to the event':
          return this.set('actionType', 'assessment');

        case 'invite recruiters to the event':
          return this.set('actionType', 'recruiter');

        case 'invite panel to the event':
          return this.set('actionType', 'panel');

        default:
          return this.set('actionType', 'assessment');
      }
    },
    optionLabel: Ember.computed('actionType', function () {
      var actionType = this.get('actionType').toLowerCase();

      switch (actionType) {
        case 'assessment':
          return 'titleWithId';

        case 'recruiter':
          return 'nameWithEmail';

        case 'panel':
          return 'nameWithEmail';

        default:
          return 'titleWithId';
      }
    }),
    roles: Ember.computed('actionType', function () {
      var actionType = this.get('actionType').toLowerCase();

      switch (actionType) {
        case 'recruiter':
          return "Recruiter";

        case 'panel':
          return "Evaluator";

        default:
          return null;
      }
    }),
    save: function save(endPoint, key, model, role, status) {
      var _this$get$createRecor;

      return this.get('store').createRecord(endPoint, (_this$get$createRecor = {
        event: this.get('model.events.firstObject')
      }, _defineProperty(_this$get$createRecor, key, model), _defineProperty(_this$get$createRecor, "role", role), _defineProperty(_this$get$createRecor, "status", status), _this$get$createRecor)).save();
    },
    initSaving: function initSaving(data) {
      var _this3 = this;

      return Ember.RSVP.Promise.all(data.get('addedList').map(function (model) {
        return _this3.save(data.get('endPoint'), data.get('key'), model, data.get('role'), data.get('status')).then(function (response) {
          if (_this3.get('actionType') == 'assessment') {
            _this3.get('model.eventAssessmentsCount').pushObject(response);

            return _this3.checkRecruiter(model, data);
          } else {
            return response;
          }
        }).catch(function (e) {
          return _this3.catchError(e);
        });
      })).then(function (response) {
        if (_npmLodash.default.some(response)) {
          _this3.send('success', data.label);
        }
      }).catch(function (e) {
        _this3.set('isLoading', false);

        return _this3.catchError(e);
      }); // .finally(() => this.set('isLoading', false))
    },
    checkRecruiter: function checkRecruiter(assessment) {
      var _this4 = this;

      return this.get('model.events.firstObject.recruiter').then(function () {
        return assessment.get('owned_by').then(function (user) {
          if (!_this4.get('currentRecruiter').findBy('id', user.get('id'))) {
            _this4.get('currentRecruiter').pushObject(user); //To store recently added event-user


            return _this4.save('event-user', 'user', user, 'Recruiter', 1);
          } else {
            return true;
          }
        });
      });
    },
    getContext: function getContext(endPoint, key, label, addedList, role, status) {
      return Ember.Object.create({
        endPoint: endPoint,
        key: key,
        label: label,
        addedList: addedList,
        role: role,
        status: status
      });
    },
    actions: {
      refetchEventDetails: function refetchEventDetails() {
        var _this5 = this;

        return this.get('model.events.firstObject').reloadEventRelations().then(function () {
          return _this5.send('back');
        }).finally(function () {
          return _this5.set('isLoading', false);
        });
      },
      addItem: function addItem(item, type) {
        if (this.get('actionType') == 'assessment') {
          this.sendAction('addItem', item, 'disabledItems');
        } else {
          this.sendAction('addItem', item, 'currentRecruiter');
        }

        if (type == 'assessment') {
          this.set('selectedOption', null);
        }
      },
      success: function success(label) {
        this.get('toast').success(label);
        this.send('refetchEventDetails'); // this.send('back')
      },
      add: function add() {
        this.set('isLoading', true);
        var data = {};

        if (this.get('actionType') == 'recruiter') {
          data = this.getContext('event-recruiter', 'user', "Recruiter(s) invited.", this.get('addedList'), 'Recruiter', 2);
        } else if (this.get('actionType') == 'assessment') {
          data = this.getContext('event-assessment', 'assessment', "Assessment(s) added.", this.get('addedList'));
        } else if (this.get('actionType') == 'panel') {
          data = this.getContext('event-panel', 'user', "Panel(s) invited.", this.get('addedList'), this.get('roles'), 1);
        }

        return this.initSaving(data);
      }
    }
  });

  _exports.default = _default;
});