define("recruit/routes/user/change-role", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    userRole: Ember.inject.service(),
    queryParams: {
      redirectPath: {
        refreshModel: true
      },
      redirectParams: {
        refreshModel: true
      },
      redirectURL: {
        refreshModel: true
      }
    },
    model: function model() {
      return this.get('session.user');
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      controller.set('redirectURL', transition.queryParams.redirectURL);
      controller.set('redirectPath', transition.queryParams.redirectPath);
      controller.set('redirectParams', transition.queryParams.redirectParams);
      controller.set('currentRole', this.get('userRole.currentRole'));
      controller.set('isLoading', false);
    },
    actions: {
      updateRole: function updateRole(currentRole) {
        this.get('userRole').update(currentRole);

        if (this.controller.get('redirectPath') && this.controller.get('redirectParams')) {
          this.transitionTo(this.controller.get('redirectPath'), {
            queryParams: this.controller.get('redirectParams')
          });
        } else {
          if (this.controller.get('redirectURL')) {
            this.transitionTo(this.controller.get('redirectURL'));
          } else {
            this.transitionTo('dashboard');
          }
        }
      },
      back: function back(model) {
        model.rollbackAttributes();
        history.back();
      }
    }
  });

  _exports.default = _default;
});