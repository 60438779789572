define("recruit/templates/components/assessment/assessment-section-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qa6y4x4C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments assessment-card\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[7],[0,\"\\n\"],[0,\"  \\t\"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n  \\t\\t\"],[6,\"div\"],[9,\"class\",\"one wide column padding-no text-center\"],[9,\"style\",\"width: 50px !important;\"],[7],[0,\"\\n        \"],[6,\"img\"],[10,\"src\",[26,[\"/images/section_icons/\",[20,[\"sectionModel\",\"sectionType\",\"name\"]],\".svg\"]]],[9,\"style\",\"height: 35px; margin-right: 5px;\"],[7],[8],[0,\"\\n  \\t\\t\"],[8],[0,\"\\n  \\t\\t\"],[6,\"div\"],[9,\"class\",\"five wide column padding-no\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"display-inline-block editable-text\"],[9,\"data-tooltip\",\"Set section name\"],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n          \"],[1,[25,\"input\",null,[[\"type\",\"placeholder\",\"class\",\"value\",\"autofocus\"],[\"text\",\"Untitled Section\",\"text-black text-size-normal text-weight-medium editable-input-field\",[20,[\"sectionModel\",\"title\"]],true]]],false],[0,\"\\n          \"],[1,[25,\"fa-icon\",[\"pencil\"],[[\"class\"],[\"text-size-small text-blue edit-icon\"]]],false],[0,\"\\n        \"],[8],[0,\"\\n\\t  \\t\\t\"],[6,\"div\"],[9,\"class\",\"text-black text-size-small\"],[7],[1,[20,[\"sectionModel\",\"sectionType\",\"name\"]],false],[8],[0,\"\\n  \\t\\t\"],[8],[0,\"\\n  \\t\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment assessment-card-section\"],[7],[0,\"\\n    \"],[1,[25,\"assessment/assessment-section-question\",null,[[\"assessmentModel\",\"sectionModel\",\"availableSections\",\"pearsonAssessments\",\"index\"],[[20,[\"assessmentModel\"]],[20,[\"sectionModel\"]],[20,[\"availableSections\"]],[20,[\"pearsonAssessments\"]],[20,[\"index\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/assessment/assessment-section-card.hbs"
    }
  });

  _exports.default = _default;
});