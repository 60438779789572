define("recruit/routes/recruiter/recruiter-actions/actions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('modal-actions/recruiter/actions', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', model);
    },
    deactivate: function deactivate() {
      this.controller.get('model').destroyRecord();
    }
  });

  _exports.default = _default;
});