define("recruit/templates/components/filters/quick-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g8AXGLvp",
    "block": "{\"symbols\":[\"filter\",\"filter\"],\"statements\":[[6,\"div\"],[9,\"class\",\"tv-filter-skin\"],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"text-uppercase text-size-vsmall margin-bottom-five text-black\"],[7],[0,\"Saved filters\"],[8],[0,\"\\n\"],[4,\"ui-dropdown\",null,[[\"class\",\"selected\",\"onChange\"],[\"selection full-width\",\"Bob\",[25,\"action\",[[19,0,[]],\"setQuickFilter\"],null]]],{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"default text\"],[7],[0,\"Select Filter\"],[8],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"dropdown icon\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"menu\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"header\"],[7],[0,\"\\n        \"],[1,[25,\"fa-icon\",[\"tag\"],[[\"class\"],[\"margin-right-five\"]]],false],[0,\"\\n        Default filters\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"divider\"],[7],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"quickFilters\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"item\"],[10,\"data-value\",[19,2,[\"value\"]],null],[7],[1,[19,2,[\"key\"]],false],[8],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[6,\"div\"],[9,\"class\",\"header\"],[7],[0,\"\\n        \"],[1,[25,\"fa-icon\",[\"tag\"],[[\"class\"],[\"margin-right-five\"]]],false],[0,\"\\n        Your filters\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"divider\"],[7],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"savedFilters\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"item\"],[10,\"data-value\",[19,1,[\"value\"]],null],[7],[1,[19,1,[\"field\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/filters/quick-filters.hbs"
    }
  });

  _exports.default = _default;
});