define("recruit/serializers/dashboard/count", ["exports", "recruit/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: "pendingCandidateCount",
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload = [payload];
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});