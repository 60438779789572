define("recruit/components/action-wrapper/simulation-modal/simulation-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    isNotify: true,
    actions: {
      closeModal: function closeModal(modalProperty) {
        this.sendAction('toggleModal', modalProperty);
      },
      cancelSession: function cancelSession(assc) {
        var _this = this;

        if (assc.get('status.id') == 10) {
          this.get('toast').error('Simulation session id:' + assc.get('id'), 'The session is already cancelled');
          this.sendAction('toggleModal', this.get('modalProperty'));
        } else {
          // let asscIds = this.get('assc').map((ls) => ls.get('id'));
          this.get('store').createRecord('actions/simulation-cancel', {
            assessmentSectionCandidateID: assc.get('id'),
            isNotify: this.get('isNotify')
          }).save().then(function () {
            _this.get('toast').success('Live session cancelled', 'Successful');

            return _this.send('closeModal', 'Cancel');
          }).catch(function (e) {
            if (e.errors) {
              e.errors.forEach(function (error) {
                _this.get('toast').error(error.detail, 'Error!');
              });
            } else {
              _this.get('toast').error('Please try after some time', 'Something went wrong');
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});