define("recruit/components/recruiter-view/bulk/import-results", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasAssessment: false,
    userPermission: Ember.inject.service('user-permission'),
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.menu .item').tab();
    },
    failedRecords: Ember.computed(function () {
      if (this.get('feErrors.length') || this.get('bcErrors.length')) {
        return _npmLodash.default.orderBy(this.get('feErrors').concat(this.get('bcErrors')), 'rowCount', [true, false]);
      } else {
        return 0;
      }
    }),
    actions: {
      bulkImportCandidates: function bulkImportCandidates(property) {
        this.sendAction('bulkImportCandidates', property);
      },
      close: function close() {
        return history.back();
      },
      uploadAgain: function uploadAgain() {
        this.sendAction('importAgain');
      },
      completedView: function completedView() {
        this.sendAction('completedView', true);
      },
      inviteToAssessment: function inviteToAssessment(candidates) {
        this.sendAction('inviteToAssessment', candidates);
      }
    }
  });

  _exports.default = _default;
});