define("recruit/templates/recruiter/assessment/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EDB1Lq99",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Assessments\"],null],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"margin-top-hundred\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui grid container\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[9,\"style\",\"box-shadow:2px 2px 2px rgba(0,0,0,0.3); padding:0; margin-top:15px;\"],[7],[0,\"\\n      \"],[1,[25,\"filters/master-filter\",null,[[\"searchText\",\"masterFilters\",\"appliedFilters\",\"objectType\",\"masterColumns\",\"columns\",\"filters\"],[[20,[\"searchText\"]],[20,[\"masterFilters\"]],[20,[\"appliedFilters\"]],\"a\",[20,[\"model\",\"columnMaster\"]],[20,[\"columns\"]],[20,[\"filters\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"twelve wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui segment padding-no\"],[7],[0,\"\\n        \"],[1,[25,\"model-table/recruiter/assessment\",null,[[\"currentUser\",\"appliedFilters\",\"searchText\"],[[20,[\"currentUser\"]],[20,[\"appliedFilters\"]],[20,[\"searchText\"]]]]],false],[0,\"\\n\"],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/assessment/index.hbs"
    }
  });

  _exports.default = _default;
});