define("recruit/models/performance", ["exports", "ember-data", "recruit/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    application: _emberData.default.attr('string', {
      defaultValue: 'Recruit'
    }),
    name: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    user_id: _emberData.default.attr('number', {
      defaultValue: function defaultValue(self) {
        return self.get('currentUser.me.id');
      }
    }),
    organization_id: _emberData.default.attr('number', {
      defaultValue: function defaultValue(self) {
        return self.get('currentUser.me.organization.id');
      }
    }),
    domain: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return window.location.host;
      }
    }),
    version: _emberData.default.attr('string', {
      defaultValue: _environment.default.APP.VERSION
    })
  });

  _exports.default = _default;
});