define("recruit/templates/recruiter/assessment/details/job-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B21IwO9c",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment padding-bg-left padding-bg-right\"],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"text-blue text-weight-medium cursor-pointer\"],[3,\"action\",[[19,0,[]],\"back\"]],[7],[6,\"i\"],[9,\"class\",\"angle left icon\"],[7],[8],[0,\"Go Back\"],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"text-black text-weight-medium\"],[9,\"style\",\"float:right\"],[7],[0,\"JOB DESCRIPTION\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment grey-background padding-bg-left padding-bg-right\"],[9,\"style\",\"background:#F0F0F0\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"padding-vs-left padding-vs-right text-blue text-weight-medium text-size-medium padding-sm-top padding-vs-bottom\"],[7],[1,[20,[\"model\",\"title\"]],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"padding-vs-left padding-vs-right padding-sm-bottom\"],[7],[0,\"Reference ID : \"],[1,[25,\"if\",[[20,[\"model\",\"external_id\"]],[20,[\"model\",\"external_id\"]],\" __\"],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment\"],[9,\"style\",\"height:calc(100vh - 300px)\"],[7],[0,\"\\n    \"],[1,[25,\"iframe-html\",null,[[\"src\",\"class\"],[[20,[\"model\",\"formattedDescription\"]],\"iframe-html-height padding-sm-left padding-sm-right\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/assessment/details/job-description.hbs"
    }
  });

  _exports.default = _default;
});