define("recruit/templates/recruiter/events/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T6UHb7LX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"inverted-header\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"events-header\"],[7],[0,\"\\n    \"],[1,[25,\"events/event-creation-header\",null,[[\"event\"],[[20,[\"event\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[1,[25,\"events/event-details\",null,[[\"event\"],[[20,[\"event\"]]]]],false],[0,\"\\n\"],[1,[25,\"events/assessment-view\",null,[[\"class\",\"event\",\"currentUser\",\"userRole\"],[\"ui segments border-none box-shadow-none\",[20,[\"event\"]],[20,[\"currentUser\"]],[20,[\"userRole\"]]]]],false],[0,\"\\n\"],[1,[18,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/events/edit.hbs"
    }
  });

  _exports.default = _default;
});