define("recruit/routes/panel/candidate/evaluate/index", ["exports", "recruit/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.get('store').findRecord('panelCandidate', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('isComponentLoading', true);
      controller.set('assessmentPending', false);
      controller.set('isDevelopment', _environment.default.isDevelopment);
      controller.set('panelCandidate', model); //Force load other invitations

      model.get('assessmentCandidate').then(function (ac) {
        return ac.get('assessmentSectionCandidates').then(function (asscs) {
          Ember.RSVP.resolve(asscs);
        });
      });
      return model.get('assessmentSectionCandidate').then(function (assc) {
        controller.set('selectedAssc', assc); // controller.set('answers', assc.get('answers').filterBy('status', 3000))

        return Ember.RSVP.Promise.resolve().then(function () {
          return Ember.RSVP.hash({
            section: assc.get('section'),
            status: assc.get('status')
          });
        }).then(function (_ref) {
          var section = _ref.section;
          controller.set('currentSection', section);
          controller.set('assessmentPending', !assc.get('showAnswers'));
        }).then(function () {
          return assc.get('answers');
        });
      }).then(function (answers) {
        controller.set('answers', answers.filterBy('status', 3000));
      }).finally(function () {
        controller.set('isComponentLoading', false);
      });
    },
    actions: {
      switchCandidate: function switchCandidate(panelCandidate) {
        return this.transitionTo('panel.candidate.evaluate.index', panelCandidate.get('id'));
      },
      toggleModal: function toggleModal(type) {
        this.controller.toggleProperty(type);
      }
    }
  });

  _exports.default = _default;
});