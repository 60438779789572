define("recruit/components/reports/helpers/time-row-component", ["exports", "moment", "npm:moment-duration-format"], function (_exports, _moment, _npmMomentDurationFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      (0, _npmMomentDurationFormat.default)(_moment.default);
    },
    timeValue: Ember.computed(function () {
      if (this.get('column.selectedGraphFilter') == 'hours') {
        return parseInt(_moment.default.duration(this.get('value'), 'seconds').asHours()) + " h";
      } else {
        return parseInt(_moment.default.duration(this.get('value'), 'seconds').asDays()) + " d";
      }
    }),
    tooltip_text: Ember.computed(function () {
      if (this.get('column.selectedGraphFilter') == 'hours') {
        return _moment.default.duration(this.get('value'), 'seconds').format("h [hours] m [minutes]");
      } else {
        return _moment.default.duration(this.get('value'), 'seconds').format("d [days] h [hours] m [minutes]");
      }
    })
  });

  _exports.default = _default;
});