define("recruit/components/google-maps-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global google*/
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.fetchAddress();
      Ember.run.next('afterRender', function () {
        if (Ember.$('#location').length) {
          Ember.$('#location').focus();
        }
      });
    },
    searchTextObserver: Ember.observer('searchText', function () {
      var _this = this;

      if (this.get('searchText')) {
        var service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions({
          input: this.get('searchText')
        }, function (results, status) {
          Ember.run.next('afterRender', function () {
            if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              _this.resetProperty();

              _this.set('noLocationFound', true);

              _this.set('location.formattedAddress', _this.get('searchText'));
            } else {
              _this.set('noLocationFound', false);
            }
          });
        });
      } else {
        this.resetProperty();
      }
    }),
    fetchAddress: function fetchAddress() {
      var _this2 = this;

      var autocomplete;
      autocomplete = new google.maps.places.Autocomplete(document.getElementById('location')); // autocomplete.setFields(['id', 'geometry', 'icon', 'name']);

      autocomplete.addListener('place_changed', function () {
        var location = autocomplete.getPlace();

        if (location && location.geometry) {
          _this2.get('location').setProperties({
            "formattedAddress": location.formatted_address,
            "formattedPhoneNumber": location.formatted_phone_number,
            "place_id": location.place_id,
            "coordinate": location.geometry.location,
            "url": location.url
          });
        }
      });
    },
    setObject: function setObject() {
      this.set('location', Ember.Object.create({
        formattedAddress: null,
        formattedPhoneNumber: null,
        place_id: null,
        coordinate: null,
        url: null,
        description: null
      }));
    },
    resetProperty: function resetProperty() {
      var description = this.get('location.description');
      this.setObject();
      this.set('location.description', description);
    }
  });

  _exports.default = _default;
});