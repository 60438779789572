define("recruit/components/recruiter-view/questions/bulk-create/error-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      back: function back() {
        this.sendAction('back');
      },
      uploadAgain: function uploadAgain() {
        this.sendAction('uploadAgain');
      }
    }
  });

  _exports.default = _default;
});