define("recruit/controllers/recruiter/pending-candidates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchText: null,
    isPendingList: false,
    filters: null
  });

  _exports.default = _default;
});