define("recruit/templates/components/assessment-helpers/rating-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ihUEVWIv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"type\"]],\"Recommendation\"],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"round-checkbox\"],[7],[0,\"\\n\"],[4,\"ui-checkbox\",null,[[\"checked\",\"value\",\"disabled\"],[true,[20,[\"value\"]],true]],{\"statements\":[[0,\"      \"],[6,\"label\"],[9,\"class\",\"cursor-pointer text-black text-size-small\"],[7],[1,[18,\"type\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"round-checkbox cursor-pointer\"],[3,\"action\",[[19,0,[]],\"changeRatingType\",[20,[\"type\"]],[20,[\"value\"]]]],[7],[0,\"\\n\"],[4,\"ui-checkbox\",null,[[\"checked\",\"value\",\"disabled\"],[[20,[\"checked\"]],[20,[\"value\"]],[20,[\"disabled\"]]]],{\"statements\":[[0,\"      \"],[6,\"label\"],[9,\"class\",\"cursor-pointer text-black text-size-small\"],[7],[1,[18,\"type\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/assessment-helpers/rating-card.hbs"
    }
  });

  _exports.default = _default;
});