define("recruit/components/live-session/join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global JitsiMeetExternalAPI */
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var domain = 'meet.talview.com';
      var options = {
        roomName: this.get('sessionName'),
        parentNode: document.getElementById('meet_talview')
      };
      var jitsiApi = new JitsiMeetExternalAPI(domain, options);
      this.set('jitsiApi', jitsiApi);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.get('jitsiApi').executeCommand('displayName', this.get('userName'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('jitsiApi').dispose();
    }
  });

  _exports.default = _default;
});