define("recruit/components/events/panel-match-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['customStyle:style'],
    customStyle: Ember.computed('index', function () {
      if (this.get('threshold')) {
        if (this.get('index') < this.get('threshold')) {
          return new Ember.String.htmlSafe("right: ".concat(this.get('index') * 10, "px; position: relative;"));
        }

        return new Ember.String.htmlSafe("display: none !important");
      }

      return new Ember.String.htmlSafe("right: ".concat(this.get('index') * 10, "px; position: relative;"));
    }),
    tooltipData: Ember.computed(function () {
      return "\n    <div class=\"ui middle aligned grid margin-no\" style=\"min-width: 300px\">\n      <div class=\"three wide column padding-no\">\n        <img src='".concat(this.get('panel.user.profilePic'), "' style=\"width: 47px !important; height: 47px; border-radius: 25px;\">\n      </div>\n      <div class=\"thirteen wide column\" style=\"padding: 0px 10px;\">\n        <div class=\"text-black text-weight-medium\">").concat(this.get('panel.user.name'), "</div>\n        <div class=\"text-grey\">").concat(this.get('panel.user.email'), "</div>\n      </div>\n    </div>");
    }),
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('.panel-match-tooltip').popup();
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('index') >= this.get('threshold')) {
        if (this.get('extraPanels')) {
          this.get('extraPanels').pushObject(this.get('panel'));
        }
      }
    }
  });

  _exports.default = _default;
});