define("recruit/components/live-schedule/time-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    timeString: Ember.computed('time', function () {
      return this.get('time').format('h a');
    })
  });

  _exports.default = _default;
});