define("recruit/models/candidate-auth/identity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    date_of_birth: _emberData.default.attr('pgdate'),
    created_at: _emberData.default.attr('pgdate'),
    files: _emberData.default.hasMany('candidate-auth/file'),
    requests: _emberData.default.hasMany('candidate-auth/log'),
    documents: _emberData.default.hasMany('candidate-auth/document'),
    dob: Ember.computed.alias('date_of_birth'),
    logs: Ember.computed.alias('requests'),
    authPhoto: Ember.computed('files.isFulfilled', function () {
      return this.get('files').findBy('type', 1);
    }),
    authFile: Ember.computed('files.isFulfilled', function () {
      return this.get('files').findBy('type', 2);
    }),
    authAudio: Ember.computed('files.isFulfilled', function () {
      return this.get('files').findBy('type', 3);
    }),
    details: Ember.computed('documents.isFulfilled', function () {
      var _this = this;

      this.get('documents').then(function (docs) {
        var ID = docs.findBy('type_id', 2);

        if (ID) {
          _this.set('docType', 'Aadhaar');

          _this.set('details', ID.get('attr'));

          _this.set('identifier', ID.get('identifier'));
        }
      });
    })
  });

  _exports.default = _default;
});