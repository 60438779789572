define("recruit/components/candidate-helpers/resume-score-tooltip", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    eduStep: Ember.computed(function () {
      return _lodash.default.ceil(_lodash.default.round(this.get('row.educationMatchScore')) / 20) * 20 || 0;
    }),
    expStep: Ember.computed(function () {
      return _lodash.default.ceil(_lodash.default.round(this.get('row.experienceMatchScore')) / 20) * 20 || 0;
    }),
    skillStep: Ember.computed(function () {
      return _lodash.default.ceil(_lodash.default.round(this.get('row.skillMatchScore')) / 20) * 20 || 0;
    }),
    eduScore: Ember.computed(function () {
      return _lodash.default.round(this.get('row.educationMatchScore'));
    }),
    skillScore: Ember.computed(function () {
      return _lodash.default.round(this.get('row.skillMatchScore'));
    }),
    expScore: Ember.computed(function () {
      return _lodash.default.round(this.get('row.experienceMatchScore'));
    }),
    resumeScore: Ember.computed(function () {
      return _lodash.default.round(this.get('row.resumeMatchScore'));
    }),
    actions: {
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      }
    }
  });

  _exports.default = _default;
});