define("recruit/templates/panel/candidate/evaluate/hr-ios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wk9Vz/my",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/panel/candidate/evaluate/hr-ios.hbs"
    }
  });

  _exports.default = _default;
});