define("recruit/components/model-table/recruiter/event", ["exports", "recruit/mixins/table-common", "recruit/config/environment"], function (_exports, _tableCommon, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    isDevelopment: _environment.default.isDevelopment,
    modelType: 'expanded-event',
    columns: Ember.computed(function () {
      return [// {
      //   component : "helpers/table-checkboxs",
      //   cellComponent : "helpers/row-checkbox",
      //   sortable : false,
      //   width : '40px',
      //   classNames : ['table-head padding-no table-head-checkbox-th'],
      //   cellClassNames : ['child padding-no'],
      // },
      {
        label: 'Name',
        cellComponent: 'assessment-helpers/assessment-details',
        width: '200px',
        classNames: ['table-head text-left padding-bg-left'],
        cellClassNames: ['padding-bg-left']
      }, {
        label: 'Owner',
        valuePath: 'owned_by',
        width: '270px',
        cellComponent: 'user-helpers/user-details-cell-component',
        classNames: ['table-head text-center']
      }, {
        label: 'Current Summary',
        valuePath: 'id',
        cellComponent: 'recruiter-view/assessment/summary',
        width: '335px',
        classNames: ['table-head'],
        cellClassNames: ['text-center'],
        isHoldCount: true,
        isAddedCount: true
      }, {
        label: 'Event Date',
        cellComponent: 'helpers/date-range-view',
        width: '170px',
        showTimeAgo: false,
        dateFormat: 'DD MMM YYYY',
        valuePath: 'start_date',
        classNames: ['table-head'],
        cellClassNames: ['text-center']
      }, {
        label: 'Status',
        cellComponent: 'events/event-status',
        valuePath: 'selectedStatus',
        width: '150px',
        cellClassNames: ['text-center text-weight-light'],
        classNames: ['table-head']
      }];
    }),
    actions: {
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      },
      routeTransistion: function routeTransistion(row) {
        this.get('router').transitionTo('recruiter.events.details.overview', row.get('content.id'));
      }
    }
  });

  _exports.default = _default;
});