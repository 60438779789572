define("recruit/data/index", ["exports", "recruit/data/assessment-status", "recruit/data/section-type", "recruit/data/ac-advance-filters", "recruit/data/assc-advance-filters"], function (_exports, _assessmentStatus, _sectionType, _acAdvanceFilters, _asscAdvanceFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import columns from './columns';
  var _default = [].concat(_sectionType.default, _assessmentStatus.default, _acAdvanceFilters.default, _asscAdvanceFilters.default);

  _exports.default = _default;
});