define("recruit/components/model-table/recruiter/invites-row-component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    tagName: 'tr',
    classNameBindings: ['invite.isSelected:is-selected', 'invite.isMarkedForScroll:isMarkedForScroll'],
    checked: Ember.computed.alias('invite.isSelected'),
    performance: Ember.inject.service(),
    performanceObserver: Ember.observer('invite.isRelationsLoaded', function () {
      var _this = this;

      Ember.run.once('afterRender', function () {
        if (_this.get('invites.length') >= 25 && _this.get('invites.lastObject.id') == _this.get('invite.id') && _this.get('invite.isRelationsLoaded')) {
          _this.get('performance').end('Recruiter:Invites:list');

          _this.sendAction('onFirstPageRender');
        }
      });
    }).on('init'),
    click: function click(element) {
      if (element.target.parentElement.classList.contains('checkbox')) {
        return;
      }

      return this.get('router').transitionTo('recruiter.candidate.details.assessment', this.get('invite.candidate.id'), this.get('invite.id'));
    },
    actions: {
      onRowChecked: function onRowChecked(invite, event) {
        this.sendAction("onRowChecked", invite, event);
      }
    }
  });

  _exports.default = _default;
});