define("recruit/templates/components/reports/helpers/column-with-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1SQPNIMr",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"style\",\"padding-bottom: 5px;\"],[7],[1,[18,\"value\"],false],[8],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"float-right\"],[9,\"style\",\"padding-bottom: 5px; cursor: pointer;\"],[9,\"data-tooltip\",\"Resize table\"],[9,\"data-position\",\"left center\"],[3,\"action\",[[19,0,[]],[20,[\"tableActions\",\"toggleGraph\"]]]],[7],[0,\"\\n    \"],[1,[25,\"fa-icon\",[\"expand-arrows\"],[[\"class\"],[\"text-blue\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/column-with-toggle.hbs"
    }
  });

  _exports.default = _default;
});