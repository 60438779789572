define("recruit/components/assessment/update-assessment-section", ["exports", "npm:lodash", "recruit/mixins/assessment-section-settings"], function (_exports, _npmLodash, _assessmentSectionSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_assessmentSectionSettings.default, {
    user: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    _assessmentSectionObserver: Ember.observer('sectionModel.{title}', function () {
      this.set('isTyping', true);
      Ember.run.debounce(this, this.saveModel, 2000);
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('selectedAS.isExtraAs')) {
        this.set('selectedTab', 'question');
      } else {
        this.set('selectedTab', 'configuration');
      }

      this.get('user.me.organization').then(function (res) {
        res.getSectionTypeList(true);

        _this.set('availableSections', res.availableSections);
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      Ember.run.next('afterRender', function () {
        _this2.set('isExtraAs', _this2.get('selectedAS.isExtraAs'));
      });
    },
    saveModel: function saveModel() {
      var _this3 = this;

      if (this.get('sectionModel.content')) {
        this.get('sectionModel.content').save().then(function () {
          _this3.set('isTyping', false);

          _this3.set('isSectionUpdated', true);
        }).catch(function () {
          _this3.get('sectionModel.content').rollbackAttributes(['title']);

          _this3.get('toast').error("Section name not updated", "Something went wrong", {
            positionClass: 'assessment-toast-error'
          });
        }).finally(function () {
          setTimeout(function () {
            _this3.set('isSectionUpdated', false);
          }, 2000);
          return _this3.set('isTyping', false);
        });
      } else {
        this.get('sectionModel').save().then(function () {
          _this3.set('isTyping', false);

          _this3.set('isSectionUpdated', true);
        }).catch(function () {
          _this3.get('sectionModel').rollbackAttributes(['title']);

          _this3.get('toast').error("Section name not updated", "Something went wrong", {
            positionClass: 'assessment-toast-error'
          });
        }).finally(function () {
          setTimeout(function () {
            _this3.set('isSectionUpdated', false);
          }, 1000);
          return _this3.set('isTyping', false);
        });
      }
    },
    createQuestionObject: function createQuestionObject() {
      var question = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var questionObj = [];

      if (question && question.length) {
        question.forEach(function (question, index) {
          if (question.get('questionType') == 1) {
            questionObj.pushObject({
              question_id: question.get('question_id'),
              sort_order: index + 1
            });
          } else {
            questionObj.pushObject({
              skill_id: question.get('skill_id'),
              sort_order: index + 1,
              q_count: +question.get('qCount')
            });
          }
        });
      }

      return questionObj;
    },
    setAsProperties: function setAsProperties(assessmentSection, section, sectionModel, totalQuestions) {
      this.setSectionSettings(sectionModel.get('sectionType.id'), totalQuestions, section);
      var changedAttr = assessmentSection.changedAttributes();
      var changedProperties = Object.keys(changedAttr);

      if (changedProperties.length) {
        changedProperties.forEach(function (property) {
          section[property] = assessmentSection.get("".concat(property));
        });
      }

      return section;
    },
    _getParentId: function _getParentId(all, as, selectedAS) {
      var asIds = as.map(function (a) {
        return a.get('id');
      });
      var parent = asIds[_npmLodash.default.indexOf(asIds, selectedAS) - 1];

      if (_npmLodash.default.isNil(parent)) {
        var allSections = all.map(function (obj) {
          return obj.get('id');
        });
        return allSections[_npmLodash.default.indexOf(allSections, as.objectAt(1).get('id')) - 1];
      }

      return parent;
    },
    _getChildId: function _getChildId(as, parentId) {
      var asIds = as.map(function (a) {
        return a.get('id');
      });
      return asIds[_npmLodash.default.indexOf(asIds, parentId) + 1];
    },
    actions: {
      setSelectedTab: function setSelectedTab(property) {
        if (this.get('selectedAS.hasDirtyAttributes') && !this.get('selectedAS.isExtraAs')) {
          this.set('selectedTabProperty', property);
          this.toggleProperty('showAlert');
        } else {
          this.set('selectedTab', property);
        }
      },
      updateAs: function updateAs() {
        var assessmentSection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('selectedAS');
        this.sendAction('updateAs', assessmentSection);
      },
      discard: function discard() {
        var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('selectedAS');
        this.sendAction('discard', model);
      },
      addSection: function addSection(allSections, assessmentSection, section) {
        var _this4 = this;

        var childId;

        var parentId = this._getParentId(allSections, this.get('assessmentSections'), assessmentSection.get('id'));

        if (parentId) {
          childId = this._getChildId(allSections, parentId);
        }

        if (!section.get('title')) {
          return this.get('toast').error('Section Title can not be blank', 'Required Field', {
            positionClass: 'assessment-toast-error'
          });
        }

        var secObj = {
          title: section.get('title'),
          section_type_id: section.get('sectionType.id'),
          evaluation_form_id: section.get('evaluationForm.id') ? +section.get('evaluationForm.id') : null,
          evaluation_form_url: section.get('evaluationForm.url') ? +section.get('evaluationForm.url') : null,
          external_assessment_id: section.get('externalAssessment.id'),
          parameters: section.get('sectionParameters.length') ? section.get('sectionParameters').map(function (sp) {
            return {
              id: +sp.get('parameter.id'),
              sort_order: sp.get('sort_order')
            };
          }) : Ember.A([])
        };

        if (section.get('sectionType.id') == 19) {
          if (!section.get('external_id')) {
            return this.get('toast').error('Select a versant assessment type', 'Error', {
              positionClass: 'assessment-toast-error'
            });
          }

          secObj.external_id = section.get('external_id');
        }

        if (section.get('sectionType.id') == 21) {
          if (!section.get('externalAssessment')) {
            return this.get('toast').error('Select an external assessment type', 'Error', {
              positionClass: 'assessment-toast-error'
            });
          }

          secObj.external_assessment_id = section.get('externalAssessment.id');
        }

        section.get('assessmentQuestions').then(function (questionsObj) {
          if (!questionsObj.get('length') && section.checkIFQuestionAvailable(section.get('sectionType.id'))) {
            return _this4.get('toast').error("Add atleast 1 question to '".concat(section.get('title'), "' section"), 'Required Field', {
              positionClass: 'assessment-toast-error'
            });
          } else {
            _this4.set('isLoading', true);

            secObj.combined = _this4.createQuestionObject(questionsObj);

            _this4.setAsProperties(assessmentSection, secObj, section, questionsObj.get('length'));

            _this4.get('store').createRecord('actions/section-workflow', {
              parent: parentId ? parentId : null,
              child: childId ? childId : null,
              type: 'insert',
              assessmentID: _this4.get('assessmentModel.id'),
              section: secObj,
              sync: true
            }).save().then(function () {
              _this4.get('toast').success('Section Added', 'Success', {
                positionClass: 'assessment-toast-error'
              });

              _this4.set('sectionId', null);

              _this4.set('assessmentModel.isExtraSectionAvailable', false);
              /* Reloading assessment section model so that localforage model is replaced with the newly created assessmentSection */


              _this4.get('assessmentModel.assessmentSections').reload();
              /* Reloading all assessment sections so that newly added assessment section is availble to identify parent and child id. */


              allSections.reload();
            }).catch(function (err) {
              if (err.errors) {
                err.errors.forEach(function (error) {
                  _this4.get('toast').error(error.detail, 'Error!', {
                    positionClass: 'assessment-toast-error'
                  });
                });
              } else {
                _this4.get('toast').error("Something went wrong.", 'Try again.', {
                  positionClass: 'assessment-toast-error'
                });
              }
            }).finally(function () {
              _this4.set('isLoading', false);
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});