define("recruit/templates/components/status-helpers/live", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b9CiSeHm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"span\"],[10,\"class\",[26,[\"ui \",[25,\"if\",[[20,[\"isBasic\"]],\"basic\"],null],\" \",[18,\"statusColor\"],\" label\"]]],[9,\"style\",\"padding:0.25em; min-width: 68px; text-align:center; font-weight:normal;\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    __\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/status-helpers/live.hbs"
    }
  });

  _exports.default = _default;
});