define("recruit/routes/recruiter/assessment/create-new-assessment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global localforage*/
  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service('current-user'),
    queryParams: {
      currentSlide: {
        replace: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        assessment: this.get('store').findRecord('lf-adapter/assessment-creation', params.uuid),
        user: this.get('currentUser.me'),
        organization: this.get('currentUser.me.organization'),
        pearsonAssessments: this.get('store').findAll('pearson-assessment')
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('pearsonAssessments', model.pearsonAssessments);
      model.organization.getSectionTypeList(true);
      controller.set('availableSections', model.organization.availableSections);
    },
    deactivate: function deactivate() {
      if (localforage) {
        localforage.removeItem('create-assessment');
      }
    }
  });

  _exports.default = _default;
});