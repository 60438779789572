define("recruit/templates/components/helpers/plain-text-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i+hRObPc",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"data-tooltip\",[18,\"value\"],null],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[7],[1,[18,\"value\"],false],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/plain-text-view.hbs"
    }
  });

  _exports.default = _default;
});