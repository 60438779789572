define("recruit/helpers/get-array-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getArrayValue = getArrayValue;
  _exports.default = void 0;

  function getArrayValue(object) {
    return object[0][object[1]];
  }

  var _default = Ember.Helper.helper(getArrayValue);

  _exports.default = _default;
});