define("recruit/models/parameter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    max_value: _emberData.default.attr('number'),
    currentValue: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    recommended_value: _emberData.default.attr('string', {
      defaultValue: '--'
    }),
    category: _emberData.default.belongsTo('parameter-category'),
    sectionParameters: _emberData.default.belongsTo('section-parameter'),
    recommendedValue: Ember.computed('recommended_value', function () {
      return this.get('recommended_value') ? this.get('recommended_value') : '--';
    }),
    setColorClass: Ember.computed('currentValue', function () {
      if (this.get('currentValue') < this.get('recommended_value')) {
        return 'parameters-red';
      } else {
        return 'parameters-blue';
      }
    }),
    formattedCurrentValue: Ember.computed('currentValue', function () {
      return this.get('currentValue') < 0 || this.get('currentValue') > this.get('max_value') ? 'NA' : this.get('currentValue');
    })
  });

  _exports.default = _default;
});