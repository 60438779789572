define("recruit/components/recruiter-helpers/scheduling/assessment-section", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    hasAssessmentSections: true,
    selectedAssessment: Ember.computed('assessmentId', function () {
      if (this.get('assessmentId')) {
        return this.get('store').findRecord('assessment', this.get('assessmentId'));
      }
    }),
    assessmentSectionObserver: Ember.observer('selectedAssessment', 'selectedAssessment.assessmentSections', function () {
      var _this = this;

      this.set('hasAssessmentSections', true);
      this.set('showAssessmentSections', false);
      this.set('selectedAssessmentSection', null);

      if (this.get('selectedAssessment')) {
        this.set('assessmentId', this.get('selectedAssessment.id'));
        this.get('selectedAssessment.assessmentSections').then(function (assessmentSections) {
          Ember.RSVP.all(assessmentSections.getEach('section')).then(function () {
            if (_this.get('isVm')) {
              _this.set('assessmentSections', assessmentSections.filterBy('section.isVm'));
            } else {
              _this.set('assessmentSections', assessmentSections.filterBy('section.isLive'));
            }

            if (_this.get('assessmentSections') && _this.get('assessmentSections.length') && _this.get('assessmentSections.length') == 1) {
              _this.set('selectedAssessmentSection', _this.get('assessmentSections.firstObject'));
            }

            _this.set('showAssessmentSections', true);

            _this.set('hasAssessmentSections', _this.get('assessmentSections.length'));
          });
        });
      } else {
        this.set('assessmentId', null);
      }
    }),
    allAssessments: Ember.computed('s', function () {
      var query = _npmLodash.default.omitBy(this.get('s'), _npmLodash.default.isNil);

      return this.get('store').query('assessment', query);
    }),
    searchObserver: Ember.observer('search', function () {
      var _this2 = this;

      Ember.run.debounce(this, function () {
        _this2.set('s', _this2.get('search'));
      }, 300);
    }),
    actions: {
      changeSelection: function changeSelection(property) {
        if (property == 'selectedAssessment') {
          this.set('assessmentId', null);
        }

        this.set(property, null);
        this.sendAction('changeSelection');
      }
    }
  });

  _exports.default = _default;
});