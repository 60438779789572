define("recruit/components/recruiter-view/actions/append-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.getActionType();
    },
    getActionType: function getActionType() {
      var actionType = this.get('model.actionType').toLowerCase();

      switch (actionType) {
        case 'add assessments to the event':
          return this.set('actionType', 'assessments');

        case 'invite recruiters to the event':
          return this.set('actionType', 'recruiter');

        case 'invite panel to the event':
          return this.set('actionType', 'panel');

        default:
          return this.set('actionType', 'assessment');
      }
    },
    headerText: Ember.computed('actionType', function () {
      var actionType = this.get('actionType').toLowerCase();

      switch (actionType) {
        case 'assessments':
          return 'Selected Assessments';

        case 'recruiter':
          return 'Selected Recruiters';

        case 'panel':
          return 'Selected Panel Members';

        default:
          return 'Selected Assessments';
      }
    }),
    actions: {
      removeItem: function removeItem(item) {
        if (this.get('currentRecruiter.length') && this.get('currentRecruiter').findBy('id', item.get('id'))) {
          return this.sendAction('removeItem', item, 'currentRecruiter');
        }

        if (this.get('disabledItems.length') && this.get('disabledItems').findBy('id', item.get('id'))) {
          return this.sendAction('removeItem', item, 'disabledItems');
        }
      }
    }
  });

  _exports.default = _default;
});