define("recruit/components/recruiter-view/assessment/helpers/panel-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    panelCount: Ember.computed('row', 'row.panelCandidates', function () {
      return +this.get('row.panelCandidates.length') - 1;
    }),
    panel: Ember.computed('row', 'value', 'value.length', 'pc', 'value.firstObject.panel', function () {
      if (this.get('pc') && this.get('pc')) {
        return this.get('pc');
      } else if (this.get('value.length')) {
        this.set('row.currentPanelId', this.get('value.firstObject.panel.id'));
        return this.get('value.firstObject');
      }
    }),
    showExpandable: Ember.computed('row', 'row.panelCandidates.length', 'pc', function () {
      return this.get('row.panelCandidates.length') > 1 && !this.get('pc');
    })
  });

  _exports.default = _default;
});