define("recruit/components/recruiter-view/assessment/helpers/comment-view", ["exports", "recruit/components/recruiter-view/assessment/helpers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    comment: Ember.computed('row', 'selectedEvaluation', 'value.@each.evaluationComments.isFulfilled', 'pc', function () {
      var selectedEvaluation = this.get('selectedEvaluation');

      if (selectedEvaluation) {
        if (selectedEvaluation.get('evaluationComments.length')) {
          return selectedEvaluation.get('evaluationComments.firstObject.content') ? selectedEvaluation.get('evaluationComments.firstObject.content') : '--';
        } else {
          return '--';
        }
      } else {
        return '--';
      }
    })
  });

  _exports.default = _default;
});