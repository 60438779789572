define("recruit/components/helpers/table-profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.inject.service('current-user'),
    userRole: Ember.inject.service(),
    computedValue: Ember.computed('value', 'row', function () {
      return this.get('value') || this.get('row.content');
    }),
    isNotLiveSession: Ember.computed(function () {
      return this.get('row.content._internalModel.modelName') != 'live-session';
    }),
    actions: {
      linkToPage: function linkToPage() {
        var link;

        if (this.get('userRole.isRecruiter') && this.get('isNotLiveSession')) {
          link = 'recruiter.candidate.details.profile';
          this.get('router').transitionTo(link, this.get('computedValue.id'));
        } else {
          if (this.get('userRole.isRecruiter')) {
            link = 'recruiter.live-session.view';
          } else {
            if (this.get('isNotLiveSession')) {
              link = 'panel.candidate.evaluate';
            } else {
              link = 'panel.live-session.view';
            }
          }

          this.get('router').transitionTo(link, this.get('row.id'));
        }
      }
    }
  });

  _exports.default = _default;
});