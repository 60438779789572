define("recruit/templates/user/details/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D/1HyjAE",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui blue segment text-uppercase text-black\"],[7],[0,\"\\n    Recruiter Statistics\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment padding-bg\"],[7],[0,\"\\n\"],[0,\"    \"],[6,\"img\"],[9,\"src\",\"/images/empty_states/no_activity.jpg\"],[9,\"alt\",\"\"],[9,\"class\",\"ui centered small image\"],[7],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"text-center\"],[7],[0,\"No stats available\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui blue segment text-uppercase text-black\"],[7],[0,\"\\n    Panel Statistics\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment padding-bg\"],[7],[0,\"\\n\"],[0,\"    \"],[6,\"img\"],[9,\"src\",\"/images/empty_states/no_activity.jpg\"],[9,\"alt\",\"\"],[9,\"class\",\"ui centered small image\"],[7],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"text-center\"],[7],[0,\"No stats available\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/user/details/stats.hbs"
    }
  });

  _exports.default = _default;
});