define("recruit/components/error-states/error-504", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      refresh: function refresh() {
        this.sendAction('refresh');
      }
    }
  });

  _exports.default = _default;
});