define("recruit/controllers/panel/event/details/response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['status', 'panel_id', 'event_user_id'],
    status: null,
    panel_id: null,
    event_user_id: null
  });

  _exports.default = _default;
});