define("recruit/data/columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    type: 'column/master',
    attributes: {
      'isDefault': true,
      'isShow': true,
      'objectType': 'r-c',
      'valuePath': 'candidate',
      'cellComponent': 'recruiter-view/ac-list-to-assc-link',
      'sortable': false,
      'width': '60px',
      'classNames': ['table-head']
    }
  }, {
    id: 2,
    type: 'column/master',
    attributes: {
      'isDefault': true,
      'isShow': true,
      'objectType': 'r-c',
      'label': 'Candidate',
      'valuePath': 'candidate',
      'cellComponent': 'candidate-helpers/candidate-details',
      'classNames': ['table-head text-left']
    }
  }, {
    id: 3,
    type: 'column/master',
    attributes: {
      'isDefault': true,
      'isShow': true,
      'objectType': 'r-c',
      'label': 'Assessment',
      'valuePath': 'assessment',
      'cellComponent': 'assessment-helpers/assessment-details',
      'classNames': ['table-head text-left']
    }
  }, {
    id: 4,
    type: 'column/master',
    attributes: {
      'isDefault': true,
      'isShow': true,
      'objectType': 'r-c',
      'label': 'Invite Status',
      'cellComponent': 'candidate-helpers/status',
      'valuePath': 'status.label',
      'cellClassNames': 'text-center text-weight-light',
      'classNames': ['table-head']
    }
  }, {
    id: 5,
    type: 'column/master',
    attributes: {
      'isDefault': true,
      'isShow': false,
      'objectType': 'r-c',
      'label': 'Sections',
      'cellComponent': 'recruiter-helpers/sections',
      'valuePath': 'assessmentSectionCandidates.length',
      'sortable': false,
      'classNames': ['table-head'],
      'align': 'center'
    }
  }, {
    id: 6,
    type: 'column/master',
    attributes: {
      'isDefault': false,
      'isShow': false,
      'objectType': 'r-c',
      'label': 'Email',
      'valuePath': 'candidate.email',
      'classNames': ['table-head text-left']
    }
  }, {
    id: 7,
    type: 'column/master',
    attributes: {
      'isDefault': false,
      'isShow': false,
      'objectType': 'r-c',
      'label': 'Name',
      'valuePath': 'candidate.fullName',
      'classNames': ['table-head text-left']
    }
  }];
  _exports.default = _default;
});