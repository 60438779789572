define("recruit/routes/auth/aad", ["exports", "recruit/config/environment", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _environment, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var locationHash = JSON.parse('{"' + decodeURI(location.hash.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      var url = _environment.default.isDevelopment ? _environment.default.APP.API_ROOT + "/aad/all-tenant" : location.origin + "/api/aad/all-tenant";

      if (locationHash.error_subcode == 'cancel') {
        return this.get('router').transitionTo('login');
      }

      if (locationHash.id_token) {
        controller.set('isError', false);
        controller.set('isLoading', true);
        return Ember.$.ajax({
          type: "POST",
          url: url,
          data: {
            id_token: locationHash.id_token
          },
          success: function success(accounts) {
            controller.set('isLoading', false);

            if (accounts.length) {
              if (accounts.length == 1) {
                return controller.send('authenticateAccount', accounts[0]);
              }

              controller.set('accounts', accounts);
            } else {
              controller.set('isError', true);
            }
          },
          error: function error() {
            controller.set('isError', true);
            controller.set('isLoading', false);
            return false;
          }
        });
      } else {
        this.get('toast').error('Something went wrong', 'Please try after some time.');
      }
    },
    actions: {
      authenticateAccount: function authenticateAccount(account) {
        if (account.base_recruit_domain) {
          localStorage.setItem('is_tv_azure_login', true); // account.base_recruit_domain = location.origin;  // Uncomment this for redirection to localhost

          window.location.replace("//".concat(account.base_recruit_domain, "/auth/token?token=").concat(btoa(JSON.stringify(account.token))));
        } else {
          return this.get('session').authenticate('authenticator:tv-activate', account.token).then(function () {
            return localStorage.setItem('is_tv_azure_login', true);
          });
        }
      },
      logout: function logout() {
        var authContext = new AuthenticationContext({
          clientId: _environment.default.APP.AZURE_CLIENT_ID,
          postLogoutRedirectUri: location.origin + '/login'
        });
        authContext.logOut();
      }
    }
  });

  _exports.default = _default;
});