define("recruit/data/section-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 11002,
    type: "filter/master",
    attributes: {
      "field": "Status",
      "type": "list",
      "model": 'status',
      "isRemote": true,
      "objectType": "as",
      "queryTemplate": '(assessmentSectionCandidates.assessment_section_id:{{asID}} AND assessmentSectionCandidates.status_id:{{value}})'
    }
  }, // {
  //   id: 11003,
  //   type:"filter/master",
  //   attributes: {
  //     "field" : "Panel",
  //     "type" : "list",
  //     "model": 'panel',
  //     "isRemote" : true,
  //     "objectType": "as",
  //     "queryTemplate" : '(assessmentSectionCandidates.assessment_section_id:{{asID}} AND assessmentSectionCandidates.panel.id:{{value}})'
  //   }
  // },
  {
    id: 11007,
    type: "filter/master",
    attributes: {
      "type": "date",
      "field": "Completion date",
      "isRemote": false,
      "objectType": "as",
      "queryTemplate": '(assessmentSectionCandidates.completed_at:[{{start}} TO {{end}} ])'
    }
  }, {
    id: 11008,
    type: "filter/master",
    attributes: {
      "id": 5778,
      "type": "date",
      "field": "Invited date",
      "isRemote": false,
      "objectType": "as",
      "queryTemplate": '(invited_at:[{{start}} TO {{end}} ])'
    }
  }, {
    id: 11009,
    type: "filter/master",
    attributes: {
      "id": 5778,
      "type": "date",
      "field": "Selected date",
      "isRemote": false,
      "objectType": "as",
      "queryTemplate": '(assessmentSectionCandidates.evaluations.evaluated_at:[{{start}} TO {{end}} ] AND assessmentSectionCandidates.evaluations.recommendation: "select")'
    }
  }, {
    id: 11010,
    type: "filter/master",
    attributes: {
      "id": 5778,
      "type": "date",
      "field": "Last date to attend",
      "isRemote": false,
      "objectType": "as",
      "queryTemplate": '(end_date:[{{start}} TO {{end}} ])'
    }
  }];
  _exports.default = _default;
});