define("recruit/adapters/app-state", ["exports", "ember-localforage-adapter/adapters/localforage"], function (_exports, _localforage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localforage.default.extend({
    namespace: 'recruit'
  });

  _exports.default = _default;
});