define("recruit/models/assessment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service('current-user'),
    external_id: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    owned_by: _emberData.default.belongsTo('user'),
    status: _emberData.default.belongsTo('status/assessment'),
    start_date: _emberData.default.attr('pgdate'),
    end_date: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    events: _emberData.default.hasMany('event'),
    position: _emberData.default.belongsTo('position'),
    // TODO: 404 on API
    firstAssessmentSection: _emberData.default.belongsTo('assessment-section', {
      inverse: null
    }),
    assessmentSections: _emberData.default.hasMany('assessment-section', {
      inverse: null
    }),
    publicSection: _emberData.default.hasMany('assessment-section', {
      inverse: null
    }),
    evaluateSections: _emberData.default.hasMany('assessment-section', {
      inverse: null
    }),
    allSections: _emberData.default.hasMany('assessment-section', {
      inverse: null
    }),
    organization: _emberData.default.belongsTo('organization'),
    customFields: _emberData.default.attr(),
    assessmentCandidates: _emberData.default.hasMany('assessment-candidate', {
      async: true,
      inverse: 'assessment'
    }),
    count: _emberData.default.attr('number'),
    invitedCount: _emberData.default.attr('number'),
    inProgressCount: _emberData.default.attr('number'),
    attendedCount: _emberData.default.attr('number'),
    assessedCount: _emberData.default.attr('number'),
    selectedCount: _emberData.default.attr('number'),
    rejectedCount: _emberData.default.attr('number'),
    holdCount: _emberData.default.attr('number'),
    organization_id: _emberData.default.attr('number'),
    report: _emberData.default.belongsTo('file'),
    all: _emberData.default.hasMany('assessment-candidate'),
    metrics: _emberData.default.belongsTo('assessment-metrics'),
    notificationLog: _emberData.default.hasMany('notification-log', {
      inverse: null
    }),
    sections: _emberData.default.hasMany('section'),
    reportUrl: _emberData.default.attr('string'),
    templates: _emberData.default.hasMany('notification-template'),
    menuCardAssessments: _emberData.default.hasMany('menu-card-assessment'),
    isExtraSectionAvailable: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    tbiRole: _emberData.default.attr('string'),
    tbiQcEnabled: Ember.computed.notEmpty('tbiRole'),
    isRelationsLoaded: Ember.computed('owned_by', function () {
      var _this = this;

      this.get('owned_by').then(function () {
        return _this.set('isRelationsLoaded', true);
      }).catch(function () {
        return _this.set('isRelationsLoaded', true);
      });
    }),
    presentMenuCards: Ember.computed(function () {
      var _this2 = this;

      this.get('menuCardAssessments').then(function (res) {
        Ember.RSVP.resolve(res);

        _this2.set('presentMenuCards', res);
      });
    }),
    formattedJobDescription: Ember.computed('description', function () {
      return this.get('description') ? this.get('description').trim() : false;
    }),
    attributeChangeObserver: Ember.observer('menuCardAssessments.[]', 'firstAssessmentSection', function () {
      if (!this.get('isSaving') && this.get('isLoaded')) {
        this.reload();
      }
    }),
    ownerProfilePic: Ember.computed('owned_by', function () {
      var _this3 = this;

      this.get('owned_by').then(function () {
        _this3.set('ownerProfilePic', _this3.get('owned_by.profilePic'));
      }).catch(function () {
        _this3.set('ownerProfilePic', '/images/user_light.svg');
      });
    }),
    ownerName: Ember.computed(function () {
      return this.get('owned_by.name') || '--';
    }),
    formattedDescription: Ember.computed(function () {
      return this.get('description') ? this.get('description').trim() ? this.get('description').trim() : 'No job description available.' : 'No job description available.';
    }),
    titleWithId: Ember.computed('external_id', function () {
      return this.get('external_id') ? "".concat(this.get('title'), " (").concat(this.get('external_id'), ")") : this.get('title');
    }),
    key: Ember.computed.alias('titleWithId'),
    value: Ember.computed(function () {
      return this.get('id');
    }),
    statusText: Ember.computed('status', function () {
      return this.get('status.sort_key') ? this.get('status') : null;
    }),
    statusTooltip: Ember.computed('status', function () {
      if (this.get('status.id')) {
        switch (this.get('status.id')) {
          case "1":
            return Ember.Object.create({
              color: "yellow",
              text: "Draft status signifies that the assessment is not published or there are no candidates invited and you can modify the assessment completely."
            });

          case "3":
            return Ember.Object.create({
              color: "green",
              text: "The assessment is published or have active candidates and you cannot modify some attributes in the assessment."
            });

          default:
            return Ember.Object.create({
              color: "black",
              text: null
            });
        }
      } else {
        return Ember.Object.create({
          color: "black",
          text: "Could not generate the status. We are working on it!"
        });
      }
    }),
    getStatusTooltipContent: function getStatusTooltipContent() {
      if (this.get('status.id')) {
        return "<div style=\"min-width: 150px;\"><div class=\"text-weight-medium text-size-small\">\n        Assessment Status: <span class=\"text-".concat(this.get('statusTooltip.color'), "\">").concat(this.get('status.label'), "</span></div>\n        <div class=\"text-size-small\">").concat(this.get('statusTooltip.text'), "</div></div>");
      }

      return "<div style=\"min-width: 150px;\"><div class=\"text-size-small\">".concat(this.get('statusTooltip.text'), "</div></div>");
    },
    freezeAssessment: Ember.computed('status', function () {
      return false;
      /* todo://Uncomment the below code for assessment freeze */
      // return this.get('status.id') === "3"
    }),
    liveSections: Ember.computed('assessmentSections', function () {//let data = this.get('assessmentSections').mapBy('section').filterBy('section_type_id',7);
    }),
    customFieldsSorted: Ember.computed('customFields', function () {
      return this.get('customFields').sortBy('sort_order');
    }),
    isOwnedByCurrentUser: Ember.computed(function () {
      return this.get('currentUser.me.id') == this.get('owned_by.id');
    }),
    hasValidJD: Ember.computed('description', function () {
      var jd = String(this.get('description') || "").replace(/(<([^>]+)>)/ig, "").trim();
      return jd.length && jd !== '(not set)' && jd !== 'not set';
    })
  });

  _exports.default = _default;
});