define("recruit/components/list-profile-pic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    img: null,
    size: 'small',
    default: '/images/cuton_placeholder.png',
    renderedImage: Ember.computed('img', 'default', function () {
      return this.get('img') || this.get('default');
    })
  });

  _exports.default = _default;
});