define("recruit/routes/setup/user", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/validations/user-account"], function (_exports, _authenticatedRouteMixin, _userAccount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    UserAccountValidations: _userAccount.default,
    session: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        currentUser: this.get('session.user'),
        settingsAllowed: this.get('store').query('settingsAllowed', {
          settings_id: 1
        }),
        timeZone: this.get('store').query('settingsData', {
          settings_id: 1
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);

      controller.set('model', models.currentUser);
      controller.set('settingsAllowed', models.settingsAllowed);
      controller.set('settingsData', models.settingsData);
      controller.set('timeZone', models.timeZone);
      controller.set('UserAccountValidations', _userAccount.default);
    },
    actions: {
      next: function next(changeset) {
        var _this = this;

        var passwordResetToken = this.controller.get('model.password_reset_token');
        localStorage.setItem('tv_password_reset_token', passwordResetToken);

        if (this.controller.get('timeZoneSelected')) {
          if (changeset.get('isValid')) {
            changeset.save().then(function () {
              return _this.get('store').createRecord('settingsData', {
                settings_id: 1,
                settings_allowed_value_id: _this.controller.get('timeZoneSelected.id')
              }).save();
            }).then(function () {
              _this.get('router').transitionTo('setup.security');
            }).catch(function () {
              _this.get('toast').error('Something went wrong', 'Please try after some time.');
            });
          }
        } else {
          this.get('toast').error("Timezone can not be blank.", "Please try agin");
        }
      }
    }
  });

  _exports.default = _default;
});