define("recruit/templates/components/candidate-helpers/profile-view-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IK1BApy5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"link-to\",[\"recruiter.candidate.details.profile\",[20,[\"value\",\"id\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"value\",\"profilePic\"]],\"ui circular image profile-picture\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-helpers/profile-view-link.hbs"
    }
  });

  _exports.default = _default;
});