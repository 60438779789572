define("recruit/components/recruiter-view/questions/helpers/user-details-cell-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          position: 'bottom center'
        });
      });
    }
  });

  _exports.default = _default;
});