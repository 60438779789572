define("recruit/templates/signed-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SL0m/ZbK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dark-modal-wrapper\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"style\",\"padding-top: 35vh!important;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segment align-center\"],[9,\"style\",\"width:45vw;\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"four wide column\"],[7],[0,\"\\n          \"],[6,\"img\"],[9,\"src\",\"/images/cuton_search.png\"],[9,\"alt\",\"cuton\"],[9,\"class\",\"ui image cuton-updown\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"eleven wide column padding-bg-top\"],[7],[0,\"\\n          \"],[6,\"h2\"],[9,\"class\",\"text-black\"],[7],[0,\"You've been signed out!\"],[8],[0,\"\\n          \"],[6,\"p\"],[9,\"class\",\"text-size-normal\"],[7],[0,\"This was probably because your account was signed in from a new device.\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"isDevelopment\"]]],null,{\"statements\":[[0,\"            \"],[6,\"button\"],[9,\"class\",\"ui blue button\"],[7],[0,\"Sign out of all devices\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/signed-out.hbs"
    }
  });

  _exports.default = _default;
});