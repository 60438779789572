define("recruit/components/recruiter-view/actions/remind-panel", ["exports", "moment", "recruit/config/environment", "recruit/mixins/g-actions"], function (_exports, _moment, _environment, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    isNotify: true,
    isLoading: false,
    endDate: (0, _moment.default)().add(10, 'd').toDate(),
    minDate: (0, _moment.default)().toDate(),
    isPanel: Ember.computed(function () {
      return this.get('model.actionType') == 'send panel reminder';
    }),
    actions: {
      remindPanel: function remindPanel() {
        var _this = this;

        this.set('isLoading', true);
        var panels, assessmentCandidates, length, assessment, label, assessmentSections;

        switch (this.get('model.relationType')) {
          case 'panels':
            {
              panels = this.get('selectedListValues').map(function (p) {
                //Asessment overview's Section's Action's remind assigned panel
                return {
                  "id": p.get('id')
                }; //Panels ID
              });
              length = panels.length;
              label = 'Panels';
              break;
            }

          case 'assessmentSections':
            {
              assessmentSections = this.get('selectedListValues').map(function (as) {
                //Asessment overview's Assessment/Section's Actions remind all panels and candidates
                return {
                  id: as.get('id')
                }; //AssessmentSections ID
              });
              length = this.get('model.invitedCandidatesCount');
              this.set('endDate', null);
              label = 'Candidates';
              break;
            }

          default:
            {
              assessmentCandidates = this.get('selectedListValues').map(function (ac) {
                return {
                  "id": ac.get('id')
                };
              });
              length = assessmentCandidates.length;
              label = 'Candidates';
            }
        }

        var modelName = this.get('isPanel') ? 'actions/remind-panel' : 'actions/remind-candidate';
        this.get('store').createRecord(modelName, {
          assessmentCandidates: assessmentCandidates,
          endDate: this.get('endDate'),
          isNotify: this.get('isNotify'),
          message: this.get('message'),
          panel: panels,
          assessment: assessment,
          assessmentSections: assessmentSections,
          sync: true
        }).save().then(function () {
          _this.get('toast').success("for ".concat(length, " ").concat(label), _this.get('isPanel') ? 'Panel Reminder sent' : 'Candidate Reminder sent');

          return _this.send('back');
        }).catch(function (e) {
          _this.catchError(e);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});