define("recruit/routes/recruiter/questions/create", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    model: function model() {
      return Ember.RSVP.hash({
        currentUser: this.get('currentUser.me'),
        organization: this.get('currentUser.me.organization'),
        question: this.get('store').createRecord('question')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      model.organization.getSectionTypeList(false);
      this.controller.set('availableSections', model.organization.availableSections);
      this.controller.set('selectedQuestionType', controller.get('availableSections').findBy('id', "6"));
    }
  });

  _exports.default = _default;
});