define("recruit/templates/recruiter/assessment/details/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ct25asvk",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"recruiter-view/assessment/details/assessment-notification\",null,[[\"currentUser\",\"model\",\"isDevelopment\",\"searchText\"],[[20,[\"currentUser\"]],[20,[\"model\"]],[20,[\"isDevelopment\"]],[20,[\"searchText\"]]]]],false],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/assessment/details/notification.hbs"
    }
  });

  _exports.default = _default;
});