define("recruit/components/candidate-helpers/candidate-basic-details", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.set('rerenderTable', false);
      document.getElementById('candidateForm').reset();
    },
    setActionsParmas: function setActionsParmas(key, value, type) {
      return {
        key: key,
        value: value,
        type: type
      };
    },
    actions: {
      removeUploadedFile: function removeUploadedFile() {
        this.set('file', null);
      },
      save: function save(changeset, file) {
        var _this = this;

        this.set('isLoading', true);
        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            if (_this.get('isEventsModal')) {
              return _this.send(_this.get('customActionName'), changeset);
            } else {
              return changeset.save().then(function (savedCandidate) {
                if (file) {
                  return _this.get('store').createRecord('candidate-file', {
                    file_id: file.id,
                    candidate: savedCandidate
                  }).save().then(function () {
                    if (_this.get('isAssessmentOverview')) {
                      _this.send(_this.get('customActionName'), savedCandidate);
                    }

                    return Ember.RSVP.resolve(savedCandidate);
                  });
                } else {
                  if (_this.get('isAssessmentOverview')) {
                    _this.send(_this.get('customActionName'), savedCandidate);
                  }

                  return Ember.RSVP.resolve(savedCandidate);
                }
              }).then(function (savedCandidate) {
                document.getElementById('candidateForm').reset();

                if (!_this.get('hasCustomFields')) {
                  _this.set('isLoading', false);

                  _this.get('toast').success('Candidate created');

                  if (!_this.get('isEventsModal') && !_this.get('isAssessmentOverview')) {
                    return _this.get('router').transitionTo('recruiter.pending-candidates');
                  }
                } else {
                  _this.set('candidateModel', savedCandidate);

                  return _this.get('router').transitionTo('recruiter.candidate.create.additional');
                }
              }).catch(function (error) {
                if (!error || Ember.typeOf(error.errors) != 'array') {
                  _this.get('toast').error('Please try after sometime', 'Candidate creation failed');
                } else {
                  error.errors.forEach(function (e) {
                    return _this.get('toast').error(e.detail);
                  });
                }
              });
            }
          } else {
            _this.get('toast').error('', 'Please check the form errors');

            return Ember.RSVP.resolve();
          }
        }).finally(function () {
          if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
            _this.set('isLoading', false);
          }
        });
      },
      inviteToAssessment: function inviteToAssessment(candidates) {
        var candidateRelation;

        if (!Ember.isArray(candidates)) {
          candidateRelation = Ember.A([candidates.get('_content') ? candidates.get('_content') : candidates]);
        }

        if (this.get('assessmentModel')) {
          this.send('routeActions', candidateRelation, 'candidates', 'invite to assessment', this.setActionsParmas('assessments', this.get('assessmentModel'), 'value'), this.setActionsParmas('isAssessmentFrozen', true, 'flag'), this.setActionsParmas('redirectTo', 'recruiter.assessment.details.overview', 'flag'));
        } else {
          this.send('routeActions', candidateRelation, 'candidates', 'invite to assessment');
        }
      },
      createEventCandidate: function createEventCandidate(candidate) {
        var _this2 = this;

        this.set('isLoading', true);
        var candidateArray = Ember.A([candidate.get('_content')]);
        this.get('store').createRecord('actions/event-candidate', {
          event: this.get('model'),
          candidates: candidateArray
        }).save().then(function () {
          _this2.get('toast').success('candidate added successfully!');

          if (_this2.get('isEventOverview')) {
            _this2.send("bulkImportCandidates", "openBulkImport");

            Ember.run.next(function () {
              _this2.get('router').transitionTo('recruiter.events.details.candidate', _this2.get('model'));
            });
          } else {
            _this2.set('rerenderTable', true);

            _this2.send('bulkImportCandidates', "openBulkImport");
          }
        }).catch(function (e) {
          if (e.errors) {
            e.errors.forEach(function (error) {
              _this2.get('toast').error(error.detail, 'Error!');
            });
          } else {
            _this2.get('toast').error('Please try after some time', 'Something went wrong');
          }
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      bulkImportCandidates: function bulkImportCandidates(property) {
        if (this.get('rerenderTable')) {
          this.send('rerenderTable', this.get('rerenderTable'));
        }

        this.sendAction('bulkImportCandidates', property);
      },
      rerenderTable: function rerenderTable(property) {
        this.sendAction('rerenderTable', property);
      },
      back: function back() {
        if (history.length) {
          this.set('isLoading', false);
          history.back();
        } else {
          this.get('router').transitionTo('dashboard');
        }
      }
    }
  });

  _exports.default = _default;
});