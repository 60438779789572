define("recruit/components/helpers/power-select-after-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      customAction: function customAction() {
        this.sendAction('_targetObject.customAction');
      }
    }
  });

  _exports.default = _default;
});