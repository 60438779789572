define("recruit/templates/components/reports/helpers/advanced-row-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LUaPVV1q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[9,\"style\",\"height: 34px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"twelve wide column padding-no padding-md-left text-left\"],[7],[0,\"\\n    \"],[6,\"div\"],[10,\"data-tooltip\",[26,[[20,[\"row\",\"title\"]]]]],[9,\"data-position\",\"bottom center\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"text-black text-weight-medium margin-no ellipsis\"],[9,\"style\",\"position: relative; top: 6px;\"],[7],[1,[20,[\"row\",\"title\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/advanced-row-component.hbs"
    }
  });

  _exports.default = _default;
});