define("recruit/components/events/event-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    location: Ember.Object.create(),
    customPopupOptions: {
      position: 'right center'
    },
    extra: {
      text: "Change Owner"
    },
    minimumDate: Ember.computed(function () {
      return (0, _moment.default)().subtract(1, 'days').startOf('day').toDate();
    }),
    disableShare: Ember.computed('eventModel.status', function () {
      return this.get('eventModel.status') == 3 || this.get('eventModel.status') == 5 || this.get('eventModel.status') == 6;
    }),
    allowChangeInfo: Ember.computed('eventModel.status', 'eventModel.owned_by', function () {
      return this.get('eventModel.canEditEvent') && this.get('eventModel.status') != 4;
    }),
    _eventOwnerObserver: Ember.observer('eventModel.owned_by', function () {
      this.toggleProperty('changeOwner');
      Ember.run.once(this, 'saveEventsModel', 'Owner');
    }),
    _eventEndDateObserver: Ember.observer('end_date', function () {
      this.set('eventModel.start_date', (0, _moment.default)(this.get('start_date')));
      this.set('eventModel.end_date', (0, _moment.default)(this.get('end_date')));

      if (this.get('end_date')) {
        if (this.get('eventModel.status') == 1 || this.get('eventModel.status') == 2) {
          this.set('modalTitle', 'Heads Up!');
          this.set('modalMessage', 'Are you sure you want to change the event date? An email will be sent to all the recruiters in the event about the change.');
        }

        if (this.get('eventModel.status') == 4 || this.get('eventModel.status') == 5) {
          this.set('modalTitle', 'There was a problem');
          this.set('modalMessage', "You can't change the event date for an event in ".concat(this.get('eventModel.selectedStatus.name')));
        }

        this.set('buttonName', 'Change Date');

        if (!this.get('showHeadsup')) {
          this.set('changedAttr', 'Event Date');
          this.set('changedView', 'setEventDate');
          this.toggleProperty('showHeadsup');
        }
      }
    }),
    _eventWindowObserver: Ember.observer('registration_end_date', function () {
      this.set('eventModel.registration_start_date', (0, _moment.default)(this.get('registration_start_date')));
      this.set('eventModel.registration_end_date', (0, _moment.default)(this.get('registration_end_date')));

      if (this.get('registration_end_date')) {
        if (this.get('eventModel.status') == 1 || this.get('eventModel.status') == 2) {
          this.set('modalTitle', 'Heads Up!');
          this.set('modalMessage', 'Are you sure you want to change the registration date? An email will be sent to all the recruiters in the event about the change.');
        }

        if (this.get('eventModel.status') == 4 || this.get('eventModel.status') == 5) {
          this.set('modalTitle', 'There was a problem');
          this.set('modalMessage', "You can't change the registration window for an event in ".concat(this.get('eventModel.selectedStatus.name')));
        }

        this.set('buttonName', 'Change Date');

        if (!this.get('showHeadsup')) {
          this.set('changedAttr', 'Registration Window');
          this.set('changedView', 'setRegistrationWindow');
          this.toggleProperty('showHeadsup');
        }
      }
    }),
    _locationObserver: Ember.observer('location.{url,name}', function () {
      if (this.get('setLocation') && this.get('location.url')) {
        this.set('eventModel.location', this.get('location'));

        if (this.get('eventModel.status') == 1 || this.get('eventModel.status') == 2) {
          this.set('modalTitle', 'Heads Up!');
          this.set('modalMessage', 'Are you sure you want to change the venue? An email will be sent to all the recruiters in the event about the change. ');
        }

        if (this.get('eventModel.status') == 4 || this.get('eventModel.status') == 5) {
          this.set('modalTitle', 'There was a problem');
          this.set('modalMessage', "You can't change the venue for an event in ".concat(this.get('eventModel.selectedStatus.name')));
        }

        this.set('buttonName', 'Change Venue');

        if (!this.get('showHeadsup')) {
          this.set('changedAttr', 'Venue');
          this.toggleProperty('showHeadsup');
        }
      }
    }),
    _canSelectAssessmentObserver: Ember.observer('eventModel.canSelectAssessment', function () {
      return this.saveEventsModel('');
    }),
    didInsertElement: function didInsertElement() {
      Ember.$('.ui.floating.dropdown').dropdown();
      Ember.run.next('afterRender', function () {
        Ember.$('.event-assessment-popup').popup({
          position: 'right center',
          lastResort: true
        });
      });
    },
    saveEventsModel: function saveEventsModel(field) {
      var _this = this;

      this.get('eventModel').save().then(function () {
        return _this.get('toast').success("".concat(field, " updated"));
      }).catch(function (err) {
        if (err.errors) {
          err.errors.forEach(function (error) {
            _this.get('toast').error(error.detail, 'Error!');
          });
        } else {
          _this.get('toast').error("Something went wrong.", 'Try again.');
        }
      }).finally(function () {
        if (_this.get('showHeadsup')) {
          _this.send('toggleProperty', 'showHeadsup', _this.get('changedView'));
        }
      });
    },
    actions: {
      toggleProperty: function toggleProperty(property, property2) {
        if (this.get('eventModel.canEditEvent')) {
          this.toggleProperty(property);

          if (property2 && property2.length) {
            if (this.get('eventModel.hasDirtyAttributes')) {
              this.get('eventModel').rollbackAttributes();
            }

            if (this.get(property2)) {
              this.toggleProperty(property2);
            }
          }

          if (this.get(property)) {
            Ember.run.next(function () {
              if (Ember.$(".".concat(property)).length) {
                Ember.$(".".concat(property)).focus();
              }
            });
          }
        }
      },
      saveDescription: function saveDescription(property) {
        var changedAttr = this.get('eventModel').changedAttributes();
        var changedProperties = Object.keys(changedAttr);

        if (changedProperties.length) {
          Ember.run.once(this, 'saveEventsModel', 'Description');
        }

        this.toggleProperty(property);
      },
      saveModel: function saveModel(field) {
        this.saveEventsModel(field);
      },
      toggleShareModal: function toggleShareModal(property) {
        if (this.get('eventModel.status') != 1) {
          return this.toggleProperty(property);
        } else {
          this.toggleProperty('shareEventError');
        }
      },
      onClickOutside: function onClickOutside(property) {
        if (property == 'setEventDate') {
          if (!this.get('start_date') || !this.get('end_date')) {
            this.get('eventModel').rollbackAttributes();
          }
        }

        if (property == 'setRegistrationWindow') {
          if (!this.get('registration_start_date') || !this.get('registration_end_date')) {
            this.get('eventModel').rollbackAttributes();
          }
        }

        if (this.get('searchText') && this.get('searchText.length')) {
          this.set('searchText', null);
        }

        this.send('toggleProperty', property);
      },
      bulkImportCandidates: function bulkImportCandidates(property) {
        if (this.get('eventModel.status') != 1) {
          this.toggleProperty(property);
        } else {
          this.toggleProperty('addCandidate');
        }
      }
    }
  });

  _exports.default = _default;
});