define("recruit/templates/components/recruiter-view/actions/section-status-change-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "itmr3k0E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"ui-radio\",null,[[\"name\",\"label\",\"value\",\"onChange\"],[\"status\",[20,[\"status\",\"firstObject\",\"label\"]],[20,[\"status\",\"firstObject\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"selectedStatus\"]]],null]],null]]]],false],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/actions/section-status-change-icon.hbs"
    }
  });

  _exports.default = _default;
});