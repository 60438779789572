define("recruit/models/answer-code-submission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    time: _emberData.default.attr('string'),
    input: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    score: _emberData.default.attr('number', {
      "defaultValue": 0
    }),
    status: _emberData.default.attr('number'),
    stderr: _emberData.default.attr('string'),
    output: _emberData.default.attr('string'),
    result: _emberData.default.attr('string'),
    cmpinfo: _emberData.default.attr('string'),
    language: _emberData.default.belongsTo('code-language'),
    compiler: _emberData.default.attr('string'),
    answer: _emberData.default.belongsTo('answer'),
    memory: _emberData.default.attr('number'),
    compilationStatus: Ember.computed('status', 'result', function () {
      return this.get('isSuccessFull') ? 'Success' : 'Errors';
    }),
    isSuccessFull: Ember.computed('status', 'result', function () {
      return this.get('status') === 0 && this.get('result') === null && this.get('score') > 0;
    }),
    compilationIcon: Ember.computed('status', 'result', function () {
      return this.get('status') === 0 && this.get('result') === null ? 'checkmark green' : 'remove red';
    })
  });

  _exports.default = _default;
});