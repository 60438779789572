define("recruit/templates/recruiter/assessment/details/candidate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NtBXtd+K",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"recruiter-view/assessment/details/assessment-candidate\",null,[[\"currentUser\",\"searchText\",\"appliedFilters\",\"customListFilters\",\"assessmentSection\",\"model\",\"columns\",\"sortKey\",\"assessmentFilters\",\"filters\",\"sort\",\"hasResumeMatch\"],[[20,[\"currentUser\"]],[20,[\"searchText\"]],[20,[\"appliedFilters\"]],[20,[\"customListFilters\"]],[20,[\"assessmentSection\"]],[20,[\"model\"]],[20,[\"columns\"]],[20,[\"sortKey\"]],[20,[\"assessmentFilters\"]],[20,[\"filters\"]],[20,[\"sort\"]],[20,[\"userPermission\",\"hasResumeMatch\"]]]]],false],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/assessment/details/candidate.hbs"
    }
  });

  _exports.default = _default;
});