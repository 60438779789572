define("recruit/validations/mcq", ["exports", "recruit/validators/confirm-presence", "recruit/validators/confirm-text-presence", "ember-changeset-validations/validators"], function (_exports, _confirmPresence, _confirmTextPresence, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    content: [(0, _validators.validatePresence)(true), (0, _confirmTextPresence.default)()],
    choice_1: [(0, _confirmPresence.default)()],
    choice_2: [(0, _confirmPresence.default)()],
    correct_choice: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});