define("recruit/validators/confirm-text-presence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateConfirmTextPresence;

  function validateConfirmTextPresence() {
    return function (key, newValue) {
      var isTextPresent = newValue && newValue.trim().length ? true : false;

      if (isTextPresent) {
        var hasText = newValue.includes("src") && newValue.includes("img"); // check whether string includes img or src substrings

        var replacedText = hasText ? newValue.replace(/(<([^>]+)>)/ig, '') : newValue; // strip all html tags from string and check length

        var isValid = replacedText.trim().length ? true : false;
        return isValid || key.capitalize() + ' must include text';
      }
    };
  }
});