define("recruit/routes/panel/event/invitation", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      event_user_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.get('store').findRecord('eventUser', params.event_user_id).then(function (eu) {
        return Ember.RSVP.hash({
          eventUser: eu,
          event: eu.get('event')
        });
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      if (model.event.get('status') != 3 || model.event.get('status') != 6) {
        return this.get('router').replaceWith('panel.event.details.register', model.event, {
          queryParams: {
            event_user_id: transition.queryParams.event_user_id
          }
        });
      }
    },
    afterModel: function afterModel(model) {
      if (model.event.get('status') == 3 || model.event.get('status') == 6) {
        var e = {
          errors: [{
            title: "Oops!",
            detail: model.event.get('status') == 3 ? "The Event is cancelled." : "The Event is already complete."
          }]
        };
        return Ember.RSVP.reject(e);
      }
    }
  });

  _exports.default = _default;
});