define("recruit/controllers/recruiter/live-schedule/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({// userTimeZone: Ember.computed(function() {
    //   if(this.get('timeZone')) {
    //     return this.get('store').peekRecord('settings-allowed', this.get('timeZone.firstObject.settings_allowed_value_id'))
    //   }
    // }),
  });

  _exports.default = _default;
});