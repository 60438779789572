define("recruit/components/panel-evaluation/section-modal-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changeAnswer: function changeAnswer(answer) {
        this.sendAction('changeAnswer', answer);
      },
      toggleModal: function toggleModal() {
        this.sendAction('toggleModal');
      },
      gotoNextAnswer: function gotoNextAnswer(answer) {
        this.sendAction('gotoNextAnswer', answer);
      }
    }
  });

  _exports.default = _default;
});