define("recruit/data/reports-advance-filters-recruiter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*REPORTS ADVANCE FILTERS FOR RECRUITER STARTS*/
  var _default = [{
    id: 1,
    type: "filter/master",
    attributes: {
      "field": "Roles",
      "objectType": "rf",
      "isRemote": false,
      'list': [{
        key: 'Master Recuriter',
        value: 'Master Recuriter'
      }, {
        key: 'Recuriter',
        value: 'Recuriter'
      }, {
        key: 'Evaluator',
        value: 'Evaluator'
      }],
      "type": 'list',
      "queryTemplate": '(roles: {{value}})'
    }
  }, {
    id: 2,
    type: "filter/master",
    attributes: {
      field: "Assessment Status",
      isRemote: true,
      model: "status/assessment",
      doPeekModel: true,
      optionLabel: 'label',
      objectType: "rf",
      queryTemplate: "(assessment.status_id:{{value}})",
      type: "list"
    }
  }];
  /*REPORTS ADVANCE FILTERS FOR RECRUITER ENDS*/

  _exports.default = _default;
});