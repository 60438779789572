define("recruit/components/model-table/recruiter/candidate-loading-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['lt-row']
  });

  _exports.default = _default;
});