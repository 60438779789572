define("recruit/components/recruiter-view/assessment/helpers/notification-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notification: Ember.computed('row', function () {
      return this.get('row.content');
    })
  });

  _exports.default = _default;
});