define("recruit/templates/design/live-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l9cp3DSO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-wrapper\",null,[[\"showSupport\"],[[20,[\"showSupport\"]]]],{\"statements\":[[6,\"div\"],[9,\"class\",\"five wide column\"],[7],[0,\"\\n  \"],[6,\"img\"],[9,\"src\",\"/images/cuton_search.png\"],[9,\"alt\",\"\"],[9,\"class\",\"ui image\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ten wide column padding-bg-top margin-top-thirty\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"centered row\"],[7],[0,\"\\n    \"],[6,\"h3\"],[9,\"class\",\"text-black text-center\"],[7],[0,\"Seems like the interview has not yet started!\"],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"text-black text-center\"],[7],[0,\"You can join \"],[6,\"strong\"],[7],[0,\"10 minutes\"],[8],[0,\" before the scheduled time mentioned below:\"],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"text-size-large text-blue text-center\"],[7],[0,\"22 Nov 2017 | 06:00 PM\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/design/live-prompt.hbs"
    }
  });

  _exports.default = _default;
});