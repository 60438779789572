define("recruit/models/actions/live-schedule", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    startDate: _emberData.default.attr('pgdate'),
    endDate: _emberData.default.attr('pgdate'),
    event: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    panel: _emberData.default.attr(),
    candidateID: _emberData.default.attr('number'),
    email: _emberData.default.attr('string'),
    candidateName: _emberData.default.attr('string'),
    assessmentCandidateID: _emberData.default.attr('number'),
    assessmentSectionID: _emberData.default.attr('number'),
    isNotify: _emberData.default.attr('boolean', {
      default: true
    }),
    panelMessage: _emberData.default.attr('string', {
      default: ' '
    }),
    candidateMessage: _emberData.default.attr('string', {
      default: " "
    }),
    startsAt: Ember.computed.alias('startDate'),
    endsAt: Ember.computed.alias('endDate'),
    from: Ember.computed.alias('startDate'),
    to: Ember.computed.alias('endDate'),
    title: Ember.computed.alias('event'),
    timezone: _emberData.default.attr(),
    currentOffset: _emberData.default.attr(),
    currentTimezone: _emberData.default.attr(),
    date: _emberData.default.attr('pgdate'),
    status: _emberData.default.attr('number'),
    response: _emberData.default.attr('string'),
    askCandidate: _emberData.default.attr('boolean'),
    askPanel: _emberData.default.attr('boolean'),
    sync: _emberData.default.attr('boolean'),
    isOffline: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    location: _emberData.default.attr(),
    liveSession: _emberData.default.belongsTo('liveSession'),
    sessionStartTime: Ember.computed('date', 'startsAt', function () {
      var startTime = (0, _moment.default)(this.get('startsAt'));
      var date = (0, _moment.default)(this.get('startsAt')).startOf('day').add(startTime.hours(), 'hours').add(startTime.minutes(), 'minutes').add(startTime.seconds(), 'seconds').toDate();
      return date;
    }),
    sessionEndTime: Ember.computed('date', 'endsAt', function () {
      var endTime = (0, _moment.default)(this.get('endsAt'));
      var date = (0, _moment.default)(this.get('startsAt')).startOf('day').add(endTime.hours(), 'hours').add(endTime.minutes(), 'minutes').add(endTime.seconds(), 'seconds').toDate();
      return date;
    }),
    bgClass: Ember.computed('type', function () {
      return this.get('type') === 2 ? 'available' : 'booked';
    })
  });

  _exports.default = _default;
});