define("recruit/components/events/panel-view", ["exports", "recruit/mixins/g-actions", "recruit/config/environment"], function (_exports, _gActions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    store: Ember.inject.service(),
    isDevelopment: _environment.default.isDevelopment,
    init: function init() {
      this._super.apply(this, arguments);

      this.initializeProperty();
    },
    initializeProperty: function initializeProperty() {
      this.setProperties({
        'selectedPanel': null,
        'isPanelDelete': false,
        'isLoading': false
      });
    },
    actions: {
      toggleModal: function toggleModal(property, item) {
        //item is an object {key: [property stored current selection], value}
        if (item) {
          this.set(item.key, item.value);
        }

        this.toggleProperty(property);
        return false;
      },
      remind: function remind(property) {
        var _this = this;

        var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        this.get('store').createRecord('actions/event-panel-notify', {
          event_id: this.get('eventModel.id'),
          eventUsers: value ? [{
            id: value.get('id')
          }] : []
        }).save().then(function () {
          if (!value) {
            _this.get('toast').success("A reminder email has been sent to ".concat(_this.get('eventModel.panel.length'), " panel"));
          } else {
            _this.get('toast').success("A reminder email has been sent to ".concat(value.get('user.name'), "."));
          }

          if (property) {
            _this.send('toggleModal', property);
          }
        });
      },
      remove: function remove(item, togglableProperty) {
        var _this2 = this;

        //item is an object {key: [property stored current selection], value}
        if (!item.value.get('isPanelDeleteable')) {
          return this.get('toast').error('Panel can not be removed.');
        }

        var model = item.value;
        this.set('isLoading', true);
        model.destroyRecord().then(function () {
          return _this2.get('eventModel').reloadEventRelations();
        }).then(function () {
          if (togglableProperty) {
            _this2.send('toggleModal', togglableProperty, {
              key: item.key,
              value: null
            });
          }

          _this2.get('toast').success("Panel removed");
        }).catch(function (e) {
          return _this2.catchError(e);
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      },
      invite: function invite(values, relationType, actionType) {
        if (!this.get('eventModel.isEventEditable')) {
          return this.get('toast').error('This event can not be updated.');
        }

        var isEventActions = {
          key: 'isEventActions',
          value: true,
          type: 'flag'
        };
        var event = {
          key: 'events',
          value: this.get('eventModel'),
          type: 'value'
        };
        this.send('routeActions', values, relationType, actionType, isEventActions, event);
      }
    }
  });

  _exports.default = _default;
});