define("recruit/models/lf-adapter/assessment-question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    question: _emberData.default.attr(),
    title: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    plainText: _emberData.default.attr('string'),
    plainTxtContent: _emberData.default.attr('string'),
    qCount: _emberData.default.attr('number'),
    question_id: _emberData.default.attr('number'),
    skill_id: _emberData.default.attr('number'),
    questionType: _emberData.default.attr('number') // type = 1 for question based; type = 2 for skill based questions.

  });

  _exports.default = _default;
});