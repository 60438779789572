define("recruit/models/user", ["exports", "ember-data", "moment", "npm:lodash", "recruit/mixins/profile-pic-initials"], function (_exports, _emberData, _moment, _npmLodash, _profilePicInitials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import Changeset from 'ember-changeset';
  //import lookupValidator from 'ember-changeset-validations';
  var _default = _emberData.default.Model.extend(_profilePicInitials.default, {
    external_id: _emberData.default.attr('string'),
    createdBy: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      inverse: null
    }),
    username: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    roles: _emberData.default.attr(),
    current_role: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization', {
      inverse: null
    }),
    status: _emberData.default.attr('number', {
      defaultValue: 10
    }),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    logged_in_at: _emberData.default.attr('pgdate'),
    gravatarLink: _emberData.default.attr('string'),
    availability: _emberData.default.hasMany('user-calendar', {
      inverse: null
    }),
    busyCalendar: _emberData.default.hasMany('user-calendar', {
      inverse: null
    }),
    liveCalendar: _emberData.default.hasMany('user-calendar', {
      inverse: null
    }),
    permissions: _emberData.default.hasMany('user-permission'),
    password_reset_token: _emberData.default.attr(),
    appState: _emberData.default.attr(),
    metrics: _emberData.default.belongsTo('user-metrics'),
    nameWithEmail: Ember.computed(function () {
      return "".concat(this.get('name'), " (").concat(this.get('email'), ")");
    }),
    allRoles: Ember.computed(function () {
      return this.get('roles').join(', ');
    }),
    statusOptions: Ember.A([{
      key: 10,
      value: 'New'
    }, {
      key: 20,
      value: 'Active'
    }, {
      key: 30,
      value: 'Suspended'
    }, {
      key: 40,
      value: 'Inactive'
    }]),
    hasRole: function hasRole(role) {
      return _npmLodash.default.includes(this.get('current_role'), role);
    },
    statusText: Ember.computed('status', function () {
      return _npmLodash.default.get(_npmLodash.default.find(this.get('statusOptions'), {
        key: this.get('status')
      }), 'value') || 'Added';
    }),
    statusValue: function statusValue(value) {
      return _npmLodash.default.get(_npmLodash.default.find(this.get('statusOptions'), {
        value: value
      }), 'key') || 10;
    },
    isRecruiter: Ember.computed('current_role', 'status', function () {
      return this.hasRole('Recruiter') || this.hasRole('Master Recruiter');
    }),
    isMasterRecruiter: Ember.computed('current_role', 'status', function () {
      return this.hasRole('Master Recruiter');
    }),
    isPanel: Ember.computed('current_role', function () {
      return this.hasRole('Evaluator');
    }),
    rolePath: Ember.computed('current_role', function () {
      return this.get('isPanel') ? 'panel' : 'recruiter';
    }),
    statusLabelColor: Ember.computed('status', function () {
      return _npmLodash.default.get(_npmLodash.default.find(this.get('_statusLabelColorMap'), {
        key: this.get('status')
      }), 'value') || 'Green';
    }),
    hasPanel: Ember.computed(function () {
      return _npmLodash.default.includes(this.get('roles'), 'Evaluator');
    }),
    defaultProfileImage: Ember.computed('gravatarLink', function () {
      return this.get('profileImg') || '/images/user-icon-cuton.jpg';
    }),
    profilePicSource: Ember.computed.alias('profileImg'),
    fullName: Ember.computed.alias('name'),
    currentId: Ember.computed.alias('id'),
    //alias for profile-picture-mixin
    _statusLabelColorMap: Ember.A([{
      key: 10,
      value: 'blue'
    }, {
      key: 20,
      value: 'green'
    }, {
      key: 30,
      value: 'red'
    }, {
      key: 40,
      value: 'brown'
    }]),
    resetPassword: function resetPassword() {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.resetPassword(this.get('id'));
    },
    // Used in Recruiter name filter
    key: Ember.computed('name', function () {
      return this.get('name');
    }),
    value: Ember.computed('id', function () {
      return this.get('id');
    }),
    busyCalendarEvents: Ember.computed(function () {
      return this.get('store').query('extended-user-calendar', {
        type: 3,
        user_id: this.get('id'),
        startsAt: (0, _moment.default)().unix(),
        endsAt: (0, _moment.default)().add(1, 'months').unix()
      });
    }),
    availablityEvents: Ember.computed('availability.[]', function () {
      return this.get('store').query('extended-user-calendar', {
        type: 2,
        user_id: this.get('id'),
        startsAt: (0, _moment.default)().unix(),
        endsAt: (0, _moment.default)().add(1, 'months').unix()
      });
    })
  });

  _exports.default = _default;
});