define("recruit/templates/components/answer-views/evaluation-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "06waykfU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui centered grid margin-no margin-top-sixty\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"eight wide centered center aligned column margin-top-sixty\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"src\",\"/images/empty_states/view_form.jpg\"],[9,\"alt\",\"\"],[9,\"class\",\"ui centered small image\"],[7],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"padding-md margin-no\"],[7],[0,\"This is an evaluation only section.\\n\"],[4,\"if\",[[20,[\"canEvaluate\"]]],null,{\"statements\":[[0,\"        \"],[6,\"br\"],[7],[8],[0,\"Use evaluation panel on the right to evaluate.\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"canEvaluate\"]]],null,{\"statements\":[[0,\"      \"],[6,\"img\"],[9,\"src\",\"/images/panel/hand.gif\"],[9,\"alt\",\"\"],[9,\"class\",\"ui centered image\"],[9,\"style\",\"width:60px; padding-top:0;\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/answer-views/evaluation-only.hbs"
    }
  });

  _exports.default = _default;
});