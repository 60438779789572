define("recruit/components/recruiter-view/dashboard/calendar-events", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    loadingCount: new Array(4),
    liveCalendar: Ember.computed(function () {
      return this.get('currentUser.me.liveCalendar');
    }),
    events: Ember.computed('liveCalendar.[]', function () {
      var calendarEvents = this.get('liveCalendar').filter(function (et) {
        return et.get('live_session_id');
      }),
          events = [];
      calendarEvents.forEach(function (et) {
        var hasSession = events.findBy('date', (0, _moment.default)(et.get('from')).format("DD MMM'YY"));

        if (!hasSession) {
          events.pushObject(Ember.Object.create({
            date: (0, _moment.default)(et.get('from')).format("DD MMM'YY"),
            day: (0, _moment.default)(et.get('from')).format("dddd"),
            sessions: []
          }));
        }

        events.findBy('date', (0, _moment.default)(et.get('from')).format("DD MMM'YY")).get('sessions').pushObject(et);
        events.forEach(function (et) {
          et.sessions = et.sessions.uniqBy('live_session_id');
        });
      });
      return events.sortBy('date');
    })
  });

  _exports.default = _default;
});