define("recruit/components/live-schedule/calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isLoading: false,
    occurrences: Ember.A(),
    didInsertElement: function didInsertElement() {
      return Ember.RSVP.resolve();
    },
    actions: {
      calendarAddOccurrence: function calendarAddOccurrence(occurrence) {
        var _this = this;

        // if(!(this.get('selectedLiveSection'))){
        //   return this.get('toast').error('Select a section', 'Invalid Section');
        // }
        // if(!(this.get('selectedPanels.length') > 0)){
        //   return this.get('toast').error('Select atleast one panel', 'Invalid Panel');
        // }
        this.get('occurrences').forEach(function (occurrence) {
          if (occurrence.get('isNew')) {
            _this.get('occurrences').removeObject(occurrence);
          }
        });
        var selectedPanels = this.get('selectedPanels').map(function (p) {
          return {
            id: p.get('id')
          };
        });
        var model = this.get('store').createRecord('actions/live-schedule', {
          event: occurrence.get('title') || 'Live',
          startDate: occurrence.get('startsAt'),
          endDate: occurrence.get('endsAt'),
          panel: selectedPanels,
          currentOffset: (0, _moment.default)().local().utcOffset() * 60,
          assessmentCandidateID: this.get('model.id'),
          assessmentSectionID: this.get('selectedLiveSection.id'),
          type: 1
        });
        this.set('isLoading', true);
        return this.get('occurrences').pushObject(model);
      },
      calendarUpdateOccurrence: function calendarUpdateOccurrence(occurrence, properties) {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this2, _this2._updateEvent, occurrence, properties, resolve, reject, 300);
        });
      },
      calendarRemoveOccurrence: function calendarRemoveOccurrence() {
        return;
      },
      calendarNavigateWeek: function calendarNavigateWeek() {},
      showEvent: function showEvent() {},
      revertModel: function revertModel(model) {
        if (model.get('isNew')) {
          this.get('occurrences').removeObject(model);
        } else {
          model.rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});