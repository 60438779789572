define("recruit/templates/components/reports/helpers/assessment-graph-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pwuKrnma",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"style\",\"width: 50%; margin: 0px 10px;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"loader-header\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"loader-header animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"loader-header\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"loader-header animated-background\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"loader-header\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,\"and\",[[25,\"not\",[[20,[\"showEmptyState\"]]],null],[20,[\"selectedRow\",\"title\"]]],null]],null,{\"statements\":[[0,\"  \\t\"],[6,\"div\"],[9,\"class\",\"padding-md-left padding-md-right\"],[7],[0,\"\\n  \\t\\t\"],[6,\"div\"],[9,\"class\",\"text-weight-medium text-black text-size-medium center\"],[7],[0,\"\\n  \\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"ui assessment details display-inline-block\"],[10,\"data-content\",[26,[\"Assessment Name: \",[20,[\"selectedRow\",\"title\"]]]]],[7],[0,\"\\n           \"],[4,\"link-to\",[\"recruiter.assessment.details.overview\",[20,[\"selectedRow\",\"id\"]]],[[\"class\"],[\"text-blue text-weight-medium margin-no ellipsis\"]],{\"statements\":[[1,[20,[\"selectedRow\",\"title\"]],false]],\"parameters\":[]},null],[0,\"\\n        \"],[8],[0,\"\\n  \\t\\t\"],[8],[0,\"\\n  \\t\\t\"],[6,\"div\"],[9,\"class\",\"ui assessment details display-inline-block text-black text-size-small\"],[10,\"data-content\",[26,[\"External Id: \",[20,[\"selectedRow\",\"external_id\"]]]]],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[7],[1,[20,[\"selectedRow\",\"external_id\"]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n  \\t\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/assessment-graph-header.hbs"
    }
  });

  _exports.default = _default;
});