define("recruit/templates/recruiter/assessment/details/edit-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YVsgIygL",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"recruiter-view/assessment/details/edit-notification-template\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"],[1,[18,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/assessment/details/edit-template.hbs"
    }
  });

  _exports.default = _default;
});