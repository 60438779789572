define("recruit/routes/recruiter/candidate/details/profile", ["exports", "ember-cli-uuid", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberCliUuid, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    actions: {
      inviteCandidate: function inviteCandidate(candidate) {
        var _this = this;

        this.get('store').createRecord('modal-actions/recruiter/actions', {
          id: (0, _emberCliUuid.uuid)(),
          actionType: 'Invite to Assessment',
          candidates: [candidate],
          relationType: 'candidates'
        }).save().then(function (response) {
          _this.get('router').transitionTo('recruiter.recruiter-actions.actions', response);
        }).catch(function (err) {
          if (err.errors) {
            err.errors.forEach(function (e) {
              _this.get('toast').error(e.detail, 'Error');
            });
          } else {
            _this.get('toast').error('Please try after some time', 'Something went wrong');
          }
        });
      }
    }
  });

  _exports.default = _default;
});