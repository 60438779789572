define("recruit/components/filters/custom-filters", ["exports", "npm:lodash", "moment"], function (_exports, _npmLodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLoading: Ember.computed.not('listSource.isFulfilled'),
    isValueSet: false,
    currentDate: Ember.computed(function () {
      return (0, _moment.default)().toDate();
    }),
    popupOptions: {
      position: 'top center'
    },
    startDateObserver: Ember.observer('startDate', function () {
      var _this = this;

      Ember.run.next(function () {
        if (_this.get('startDate')) {
          Ember.$('#endDate input').focus();
        }
      });
    }),
    listSource: Ember.computed('filter', 's', function () {
      if (this.get('filter.isRemote')) {
        var query = this.get('modelQuery') || {};
        query.s = this.get('s');
        query = _npmLodash.default.omitBy(query, _npmLodash.default.isNil);
        return this.get('store').query(this.get('filter.model'), query);
      } else {
        if (this.get('filter.field') === "Section type") {
          this.setSectionTypeFilters(this.get('filter'));
        }

        return this.get('filter.list');
      }
    }),
    setSectionTypeFilters: function setSectionTypeFilters(filterObject) {
      filterObject.set('list', Ember.A([]));
      this.get('availableSections').forEach(function (section) {
        var sectionObject = Ember.Object.create({
          value: section.get('id'),
          label: section.get('name'),
          key: section.get('label')
        });
        filterObject.get('list').pushObject(sectionObject);
      });
    },
    searchObserver: Ember.observer('search', function () {
      var _this2 = this;

      if (!this.get('isValueSet')) {
        Ember.run.debounce(this, function () {
          _this2.set('s', _this2.get('search'));
        }, 200);
      }
    }),
    actions: {
      setListValue: function setListValue(value) {
        this.set('isValueSet', true);
        this.set('listValue', value);
      },
      onSearch: function onSearch(search) {
        var _this3 = this;

        var query = this.get('modelQuery') || {};
        query.s = search;
        query = _npmLodash.default.omitBy(query, _npmLodash.default.isNil);
        return this.get('store').query(this.get('filter.model'), query).then(function (newListSource) {
          _this3.set('listSource', newListSource);
        });
      },
      add: function add() {
        this.sendAction('applyFilter');
      },
      onShow: function onShow() {
        Ember.$('.assessmentFilter').removeClass('overflow-auto');
      },
      onHide: function onHide() {
        Ember.$('.assessmentFilter').addClass('overflow-auto');
      }
    }
  });

  _exports.default = _default;
});