define("recruit/templates/components/filters/assessment/section-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tVrSqmcj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[25,\"helpers/dynamic-checkbox\",null,[[\"value\",\"labelName\",\"isChecked\",\"isMutAction\",\"actionName\"],[[20,[\"filter\"]],[20,[\"filter\",\"field\"]],[20,[\"checked\"]],false,[25,\"action\",[[19,0,[]],\"setField\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"checked\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"border-none box-shadow-none margin-no segment ui input\"],[9,\"style\",\"width:100%\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"filter\",\"type\"]],\"list\"],null]],null,{\"statements\":[[4,\"if\",[[20,[\"filter\",\"isRemote\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"helpers/searchable-dropdown\",null,[[\"searchEnabled\",\"modelName\",\"isRemoteSearch\",\"selectedOption\",\"searchField\",\"optionLabel\",\"modelQuery\",\"roles\",\"renderInPlace\",\"placeholder\",\"verticalPosition\",\"class\"],[true,[20,[\"modelName\"]],true,[20,[\"filterSubType\"]],[20,[\"optionLabel\"]],[20,[\"optionLabel\"]],[20,[\"modelQuery\"]],[20,[\"roles\"]],false,\"Select Option\",\"below\",\"section-dropdown-width\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"helpers/searchable-dropdown\",null,[[\"listSource\",\"searchEnabled\",\"selectedOption\",\"searchField\",\"optionLabel\",\"renderInPlace\",\"placeholder\",\"verticalPosition\",\"class\"],[[20,[\"listItems\"]],true,[20,[\"filterSubType\"]],[20,[\"optionLabel\"]],[20,[\"optionLabel\"]],false,\"Select Option\",\"below\",\"section-dropdown-width\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[6,\"div\"],[9,\"class\",\"ui button button-positive\"],[9,\"style\",\"border-top-left-radius:0;border-bottom-left-radius:0;\"],[3,\"action\",[[19,0,[]],\"apply\"]],[7],[0,\"Add\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/filters/assessment/section-filters.hbs"
    }
  });

  _exports.default = _default;
});