define("recruit/components/recruiter-view/assessment/helpers/score-view", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    score: Ember.computed('value', function () {
      if (!_npmLodash.default.isNil(this.get('value'))) {
        return this.get('value');
      }

      return '--';
    })
  });

  _exports.default = _default;
});