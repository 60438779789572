define("recruit/templates/user/change-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r+eJkA4T",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[\"Change role\"],null],false],[0,\"\\n\"],[4,\"modal-wrapper\",null,[[\"showSupport\"],[false]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ten wide centered column\"],[7],[0,\"\\n    \"],[1,[25,\"helpers/profile-picture\",null,[[\"src\",\"customClass\"],[[20,[\"model\",\"profilePic\"]],\"ui small circular centered image\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ten wide centered center aligned column\"],[7],[0,\"\\n    \"],[6,\"form\"],[9,\"class\",\"ui form\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"field\"],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"text-size-medium\"],[7],[0,\"Select Role\"],[8],[0,\"\\n        \"],[1,[25,\"helpers/searchable-dropdown\",null,[[\"searchEnabled\",\"selectedOption\",\"placeHolder\",\"isRemoteSearch\",\"isObjectType\",\"listSource\"],[false,[20,[\"currentRole\"]],\"Search an Role\",false,false,[20,[\"model\",\"roles\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"field\"],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"ui negative submit button top margin\"],[3,\"action\",[[19,0,[]],\"back\",[20,[\"model\"]]]],[7],[1,[25,\"t\",[\"common.cancel\"],null],false],[8],[0,\"\\n        \"],[6,\"button\"],[10,\"class\",[26,[\"ui primary submit button top margin \",[25,\"if\",[[20,[\"isLoading\"]],\"disabled\"],null]]]],[3,\"action\",[[19,0,[]],\"updateRole\",[20,[\"currentRole\"]],[20,[\"model\"]]]],[7],[0,\"\\n          \"],[4,\"if\",[[20,[\"isLoading\"]]],null,{\"statements\":[[1,[25,\"fa-icon\",[\"spinner\"],[[\"pulse\",\"class\"],[\"true\",\"text-grey\"]]],false]],\"parameters\":[]},null],[1,[25,\"t\",[\"common.update\"],null],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/user/change-role.hbs"
    }
  });

  _exports.default = _default;
});