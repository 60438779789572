define("recruit/components/model-table/recruiter/event-candidates", ["exports", "recruit/mixins/table-common", "recruit/config/environment"], function (_exports, _tableCommon, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    modelType: 'event-candidate',
    isDevelopment: _environment.default.isDevelopment,
    columns: Ember.computed(function () {
      return [{
        label: 'Name',
        valuePath: 'candidate',
        cellComponent: 'events/helpers/event-candidate-details',
        width: '400px',
        cellClassNames: 'padding-vs',
        classNames: ['table-head']
      }, {
        label: 'Assessment Name',
        valuePath: 'assessment',
        width: '250',
        cellClassNames: 'padding-vs',
        cellComponent: 'events/helpers/assessment-details-cell-component',
        classNames: ['table-head text-left']
      }, {
        label: 'Last Updated',
        width: '150',
        valuePath: 'updated_at',
        dateFormat: 'DD MMMM YYYY | hh:mm A',
        showTimeAgo: true,
        cellClassNames: 'padding-vs',
        cellComponent: 'helpers/date-view',
        classNames: ['table-head']
      }, {
        label: 'Invite Status',
        cellComponent: 'candidate-helpers/status',
        valuePath: 'assessmentCandidate.status',
        width: '150px',
        cellClassNames: 'text-center text-weight-light padding-vs',
        classNames: ['table-head text-center']
      }];
    }),
    actions: {
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      },
      bulkImportCandidates: function bulkImportCandidates(property) {
        this.sendAction('bulkImportCandidates', property);
      }
    }
  });

  _exports.default = _default;
});