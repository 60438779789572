define("recruit/models/actions/simulation-schedule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var durationList = [{
    key: '15 minutes',
    value: 15
  }, {
    key: '30 minutes',
    value: 30
  }, {
    key: '45 minutes',
    value: 45
  }, {
    key: '1 hour',
    value: 60
  }, // { key: '1 hour 30 minutes', value: 90},
  {
    key: '2 hours',
    value: 120
  }, {
    key: '3 hours',
    value: 180
  }];

  var _default = _emberData.default.Model.extend({
    startDate: _emberData.default.attr('pgdate'),
    endDate: _emberData.default.attr('pgdate'),
    candidateID: _emberData.default.attr('number'),
    email: _emberData.default.attr('string'),
    assessmentCandidateID: _emberData.default.attr('number'),
    assessmentSectionID: _emberData.default.attr('number'),
    isNotify: _emberData.default.attr('boolean', {
      default: true
    }),
    startsAt: Ember.computed.alias('startDate'),
    endsAt: Ember.computed.alias('endDate'),
    from: Ember.computed.alias('startDate'),
    to: Ember.computed.alias('endDate'),
    title: Ember.computed.alias('event'),
    timezone: _emberData.default.attr(),
    currentOffset: _emberData.default.attr(),
    currentTimezone: _emberData.default.attr(),
    date: _emberData.default.attr('pgdate'),
    panel: _emberData.default.attr(),
    status: _emberData.default.attr('number'),
    response: _emberData.default.attr('string'),
    candidateMessage: _emberData.default.attr('string'),
    askCandidate: _emberData.default.attr('boolean'),
    askPanel: _emberData.default.attr('boolean'),
    durations: durationList,
    durationPreSelected: durationList[3],
    bgClass: Ember.computed('type', function () {
      return this.get('type') === 2 ? 'available' : 'booked';
    }),
    startsAtTimeZoneFormated: Ember.computed.alias('from')
  });

  _exports.default = _default;
});