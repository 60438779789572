define("recruit/components/answer-views/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    answers: [],
    openModal: false,
    selectedAnswer: Ember.computed('answers.@each', function () {
      return this.get('answers.firstObject');
    }),
    actions: {
      changeAnswer: function changeAnswer(answer) {
        this.set('selectedAnswer', answer);
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('openModal');
      }
    }
  });

  _exports.default = _default;
});