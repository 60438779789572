define("recruit/templates/components/assessment/question/skill-based-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I1Mq+tUe",
    "block": "{\"symbols\":[\"skill\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"sectionModel\",\"sectionType\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui grid margin-no\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"fourteen wide column padding-no\"],[7],[0,\"\\n\"],[4,\"helpers/searchable-dropdown\",null,[[\"placeHolder\",\"searchEnable\",\"renderInPlace\",\"modelName\",\"section_type_id\",\"searchField\",\"isRemoteSearch\",\"optionLabel\",\"expand\",\"selectedOption\"],[\"Search skills\",true,false,\"skill\",[20,[\"section_type_id\"]],\"name\",true,\"name\",\"qCount\",[20,[\"selectedSkill\"]]]],{\"statements\":[[0,\"        \"],[6,\"span\"],[9,\"class\",\"float-right margin-right-fifteen\"],[7],[0,\"Available Questions: \"],[1,[25,\"get-value\",[[19,1,[\"qCount\"]]],null],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"two wide column padding-no\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui action input\"],[9,\"style\",\"width: 95%;\"],[7],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"id\",\"type\",\"min\",\"class\",\"value\"],[\"questionCount\",\"number\",\"0\",\"question-count\",[20,[\"skillQuestionCount\"]]]]],false],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"ui blue button\"],[3,\"action\",[[19,0,[]],\"setQuestionCount\",[20,[\"selectedSkill\"]],[20,[\"skillQuestionCount\"]]]],[7],[0,\"ADD\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"fourteen wide column padding-no\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"two wide column padding-no\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-center text-size-small questionCountInfo\"],[9,\"data-content\",\"Enter the count of questions you would like to add out of the available questions.\"],[7],[0,\"\\n        Question Count\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/assessment/question/skill-based-dropdown.hbs"
    }
  });

  _exports.default = _default;
});