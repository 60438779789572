define("recruit/components/recruiter-view/assessment/helpers/tags-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagSearchObserver: Ember.observer('tagSearchText', 'supportedTags.[]', function () {
      var _this = this;

      if (this.get('tagSearchText')) {
        this.set('tagList', this.get('supportedTags').filter(function (tag) {
          return tag.get('description').toLowerCase().includes(_this.get('tagSearchText').toLowerCase().trimStart());
        }));
      } else {
        this.set('tagList', this.get('supportedTags'));
      }
    }).on('init'),
    actions: {
      copyLink: function copyLink(text) {
        this.sendAction('copyLink', text);
      }
    }
  });

  _exports.default = _default;
});