define("recruit/models/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    file_type_id: _emberData.default.attr('number'),
    size: _emberData.default.attr('string'),
    resourceUrl: _emberData.default.attr('string'),
    pdfUrl: _emberData.default.attr('string'),
    originalName: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('pgdate'),
    fileType: Ember.computed('file_type_id', function () {
      if (this.get('file_type_id') == 4 || this.get('file_type_id') == 37 || this.get('file_type_id') == 41) {
        return 'video/mp4';
      } else if (this.get('file_type_id') == 5) {
        return 'video/webm';
      }
    }),
    flowplayerObject: Ember.computed('fileType', 'resourceUrl', function () {
      return {
        src: this.get('resourceUrl'),
        type: this.get('fileType')
      };
    }),
    liveWebrtcRecording: Ember.computed('flowplayerObject', function () {
      return Ember.RSVP.resolve([this.get('flowplayerObject')]);
    }),
    pdfLink: Ember.computed('resourceUrl', 'pdfLink', function () {
      return this.get('pdfUrl') || this.get('resourceUrl');
    }),
    isUploadedBefore: Ember.computed('created_at', function () {
      if (this.get('created_at')) {
        var created_at = moment(this.get('created_at')).add(10, 'minutes');
        return created_at.isBefore(moment());
      }
    })
  });

  _exports.default = _default;
});