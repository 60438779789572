define("recruit/routes/recruiter/candidate/details/assessment", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "recruit/config/environment", "moment"], function (_exports, _authenticatedRouteMixin, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    model: function model(params) {
      return this.get('store').query('assessment-candidate', {
        id: params.ac_id,
        v: (0, _moment.default)().unix()
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', Ember.isArray(model) ? model.get('firstObject') : model);
      var detailsController = this.controllerFor('recruiter.candidate.details');

      if (detailsController) {
        detailsController.set('selectedAssessmentId', controller.get('model.assessment.id'));
      }

      controller.set('isDevelopment', _environment.default.isDevelopment);
      controller.set('candidates', [controller.get('model')]);
      controller.set('currentUser', this.get('currentUser.me'));
      controller.set('isInvitationLoading', true);
      controller.set('isInviteView', false); // return model.get('firstObject.assessmentSectionCandidates').then(assc => {

      return this.get('store').query('assessment-section-candidate', {
        assessment_candidate_id: controller.get('model.id'),
        v: (0, _moment.default)().unix()
      }).then(function (asscs) {
        asscs.forEach(function (assc) {
          return controller.get('model.assessmentSectionCandidates').pushObject(assc);
        });
        controller.set('isInvitationLoading', false);
        var currentAssc = asscs.get('lastObject');
        controller.set('selectedAssc', currentAssc);
        controller.set('selectedAs', currentAssc.get('assessmentSection'));
      });
    },
    actions: {
      setAssc: function setAssc() {
        this.controller.set('isDirect', false);
      },
      back: function back() {
        history.back();
      },
      toggleModal: function toggleModal(modalName) {
        this.controller.toggleProperty('is' + modalName);
      }
    }
  });

  _exports.default = _default;
});