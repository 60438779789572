define("recruit/templates/components/reports/helpers/table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zErxZXd7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"ui column head text-center \",[18,\"dynamicName\"]]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ellipsis\"],[7],[0,\"\\n    \"],[1,[20,[\"column\",\"label\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"column\",\"subLabel\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ellipsis\"],[7],[1,[20,[\"column\",\"subLabel\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[20,[\"column\",\"hideDot\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",[26,[\"table-head-dot \",[20,[\"column\",\"label\"]]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[8],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/table-header.hbs"
    }
  });

  _exports.default = _default;
});