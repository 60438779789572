define("recruit/components/high-chart-view", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isLoading: true,
    noCountsLoaded: true,
    modelObserver: Ember.observer('model', function () {
      this.initChart();
    }),
    didInsertElement: function didInsertElement() {
      this.set('categories', ['Invited', 'Attending', 'Completed', 'Selected', 'On Hold', 'Rejected']);
      this.initChart();
    },
    initChart: function initChart() {
      var c = 0;
      this.set('isLoading', true);
      this.set('noCountsLoaded', true);
      var countData = Ember.A([{
        y: this.get('model.invitedCount'),
        name: "Invited",
        color: '#BDBDBD',
        borderColor: '#34363D',
        borderWidth: 1,
        sortKey: 20
      }, {
        y: this.get('model.inProgressCount'),
        name: "Attending",
        color: '#F9C19C',
        borderColor: '#F2711C',
        borderWidth: 1,
        sortKey: 30
      }, {
        y: this.get('model.attendedCount'),
        name: "Completed",
        color: '#A2D6FF',
        borderColor: '#00A7FF',
        borderWidth: 1,
        sortKey: 50
      }, {
        y: this.get('model.selectedCount'),
        name: "Selected",
        color: '#B2E6DC',
        borderColor: '#1ABA9A',
        borderWidth: 1,
        sortKey: 70
      }, {
        y: this.get('model.holdCount'),
        name: "On Hold",
        color: '#FFF0C5',
        borderColor: '#FFBF00',
        borderWidth: 1,
        sortKey: 80
      }, {
        y: this.get('model.rejectedCount'),
        name: "Rejected",
        color: '#FF9690',
        borderColor: '#BC3A38',
        borderWidth: 1,
        sortKey: 90
      }]);

      if (!_npmLodash.default.isNil(this.get('model.addedCount'))) {
        this.get('categories').insertAt(0, 'Added');
        countData.insertAt(0, {
          y: this.get('model.addedCount'),
          name: "Added",
          color: '#f0f0f0',
          borderColor: '#CCC',
          borderWidth: 1,
          sortKey: 20
        });
      }

      countData.forEach(function (d) {
        c = c + d.y;
      });
      this.set('noCountsLoaded', !(c === 0));
      this.set('isLoading', false);
      this.set('statusCount', countData);
    },
    chartOption: Ember.computed('isLoading', 'statusCount', function () {
      var _this = this;

      return {
        chart: {
          height: this.get('columnHeight'),
          type: 'column'
        },
        title: {
          text: this.get('chartText')
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'category',
          categories: this.get('categories'),
          labels: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            },
            cursor: 'pointer'
          },
          column: {
            events: {
              click: function click(e) {
                if (_this.get('bindGraphActions')) {
                  var filter = JSON.stringify({
                    'id': _this.getID(e.point.options.sortKey),
                    'sortKey': e.point.options.sortKey,
                    'label': e.point.options.name,
                    'type': 'finalStatus',
                    'type_id': 10
                  });

                  _this.get('router').transitionTo('recruiter.assessment.details.candidate', _this.get('model.id'), {
                    queryParams: {
                      "assessmentFilters": filter
                    }
                  });
                }
              }
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:#4a4a4a; font-weight:bold;">{point.name}</span>: <b>{point.y}</b>'
        },
        "series": [{
          name: 'Current invites count',
          colorByPoint: true,
          data: this.get('statusCount')
        }]
      };
    }),
    getID: function getID(sortKey) {
      switch (sortKey) {
        case 70:
          return 6;

        case 90:
          return 8;

        case 80:
          return 7;

        case 30:
          return 3;

        case 50:
          return 4;

        case 60:
          return 5;

        case 20:
          return 2;
      }
    },
    actions: {
      bulkImportCandidates: function bulkImportCandidates(property) {
        this.sendAction('bulkImportCandidates', property);
      }
    }
  });

  _exports.default = _default;
});