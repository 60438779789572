define("recruit/components/live-session/scheduling/modal", ["exports", "recruit/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    isOrganiser: false,
    requestMadeFor: (0, _moment.default)().startOf('days'),
    isDevelopment: _environment.default.isDevelopment,
    customPopupOptions: {
      position: 'bottom center'
    },
    disableCheckbox: Ember.computed('panelList.[]', function () {
      return this.get('panelList.length') <= 1;
    }),
    disablePre: Ember.computed('liveSession.from', function () {
      return (0, _moment.default)(this.get('liveSession.from')).isSame(this.get('minimumDate'), 'day');
    }),
    gridWidth: Ember.computed('openModal', 'panelList.[]', 'selectedPanels.[]', function () {
      this.get('selectedPanels').findBy('id', this.get('currentUser.me.id')) ? this.set('isOrganiser', true) : this.set('isOrganiser', false);

      if (this.get('openModal')) {
        var width = Ember.$('#liveScheduleCalendar').innerWidth();

        if (this.get('panelList').findBy('id', this.get('currentUser.me.id'))) {
          if (width < 1080) {
            return this.get('panelList.length') > 2;
          } else {
            return this.get('panelList.length') > 3;
          }
        } else {
          if (width < 1080) {
            return this.get('panelList.length') > 1;
          } else {
            return this.get('panelList.length') > 2;
          }
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('liveSession.from')) {
        this.set('liveSession.from', this.get('currentDate'));
      }

      Ember.run.next('afterRender', function () {
        if (_this.get('openModal')) {
          Ember.$('.ui.sticky').sticky();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('openModal')) {
        this.toggleProperty('openModal');
      }
    },
    setEndAtObserver: Ember.observer('liveSession.durationPreSelected.value', 'liveSession.from', function () {
      var duration = this.get('liveSession.durationPreSelected.value');
      var endAt = (0, _moment.default)(this.get('liveSession.from')).add(duration, 'minutes');
      this.set('liveSession.to', endAt._d);
    }),
    timeRange: Ember.computed('liveSession.from', function () {
      var times = [];
      var selectedDate = this.get('liveSession.from') ? (0, _moment.default)(this.get('liveSession.from')) : (0, _moment.default)();

      for (var i = 0; i < 24; i++) {
        var currentTimeRange = selectedDate.startOf('day').add(1000 * 60 * 60 * i, 'milliseconds');
        var timeString = currentTimeRange.clone();
        times.push(timeString);
      }

      return times;
    }),
    //fetching calendar events beyond 30 days from current date
    fetchCalendarEvents: function fetchCalendarEvents() {
      var _this2 = this;

      var startOfselectedDate = (0, _moment.default)(this.get('timeRange.firstObject')).startOf('days');
      var endOfselectedDate = (0, _moment.default)(this.get('timeRange.firstObject')).endOf('days');

      if (startOfselectedDate.isAfter((0, _moment.default)().add(1, 'months'), 'day') && !startOfselectedDate.isSame(this.get('requestMadeFor'), 'day')) {
        Ember.RSVP.resolve(this.get('candidateRecord.calendarEvents')).then(function () {
          return _this2.get('candidateRecord.calendarEvents').filter(function (event) {
            return event.get('from').isSame(_this2.get('timeRange.firstObject'), 'day');
          });
        }).then(function (res) {
          if (!res.length) {
            _this2.set('requestMadeFor', startOfselectedDate);

            _this2.set('isLoading', true);

            _this2.get('store').query('candidate-calendar', {
              candidate_id: _this2.get('candidateRecord.id'),
              startsAt: startOfselectedDate.unix(),
              endsAt: endOfselectedDate.unix()
            }).then(function (res) {
              return _this2.formatEvents(res, _this2.get('candidateRecord.calendarEvents'));
            }).then(function () {
              return _this2.set('candidateRecord.calendarEvents', _this2.get('candidateRecord.calendarEvents').uniqBy('id'));
            }).catch(function (e) {
              if (e.errors.length) {
                e.errors.forEach(function (error) {
                  _this2.get('toast').error(error.detail);
                });
              } else {
                _this2.get('toast').error('Please try some time later', 'Something went wrong');
              }
            }).finally(function () {
              _this2.set('isLoading', false);
            });
          }
        });
      }
    },
    candidateAllDayEvent: Ember.computed('candidateRecord.calendarEvents.[]', 'timeRange.[]', function () {
      var _this3 = this;

      if (this.get('candidateRecord.calEvents.isFulfilled')) {
        this.fetchCalendarEvents();
      }

      if (this.get('candidateRecord.calendarEvents.length')) {
        return this.get('candidateRecord.calendarEvents').filter(function (calendarEvent) {
          var startAt = calendarEvent.get('from').valueOf();
          var endAt = calendarEvent.get('to').valueOf();
          var startOfDay = (0, _moment.default)(_this3.get('timeRange.firstObject')).startOf('days').valueOf();
          var endOfDay = (0, _moment.default)(_this3.get('timeRange.firstObject')).endOf('days').valueOf();
          return startAt == startOfDay && endAt == endOfDay;
        });
      }
    }),
    formatEvents: function formatEvents(events, calendarEvents) {
      if (events.get('length')) {
        events.forEach(function (calendar) {
          if ((0, _moment.default)(calendar.get('from')).endOf('day').isBefore(calendar.get('to'))) {
            calendar.set('to', (0, _moment.default)(calendar.get('from')).endOf('day'));
          }

          calendarEvents.pushObject(calendar);
        });
      }
    },
    selectedCandidateObserver: Ember.observer('candidateRecord', function () {
      var _this4 = this;

      if (this.get('candidateRecord')) {
        this.set('isLoading', true);
        this.set('candidateRecord.calendarEvents', Ember.A([]));
        return this.get('candidateRecord.calEvents').then(function (canCal) {
          return _this4.formatEvents(canCal, _this4.get('candidateRecord.calendarEvents'));
        }).catch(function (e) {
          if (e.errors.length) {
            e.errors.forEach(function (error) {
              _this4.get('toast').error(error.detail);
            });
          } else {
            _this4.get('toast').error('Please try some time later', 'Something went wrong');
          }
        }).finally(function () {
          _this4.set('isLoading', false);
        });
      }
    }),
    actions: {
      addPanel: function addPanel(user) {
        this.sendAction('addPanel', user);
      },
      toggleModal: function toggleModal(property1, property2) {
        this.toggleProperty(property1);

        if (property2) {
          this.sendAction('toggleModal', property2);
        }
      },
      nextDay: function nextDay() {
        var selectedDate = (0, _moment.default)(this.get('liveSession.from')).add(1, 'days');
        this.set('liveSession.from', selectedDate._d);
      },
      previousDay: function previousDay() {
        var selectedDate = (0, _moment.default)(this.get('liveSession.from')).subtract(1, 'days');

        if (!selectedDate.isBefore((0, _moment.default)(this.get('minimumDate')), 'day')) {
          this.set('liveSession.from', selectedDate._d);
        }
      },
      today: function today() {
        this.set('liveSession.from', this.get('currentDate'));
      },
      remove: function remove(user, index) {
        this.sendAction('removePanel', user, index);
      },
      save: function save() {
        this.sendAction('toggleModal', 'openModal');
      }
    }
  });

  _exports.default = _default;
});