define("recruit/data/section-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 3,
    type: "section-type",
    attributes: {
      "name": "Form",
      "isInvite": false,
      "label": "Form"
    }
  }, {
    id: 6,
    type: "section-type",
    attributes: {
      "name": "Objective",
      "title": "Objective Test",
      "isInvite": true,
      "label": "MCQ",
      "icon": "list",
      "component": "mcq",
      "description": "Multiple choice questions with single correct answer.",
      "answerComponent": "answer-views/objective",
      "backendProperty": 'isObjective'
    }
  }, {
    id: 5,
    type: "section-type",
    attributes: {
      "name": "Interview",
      "title": "Video Interview",
      "isInvite": true,
      "label": "Automated",
      "icon": "user-headset",
      "component": "automated",
      "description": "Questions that take accept a video response.",
      "answerComponent": "answer-views/automated",
      "backendProperty": 'isInterview'
    }
  }, {
    id: 4,
    type: "section-type",
    attributes: {
      "name": "Essay",
      "title": "Essay Test",
      "isInvite": true,
      "label": "Essay",
      "icon": "marker",
      "component": "essay",
      "description": "Long answer type questions.",
      "answerComponent": "answer-views/essay",
      "backendProperty": 'isEssay'
    }
  }, {
    id: 10,
    type: "section-type",
    attributes: {
      "name": "Typing",
      "title": "Typing Test",
      "isInvite": true,
      "label": "Typing",
      "icon": "font",
      "component": "typing",
      "description": "Questions to check the typing speed and accuracy.",
      "answerComponent": "answer-views/typing",
      "backendProperty": 'isTyping'
    }
  }, {
    id: 17,
    type: "section-type",
    attributes: {
      "name": "Code",
      "title": "Coding Test",
      "isInvite": true,
      "label": "Code",
      "icon": "terminal",
      "component": "code",
      "answerComponent": "answer-views/code",
      "backendProperty": 'isCode'
    }
  }, {
    id: 7,
    type: "section-type",
    attributes: {
      "name": "Live",
      "title": "Live Interview",
      "isInvite": false,
      "label": "Live",
      "icon": "video",
      "answerComponent": "answer-views/live",
      "component": 'live',
      "backendProperty": 'isLive'
    }
  }, {
    id: 8,
    type: "section-type",
    attributes: {
      "name": "Post",
      "isInvite": false,
      "label": "Post"
    }
  }, {
    id: 9,
    type: "section-type",
    attributes: {
      "name": "Promo",
      "isInvite": false,
      "label": "Promo"
    }
  }, {
    id: 11,
    type: "section-type",
    attributes: {
      "name": "Instructions",
      "isInvite": false,
      "label": "Instructions"
    }
  }, {
    id: 12,
    type: "section-type",
    attributes: {
      "name": "Custom Page",
      "isInvite": false,
      "label": "Page"
    }
  }, {
    id: 13,
    type: "section-type",
    attributes: {
      "name": "Mixed",
      "isInvite": false,
      "label": "Mixed",
      "icon": "asterisk"
    }
  }, {
    id: 14,
    type: "section-type",
    attributes: {
      "name": "Face to Face",
      "isInvite": false,
      "icon": "smile",
      "label": "Face to Face"
    }
  }, {
    id: 15,
    type: "section-type",
    attributes: {
      "name": "Evaluation",
      "title": "Screening",
      "isInvite": true,
      "icon": "file",
      "label": "Evaluation Only",
      "component": "evaluation-only",
      "answerComponent": "answer-views/evaluation-only",
      "backendProperty": 'isEvaluation'
    }
  }, {
    id: 16,
    type: "section-type",
    attributes: {
      "name": "Walkin",
      "isInvite": false,
      "label": "Walkin"
    }
  }, {
    id: 18,
    type: "section-type",
    attributes: {
      "name": "Integration",
      "isInvite": true,
      "label": "Integration"
    }
  }, {
    id: 19,
    type: "section-type",
    attributes: {
      "name": "Versant",
      "title": "Versant Test",
      "isInvite": true,
      "label": "Versant",
      "answerComponent": "answer-views/pearson",
      "icon": "chart-line",
      "component": "versant",
      "backendProperty": 'isPearsonEnabled'
    }
  }, {
    id: 20,
    type: "section-type",
    attributes: {
      "name": "Vita Powered",
      "isInvite": true,
      "icon": "chart-line",
      "answerComponent": "answer-views/pearson",
      "label": "Versant Vita Powered"
    }
  }, {
    id: 21,
    type: "section-type",
    attributes: {
      "name": "Assessment Partner",
      "title": "External Assessment",
      "isInvite": true,
      "answerComponent": "answer-views/external",
      "label": "External",
      "icon": "external-link",
      "backendProperty": 'isExternalEnabled'
    }
  }, {
    id: 22,
    type: "section-type",
    attributes: {
      "name": "VM",
      "title": "Simulation Test",
      "isInvite": false,
      "label": "Simulation",
      "icon": "desktop",
      "answerComponent": "answer-views/simulation",
      "component": "vm",
      "backendProperty": 'isSimulation'
    }
  }];
  _exports.default = _default;
});