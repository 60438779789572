define("recruit/templates/components/reports/filters/filters-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qmOsoAsZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"span\"],[9,\"class\",\"filterLabels text-black\"],[10,\"data-content\",[26,[[18,\"labelName\"],\": \",[18,\"value\"]]]],[9,\"style\",\"background: #dcdcdc; padding: 5px 10px; border: 1px solid #ccc; font-size: 0.9em; display: inline-block; border-radius: 15px; margin-bottom: 5px; line-height: 15px;\"],[7],[0,\"\\n  \"],[1,[18,\"value\"],false],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"remove icon cursor-pointer padding-no float-right\"],[9,\"style\",\"margin: 0 0 0 5px;\"],[3,\"action\",[[19,0,[]],\"removeFilter\",[20,[\"filterArray\"]],[20,[\"filter\"]]]],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/filters/filters-card.hbs"
    }
  });

  _exports.default = _default;
});