define("recruit/components/filters/assessment/section-filters", ["exports", "npm:lodash", "moment"], function (_exports, _npmLodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    roles: null,
    checked: Ember.computed('appliedFilters.[]', function () {
      if (this.get('appliedFilters.length')) {
        var data = this.get('appliedFilters').findBy('filterValues.asID', this.get('selectedAssessmentSections.id'));

        if (data) {
          return data.get('filter.model') == this.get('filter.model');
        }
      } else {
        return false;
      }
    }),
    filterSubType: Ember.computed('checked', 'subFilters.[]', function () {
      if (this.get('subFilters.length') && this.get('checked')) {
        var data = this.get('subFilters').findBy('section.id', this.get('selectedAssessmentSections.id'));

        if (data) {
          return data.get("".concat(this.get('filter.model')));
        }
      }
    }),
    optionLabel: Ember.computed(function () {
      switch (this.get('filter.model')) {
        case 'status':
          return 'label';

        case 'panel':
          {
            if (this.get('selectedAssessmentSections.section.title') == 'Any') {
              return 'name';
            } else {
              return 'user.name';
            }
          }
      }
    }),
    modelName: Ember.computed('filter.model', function () {
      if (this.get('filter.model') == 'panel') {
        if (this.get('selectedAssessmentSections.section.title') == 'Any') {
          return 'user';
        } else {
          return 'panel';
        }
      } else {
        return this.get('filter.model');
      }
    }),
    modelQuery: Ember.computed('selectedAssessmentSections', function () {
      if (this.get('filter.model') == 'panel') {
        if (this.get('selectedAssessmentSections.section.title') == 'Any') {
          this.set('roles', "Evaluator");
          return null;
        } else {
          return {
            assessment_section_id: this.get('selectedAssessmentSections.id')
          };
        }
      } else if (this.get('filter.model') == 'status') {
        return {
          type: 20
        };
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('selectedFilter', this.get('filter'));
    },
    getFormattedTimeStamp: function getFormattedTimeStamp(date, type, isPast, isStart) {
      date = (0, _moment.default)(date);
      date = isPast ? date.subtract(1, type) : date;
      date = isStart ? date.startOf(type) : date.endOf(type);
      return date.toISOString();
    },
    actions: {
      apply: function apply() {
        var filterSubType = this.get('filterSubType');
        var section = this.get('subFilters').findBy('section.id', this.get('selectedAssessmentSections.id'));

        if (section) {
          if (filterSubType) {
            section.set("".concat(this.get('filter.model')), filterSubType);
          } else {
            this.get('toast').error("".concat(this.get('filter.field'), " cannot be empty"), 'Please select a value.');
          }
        } else {
          if (filterSubType) {
            this.get('subFilters').pushObject(Ember.Object.create(_defineProperty({
              section: this.get('selectedAssessmentSections')
            }, "".concat(this.get('filter.model')), filterSubType)));
          } else {
            this.get('toast').error("".concat(this.get('filter.field'), " cannot be empty"), 'Please select a value.');
          }
        }

        if (filterSubType) {
          this.sendAction('addSectionSubFilter', this.get('filter.model'), filterSubType, this.get('selectedAssessmentSections'), this.get('selectedFilter'));
        }
      },
      setField: function setField(checked) {
        this.set('checked', checked);

        if (!this.get('checked')) {
          var field;

          var index = _npmLodash.default.findIndex(this.get('subFilters'), ['section.id', this.get('selectedAssessmentSections.id')]);

          var appliedIndex = _npmLodash.default.findIndex(this.get('appliedFilters'), ['section', this.get('selectedAssessmentSections.id')]);

          if (index != -1) {
            field = _npmLodash.default.find(this.get('subFilters'), ['section.id', this.get('selectedAssessmentSections.id')]);

            if (field.get("".concat(this.get('filter.model')))) {
              field.set("".concat(this.get('filter.model')), '');
            }
          }

          if (appliedIndex != -1) {
            field = _npmLodash.default.find(this.get('appliedFilters'), ['section', this.get('selectedAssessmentSections.id')]);

            if (field.get("".concat(this.get('filter.model')))) {
              field.set("".concat(this.get('filter.model')), '');
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});