define("recruit/templates/components/reports/filters/report-filter-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ezqySYG",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[1,[25,\"helpers/multiple-dropdown\",null,[[\"searchEnabled\",\"disabledItems\",\"modelName\",\"searchField\",\"renderInPlace\",\"placeHolder\",\"customAction\",\"optionLabel\",\"actionName\",\"roles\"],[true,[20,[\"defaultList\"]],[20,[\"modelName\"]],[20,[\"searchField\"]],[20,[\"renderInPlace\"]],[20,[\"placeHolder\"]],[20,[\"customAction\"]],[20,[\"optionLabel\"]],[20,[\"actionName\"]],[20,[\"roles\"]]]]],false],[0,\"\\n\\n\"],[6,\"div\"],[9,\"style\",\"max-height: 200px; overflow-y:auto; margin: 15px 0;\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"defaultList\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"defaultList\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"style\",\"text-align: left; margin:10px 0;\"],[7],[0,\"\\n        \"],[1,[25,\"helpers/dynamic-checkbox\",null,[[\"selectedOptions\",\"value\",\"actionName\"],[[20,[\"selectedOptions\"]],[19,1,[]],[25,\"action\",[[19,0,[]],\"setFilter\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[19,1,[\"isDelete\"]]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[9,\"class\",\"trash alternate icon float-right text-black cursor-pointer\"],[9,\"style\",\"position: relative; top: 4px;\"],[3,\"action\",[[19,0,[]],\"removeFilter\",[20,[\"defaultList\"]],[19,1,[\"id\"]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"style\",\"padding: 30px 0;\"],[7],[6,\"div\"],[9,\"class\",\"ui active centered inline loader\"],[7],[8],[0,\"Loading..\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[11,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/filters/report-filter-dropdown.hbs"
    }
  });

  _exports.default = _default;
});