define("recruit/templates/components/graphs/stacked-bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k5mUygCJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"high-charts\",null,[[\"mode\",\"chartOptions\",\"theme\"],[[20,[\"mode\"]],[20,[\"chartOptions\"]],[20,[\"theme\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/graphs/stacked-bar-chart.hbs"
    }
  });

  _exports.default = _default;
});