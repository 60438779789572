define("recruit/components/assessment/assessment-section-question", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _sectionChangeObserver: Ember.observer('selectedSectionOption', function () {
      this.set('sectionModel.sectionType', this.get('selectedSectionOption'));
      this.send('setSelectedAS', this.get('selectedSectionOption'), true);
    }),
    moreSections: Ember.computed('availableSections.length', function () {
      if (this.get('availableSections.length') > 4) {
        return _npmLodash.default.filter(this.get('availableSections'), function (obj, index) {
          return index > 4;
        });
      }
    }),
    _pearsonTestObserver: Ember.observer('selectedPearsonTest', function () {
      this.set('sectionModel.external_id', this.get('selectedPearsonTest.type'));
      this.get('sectionModel').save();
    }),
    _easpObserver: Ember.observer('external_assessment_id', function () {
      this.get('sectionModel').save();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('questionTypeList', Ember.A([{
        id: 0,
        type: 'question',
        name: 'Questions',
        title: 'questions'
      }, {
        id: 1,
        type: 'skill',
        name: 'Skills',
        title: 'skills'
      }]));

      if (!this.get('sectionModel.questionType')) {
        this.set('sectionModel.questionType', this.get('questionTypeList.firstObject'));
      }

      this.set('isQuestionAvailable', this.get('sectionModel').checkIFQuestionAvailable(this.get('sectionModel.sectionType.id')));
      this.get('sectionModel').save();
    },
    actions: {
      setSelectedAS: function setSelectedAS(sectionObj, fromDropdown) {
        this.set('dropdownActive', fromDropdown);
        this.set('sectionModel.sectionType', sectionObj);
        this.set('isQuestionAvailable', this.get('sectionModel').checkIFQuestionAvailable(sectionObj.get('id')));
        this.set('sectionModel.title', sectionObj.get('title'));
        this.get('sectionModel').save(); // this.get('assessmentModel').save();
      }
    }
  });

  _exports.default = _default;
});