define("recruit/components/assessment/question/question-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    questionContent: Ember.computed('sectionModel.assessmentQuestions.isFulfilled', function () {
      if (this.get('question.plainText.length') > 110) {
        this.set('showMore', true);
        return this.get('question.plainText').slice(0, 110) + '....';
      } else {
        this.set('showMore', false);
        return this.get('question.plainText');
      }
    }),
    actions: {
      removeQuestion: function removeQuestion(index, question, model) {
        var _this = this;

        model.removeObject(question);
        return question.destroyRecord().then(function () {
          return _this.get('sectionModel').save().then(function () {
            _this.get('toast').success('Question deleted', 'Success');
          });
        });
      },
      toggleText: function toggleText(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});