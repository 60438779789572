define("recruit/data/live-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 2061,
    "type": "column/master",
    "attributes": {
      "label": "Candidate",
      "isShow": true,
      "isDefault": true,
      "tableType": "r-ls"
    }
  }, {
    "id": 2062,
    "type": "column/master",
    "attributes": {
      "label": "Session ID",
      "isShow": true,
      "isDefault": true,
      "tableType": "r-ls"
    }
  }, {
    "id": 2063,
    "type": "column/master",
    "attributes": {
      "label": "Status",
      "width": "100px",
      "isShow": true,
      "isDefault": true,
      "tableType": "r-ls"
    }
  }, {
    "id": 2064,
    "type": "column/master",
    "attributes": {
      "label": "Starts at",
      "isShow": true,
      "isDefault": true,
      "tableType": "r-ls"
    }
  }, {
    "id": 2065,
    "type": "column/master",
    "attributes": {
      "label": "Duration",
      "isShow": true,
      "isDefault": true,
      "tableType": "r-ls"
    }
  }, {
    "id": 2066,
    "type": "column/master",
    "attributes": {
      "label": "Timezone",
      "width": "200px",
      "isShow": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "timezone",
      "classNames": ["table-head"],
      "sortWeight": 100,
      "cellComponent": "helpers/live-session/assessment-helper",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2067,
    "type": "column/master",
    "attributes": {
      "label": "Section Name",
      "width": "200px",
      "isShow": false,
      "sortable": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "assessmentSectionCandidate.section.title",
      "classNames": ["table-head"],
      "sortWeight": 100,
      "cellComponent": "helpers/live-session/assessment-helper",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2068,
    "type": "column/master",
    "attributes": {
      "label": "Assessment Name",
      "width": "200px",
      "isShow": false,
      "sortable": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "assessmentSectionCandidate.assessment",
      "classNames": ["table-head"],
      "sortWeight": 100,
      "cellComponent": "assessment-helpers/assessment-details",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2069,
    "type": "column/master",
    "attributes": {
      "label": "Assessment Start Date",
      "width": "200px",
      "isShow": false,
      "sortable": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "assessmentSectionCandidate.assessment.start_date",
      "classNames": ["table-head"],
      "dateFormat": "DD-MMM-YY | hh:mm A",
      "sortWeight": 100,
      "showTimeAgo": false,
      "cellComponent": "helpers/date-view",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2070,
    "type": "column/master",
    "attributes": {
      "label": "Assessment End Date",
      "width": "200px",
      "isShow": false,
      "sortable": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "assessmentSectionCandidate.assessment.end_date",
      "classNames": ["table-head"],
      "dateFormat": "DD-MMM-YY | hh:mm A",
      "sortWeight": 100,
      "showTimeAgo": false,
      "cellComponent": "helpers/date-view",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2071,
    "type": "column/master",
    "attributes": {
      "label": "Assessment Owner",
      "width": "170px",
      "isShow": false,
      "sortable": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "assessmentSectionCandidate.assessment.owned_by.name",
      "classNames": ["table-head"],
      "sortWeight": 100,
      "cellComponent": "helpers/live-session/assessment-helper",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2072,
    "type": "column/master",
    "attributes": {
      "label": "Created On",
      "width": "170px",
      "isShow": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "created_at",
      "classNames": ["table-head"],
      "dateFormat": "DD-MMM-YY | hh:mm A",
      "sortWeight": 100,
      "showTimeAgo": false,
      "cellComponent": "helpers/date-view",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2073,
    "type": "column/master",
    "attributes": {
      "label": "Ends At",
      "width": "170px",
      "isShow": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "ends_at",
      "classNames": ["table-head"],
      "dateFormat": "DD-MMM-YY | hh:mm A",
      "sortWeight": 100,
      "showTimeAgo": false,
      "cellComponent": "helpers/date-view",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2074,
    "type": "column/master",
    "attributes": {
      "label": "Evaluator",
      "width": "170px",
      "isShow": false,
      "sortable": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "panels",
      "classNames": ["table-head"],
      "sortWeight": 100,
      "cellComponent": "candidate-helpers/evaluator-names",
      "cellClassNames": ["text-center"]
    }
  }, {
    "id": 2075,
    "type": "column/master",
    "attributes": {
      "label": "Scheduled By",
      "width": "170px",
      "isShow": false,
      "sortable": false,
      "isDefault": false,
      "tableType": "r-ls",
      "valuePath": "hostedBy.name",
      "classNames": ["table-head"],
      "sortWeight": 100,
      "cellComponent": "helpers/live-session/assessment-helper",
      "cellClassNames": ["text-center"]
    }
  }];
  _exports.default = _default;
});