define("recruit/components/reports/filters/report-filter", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isFiltersApplied: Ember.computed(function () {
      var flag = false;

      _npmLodash.default.forEach(this.get('appliedFilters'), function (value) {
        if (value.length && !flag) {
          flag = true;
        }
      });

      return flag;
    }),
    showApplyButton: Ember.computed('selectedAssessments.length', 'selectedRecruiters.length', 'selectedPanels.length', 'selectedAdvanceFilters.length', 'isFiltersApplied', function () {
      return this.get('selectedAssessments.length') || this.get('selectedRecruiters.length') || this.get('selectedPanels.length') || this.get('selectedAdvanceFilters.length');
    }),
    initialize: function initialize() {
      this.setProperties({
        'selectedAssessments': Ember.A([]),
        'selectedRecruiters': Ember.A([]),
        'selectedPanels': Ember.A([]),
        'selectedAdvanceFilters': Ember.A([])
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.initialize();

      if (this.get('appliedFilters.recruiter_id.length')) {
        this.get('appliedFilters.recruiter_id').forEach(function (obj) {
          _this.get('selectedRecruiters').pushObject(obj);
        });
      }

      if (this.get('appliedFilters.user_id.length')) {
        this.get('appliedFilters.user_id').forEach(function (obj) {
          if (_npmLodash.default.includes(_this.get('graphTitle'), 'panel')) {
            _this.get('selectedPanels').pushObject(obj);
          } else {
            _this.get('selectedRecruiters').pushObject(obj);
          }
        });
      }

      if (this.get('appliedFilters.panel_id.length')) {
        this.get('appliedFilters.panel_id').forEach(function (obj) {
          _this.get('selectedPanels').pushObject(obj);
        });
      }

      if (this.get('appliedFilters.assessment_id.length')) {
        this.get('appliedFilters.assessment_id').forEach(function (obj) {
          _this.get('selectedAssessments').pushObject(obj);
        });
      }

      if (this.get('appliedFilters.advanceFilters.length')) {
        this.get('appliedFilters.advanceFilters').forEach(function (obj) {
          _this.get('selectedAdvanceFilters').pushObject(obj);
        });
      }
    },
    actions: {
      addDefaultAssessments: function addDefaultAssessments(obj) {
        obj.set('isDelete', true);
        this.get('defaultAssessments').pushObject(obj);
        this.get('selectedAssessments').pushObject(obj);
      },
      addDefaultRecruiters: function addDefaultRecruiters(obj) {
        obj.set('isDelete', true);
        this.get('defaultRecruiters').pushObject(obj);
        this.get('selectedRecruiters').pushObject(obj);
      },
      addDefaultPanels: function addDefaultPanels(obj) {
        obj.set('isDelete', true);
        this.get('defaultPanels').pushObject(obj);
        this.get('selectedPanels').pushObject(obj);
      },
      setRecruiterFilters: function setRecruiterFilters(val, obj) {
        if (val) {
          this.get('selectedRecruiters').pushObject(obj);
        } else {
          this.send('removeFilter', this.get('selectedRecruiters'), obj.get('id'));
        }
      },
      setPanelFilters: function setPanelFilters(val, obj) {
        if (val) {
          this.get('selectedPanels').pushObject(obj);
        } else {
          this.send('removeFilter', this.get('selectedPanels'), obj.get('id'));
        }
      },
      setAssessmentFilters: function setAssessmentFilters(val, obj) {
        if (val) {
          this.get('selectedAssessments').pushObject(obj);
        } else {
          this.send('removeFilter', this.get('selectedAssessments'), obj.get('id'));
        }
      },
      setAdvanceFilters: function setAdvanceFilters(appliedFilter, value) {
        if (appliedFilter) {
          var fieldName = appliedFilter.get('filter.field');
          var fieldArray = this.get('selectedAdvanceFilters').map(function (obj) {
            return obj.get('filter.field');
          });

          if (_npmLodash.default.includes(fieldArray, fieldName)) {
            this.get('selectedAdvanceFilters').removeAt(fieldArray.indexOf(fieldName));
          }

          this.get('selectedAdvanceFilters').pushObject(appliedFilter);
        } else {
          this.send('removeFilter', this.get('selectedAdvanceFilters'), value.get('id'));
        }
      },
      removeFilter: function removeFilter(arr, id) {
        var ids = arr.map(function (a) {
          return a.get('id');
        });

        if (_npmLodash.default.includes(ids, id)) {
          arr.removeAt(ids.indexOf(id));
        }
      },
      resetFilters: function resetFilters() {
        this.initialize();
      },
      applyFilters: function applyFilters(assessment, recruiter, panel, advanceFilters) {
        this.sendAction('applyAllFilters', assessment, recruiter, panel, advanceFilters);
      },
      closeModal: function closeModal() {
        this.sendAction('closeFilter', 'openFilter');
      }
    }
  });

  _exports.default = _default;
});