define("recruit/components/bulk-file-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader", "recruit/config/environment", "npm:lodash"], function (_exports, _fileField, _uploader, _environment, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('target')) {
        Ember.$(this.get('target')).on('click', function () {
          _this.$().click();
        });
      }
    },
    filesDidChange: function filesDidChange(files) {
      if (this.get('supportedExtensions')) {
        var extension = files[0].name.split('.').pop();

        if (!_npmLodash.default.includes(this.get('supportedExtensions').split(' '), extension)) {
          this.get('toast').error('Upload "csv" or "xls" file.', 'File type error!');
        } else {
          this.uploadFile(files);
        }
      } else {
        this.uploadFile(files);
      }
    },
    uploadFile: function uploadFile(files) {
      var _this2 = this;

      this.set('documentName', files[0].name);
      Ember.$('.bulk-file-upload-text').val(files[0].name);

      var uploader = _uploader.default.create({
        url: _environment.default.APP.API_ROOT + this.get('modelName'),
        ajaxSettings: {
          headers: {
            Authorization: "Bearer ".concat(this.get('session.session.authenticated.access_token'))
          }
        }
      }); // this.set('saveDisabled', true);


      this.set('file', files[0]);
      this.set('inProgress', true);
      uploader.upload(files[0], {
        file_type_id: this.get('file_type_id')
      });
      uploader.on('didError', function () {
        _this2.set('inProgress', false);

        _this2.get('toast').error('Something went wrong', 'Please try after some time.');
      });
      uploader.on('progress', function (e) {
        _this2.set('fileUploadProgress', e.percent);
      });
      uploader.on('didUpload', function (uploadedFile) {
        _this2.set('uploadedFile', uploadedFile.data);

        _this2.set('saveDisabled', false);

        if (_this2.get('postUpload')) {
          _this2.sendAction('postUpload', uploadedFile.data);
        } else {
          _this2.set('fileUploding', false);

          _this2.get('toast').success('File uploaded', 'Successful');
        }
      }); // Reset selected file

      this.$().val(null);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('target')) {
        Ember.$(this.get('target')).off('click');
      }
    }
  });

  _exports.default = _default;
});