define("recruit/templates/components/live-schedule/calendar-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZvmV9aDO",
    "block": "{\"symbols\":[\"event\",\"&default\"],\"statements\":[[4,\"if\",[[20,[\"eventSet\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"eventSet\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"data-content\",[26,[[19,1,[\"eventContent\",\"event\"]],\", \",[19,1,[\"eventRange\"]]]]],[9,\"data-position\",\"left center\"],[9,\"class\",\"text-weight-medium live-schedule-calendar-card padding-no-top padding-sm-left\"],[10,\"style\",[19,1,[\"cardStyle\"]],null],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[7],[1,[19,1,[\"eventContent\",\"event\"]],false],[0,\", \"],[1,[19,1,[\"eventRange\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-schedule/calendar-card.hbs"
    }
  });

  _exports.default = _default;
});