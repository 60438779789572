define("recruit/components/recruiter-view/candidate/assessment-details-card", ["exports", "recruit/mixins/g-actions"], function (_exports, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    actions: {
      toggleModal: function toggleModal(modalName) {
        this.sendAction('toggleModal', modalName);
      }
    }
  });

  _exports.default = _default;
});