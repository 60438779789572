define("recruit/components/graphs/stacked-bar-chart", ["exports", "ember-highcharts/components/high-charts"], function (_exports, _highCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _highCharts.default.extend({
    contentDidChange: Ember.observer('chartOptions', function () {
      // add redraw logic here. ex:
      var chart = this.get('chart'); // var seriesName = this.get('content')[0].name;

      chart.series[0].update({
        name: 'seriesName',
        data: this.get('selectedRow')
      }, false); // chart.setTitle(null, { text: seriesName }, false);

      chart.redraw();
    })
  });

  _exports.default = _default;
});