define("recruit/components/recruiter-view/assessment/helpers/view-form-link", ["exports", "recruit/components/recruiter-view/assessment/helpers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    panelEvaluation: Ember.computed('row', 'value.@each.evaluations.isFulfilled', 'pc', 'selectedEvaluation', function () {
      var selectedEvaluation = this.get('selectedEvaluation');

      if (selectedEvaluation) {
        return selectedEvaluation;
      }
    }),
    actions: {
      toggleModal: function toggleModal(evaluation) {
        var _this = this;

        if (evaluation) {
          evaluation.get('formInstance').then(function (formData) {
            _this.setProperties({
              selectedEvaluation: evaluation,
              selectedFormData: formData.get('formData')
            });

            _this.toggleProperty('openModal');
          });
        } else {
          this.toggleProperty('openModal');
        }
      }
    }
  });

  _exports.default = _default;
});