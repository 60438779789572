define("recruit/templates/recruiter/live-session/videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zKkmxddy",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"page-title\",[[20,[\"model\",\"name\"]]],null],false],[0,\"\\n\"],[1,[25,\"live-session/videos\",null,[[\"model\",\"isPanel\"],[[20,[\"model\"]],false]]],false],[0,\"\\n\"],[1,[18,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/live-session/videos.hbs"
    }
  });

  _exports.default = _default;
});