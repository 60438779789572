define("recruit/templates/components/recruiter-view/assessment/helpers/date-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lLIxRpoO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"date\"]],[20,[\"value\",\"isFulfilled\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[25,\"helpers/date-view\",null,[[\"value\"],[[20,[\"date\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  --\\n\"]],\"parameters\":[]}],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/helpers/date-view.hbs"
    }
  });

  _exports.default = _default;
});