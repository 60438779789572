define("recruit/templates/components/reports/helpers/table-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LREzi579",
    "block": "{\"symbols\":[\"column\",\"&default\"],\"statements\":[[4,\"unless\",[[20,[\"showFooter\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"reports-table-placeholder\",null,[[\"columns\",\"customColor\"],[[20,[\"columns\"]],\"bg-primary\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"style\",[26,[\"width: \",[20,[\"columns\",\"firstObject\",\"width\"]],\"; background-color:  #ECF1F5; padding: 10px; border-top: 2px solid #C5DAEB; display: inline; float: left;\"]]],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"text-center text-weight-medium text-uppercase text-black\"],[7],[0,\"overall\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"columns\",\"lastObject\",\"subColumns\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"style\",[26,[\"width: \",[19,1,[\"width\"]],\"; background-color:  #ECF1F5; border-top: 2px solid #C5DAEB; display: inline; padding: 10px; float: left;\"]]],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"text-center text-weight-medium text-uppercase text-black\"],[7],[1,[20,[\"model\",\"firstObject\",\"conversionRate\"]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/reports/helpers/table-footer.hbs"
    }
  });

  _exports.default = _default;
});