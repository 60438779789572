define("recruit/serializers/reports/base", ["exports", "recruit/serializers/application", "npm:lodash"], function (_exports, _application, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      var counts = Ember.A(['id', 'invitedCount', 'inprogressCount', 'completedCount', 'evaluatedCount', 'selectedCount', 'rejectedCount', 'holdCount', 'suspendedCount', 'inviteCount', 'createCandidateCount', 'changeStatusCount', 'remindPanelCount', 'remindCandidateCount', 'extendEndDateCount', 'cancelInviteCount', 'addPanelCount', 'liveScheduleCount', 'liveCancelCount', 'liveRescheduleCount', 'liveChangePanelCount', 'liveReminderCount', 'reInviteCount', 'createAssessmentCount', 'simScheduleCount', 'simCancelCount', 'simRescheduleCount', 'simReminderCount', 'evaluationCount', 'sectionWorkflowCount', 'panelHoldCount', 'panelRejectCount', 'panelSelectCount', 'panelAssignedCount', 'tat', //Assessment & Recruiter time properties
      'inviteTime', 'completeTime', 'assignTime', 'evaluationTime', 'shortlistTime']);

      _npmLodash.default.forEach(counts, function (type) {
        if (!hash[type]) {
          hash[type] = 0;
        } // else {
        //   hash[type] = parseInt(hash[type], 10);
        // }

      });

      return this._super(typeClass, hash);
    }
  });

  _exports.default = _default;
});