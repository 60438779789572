define("recruit/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    common: {
      candidates: 'Candidates',
      done: 'Done',
      save: 'Save',
      cancel: 'Cancel',
      login: 'Login',
      logout: 'Logout',
      settings: 'Settings',
      help: 'Help',
      search: 'Search',
      update: 'Update'
    },
    nav: {
      raise_ticket: 'Raise a ticket',
      'change-role': 'Change role'
    },
    settings: {
      account: 'Account',
      password: 'Password',
      notifications: 'Notifications',
      security: 'Security',
      change_profile_picture: 'Change Profile Picture'
    }
  };
  _exports.default = _default;
});