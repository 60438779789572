define("recruit/components/panel/live-evaluation-strip", ["exports", "recruit/components/panel/evaluation-strip"], function (_exports, _evaluationStrip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _evaluationStrip.default.extend({
    currentUser: Ember.inject.service('currentUser'),
    showTextIcon: Ember.computed(function () {
      return !this.get('currentUser.me.organization.isIconEvaluation');
    })
  });

  _exports.default = _default;
});