define("recruit/mixins/table-static", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      var table = new _emberLightTable.default(this.get('columns'), this.get('model'), {
        enableSync: this.get('enableSync')
      });
      this.set('table', table);
    }
  });

  _exports.default = _default;
});