define("recruit/components/bulk-file-import", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    inProgress: false,
    didInsertElement: function didInsertElement() {
      this.initializeValues();
    },
    initializeValues: function initializeValues() {
      this.setProperties({
        feErrors: Ember.A([]),
        bcErrors: Ember.A([]),
        isSuccess: false,
        uploadFail: false,
        totalRecords: 0,
        uploadedRecords: Ember.A([]),
        rejectedRecords: 0,
        count: 1,
        inProgress: false
      });
    },
    successFullRecords: Ember.computed('uploadedRecords.[]', function () {
      return this.get('uploadedRecords').sortBy('count');
    }),
    sortedBcErrors: Ember.computed('bcErrors.[]', function () {
      return this.get('bcErrors').sortBy('rowCount');
    }),
    sortedFeErrors: Ember.computed('feErrors.[]', function () {
      return this.get('feErrors').sortBy('rowCount');
    }),
    actions: {
      postUpload: function postUpload(candidates) {
        var _this = this;

        candidates = candidates.length ? _npmLodash.default.forEach(candidates, function (candidate, index) {
          return candidate.count = index + 1;
        }) : [];
        candidates = _npmLodash.default.remove(candidates, null);
        this.set('totalRecords', candidates.length);

        if (candidates.length) {
          if (this.get('importAndInvite')) {
            this.set('uploadFail', false);
            this.set('isSuccess', true);
            this.set('successFullRecords', candidates);
            this.sendAction('inviteCandidates', candidates);
          } else {
            _npmLodash.default.chunk(candidates, Math.ceil(candidates.length / 8)).forEach(function (candidateChunks) {
              return candidateChunks.reduce(function (promise, candidate) {
                return promise.then(function () {
                  return _this.get('store').createRecord('candidate').parse(candidate).validate().then(function (changeset) {
                    if (changeset.get('isValid')) {
                      return changeset.save().then(function (savedCandidate) {
                        if (!_this.get('uploadFail')) {
                          _this.set('isSuccess', true);
                        }

                        savedCandidate.set('count', candidate.count);
                        return _this.get('uploadedRecords').pushObject(savedCandidate);
                      }).catch(function (e) {
                        _this.incrementProperty('rejectedRecords');

                        _this.get('bcErrors').pushObject({
                          error: e.errors,
                          rowCount: candidate.count
                        });

                        _this.set('uploadFail', true);

                        return Ember.RSVP.Promise.resolve();
                      });
                    } else {
                      _this.incrementProperty('rejectedRecords');

                      _this.get('feErrors').pushObject({
                        error: changeset.get('errors'),
                        rowCount: candidate.count
                      });

                      _this.set('uploadFail', true);

                      return Ember.RSVP.Promise.resolve();
                    }
                  });
                });
              }, Ember.RSVP.resolve().then(function () {
                return Ember.RSVP.resolve();
              }));
            });
          }
        } else {
          this.set('inProgress', false);
          this.get('toast').error('Either file is empty or invalid', 'Error', {
            timeOut: 5000
          });
        }
      },
      importAgain: function importAgain() {
        this.initializeValues();
      },
      back: function back() {
        this.set('inProgress', false);
        return history.back();
      }
    }
  });

  _exports.default = _default;
});