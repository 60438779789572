define("recruit/data/assessment-invite-candidate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "id": 2,
    "type": "column/master",
    "attributes": {
      "cellClassNames": ["fixed-column padding-no"],
      "cellComponent": "helpers/table-profile-picture",
      "classNames": ["ember-table-head fixed-column"],
      "sortWeight": 2,
      "sortable": false,
      'isColumnSelected': true,
      "valuePath": "candidate",
      "width": "60px",
      "isDefault": true,
      "tableType": "a-i-c",
      "fixed": true
    }
  }, {
    "id": 3,
    "type": "column/master",
    "attributes": {
      "cellClassNames": ["fixed-column padding-no"],
      "cellComponent": "candidate-helpers/candidate-details",
      "classNames": ["text-grey ember-table-head text-left fixed-column"],
      "sortWeight": 3,
      'isColumnSelected': true,
      "label": "Name",
      "valuePath": "candidate",
      "sortable": false,
      "width": "190px",
      "isDefault": true,
      "fixed": true,
      "tableType": "a-i-c"
    }
  }, {
    "id": 4,
    "type": "column/master",
    "attributes": {
      "cellClassNames": ["text-center asst-candidate-status"],
      "cellComponent": "candidate-helpers/status",
      "classNames": ["text-grey cursor-auto ember-table-head text-center"],
      "sortWeight": 4,
      'isColumnSelected': true,
      "label": "Invite Status",
      "sortable": false,
      "valuePath": "status",
      "width": "150px",
      "isDefault": true,
      "tableType": "a-i-c"
    }
  }, {
    "id": 5,
    "type": "column/master",
    "attributes": {
      "cellClassNames": ["padding-no resumeColumn ignoreRowClick asst-candidate-status"],
      "cellComponent": "table-resume-uploader",
      "classNames": ["text-grey cursor-auto ember-table-head text-center"],
      "sortWeight": 40,
      "label": "Resume",
      'isColumnSelected': true,
      "sortable": false,
      "valuePath": "candidate",
      "width": "160px",
      "isDefault": true,
      "tableType": "a-i-c"
    }
  }];
  _exports.default = _default;
});