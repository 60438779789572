define("recruit/models/reports/assessment-time", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    "external_id": _emberData.default.attr(),
    "title": _emberData.default.attr('string'),
    "status": _emberData.default.attr('number'),
    "owned_by": _emberData.default.attr('string'),
    "inviteTime": _emberData.default.attr('number'),
    "completeTime": _emberData.default.attr('number'),
    "assignTime": _emberData.default.attr('number'),
    "evaluationTime": _emberData.default.attr('number'),
    "shortlistTime": _emberData.default.attr('number'),
    timeToProcess: Ember.computed('completeTime', 'assignTime', 'inviteTime', 'evaluationTime', 'shortlistTime', function () {
      var maxValue = Math.max(this.get('completeTime'), this.get('assignTime'));
      return this.get('inviteTime') + maxValue + this.get('evaluationTime') + this.get('shortlistTime');
    })
  });

  _exports.default = _default;
});