define("recruit/components/panel/evaluate/candidate-card-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      switchCandidate: function switchCandidate(panelCandidate) {
        this.sendAction('switchCandidate', panelCandidate);
      }
    }
  });

  _exports.default = _default;
});