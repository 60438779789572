define("recruit/components/model-table/recruiter/event-row-component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    performance: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var rows = this.get('table.rows');

      if (rows.get('length') <= 25 && rows.get('lastObject.id') === this.get('row.id') && window.performance) {
        this.get('performance').end('Recruiter:Events:list');
      }
    }
  });

  _exports.default = _default;
});