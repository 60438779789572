define("recruit/data/assc-advance-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 612,
    type: "filter/master",
    attributes: {
      "type": 'range',
      "field": "Score",
      "objectType": "assc-filters",
      "isRemote": false,
      "addColumn": true,
      "queryTemplate": 'score: [{{start}} TO {{end}}]'
    }
  }, {
    id: 613,
    type: "filter/master",
    attributes: {
      "type": 'range',
      "field": "Cutoff",
      "objectType": "assc-filters",
      "isRemote": false,
      "addColumn": true,
      "queryTemplate": 'assessmentSection.cut_off: [{{start}} TO {{end}}]'
    }
  }, {
    id: 614,
    type: "filter/master",
    attributes: {
      "type": 'list',
      "field": "Invite Status",
      "objectType": "assc-filters",
      "isRemote": false,
      "addColumn": true,
      'list': [Ember.Object.create({
        sort_key: 70,
        id: 6,
        label: 'Selected',
        type: 10,
        key: "Selected"
      }), Ember.Object.create({
        sort_key: 90,
        id: 8,
        label: 'Rejected',
        type: 10,
        key: "Rejected"
      }), Ember.Object.create({
        sort_key: 80,
        id: 7,
        label: 'Hold',
        type: 10,
        key: "Hold"
      }), Ember.Object.create({
        sort_key: 120,
        id: 9,
        label: 'Archive',
        type: 10,
        key: "Archive"
      }), Ember.Object.create({
        sort_key: 100,
        id: 10,
        label: 'Suspend',
        type: 10,
        key: "Suspend"
      }), Ember.Object.create({
        sort_key: 20,
        id: 2,
        label: 'Invited',
        type: 10,
        key: "Invited"
      }), Ember.Object.create({
        sort_key: 30,
        id: 3,
        label: 'In Progress',
        type: 10,
        key: "In Progress"
      }), Ember.Object.create({
        sort_key: 50,
        id: 4,
        label: 'Completed',
        type: 10,
        key: "Completed"
      })],
      "queryTemplate": 'assessmentCandidate.status_id: {{value}}'
    }
  }, {
    id: 615,
    type: "filter/master",
    attributes: {
      "type": "date-list",
      "field": "Invitation Date",
      "isRemote": false,
      "addColumn": true,
      "objectType": "assc-filters",
      "queryTemplate": "invited_at: [{{start}} TO {{end}}]",
      'list': ['is', 'is equal to', 'is before', 'is after', 'is between']
    }
  }, {
    id: 616,
    type: "filter/master",
    attributes: {
      "type": 'checkbox',
      "field": "Proview Section Index",
      "objectType": "assc-filters",
      "isRemote": false,
      "addColumn": true,
      'list': [Ember.Object.create({
        id: 1,
        key: 'High',
        value: false
      }), Ember.Object.create({
        id: 2,
        key: 'Medium',
        value: false
      }), Ember.Object.create({
        id: 3,
        key: 'Low',
        value: false
      })],
      "queryTemplate": 'meta.proview-rating: {{value}}'
    }
  }, {
    id: 617,
    type: "filter/master",
    attributes: {
      "type": 'date-list',
      "field": "Evaluation Date",
      "objectType": "assc-filters",
      "isRemote": false,
      "addColumn": true,
      'list': ['is', 'is equal to', 'is before', 'is after', 'is between'],
      "queryTemplate": 'latestEvaluation.evaluated_at: [{{start}} TO {{end}}]'
    }
  }];
  _exports.default = _default;
});