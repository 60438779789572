define("recruit/templates/components/candidate-helpers/bulk-import/success-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u7HvZhkR",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"content text-size-twelve\"],[7],[0,\"\\n    \"],[1,[25,\"get-value\",[[20,[\"candidate\",\"count\"]]],null],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"text-size-twelve\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"candidate\",\"fullName\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,[\"candidate\",\"fullName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    --\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"text-size-twelve\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"candidate\",\"email\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,[\"candidate\",\"email\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    --\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[4,\"if\",[[25,\"not\",[[20,[\"isEventsModal\"]]],null]],null,{\"statements\":[[0,\"  \"],[6,\"td\"],[9,\"class\",\"text-size-eleven\"],[7],[0,\"\\n    \"],[1,[25,\"candidate-helpers/resume-uploader\",null,[[\"isBulkImporModule\",\"candidate\",\"resumeColumn\",\"toggleModal\"],[true,[20,[\"candidate\"]],true,[25,\"action\",[[19,0,[]],\"toggleModal\"],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"modal-view\",null,[[\"isFullScreen\",\"openModal\",\"closable\",\"modalSize\"],[false,[20,[\"openModal\"]],false,\"medium\"]],{\"statements\":[[4,\"if\",[[20,[\"openModal\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"live-session/helpers/live-modal-viewer\",null,[[\"displayTitle\",\"candidate\",\"file\",\"toggleModal\"],[[20,[\"displayTitle\"]],[20,[\"candidate\"]],[20,[\"candidate\",\"resume\",\"firstObject\"]],[25,\"action\",[[19,0,[]],\"toggleModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/candidate-helpers/bulk-import/success-row.hbs"
    }
  });

  _exports.default = _default;
});