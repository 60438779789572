define("recruit/components/assessment/question/assessment-question", ["exports", "npm:lodash", "ember-cli-uuid"], function (_exports, _npmLodash, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    pushQuestions: Ember.observer('selectedQuestion', function () {
      var _this = this;

      if (this.get('selectedQuestion.id')) {
        if (parseInt(this.get('sectionModel.sectionType.id'), 10) == 10 && this.get('sectionModel.assessmentQuestions.length') >= 1) {
          this.set('selectedQuestion', null);
          return this.toggleProperty('showTypingError');
        }

        if (!this.checkIfAdded(this.get('selectedQuestion.id'))) {
          this.get('store').createRecord('lf-adapter/assessment-question', {
            id: (0, _emberCliUuid.uuid)(),
            title: this.get('selectedQuestion.title'),
            content: this.get('selectedQuestion.contentRaw'),
            question_id: this.get('selectedQuestion.id'),
            questionType: 1,
            plainText: this.get('selectedQuestion.plainTxtContent'),
            qCount: 1 // question: this.get('selectedQuestion'),

          }).save().then(function (res) {
            _this.get('sectionModel.assessmentQuestions').pushObject(res);

            _this.get('sectionModel').save().then(function () {
              _this.set('selectedQuestion', null);

              _this.get('toast').success('Question Added', '', {
                positionClass: 'assessment-toast-error'
              });
            });
          });
        } else {
          this.set('selectedQuestion', null);
          this.get('toast').warning('Question has already been added', '', {
            positionClass: 'assessment-toast-error'
          });
        }
      }
    }),
    _totalQuestionsAddedObserver: Ember.observer('sectionModel.assessmentQuestions.@each', function () {
      this.getTotalQuestions();
    }),
    didInsertElement: function didInsertElement() {
      this.getTotalQuestions();
    },
    getTotalQuestions: function getTotalQuestions() {
      var _this2 = this;

      this.get('sectionModel.assessmentQuestions').then(function (questions) {
        if (questions.get('length')) {
          var qCount = questions.map(function (question) {
            return +question.get('qCount');
          });

          _this2.set('totalQuestionsAdded', _npmLodash.default.sum(qCount));
        }
      });
    },
    didRender: function didRender() {
      Ember.$('.sectionQuestion').popup({
        position: 'bottom center',
        inline: false
      });
      Ember.run.later('afterRender', function () {});
    },
    checkIfAdded: function checkIfAdded(id) {
      var addedQuestionIDs = this.get('sectionModel.assessmentQuestions').map(function (question) {
        return question.get('question_id');
      });
      return _npmLodash.default.includes(addedQuestionIDs, id);
    },
    actions: {
      reorderItems: function reorderItems(itemModels) {
        this.set('sectionModel.assessmentQuestions', itemModels);
        this.get('sectionModel').save();
      },
      removeQuestion: function removeQuestion(index, question) {
        var _this3 = this;

        if (this.get('questions.length') < 2) {
          return this.get('toast').error('You need to add atleast 1 question to this assessment', '', {
            positionClass: 'assessment-toast-error'
          });
        }

        question.destroyRecord().then(function () {
          _this3.get('toast').success('Question deleted', '', {
            positionClass: 'assessment-toast-error'
          });
        }).catch(function () {
          _this3.get('toast').error("Error!", "Something went wrong.", {
            positionClass: 'assessment-toast-error'
          });
        });
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});