define("recruit/models/candidate", ["exports", "ember-data", "npm:lodash", "recruit/mixins/profile-pic-initials", "recruit/validations/candidate", "ember-changeset", "ember-changeset-validations", "moment"], function (_exports, _emberData, _npmLodash, _profilePicInitials, _candidate, _emberChangeset, _emberChangesetValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_profilePicInitials.default, {
    email: _emberData.default.attr('string'),
    first_name: _emberData.default.attr('string'),
    middle_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    external_id: _emberData.default.attr('string'),
    last_activity: _emberData.default.attr('string'),
    profile_pic: _emberData.default.attr('string'),
    bio: _emberData.default.attr('string'),
    social: _emberData.default.attr(),
    // was array
    industry: _emberData.default.attr('string'),
    currentLocation: _emberData.default.attr('string'),
    currentCompany: _emberData.default.attr('string'),
    customFields: _emberData.default.attr(),
    gender: _emberData.default.attr('string'),
    education: _emberData.default.attr(),
    // was array
    experience: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    salary: _emberData.default.attr('string'),
    cf: _emberData.default.attr(),
    authToken: _emberData.default.attr(),
    assigned_to: _emberData.default.belongsTo('user'),
    status: _emberData.default.belongsTo('status'),
    image: _emberData.default.belongsTo('candidate-profile-pic'),
    resume: _emberData.default.hasMany('file'),
    assessmentCandidates: _emberData.default.hasMany('assessmentCandidate', {
      inverse: null
    }),
    candidateFileAttachments: _emberData.default.hasMany('candidate-file'),
    calendar: _emberData.default.hasMany('user-calendar', {
      inverse: null
    }),
    // Added for bulk import functionality: to persist row count
    count: _emberData.default.attr('number'),
    allAssessmentCandidates: Ember.computed(function () {
      var _this = this;

      return this.get('assessmentCandidates').then(function (assessmentCandidates) {
        return _this.set('allAssessmentCandidates', assessmentCandidates);
      });
    }),
    isRelationsLoaded: true,
    // for table loading
    fullName: Ember.computed('first_name', 'last_name', function () {
      var c = '';

      if (this.get('last_name') && this.get('first_name')) {
        c = "".concat(this.get('first_name'), " ").concat(this.get('last_name'));
      } else if (this.get('first_name')) {
        c = this.get('first_name');
      } else {
        c = '__';
      }

      return _npmLodash.default.chain(c).toLower().startCase().value();
    }),
    identity: Ember.computed('fullName', 'email', function () {
      if (this.get('fullName')) {
        return this.get('fullName');
      } else {
        return this.get('email');
      }
    }),
    nameWithMail: Ember.computed('fullName', 'email', function () {
      if (this.get('fullName')) {
        return "".concat(this.get('fullName'), " (").concat(this.get('email'), ")");
      } else {
        return "".concat(this.get('email'));
      }
    }),
    formattedExternalId: Ember.computed(function () {
      return this.get('external_id') ? this.get('external_id') : '--';
    }),
    profilePicSource: Ember.computed.alias('profile_pic'),
    currentId: Ember.computed.alias('id'),
    //alias for profile-picture-mixin
    formattedPhone: Ember.computed(function () {
      return this.get('phone') ? this.get('phone') : '--';
    }),
    customFieldsSorted: Ember.computed('customFields', function () {
      return this.get('customFields').sortBy('sort_order');
    }),
    modelAttrs: Ember.computed(function () {
      var arr = Ember.A([]);
      this.eachAttribute(function (name) {
        arr.push(name);
      });
      return arr;
    }),
    parse: function parse(row) {
      var _this2 = this;

      _npmLodash.default.forOwn(Object.keys(row), function (name) {
        if (_npmLodash.default.includes(_this2.get('modelAttrs'), name)) {
          var v = _npmLodash.default.trim(_npmLodash.default.get(row, name) || '');

          _this2.set(name, v);
        } else {
          if (!_this2.get('cf')) {
            _this2.set('cf', {});
          }

          if (_npmLodash.default.get(row, name)) {
            _this2.set("cf.".concat(name), _npmLodash.default.get(row, name));
          }
        }
      });

      return this;
    },
    validate: function validate() {
      var changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(_candidate.default), _candidate.default);
      return changeset.validate().then(function () {
        return changeset;
      });
    },
    calEvents: Ember.computed(function () {
      return this.get('store').query('candidate-calendar', {
        candidate_id: this.get('id'),
        startsAt: (0, _moment.default)().unix(),
        endsAt: (0, _moment.default)().add(1, 'months').unix()
      });
    })
  });

  _exports.default = _default;
});