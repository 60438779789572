define("recruit/components/live-session/scheduling/calendar-card", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui popup bottom right transition visible calendar-card'],
    store: Ember.inject.service(),
    actions: {
      saveModel: function saveModel(liveSession, timeZone) {
        var endTime = liveSession.get('sessionEndTime');
        var startTime = liveSession.get('sessionStartTime');

        if ((0, _moment.default)(endTime).isBefore((0, _moment.default)(startTime))) {
          return this.get('toast').error('', "End time can't be before start time");
        } else if ((0, _moment.default)(endTime).hour() - (0, _moment.default)(startTime).hour() > 2) {
          return this.get('toast').error('', "Max time for Live Session is 2 Hours");
        } else {
          this.sendAction('saveModel', liveSession, timeZone);
        }
      },
      revertModel: function revertModel(model) {
        this.sendAction('revertModel', model);
      }
    }
  });

  _exports.default = _default;
});