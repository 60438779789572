define("recruit/components/filters/section-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedAssessment: null,
    assessmentList: Ember.computed(function () {
      return this.get('store').findAll('assessment');
    }),
    assessmentSectionList: Ember.computed('selectedAssessment', function () {
      if (this.get('selectedAssessment')) {
        var assessment = this.get('store').peekRecord('assessment', parseInt(this.get('selectedAssessment'), 10));
        return assessment.get('assessmentSections');
      }
    })
  });

  _exports.default = _default;
});