define("recruit/components/recruiter-view/candidate/evaluations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    panelID: null,
    openModal: false,
    init: function init() {
      this._super.apply(this, arguments);

      var panelID = localStorage.getItem('panelCandidateID');

      if (panelID) {
        this.set('panelID', panelID);
        localStorage.removeItem('panelCandidateID');
      } else {
        this.set('panelID', null);
      }
    },
    didRender: function didRender() {
      var _this = this;

      Ember.$('.ui.accordion').accordion();

      if (this.get('panelID')) {
        Ember.run.next(function () {
          Ember.$("#".concat(_this.get('panelID'))).addClass("active");
        });
      }
    },
    actions: {
      toggleModal: function toggleModal(panelCandidate) {
        this.set('selectedPanelCandidate', panelCandidate);
        this.toggleProperty('openModal');
      }
    }
  });

  _exports.default = _default;
});