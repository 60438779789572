define("recruit/templates/components/recruiter-view/candidate/assessment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eInBjwy3",
    "block": "{\"symbols\":[\"assessmentCandidate\",\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui segments margin-no\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment text-black\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"recruiter.candidate.details.assessments\",[20,[\"model\",\"id\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"text-blue\"],[7],[1,[25,\"fa-icon\",[\"file-alt\"],[[\"class\"],[\"margin-right-ten\"]]],false],[0,\"Assessment(s)\"],[8],[0,\"\\n      \"],[6,\"a\"],[9,\"class\",\"ui basic circular label margin-top-five-negative float-right text-black text-weight-normal text-size-small padding-vs-right\"],[7],[1,[20,[\"assessmentCandidates\",\"length\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui segment margin-no padding-no bg-white\"],[9,\"style\",\"max-height:150px; overflow-y:auto;\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"assessmentCandidates\"]]],null,{\"statements\":[[4,\"link-to\",[\"recruiter.candidate.details.assessment\",[19,1,[\"candidate\",\"id\"]],[19,1,[\"id\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[10,\"class\",[26,[\"padding-vs ellipsis text-blue text-size-small \",[25,\"if\",[[25,\"eq\",[[19,1,[\"assessment\",\"id\"]],[20,[\"selectedAssessmentId\"]]],null],\"grey-background\"],null]]]],[7],[0,\"\\n          \"],[6,\"span\"],[9,\"style\",\"padding-left: 15px!important;\"],[7],[6,\"i\"],[9,\"class\",\"ui caret right icon text-grey\"],[7],[8],[1,[19,1,[\"assessment\",\"title\"]],false],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[11,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/candidate/assessment-list.hbs"
    }
  });

  _exports.default = _default;
});