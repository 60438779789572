define("recruit/models/actions/remind-candidate", ["exports", "ember-data", "recruit/mixins/ac-action"], function (_exports, _emberData, _acAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_acAction.default, {
    assessmentCandidates: _emberData.default.attr(),
    assessmentSections: _emberData.default.attr(),
    assessment: _emberData.default.attr()
  });

  _exports.default = _default;
});