define("recruit/components/assessment/tabs/assessment-instruction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['canAddInstructions'],
    canAddInstructions: Ember.computed('selectedAS.section.isFulfilled', function () {
      return this.get('selectedAS.section.sectionType.id') != 7 && this.get('selectedAS.section.sectionType.id') != 15 && this.get('selectedAS.section.sectionType.id') != 19 && this.get('selectedAS.section.sectionType.id') != 22 && this.get('selectedAS.section.sectionType.id') != 21;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('activeTab', 'pre');
    },
    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.instructions.menu .item').tab();
      });
    },
    actions: {
      toggleTab: function toggleTab(property) {
        this.toggleProperty(property);
      },
      preText: function preText(editor) {
        this.set('selectedAS.intro', editor);
      },
      postText: function postText(editor) {
        this.set('selectedAS.conclusion', editor);
      },
      setActiveTab: function setActiveTab(property) {
        this.set('activeTab', property);
      },
      updateAs: function updateAs(selectedAS) {
        this.sendAction('updateAs', selectedAS);
      },
      setInstructions: function setInstructions(editor) {
        if (this.get('activeTab') == 'pre') {
          return this.set('selectedAS.intro', editor);
        }

        return this.set('selectedAS.conclusion', editor);
      }
    }
  });

  _exports.default = _default;
});