define("recruit/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('dashboard.setup.user'), this.toRoute('dashboard.setup.security'), this.useAndReverse('crossFade'));
    this.transition(this.hasClass('t-security'), this.use('fade'));
    this.transition(this.fromRoute('login'), this.toRoute('dashboard'), this.use('fade'), this.reverse('fade'));
    this.transition(this.fromRoute('loading'), this.use('fade'));
    this.transition(this.fromRoute('login'), this.toRoute(['forgot', 'ask-activation']), this.use('fade'), this.reverse('fade'));
    this.transition(this.fromRoute('panel.candidate.index'), this.toRoute('panel.candidate.evaluate'), this.use('crossFade', {
      duration: 400
    }), this.reverse('crossFade', {
      duration: 400
    }));
    this.transition(this.hasClass('t-current-candidate'), this.use('fade'));
    this.transition(this.hasClass('t-selected-answer'), this.use('crossFade'));
    this.transition(this.childOf('.t-eval-status'), this.use('toRight'));
    this.transition(this.childOf('.t-bulk-question-review'), this.use('toRight', {
      duration: 400
    }));
    this.transition(this.hasClass('t-answer'), this.use('crossFade'));
    this.transition(this.hasClass('t-section-details'), this.use('fade'));
    this.transition(this.hasClass('t-report-filters'), this.useAndReverse('explode', {
      pick: '.t-report-filters',
      use: ['fade', {
        duration: 400
      }]
    }));
    this.transition(this.toRoute(['user.settings.account', 'user.settings.password', 'user.settings.notifications', 'user.settings.security']), this.useAndReverse('explode', {
      pick: '.t-user-settings',
      use: ['toLeft', {
        duration: 400
      }]
    }));
    this.transition(this.fromRoute(['user.settings.account', 'user.settings.password', 'user.settings.notifications', 'user.settings.security']), this.toRoute('user.settings.picture'), this.use('toLeft'), this.reverse('toRight')); // this.transition(
    //   this.outletName('main'),
    //   this.use('fade')
    // );

    this.transition(this.hasClass('swiper'), // this.toValue(true),
    this.useAndReverse('crossFade', {
      duration: 700
    }) // this.reverse('toRight', {duration: 1000}),
    );
    this.transition(this.hasClass('main'), this.use('fade'));
    this.transition(this.hasClass('liquid-accordion'), this.useAndReverse('crossFade'));
  }
});