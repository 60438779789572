define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-sections", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['margin-top-fifteen'],
    isContentLoaded: false,
    allPanels: Ember.A([]),
    funnelObserver: Ember.observer('isContentLoaded', function () {
      if (this.get('isContentLoaded')) {
        var widthArray = this.get('sectionCandidates').map(function (ob) {
          return ob.total;
        });
        this.set('isNotEmpty', this.calcSum(widthArray));
        this.generateFunnelProperties(widthArray);

        if (this.get('sectionCandidates.length')) {
          this.set('selectedAS', this.get('sectionCandidates.firstObject.as'));
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('funnelProperties', Ember.A([]));
      this.set('sectionCandidates', Ember.A([]));
    },
    calcSum: function calcSum(arr) {
      var sum = 0;
      arr.forEach(function (elem) {
        return sum += elem;
      });
      return sum;
    },
    generateFunnelProperties: function generateFunnelProperties(widthArray) {
      var _this = this;

      var maxProperty = _npmLodash.default.max(widthArray);

      widthArray.forEach(function (property, index) {
        var step = _this.calcPercent(property, maxProperty);

        var l1, l2, l3, l4;

        if (index) {
          l1 = _this.get('funnelProperties')[index - 1].l2;
          l4 = _this.get('funnelProperties')[index - 1].l3;
        } else {
          l1 = property ? 0 : 94;
          l4 = property ? 200 : 108;
        }

        if (property == maxProperty && step == 100) {
          l2 = 0;
          l3 = 200;
        } else {
          l2 = 100 - step > 94 ? 94 : 100 - step;
          l3 = 100 + step < 108 ? 108 : 100 + step;
        }

        _this.setFunnelProp(l1, l2, l3, l4);
      });
    },
    setFunnelProp: function setFunnelProp(l1, l2, l3, l4) {
      this.get('funnelProperties').pushObject({
        'l1': l1,
        'l2': l2,
        'l3': l3,
        'l4': l4
      });
    },
    calcPercent: function calcPercent(property, maxProperty) {
      return property / maxProperty * 100;
    },
    actions: {
      contentLoaded: function contentLoaded(value) {
        this.set('isContentLoaded', value);
        this.set('totalCandidates', this.get('sectionCandidates.firstObject.total'));
      },
      selectedAS: function selectedAS(as, totalCandidates) {
        this.set('totalCandidates', totalCandidates);
        this.set('selectedAS', as);
      }
    }
  });

  _exports.default = _default;
});