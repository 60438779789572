define("recruit/helpers/is-valid-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidField = isValidField;
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function isValidField(params
  /*, hash*/
  ) {
    var value = params[0];

    if (_typeof(value) == 'object' && value == null) {
      return '--';
    }

    if (typeof value == 'string' && !value.length) {
      return '--';
    }

    if (typeof value == 'string') {
      return Ember.String.htmlSafe(value.replace(/\n/g, '<br>'));
    }

    if (typeof value == 'boolean' || typeof value == 'number') {
      return value;
    }
  }

  var _default = Ember.Helper.helper(isValidField);

  _exports.default = _default;
});