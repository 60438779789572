define("recruit/components/reports/graphs/time-range/time-per-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    graphObserver: Ember.observer('selectedRow', function () {
      this.renderGraph();
    }),
    didInsertElement: function didInsertElement() {
      this.renderGraph();
    },
    renderGraph: function renderGraph() {
      this.set('chartOptions', {
        chart: {
          type: 'bar',
          height: '300',
          backgroundColor: '#2B2B2B',
          style: {
            color: "#fff"
          }
        },
        title: {
          text: null
        },
        xAxis: {
          categories: ['January', 'February', 'March', 'April', 'May', 'jun', 'july', 'sept', 'oct', 'nov', 'dec'],
          labels: {
            style: {
              color: '#fff'
            }
          },
          style: {
            color: '#fff'
          },
          gridLineColor: '#ccc',
          lineColor: '#fff',
          lineWidth: 2
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          },
          color: '#fff',
          labels: {
            style: {
              color: '#fff'
            }
          },
          lineColor: '#fff',
          lineWidth: 1,
          gridLineColor: '#ccc'
        },
        legend: {
          reversed: true,
          itemStyle: {
            color: '#CDCDCD'
          },
          itemHoverStyle: {
            color: '#fff'
          }
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderRadius: 1,
            color: '#fff',
            pointWidth: 10
          }
        },
        series: [{
          name: 'Invited',
          color: 'green',
          borderColor: 'green',
          height: '100',
          data: this.get('selectedRow')
        }, {
          name: 'Completed',
          color: 'yellow',
          borderColor: 'yellow',
          data: [2, 2, 3, 2, 1]
        }, {
          name: 'Evaluated',
          color: 'blue',
          borderColor: 'blue',
          data: [3, 4, 4, 2, 5]
        }, {
          name: 'Final Selection',
          color: '#4b137b',
          legendColor: 'red',
          borderColor: '#4b137b',
          data: [3, 4, 4, 2, 5]
        }]
      });
    }
  });

  _exports.default = _default;
});