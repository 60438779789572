define("recruit/components/assessment/rating-templates/form-parameters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showErrorMessage: function showErrorMessage(err) {
      var _this = this;

      if (err.errors) {
        err.errors.forEach(function (error) {
          return _this.get('toast').error(error.detail, 'Error!', {
            positionClass: 'assessment-toast-error'
          });
        });
      } else {
        return this.get('toast').error("Something went wrong.", 'Try again.', {
          positionClass: 'assessment-toast-error'
        });
      }
    },
    actions: {
      updateRatings: function updateRatings(section) {
        var _this2 = this;

        if (this.get('disabled')) {
          if (!this.get('toggleNag')) {
            this.toggleProperty('toggleNag');
          }

          return;
        }

        if (!section.get('evaluationForm.id')) {
          this.set('errorProperty', 'form');
          return this.send('toggleModal', 'showAlert');
        }

        section.get('sectionParameters').then(function (sectionParameters) {
          if (!sectionParameters.length) {
            _this2.set('errorProperty', 'parameter');

            return _this2.send('toggleModal', 'showAlert');
          }

          sectionParameters.forEach(function (sectionParameter) {
            if (sectionParameter.get('isNew')) {
              _this2.set('isLoading', true);

              sectionParameter.save().then(function () {
                _this2.send('saveSection', section.get('content') ? section.get('content') : section);
              }).catch(function (error) {
                _this2.showErrorMessage(error);
              }).finally(function () {
                _this2.set('isLoading', false);
              });
            } else {
              _this2.set('isLoading', true);

              _this2.send('saveSection', section.get('content') ? section.get('content') : section);
            }
          });
        });
      },
      saveSection: function saveSection(section) {
        var _this3 = this;

        section.save().then(function () {
          return _this3.get('toast').success('Ratings udpated', 'Success', {
            positionClass: 'assessment-toast-error'
          });
        }).catch(function (error) {
          _this3.showErrorMessage(error);
        }).finally(function () {
          _this3.set('isLoading', false);
        });
      },
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});