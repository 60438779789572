define("recruit/components/live-schedule/form", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isSelectedCandidate: Ember.computed('candidateRecord', 'params', function () {
      return this.get('params.cid') || this.get('candidateRecord');
    }),
    selectedAssessment: Ember.computed('params.aid', function () {
      if (this.get('params.aid') && this.get('params.aid') != 'null') {
        return this.get('store').findRecord('assessment', this.get('params.aid'));
      }
    }),
    // Observer for pre-filling panel who have been already assigned.
    assessmentObserver: Ember.observer('selectedAssessmentSection', function () {
      var _this = this;

      if (this.get('selectedAssessmentSection')) {
        this.set('isPanelLoading', true);
        this.get('store').query('panel', {
          assessment_section_id: this.get('selectedAssessmentSection.id')
        }).then(function (panels) {
          if (panels.get('length')) {
            Ember.RSVP.Promise.all(panels.map(function (p) {
              return p.get('user');
            })).then(function (users) {
              _this.set('isPanelLoading', false);

              _lodash.default.take(users, 5).forEach(function (user) {
                _this.send('addPanel', user);
              });
            });
          } else {
            _this.set('isPanelLoading', false);
          }
        });
      }
    }),
    actions: {
      remove: function remove(user) {
        this.sendAction('removePanel', user);
      },
      changeSelection: function changeSelection(property) {
        if (property == 'candidateRecord') {
          this.setProperties({
            'params.cid': null,
            'params.aid': null,
            'params.acid': null
          });
          this.set('selectedAssessment', null);
        }

        this.set('selectedAssessmentSection', null);
        this.set('selectedPanels', Ember.A([]));

        if (property) {
          this.set(property, null);
        }
      },
      addPanel: function addPanel(user) {
        this.sendAction('addPanel', user);
      },
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      }
    }
  });

  _exports.default = _default;
});