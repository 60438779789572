define("recruit/data/reports-advance-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*REPORTS ADVANCE FILTERS START*/
  var _default = [{
    id: 1,
    type: "filter/master",
    attributes: {
      "field": "Reference Id",
      "isRemote": true,
      "model": "assessment",
      "objectType": "rf",
      "type": 'text',
      "queryTemplate": '({{condition}} external_id: {{exact}}{{value}})'
    }
  }, {
    id: 2,
    type: "filter/master",
    attributes: {
      "field": "Assessment Window",
      "isRemote": true,
      "objectType": "rf",
      "type": 'date-range',
      "queryTemplate": '(end_date: [{{start}} TO {{end}} ])'
    }
  }];
  /*REPORTS ADVANCE FILTERS END*/

  _exports.default = _default;
});