define("recruit/templates/integration/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+X5KfIrK",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ui container\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui middle aligned centered grid\"],[9,\"style\",\"height:100vh;\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"sixteen wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui centered grid margin-no\"],[9,\"style\",\"\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"twelve wide column padding-no bg-white center aligend\"],[7],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"ui very padded segment center aligned\"],[7],[0,\"\\n            \"],[6,\"img\"],[9,\"src\",\"/images/tick_icon.svg\"],[9,\"style\",\"width: 100px\"],[7],[8],[0,\"\\n            \"],[6,\"p\"],[9,\"class\",\"padding-sm\"],[7],[0,\"Candidate invited successfully.\"],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/integration/success.hbs"
    }
  });

  _exports.default = _default;
});