define("recruit/templates/components/recruiter-view/questions/bulk-create/bulk-import-review-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S7yYsdx4",
    "block": "{\"symbols\":[\"question\"],\"statements\":[[4,\"if\",[[25,\"lte\",[[20,[\"currentQuestionIndex\"]],[25,\"dec\",[[20,[\"questionsArray\",\"length\"]]],null]],null]],null,{\"statements\":[[4,\"liquid-bind\",[[20,[\"currentQuestion\"]]],[[\"class\"],[\"t-selected-answer\"]],{\"statements\":[[0,\"\\t\\t\"],[1,[25,\"recruiter-view/questions/bulk-create/bulk-import-review\",null,[[\"question\",\"files\",\"preSelectedSkills\",\"selectedSkills\",\"index\",\"totalArray\",\"saveQuestion\",\"discardQuestion\",\"removeSkill\",\"selectSkills\"],[[19,1,[]],[20,[\"files\"]],[20,[\"preSelectedSkills\"]],[20,[\"selectedSkills\"]],[20,[\"currentQuestionIndex\"]],[20,[\"questionsArray\",\"length\"]],[25,\"action\",[[19,0,[]],\"saveQuestion\"],null],[25,\"action\",[[19,0,[]],\"discardQuestion\"],null],[25,\"action\",[[19,0,[]],\"removeSkill\"],null],[25,\"action\",[[19,0,[]],\"selectSkills\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"recruiter-view/questions/bulk-create/bulk-import-review-success\",null,[[\"documentName\",\"totalArray\"],[[20,[\"documentName\"]],[20,[\"questionImported\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/questions/bulk-create/bulk-import-review-index.hbs"
    }
  });

  _exports.default = _default;
});