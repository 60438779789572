define("recruit/components/events/panel-match-sections", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.setMatchedSections();
    },
    _AssessmentSectionObserver: Ember.observer('assessmentSections.length', function () {
      this.setMatchedSections();
    }),
    setMatchedSections: function setMatchedSections() {
      var _this = this;

      Ember.RSVP.all(this.get('assessmentSections').map(function (assessmentSection) {
        return assessmentSection.get('section').then(function (section) {
          return section.get('title');
        });
      })).then(function (section) {
        _this.set('matchedSections', _npmLodash.default.uniq(section).toString());

        _this.set('sectionsLoaded', true);
      });
    },
    actions: {
      toggleModal: function toggleModal(property) {
        this.toggleProperty(property);
      }
    }
  });

  _exports.default = _default;
});