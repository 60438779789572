define("recruit/components/recruiter-view/actions/assign-panel", ["exports", "recruit/config/environment", "moment", "recruit/mixins/g-actions"], function (_exports, _environment, _moment, _gActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_gActions.default, {
    isDevelopment: _environment.default.isDevelopment,
    store: Ember.inject.service(),
    // selectedPanels: Ember.A([]),
    endDate: (0, _moment.default)().add(10, 'd').toDate(),
    minDate: (0, _moment.default)().toDate(),
    isNotify: true,
    isLoading: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('selectedPanels', Ember.A([]));
      this.get('listValues').then(function (ac) {
        ac.get('firstObject.assessment').then(function () {
          _this.set('assessmentRelationFulfilled', true);
        });
      });
    },
    assessmentSections: Ember.computed('assessmentRelationFulfilled', 'selectedListValues.[]', function () {
      var assessments = this.get('selectedListValues').map(function (c) {
        return c.get('assessment');
      });

      if (assessments.length && assessments.get('firstObject.isFulfilled')) {
        var assessment = assessments[0];
        return assessment.get('evaluateSections');
      }
    }),
    actions: {
      addPanel: function addPanel(user) {
        if (!this.get('selectedPanels').findBy('id', user.get('id'))) {
          this.get('selectedPanels').pushObject(user);
        }
      },
      remove: function remove(user) {
        this.get('selectedPanels').removeObject(user);
      },
      assignPanel: function assignPanel() {
        var _this2 = this;

        this.set('isLoading', true);
        var assessmentCandidates = this.get('selectedListValues').map(function (ac) {
          return ac.get('id');
        });
        var panels = this.get('selectedPanels').map(function (p) {
          return {
            id: p.get('id')
          };
        });
        this.get('store').createRecord('actions/add-panel', {
          assessmentSections: [{
            id: this.get('selectedAssessmentSections.id')
          }],
          assessmentCandidates: assessmentCandidates,
          endDate: (0, _moment.default)(this.get('endDate')).toISOString(),
          isNotify: this.get('isNotify'),
          message: this.get('message'),
          panel: panels,
          sync: true
        }).save().then(function () {
          _this2.get('toast').success("for ".concat(_this2.get('selectedListValues.length'), " Candidates"), 'Panel Added Successfully');

          return _this2.send('back');
        }).catch(function (e) {
          _this2.catchError(e);
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});