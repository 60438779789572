define("recruit/components/calendar-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    calendarDate: Ember.computed('calendarDate', function () {
      return this.get('currentDate') ? this.get('currentDate') : this.get('model.from');
    }),
    popupOptions: {
      position: 'top center'
    },
    actions: {
      toggleModal: function toggleModal(property) {
        this.sendAction('toggleModal', property);
      }
    }
  });

  _exports.default = _default;
});