define("recruit/serializers/reports/recruiter-time", ["exports", "recruit/serializers/reports/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend();

  _exports.default = _default;
});