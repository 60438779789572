define("recruit/components/forms/evaluation-forms", ["exports", "npm:lodash", "recruit/config/environment", "recruit/components/panel/evaluation-strip"], function (_exports, _npmLodash, _environment, _evaluationStrip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _evaluationStrip.default.extend({
    store: Ember.inject.service(),
    userRole: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service('currentUser'),
    showTextIcon: Ember.computed(function () {
      return !this.get('currentUser.me.organization.isIconEvaluation');
    }),
    styleClass: Ember.computed('isCandidateSidebar', function () {
      return this.get('isCandidateSidebar') ? 'ui segment evaluation-header' : 'ui inverted segment';
    }),
    styleProperty: Ember.computed('isCandidateSidebar', function () {
      return this.get('isCandidateSidebar') ? 'border-bottom: 1px solid lightgrey !important;' : '';
    }),
    accessToken: Ember.computed.alias('session.session.content.authenticated.access_token'),
    evaluationFormUrl: Ember.computed('selectedAssc.section.evaluationFormUrl', function () {
      var url = "".concat(this.get('selectedAssc.section.evaluationFormUrl'), "?assc=").concat(this.get('selectedAssc.id'), "&formId=").concat(this.get('selectedAssc.section.evaluationForm.id'), "&accessToken=").concat(this.get('accessToken'));
      url = _environment.default.isDevelopment ? url + '&dev=true' : url;
      return url;
    }),
    parameterGroups: Ember.computed('selectedAssc.as.parameters.[]', function () {
      var parameters = this.get('selectedAssc.as.parameters'),
          groups = [];
      parameters.forEach(function (parameter) {
        parameter.set('currentValue', -1);
        var hasGroup = groups.findBy('category_id', parameter.get('category.id'));

        if (!hasGroup) {
          groups.pushObject(Ember.Object.create({
            category_id: parameter.get('category.id'),
            category: parameter.get('category'),
            parameters: []
          }));
        }

        groups.findBy('category_id', parameter.get('category.id')).get('parameters').pushObject(parameter);
      });
      return groups;
    }),
    ac: Ember.computed('selectedAssc.isFulfilled', function () {
      return this.get('selectedAssc.assessmentCandidate');
    }),
    formLabel: Ember.computed('selectedAssc.section.isFulfilled', function () {
      if (this.get('selectedAssc.section.external_id') == 'HRIOS') {
        return 'HR IOS';
      } else {
        return 'TECH IOS';
      }
    }),
    didInsertElement: function didInsertElement() {
      var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      var eventer = window[eventMethod];
      var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
      this._processForm = this.processForm.bind(this);
      eventer(messageEvent, this._processForm, false);
    },
    willDestroyElement: function willDestroyElement() {
      var eventMethod = window.removeEventListener ? "removeEventListener" : "detachEvent";
      var eventer = window[eventMethod];
      var messageEvent = eventMethod == "detachEvent" ? "onmessage" : "message";
      eventer(messageEvent, this._processForm, false);
    },
    processForm: function processForm(event) {
      var filesUploaded = Ember.A([]); // Message when iframe form gets initialized

      if (event.data.type == 'FormInitialized') {
        document.getElementById('assessmentForm').contentWindow.postMessage({
          type: 'gotAccessToken',
          accessToken: "Bearer ".concat(this.get('session.session.content.authenticated.access_token'))
        }, '*');
      }

      if (event.data.type == 'notification') {
        var err = event.data.error;

        if (err.title && err.message) {
          switch (err.type) {
            case 'success':
              this.get('toast').success(err.message, err.title);
              break;

            case 'error':
              this.get('toast').error(err.message, err.title, {
                timeOut: 500000,
                closeButton: false
              });
              break;
          }
        }
      } // Message when form is submitted


      if (event.data.type == 'formData') {
        event.data.formData.map(function (data) {
          if (data.isFile) {
            return filesUploaded.pushObject({
              id: data.value.id,
              key: data.label
            });
          }
        });

        if (filesUploaded.get('length')) {
          var filteredFormData = _npmLodash.default.remove(event.data.formData, function (data) {
            return data.isFile ? false : data;
          });

          this.send('submitEvaluation', filteredFormData, filesUploaded);
        } else {
          this.send('submitEvaluation', event.data.formData, []);
        }
      }
    },
    actions: {
      submitEvaluation: function submitEvaluation(formData, filesUploaded) {
        var _this = this;

        var previewEvaluations = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
        var ratings = Ember.A();

        if (this.get('selectedAssc.as.parameters.length')) {
          this.get('selectedAssc.as.parameters').forEach(function (p) {
            if (p.get('currentValue') >= 0) {
              ratings.pushObject({
                "parameter_id": p.get('id'),
                "rating": p.get('currentValue')
              });
            }
          });
        }

        var form = this.get('store').createRecord('actions/evaluate', {
          recommendation: this.get('status'),
          evaluationComment: this.get('comments'),
          formData: formData,
          files: filesUploaded,
          rating: ratings,
          assessmentSectionCandidateID: this.get('selectedAssc.id'),
          formInstanceID: previewEvaluations.get('length') ? previewEvaluations.get('firstObject.formInstance.id') : null
        });
        form.save().then(function (response) {
          if (response.get('status') == 99) {
            return _this.get('toast').error(response.get('response'));
          } else {
            _this.set('currentSessionEvaluated', true);

            return response.get('evaluation').then(function (evalResponse) {
              if (_this.get('model.evaluations.length')) {
                _this.get('model.evaluations').clear();

                _this.get('model.evaluations').pushObject(evalResponse);
              }

              _this.get('toast').success('Status: ' + evalResponse.get('evaluationsStatus'), 'Evaluation saved successfully!', {
                timeOut: 5000
              });

              if (_this.get('isLsForm')) {
                if (_this.get('redirect')) {
                  if (_this.get('userRole.isRecruiter')) {
                    _this.get('router').transitionTo('recruiter.live-session.view', _this.get('liveSession'));
                  } else {
                    _this.get('router').transitionTo('panel.live-session.view', _this.get('liveSession'));
                  }
                } else {
                  if (_this.get('isCandidateSidebar')) {
                    return _this.send('toggleEvaluationSidebar', 'toggleEvaluationSidebar');
                  } else {
                    return _this.sendAction('toggleSlider');
                  }
                }
              } else {
                return window.history.back();
              }
            });
          }
        }).catch(function (e) {
          if (e.errors) {
            e.errors.forEach(function (error) {
              _this.get('toast').error(error.detail, 'Error!');
            });
          } else {
            _this.get('toast').error(e, 'Error!');
          }
        });
      },
      evaluateCandidate: function evaluateCandidate(status, previewEvaluations) {
        var isEditable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
        this.set('status', status);

        if (previewEvaluations.get('length') && !isEditable) {
          this.send('submitEvaluation', [], [], previewEvaluations);
          this.get('toast').warning('Form already submitted', 'Error!');
        } else {
          var iFrame = document.getElementById('assessmentForm');
          iFrame.contentWindow.postMessage({
            type: "submitEvaluationForm"
          }, '*');
        }
      },
      toggleSlider: function toggleSlider() {
        this.sendAction('toggleSlider');
      },
      toggleEvaluationSidebar: function toggleEvaluationSidebar(property) {
        this.sendAction('toggleEvaluationSidebar', property);
      }
    }
  });

  _exports.default = _default;
});