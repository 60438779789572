define("recruit/serializers/answer", ["exports", "recruit/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (Ember.typeOf(payload) == 'array') {
        payload.forEach(function (answer, index, answers) {
          if (index + 1 < answers.length) {
            answer.nextAnswerId = answers[index + 1].id;
          } else {
            answer.nextAnswerId = null;
          }

          answer.index = index + 1;
        });
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});