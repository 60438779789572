define("recruit/components/popups/alert-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'modal'],
    classNameBindings: ['isFullScreen:fullscreen', 'modalSize'],
    didInsertElement: function didInsertElement() {
      if (this.get('openModal')) {
        this.$().modal('show');
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var that = this;
      this.$().modal({
        onHide: function onHide() {
          that.toggleProperty('openModal');
        }
      });

      if (this.get('openModal')) {
        this.$().modal('setting', {
          autofocus: false,
          closable: this.get('closable')
        }).modal('show');
      } else {
        this.$().modal({
          closable: false
        }).modal('hide');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$().modal('hide');
    },
    actions: {
      close: function close() {
        if (this.get('customCloseAction')) {
          this.send('positive');
        } else if (this.get('closeAction')) {
          this.sendAction('closeAction');
        } else {
          this.toggleProperty('openModal');
        }
      },
      negative: function negative() {
        if (this.get('negativeAction')) {
          this.sendAction('negativeAction');
        } else {
          this.send('close');
        }
      },
      positive: function positive() {
        if (this.get('positiveAction')) {
          this.sendAction('positiveAction');
        }
      }
    }
  });

  _exports.default = _default;
});