define("recruit/services/performance", ["exports", "recruit/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */
  var _default = Ember.Service.extend({
    metrics: Ember.inject.service(),
    isMarkSupportedBrowser: true,
    isNowSupportedBrowser: true,
    secondaryPerformanceObj: {},
    metricsObj: {},
    store: Ember.inject.service(),
    init: function init() {
      // detecting the browser's Compatibility over window.performance.mark and window.performance.now
      if (!(window.performance && window.performance.mark)) {
        console.warn('Performance.mark is not supported in this browser. Hence falling back to performance.now()');
        this.set('isMarkSupportedBrowser', false);

        if (!(window.performance && window.performance.now)) {
          console.warn('Performance.now is also not supported. Hence falling back to javascript Date API');
          this.set('isNowSupportedBrowser', false);
        }
      }
    },
    start: function start(eventName) {
      if (typeof eventName !== 'string') {
        console.error('Expected type String for invoking `start`');
        return -1;
      }

      var secondaryPerformanceObj = this.get('secondaryPerformanceObj');
      secondaryPerformanceObj[eventName] = secondaryPerformanceObj[eventName] || {};
      var eventObj = secondaryPerformanceObj[eventName];

      if (this.get('isNowSupportedBrowser')) {
        if (this.get('isMarkSupportedBrowser')) {
          performance.mark("tvr:mark_".concat(eventName, "_start"));
        } else {
          eventObj.start = window.performance.now();
        }
      } else {
        eventObj.start = new Date().valueOf();
      }

      return;
    },
    end: function end(eventName) {
      if (typeof eventName !== 'string') {
        console.error('Expected type String for invoking `end`');
        return -1;
      }

      var secondaryPerformanceObj = this.get('secondaryPerformanceObj');
      secondaryPerformanceObj[eventName] = secondaryPerformanceObj[eventName] || {}; // creating a new entry if not already present

      var eventObj = secondaryPerformanceObj[eventName];
      var metricObj = this.get('metricsObj');
      metricObj[eventName] = metricObj[eventName] || []; // creating a new entry if not already present

      var metricArray = metricObj[eventName];

      if (this.get('isNowSupportedBrowser')) {
        var startMark = "tvr:mark_".concat(eventName, "_start");
        var endMark = "tvr:mark_".concat(eventName, "_end");

        if (this.get('isMarkSupportedBrowser')) {
          performance.mark(endMark);
          performance.measure("tvr:".concat(eventName), startMark, endMark);
        } else {
          eventObj.end = window.performance.now();
        }
      } else {
        eventObj.end = new Date().valueOf();
      }
      /*
      pushing to the `metricsObj` here, since pushing in measure method will leads to have duplicate entires when
      the measure api is invoked by the consuming application
      */


      var duration = this.measure(eventName);
      metricArray.push(duration);

      if (_environment.default.isDevelopment) {
        console.log("".concat(eventName, ": ").concat(duration));
      } else {
        this.get('store').createRecord('performance', {
          name: eventName,
          duration: duration
        }).save().catch(function (error) {
          Raven.captureException(error);
        });
        Ember.get(this, 'metrics').trackEvent('GoogleAnalytics', {
          hitType: 'timing',
          timingCategory: eventName,
          timingVar: 'load',
          timingValue: duration
        });
      }

      return duration;
    },
    measure: function measure(eventName) {
      if (typeof eventName !== 'string') {
        console.error('Expected type String for invoking `measure`');
        return -1;
      }

      var secondaryPerformanceObj = this.get('secondaryPerformanceObj');
      var eventObj = secondaryPerformanceObj[eventName] || {};
      var duration;

      if (this.get('isMarkSupportedBrowser')) {
        var perfEntries = performance.getEntriesByName("tvr:".concat(eventName)); // poping up the last entry pushed into the array

        var entry = perfEntries[perfEntries.length - 1];

        if (entry) {
          duration = entry.duration;
        }
      } else {
        duration = eventObj.end - eventObj.start;
        eventObj.duration = duration;
      }

      return typeof duration === 'number' ? duration : -1;
    },
    getAllMetrics: function getAllMetrics(eventName) {
      /*
        getAllMetrics will return the entires of given event name.
        if no eventName is given, it will return the entire metricsObj
      */
      if (eventName) {
        return this.get("metricsObj.".concat(eventName)) || {};
      }

      return this.get('metricsObj') || {};
    },
    clearAllMetrics: function clearAllMetrics() {
      var _this = this;

      if (this.get('isMarkSupportedBrowser')) {
        var eventNames = Object.keys(this.get('metricsObj') || {});
        eventNames.forEach(function (eventName) {
          _this.clearMetric(eventName);
        });
      }

      this.set('secondaryPerformanceObj', {});
      this.set('metricsObj', {});
    },
    clearMetric: function clearMetric(eventName) {
      if (this.get('isMarkSupportedBrowser')) {
        performance.clearMeasures("tvr:".concat(eventName));
      }

      var secondaryPerformanceObj = this.get('secondaryPerformanceObj') || {};
      delete secondaryPerformanceObj[eventName];
      var metricsObj = this.get('metricsObj') || {};
      delete metricsObj[eventName];
    }
  });

  _exports.default = _default;
});