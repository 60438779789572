define("recruit/routes/recruiter/reports/advanced/conversion/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        columnMaster: this.get('store').query('custom-field-master', {
          'type': 1,
          'is_report': true
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('columns', model.columnMaster);
      controller.set('filterValue', null);
      Ember.run.next('afterRender', function () {
        Ember.$('.ui.cursor-help').popup({
          position: 'bottom center'
        });
      });
    },
    actions: {
      clearText: function clearText(value) {
        if (value) {
          this.controller.set('filterValue', null);
        }
      }
    }
  });

  _exports.default = _default;
});