define("recruit/templates/components/recruiter-view/assessment/helpers/comment-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZEuR4hNH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"custom-tooltip\"],[9,\"style\",\"position: relative\"],[7],[0,\"\\n    \"],[6,\"p\"],[10,\"class\",[26,[\"margin-no \",[25,\"if\",[[20,[\"customColor\"]],[20,[\"customColor\"]],\"text-grey\"],null],\" text-weight-normal ellipsis\"]]],[9,\"style\",\"word-break:break-all\"],[7],[1,[18,\"comment\"],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segment pop-up text-size-small text-left ignoreRowClick\"],[7],[0,\"\\n      \"],[1,[18,\"comment\"],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"p\"],[10,\"class\",[26,[\"margin-no \",[25,\"if\",[[20,[\"customColor\"]],[20,[\"customColor\"]],\"text-black\"],null],\" text-weight-normal ellipsis text-center\"]]],[7],[0,\"--\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[11,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/recruiter-view/assessment/helpers/comment-view.hbs"
    }
  });

  _exports.default = _default;
});