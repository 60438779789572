define("recruit/components/helpers/filters-card-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      Ember.run.next('afterRender', function () {
        Ember.$('.section-label').popup();
      });
    },
    actions: {
      remove: function remove(index) {
        if (this.get('filter')) {
          this.sendAction('actionName', this.get('filter'), this.get('array'), index);
        } else {
          this.sendAction('removeAddedField', this.get('array'), index, this.get('customListFilters'));
        }
      }
    }
  });

  _exports.default = _default;
});