define("recruit/controllers/candidates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      toggle: function toggle(value) {
        Ember.$("#".concat(value)).sidebar('toggle');
      }
    }
  });

  _exports.default = _default;
});