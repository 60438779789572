define("recruit/routes/integration/invite", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Candidate ID: c
    queryParams: {
      c: {
        refreshModel: true
      },
      a: {
        refreshModel: true
      }
    },
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);

      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      var cookies = Ember.getOwner(this).lookup('service:cookies');

      var protocol = function () {
        if (_this.get('_isFastBoot')) {
          return fastboot.get('request.protocol');
        } else {
          return window.location.protocol.replace(/:/, '');
        }
      }();

      if (!this.get('session.isAuthenticated')) {
        cookies.write('ember_simple_auth-redirectTarget', transition.intent.url, {
          path: '/',
          secure: protocol === 'https'
        });
      } else {
        if (cookies.exists('ember_simple_auth-redirectTarget')) {
          cookies.clear('ember_simple_auth-redirectTarget');
        }

        Ember.$('body').addClass('action_bg');
        return this._super.apply(this, arguments);
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        candidate: this.get('store').query('candidate', {
          id: params.c
        }),
        assessment: this.get('store').find('assessment', params.a)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.candidate.length) {
        controller.set('candidates', model.candidate);
      }

      if (model.assessment) {
        controller.set('model.assessments', [model.assessment]);
        controller.set('model.isAssessmentFrozen', true);
        controller.set('model.redirectTo', 'integration.success');
        controller.set('noSelectedValue', controller.get('candidates.length'));
      }
    }
  });

  _exports.default = _default;
});