define("recruit/models/image-placeholder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    text: _emberData.default.attr('string'),
    bgColor: _emberData.default.attr('string'),
    x: _emberData.default.attr('number'),
    y: _emberData.default.attr('number'),
    fillColor: _emberData.default.attr('string'),
    height: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    fSize: _emberData.default.attr('number')
  });

  _exports.default = _default;
});