define("recruit/components/reports/table/time-range/time-per-month", ["exports", "recruit/mixins/report-table-common"], function (_exports, _reportTableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTableCommon.default, {
    modelName: 'assessment',
    // hideGraph: false,
    columns: Ember.computed(function () {
      return [{
        label: 'Invited In',
        component: 'reports/helpers/assessment-header',
        classNames: ['table-head mis text-center border-right-grey'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        valuePath: 'title',
        width: '25%',
        sortable: false,
        cellComponent: 'status-helpers.live' // subColumns: [{
        //   label: 'Avatar',
        //   classNames: ['table-head text-center'],
        //   cellClassNames: ['text-center border-right-grey padding-vs'],
        // }]

      }, {
        sortable: false,
        component: 'reports/helpers/column-with-toggle',
        value: 'AVG. TIME TAKEN PER MONTH(Days)',
        classNames: ['table-head text-center'],
        cellClassNames: ['text-center border-right-grey padding-vs'],
        subColumns: [{
          label: 'Invited',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          valuePath: 'randomValue',
          width: '15%'
        }, {
          label: 'Completed',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          width: '15%',
          valuePath: 'randomValue'
        }, {
          label: 'Evaluated',
          component: 'reports/helpers/table-header',
          classNames: ['table-head mis text-center border-right-grey'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          width: '15%',
          valuePath: 'randomValue'
        }, {
          label: 'Final Selection',
          component: 'reports/helpers/table-header',
          valuePath: 'randomValue',
          classNames: ['table-head text-center'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          width: '15%'
        }, {
          label: 'Avg. TAT',
          component: 'reports/helpers/table-header',
          valuePath: 'randomValue',
          classNames: ['table-head text-center'],
          cellClassNames: ['text-center border-right-grey padding-vs'],
          width: '15%'
        }]
      }];
    }),
    actions: {
      onRowClick: function onRowClick() {
        var a = [];

        for (var i = 0; i < 4; i++) {
          a[i] = Math.floor(Math.random() * (30 - 1 + 1)) + 1;
        }

        this.set('selectedRow', a);
      },
      toggleGraph: function toggleGraph() {
        this.toggleProperty('hideGraph');
      }
    }
  });

  _exports.default = _default;
});