define("recruit/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7UprLV0p",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ember-load-indicator\"],[9,\"style\",\"padding-top:15vh\"],[7],[0,\"\\n    \"],[1,[18,\"spinkit-folding-cube\"],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[1,[18,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});