define("recruit/components/helpers/table-header-view", ["exports", "ember-light-table/components/columns/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      Ember.run.next('afterRender', function () {
        if (Ember.$('.header-popup').length) {
          Ember.$('.header-popup').popup();
        }
      });
    }
  });

  _exports.default = _default;
});