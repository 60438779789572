define("recruit/models/select-field-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    parent_value: _emberData.default.attr('string'),
    parent_id: _emberData.default.attr('string'),
    select_field_type_id: _emberData.default.belongsTo('selectFieldType'),
    sort_order: _emberData.default.attr('number')
  });

  _exports.default = _default;
});