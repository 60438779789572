define("recruit/models/candidate/local", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    first_name: _emberData.default.attr('string'),
    middle_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('number'),
    external_id: _emberData.default.attr('number'),
    assigned_to: _emberData.default.belongsTo('user'),
    last_activity: _emberData.default.attr('string'),
    profile_pic: _emberData.default.attr('string'),
    status: _emberData.default.belongsTo('status'),
    bio: _emberData.default.attr('string'),
    social: _emberData.default.attr(),
    // was array
    industry: _emberData.default.attr('string'),
    currentLocation: _emberData.default.attr('string'),
    currentCompany: _emberData.default.attr('string'),
    customFields: _emberData.default.attr(),
    gender: _emberData.default.attr('string'),
    education: _emberData.default.attr(),
    // was array
    experience: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('pgdate'),
    updated_at: _emberData.default.attr('pgdate'),
    salary: _emberData.default.attr('string'),
    cf: _emberData.default.attr()
  });

  _exports.default = _default;
});