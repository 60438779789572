define("recruit/controllers/recruiter/assessment/create-new-assessment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['currentSlide'],
    currentSlide: 0
  });

  _exports.default = _default;
});