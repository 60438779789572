define("recruit/components/login-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ENV from '../config/environment';
  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    userRole: Ember.inject.service(),
    loading: false,
    disabled: false,
    canRenderCaptcha: Ember.computed(function () {
      return window.grecaptcha.render;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$('body').addClass('login-bg');
    },
    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);

      Ember.$('body').removeClass('login-bg');
    },
    actions: {
      onCaptchaResolved: function onCaptchaResolved() {
        this.set('disabled', false);
      },
      adLogin: function adLogin() {
        this.sendAction('adLogin');
      },
      authenticate: function authenticate() {
        var _this = this;

        var _this$getProperties = this.getProperties('identification', 'password'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        if (!(identification && password)) {
          return this.set('errorMessage', 'Please enter username and password');
        }

        this.set('loading', true);
        this.get('session').authenticate('authenticator:oauth2', identification, password).then(function () {
          _this.set('loading', false);

          return _this.get('currentUser').load().then(function () {
            return _this.get('userRole').init();
          });
        }).catch(function (reason) {
          _this.set('loading', false);

          if (reason.title == 'TransitionAborted') {
            return false;
          }

          if (reason.message) {
            _this.get('toast').error(reason.message, 'Invalid credentials');
          } else {
            _this.get('toast').error('Please try again later', 'There was an error logging in. ');
          }
        });
      }
    }
  });

  _exports.default = _default;
});