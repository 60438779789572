define("recruit/components/recruiter-actions/actions", ["exports", "npm:lodash"], function (_exports, _npmLodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$('body').addClass('action_bg');
      this.set('selectedListValues', Ember.A([]));
      this.set('emptyList', false);
      this.set('addedList', Ember.A([]));
      this.set('disabledItems', Ember.A([]));
      this.set('currentRecruiter', Ember.A([]));
    },
    didInsertElement: function didInsertElement() {
      this.set('selectedListValues', this.get('listValues').map(function (model) {
        return model;
      }));
    },
    noSelectedValue: Ember.computed('selectedListValues.[]', function () {
      return this.get('selectedListValues.length');
    }),
    isQuestionRelation: Ember.computed('model.relationType', function () {
      return this.get('model.relationType') === 'questions';
    }),
    showAssessmentList: Ember.computed('selectedListValues.[]', function () {
      var assessments = this.get('selectedListValues').map(function (ac) {
        return +ac.get('assessment.id');
      });
      return _npmLodash.default.uniq(assessments).length != 1 && this.get('model.actionType') === "assign panel";
    }),
    internalView: Ember.computed.oneWay('model.internalView'),
    hideCandidateList: Ember.computed('model.relationType', function () {
      return this.get('model.relationType') == 'assessmentSections' || this.get('model.actionType') == 'remind panel' || this.get('model.actionType') == 'change assessment owner';
    }),
    showCandidateCount: Ember.computed('model.relationType', function () {
      return this.get('model.relationType') == 'assessmentSections';
    }),
    listContain: Ember.computed('model.actionType', function () {
      switch (this.get('model.actionType')) {
        case 'delete assessment':
          return 'assessment';

        case 'delete invite':
          return 'invite';

        case 'delete questions':
          return 'question';

        default:
          return 'candidate';
      }
    }),
    willClearRender: function willClearRender() {
      this._super.apply(this, arguments);

      Ember.$('body').removeClass('action_bg');
    },
    actions: {
      back: function back() {
        window.history.back();
      },
      addItem: function addItem(item, disableList) {
        if (!_npmLodash.default.find(this.get('addedList'), ['id', item.id])) {
          this.get('addedList').pushObject(item);
          this.get(disableList).pushObject(item);
        }
      },
      removeItem: function removeItem(item, disableList) {
        if (_npmLodash.default.find(this.get('addedList'), ['id', item.id])) {
          this.get('addedList').removeObject(item);
        }

        if (this.get(disableList) && this.get(disableList).findBy('id', item.get('id'))) {
          this.get(disableList).removeObject(item);
        }
      }
    }
  });

  _exports.default = _default;
});