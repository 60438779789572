define("recruit/routes/recruiter/assessment/details/candidate", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "npm:lodash", "recruit/data/assessment-invite-candidate"], function (_exports, _authenticatedRouteMixin, _npmLodash, _assessmentInviteCandidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var resetFilterRoutes = ["recruiter.pending-candidates", "recruiter.invites.index", "recruiter.assessment.index", "recruiter.live-session.index", "recruiter.reports.dashboard", "recruiter.assessment.details.overview", "user.settings.account", "dashboard.index"];

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    userPermission: Ember.inject.service('user-permission'),
    queryParams: {
      searchText: {
        replace: true
      },
      assessmentFilters: {
        replace: true
      },
      sort: {
        replace: true
      }
    },
    performance: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('store').push({
        data: _assessmentInviteCandidate.default
      });
      this.get('performance').start('Recruiter:Assessment:Invites:list');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('assessmentSection', Ember.A([]));
      controller.set('model', model);
      var appliedFilters = Ember.A([]);
      controller.set('appliedFilters', appliedFilters);
      controller.set('columns', Ember.A([]));
      controller.set('customListFilters', Ember.A([]));
      controller.set('userPermission', this.get('userPermission'));
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.get('store').unloadAll('column/master');
        this.controller.set('searchText', null);
        this.controller.set('sort', null);

        if (_npmLodash.default.includes(resetFilterRoutes, transition.targetName)) {
          this.controller.set('assessmentFilters', null);
        }
      }
    }
  });

  _exports.default = _default;
});