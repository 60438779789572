define("recruit/templates/user/settings/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "geG6QnD/",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[6,\"div\"],[9,\"class\",\"ui grid t-user-settings\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ui large form container margin-top-ten\"],[7],[0,\"\\n\"],[4,\"with\",[[25,\"changeset\",[[20,[\"model\"]],[20,[\"PasswordValidations\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"fields\"],[7],[0,\"\\n      \"],[1,[25,\"validated-field\",null,[[\"label\",\"modelName\",\"type\",\"class\",\"changeset\",\"property\"],[\"Current Password\",\"updatePassword\",\"password\",\"eight wide\",[19,1,[]],\"current_password\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"fields\"],[9,\"style\",\"margin-top:40px;\"],[7],[0,\"\\n      \"],[1,[25,\"validated-field\",null,[[\"label\",\"modelName\",\"type\",\"class\",\"changeset\",\"property\",\"passwordField\",\"passwordStrength\"],[\"New Password\",\"updatePassword\",\"password\",\"eight wide\",[19,1,[]],\"new_password\",true,[20,[\"passwordStrength\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"fields\"],[9,\"style\",\"margin-top:40px;\"],[7],[0,\"\\n      \"],[1,[25,\"validated-field\",null,[[\"label\",\"modelName\",\"type\",\"class\",\"changeset\",\"property\"],[\"Re-type Password\",\"updatePassword\",\"password\",\"eight wide\",[19,1,[]],\"new_password_repeat\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui right floated buttons small top margin\"],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\" ui \",[25,\"if\",[[20,[\"isSaveLoading\"]],\"loading disabled\"],null],\" primary submit button\"]]],[10,\"disabled\",[19,1,[\"isInvalid\"]],null],[3,\"action\",[[19,0,[]],\"updatePassword\",[19,1,[]]]],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"check icon\"],[7],[8],[0,\" Save\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/user/settings/password.hbs"
    }
  });

  _exports.default = _default;
});