define("recruit/templates/recruiter/reports/advanced/conversion/conversion-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xbG0qc4b",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"style\",\"background: #FFF; padding: 30px; margin-top: 10px; padding-bottom: 10px; padding-top: 15px;\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"style\",\"margin: 5px;\"],[7],[0,\"\\n    \"],[1,[25,\"reports/graphs/conversion-graph\",null,[[\"isLoading\",\"hideFilters\",\"startDate\",\"endDate\",\"selectedFilterValue\",\"selectedRow\",\"nodeColors\",\"nodeValues\",\"cirlceLabels\",\"graphTitle\",\"hideGraph\",\"dateRecordSet\",\"graphInfo\",\"firstTableRecord\",\"dateFilters\",\"setDateFilter\"],[[20,[\"isLoading\"]],true,[20,[\"startDate\"]],[20,[\"endDate\"]],[20,[\"selectedFilterValue\"]],[20,[\"selectedRow\"]],\"purple blue yellow green\",[20,[\"nodeValues\"]],[20,[\"cirlceLabels\"]],[20,[\"graphTitle\"]],[20,[\"hideGraph\"]],[20,[\"dateRecordSet\"]],[20,[\"graphInfo\"]],[20,[\"firstTableRecord\"]],[20,[\"dateFilters\"]],\"setDateFilter\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"style\",\"margin-bottom: 30px;\"],[7],[0,\"\\n    \"],[1,[25,\"reports/table/conversion-table\",null,[[\"isLoading\",\"selectedFilterValue\",\"dateFilters\",\"firstTableRecord\",\"selectedRow\",\"hideGraph\",\"columns\",\"dateRecordSet\",\"startDate\",\"endDate\",\"start_date\",\"end_date\",\"custom_field_master_id\",\"modelName\"],[[20,[\"isLoading\"]],[20,[\"selectedFilterValue\"]],[20,[\"dateFilters\"]],[20,[\"firstTableRecord\"]],[20,[\"selectedRow\"]],[20,[\"hideGraph\"]],[20,[\"columns\"]],[20,[\"dateRecordSet\"]],[20,[\"startDate\"]],[20,[\"endDate\"]],[20,[\"start_date\"]],[20,[\"end_date\"]],[20,[\"custom_field_master_id\"]],\"reports/custom-field-conversion\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[18,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/recruiter/reports/advanced/conversion/conversion-index.hbs"
    }
  });

  _exports.default = _default;
});