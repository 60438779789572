define("recruit/templates/components/panel/live-evaluation-strip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sJn+cvv3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"selectedAssc\",\"section\",\"evaluationFormUrl\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"data-tooltip\",\"Evaluate\"],[9,\"data-position\",\"left center\"],[9,\"style\",\"display: inherit;\"],[7],[0,\"\\n    \"],[6,\"img\"],[10,\"src\",[26,[\"/images/panel/form\",[25,\"if\",[[20,[\"showTextIcon\"]],\"_text\"],null],\".svg\"]]],[9,\"alt\",\"\"],[9,\"class\",\"ui centered image\"],[9,\"style\",\"width:70px; cursor:pointer; border: 1px solid #bbbbbb; border-radius: 50%;\"],[3,\"action\",[[19,0,[]],\"formEvaluation\",\"rating\"]],[7],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"span\"],[9,\"data-tooltip\",\"Comment\"],[9,\"data-position\",\"left center\"],[9,\"style\",\"display:table; margin:auto\"],[7],[0,\"\\n    \"],[6,\"img\"],[10,\"src\",[26,[\"/images/panel/comment\",[25,\"if\",[[20,[\"showTextIcon\"]],\"_text\"],null],\".svg\"]]],[9,\"alt\",\"\"],[9,\"class\",\"ui centered image\"],[9,\"style\",\"width:70px; cursor:pointer; border: 1px solid #bbbbbb; border-radius: 50%;\"],[3,\"action\",[[19,0,[]],\"formEvaluation\",\"comment\"]],[7],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/panel/live-evaluation-strip.hbs"
    }
  });

  _exports.default = _default;
});