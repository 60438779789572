define("recruit/components/graphs/time-range-conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.drawChart();
      var chart = Ember.$('#highcharts-container').highcharts();
      chart.xAxis[0].setCategories(this.get('xAxisCategories'));
    },
    drawChart: function drawChart() {
      Ember.$('#highcharts-container').highcharts({
        chart: {
          type: 'line',
          backgroundColor: '#fff'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        subtitle: {
          text: ''
        },
        yAxis: {
          title: {
            text: 'Number of Candidates'
          }
        },
        plotOptions: {
          series: {
            showInLengend: false,
            pointStart: 0,
            connectNulls: true
          },
          line: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true
          }
        },
        "series": this.get('dataArray')
      });
    }
  });

  _exports.default = _default;
});