define("recruit/templates/components/live-schedule/candidate-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8j5YmeMc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"isLiveSchedule\"]]],null,{\"statements\":[[0,\"  \"],[6,\"p\"],[9,\"class\",\"ellipsis text-left text-black margin-no text-weight-medium\"],[7],[0,\"\\n    \"],[1,[20,[\"candidateRecords\",\"fullName\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"ellipsis text-left text-black margin-no\"],[7],[0,\"\\n    \"],[1,[20,[\"candidateRecords\",\"fullName\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[6,\"p\"],[9,\"class\",\"ellipsis text-left\"],[7],[0,\"\\n  \"],[1,[20,[\"candidateRecords\",\"email\"]],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/live-schedule/candidate-details.hbs"
    }
  });

  _exports.default = _default;
});