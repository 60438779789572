define("recruit/components/recruiter-view/actions/section-status-change-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    status: Ember.computed(function () {
      return this.get('store').query('status', {
        type: 20,
        sort_key: this.get('statusValue')
      });
    })
  });

  _exports.default = _default;
});