define("recruit/templates/components/helpers/multiple-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zS2FtkIh",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"if\",[[20,[\"customAction\"]]],null,{\"statements\":[[4,\"power-select-multiple\",null,[[\"searchEnabled\",\"options\",\"search\",\"searchField\",\"renderInPlace\",\"defaultHighlighted\",\"selected\",\"placeholder\",\"disabled\",\"triggerClass\",\"results\",\"onchange\"],[[20,[\"searchEnabled\"]],[20,[\"listSource\"]],[25,\"action\",[[19,0,[]],\"searchAction\"],null],[20,[\"searchField\"]],[20,[\"renderInPlace\"]],[25,\"action\",[[19,0,[]],\"disabledOption\"],null],[20,[\"selectedOption\"]],[20,[\"placeHolder\"]],[20,[\"disabled\"]],[20,[\"dropdownClass\"]],[20,[\"disabledItems\"]],[25,\"action\",[[19,0,[]],\"customAction\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"optionLabel\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"get-property\",[[19,2,[]],[20,[\"optionLabel\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[19,2,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"power-select-multiple\",null,[[\"searchEnabled\",\"options\",\"search\",\"searchField\",\"renderInPlace\",\"selected\",\"disabled\",\"placeholder\",\"triggerClass\",\"onchange\"],[[20,[\"searchEnabled\"]],[20,[\"listSource\"]],[25,\"action\",[[19,0,[]],\"searchAction\"],null],[20,[\"searchField\"]],[20,[\"renderInPlace\"]],[20,[\"selectedOption\"]],[20,[\"disabled\"]],[20,[\"placeHolder\"]],[20,[\"dropdownClass\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"selectedOption\"]]],null]],null]]],{\"statements\":[[4,\"if\",[[20,[\"optionLabel\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"get-property\",[[19,1,[]],[20,[\"optionLabel\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[19,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/helpers/multiple-dropdown.hbs"
    }
  });

  _exports.default = _default;
});