define("recruit/models/expanded-event", ["exports", "recruit/models/event"], function (_exports, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _event.default.extend();

  _exports.default = _default;
});