define("recruit/models/actions/live-reschedule", ["exports", "ember-data", "recruit/mixins/ac-action"], function (_exports, _emberData, _acAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_acAction.default, {
    liveSessionID: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('pgdate'),
    endDate: _emberData.default.attr('pgdate'),
    currentOffset: _emberData.default.attr(),
    timezone: _emberData.default.attr(),
    isNotify: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});