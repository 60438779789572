define("recruit/components/recruiter-view/assessment/assessment-overview/assessment-candidate-funnel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['margin-bottom-five'],
    store: Ember.inject.service(),
    pathL: Ember.computed(function () {
      var arr = this.get('funnelValues'); // console.log(arr[this.get('indexValue')])

      return arr[this.get('indexValue')]; // return this.get('funnelValues')[this.get('asIndex')]
    }),
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup();
      });
    },
    actions: {
      changeAS: function changeAS(model, totalCandidates) {
        this.sendAction('selectedAS', model, totalCandidates);
      }
    }
  });

  _exports.default = _default;
});