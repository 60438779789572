define("recruit/adapters/application", ["exports", "ember-data", "recruit/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "npm:object-hash", "npm:lodash", "moment"], function (_exports, _emberData, _environment, _dataAdapterMixin, _npmObjectHash, _npmLodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:oauth2',
    namespace: '',
    session: Ember.inject.service(),
    userRole: Ember.inject.service(),
    host: _environment.default.APP.API_ROOT,
    coalesceFindRequests: true,
    router: Ember.inject.service('-routing'),
    user: Ember.inject.service('current-user'),
    headers: Ember.computed(function () {
      if (this.get('session.isAuthenticated')) {
        return {
          "X-Current-Role": this.get('userRole.currentRole'),
          "accept": 'application/json; version=1.1'
        };
      }
    }).property().volatile(),
    // buildURL (modelName, id, snapshot, requestType, query={}) {
    //   query.v = moment().unix();
    //   return this._super(...arguments);
    // },
    createRecord: function createRecord(store, type, snapshot) {
      var headers = this.get('headers') || {};
      headers['X-Fingerprint'] = (0, _npmObjectHash.default)(snapshot + parseInt((0, _moment.default)().unix() / 100));
      this.set('headers', headers);
      return this._super.apply(this, arguments);
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var headers = this.get('headers') || {};
      headers['X-Fingerprint'] = (0, _npmObjectHash.default)(snapshot + parseInt((0, _moment.default)().unix() / 100));
      this.set('headers', headers);
      return this._super.apply(this, arguments);
    },
    pathForType: function pathForType(type) {
      return Ember.String.underscore(type);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      // if(headers.hasOwnPropert('x-pagination-total-count')){
      if (_npmLodash.default.includes(_npmLodash.default.toLower(_npmLodash.default.keys(headers)), 'x-pagination-total-count')) {
        var meta = {
          total: headers["x-pagination-total-count"] || headers["X-Pagination-Total-Count"] || 1,
          pages: headers["x-pagination-page-count"] || headers["X-Pagination-Page-Count"] || 1
        };
        payload.meta = meta;
      }

      if (payload) {
        if (status === 422 || status === 400) {
          var e = {
            errors: []
          };

          if (Array.isArray(payload)) {
            payload.forEach(function (elem) {
              e.errors.push({
                detail: elem.message,
                field: elem.field,
                source: {
                  pointer: 'data/attributes/' + elem.field
                }
              });
            });
          } else {
            e.errors.push({
              detail: payload.message,
              status: payload.status,
              name: payload.name
            });
          }

          payload = e;
        }
      }

      return this._super(status, headers, payload, requestData);
    }
  });

  _exports.default = _default;
});