define("recruit/initializers/ember-cli-uuid", ["exports", "ember-data", "ember-cli-uuid", "recruit/config/environment", "ember-cli-uuid/configuration"], function (_exports, _emberData, _emberCliUuid, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-cli-uuid',
    initialize: function initialize() {
      var config = _environment.default['ember-cli-uuid'] || {};

      _configuration.default.load(config);

      _emberData.default.Adapter.reopen({
        generateIdForRecord: function generateIdForRecord() {
          return _configuration.default.defaultUUID ? (0, _emberCliUuid.uuid)() : null;
        }
      });
    }
  };
  _exports.default = _default;
});