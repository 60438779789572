define("recruit/components/helpers/download-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'a',
    target: '_blank',
    attributeBindings: ['href', 'download', 'target'],
    accessToken: Ember.computed.alias('session.session.content.authenticated.access_token'),
    download: Ember.computed('url', function () {
      return "".concat(this.get('url'), "?&accessToken=").concat(this.get('accessToken'));
    }),
    href: Ember.computed.alias('download')
  });

  _exports.default = _default;
});