define("recruit/templates/components/events/helpers/create-event-candidate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GpyfOKC6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"isBulkUpload\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui segments bg-white\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"text-black\"],[7],[0,\"\\n      \"],[1,[25,\"recruiter-view/bulk/bulk-upload\",null,[[\"model\",\"isEventsModal\",\"isBulkUpload\",\"isEventOverview\",\"rerenderTable\",\"bulkImportCandidates\"],[[20,[\"model\"]],true,[20,[\"isBulkUpload\"]],[20,[\"isEventOverview\"]],[25,\"action\",[[19,0,[]],\"rerenderTable\"],null],[25,\"action\",[[19,0,[]],\"bulkImportCandidates\"],null]]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ui segments\"],[7],[0,\"\\n    \"],[1,[25,\"recruiter-view/candidate/create/create-header\",null,[[\"candidateModel\",\"isEventsModal\",\"isBulkUpload\"],[[20,[\"candidateModel\"]],true,[20,[\"isBulkUpload\"]]]]],false],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui segment padding-no\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui large form light-text bg-white\"],[7],[0,\"\\n        \"],[1,[25,\"candidate-helpers/candidate-basic-details\",null,[[\"file\",\"model\",\"isEventsModal\",\"customActionName\",\"isEventOverview\",\"candidateModel\",\"CandidateValidations\",\"rerenderTable\",\"bulkImportCandidates\"],[[20,[\"file\"]],[20,[\"model\"]],true,\"createEventCandidate\",true,[20,[\"candidateModel\"]],[20,[\"CandidateValidations\"]],[25,\"action\",[[19,0,[]],\"rerenderTable\"],null],[25,\"action\",[[19,0,[]],\"bulkImportCandidates\"],null]]]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "recruit/templates/components/events/helpers/create-event-candidate.hbs"
    }
  });

  _exports.default = _default;
});