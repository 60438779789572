define("recruit/components/events/event-candidate-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').query('expanded-event', {
        'id': this.get('eventModel.id')
      }).then(function (res) {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('expandedEventModel', res.get('firstObject'));

          _this.set('isRelationLoaded', true);
        }
      });
    },
    actions: {
      bulkImportCandidates: function bulkImportCandidates(property) {
        this.sendAction('bulkImportCandidates', property);
      }
    }
  });

  _exports.default = _default;
});