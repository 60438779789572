define("recruit/components/answer-views/pearson", ["exports", "recruit/components/answer-views/objective"], function (_exports, _objective) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _objective.default.extend({
    didRender: function didRender() {
      Ember.run.next('afterRender', function () {
        Ember.$('[data-content]').popup({
          position: 'top center'
        });
      });
    }
  });

  _exports.default = _default;
});