define("recruit/models/event-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    userRole: Ember.inject.service(),
    event: _emberData.default.belongsTo('event'),
    user: _emberData.default.belongsTo('user'),
    role: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('pgdate'),
    created_by: _emberData.default.belongsTo('user'),
    updated_at: _emberData.default.attr('pgdate'),
    updated_by: _emberData.default.belongsTo('user'),
    status: _emberData.default.attr('number', {
      defaultValue: 1
    }),

    /* 1. Pending, 2. Accepted, 3. Declined */
    panel: _emberData.default.belongsTo('panel'),
    assessmentCountObserver: Ember.observer('user.isFulfilled', 'event.isFulfilled', 'event.eventAssessmentsCount.isFulfilled', 'event.eventAssessmentsCount.[]', 'event.eventAssessmentsCount.@each.assessment.isFulfilled', 'event.eventAssessmentsCount.@each.assessment.owned_by.isFulfilled', function () {
      if (this.get('event.isFulfilled') && this.get('event.eventAssessmentsCount.isFulfilled')) {
        this.set('assessmentCount', this.get('event.eventAssessmentsCount').filterBy('assessment.owned_by.id', this.get('user.id')).length);
      }
    }),
    isDeleteableObserver: Ember.observer('event.isFulfilled', 'assessmentCount', 'event.status', 'eventModel.canEditEvent', 'userRole.currentRole', function () {
      if (this.get('event.isFulfilled') && this.get('event.eventAssessmentsCount.isFulfilled') && (this.get('event.status') == 1 || this.get('event.status') == 2 || this.get('event.status') == 4)) {
        this.set('isDeleteable', this.get('event.canEditEvent') && !this.get('assessmentCount'));
      }
    }),
    isPanelDeleteable: Ember.computed('assessmentCount', 'event.isFulfilled', 'event.status', 'created_by.isFulfilled', 'event.owned_by.isFulfilled', 'event.eventAssessmentsCount.isFulfilled', 'event.eventAssessmentsCount.[]', 'event.eventAssessmentsCount.@each.owned_by.isFulfilled', function () {
      if (this.get('event.status') != 3 && this.get('event.status') != 6) {
        if (this.get('event.owned_by.id') == this.get('currentUser.me.id')) {
          return this.get('created_by.id') == this.get('currentUser.me.id');
        } else {
          return this.get('event.eventAssessmentsCount').filterBy('owned_by.id', this.get('currentUser.me.id')).length;
        }
      }
    })
  });

  _exports.default = _default;
});