define("recruit/components/recruiter-view/assessment/helpers/parameter-view", ["exports", "recruit/components/recruiter-view/assessment/helpers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    rating: Ember.computed('row', 'selectedEvaluation', 'value.@each.evaluationRatings.isFulfilled', 'pc', function () {
      var selectedEvaluation = this.get('selectedEvaluation');

      if (selectedEvaluation) {
        if (selectedEvaluation.get('evaluationRatings.length')) {
          return selectedEvaluation.get('evaluationRatings').findBy('parameter.id', this.get('column.parameterId')).get('rating');
        } else {
          return '--';
        }
      }
    })
  });

  _exports.default = _default;
});