define("recruit/models/filter/applied", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import _ from 'npm:lodash';
  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    filter: _emberData.default.belongsTo('filter/master'),
    filterValues: _emberData.default.attr(),
    objectType: _emberData.default.attr('string'),
    formattedFilterValue: _emberData.default.attr('string'),
    sectionName: _emberData.default.attr('string'),
    queryString: Ember.computed('filter', 'value', function () {
      return this.interpolate(this.get('filter.queryTemplate'))(this.get('filterValues'));
    }),
    interpolate: function interpolate(key) {
      var k = key || '';
      return function interpolate(value) {
        return k.replace(/{{([^{}]*)}}/g, function (a, b) {
          var r = value[b];

          if (r) {
            // if(typeof(r) != 'string') {
            //   r = r.toString();
            // }
            // r = r.replace(/[*+=\-?^><!&${}()|"~]/g, "\\$&");
            // r = b == 'value' ? _.pad(r, r.length + 2, '"') : r;
            return typeof r === 'string' || typeof r === 'number' ? r : a; // Refernce [1]
          } else {
            return '';
          }
        });
      };
    }
  });
  /*
    [1] Escaping the special characters to \\
    Reference: https://www.elastic.co/guide/en/elasticsearch/reference/5.5/query-dsl-query-string-query.html#_reserved_characters
  */


  _exports.default = _default;
});