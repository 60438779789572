define("recruit/helpers/get-object-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getObjectValue = getObjectValue;
  _exports.default = void 0;

  function getObjectValue(object) {
    var a = Ember.get(object[0], object[1].toString());

    if (a) {
      return a[object[2]];
    }
  }

  var _default = Ember.Helper.helper(getObjectValue);

  _exports.default = _default;
});